import React,
{
  useCallback,
  useEffect,
  useMemo,
  useState,
  ChangeEvent,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from '@material-ui/core';

import Spinner from '../../components/spinner/spinner';

import getSettings from '../../actions/get-settings';

import apiRequest from '../../helpers/api-request';
import isUserRole from '../edit-user-page/helpers/isUserRole';
import validateEmail from '../../helpers/validate-email';

import Nullable from '../../types/Nullable';
import ReduxState from '../../types/ReduxState';
import User from '../../types/User';
import Label from '../../types/Label';
import UserRole from '../../types/UserRole';
import SiteData from '../../types/SiteData';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  pageTitle: {
    marginBottom: 20,
  },
  textInput: {
    marginBottom: 20,
    width: 350,
  },
  radioGroup: {
    display: 'block',
    marginBottom: 10,
  },
  saveButton: {
    marginTop: 19,
    marginBottom: 10,
    width: 350,
  },
  subtitle: {
    margin: '37px 0px 8px',
  },
  instruction: {
    marginBottom: 16,
  },
}));

const AddSiteUserPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const authenticatedUser = useSelector<ReduxState, Nullable<User>>(
    (state) => state.authentication.get('USER'),
  );
  const settings = useSelector<ReduxState, Nullable<SiteData>>(
    (state) => state.settings.get('SETTINGS'),
  );

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [label, setLabel] = useState<Nullable<Label>>(null);
  const [role, setRole] = useState<UserRole>(UserRole.User);
  const [POSPin, setPOSPin] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Nullable<string>>(null);

  useEffect(() => {
    if (settings == null && authenticatedUser != null) {
      dispatch(getSettings(authenticatedUser.site));
    }
  }, [authenticatedUser]);

  const labelSelectOptions = useMemo(() => {
    if (settings != null && settings.reportingLabels) {
      return settings.reportingLabels
        .filter((lab) => (lab.active != null ? lab.active : true));
    }
    return [];
  }, [settings]);

  const nameChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, [setName]);

  const emailChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, [setEmail]);

  const labelChangeHandler = useCallback((event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    if (value === 'clear-select') {
      setLabel(null);
    } else if (settings != null && settings.reportingLabels) {
      const newLabel = settings.reportingLabels.find(({ text }) => text === value);
      if (newLabel) setLabel(newLabel);
    }
  }, [setLabel, settings]);

  const roleChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (isUserRole(value)) {
      setRole(value);
    }
  }, [setRole]);

  type CreateSiteUserBody = {
    name: string;
    email: string;
    site: string;
    role: string;
    reportingLabels: string[];
    createdBy: string;
    POSPin?: string;
    POSUser?: boolean;
  };

  const handleSaveClick = async () => {
    setLoading(true);
    try {
      const body: CreateSiteUserBody = {
        name,
        email,
        site: settings?.site!,
        role,
        reportingLabels: label ? [label.id] : [],
        createdBy: authenticatedUser?.id!,
      };
      if (POSPin && POSPin.length === 4) {
        body.POSPin = POSPin;
        body.POSUser = true;
      }
      await apiRequest(
        'create-site-user',
        'POST',
        body,
      );
      history.push('/manage-site-users');
    } catch (e) {
      setError('User could not be created, please try again.');
      setLoading(false);
    }
  };

  const handleChangePin = (event: React.ChangeEvent<{ value: string }>) => {
    const { target: { value } } = event;
    if (/^\d{0,4}$/.test(value)) setPOSPin(value);
  };

  const isValidUser = useMemo(() => (
    !validateEmail(email)
    && name.length > 0
    && isUserRole(role)
    && (POSPin.length === 4 || POSPin.length === 0)
  ), [name, email, role, POSPin]);

  if (!settings || loading) return <Spinner />;

  return (
    <Box className={classes.container}>
      <Typography variant="h6" className={classes.pageTitle}>Create User</Typography>
      <TextField
        label="Name"
        className={classes.textInput}
        variant="outlined"
        value={name}
        onChange={nameChangeHandler}
      />
      <TextField
        label="Email"
        className={classes.textInput}
        variant="outlined"
        value={email}
        onChange={emailChangeHandler}
      />
      <FormControl style={{ width: 350, marginBottom: 20 }} variant="outlined">
        <InputLabel id="label-select-label">Reporting Label</InputLabel>
        <Select
          labelId="label-select-label"
          labelWidth={settings.site.length + 130}
          value={label ? label.text : ''}
          onChange={labelChangeHandler}
          MenuProps={{ variant: 'menu' }}
        >
          {labelSelectOptions.map(({ id, text }) => (
            <MenuItem key={id} value={text}>{text}</MenuItem>
          ))}
          <MenuItem key="clear-select" value="clear-select">
            <Typography variant="button" color="primary">No Label</Typography>
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl className={classes.radioGroup}>
        <FormLabel id="role-radio-group-label">Role</FormLabel>
        <RadioGroup
          row
          aria-labelledby="role-radio-group-label"
          defaultValue={UserRole.User}
          value={role}
          onChange={roleChangeHandler}
        >
          <FormControlLabel value={UserRole.User} control={<Radio />} label="User" />
          <FormControlLabel value={UserRole.Manager} control={<Radio />} label="Manager" />
          <FormControlLabel value={UserRole.SuperUser} control={<Radio />} label="Super User" />
        </RadioGroup>
      </FormControl>
      <Typography variant="subtitle1" className={classes.subtitle}>Peazi POS PIN (optional)</Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        className={classes.instruction}
      >
        Assign a 4-number PIN for Peazi POS login
      </Typography>
      <TextField
        label="PIN"
        className={classes.textInput}
        variant="outlined"
        type="number"
        value={POSPin}
        onChange={handleChangePin}
      />
      <Button
        className={classes.saveButton}
        variant="contained"
        color="primary"
        onClick={handleSaveClick}
        disabled={!isValidUser}
      >
        Create User
      </Button>
      {error != null && <Typography variant="body1" color="error">{error}</Typography>}
    </Box>
  );
};

export default AddSiteUserPage;
