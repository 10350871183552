import React from 'react';

import {
  Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
} from '@material-ui/core';

type Props = {
  open: boolean
  onClose: () => void
};

const DiscountCodesInfo = ({ open, onClose }: Props) => (
  <Dialog open={open} onClose={onClose} aria-labelledby="discount-dialog-title">
    <DialogTitle id="discount-dialog-title">Discount Codes Information</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Discount Code: A unique code that the user can enter and get a discount
      </DialogContentText>
      <DialogContentText>
        Usages: The amount of times you want the discount
        to be used, when left blank the code can be used unlimited.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button color="secondary" variant="text" onClick={onClose}>Close</Button>
    </DialogActions>
  </Dialog>
);

export default DiscountCodesInfo;
