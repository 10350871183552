import EditableSiteData from '../../../types/EditableSiteData';
import SiteData from '../../../types/SiteData';

const createEditableSiteData = (uneditableSiteData: SiteData): EditableSiteData => {
  if (uneditableSiteData.serviceCharge != null) {
    const { serviceCharge: serviceChargeData } = uneditableSiteData;
    const editableServiceCharge = {
      plu: String(serviceChargeData.plu),
      value: String(serviceChargeData.value),
      chargeName: serviceChargeData.chargeName,
      percentage: String(serviceChargeData.percentage),
      compulsory: serviceChargeData.compulsory,
    };
    return { ...uneditableSiteData, serviceCharge: editableServiceCharge };
  }
  return { ...uneditableSiteData, serviceCharge: null };
};

export default createEditableSiteData;
