import React from 'react';

import { Chip, Tooltip } from '@material-ui/core';

type Props = {
  label: string
  color: string
  onClick?: () => void
  tooltip?: string
};

const CustomChip = ({
  label, color, onClick, tooltip,
}: Props) => {
  if (onClick != null) {
    return (
      <Tooltip title={tooltip || label}>
        <Chip
          variant="outlined"
          color="primary"
          label={label}
          style={{ color, borderColor: color }}
          onClick={onClick}
        />
      </Tooltip>
    );
  }
  return (
    <Tooltip title={tooltip || label}>
      <Chip
        variant="outlined"
        color="primary"
        label={label}
        style={{ color, borderColor: color }}
      />
    </Tooltip>
  );
};

export default CustomChip;
