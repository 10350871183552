import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Grid, Typography } from '@material-ui/core';

import getSiteMap from '../../actions/get-site-map';
import getProducts from '../../actions/get-products';

import Spinner from '../../components/spinner/spinner';
import SubCategoryForm from '../../components/SubCategoryForm/SubCategoryForm';

import filterSiteMap from '../SubCategoryPage/helpers/filterSiteMap';
import changeSiteMap from '../../helpers/change-site-map';
import nameToUrl from '../../helpers/name-to-url';
import uploadImage from '../../helpers/upload-image';
import getImageUrl from '../../helpers/get-image-url';
import filterCategory from '../category-page/helpers/filter-category';

const EditSubCategoryPage = () => {
  const user = useSelector((state) => state.authentication.get('USER'));
  const isSiteMapLoading = useSelector((state) => state.siteMap.get('IS_SITE_MAP_LOADING'));
  const siteMapId = useSelector((state) => state.siteMap.get('SITE_MAP_ID'));
  const siteMap = useSelector((state) => state.siteMap.get('SITE_MAP'));
  const isProductsLoading = useSelector((state) => state.products.get('IS_PRODUCTS_LOADING'));
  const products = useSelector((state) => state.products.get('PRODS'));
  const [errorMessage, setErrorMessage] = useState(null);
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const { category, subCategory } = params;

  useEffect(() => {
    if (user.site) {
      dispatch(getSiteMap(user.site));
      dispatch(getProducts(user.site));
    }
  }, [user]);

  const handleSave = useCallback(async (newSubCat, subCatImage) => {
    try {
      let image = newSubCat.image || '';

      if (subCatImage) {
        image = await uploadImage(user.site, subCatImage)
          .then(({ ref }) => getImageUrl(ref.fullPath));
      }

      const newSiteMap = siteMap.map((map) => {
        if (nameToUrl(map.category) === category) {
          return {
            ...map,
            subCategories: map.subCategories.map((subCat) => {
              if (nameToUrl(subCat.name) === subCategory) {
                return { ...newSubCat, image };
              }
              return subCat;
            }),
          };
        }
        return map;
      });

      await changeSiteMap(siteMapId, { site: user.site, products: newSiteMap });

      dispatch(getSiteMap(user.site));
      history.push(`/site-map/${nameToUrl(category)}/${nameToUrl(newSubCat.name)}`);
    } catch (e) {
      setErrorMessage(e.message || 'Something went wrong, please try again or contact support.');
    }
  }, [user, siteMap, siteMapId, category, subCategory]);

  const handleCancel = useCallback(() => {
    history.push(`/site-map/${nameToUrl(category)}/${nameToUrl(subCategory)}`);
  }, [category, subCategory]);

  const filteredCategory = useMemo(() => filterCategory(siteMap, category), [siteMap, category]);
  const filteredSiteMap = useMemo(
    () => filterSiteMap(siteMap, category, subCategory),
    [siteMap, category, subCategory],
  );

  if (filteredSiteMap.length === 0 || products.length === 0) return null;

  if (isSiteMapLoading || isProductsLoading) return <Spinner />;

  return (
    <Grid>
      <SubCategoryForm
        user={user}
        subCategories={filteredCategory}
        subCategoryToEdit={filteredSiteMap}
        productsToUse={products}
        onSave={handleSave}
        onCancel={handleCancel}
      />
      {errorMessage ? <Typography variant="body1" color="error">{errorMessage}</Typography> : null}
    </Grid>
  );
};

export default EditSubCategoryPage;
