import { Paper, Typography, makeStyles } from '@material-ui/core';
import { DataGrid, GridCellParams } from '@material-ui/data-grid';
import React, { useMemo } from 'react';

import formatMoney from '../../../helpers/formatMoney';

import CharityDonationsReportSummary from '../../../types/CharityDonationsReportSummary';
import Nullable from '../../../types/Nullable';
import SiteData from '../../../types/SiteData';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  table: { border: 'none' },
  tableHeading: {
    marginLeft: theme.spacing(3),
  },
}));

interface Props {
  charityDonationsSummaryData?: CharityDonationsReportSummary[]
  siteData: Nullable<SiteData>
}

const CharityDonationsSummary = ({
  charityDonationsSummaryData,
  siteData,
}: Props) => {
  const classes = useStyles();

  const formatCharityDonationsData = useMemo(() => {
    if (charityDonationsSummaryData == null) return [];
    return charityDonationsSummaryData.sort((a, b) => (
      b.totalValues - a.totalValues
    )).map((row) => ({ ...row, id: row.name }));
  }, [charityDonationsSummaryData]);

  const columns = [
    { field: 'name', headerName: 'Charity name', flex: 1.5 },
    {
      field: 'totalValues',
      headerName: 'Donation total',
      flex: 1,
      renderCell: (params: GridCellParams) => formatMoney(
        params.row.totalValues,
        siteData?.currency,
      ),
    },
    {
      field: 'chargeValues',
      headerName: 'Charges',
      flex: 1,
      renderCell: (params: GridCellParams) => formatMoney(
        params.row.chargeValues,
        siteData?.currency,
      ),
    },
    {
      field: 'payoutValues',
      headerName: 'Donation payout',
      flex: 1,
      renderCell: (params: GridCellParams) => formatMoney(
        params.row.payoutValues,
        siteData?.currency,
      ),
    },
  ];

  return (
    <Paper className={classes.container}>
      <Typography className={classes.tableHeading} variant="h6">Charity donations report</Typography>
      <DataGrid
        loading={charityDonationsSummaryData == null}
        className={classes.table}
        autoHeight
        columns={columns}
        rows={formatCharityDonationsData}
        rowsPerPageOptions={[5, 10, 25]}
        pageSize={5}
        localeText={{ noRowsLabel: 'No charity donations data to display' }}
      />
    </Paper>
  );
};

export default CharityDonationsSummary;
