import React, { useCallback, useMemo, useState } from 'react';

import MaterialTable from 'material-table';
import { Grid } from '@material-ui/core';

import RemoveItemDialog from '../../../components/RemoveItemDialog/RemoveItemDialog';

import useUrlParams from '../../../hooks/useUrlParams';

import CloudPrinterData from '../../../types/CloudPrinterData';
import Label from '../../../types/Label';
import Nullable from '../../../types/Nullable';

type Props = {
  tableData: CloudPrinterData[]
  onAdd: () => void
  onEdit: (data: CloudPrinterData) => void
  onRemove: (id: string) => void
  labels: Label[]
};

const CloudPrintersTable = ({
  tableData,
  onAdd,
  onEdit,
  onRemove,
  labels,
}: Props) => {
  const [itemToRemove, setItemToRemove] = useState<Nullable<CloudPrinterData>>(null);

  const {
    values: [page, search],
    setters: [onPageChange, onSearchChange],
  } = useUrlParams(['page', 'search']);

  const columns = [
    { title: 'Id', field: 'id' },
    { title: 'Name', field: 'name' },
    {
      title: 'Reporting Labels',
      field: 'reportingLabels',
      render: (rowData: CloudPrinterData) => {
        if (rowData.reportingLabels == null) return 'Not Set';
        return rowData.reportingLabels
          .map((label, i) => `${labels
            .find(({ id }) => id === label)?.text || ''} ${(i + 1) !== rowData.reportingLabels.length ? ' ,' : ''}`);
      },
    },
  ];

  const handleRemove = useCallback(() => {
    if (itemToRemove && itemToRemove.id) {
      onRemove(itemToRemove.id);
    }
  }, [onRemove, itemToRemove]);

  const openRemoveDialog = useCallback(setItemToRemove, [setItemToRemove]);

  const closeRemoveDialog = useCallback(() => setItemToRemove(null), [setItemToRemove]);

  const description = useMemo(() => (itemToRemove
    ? `Are you sure you want to remove ${itemToRemove.name}`
    : ''), [itemToRemove]);

  return (
    <Grid>
      <MaterialTable
        title="Cloud Printers"
        columns={columns}
        data={tableData}
        actions={[
          {
            icon: 'add',
            tooltip: 'Add Cloud Printer',
            isFreeAction: true,
            onClick: onAdd,
          },
          (rowData) => ({
            icon: 'edit',
            tooltip: 'Edit Cloud Printer',
            onClick: () => onEdit(rowData),
          }),
          (rowData) => ({
            icon: 'delete',
            tooltip: 'Remove Cloud Printer',
            onClick: () => openRemoveDialog(rowData),
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
          initialPage: Number(page || 0),
          searchText: search || '',
        }}
        onSearchChange={onSearchChange}
        onChangePage={(newPage) => onPageChange(String(newPage))}
      />
      <RemoveItemDialog
        title="Remove Cloud Printer"
        description={description}
        open={itemToRemove != null}
        onRemove={handleRemove}
        handleClose={closeRemoveDialog}
      />
    </Grid>
  );
};

export default CloudPrintersTable;
