import getReconcilicationPayoutDetailAmount from './getReconcilicationPayoutDetailAmount';

import getLabelTextFromId from '../../../helpers/getLabelTextFromId';
import capitalize from '../../../helpers/capitalize';
import downloadFile from '../../../helpers/downloadFile';

import ReconciliationReportData from '../types/ReconciliationReportData';
import Label from '../../../types/Label';
import SiteData from '../../../types/SiteData';
import Nullable from '../../../types/Nullable';

const downloadReconciliationReport = (
  reconcilicationData: ReconciliationReportData[],
  payoutDetailTitles: string[],
  siteLabels: Label[],
  siteName: Nullable<SiteData['name']>,
  site: Nullable<SiteData['site']>,
  period: string,
) => {
  const getVendorName = (vendorId: string) => {
    const name = getLabelTextFromId(vendorId, siteLabels);
    if (name != null) return name;
    return vendorId === site
      ? siteName
      : vendorId;
  };
  const formattedDetailTitles = payoutDetailTitles.map((title) => `${capitalize(title)} Payout (£)`);
  const reportHeadings: string = ['Vendor', 'Transactions', 'Total Sales (£)', 'Tips (£)', 'Other Charges (£)', 'Charity Donations (£)', 'Gift Cards Sold (£)', 'Fees net of VAT (£)', 'Fees VAT (£)', 'Commission Received (£)', 'Commission Paid (£)', 'Total Payout (£)'].concat(formattedDetailTitles).join(',');

  const reconciliationReport = reconcilicationData.map((vendorDetails) => {
    const payoutDetails = payoutDetailTitles.map((payoutName) => (
      getReconcilicationPayoutDetailAmount(
        reconcilicationData, vendorDetails.vendorId, payoutName,
      ).toFixed(2)
    ));
    return [
      getVendorName(vendorDetails.vendorId),
      vendorDetails.transactions,
      vendorDetails.totalSales.toFixed(2),
      vendorDetails.tips.toFixed(2),
      vendorDetails.serviceCharge.toFixed(2),
      vendorDetails.charityDonations.toFixed(2),
      vendorDetails.giftCardsSold.toFixed(2),
      vendorDetails.netPeaziFees.toFixed(2),
      vendorDetails.peaziFeesVat.toFixed(2),
      vendorDetails.commissionReceived.toFixed(2),
      vendorDetails.commissionPaid.toFixed(2),
      vendorDetails.payout.toFixed(2),
      ...payoutDetails,
    ].join(',');
  });

  const fileName = `Reconciliation Report ${reconcilicationData.length === 1 ? `${getVendorName(reconcilicationData[0].vendorId)}_` : ''}${period.replace(',to,', ' to ')}.csv`;

  return downloadFile({
    data: [
      reportHeadings,
      ...reconciliationReport,
    ].join('\n'),
    fileName,
    fileType: 'text/csv',
  });
};

export default downloadReconciliationReport;
