import React from 'react';
import {
  Paper,
  makeStyles,
  Typography,
  Box,
} from '@material-ui/core';
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
} from 'recharts';

interface Props {
  rushTimeData: { hour: string; count: number }[];
  dateRangeText: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
    height: 300,
  },
  titleBox: {
    display: 'inline',
  },
  titleContainer: {
    marginBottom: theme.spacing(2),
  },
}));

const RushTimeSection = ({ rushTimeData, dateRangeText }: Props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.container}>
      <Typography className={classes.titleContainer}>
        <Box className={classes.titleBox} fontWeight="fontWeightBold">Rush Time</Box>
        &nbsp;–&nbsp;
        {dateRangeText}
      </Typography>
      <ResponsiveContainer width="100%" height="90%">
        <BarChart
          data={rushTimeData}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="hour" />
          <YAxis />
          <Bar dataKey="count" fill="#03DAC6" />
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default RushTimeSection;
