import React, { useMemo } from 'react';

import moment from 'moment';

import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { BlockOutlined, CheckCircleOutline } from '@material-ui/icons';

import PaymentMethodChip from '../PaymentMethodChip/PaymentMethodChip';
import OrderStatus from '../OrderStatus/OrderStatus';
import OrderItemStatus from '../OrderItemStatus/OrderItemStatus';
import ProductDescription from '../ProductDescription/ProductDescription';
import RefundList from './components/RefundList';

import formatMoney from '../../helpers/formatMoney';
import capitalize from '../../helpers/capitalize';
import getPaymentMethod from './helpers/getPaymentMethod';
import getLastFour from './helpers/getLastFour';
import getPaymentLabel from '../../helpers/getPaymentLabel';

import OrderData from '../../types/OrderData';
import Label from '../../types/Label';
import SiteData from '../../types/SiteData';
import OrderPaymentType from '../../types/OrderPaymentType';
import Nullable from '../../types/Nullable';
import BalancePaymentMethod from '../../types/BalancePaymentMethod';
import PaymentMethod from '../../types/PaymentMethod';

type Props = {
  selectedOrder: OrderData
  reportingLabels: Label[]
  siteData: Nullable<SiteData>
};

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  boldText: {
    fontWeight: 700,
  },
  paper: {
    minHeight: 250,
  },
  paperSection: {
    margin: 10,
  },
  status: {
    marginLeft: 10,
  },
  paymentListItemText: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
  },
}));

const OrderList = ({ selectedOrder, reportingLabels, siteData }: Props) => {
  const classes = useStyles();
  const {
    amountRefunded,
    discount,
    paymentIntent,
    status,
    paymentMethod,
    payments,
  } = selectedOrder;

  const isOrderRefunded = useMemo(() => status === 'full-refund' || status === 'partial-refund', [status]);
  const lastFour = useMemo(() => getLastFour(paymentIntent), [paymentIntent]);

  const paymentMethodUsed: Nullable<OrderPaymentType> | string = useMemo(() => {
    if (payments != null && payments.length > 1) {
      return 'Multiple';
    }
    if (payments != null && payments.length === 1) {
      const [payment] = payments;
      if (payment.type === BalancePaymentMethod.Custom) return payment.name;
      if (payment.type === BalancePaymentMethod.GiftCard) return OrderPaymentType.GiftCard;
      if (payment.type === BalancePaymentMethod.Stripe) {
        if (payment.paymentMethod === PaymentMethod.Terminal) return OrderPaymentType.CardPresent;
        return OrderPaymentType.Card;
      }
    }
    if (paymentIntent != null) return getPaymentMethod(paymentIntent);
    return paymentMethod || 'Other';
  }, [paymentIntent]);

  const tipItem = useMemo(() => selectedOrder.order.find((o) => o.tip), [selectedOrder]);
  const serviceCharge = useMemo(() => selectedOrder.order
    .find((o) => o.serviceCharge), [selectedOrder]);

  const charityDonation = useMemo(() => selectedOrder.order
    .find((o) => o.charityDonation), [selectedOrder]);

  const itemTipShareAmount = useMemo(() => {
    const items = selectedOrder.order.filter((o) => (
      !o.tip && !o.discount && !o.serviceCharge && !o.charityDonation
    ));

    return items.reduce((partialSum, { tipValue }) => partialSum + (tipValue || 0), 0);
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <List disablePadding>
          {selectedOrder.order.filter((o) => (
            !o.tip && !o.discount && !o.serviceCharge && !o.charityDonation
          ))
            .map((product) => (
              <ListItem divider key={product.cartId} className={classes.listItem}>
                <ListItemText
                  primary={`${product.quantity}x ${product.name}`}
                  secondary={(
                    <>
                      <ProductDescription
                        product={product}
                        reportingLabels={reportingLabels}
                        currency={selectedOrder.currency}
                      />
                      {product.status != null
                        ? <OrderItemStatus status={product.status} />
                        : null}
                    </>
                  )}
                  primaryTypographyProps={{ className: 'productInfo' }}
                />
                <Typography variant="body2">{formatMoney(product.price, selectedOrder.currency)}</Typography>
              </ListItem>
            ))}
          {tipItem != null && (
            <ListItem className={classes.listItem}>
              <ListItemText
                primary={tipItem.name}
                secondary={<ProductDescription product={tipItem} />}
              />
              <Typography variant="body2">{formatMoney((tipItem.price + itemTipShareAmount), selectedOrder.currency)}</Typography>
            </ListItem>
          )}
          {(tipItem == null && selectedOrder.tipAmount != null && selectedOrder.tipAmount > 0) && (
            <ListItem className={classes.listItem}>
              <ListItemText primary="Tip" />
              <Typography variant="body2">{formatMoney(selectedOrder.tipAmount, selectedOrder.currency)}</Typography>
            </ListItem>
          )}
          {serviceCharge && (
            <ListItem className={classes.listItem}>
              <ListItemText
                primary={serviceCharge.name}
                secondary={<ProductDescription product={serviceCharge} />}
              />
              <Typography variant="body2">{formatMoney(serviceCharge.price, selectedOrder.currency)}</Typography>
            </ListItem>
          )}
          {charityDonation && (
            <ListItem className={classes.listItem}>
              <ListItemText
                primary={`Charity Donation: ${charityDonation.name}`}
                secondary={<ProductDescription product={charityDonation} />}
              />
              <Typography variant="body2">{formatMoney(charityDonation.price, selectedOrder.currency)}</Typography>
            </ListItem>
          )}
          {discount != null && (
            <ListItem className={classes.listItem}>
              <ListItemText primary={`${discount.name || 'Discount'} - ${discount.code}`} />
              <Typography variant="body2">
                {`-${formatMoney(discount.discountAmount || 0, selectedOrder.currency)}`}
              </Typography>
            </ListItem>
          )}
          <ListItem className={classes.listItem}>
            <ListItemText primary="Total" className={classes.boldText} />
            <Typography variant="subtitle1" className={classes.boldText}>
              {formatMoney(selectedOrder.total, selectedOrder.currency)}
            </Typography>
          </ListItem>
          {payments != null && payments.length > 0
            ? payments.map((payment) => (
              <ListItem key={payment.id} className={classes.listItem}>
                <ListItemText
                  primary={(
                    <Typography className={classes.paymentListItemText}>
                      {getPaymentLabel(payment)}
                      &nbsp;Payment
                      &nbsp;
                      {(payment.isPaymentComplete
                        ? <CheckCircleOutline color="primary" />
                        : <BlockOutlined color="error" />
                      )}
                    </Typography>
                  )}
                />
                <Typography variant="subtitle2">
                  {formatMoney(payment.amount, selectedOrder.currency)}
                </Typography>
              </ListItem>
            ))
            : null}
          <RefundList orderData={selectedOrder} />
        </List>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Paper className={classes.paper} variant="outlined">
          <div className={classes.paperSection}>
            <Grid container direction="row">
              <Typography variant="h6" gutterBottom>Order Details</Typography>
            </Grid>
            <Typography variant="body1" gutterBottom>{`Order Number: #${selectedOrder.orderNumber}`}</Typography>
            {selectedOrder.tableNumber && <Typography variant="body1" gutterBottom>{`${siteData?.locationDescriptor || 'Table Number'}: ${selectedOrder.tableNumber}`}</Typography>}
            {selectedOrder.collectionNumber && <Typography variant="body1" gutterBottom>{`Collection Number: ${selectedOrder.collectionNumber}`}</Typography>}
            {lastFour && <Typography variant="body1" gutterBottom>{`Last Four: ${lastFour}`}</Typography>}
            <Typography variant="body1" gutterBottom>
              {`Order Placed: ${moment(selectedOrder.orderTime).format('LLLL')}`}
            </Typography>
            {selectedOrder.onlineOrder && (
              <Typography variant="body1" gutterBottom>
                {`${capitalize(selectedOrder?.onlineType || '')} Slot: ${moment(`${siteData?.allDaySlots
                  ? `${selectedOrder.slotDay}`
                  : `${selectedOrder.slotDay} ${selectedOrder.slotTime}`}`).format(siteData?.allDaySlots ? 'dddd, MMMM Do, YYYY' : 'LLLL')}`}
              </Typography>
            )}
            <Typography variant="body1" gutterBottom>
              {`Total cost: ${formatMoney(selectedOrder.total, selectedOrder.currency)}`}
            </Typography>
            {isOrderRefunded && paymentIntent && (
              <Typography variant="body1" gutterBottom>
                {`Total refunded: ${formatMoney(paymentIntent.amount_refunded / 100, selectedOrder.currency)}`}
              </Typography>
            )}
            {isOrderRefunded && amountRefunded && (
              <Typography variant="body1" gutterBottom>
                {`Total refunded: ${formatMoney(amountRefunded, selectedOrder.currency)}`}
              </Typography>
            )}
            {selectedOrder.notes && (
              <Typography variant="body1" gutterBottom>
                {`Notes: ${selectedOrder.notes}`}
              </Typography>
            )}
            {paymentMethodUsed && <PaymentMethodChip method={paymentMethodUsed} />}
            <OrderStatus status={selectedOrder.status} />
          </div>
        </Paper>
      </Grid>
      {selectedOrder.userDetails != null && selectedOrder.userDetails.name != null && (
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper} variant="outlined">
            <div className={classes.paperSection}>
              <Typography variant="h6" gutterBottom>
                User Details
              </Typography>
              <Typography gutterBottom>{`Customer Name: ${selectedOrder.userDetails.name || 'N/A'}`}</Typography>
              <Typography gutterBottom>{selectedOrder.userDetails.email}</Typography>
              <Typography gutterBottom>{selectedOrder.userDetails.phoneNumber}</Typography>
              <Typography gutterBottom>{selectedOrder.userDetails.selectedAddress}</Typography>
            </div>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default OrderList;
