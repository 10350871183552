const getColour = (index: number) => {
  const colours = [
    '#065535',
    '#ff80ed',
    '#ff0000',
    '#fa8072',
    '#00ffff',
    '#ffa500',
    '#bada55',
    '#666666',
    '#0000ff',
    '#c39797',
    '#800000',
    '#008000',
    '#8a2be2',
    '#794044',
    '#a0db8e',
    '#420420',
    '#00ff7f',
    '#ff4040',
    '#3399ff',
    '#800080',
    '#66cdaa',
    '#ff00ff',
    '#daa520',
    '#8b0000',
    '#66cccc',
    '#0a75ad',
    '#b4eeb4',
    '#468499',
    '#c0d6e4',
    '#00ff00',
  ];

  return colours[index];
};

export default getColour;
