import CollectionTime from '../../../types/CollectionTime';

const getDefaultCollectionTimes = (): CollectionTime[] => [...new Array(7)].map((d, i) => ({
  firstCollection: '00:00',
  lastCollection: '00:00',
  leadTime: 0,
  day: i,
}));

export default getDefaultCollectionTimes;
