import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Grid, IconButton, Tooltip, makeStyles,
} from '@material-ui/core';
import { DataGrid, GridColumns, GridCellParams } from '@material-ui/data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import Toolbar from './components/Toolbar';

import hasPermission from '../../helpers/has-permission';
import formatMoney from '../../helpers/formatMoney';

import AppUrl from '../../types/AppUrl';
import RoleRestrictedAction from '../../types/RoleRestrictedAction';
import Nullable from '../../types/Nullable';
import Discount from '../../types/Discount';
import User from '../../types/User';
import SiteData from '../../types/SiteData';
import DiscountStatus from '../../types/DiscountStatus';
import ReduxState from '../../types/ReduxState';
import RemoveItemDialog from '../RemoveItemDialog/RemoveItemDialog';

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: 10,
    height: 500,
    paddingBottom: theme.spacing(5),
  },
}));

type Props = {
  discounts: Discount[]
  onUpdate: (id: string, item: { status: DiscountStatus }) => void
  onRemove: (id: string) => void
};

const DiscountsTable = ({ discounts, onUpdate, onRemove }: Props) => {
  const siteData = useSelector<ReduxState, Nullable<SiteData>>((state) => state.settings.get('SETTINGS'));
  const user = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));
  const [itemToRemove, setItemToRemove] = useState<Nullable<{ id: string, name: string }>>(null);
  const [searchText, setSearchText] = useState<string>('');
  const classes = useStyles();
  const history = useHistory();

  const handleRemove = useCallback(() => {
    if (itemToRemove && itemToRemove.id) {
      onRemove(itemToRemove.id);
    }
  }, [onRemove, itemToRemove]);

  const openRemoveDialog = useCallback(setItemToRemove, [setItemToRemove]);

  const closeRemoveDialog = useCallback(() => setItemToRemove(null), [setItemToRemove]);

  const description = useMemo(() => (itemToRemove
    ? `Are you sure you want to remove ${itemToRemove.name}`
    : ''), [itemToRemove]);

  const handleSearchTextChange = useCallback((text: string) => setSearchText(text), []);

  const handleSearchTextClear = useCallback(() => setSearchText(''), []);

  const handleEdit = useCallback((id: string) => history.push(AppUrl.EditDiscountPage.replace(':discountId', id)), []);

  const discountsList = useMemo(() => {
    if (searchText == null || searchText.length === 0) return discounts;

    const searchTerm = searchText.toLowerCase();

    return discounts.filter((discount) => discount.name.toLowerCase().includes(searchTerm));
  }, [discounts, searchText]);

  const columns: GridColumns = useMemo(() => [
    { field: 'name', headerName: 'Name', width: 325 },
    {
      field: 'value',
      headerName: 'Amount',
      width: 150,
      renderCell: (params: GridCellParams) => (params.row.type === 'fixedValue'
        ? formatMoney(params.row.value, siteData?.currency)
        : `${params.row.value * 100}%`),
    },
    {
      field: 'from',
      headerName: 'Valid From',
      width: 150,
      renderCell: (params: GridCellParams) => (params.row.from !== '' ? params.row.from : '-'),
    },
    {
      field: 'to',
      headerName: 'Valid To',
      width: 150,
      renderCell: (params: GridCellParams) => (params.row.to !== '' ? params.row.to : '-'),
    },
    {
      field: 'day',
      headerName: 'Days of Week',
      width: 200,
      renderCell: (params: GridCellParams) => (params.row.days && params.row.days.length > 0
        ? params.row.days.join(', ')
        : 'All'),
    },
    {
      field: 'codes',
      headerName: 'No. Codes',
      width: 150,
      renderCell: (params: GridCellParams) => params.row.codes.length,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: (params: GridCellParams) => (
        <Grid>
          <Tooltip title={params.row.status === DiscountStatus.On ? 'Switch Discount Off' : 'Switch Discount On'}>
            <IconButton
              onClick={() => onUpdate(params.row.id || '', {
                status: params.row.status === DiscountStatus.On
                  ? DiscountStatus.Off
                  : DiscountStatus.On,
              })}
              color="inherit"
              disabled={!hasPermission(RoleRestrictedAction.EditDiscountCode, user)}
            >
              {params.row.status === DiscountStatus.On ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit Discount">
            <IconButton
              onClick={() => handleEdit(params.row.id)}
              color="inherit"
              disabled={!hasPermission(RoleRestrictedAction.EditDiscountCode, user)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Remove Discount">
            <IconButton
              onClick={() => openRemoveDialog({ id: params.row.id, name: params.row.name })}
              color="inherit"
              disabled={!hasPermission(RoleRestrictedAction.RemoveDiscountCode, user)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ),
    },
  ], []);

  return (
    <Grid className={classes.table}>
      <DataGrid
        components={{ Toolbar }}
        rows={discountsList}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        componentsProps={{
          toolbar: {
            value: searchText,
            onChange: handleSearchTextChange,
            clearSearch: handleSearchTextClear,
            user,
          },
        }}
      />
      <RemoveItemDialog
        title="Remove Discount"
        description={description}
        open={itemToRemove != null}
        onRemove={handleRemove}
        handleClose={closeRemoveDialog}
      />
    </Grid>
  );
};

export default DiscountsTable;
