import { Map } from 'immutable';
import {
  GET_USERS_START,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
} from '../constants/actions';
import STORE_KEYS from '../constants/store-keys';

const {
  IS_USERS_LOADING,
  NUMBER_OF_USERS,
  USERS,
  USERS_LOADING_ERROR,
} = STORE_KEYS.USERS;

const initialState = {};
initialState[IS_USERS_LOADING] = false;
initialState[NUMBER_OF_USERS] = 0;
initialState[USERS] = [];
initialState[USERS_LOADING_ERROR] = '';
const initialStateMap = Map(initialState);

export default (state = initialStateMap, action) => {
  switch (action.type) {
    case GET_USERS_START:
      return state
        .set(IS_USERS_LOADING, true)
        .set(NUMBER_OF_USERS, 0)
        .set(USERS, [])
        .set(USERS_LOADING_ERROR, '');
    case GET_USERS_SUCCESS:
      return state
        .set(IS_USERS_LOADING, false)
        .set(NUMBER_OF_USERS, action.numberOfUsers)
        .set(USERS, action.users);
    case GET_USERS_ERROR:
      return state
        .set(IS_USERS_LOADING, false)
        .set(USERS_LOADING_ERROR, action.message);
    default:
      return state;
  }
};
