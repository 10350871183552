enum RoleRestrictedAction {
  ViewOrder,
  ProcessOrder,
  CreateSubCategory,
  CreateDiscountCode,
  ViewDiscountCode,
  EditDiscountCode,
  RemoveDiscountCode,
  RefundOrder,
  SetProductOutOfStock,
  SetProductShow,
  RemoveProduct,
  ViewProduct,
  AddProduct,
  UploadProductCSV,
  DownloadProductCSV,
  GenerateSlotTimes,
  CreateSlotTime,
  EditSlotTime,
  RemoveSlotTime,
  CreateSlotDay,
  RemoveSlotDay,
  ConfigureStripe,
  EditSiteSettings,
  CreateCategory,
  EditCategory,
  EditProduct,
  EditReportingLabelText,
  ViewLabelPaymentSettings,
  SetLabelPaymentEnabled,
  SetLabelPaymentCommission,
  SetLabelVendorVatStatus,
  ManageLabelStripeAccount,
  SetSiteOffline,
  ControlAllQuickSettings,
  SetLabelOffline,
  ViewServiceChargeSummary,
  ViewSiteTipShare,
  EditCategorySequence,
  ViewDiscount,
  ViewCloudPrinter,
  SetLabelVatStatusAfterInitialSetup,
  ViewTransactionReportSummaries,
  CreateNewSearchLabels,
  ViewLabelTips,
}

export default RoleRestrictedAction;
