import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from '@material-ui/core';
import SiteAddress from '../../../types/SiteAddress';
import Country from '../../../types/Country';

interface Props {
  open: boolean;
  address: SiteAddress;
  onCancelCick: () => void;
  onSubmitClick: (address: SiteAddress) => void;
}

const useStyles = makeStyles((theme) => ({
  buttonSpacer: {
    marginRight: theme.spacing(1),
  },
  input: {
    margin: theme.spacing(1, 0),
  },
}));

const EditAddressDialog = ({
  open,
  address,
  onCancelCick,
  onSubmitClick,
}: Props) => {
  const classes = useStyles();

  const [newAddress, setNewAddress] = useState<SiteAddress>(address);

  const handleLine1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;
    setNewAddress((currentValue) => ({ ...currentValue, line1: value }));
  };

  const handleLine2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;
    if (value.length > 0) {
      setNewAddress((currentValue) => ({ ...currentValue, line2: value }));
    } else {
      setNewAddress(({
        line1,
        city,
        country,
        postCode,
      }) => ({
        line1,
        city,
        country,
        postCode,
      }));
    }
  };

  const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;
    setNewAddress((currentValue) => ({ ...currentValue, city: value }));
  };

  const handlePostCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;
    setNewAddress((currentValue) => ({ ...currentValue, postCode: value }));
  };

  const handleCountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { target: { value } } = event;
    const newCountry = value as Country;
    setNewAddress((currentValue) => ({ ...currentValue, country: newCountry }));
  };

  const isFormValid = (
    newAddress.line1
    && newAddress.city
    && newAddress.postCode
    && newAddress.country
  );

  return (
    <Dialog open={open}>
      <DialogTitle>Edit Address</DialogTitle>
      <DialogContent>
        <TextField
          className={classes.input}
          label="Address Line 1"
          variant="outlined"
          fullWidth
          value={newAddress.line1}
          onChange={handleLine1Change}
        />
        <TextField
          className={classes.input}
          label="Address Line 2"
          variant="outlined"
          fullWidth
          value={newAddress.line2}
          onChange={handleLine2Change}
        />
        <TextField
          className={classes.input}
          label="City"
          variant="outlined"
          fullWidth
          value={newAddress.city}
          onChange={handleCityChange}
        />
        <TextField
          className={classes.input}
          label="Post Code"
          variant="outlined"
          fullWidth
          value={newAddress.postCode}
          onChange={handlePostCodeChange}
        />
        <FormControl
          className={classes.input}
          fullWidth
          variant="outlined"
        >
          <InputLabel id="country-select-label">Country</InputLabel>
          <Select
            labelId="country-select-label"
            value={newAddress.country}
            onChange={handleCountryChange}
            label="Country"
          >
            <MenuItem value={Country.GB}>Great Britain</MenuItem>
            <MenuItem value={Country.ES}>Spain</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.buttonSpacer}
          variant="outlined"
          color="secondary"
          onClick={onCancelCick}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSubmitClick(newAddress)}
          disabled={!isFormValid}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAddressDialog;
