import React,
{
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import MaterialTable from 'material-table';
import { Grid } from '@material-ui/core';

import RemoveItemDialog from '../../../components/RemoveItemDialog/RemoveItemDialog';

import nameToUrl from '../../../helpers/name-to-url';
import hasPermission from '../../../helpers/has-permission';
import useUrlParams from '../../../hooks/useUrlParams';

import RoleRestrictedAction from '../../../types/RoleRestrictedAction';
import Category from '../../../types/Category';
import User from '../../../types/User';
import Nullable from '../../../types/Nullable';

type Props = {
  siteMap: Category[]
  onCreate: () => void
  onRemove: (id: string) => void
  onEdit: (category: Category) => void
  editableCategories: Category['category'][]
  user: User
};

const SiteMapTable = ({
  siteMap,
  onCreate,
  onRemove,
  onEdit,
  editableCategories,
  user,
}: Props) => {
  const [itemToRemove, setItemToRemove] = useState<Nullable<Category>>(null);
  const history = useHistory();

  const {
    values: [page, search],
    setters: [onPageChange, onSearchChange],
  } = useUrlParams(['page', 'search']);

  const handleRemove = useCallback(() => {
    if (itemToRemove != null) {
      onRemove(itemToRemove.category);
    }
    setItemToRemove(null);
  }, [onRemove, itemToRemove]);

  const openRemoveDialog = useCallback(setItemToRemove, [setItemToRemove]);

  const closeRemoveDialog = useCallback(() => setItemToRemove(null), [setItemToRemove]);

  const description = useMemo(() => (itemToRemove
    ? `Are you sure you want to remove ${itemToRemove.category}`
    : ''), [itemToRemove]);

  return (
    <Grid>
      <MaterialTable
        title="Site Map"
        columns={[
          { title: 'Category', field: 'category' },
          { title: 'Sequence', field: 'sequence' },
          { title: 'Sub Categories', field: 'subCategories', render: (rowData) => rowData.subCategories.length },
        ]}
        data={siteMap}
        actions={[
          (rowData) => ({
            icon: 'visibility',
            tooltip: 'View Category',
            onClick: () => history.push(`/site-map/${nameToUrl(rowData.category)}`),
          }),
          {
            icon: 'add',
            tooltip: 'Add Category',
            isFreeAction: true,
            disabled: !hasPermission(RoleRestrictedAction.CreateCategory, user),
            onClick: onCreate,
          },
          (rowData) => ({
            icon: 'delete',
            tooltip: 'Remove Category',
            disabled: (
              !hasPermission(RoleRestrictedAction.CreateCategory, user)
              || !editableCategories.includes(rowData.category)
            ),
            onClick: () => openRemoveDialog(rowData),
          }),
          (rowData) => ({
            icon: 'edit',
            tooltip: 'Edit Category',
            disabled: (
              !hasPermission(RoleRestrictedAction.CreateCategory, user)
              || !editableCategories.includes(rowData.category)
            ),
            onClick: () => onEdit(rowData),
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
          initialPage: Number(page || 0),
          pageSize: 10,
          searchText: search || '',
        }}
        onSearchChange={onSearchChange}
        onChangePage={(newPage) => onPageChange(String(newPage))}
      />
      <RemoveItemDialog
        title="Remove Category"
        description={description}
        open={itemToRemove != null}
        onRemove={handleRemove}
        handleClose={closeRemoveDialog}
      />
    </Grid>
  );
};

export default SiteMapTable;
