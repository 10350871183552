import { firestore } from 'firebase/app';

import isDiscount from './isDiscount';

import Discount from '../types/Discount';
import SiteData from '../types/SiteData';
import Nullable from '../types/Nullable';

const initialiseDiscountsListener = (
  site: SiteData['site'],
  handleSetDiscounts: (discount: Discount[]) => void,
  handleSetError: (error: Nullable<Error>) => void,
) => (
  firestore()
    .collection('discounts')
    .where('site', '==', site)
    .onSnapshot((querySnapshot) => {
      const queryResults: Discount[] = [];
      querySnapshot.forEach((doc) => {
        const data = {
          ...doc.data(), id: doc.id,
        };
        if (isDiscount(data)) queryResults.push(data);
      });

      handleSetDiscounts(queryResults);
    },
    () => {
      handleSetError(new Error(`Discounts for ${site} could not be loaded successfully.`));
    })
);

export default initialiseDiscountsListener;
