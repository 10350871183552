import ReduxState from '../../../types/ReduxState';
import OrderData from '../../../types/OrderData';

const ordersSelector = (state: ReduxState): {
  all: { inhouse: OrderData[], online: OrderData[] },
  outstanding: { inhouse: OrderData[], online: OrderData[] },
} => (
  {
    all: {
      inhouse: state.orders.get('INHOUSE_ORDERS'),
      online: state.orders.get('ONLINE_ORDERS'),
    },
    outstanding: {
      inhouse: state.orders.get('UNPROCESSED_INHOUSE_ORDERS'),
      online: state.orders.get('UNPROCESSED_ONLINE_ORDERS'),
    },
  }
);

export default ordersSelector;
