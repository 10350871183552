import OrderItem from '../types/OrderItem';
import ProductData from '../types/ProductData';
import UserSiteConfig from '../types/UserSiteConfig';

const canUserViewProduct = (
  product: ProductData | OrderItem,
  userConfig: UserSiteConfig,
): boolean => {
  const userHasLabels = userConfig.reportingLabels != null && userConfig.reportingLabels.length > 0;
  const producthasLabels = product.reportingLabels != null && product.reportingLabels?.length > 0;

  if (!userHasLabels) {
    return true;
  }
  if (userHasLabels && !producthasLabels) {
    return false;
  }
  if (product.reportingLabels?.some((labelId) => userConfig.reportingLabels?.includes(labelId))) {
    return true;
  }
  return false;
};

export default canUserViewProduct;
