import React from 'react';

import {
  Button,
  Grid,
  makeStyles,
  Box,
  IconButton,
} from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import DownloadIcon from '@material-ui/icons/GetApp';

import DateField from '../../../components/date-field/date-field';
import downloadTextMessages from '../helpers/downloadTextMessages';

import TextMessageData from '../../../types/TextMessageData';

type Props = {
  textMessages: TextMessageData[];
  startDate: string;
  endDate: string;
  onStartDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEndDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRefreshButtonClick: () => Promise<void>;
};

const useStyles = makeStyles((theme) => ({
  toolbar: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dateField: {
    margin: theme.spacing(1),
  },
}));

const Toolbar = ({
  textMessages,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  onRefreshButtonClick,
}: Props) => {
  const classes = useStyles();

  return (
    <Grid className={classes.toolbar}>
      <Button
        style={{ margin: 5 }}
        startIcon={<DownloadIcon />}
        onClick={() => downloadTextMessages(textMessages, `text_messages_report_${startDate}_${endDate}.csv`)}
        variant="outlined"
        color="primary"
      >
        Download Text Messages
      </Button>
      <Box>
        <DateField
          textFieldClassName={classes.dateField}
          label="From"
          value={startDate}
          onChange={onStartDateChange}
        />
        <DateField
          textFieldClassName={classes.dateField}
          label="Until"
          value={endDate}
          onChange={onEndDateChange}
        />
        <IconButton
          onClick={onRefreshButtonClick}
        >
          <Refresh />
        </IconButton>
      </Box>
    </Grid>
  );
};

export default Toolbar;
