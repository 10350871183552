import firebase from 'firebase/app';

export default (site, file) => firebase
  .storage()
  .ref(site)
  .child(file.name)
  .put(file)
  .then((snapshot) => {
    const {
      bucket, fullPath, name, timeCreated,
    } = snapshot.metadata;
    return firebase
      .firestore()
      .collection('filetransformation')
      .doc()
      .set({
        fileName: name, bucket, fullPath, timeCreated, status: 'File Uploaded',
      });
  });
