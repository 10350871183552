import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  Button, Dialog, DialogTitle, DialogContent, DialogActions,
  TextField,
  Typography,
  makeStyles,
  CircularProgress,
  Box,
} from '@material-ui/core';

import updateStripeStatementDescriptor from '../../helpers/updateStripeStatementDescriptor';
import getStripeAccountDetails from '../../helpers/getStripeAccountDetails';

import InfoField from '../info-field/info-field';

import SiteData from '../../types/SiteData';
import Nullable from '../../types/Nullable';

const useStyles = makeStyles(() => ({
  loadingBox: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));

type Props = {
  stripeConnectId: SiteData['stripeConnect']
  fullWidthButton?: boolean
  normalWeightTitleFont?: boolean
};

const StripeStatementDescriptorDialog = ({
  stripeConnectId,
  fullWidthButton = false,
  normalWeightTitleFont = false,
}: Props) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [descriptor, setDescriptor] = useState<string>('');
  const [isLoadingDescriptor, setIsLoadingDescriptor] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(true);

  const updateDescriptorFromStripe = useCallback(async () => {
    if (stripeConnectId != null && stripeConnectId.length > 0) {
      try {
        setIsLoadingDescriptor(true);
        const {
          stripeAccountDetails: {
            statementDescriptor,
          },
        } = await getStripeAccountDetails(stripeConnectId);
        setDescriptor(statementDescriptor);
      } catch (err) {
        setErrorMessage('Could not fetch descriptor from Stripe');
        setDescriptor('');
      } finally {
        setIsLoadingDescriptor(false);
      }
    }
  }, [stripeConnectId]);

  useEffect(() => {
    updateDescriptorFromStripe();
  }, [stripeConnectId]);

  const handleClose = async () => {
    setIsOpen(false);
    await updateDescriptorFromStripe();
  };

  const handleSave = async () => {
    setErrorMessage(null);
    setIsButtonEnabled(false);
    try {
      await updateStripeStatementDescriptor(stripeConnectId, descriptor);
      handleClose();
    } catch (err) {
      if (
        typeof err === 'string'
      ) {
        setErrorMessage(err);
      } else {
        setErrorMessage('Error updating stripe statement descriptor');
      }
    } finally {
      setIsButtonEnabled(true);
    }
  };

  const handleDescriptorChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setDescriptor(value);
  };

  return (
    <>
      {isLoadingDescriptor ? (
        <Box className={classes.loadingBox}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <InfoField
            title="Stripe Statement Descriptor"
            normalWeightTitleFont={normalWeightTitleFont}
            info={descriptor}
          />
          <Button
            style={{ marginTop: 5 }}
            color="primary"
            variant="outlined"
            onClick={() => setIsOpen(true)}
            fullWidth={fullWidthButton}
          >
            Edit Stripe statement descriptor
          </Button>
        </>
      )}
      <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Stripe Statement Descriptor</DialogTitle>
        <DialogContent>
          <TextField
            style={{ width: 350, marginRight: 20, marginBottom: 20 }}
            value={descriptor}
            onChange={handleDescriptorChange}
            label="Statement Descriptor"
            variant="outlined"
          />
          {errorMessage && <Typography variant="body1" color="error">{errorMessage}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleClose}
            disabled={!isButtonEnabled}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleSave}
            disabled={!isButtonEnabled}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StripeStatementDescriptorDialog;
