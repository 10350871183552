import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';

import AuthUrl from '../../../types/AuthUrl';
import getAuthRouteComponent from '../../../helpers/getAuthRouteComponent';

import {
  AUTH_ROUTES,
} from '../../../constants/routes';
import NoMatchPage from '../../no-match-page/no-match-page';

const AuthRouter = () => (
  <Switch>
    {AUTH_ROUTES.map((route) => (
      <Route<{}, AuthUrl>
        key={route.url}
        path={route.url}
        component={getAuthRouteComponent(route)}
        exact
      />
    ))}
    <Route
      key="nomatch"
      path="nomatch"
      component={NoMatchPage}
      exact
    />
  </Switch>
);

export default AuthRouter;
