import firebase from 'firebase';

import Label from '../../../types/Label';
import SiteData from '../../../types/SiteData';

const editReportingLabel = (
  site: SiteData,
  updatedLabel: Label,
) => {
  const { id: siteId, reportingLabels } = site;
  if (!siteId || !reportingLabels) throw new Error('site settings are invalid');

  const updatedLabels = reportingLabels.map((label) => {
    if (label.id !== updatedLabel.id) return label;
    return updatedLabel;
  });
  const offlineLabels = site.offlineLabels || [];
  const shouldSwitchOffline = !updatedLabel.active && !offlineLabels.includes(updatedLabel.id);

  return firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .update(shouldSwitchOffline
      ? {
        reportingLabels: updatedLabels,
        offlineLabels: firebase.firestore.FieldValue.arrayUnion(updatedLabel.id),
      }
      : { reportingLabels: updatedLabels });
};

export default editReportingLabel;
