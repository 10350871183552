enum AppUrl {
  Home = '/',
  StripeSetup = '/stripesetup',
  StripeComplete = '/stripecomplete',
  Notifications = '/notifications',
  Notification = '/notification/:notificationId',
  Admin = '/admin',
  CloudPrinters = '/cloud-printers',
  UnprocessedOrders = '/admin/unprocessed-orders',
  UnpaidOrders = '/admin/unpaid-orders',
  Sites = '/admin/sites',
  CreateSite = '/admin/sites/create',
  Users = '/admin/users',
  AddUser = '/admin/users/add',
  EditUser = '/admin/users/:userId',
  UploadProducts = '/admin/sites/product-upload',
  TextMessages = '/admin/text-messages',
  QuickSettings = '/quick-settings',
  Settings = '/settings',
  Slots = '/settings/slots',
  SlotTimes = '/settings/slots/:day',
  Profile = '/profile',
  SiteMap = '/site-map',
  Category = '/site-map/:category',
  AddSubCategory = '/site-map/:category/subcategory/add',
  SubCategory = '/site-map/:category/:subCategory',
  EditSubCategory = '/site-map/:category/:subCategory/edit',
  Products = '/products',
  AddProduct = '/add-product',
  ViewProduct = '/products/:productId',
  EditProduct = '/products/:productId/edit',
  SiteOrders = '/orders',
  Reports = '/reports',
  SiteUnpaidOrders = '/unpaid-orders',
  RefundedOrders = '/refunded-orders',
  SiteUnprocessedOrders = '/unprocessed-orders',
  Discounts = '/discounts',
  AddDiscountPage = '/discounts/add',
  EditDiscountPage = '/discounts/:discountId',
  Orders = '/admin/orders',
  Order = '/orders/:orderNumber',
  SiteDashboard = '/dashboard',
  DispenseScreen = '/dispense-screen',
  CollectionScreen = '/collection-screen',
  ReportingLabelManagement = '/reporting-labels',
  ManageSiteUsers = '/manage-site-users',
  AddSiteUser = '/manage-site-users/add',
  EditSiteUser = '/manage-site-users/edit/:userId',
  AdminDashbaord = '/admin/dashboard',
  POSHome = '/pos',
  AddPOSUser = '/pos/add-pos-user',
  EditPosUser = '/pos/user/:userId',
  POSLocation = '/pos/:locationId',
  AddPosAdditionalPaymentType = '/pos/add-additional-payment-type',
  EditPosAdditionalPaymentType = '/pos/edit-additional-payment-type/:POSPaymentTypeId',
  Kiosk = '/kiosk',
  GiftCards = '/gift-cards',
  GiftCard = '/gift-cards/:giftCardId',
  AddGiftCardProduct = '/gift-cards/products/add',
  EditGiftCardProduct = '/gift-cards/products/:productId',
  Customers = '/customers',
  Customer = '/customers/:id',
}

export default AppUrl;
