import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  Button, Divider, Grid, Typography, capitalize, makeStyles,
} from '@material-ui/core';

import Spinner from '../../components/spinner/spinner';
import OrderList from '../../components/OrderList/OrderList';
import ReceiptDialog from '../../components/ReceiptDialog/ReceiptDialog';
import RefundConfirmation from './components/RefundConfirmation';
import RefundConfirmationByAmount from './components/RefundConfirmationByAmount';
import CashRefundConfirmation from './components/CashRefundConfirmation';
import RefundDialog from '../../components/RefundDialog/RefundDialog';

import apiRequest from '../../helpers/api-request';
import hasPermission from '../../helpers/has-permission';
import round from '../../helpers/round';
import initialiseOrderDataListener from '../../helpers/initialiseOrderDataListener';

import getSettings from '../../actions/get-settings';

import OrderData from '../../types/OrderData';
import SiteData from '../../types/SiteData';
import ReduxState from '../../types/ReduxState';
import Nullable from '../../types/Nullable';
import User from '../../types/User';
import BalancePaymentMethod from '../../types/BalancePaymentMethod';
import OrderStatusType from '../../types/OrderStatusType';
import RoleRestrictedAction from '../../types/RoleRestrictedAction';
import ProductType from '../../types/ProductType';

const useStyles = makeStyles(() => ({
  orderIncorrect: {
    display: 'flex',
    flexDirection: 'column',
    height: 300,
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleSection: {
    marginTop: 10,
    marginBottom: 10,
  },
  title: {
    flex: 1,
  },
  returnButton: {
    marginLeft: 10,
  },
}));

const OrderPage = () => {
  const [receiptDialogOpen, setReceiptDialogOpen] = useState<boolean>(false);
  const [refundDialogOpen, setRefundDialogOpen] = useState<boolean>(false);
  const user = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));
  const [orderData, setOrderData] = useState<Nullable<OrderData>>(null);
  const [error, setError] = useState<Nullable<Error>>(null);
  const isSettingsLoading = useSelector<ReduxState, boolean>((state) => state.settings.get('IS_SETTINGS_LOADING'));
  const settings = useSelector<ReduxState, Nullable<SiteData>>((state) => state.settings.get('SETTINGS'));
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams<{ orderNumber: string }>();
  const history = useHistory();

  const orderNumber = useMemo(() => params.orderNumber, [params]);

  useEffect(() => {
    if (orderNumber) {
      initialiseOrderDataListener(orderNumber, setOrderData, setError);
    }
  }, [orderNumber]);

  useEffect(() => {
    if (user != null) dispatch(getSettings(user.site));
  }, []);

  const handleRefundButtonClick = () => setRefundDialogOpen(true);

  const handleRefundDialogCloseClick = () => setRefundDialogOpen(false);

  const handleRefundDialogSubmit = async (
    items: { cartId: string; quantity: number; addOns: { plu: string; quantity: number }[] }[],
    paymentMethods: { id: string; amount: string }[],
    reason: string,
    additionalInfo: string,
  ) => {
    await apiRequest(
      `multiTenderOrder/${orderNumber}/refund`,
      'POST',
      {
        items,
        paymentMethods: paymentMethods.map(({ id, amount }) => ({ id, amount: Number(amount) })),
        reason,
        additionalInfo,
        user: user?.email || null,
      },
    );
  };

  const handleReceiptDialogOpen = useCallback(() => {
    setReceiptDialogOpen(true);
  }, []);

  const handleUserReceiptClick = useCallback(async () => {
    setReceiptDialogOpen(false);
  }, []);

  const handleReceiptClose = useCallback(() => {
    setReceiptDialogOpen(false);
  }, []);

  const reportingLabels = useMemo(() => {
    if (settings?.reportingLabels == null) return [];
    return settings.reportingLabels;
  }, [settings]);

  const refundableItems = useMemo(() => {
    if (orderData == null) return [];

    const { order: items } = orderData;

    const pricedItems = items.filter((item) => !item.discount);

    return pricedItems.map((item) => {
      const addOns = item.addOns.map((addOn) => {
        const discountedPrice = round(addOn.price - ((addOn.discountAmount || 0) / item.quantity));
        return {
          name: addOn.name,
          plu: String(addOn.plu),
          price: addOn.price,
          discountedPrice,
          quantity: item.quantity,
          quantityRefunded: addOn.quantityRefunded || 0,
        };
      });

      let itemName = item.name;
      if (item.tip && settings?.splitPayouts) {
        itemName = 'Tip (Site Share)';
      } else if (item.charityDonation) {
        itemName = `Charity Donation: ${itemName}`;
      } else if (item.type === ProductType.GiftCard) {
        itemName = `${itemName} (*)`;
      }

      return {
        name: itemName,
        cartId: item.cartId,
        price: item.price,
        discountedPrice: round(item.price - ((item.mainItemDiscountAmount || 0) / item.quantity)),
        discountedSubTotal: round(item.subTotal - ((item.discountAmount || 0) / item.quantity)),
        quantity: item.quantity,
        quantityRefunded: item.quantityRefunded || 0,
        tipValue: item.tipValue || 0,
        addOns,
        serviceCharge: item.serviceCharge || false,
        tip: item.tip || false,
        charityDonation: item.charityDonation || false,
        isGiftCard: item.type === ProductType.GiftCard,
      };
    });
  }, [orderData, settings]);

  const refundablePaymentMethods = useMemo(() => {
    if (orderData == null || orderData.payments == null) return [];

    const { payments } = orderData;
    return payments.map((payment) => {
      let label: string;
      if (payment.type === BalancePaymentMethod.GiftCard) {
        label = 'Gift Card';
      } else if (payment.type === BalancePaymentMethod.Stripe) {
        label = 'Card';
      } else {
        label = capitalize(payment.name);
      }
      return {
        id: payment.id,
        amount: payment.amount,
        amountRefunded: payment.amountRefunded,
        label,
      };
    });
  }, [orderData]);

  const isOrderDataForCurrentSite = useMemo(() => (
    orderData == null || orderData.site === user?.site
  ), [orderData, user]);

  const refundConfirmationDialog = useMemo(() => {
    if (
      user == null
      || orderData == null
      || settings == null
      || !isOrderDataForCurrentSite
    ) return null;

    if (orderData.payments != null) {
      const isDisabled = orderData.status === OrderStatusType.FullRefund
        || !hasPermission(RoleRestrictedAction.RefundOrder, user);
      return (
        <>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={handleRefundButtonClick}
            disabled={isDisabled}
          >
            Refund Order
          </Button>
        </>
      );
    }

    if (orderData.paymentIntent == null) {
      return <CashRefundConfirmation siteData={settings} orderData={orderData} user={user} />;
    }
    const { refundByItem } = settings;
    return refundByItem === false
      ? (
        <RefundConfirmationByAmount
          siteData={settings}
          orderData={orderData}
          user={user}
        />
      )
      : (
        <RefundConfirmation
          siteData={settings}
          orderData={orderData}
          user={user}
        />
      );
  }, [orderData, settings, user]);

  if (isSettingsLoading) return <Spinner />;

  if (error) {
    return (
      <Grid>
        <Typography variant="body1" color="error" gutterBottom>
          Something went wrong, please try again or contact support.
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid>
      <Grid className={classes.titleSection} container direction="row">
        <Typography variant="h6" className={classes.title}>Order Information</Typography>
        {refundConfirmationDialog}
        <div className={classes.returnButton}>
          {(
            !(user == null
            || orderData == null
            || settings == null
            || !isOrderDataForCurrentSite)
          ) && (
            <Button
              color="primary"
              variant="outlined"
              onClick={handleReceiptDialogOpen}
              size="large"
              disabled={orderData == null
                || orderData.status === OrderStatusType.Unpaid
                || orderData.status === OrderStatusType.Failed}
            >
              Send Receipt
            </Button>
          )}
        </div>
        <div className={classes.returnButton}>
          <Button
            color="secondary"
            variant="outlined"
            onClick={history.goBack}
            size="large"
          >
            RETURN TO ORDERS
          </Button>
        </div>
      </Grid>
      <Divider />
      {orderData == null
      || !isOrderDataForCurrentSite
        ? (
          <div className={classes.orderIncorrect}>
            <Typography variant="body1" gutterBottom>
              {!isOrderDataForCurrentSite
                ? 'Please select correct site on home screen to view this order'
                : 'Sorry but this order number does not exist'}
            </Typography>
          </div>
        )
        : (
          <OrderList
            selectedOrder={orderData}
            reportingLabels={reportingLabels}
            siteData={settings}
          />
        )}
      {orderData && (
        <ReceiptDialog
          orderId={orderData.id}
          open={receiptDialogOpen}
          onSave={handleUserReceiptClick}
          onClose={handleReceiptClose}
        />
      )}
      <RefundDialog
        open={refundDialogOpen}
        showSplitTipMessage={!!settings?.splitPayouts}
        items={refundableItems}
        paymentMethods={refundablePaymentMethods}
        onClose={handleRefundDialogCloseClick}
        onSubmit={handleRefundDialogSubmit}
      />
    </Grid>
  );
};

export default OrderPage;
