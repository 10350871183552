import * as Joi from 'joi';
import ImportJob from '../types/ImportJob';
import extractEnumValues from './extractEnumValues';
import ImportJobStatus from '../types/ImportJobStatus';

const isImportJob = (value: unknown): value is ImportJob => {
  const schema = Joi.object({
    id: Joi.string().required(),
    site: Joi.string().required(),
    created: Joi.number().required(),
    type: Joi.string().required(),
    sourceFilePath: Joi.string().required(),
    createdBy: Joi.string().required(),
    status: Joi.string().valid(...extractEnumValues(ImportJobStatus)).required(),
  }).required();

  const result = schema.validate(value);
  return result.error == null;
};

export default isImportJob;
