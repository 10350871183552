import firebase from 'firebase/app';

import Label from '../types/Label';
import SiteData from '../types/SiteData';

const setLabelOnline = (siteId: SiteData['id'], ladelId: Label['id']) => firebase
  .firestore()
  .collection('sites')
  .doc(siteId)
  .update({ offlineLabels: firebase.firestore.FieldValue.arrayUnion(ladelId) });

export default setLabelOnline;
