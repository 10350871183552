export const LOGOUT = 'LOGOUT';
export const AUTHORISATION_ERROR = 'AUTHORISATION_ERROR';
export const LOGIN_START = 'LOGIN_START';
export const VERIFICATION_START = 'VERIFICATION_START';
export const AUTHORISATION_SUCCESS = 'AUTHORISATION_SUCCESS';
export const GET_FILE_DATA_START = 'GET_FILE_DATA_START';
export const GET_FILE_DATA_SUCCESS = 'GET_FILE_DATA_SUCCESS';
export const GET_FILE_DATA_ERROR = 'GET_FILE_DATA_ERROR';
export const GET_SITE_ORDERS_START = 'GET_SITE_ORDERS_START';
export const GET_SITE_ORDERS_SUCCESS = 'GET_SITE_ORDERS_SUCCESS';
export const GET_SITE_ORDERS_ERROR = 'GET_SITE_ORDERS_ERROR';
export const GET_ORDER_DATA_START = 'GET_ORDER_DATA_START';
export const GET_ORDER_DATA_SUCCESS = 'GET_ORDER_DATA_SUCCESS';
export const GET_ORDER_DATA_ERROR = 'GET_ORDER_DATA_ERROR';
export const GET_SETTINGS_START = 'GET_SETTINGS_START';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_ERROR = 'GET_SETTINGS_ERROR';
export const GET_SITES_START = 'GET_SITES_START';
export const GET_SITES_SUCCESS = 'GET_SITES_SUCCESS';
export const GET_SITES_ERROR = 'GET_SITES_ERROR';
export const UPDATE_USER_SITE = 'UPDATE_USER_SITE';
export const GET_PRODUCTS_START = 'GET_PRODUCTS_START';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';
export const GET_ORDERS_START = 'GET_ORDERS_START';
export const GET_INHOUSE_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_ERROR = 'GET_ORDERS_ERROR';
export const UPDATE_SITE_SETTINGS = 'UPDATE_SITE_SETTINGS';
export const UPDATE_SITE_SETTINGS_ERROR = 'UPDATE_SITE_SETTINGS_ERROR';
export const UPDATING_SITE_SETTINGS = 'UPDATING_SITE_SETTINGS';
export const UPDATE_SITE_LOGO = 'UPDATE_SITE_LOGO';
export const UPDATE_SITE_LOGO_ERROR = 'UPDATE_SITE_LOGO_ERROR';
export const GET_USERS_START = 'GET_USERS_START';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';
export const GET_SITE_MAP_START = 'GET_SITE_MAP_START';
export const GET_SITE_MAP_SUCCESS = 'GET_SITE_MAP_SUCCESS';
export const GET_SITE_MAP_ERROR = 'GET_SITE_MAP_ERROR';
export const GET_ORDER_STATS_START = 'GET_ORDER_STATS_START';
export const GET_ORDER_STATS_SUCCESS = 'GET_ORDER_STATS_SUCCESS';
export const GET_ORDER_STATS_ERROR = 'GET_ORDER_STATS_ERROR';
export const GET_CHART_DATA_START = 'GET_CHART_DATA_START';
export const GET_CHART_DATA_SUCCESS = 'GET_CHART_DATA_SUCCESS';
export const GET_CHART_DATA_ERROR = 'GET_CHART_DATA_ERROR';
export const GET_SLOT_TIMES_START = 'GET_SLOT_TIMES_START';
export const GET_SLOT_TIMES_SUCCESS = 'GET_SLOT_TIMES_SUCCESS';
export const GET_SLOT_TIMES_ERROR = 'GET_SLOT_TIMES_ERROR';
export const GET_SLOT_DAYS_START = 'GET_SLOT_DAYS_START';
export const GET_SLOT_DAYS_SUCCESS = 'GET_SLOT_DAYS_SUCCESS';
export const GET_SLOT_DAYS_ERROR = 'GET_SLOT_DAYS_ERROR';
export const GET_STATS_START = 'GET_STATS_START';
export const GET_STATS_SUCCESS = 'GET_STATS_SUCCESS';
export const GET_STATS_ERROR = 'GET_STATS_ERROR';
export const GET_UNPROCESSED_ORDERS_START = 'GET_UNPROCESSED_ORDERS_START';
export const GET_UNPROCESSED_ORDERS_SUCCESS = 'GET_UNPROCESSED_ORDERS_SUCCESS';
export const GET_UNPROCESSED_ORDERS_ERROR = 'GET_UNPROCESSED_ORDERS_ERROR';
export const GET_ONLINE_ORDERS_SUCCESS = 'GET_ONLINE_ORDERS_SUCCESS';
export const GET_NOTIFICATIONS_START = 'GET_NOTIFICATIONS_START';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_ERROR = 'GET_NOTIFICATIONS_ERROR';
export const GET_VAT_RATES_START = 'GET_VAT_RATES_START';
export const GET_VAT_RATES_SUCCESS = 'GET_VAT_RATES_SUCCESS';
export const GET_VAT_RATES_ERROR = 'GET_VAT_RATES_ERROR';
