import React, { useCallback, useMemo, useState } from 'react';
import firebase from 'firebase/app';

import {
  Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, Tooltip,
} from '@material-ui/core';

import BackdropSpinner from '../../../components/backdrop-spinner/backdrop-spinner';
import SnackBar from '../../../components/snack-bar/snack-bar';
import RefundItem from './RefundItem';
import SimpleSelect from '../../../components/SimpleSelect/SimpleSelect';
import OutlinedTextField from '../../../components/outlined-text-field/outlined-text-field';

import formatMoney from '../../../helpers/formatMoney';
import request from '../../../helpers/request';
import hasPermission from '../../../helpers/has-permission';
import canUserViewProduct from '../../../helpers/can-user-view-product';

import refundReasons from '../constants/refundReasons';

import RoleRestrictedAction from '../../../types/RoleRestrictedAction';
import OrderData from '../../../types/OrderData';
import User from '../../../types/User';
import SiteData from '../../../types/SiteData';
import Nullable from '../../../types/Nullable';
import ItemToRefund from '../types/ItemToRefund';
import OrderStatusType from '../../../types/OrderStatusType';

type Props = {
  siteData: SiteData
  orderData: OrderData
  user: User
};

const CashRefundConfirmation = ({ siteData, orderData, user }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [snackOpen, setSnackOpen] = useState<boolean>(false);
  const [itemsToRefund, setItemsToRefund] = useState<ItemToRefund[]>([]);
  const [refundReason, setRefundReason] = useState<string>('');
  const [additionalInfo, setAdditionalInfo] = useState<string>('');
  const [refundRequested, setRefundRequested] = useState<boolean>(false);
  const [refundError, setRefundError] = useState<Nullable<String>>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userSiteConfig = useMemo(
    () => user?.sites.find(({ site }) => site === user.site),
    [user],
  );

  const orderItems = useMemo(() => (
    orderData.order.filter((item) => {
      if (userSiteConfig == null || !canUserViewProduct(item, userSiteConfig)) return false;
      if (item.discount) return false;
      return item.quantity !== 0;
    })
  ), [orderData, userSiteConfig]);

  const handleClose = useCallback(() => {
    setSnackOpen(false);
  }, []);

  const handleDialogClose = useCallback(() => {
    setOpen(false);
    setRefundError(null);
  }, []);

  const amount = useMemo(() => (
    itemsToRefund.reduce<number>((total, item) => {
      const { refundAmount, refundedAddOns } = item;
      const addOnsTotal = refundedAddOns
        .reduce<number>((subTotal, addOn) => subTotal + addOn.refundAmount, 0);
      return total + refundAmount + addOnsTotal;
    }, 0)
  ), [itemsToRefund]);

  const amountLeftToBeRefunded = useMemo(() => (
    /* multiplying by 100 ensures these values are not decimal, preventing issues in cases such as
    1.1 - 1 = 0.10000000000000009 */
    ((orderData.total * 100) - ((orderData.amountRefunded || 0) * 100)) / 100
  ), [orderData]);

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      const idToken = await firebase.auth().currentUser?.getIdToken();
      await request(
        'refundCash',
        'POST',
        {
          orderNumber: orderData.orderNumber,
          refundReason,
          additionalInfo,
          itemsToRefund,
        },
        { Authorization: `Bearer ${idToken}` },
      );
      setRefundRequested(true);
      setIsLoading(false);
      setOpen(false);
      setSnackOpen(true);
    } catch {
      setIsLoading(false);
      setRefundError('Something went wrong');
    }
  };

  const addItemToRefund = useCallback((newItemToRefund: ItemToRefund) => {
    setItemsToRefund((currentValue) => [...currentValue, newItemToRefund]);
  }, []);

  const removeItemToRefund = useCallback((cartId: string) => {
    setItemsToRefund((currentValue) => currentValue.filter((item) => item.cartId !== cartId));
  }, []);

  const updateItemToRefund = useCallback((updatedItemToRefund: ItemToRefund) => {
    setItemsToRefund((currentValue) => currentValue.map((item) => {
      if (item.cartId !== updatedItemToRefund.cartId) return item;
      return updatedItemToRefund;
    }));
  }, []);

  const refundButtonTooltip = useMemo(() => {
    if (orderData.status === OrderStatusType.FullRefund) return 'Order already fully refunded';
    if (!hasPermission(RoleRestrictedAction.RefundOrder, user)) return 'User has no permissions to refund';
    if (refundRequested) return 'Refund in progress';
    return 'Refund Order';
  }, [orderData, hasPermission, user, refundRequested]);

  const isRefundButtonDisabled = useMemo(() => orderData.status === OrderStatusType.FullRefund
    || !hasPermission(RoleRestrictedAction.RefundOrder, user)
    || refundRequested, [orderData, hasPermission, user, refundRequested]);

  const isDisabled = useMemo(() => itemsToRefund.length === 0
    || refundReason.length === 0, [itemsToRefund, refundReason]);

  return (
    <div>
      <Tooltip disableFocusListener disableTouchListener title={refundButtonTooltip}>
        <span>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => setOpen(true)}
            disabled={isRefundButtonDisabled}
          >
            {refundRequested ? 'Refund Requested' : 'Refund Order'}
          </Button>
        </span>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Refund order: ${orderData.orderNumber}`}</DialogTitle>
        <DialogContent dividers>
          <BackdropSpinner open={isLoading} />
          {orderItems.map((orderItem) => (
            <RefundItem
              key={orderItem.cartId}
              orderItem={orderItem}
              itemsToRefund={itemsToRefund}
              addItemtoRefund={addItemToRefund}
              removeItemToRefund={removeItemToRefund}
              updateItemToRefund={updateItemToRefund}
            />
          ))}
          <SimpleSelect
            label="Refund Reason"
            value={refundReason}
            items={refundReasons}
            onChange={setRefundReason}
            labelWidth={110}
          />
          <OutlinedTextField
            id="outlined-add-info"
            classOption="flex"
            label="Additional Information"
            value={additionalInfo}
            onChange={(event) => setAdditionalInfo(event.target.value)}
          />
          <Typography variant="body1">
            {`Refund amount: ${formatMoney(amount, siteData.currency)} out of ${formatMoney(amountLeftToBeRefunded, siteData.currency)}`}
          </Typography>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            {refundError ? <Typography variant="body1" color="error" gutterBottom>{refundError}</Typography> : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus disabled={isDisabled}>
            Refund
          </Button>
        </DialogActions>
      </Dialog>
      <SnackBar
        open={snackOpen}
        onClose={handleClose}
        message="Refund successful - order will be updated soon."
      />
    </div>
  );
};

export default CashRefundConfirmation;
