import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    display: 'flex',
    height: 400,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const NoMatchPage = ({ classes }) => (
  <Typography className={classes.root} variant="h6">This is not the route you&apos;re looking for!</Typography>
);

NoMatchPage.propTypes = {
  classes: PropTypes.shape().isRequired,
};

export default withStyles(styles)(NoMatchPage);
