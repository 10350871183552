import { Map } from 'immutable';
import {
  GET_SITE_MAP_START,
  GET_SITE_MAP_SUCCESS,
  GET_SITE_MAP_ERROR,
} from '../constants/actions';
import STORE_KEYS from '../constants/store-keys';

const {
  IS_SITE_MAP_LOADING, SITE_MAP_ID, SITE_MAP, SITE_MAP_LOADING_ERROR,
} = STORE_KEYS.SITE_MAP;

const initialState = {};
initialState[IS_SITE_MAP_LOADING] = false;
initialState[SITE_MAP_ID] = '';
initialState[SITE_MAP] = [];
initialState[SITE_MAP_LOADING_ERROR] = '';
const initialStateMap = Map(initialState);

export default (state = initialStateMap, action) => {
  switch (action.type) {
    case GET_SITE_MAP_START:
      return state
        .set(IS_SITE_MAP_LOADING, true)
        .set(SITE_MAP_ID, '')
        .set(SITE_MAP, [])
        .set(SITE_MAP_LOADING_ERROR, '');
    case GET_SITE_MAP_SUCCESS:
      return state
        .set(IS_SITE_MAP_LOADING, false)
        .set(SITE_MAP_ID, action.siteMapId)
        .set(SITE_MAP, action.siteMaps);
    case GET_SITE_MAP_ERROR:
      return state
        .set(IS_SITE_MAP_LOADING, false)
        .set(SITE_MAP_LOADING_ERROR, action.message);
    default:
      return state;
  }
};
