import React from 'react';
import { IconButton, TextField, makeStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

interface Props {
  value: string;
  onSearchTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSearchTextClear: () => void
}

const useStyles = makeStyles((theme) => ({
  startIcon: {
    marginRight: theme.spacing(1),
  },
}));

const SearchToolbar = ({ value, onSearchTextChange, onSearchTextClear }: Props) => {
  const classes = useStyles();
  return (
    <TextField
      variant="outlined"
      placeholder="Search"
      value={value}
      onChange={onSearchTextChange}
      InputProps={{
        startAdornment: <SearchIcon fontSize="small" className={classes.startIcon} />,
        endAdornment: (
          <IconButton
            title="Clear"
            aria-label="Clear"
            size="small"
            onClick={onSearchTextClear}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        ),
      }}
    />
  );
};

export default SearchToolbar;
