import React from 'react';

import { DateTime } from 'luxon';

import { TextField } from '@material-ui/core';

type Props = {
  inputClassName: string
  textFieldClassName: string
  label: string
  value: string
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void
};

const DateTimeField = ({
  inputClassName,
  textFieldClassName,
  label,
  value,
  onChange,
}: Props) => (
  <TextField
    className={textFieldClassName}
    label={label}
    variant="outlined"
    type="datetime-local"
    value={value}
    onChange={onChange}
    InputLabelProps={{
      shrink: true,
    }}
    inputProps={{ max: `${DateTime.now().toFormat('yyyy-MM-dd')}T00:00`, className: inputClassName }}
  />
);

export default DateTimeField;
