import DeliveryTime from '../../../types/DeliveryTime';

const getDefaultDeliveryTimes = (): DeliveryTime[] => [...new Array(7)].map((d, i) => ({
  firstDelivery: '00:00',
  lastDelivery: '00:00',
  leadTime: 0,
  day: i,
}));

export default getDefaultDeliveryTimes;
