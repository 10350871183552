import React,
{
  useCallback,
  useState,
  useMemo,
  ChangeEvent,
} from 'react';
import {
  makeStyles,
  Box,
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  LinearProgress,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Switch,
} from '@material-ui/core';

import createDefaultOpeningTimes from '../../../helpers/createDefaultOpeningTimes';

import OpeningTimeInput from '../../../components/OpeningTimeInput/OpeningTimeInput';

import Nullable from '../../../types/Nullable';
import Label from '../../../types/Label';
import SiteOpeningTime from '../../../types/SiteOpeningTime';

interface Props {
  label: Label,
  onLabelEditSave: (updatedLabel: Label) => Promise<void>,
  onLabelEditCancel: () => void,
}

const useStyles = makeStyles({
  textInput: {
    width: 400,
    marginBottom: '1em',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const LabelEditor = ({
  label,
  onLabelEditSave,
  onLabelEditCancel,
}: Props) => {
  const classes = useStyles();
  const [labelText, setLabelText] = useState(label.text);
  const [active, setActive] = useState<boolean>(label.active != null ? label.active : true);
  const [openingTimesActive, setOpeningTimesActive] = useState<boolean>(label.openingTimes != null);
  const [openingTimes, setOpeningTimes] = useState<SiteOpeningTime[]>(
    label.openingTimes || createDefaultOpeningTimes(),
  );
  const [error, setError] = useState<Nullable<string>>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleTextChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setLabelText(event.target.value),
    [setLabelText],
  );

  const handleSwitchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setActive(event.target.checked);
    if (openingTimesActive) {
      setOpeningTimes((currentOpeningTimes) => currentOpeningTimes
        .map((time) => ({ ...time, closed: true })));
    }
  }, [openingTimesActive]);

  const handleOpeningTimesCheckboxClick = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setOpeningTimesActive(event.target.checked),
    [openingTimes, setOpeningTimes],
  );

  const openingTimeChangeHandlerFactory = useCallback(
    (day: number) => (updatedOpeningTime: SiteOpeningTime) => (
      setOpeningTimes((currentOpeningTimes) => {
        const updatedOpeningTimes = [...currentOpeningTimes];
        updatedOpeningTimes[day] = updatedOpeningTime;
        return updatedOpeningTimes;
      })
    ),
    [setOpeningTimes],
  );

  const handleSaveClick = useCallback(async () => {
    setLoading(true);
    const updatedLabel = {
      ...label,
      text: labelText,
      active,
      openingTimes: openingTimesActive ? openingTimes : null,
    };
    try {
      await onLabelEditSave(updatedLabel);
    } catch {
      setError('Label could not be updated. Please try again.');
      setLoading(false);
    }
  }, [setLoading, setError, labelText, active, openingTimesActive, openingTimes]);

  const labelTextValid = useMemo(() => (
    /^[\w\d\s#%&+=?!]{1,25}$/i.test(labelText)
  ), [labelText]);

  return (
    <Dialog
      open
    >
      <DialogTitle>Edit Reporting Label</DialogTitle>
      <DialogContent>
        <Box className={classes.container}>
          <TextField
            className={classes.textInput}
            label="Edit Label Text"
            variant="outlined"
            onChange={handleTextChange}
            value={labelText}
          />
          <FormControlLabel
            label="Label Active"
            control={(
              <Switch
                checked={active}
                onChange={handleSwitchChange}
              />
            )}
          />
          <FormControlLabel
            label="Label Opening Times"
            control={(
              <Checkbox
                checked={openingTimesActive}
                onChange={handleOpeningTimesCheckboxClick}
                disabled={!active}
              />
            )}
          />
          {openingTimesActive
            && (
              openingTimes.map((openingTime) => (
                <OpeningTimeInput
                  key={openingTime.day}
                  openingTime={openingTime}
                  onChange={openingTimeChangeHandlerFactory(openingTime.day)}
                  disabled={!active}
                />
              )))}
          {error && (
          <Typography>{error}</Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSaveClick}
          disabled={!labelTextValid || loading}
          variant="outlined"
          color="primary"
        >
          Save
        </Button>
        <Button
          onClick={onLabelEditCancel}
          variant="outlined"
        >
          Cancel
        </Button>
      </DialogActions>
      {loading && (
        <LinearProgress />
      )}
    </Dialog>
  );
};

export default LabelEditor;
