import { Map } from 'immutable';
import {
  GET_STATS_START,
  GET_STATS_SUCCESS,
  GET_STATS_ERROR,
} from '../constants/actions';
import STORE_KEYS from '../constants/store-keys';

const {
  IS_STATS_LOADING,
  TOTAL_ORDERS,
  TOTAL_SALES,
  STATS_LOADING_ERROR,
} = STORE_KEYS.STATS;

const initialState = {};
initialState[IS_STATS_LOADING] = false;
initialState[TOTAL_ORDERS] = 0;
initialState[TOTAL_SALES] = 0;
initialState[STATS_LOADING_ERROR] = '';
const initialStateMap = Map(initialState);

export default (state = initialStateMap, action) => {
  switch (action.type) {
    case GET_STATS_START:
      return state
        .set(IS_STATS_LOADING, true)
        .set(TOTAL_ORDERS, 0)
        .set(TOTAL_SALES, 0)
        .set(STATS_LOADING_ERROR, '');
    case GET_STATS_SUCCESS:
      return state
        .set(IS_STATS_LOADING, false)
        .set(TOTAL_ORDERS, action.totalOrders)
        .set(TOTAL_SALES, action.totalSales);
    case GET_STATS_ERROR:
      return state
        .set(IS_STATS_LOADING, false)
        .set(STATS_LOADING_ERROR, action.message);
    default:
      return state;
  }
};
