import { DateTime } from 'luxon';

const isOnTradingDay = (openTime: string, closeTime: string, day: DateTime) => {
  const now = DateTime.now();

  const openDate = DateTime.fromISO(`${day.toISODate()}T${openTime}`);
  let closeDate = DateTime.fromISO(`${day.toISODate()}T${closeTime}`);

  if (closeDate < openDate) {
    closeDate = closeDate.plus({ days: 1 });
  }

  return now > openDate && now < closeDate;
};

export default isOnTradingDay;
