import ProductType from '../../../types/ProductType';

const defaultGiftCardProduct = {
  name: '',
  pluName: '',
  type: ProductType.GiftCard,
  description: '',
  plu: '',
  price: 0,
  addonDescription: '',
  times: [],
  over18: false,
  show: true,
  nonDiscountable: true,
  involvesKitchen: false,
  soldOut: false,
  options: [],
  image: null,
  allergens: [],
  userLabels: [],
  reportingLabels: [],
};

export default defaultGiftCardProduct;
