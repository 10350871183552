import * as Joi from 'joi';

import FormDiscountCode from '../types/FormDiscountCode';

const isFormDiscountCode = (value: unknown): value is FormDiscountCode => {
  const schema = Joi.object({
    code: Joi.string().required(),
    maxUsages: Joi.string().regex(/^\d+$/).allow(''),
  }).unknown(true).required();

  const result = schema.validate(value, { allowUnknown: true });

  return result.error == null;
};

export default isFormDiscountCode;
