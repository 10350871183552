import request from '../../../helpers/request';
import getQueryString from './getQueryString';

import Options from '../types/Options';
import Query from '../../../types/Query';
import PayoutsReportResult from '../types/PayoutsReportResult';

const getSalesReport = async (
  site: string,
  options: Options,
  query: Query,
  userId?: string,
  reportingLabels?: string[],
): Promise<PayoutsReportResult> => {
  const endpoint = getQueryString('payoutsReport', site, options, userId, query, reportingLabels);

  const result = await request(endpoint, 'GET');
  return result;
};

export default getSalesReport;
