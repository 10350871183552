import React,
{
  ChangeEvent,
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  Typography,
} from '@material-ui/core';

import Spinner from '../../../components/spinner/spinner';
import PopularProductsSelect from './PopularProductsSelect';
import initialiseSiteListener from '../../../helpers/initialiseSiteListener';

import SiteData from '../../../types/SiteData';
import Nullable from '../../../types/Nullable';
import User from '../../../types/User';
import ProductData from '../../../types/ProductData';
import Choice from '../../../types/Choice';
import updatePopularProducts from '../../../helpers/updatePopularProducts';

interface Props {
  user: Nullable<User>;
  products: ProductData[];
  isProductsLoading: boolean;
}

const PopularProducts = ({ user, products, isProductsLoading }: Props) => {
  const [settings, setSettings] = useState<Nullable<SiteData>>(null);
  const [expanded, setExpanded] = useState<Nullable<string>>(null);
  const [error, setError] = useState<Nullable<Error>>(null);

  useEffect(() => {
    if (user != null) {
      initialiseSiteListener(user.site, setSettings, setError);
    }
  }, [user]);

  const isSiteInputVisible = useMemo(() => (
    settings?.reportingLabels == null || settings.reportingLabels.length === 0
  ), [settings]);

  const sitePopularProducts = useMemo(() => {
    if (
      settings == null
      || settings.popularProducts == null
      || settings.popularProducts[settings.site] == null
    ) return [];
    return settings.popularProducts[settings.site];
  }, [settings]);

  const userSiteConfig = useMemo(
    () => user?.sites.find((config) => config.site === user.site),
    [user],
  );

  const reportingLabels = settings?.reportingLabels || [];
  const popularProducts = settings?.popularProducts || {};
  const labelsWithPopularProducts = reportingLabels
    .map(
      (label) => ({
        ...label,
        labelPopularProducts: popularProducts[label.id] || [],
        labelProducts: products.filter((product) => {
          const { reportingLabels: productlabels } = product;
          if (productlabels == null) return false;
          return productlabels.includes(label.id);
        }),
      }),
    ).filter(
      ({ id: labelId }) => {
        if (
          userSiteConfig?.reportingLabels == null
          || userSiteConfig.reportingLabels.length === 0
        ) return true;
        return userSiteConfig.reportingLabels.includes(labelId);
      },
    );

  const accordianHandlerFactory = (
    panel: Nullable<string>,
  ) => (
    _event: ChangeEvent<{}>,
    isExpanded: boolean,
  ) => {
    if (!isExpanded) {
      setExpanded(null);
    } else {
      setExpanded(panel);
    }
  };

  const popularProductsChangeHandler = async (
    labelId: string,
    options: Choice[],
  ) => {
    try {
      if (settings == null) throw new Error('Missing settings.');
      if (settings.id == null) throw new Error('Missing settings doc Id.');
      await updatePopularProducts(
        settings.id,
        settings.popularProducts,
        labelId,
        options,
      );
    } catch (err) {
      setError(err as Error);
    }
  };

  if (settings == null || isProductsLoading) return <Spinner />;
  if (error) {
    return (
      <Typography>
        Something went wrong. Please contact support if this problem persists.
      </Typography>
    );
  }

  return (
    <>
      {isSiteInputVisible
        ? (
          <PopularProductsSelect
            expanded={expanded === settings.site}
            onExpandedChange={accordianHandlerFactory(settings.site)}
            heading={settings.name}
            value={sitePopularProducts}
            options={products}
            onSelectChange={(options) => popularProductsChangeHandler(settings.site, options)}
          />
        ) : labelsWithPopularProducts.map(
          ({
            text,
            id,
            labelPopularProducts,
            labelProducts,
          }) => (
            <PopularProductsSelect
              expanded={expanded === id}
              onExpandedChange={accordianHandlerFactory(id)}
              heading={text}
              value={labelPopularProducts}
              options={labelProducts}
              onSelectChange={(options) => popularProductsChangeHandler(id, options)}
            />
          ),
        )}
    </>
  );
};

export default PopularProducts;
