import Joi from 'joi';

import DaysOfTheWeek from '../types/DaysOfTheWeek';
import Discount from '../types/Discount';
import DiscountType from '../types/DiscountType';

const isDiscount = (value: unknown): value is Discount => {
  const discountSchemaBase = {
    name: Joi.string().required(),
    from: Joi.date().allow(''),
    to: Joi.date().allow(''),
    days: Joi.array().items(Joi.string().valid(
      DaysOfTheWeek.Monday,
      DaysOfTheWeek.Tuesday,
      DaysOfTheWeek.Wednesday,
      DaysOfTheWeek.Thursday,
      DaysOfTheWeek.Friday,
      DaysOfTheWeek.Saturday,
      DaysOfTheWeek.Sunday,
    )),
    reportingLabels: Joi.array().items(Joi.string()),
    plu: Joi.number().required(),
    minOrderValue: Joi.number().min(0),
    status: Joi.string().required(),
  };

  const result = Joi.alternatives().try(
    Joi.object({
      ...discountSchemaBase,
      type: Joi.string().valid(DiscountType.Percentage).required(),
      value: Joi.number().min(0.1).max(0.99).required(), // Percentage units saved with a 0. infront
    }),
    Joi.object({
      ...discountSchemaBase,
      type: Joi.string().valid(DiscountType.FixedValue).required(),
      value: Joi.number().min(0.01).required(),
    }),
  ).validate(value, { allowUnknown: true });
  return result.error == null;
};

export default isDiscount;
