import { Map } from 'immutable';
import {
  GET_VAT_RATES_START,
  GET_VAT_RATES_SUCCESS,
  GET_VAT_RATES_ERROR,
} from '../constants/actions';
import ReduxAction from '../types/ReduxAction';
import ReduxState from '../types/ReduxState';

const initialStateMap: ReduxState['vatRates'] = Map();

const initialState = initialStateMap
  .set('IS_VAT_RATES_LOADING', false)
  .set('VAT_RATES', [])
  .set('VAT_RATES_LOADING_ERROR', '');

export default (
  state: ReduxState['vatRates'] = initialState,
  action: ReduxAction,
) => {
  switch (action.type) {
    case GET_VAT_RATES_START:
      return state
        .set('IS_VAT_RATES_LOADING', true)
        .set('VAT_RATES', [])
        .set('VAT_RATES_LOADING_ERROR', '');
    case GET_VAT_RATES_SUCCESS:
      return state
        .set('IS_VAT_RATES_LOADING', false)
        .set('VAT_RATES', action.vatRates);
    case GET_VAT_RATES_ERROR:
      return state
        .set('IS_VAT_RATES_LOADING', false)
        .set('VAT_RATES_LOADING_ERROR', action.message);
    default:
      return state;
  }
};
