import React from 'react';

import moment from 'moment';

import { Grid, makeStyles, Typography } from '@material-ui/core';

import OutlinedTextField from '../../outlined-text-field/outlined-text-field';

import SiteOpeningTime from '../../../types/SiteOpeningTime';

type Props = {
  times: SiteOpeningTime[]
  type: string
  onChange: (name: string, value: string, day: number) => void
};

const useStyles = makeStyles(() => ({
  h6: {
    width: 250,
  },
}));

const OpeningTimes = ({ times, type, onChange }: Props) => {
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Typography classes={classes} variant="h6" color="inherit" gutterBottom>
          {type === 'Kitchen' ? 'Kitchen Opening Times' : 'Opening Times'}
        </Typography>
        {
          times.map((time, index) => (
            <Grid key={time.day || index}>
              <OutlinedTextField
                id="time"
                label={moment().isoWeekday(time.day + 1 || index + 1).format('dddd')}
                type="time"
                classOption="time"
                value={time[type === 'Kitchen' ? 'kitchenOpen' : 'open'] || '00:00'}
                onChange={(event) => onChange(
                  type === 'Kitchen' ? 'kitchenOpen' : 'open', event.target.value, time.day ? time.day : index,
                )}
                disabled={time.closed}
              />
            </Grid>
          ))
        }
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Typography classes={classes} variant="h6" color="inherit" gutterBottom>
          {type === 'Kitchen' ? 'Kitchen Closing Times' : 'Closing Times'}
        </Typography>
        {
          times.map((time, index) => (
            <Grid key={time.day || index}>
              <OutlinedTextField
                id="time"
                label={moment().isoWeekday(time.day + 1 || index + 1).format('dddd')}
                type="time"
                classOption="time"
                value={time[type === 'Kitchen' ? 'kitchenClose' : 'close'] || '00:00'}
                onChange={(event) => onChange(
                  type === 'Kitchen' ? 'kitchenClose' : 'close', event.target.value, time.day ? time.day : index,
                )}
                disabled={time.closed}
              />
            </Grid>
          ))
        }
      </Grid>
    </>
  );
};

export default OpeningTimes;
