import React, { useMemo } from 'react';

import { DateTime } from 'luxon';

import {
  Grid, ListItem, ListItemText, makeStyles, Typography,
} from '@material-ui/core';

import formatMoney from '../../../helpers/formatMoney';
import getPaymentLabel from '../../../helpers/getPaymentLabel';
import convertRefundReason from '../helpers/convertRefundReason';

import OrderData from '../../../types/OrderData';

const useStyles = makeStyles((theme) => ({
  listItem: { padding: theme.spacing(1, 0) },
  boldText: { fontWeight: 700 },
}));

type Props = {
  orderData: OrderData
};

const RefundList = ({ orderData }: Props) => {
  const classes = useStyles();

  const refunds = orderData.refunds || [];
  const orderPayments = orderData.payments || [];

  if (orderData.payments == null) {
    const {
      status,
      paymentIntent,
      currency,
      otherPaymentRefunds,
    } = orderData;

    const isOrderRefunded = useMemo(() => status === 'full-refund' || status === 'partial-refund', [status]);
    return (
      <>
        {isOrderRefunded && paymentIntent && paymentIntent.refunds.total_count > 0
          ? paymentIntent.refunds.data.map((refund) => (
            <ListItem key={refund.id} className={classes.listItem}>
              <ListItemText
                className={classes.boldText}
                primary={`Refund (${DateTime.fromSeconds(refund.created).toLocaleString(DateTime.DATETIME_FULL)})`}
                secondary={refund.reason ? (
                  <Grid container direction="column">
                    {refund.reason && <Typography variant="caption">{`Reason: ${convertRefundReason(refund.reason)}`}</Typography>}
                    {refund.metadata?.additionalInfo && <Typography variant="caption">{`Additional Info: ${refund.metadata.additionalInfo}`}</Typography>}
                  </Grid>
                ) : null}
              />
              <Typography variant="subtitle1" className={classes.boldText}>
                {`-${formatMoney(refund.amount / 100, currency)}`}
              </Typography>
            </ListItem>
          )) : null}
        {isOrderRefunded && otherPaymentRefunds && otherPaymentRefunds.length > 0
          ? otherPaymentRefunds.map((refund) => (
            <ListItem key={refund.id} className={classes.listItem}>
              <ListItemText
                className={classes.boldText}
                primary={`Refund ${refund.paymentMethod || ''} (${DateTime.fromMillis(refund.created).toLocaleString(DateTime.DATETIME_FULL)})`}
                secondary={refund.refundReason || refund.additionalInfo ? (
                  <Grid container direction="column">
                    {refund.refundReason && <Typography variant="caption">{`Reason: ${convertRefundReason(refund.refundReason)}`}</Typography>}
                    {refund.additionalInfo && <Typography variant="caption">{`Additional Info: ${refund.additionalInfo}`}</Typography>}
                  </Grid>
                ) : null}
              />
              <Typography variant="subtitle1" className={classes.boldText}>
                {`-${formatMoney(refund.amount, currency)}`}
              </Typography>
            </ListItem>
          )) : null}
      </>
    );
  }

  if (refunds.length === 0) return null;

  return (
    <>
      {refunds.map((refund) => refund.payments.map((payment) => {
        const paymentData = orderPayments.find(({ id }) => id === payment.id);

        return (
          <ListItem className={classes.listItem}>
            <ListItemText
              className={classes.boldText}
              primary={`Refund ${paymentData ? getPaymentLabel(paymentData) : ''} (${DateTime.fromMillis(refund.refundTime).toLocaleString(DateTime.DATETIME_FULL)})`}
              secondary={refund.reason || refund.additionalInfo ? (
                <Grid container direction="column">
                  {refund.reason && <Typography variant="caption">{`Reason: ${convertRefundReason(refund.reason)}`}</Typography>}
                  {refund.additionalInfo && <Typography variant="caption">{`Additional Info: ${refund.additionalInfo}`}</Typography>}
                </Grid>
              ) : null}
            />
            <Typography variant="subtitle1" className={classes.boldText}>
              {`-${formatMoney(payment.amountRefunded, orderData.currency)}`}
            </Typography>
          </ListItem>
        );
      }))}
    </>
  );
};

export default RefundList;
