import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import getOrders from '../../actions/get-orders';

import initialiseSiteListener from '../../helpers/initialiseSiteListener';
import initialiseUserListener from '../dispense-screen-page/helpers/initlialiseUserListener';
import labelsFromIds from '../../components/label-input/helpers/labels-from-ids';
import getStatusTitle from './helpers/getStatusTitle';
import canUserViewProduct from '../../helpers/can-user-view-product';
import isLabelArray from '../edit-user-page/helpers/isLabelArray';

import Nullable from '../../types/Nullable';
import ReduxState from '../../types/ReduxState';
import User from '../../types/User';
import OrderData from '../../types/OrderData';
import PeaziColour from '../../types/PeaziColour';
import SiteData from '../../types/SiteData';
import Status from '../../types/Status';
import OrderItem from '../../types/OrderItem';
import Label from '../../types/Label';

const useStyles = makeStyles((theme) => ({
  alert: { marginBottom: 20 },
  dialog: { backgroundColor: PeaziColour.LightModeGreen },
  collectionTitle: { marginBottom: theme.spacing(2) },
  title: { color: 'black', marginBottom: theme.spacing(2) },
  paper: {
    backgroundColor: '#f2f9f1',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  collectionNumber: {
    textAlign: 'center',
    color: 'black',
  },
  button: { margin: '0.25em 1em 0.25em 0' },
}));

const CollectionScreenPage = () => {
  const authenticatedUser = useSelector<ReduxState, Nullable<User>>(
    (state) => state.authentication.get('USER'),
  );
  const orders = useSelector<ReduxState, OrderData[]>((state) => state.orders.get('INHOUSE_ORDERS'));
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [user, setUser] = useState<Nullable<User>>(null);
  const [siteData, setSiteData] = useState<Nullable<SiteData>>(null);
  const [labelSelectValue, setLabelSelectValue] = useState<Label[]>([]);
  const [error, setError] = useState<Nullable<Error>>(null);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authenticatedUser) {
      initialiseUserListener(authenticatedUser.id, setUser, setError);
      initialiseSiteListener(authenticatedUser.site, setSiteData, setError);
      dispatch(getOrders(authenticatedUser.site));
    }
  }, [authenticatedUser]);

  const userSiteConfig = useMemo(
    () => user?.sites.find((siteConfig) => siteConfig.site === siteData?.site),
    [user, siteData],
  );

  const labelSelectOptions = useMemo(() => {
    if (
      (userSiteConfig && userSiteConfig.reportingLabels.length === 0)
      && (siteData && siteData.reportingLabels && siteData.reportingLabels.length > 0)
    ) {
      return siteData.reportingLabels;
    }

    if (
      (userSiteConfig && userSiteConfig.reportingLabels.length > 0)
      && (siteData && siteData.reportingLabels && siteData.reportingLabels.length > 0)
    ) {
      const relevantLabelIds = userSiteConfig.reportingLabels.filter(
        (labelId) => siteData.reportingLabels?.some(({ id }) => id === labelId),
      );
      return labelsFromIds(relevantLabelIds, siteData.reportingLabels);
    }

    return [];
  }, [userSiteConfig, siteData]);

  const onLabelSelectChange = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    if (Array.isArray(value) && siteData && siteData.reportingLabels) {
      const newLabelSelection = value.map((labelText) => (
        labelSelectOptions.find((label) => label.text === labelText)
      ));
      if (isLabelArray(newLabelSelection)) setLabelSelectValue(newLabelSelection);
    }
  }, [siteData, labelSelectOptions, setLabelSelectValue]);

  const filterOrderItems = useCallback((items: OrderItem[]) => (
    items.filter((item) => {
      if (
        item.serviceCharge
        || item.tip
        || item.refunded
        || item.discount
        || item.charityDonation
      ) return false;

      if (
        (labelSelectValue.length > 0)
        && (
          !item.reportingLabels?.some(
            (labelId) => labelSelectValue.map(({ id }) => id).includes(labelId),
          )
        )
      ) {
        return false;
      }

      return (!userSiteConfig || canUserViewProduct(item, userSiteConfig));
    }).map((item) => {
      if (item.quantityRefunded != null) {
        return { ...item, quantity: item.quantity - item.quantityRefunded };
      }
      return item;
    })
  ), [user, labelSelectValue]);

  const filteredOrders = useMemo(() => {
    const mappedOrders = orders.map((fullOrder) => ({
      ...fullOrder, order: filterOrderItems(fullOrder.order),
    })).filter((fullOrder) => fullOrder.order.length > 0);
    return mappedOrders;
  }, [orders, labelSelectValue]);

  const orderStatuses = useMemo(() => {
    if (siteData?.orderFlow == null
      || siteData.orderFlow.statusBoard == null) return [Status.Preparing, Status.Ready];
    return siteData.orderFlow.statusBoard;
  }, [siteData]);

  const getStatusOrders = useCallback((status: Status) => {
    const statusOrders = filteredOrders
      .map((fullOrder) => ({
        ...fullOrder,
        order: fullOrder.order.filter((o) => o.status === status),
      })).filter((fullOrder) => fullOrder.order.length > 0);
    return statusOrders;
  }, [filteredOrders]);

  const title = useMemo(() => {
    if (labelSelectValue.length > 0) {
      return `${labelSelectValue.map((value) => value.text).join(', ')} Collection`;
    }
    return `${siteData?.name} Collection`;
  }, [siteData, labelSelectValue]);

  const getReportingLabels = useCallback((order: OrderData) => {
    if (siteData == null) return null;
    if (labelSelectValue.length === 1) return null;

    const vendors: string[] = [];

    order.order.forEach((o) => {
      if (o.reportingLabels != null && siteData != null) {
        const labelId = o.reportingLabels[0];
        if (!vendors.includes(labelId)) {
          vendors.push(labelId);
        }
      }
    });

    return labelsFromIds(vendors, siteData.reportingLabels || []).map((label) => label.text).join(', ');
  }, [siteData, labelSelectValue]);

  if (error) {
    return (
      <Grid>
        <Typography variant="body1" color="error" gutterBottom>
          Something went wrong, please try again or contact support.
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid>
      <Alert className={classes.alert} severity="warning">
        To close collection use ESC key on keyboard or click anywhere on screen.
      </Alert>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel
              id="label-select"
            >
              Reporting Labels
            </InputLabel>
            <Select
              multiple
              labelId="label-select"
              labelWidth={125}
              value={labelSelectValue.map((label) => label.text)}
              onChange={onLabelSelectChange}
              MenuProps={{ variant: 'menu' }}
            >
              {labelSelectOptions.map((label) => (
                <MenuItem key={label.id} value={label.text}>
                  {label.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Button className={classes.button} color="primary" variant="contained" onClick={() => setIsOpen(true)} size="large">
            Open Collection Screen
          </Button>
        </Grid>
      </Grid>
      <Dialog
        onClick={() => setIsOpen(false)}
        onClose={() => setIsOpen(false)}
        open={isOpen}
        fullScreen
      >
        <DialogContent className={classes.dialog}>
          <Typography className={classes.collectionTitle} variant="h5" align="center">{title}</Typography>
          <Grid container spacing={2}>
            {orderStatuses.map((status) => (
              <Grid key={status} item sm={6} xs={12}>
                <Paper className={classes.paper} elevation={0}>
                  <Typography className={classes.title} variant="h6">{getStatusTitle(status)}</Typography>
                  <Grid container spacing={2}>
                    {getStatusOrders(status)
                      .map((order) => {
                        const vendors = getReportingLabels(order);
                        return (
                          <Grid key={order.id} item lg={4} xs={6}>
                            <Typography className={classes.collectionNumber} variant="h3">
                              {order.collectionNumber || order.orderNumber}
                            </Typography>
                            {vendors && <Typography className={classes.collectionNumber} variant="subtitle1">{vendors}</Typography>}
                          </Grid>
                        );
                      })}
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default CollectionScreenPage;
