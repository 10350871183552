import AppUrl from '../types/AppUrl';
import APP_URL_SCREEN_MAP from '../constants/AppUrlScreenMap';
import NoMatchPage from '../containers/no-match-page/no-match-page';
import Route from '../types/Route';

const getAppRouteComponent = (route: Route<AppUrl>) => {
  const { url } = route;
  if (url == null) return NoMatchPage;

  return APP_URL_SCREEN_MAP[url];
};

export default getAppRouteComponent;
