import React, { Dispatch } from 'react';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Typography,
  fade,
  makeStyles,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import LaunchIcon from '@material-ui/icons/Launch';

import getSiteUrl from '../../../helpers/get-site-url';

import SiteData from '../../../types/SiteData';
import Nullable from '../../../types/Nullable';

const useStyles = makeStyles((theme) => ({
  media: { height: 140 },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  activeColor: {
    borderColor: 'transparent',
    backgroundColor: fade(theme.palette.success.main, 0.2),
  },
  activeChipColor: { color: theme.palette.success.main },
  notActiveColor: {
    borderColor: 'transparent',
    backgroundColor: fade(theme.palette.error.main, 0.2),
  },
  notActiveChipColor: { color: theme.palette.error.main },
  cardButton: {
    marginBottom: theme.spacing(2),
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
}));

type Props = {
  site: SiteData
  setSelectedSite: Dispatch<Nullable<SiteData>>
};

const SiteCard = ({
  site,
  setSelectedSite,
}: Props) => {
  const classes = useStyles();

  const handleDetailsClick = () => {
    setSelectedSite(site);
  };

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Card>
        <CardMedia
          className={classes.media}
          image={site.logo}
          title="green iguana"
        />
        <CardContent>
          <Grid className={classes.title}>
            <Typography style={{ marginRight: 10 }} variant="h6">
              {site.name}
            </Typography>
            {site.active ? (
              <Chip
                className={classes.activeColor}
                label="Online"
                variant="outlined"
                size="small"
                icon={<CheckCircleIcon className={classes.activeChipColor} />}
              />
            ) : (
              <Chip
                className={classes.activeColor}
                label="Offline"
                variant="outlined"
                size="small"
                icon={<CancelIcon className={classes.notActiveChipColor} />}
              />
            )}
          </Grid>
          <Typography variant="body2">{`Contact Email: ${site.email || 'N/A'}`}</Typography>
          <Typography variant="body2">
            {`Site Type: ${site.collection || site.delivery ? 'Click & Collect' : 'Table Service'}`}
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button
            className={classes.cardButton}
            color="primary"
            variant="contained"
            onClick={handleDetailsClick}
            fullWidth
          >
            Site details
          </Button>
          <Button
            className={classes.cardButton}
            color="primary"
            variant="outlined"
            target="_blank"
            href={getSiteUrl(site.urlEndpoint)}
            endIcon={<LaunchIcon />}
            fullWidth
          >
            Open Peazi Site
          </Button>
          <Button
            className={classes.cardButton}
            color="secondary"
            variant="outlined"
            onClick={() => {}}
            disabled
            fullWidth
          >
            Delete Site
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default SiteCard;
