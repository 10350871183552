import firebase from 'firebase/app';

const isUrlValid = (site: string, urlEndpoint: string) => {
  if (site === '' || urlEndpoint === '') return Promise.resolve(false);

  return firebase
    .firestore()
    .collection('sites')
    .where('urlEndpoint', '==', urlEndpoint)
    .get()
    .then((snapshots) => {
      const sites = [];
      snapshots.forEach((snapshot) => {
        if (snapshot.data().site !== site) {
          sites.push(snapshot.data());
        }
      });
      return Promise.resolve(sites.length === 0);
    });
};

export default isUrlValid;
