import firebase from 'firebase/app';
import {
  GET_USERS_START,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
} from '../constants/actions';

export default () => (dispatch) => {
  dispatch({
    type: GET_USERS_START,
  });
  firebase
    .firestore()
    .collection('userdata')
    .get()
    .then((snapshots) => {
      const users = [];
      snapshots.forEach((snapshot) => {
        users.push({ ...snapshot.data(), id: snapshot.id });
      });
      dispatch({
        type: GET_USERS_SUCCESS,
        numberOfUsers: users.length,
        users,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_USERS_ERROR,
        message: 'Failed to fetch users.',
      });
    });
};
