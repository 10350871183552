import React from 'react';

import moment from 'moment';

import { Grid, makeStyles, Typography } from '@material-ui/core';

import OutlinedTextField from '../../outlined-text-field/outlined-text-field';

import getFirstValue from '../helpers/getFirstValue';
import getLastValue from '../helpers/getLastValue';

import CollectionTime from '../../../types/CollectionTime';
import DeliveryTime from '../../../types/DeliveryTime';
import EditableSiteData from '../../../types/EditableSiteData';
import OnlineTimeType from '../../../types/OnlineTimeType';

type Props = {
  onlineTime: CollectionTime[] | DeliveryTime[]
  settings: EditableSiteData
  type: OnlineTimeType
  onChange: (newSiteData: EditableSiteData) => void
};

const useStyles = makeStyles(() => ({
  h6: {
    width: 250,
  },
}));

const OnlineTimes = ({
  type, settings, onlineTime, onChange,
}: Props) => {
  const classes = useStyles();

  const handleChange = (name: string, value: string | number, day: number) => {
    const newTime = onlineTime.map((time, i) => (i === day ? { ...time, [name]: value } : time));
    onChange({ ...settings, [type.toLowerCase()]: newTime });
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Typography classes={classes} variant="h6" color="inherit" gutterBottom>
          {`First ${type} Time`}
        </Typography>
        {
          onlineTime.map((time: CollectionTime | DeliveryTime, index: number) => (
            <Grid key={time.day || index}>
              <OutlinedTextField
                id="time"
                label={moment().isoWeekday(time.day + 1 || index + 1).format('dddd')}
                type="time"
                classOption="time"
                value={getFirstValue(time)}
                onChange={(event) => handleChange(
                  `first${type}`, event.target.value, time.day ? time.day : index,
                )}
              />
            </Grid>
          ))
        }
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Typography classes={classes} variant="h6" color="inherit" gutterBottom>
          {`Last ${type} Time`}
        </Typography>
        {
          onlineTime.map((time: CollectionTime | DeliveryTime, index: number) => (
            <Grid key={time.day || index}>
              <OutlinedTextField
                id="time"
                label={moment().isoWeekday(time.day + 1 || index + 1).format('dddd')}
                type="time"
                classOption="time"
                value={getLastValue(time)}
                onChange={(event) => handleChange(`last${type}`, event.target.value, time.day ? time.day : index)}
              />
            </Grid>
          ))
        }
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Typography classes={classes} variant="h6" color="inherit" gutterBottom>
          Lead Time
        </Typography>
        {
          onlineTime.map((time: CollectionTime | DeliveryTime, index: number) => (
            <Grid key={time.day || index}>
              <OutlinedTextField
                id="outlined-lead-time"
                type="number"
                label={moment().isoWeekday(time.day + 1 || index + 1).format('dddd')}
                classOption="number"
                value={time.leadTime || 20}
                onChange={(event) => handleChange('leadTime', Number(event.target.value), time.day ? time.day : index)}
              />
            </Grid>
          ))
        }
      </Grid>
    </>
  );
};

export default OnlineTimes;
