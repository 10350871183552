import firebase from 'firebase/app';
import {
  GET_SITES_START,
  GET_SITES_SUCCESS,
  GET_SITES_ERROR,
} from '../constants/actions';

export default () => (dispatch) => {
  dispatch({
    type: GET_SITES_START,
  });
  firebase
    .firestore()
    .collection('sites')
    .orderBy('name')
    .get()
    .then((snapshots) => {
      const sites = [];
      snapshots.forEach((snapshot) => {
        sites.push({ ...snapshot.data(), id: snapshot.id });
      });
      dispatch({
        type: GET_SITES_SUCCESS,
        siteNames: sites.map(({ site, name }) => ({ value: site, label: name })),
        numberOfSites: sites.length,
        sites,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_SITES_ERROR,
        message: 'Failed to fetch sites.',
      });
    });
};
