import * as Joi from 'joi';

import Label from '../types/Label';

const isLabel = (value: unknown): value is Label => {
  const schema = Joi.object({
    id: Joi.string().required(),
    text: Joi.string().required(),
    foodWaitingTime: Joi.number(),
    drinksWaitingTime: Joi.number(),
    openingTimes: Joi.array().items(Joi.object({
      closed: Joi.boolean(),
      close: Joi.string(),
      kitchenOpen: Joi.string(),
      open: Joi.string(),
      kitchenClose: Joi.string(),
      day: Joi.number(),
    })).allow(null),
  }).unknown();

  const joiResult = schema.validate(value);
  return joiResult.error == null;
};

export default isLabel;
