import downloadFile from '../../../helpers/downloadFile';

import PayoutsReportData from '../../../types/PayoutsReportData';

const downloadPayoutsReport = (payoutsData: PayoutsReportData[]) => {
  const payoutsReport: string[] = [];
  payoutsData.forEach((payout) => {
    payout.items.forEach((item) => {
      const {
        orderNumber, orderTime, tradingDate, payoutDate, totalPayout, reportingLabels,
      } = item;
      payoutsReport.push([
        orderNumber,
        orderTime,
        tradingDate,
        payoutDate,
        totalPayout,
        reportingLabels.join(';'),
      ].join(','));
    });
  });

  return downloadFile({
    data: ['Order Number,Time,Trading Date,Payout Date, Amount, Label', ...payoutsReport].join('\n'),
    fileName: 'payoutsReport.csv',
    fileType: 'text/csv',
  });
};

export default downloadPayoutsReport;
