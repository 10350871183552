export const LOGIN = '/';

export const FORGOT_PASSWORD = '/forgot-password';

export const HOME = '/';

export const STRIPE_SETUP = '/stripesetup';

export const STRIPE_COMPLETE = '/stripecomplete';

export const NOTIFICATIONS = '/notifications';

export const NOTIFICATION = '/notifications/:notificationId';

export const ADMIN = '/admin';

export const UNPROCESSED_ORDERS = '/admin/unprocessed-orders';

export const UNPAID_ORDERS = '/admin/unpaid-orders';

export const SITES = '/admin/sites';

export const ADD_SITE = '/admin/sites/add';

export const USERS = '/admin/users';

export const ADD_USER = '/admin/users/add';

export const EDIT_USER = '/admin/users/:userId';

export const UPLOAD_PRODUCTS = '/admin/sites/product-upload';

export const QUICK_SETTINGS = '/quick-settings';

export const SETTINGS_PAGE = '/settings';

export const SLOTS = '/settings/slots';

export const SLOT_TIMES = '/settings/slots/:day';

export const PROFILE = '/profile';

export const SITE_MAP = '/site-map';

export const CATEGORY = '/site-map/:category';

export const ADD_SUB_CATEGORY = '/site-map/:category/add';

export const SUB_CATEGORY = '/site-map/:category/:subCategory';

export const PRODUCTS = '/products';

export const ADD_PRODUCT = '/add-product';

export const VIEW_PRODUCT = '/products/:productId';

export const EDIT_PRODUCT = '/products/:productId/edit';

export const SITE_ORDERS = '/orders';

export const SITE_UNPAID_ORDERS = '/unpaid-orders';

export const REFUNDED_ORDERS = '/refunded-orders';

export const SITE_UNPROCESSED_ORDERS = '/unprocessed-orders';

export const DISCOUNTS_PAGE = '/discounts';

export const ORDERS = '/admin/orders';

export const ORDER = '/orders/:orderNumber';

export const SITE_DASHBOARD = '/dashboard';

export const DISPENSE_SCREEN = '/dispense-screen';
