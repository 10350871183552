import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Tooltip } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import apiRequest from '../../../helpers/api-request';
import Nullable from '../../../types/Nullable';

interface Props {
  siteId: Nullable<string>;
  locationId: Nullable<string>;
  onConnectClick: () => void;
  onDisconnectClick: () => void;
}

const ReaderButton = ({
  siteId,
  locationId,
  onConnectClick,
  onDisconnectClick,
}: Props) => {
  const [reader, setReader] = useState<Nullable<{
    id: string;
    label: string;
    status: string;
  }>>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Nullable<Error>>(null);

  useEffect(() => {
    setLoading(true);
    if (locationId == null) {
      setReader(null);
      setLoading(false);
      return;
    }
    if (siteId == null) {
      setError(new Error('Missing settings data.'));
      return;
    }
    apiRequest(`sites/${siteId}/terminal/locations/${locationId}/readers`, 'GET')
      .then(({ data: readers }) => {
        if (readers.length === 0) return;
        setReader(readers[0]);
      }).catch((err) => {
        setError(err);
      }).finally(() => {
        setLoading(false);
      });
  }, [locationId]);

  if (loading) {
    return <CircularProgress size="2em" />;
  }

  if (error != null) {
    return (
      <Tooltip title={`Error: ${error.message}`}>
        <Warning color="secondary" />
      </Tooltip>
    );
  }

  if (reader == null) {
    return (
      <Button variant="contained" onClick={onConnectClick}>
        Connect Reader
      </Button>
    );
  }

  return (
    <Button variant="outlined" onClick={onDisconnectClick}>
      {`${reader.label} Connected`}
    </Button>
  );
};

export default ReaderButton;
