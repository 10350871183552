export default {
  AUTHENTICATION: {
    IS_LOGGING_IN: 'IS_LOGGING_IN',
    IS_VERIFYING_USER: 'IS_VERIFYING_USER',
    IS_AUTHORISED: 'IS_AUTHORISED',
    AUTHORISATION_ERROR_MESSAGE: 'AUTHORISATION_ERROR_MESSAGE',
    USER: 'USER',
  },
  FILE_DATA: {
    IS_FILE_DATA_LOADING: 'IS_FILE_DATA_LOADING',
    FILE_DATA: 'FILE_DATA',
    FILE_DATA_LOADING_ERROR: 'FILE_DATA_LOADING_ERROR',
  },
  SITE_ORDERS: {
    IS_SITE_ORDERS_LOADING: 'IS_SITE_ORDERS_LOADING',
    NUMBER_OF_ORDERS: 'NUMBER_OF_ORDERS',
    NUMBER_OF_ORDERS_TODAY: 'NUMBER_OF_ORDERS_TODAY',
    UNPROCESSED_ORDERS: 'UNPROCESSED_ORDERS',
    UNPROCESSED_ORDERS_TODAY: 'UNPROCESSED_ORDERS_TODAY',
    AMOUNT_OF_SALES: 'AMOUNT_OF_SALES',
    AMOUNT_OF_SALES_TODAY: 'AMOUNT_OF_SALES_TODAY',
    TRACK_TRACE_INFO: 'TRACK_TRACE_INFO',
    SITE_ORDERS: 'SITE_ORDERS',
    SITE_ORDERS_LOADING_ERROR: 'SITE_ORDERS_LOADING_ERROR',
  },
  ORDER_DATA: {
    IS_ORDER_DATA_LOADING: 'IS_ORDER_DATA_LOADING',
    ORDER_DATA: 'ORDER_DATA',
    ORDER_DATA_LOADING_ERROR: 'ORDER_DATA_LOADING_ERROR',
  },
  SETTINGS: {
    IS_SETTINGS_LOADING: 'IS_SETTINGS_LOADING',
    IS_SITE_ACTIVE: 'IS_SITE_ACTIVE',
    SITE_SLOT_TIMES: 'SITE_SLOT_TIMES',
    SETTINGS: 'SETTINGS',
    SITE_SETTINGS_ERROR: 'SITE_SETTINGS_ERROR',
    SETTINGS_LOADING_ERROR: 'SETTINGS_LOADING_ERROR',
  },
  SITES: {
    IS_SITES_LOADING: 'IS_SITES_LOADING',
    SITE_NAMES: 'SITE_NAMES',
    NUMBER_OF_SITES: 'NUMBER_OF_SITES',
    SITES: 'SITES',
    SITES_LOADING_ERROR: 'SITES_LOADING_ERROR',
  },
  PRODUCTS: {
    IS_PRODUCTS_LOADING: 'IS_PRODUCTS_LOADING',
    AMOUNT_OF_PRODUCTS: 'AMOUNT_OF_PRODUCTS',
    PRODS: 'PRODS',
    PRODUCTS: 'PRODUCTS',
    PRODUCT_ID: 'PRODUCT_ID',
    PRODUCTS_LOADING_ERROR: 'PRODUCTS_LOADING_ERROR',
  },
  ORDERS: {
    IS_ORDERS_LOADING: 'IS_ORDERS_LOADING',
    ORDERS: 'ORDERS',
    NUMBER_OF_ORDERS: 'NUMBER_OF_ORDERS',
    AMOUNT_OF_SALES: 'AMOUNT_OF_SALES',
    UNPROCESSED_ORDERS: 'UNPROCESSED_ORDERS',
    NUMBER_OF_UNPROCESSED_ORDERS: 'NUMBER_OF_UNPROCESSED_ORDERS',
    ORDERS_LOADING_ERROR: 'ORDERS_LOADING_ERROR',
  },
  USERS: {
    IS_USERS_LOADING: 'IS_USERS_LOADING',
    NUMBER_OF_USERS: 'NUMBER_OF_USERS',
    USERS: 'USERS',
    USERS_LOADING_ERROR: 'USERS_LOADING_ERROR',
  },
  SITE_MAP: {
    IS_SITE_MAP_LOADING: 'IS_SITE_MAP_LOADING',
    SITE_MAP_ID: 'SITE_MAP_ID',
    SITE_MAP: 'SITE_MAP',
    SITE_MAP_LOADING_ERROR: 'SITE_MAP_LOADING_ERROR',
  },
  DISCOUNT_CODES: {
    IS_DISCOUNT_CODES_LOADING: 'IS_DISCOUNT_CODES_LOADING',
    DISCOUNT_CODES: 'DISCOUNT_CODES',
    DISCOUNT_CODES_LOADING_ERROR: 'DISCOUNT_CODES_LOADING_ERROR',
  },
  ORDER_STATS: {
    IS_ORDER_STATS_LOADING: 'IS_ORDER_STATS_LOADING',
    ORDER_STATS: 'ORDER_STATS',
    ORDER_STATS_LOADING_ERROR: 'ORDER_STATS_LOADING_ERROR',
  },
  CHART_DATA: {
    IS_CHART_DATA_LOADING: 'IS_CHART_DATA_LOADING',
    CHART_DATA: 'CHART_DATA',
    CHART_DATA_LOADING_ERROR: 'CHART_DATA_LOADING_ERROR',
  },
  SLOT_TIMES: {
    IS_SLOT_TIMES_LOADING: 'IS_SLOT_TIMES_LOADING',
    SLOT_DAYS: 'SLOT_DAYS',
    SLOT_TIMES: 'SLOT_TIMES',
    SLOT_TIMES_LOADING_ERROR: 'SLOT_TIMES_LOADING_ERROR',
  },
  STATS: {
    IS_STATS_LOADING: 'IS_STATS_LOADING',
    TOTAL_ORDERS: 'TOTAL_ORDERS',
    TOTAL_SALES: 'TOTAL_SALES',
    STATS_LOADING_ERROR: 'STATS_LOADING_ERROR',
  },
  UNPROCESSED_ORDERS: {
    IS_UNPROCESSED_ORDERS_LOADING: 'IS_UNPROCESSED_ORDERS_LOADING',
    UNPROCESSED_ORDERS: 'UNPROCESSED_ORDERS',
    NUMBER_OF_UNPROCESSED_ORDERS: 'NUMBER_OF_UNPROCESSED_ORDERS',
    UNPROCESSED_ORDERS_LOADING_ERROR: 'UNPROCESSED_ORDERS_LOADING_ERROR',
  },
  NOTIFICATIONS: {
    IS_NOTIFICATIONS_LOADING: 'IS_NOTIFICATIONS_LOADING',
    ACTIVE_NOTIFICATIONS: 'ACTIVE_NOTIFICATIONS',
    NOTIFICATIONS: 'NOTIFICATIONS',
    NOTIFICATIONS_LOADING_ERROR: 'NOTIFICATIONS_LOADING_ERROR',
  },
};
