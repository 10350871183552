import { DateTime } from 'luxon';

// Converts a local timezone timestamp to a UTC timestamp.
// e.g. For local timezone UTC+1 converts "2023-01-01T09:45" to "2023-01-01T08:45".
const convertTimestampToUtc = (
  localTimestamp: string,
) => (
  DateTime
    .fromFormat(localTimestamp, 'yyyy-MM-dd\'T\'HH:mm')
    .toUTC()
    .toISO({ includeOffset: false })
);

export default convertTimestampToUtc;
