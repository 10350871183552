import React, { useMemo } from 'react';

import { Chip, Typography, makeStyles } from '@material-ui/core';
import { Redo } from '@material-ui/icons';

import formatMoney from '../../helpers/formatMoney';
import labelsFromIds from '../label-input/helpers/labels-from-ids';

import OrderItem from '../../types/OrderItem';
import Label from '../../types/Label';
import Currency from '../../types/Currency';

type Props = {
  product: OrderItem
  reportingLabels?: Label[]
  currency?: Currency
};

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(1, 1, 0, 0),
  },
}));

const ProductDescription = ({ product, reportingLabels, currency }: Props) => {
  const classes = useStyles();

  const reportingLabelText = useMemo(() => {
    if (product.reportingLabels == null || reportingLabels == null) return [];
    return labelsFromIds(product.reportingLabels, reportingLabels).map(({ text }) => text);
  }, [product, reportingLabels]);

  const refundLabel = useMemo(() => (product.quantity <= (product.quantityRefunded || 0)
    ? 'Refunded'
    : null), [product]);

  return (
    <>
      {product.addOns && product.addOns.length !== 0 ? product.addOns.map((addOn) => (
        <Typography key={addOn.plu} component="span" display="block" variant="body2">
          {`${addOn.name} ${addOn.price > 0 ? `+${formatMoney(addOn.price, currency)}` : ''} ${addOn.refunded ? '(Refunded)' : ''}`}
        </Typography>
      )) : null}
      {product.itemNotes != null && product.itemNotes.length !== 0 && (
        <Typography>
          {`"${product.itemNotes}"`}
        </Typography>
      )}
      {reportingLabelText.map(
        (labelText) => <Chip key={labelText} className={classes.chip} label={labelText} />,
      )}
      {refundLabel && (
        <Chip
          className={classes.chip}
          icon={<Redo />}
          label={refundLabel}
          variant="outlined"
        />
      )}
    </>
  );
};

export default ProductDescription;
