import Papa from 'papaparse';

import downloadFile from '../../../helpers/downloadFile';

const downloadDiscountCodes = (fileName: string) => {
  const data = Papa.unparse([
    { code: 'TEST1', maxUsages: null, usages: 0 },
    { code: 'TEST2', maxUsages: 1, usages: 0 },
    { code: 'TEST3', maxUsages: 10, usages: 0 },
  ]);

  return downloadFile({
    data,
    fileName,
    fileType: 'text/csv',
  });
};

export default downloadDiscountCodes;
