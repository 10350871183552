import firebase from 'firebase/app';

import uploadImage from './upload-image';
import getImageUrl from './get-image-url';

import Nullable from '../types/Nullable';
import ProductData from '../types/ProductData';

const updateProduct = (
  productId: string,
  productToChange: ProductData,
  productImage: Nullable<string>,
) => {
  const firebaseCollection = firebase
    .firestore()
    .collection('masterproducts');
  return !productImage || productImage.length === 0
    ? firebaseCollection
      .doc(productId)
      .update(productToChange)
    : uploadImage(productToChange.site, productImage)
      .then(({ ref }) => getImageUrl(ref.fullPath)
        .then((image) => firebaseCollection
          .doc(productId)
          .update({ ...productToChange, image })));
};

export default updateProduct;
