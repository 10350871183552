import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import firebase from 'firebase';
import { DateTime } from 'luxon';

import { DataGrid, GridCellParams } from '@material-ui/data-grid';
import {
  Box, Divider, IconButton, makeStyles, Paper, Tooltip,
} from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import LoopIcon from '@material-ui/icons/Loop';

import PaymentMethod from '../../../components/orders-table/components/payment-method';
import ErrorDialog from '../../../components/ErrorDialog/ErrorDialog';
import ReIssueDialog from './ReIssueDialog';
import HeaderItem from '../../../components/HeaderItem/HeaderItem';
import Spinner from '../../../components/spinner/spinner';

import isGiftCard from '../../../helpers/isGiftCard';
import formatMoney from '../../../helpers/formatMoney';
import formatNumber from '../../../helpers/formatNumber';
import getGiftCardStats from '../helpers/getGiftCardStats';

import AppUrl from '../../../types/AppUrl';
import Nullable from '../../../types/Nullable';
import SiteData from '../../../types/SiteData';
import GiftCard from '../../../types/GiftCard';
import User from '../../../types/User';

type Props = {
  user: User
  siteData: SiteData
  isImportJobActive: boolean
};

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    padding: theme.spacing(1),
  },
  divider: {
    marginY: theme.spacing(0.5),
  },
  paper: {
    marginBottom: theme.spacing(1),
  },
}));

const GiftCardsTable = ({ user, siteData, isImportJobActive }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [activeReIssuedGiftCard, setActiveReIssuedGiftCard] = useState<Nullable<GiftCard>>(null);
  const [giftCards, setGiftCards] = useState<GiftCard[]>([]);
  const [error, setError] = useState<Nullable<Error>>(null);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    const unsub = firebase
      .firestore()
      .collection('giftcards')
      .where('sites', 'array-contains', siteData.site)
      .onSnapshot((snapshot) => {
        const results: GiftCard[] = [];
        snapshot.docs.forEach((doc) => {
          const data = { id: doc.id, ...doc.data() };
          if (isGiftCard(data)) results.push(data);
        });
        setGiftCards(results);
        setIsLoading(false);
      }, (err) => {
        setError(err);
        setIsLoading(false);
      });
    return () => unsub();
  }, [siteData]);

  const handleReIssueGiftCard = (giftCard: GiftCard) => setActiveReIssuedGiftCard(giftCard);

  const handleViewHistory = useCallback((id: string) => history.push(AppUrl.GiftCard.replace(':giftCardId', id)), []);

  const handleErrorOkClick = () => {
    history.go(0);
  };

  const isUserAdmin = useMemo(() => user?.admin || false, [user]);

  const giftCardStats = useMemo(() => getGiftCardStats(giftCards), [giftCards]);

  if (isLoading) return <Spinner />;

  return (
    <>
      <Paper className={classes.paper}>
        <Box className={classes.rowContainer} flexWrap="nowrap">
          <HeaderItem
            label="Number of Gift Cards"
            value={formatNumber(giftCardStats.numberOfCards)}
            tooltip="Total number of gift cards"
          />
          <Divider className={classes.divider} flexItem orientation="vertical" />
          <HeaderItem
            label="Total Outstanding Balances"
            value={formatMoney(giftCardStats.total, siteData?.currency)}
            tooltip="Total outstanding balances of gift cards"
          />
          <Divider className={classes.divider} flexItem orientation="vertical" />
          <HeaderItem
            label="Number of Expired Gift Cards"
            value={formatNumber(giftCardStats.numberOfExpiredCards)}
            tooltip="Total number of expired gift cards"
          />
          <Divider className={classes.divider} flexItem orientation="vertical" />
          <HeaderItem
            label="Total Expired Balances"
            value={formatMoney(giftCardStats.expiredTotal, siteData?.currency)}
            tooltip="Total balances of expired gift cards"
          />
        </Box>
      </Paper>
      <DataGrid
        rows={giftCards}
        columns={[
          { field: 'code', headerName: 'Code', flex: 1 },
          {
            field: 'balance',
            headerName: 'Balance',
            flex: 1,
            renderCell: (params: GridCellParams) => (
              formatMoney(params.row.balance, siteData?.currency)
            ),
          },
          {
            field: 'purchaseDate',
            headerName: 'Purchase Date',
            flex: 1,
            renderCell: (params: GridCellParams) => DateTime
              .fromMillis(typeof params.row.purchaseDate === 'number' ? params.row.purchaseDate : 0)
              .toLocaleString(DateTime.DATETIME_MED),
          },
          {
            field: 'expiryDate',
            headerName: 'Expiry Date',
            flex: 1,
            renderCell: (params: GridCellParams) => (
              params.row.expiryDate != null
                ? DateTime
                  .fromMillis(params.row.expiryDate)
                  .toLocaleString()
                : 'No Expiry'
            ),
          },
          {
            field: 'paymentMethod',
            headerName: 'Payment Method',
            flex: 1,
            renderCell: (params: GridCellParams) => (
              <PaymentMethod method={params.row.paymentMethod} />
            ),
          },
          {
            field: 'other actions',
            headerName: 'Actions',
            flex: 0.75,
            renderCell: (params: GridCellParams) => (
              <>
                {isUserAdmin && !isImportJobActive && (
                  <Tooltip title="Re-issue Gift Card">
                    <IconButton
                      onClick={() => handleReIssueGiftCard(params.row as GiftCard)}
                      color="inherit"
                      disabled={params.row.balance === 0}
                    >
                      <LoopIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="View Gift Card History">
                  <IconButton
                    onClick={() => handleViewHistory(params.row.id)}
                    color="inherit"
                  >
                    <ListIcon />
                  </IconButton>
                </Tooltip>
              </>
            ),
          },
        ]}
        autoHeight
        pageSize={10}
        rowsPerPageOptions={[10]}
        sortModel={[{ field: 'purchaseDate', sort: 'desc' }]}
      />
      <ErrorDialog
        open={error != null}
        onOkButtonClick={handleErrorOkClick}
        message={error?.message}
      />
      <ReIssueDialog
        open={activeReIssuedGiftCard != null}
        user={user}
        activeGiftCard={activeReIssuedGiftCard}
        onClose={() => setActiveReIssuedGiftCard(null)}
      />
    </>
  );
};

export default GiftCardsTable;
