import React from 'react';
import {
  Box,
  Divider,
  LinearProgress,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import HeaderItem from '../../../components/HeaderItem/HeaderItem';
import TransactionsReportHeaderData from '../../../types/TransactionsReportHeaderData';
import Nullable from '../../../types/Nullable';
import formatMoney from '../../../helpers/formatMoney';
import SiteData from '../../../types/SiteData';

interface Props {
  isLoading: boolean
  siteData: Nullable<SiteData>
  totalValues?: TransactionsReportHeaderData['totalValues']
  chargeValues?: TransactionsReportHeaderData['chargeValues']
  payoutValues?: TransactionsReportHeaderData['payoutValues']
}

const useStyles = makeStyles((theme) => ({
  summaryBox: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  divider: {
    marginY: theme.spacing(0.5),
  },
  loading: {
    color: 'grey',
  },
  containerHeading: {
    marginBottom: theme.spacing(3),
  },
}));

const ProcessingFeeSummary = ({
  isLoading,
  totalValues,
  chargeValues,
  payoutValues,
  siteData,
}: Props) => {
  const classes = useStyles();

  return (
    <Paper className={isLoading ? classes.loading : ''}>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <Box className={classes.summaryBox}>
          <Typography className={classes.containerHeading} variant="h6">Processing fee (Service charge) summary</Typography>
          <Box className={classes.rowContainer} flexWrap="nowrap">
            <HeaderItem
              label="Total"
              value={formatMoney(totalValues || 0, siteData?.currency)}
              tooltip="Sum of processing fee item total"
            />
            <Divider className={classes.divider} flexItem orientation="vertical" />
            <HeaderItem
              label="Charges"
              value={formatMoney(chargeValues || 0, siteData?.currency)}
              tooltip="Sum of processing fee item charges"
            />
            <Divider className={classes.divider} flexItem orientation="vertical" />
            <HeaderItem
              label="Payout"
              value={formatMoney(payoutValues || 0, siteData?.currency)}
              tooltip="Sum of processing fee item payouts"
            />
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default ProcessingFeeSummary;
