import React,
{
  useCallback,
  useState,
  useMemo,
  ChangeEvent,
} from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  Button,
  Dialog,
  TextField,
  withStyles,
  Typography,
  DialogContent,
  DialogActions,
  LinearProgress,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import getSites from '../../actions/get-sites';

import OpeningTimeInput from '../OpeningTimeInput/OpeningTimeInput';

import createDefaultOpeningTimes from '../../helpers/createDefaultOpeningTimes';

import Nullable from '../../types/Nullable';
import Label from '../../types/Label';
import SiteOpeningTime from '../../types/SiteOpeningTime';

const styles = () => ({
  input: {
    width: 400,
    marginBottom: '1em',
  },
});

interface Props {
  classes: ClassNameMap<'input'>,
  open: boolean,
  setOpen: (value: boolean) => void,
  onLabelSave: (label: Label) => Promise<void>,
}

const LabelCreator = ({
  classes,
  open,
  setOpen,
  onLabelSave,
}: Props) => {
  const dispatch = useDispatch();
  const [labelText, setLabelText] = useState('');
  const [openingTimesActive, setOpeningTimesActive] = useState(false);
  const [openingTimes, setOpeningTimes] = useState<SiteOpeningTime[]>(
    createDefaultOpeningTimes(),
  );
  const [error, setError] = useState<Nullable<string>>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleCancelClick = useCallback(() => {
    setLabelText('');
    setError(null);
    setOpen(false);
  }, [setLabelText, setOpen, setError]);

  const handleOpeningTimesCheckboxClick = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setOpeningTimesActive(event.target.checked),
    [openingTimes, setOpeningTimes],
  );

  const openingTimeChangeHandlerFactory = useCallback(
    (day: number) => (updatedOpeningTime: SiteOpeningTime) => (
      setOpeningTimes((currentOpeningTimes) => {
        const updatedOpeningTimes = [...currentOpeningTimes];
        updatedOpeningTimes[day] = updatedOpeningTime;
        return updatedOpeningTimes;
      })
    ),
    [setOpeningTimes],
  );

  const handleSaveClick = useCallback(
    async () => {
      setLoading(true);
      const label: Label = {
        id: uuidv4(),
        text: labelText,
        openingTimes: openingTimesActive ? openingTimes : null,
      };
      try {
        setError(null);
        await onLabelSave(label);
        setLoading(false);
        setLabelText('');
        dispatch(getSites());
      } catch {
        setLoading(false);
        setError('Product label could not be created. Please refresh the page and try again.');
      }
    },
    [
      labelText,
      onLabelSave,
      setLabelText,
      openingTimes,
      openingTimesActive,
      setOpen,
      setError,
      dispatch,
    ],
  );

  const handleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setLabelText(e.target.value),
    [setLabelText],
  );

  const labelTextValid = useMemo(() => (
    /^[\w\d\s#%&+=?!]{1,25}$/i.test(labelText)
  ), [labelText]);

  return (
    <div>
      <Dialog
        open={open}
      >
        <DialogContent>
          <TextField
            className={classes.input}
            label="New Label"
            variant="outlined"
            onChange={handleTextChange}
            value={labelText}
          />
          <FormControlLabel
            label="Label Opening Times"
            control={(
              <Checkbox
                checked={openingTimesActive}
                onChange={handleOpeningTimesCheckboxClick}
              />
            )}
          />
          {openingTimesActive
            && (
              openingTimes.map((openingTime) => (
                <OpeningTimeInput
                  key={openingTime.day}
                  openingTime={openingTime}
                  onChange={openingTimeChangeHandlerFactory(openingTime.day)}
                />
              )))}
          {error && (
          <Typography>{error}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSaveClick}
            disabled={!labelTextValid || loading}
            variant="outlined"
            color="primary"
          >
            Add Label
          </Button>
          <Button
            onClick={handleCancelClick}
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
        {loading && (
          <LinearProgress />
        )}
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(LabelCreator);
