import Payment from '../types/Payment';
import BalancePaymentMethod from '../types/BalancePaymentMethod';
import capitalize from './capitalize';

const getPaymentLabel = (payment: Payment) => {
  let label: string;
  if (payment.type === BalancePaymentMethod.GiftCard) {
    label = 'Gift Card';
  } else if (payment.type === BalancePaymentMethod.Stripe) {
    label = 'Card';
  } else {
    label = capitalize(payment.name);
  }

  return label;
};

export default getPaymentLabel;
