import { firestore } from 'firebase/app';

import isUser from '../../../helpers/isUser';

import User from '../../../types/User';
import Nullable from '../../../types/Nullable';

const initialiseUserListener = (
  id: User['id'],
  setUser: React.Dispatch<React.SetStateAction<Nullable<User>>>,
  setError: React.Dispatch<React.SetStateAction<Nullable<Error>>>,
) => (
  firestore()
    .collection('userdata')
    .doc(id)
    .onSnapshot((doc) => {
      const latestUserData = { ...doc.data(), id: doc.id };
      if (isUser(latestUserData)) {
        setUser(latestUserData);
      } else {
        setError(new Error('user data could not be updated successfully.'));
      }
    })
);

export default initialiseUserListener;
