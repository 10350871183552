import React, { useEffect, useMemo, useState } from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core';

import SalesReportsHeader from './SalesReportHeader';
import getPaymentMethodsReport from '../helpers/getPaymentMethodsReport';

import Options from '../types/Options';
import SiteData from '../../../types/SiteData';
import User from '../../../types/User';
import Nullable from '../../../types/Nullable';
import PaymentMethodsTable from './PaymentMethodsTable';
import PaymentMethodsReportData from '../types/PaymentMethodsReportData';
import getSalesReportHeader from '../helpers/getSalesReportHeader';
import SalesReportHeaderData from '../types/SalesReportHeaderData';

type Props = {
  user: Nullable<User>
  siteData: Nullable<SiteData>
  options: Options
};

const SalesReportSection = ({ user, siteData, options }: Props) => {
  const [paymentMethodsData, setPaymentMethodsData] = useState<PaymentMethodsReportData>([]);
  const [
    salesReportHeaderData,
    setSalesReportHeaderData,
  ] = useState<Nullable<SalesReportHeaderData>>(null);
  const [isPaymentMethodsLoading, setIsPaymentMethodsLoading] = useState<boolean>(true);
  const [isSalesReportHeaderLoading, setIsSalesReportHeaderLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);

  const site = useMemo(() => {
    if (!user) return '';
    return user.site;
  }, [user]);

  const siteLabels = useMemo(() => {
    if (!siteData) return [];
    if (!siteData.reportingLabels) return [];
    return siteData.reportingLabels;
  }, [siteData]);

  const activeReportingLabels = useMemo(() => options.reportingLabels, [siteLabels, options]);

  useEffect(() => {
    getPaymentMethodsReport(site, options, user?.id, activeReportingLabels)
      .then((data) => {
        setPaymentMethodsData(data);
      })
      .catch(() => {
        setErrorMessage('Something went wrong getting Sales Reports');
      })
      .finally(() => {
        setIsPaymentMethodsLoading(false);
      });

    getSalesReportHeader(site, options, user?.id, activeReportingLabels)
      .then((data) => {
        setSalesReportHeaderData(data);
      })
      .catch(() => {
        setErrorMessage('Something went wrong getting Sales Reports');
      })
      .finally(() => {
        setIsSalesReportHeaderLoading(false);
      });
  }, [options]);

  if (errorMessage) {
    return (
      <Grid>
        <Typography align="center" color="error">{errorMessage}</Typography>
      </Grid>
    );
  }

  if (salesReportHeaderData && salesReportHeaderData.totalOrders === 0) {
    return (
      <Grid>
        <Typography align="center">Sorry but there is no data for this date range</Typography>
      </Grid>
    );
  }

  return (
    <Grid>
      <SalesReportsHeader
        data={salesReportHeaderData}
        siteData={siteData}
        isLoading={isSalesReportHeaderLoading}
        user={user}
        options={options}
      />
      <PaymentMethodsTable
        paymentMethodsData={paymentMethodsData}
        siteData={siteData}
        isLoading={isPaymentMethodsLoading}
      />
    </Grid>
  );
};

export default SalesReportSection;
