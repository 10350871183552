import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import firebase from 'firebase/app';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Lock, LockOpen } from '@material-ui/icons';

import DebouncedTextField from '../../../components/DebouncedTextField/DebouncedTextField';
import SimpleSelect from '../../../components/SimpleSelect/SimpleSelect';

import isSiteData from '../../../helpers/isSiteData';
import getSiteLabelsForSelect from '../helpers/getSiteLabelsForSelect';
import isValidProcessingFeeScheme from '../helpers/isValidProcessingFeeScheme';
import isUrlValid from '../helpers/isUrlValid';

import SiteData from '../../../types/SiteData';
import Nullable from '../../../types/Nullable';
import Status from '../../../types/Status';
import ProcessingFeeSchemeType from '../../../types/ProcessingFeeSchemeType';
import Label from '../../../types/Label';

import processingFeeSchemeOptions from '../constants/processingFeeSchemeOptions';

const useStyles = makeStyles((theme) => ({
  dialogHeadingBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  editButtonBox: {
    display: 'flex',
    alignItems: 'center',
  },
  editIconButton: {
    marginRight: theme.spacing(2),
  },
  InfoBox: {
    height: theme.spacing(5),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  simpleSelectInfoBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: theme.spacing(0, -2, 1, 0),
  },
  textField: {
    width: '40%',
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  labelSectionHeading: {
    marginBottom: theme.spacing(3),
  },
  stripeStatusChip: {
    width: '22%',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  loadingSpinnerBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '160px',
  },
  urlValidationError: {
    height: theme.spacing(5),
  },
  errorTypography: {
    marginLeft: theme.spacing(2),
  },
}));

interface Props {
  open: boolean
  selectedSiteId: Nullable<string>
  onCloseClick: () => void
}

const SiteDetailsDialog = ({
  open,
  selectedSiteId,
  onCloseClick,
}: Props) => {
  const classes = useStyles();

  const [isSiteUrlValid, setIsSiteUrlValid] = useState<boolean>(true);
  const [selectedSite, setSelectedSite] = useState<Nullable<SiteData>>(null);
  const [listenerLoading, setListenerLoading] = useState<boolean>(true);
  const [listenerError, setListenerError] = useState<Nullable<Error>>(null);

  const [editsEnabled, setEditsEnabled] = useState<boolean>(false);
  const [showEditButtonHelperText, setShowEditButtonHelperText] = useState<boolean>(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [updateError, setUpdateError] = useState<Nullable<Error>>(null);

  useEffect(() => {
    if (selectedSiteId == null) return undefined;
    setListenerLoading(true);
    setListenerError(null);
    const unsub = firebase
      .firestore()
      .collection('sites')
      .doc(selectedSiteId)
      .onSnapshot(
        (snapshot) => {
          const data = { ...snapshot.data(), id: snapshot.id };
          if (!isSiteData(data)) {
            setListenerError(new Error('Missing or Invalid sites data.'));
          } else {
            setSelectedSite(data);
            setListenerLoading(false);
          }
        },
        (err) => {
          setListenerError(err);
          setListenerLoading(false);
        },
      );
    return () => {
      setSelectedSite(null);
      unsub();
    };
  }, [selectedSiteId]);

  const siteLabels = useMemo(() => {
    if (selectedSite != null && selectedSite.reportingLabels != null) {
      return selectedSite?.reportingLabels.map((label) => (
        {
          labelText: label.text,
          labelStripeId: label.stripeAccountId,
          isLabelStripeSetup: label.stripeAccountSetupComplete,
        }
      ));
    }
    return [];
  }, [selectedSite]);

  const handleEnableEditButtonClick = () => {
    setEditsEnabled((currentState) => !currentState);
    setShowEditButtonHelperText(false);
  };

  const handleSwitchBoxClick = () => {
    if (!editsEnabled) setShowEditButtonHelperText(true);
  };

  const handleUrlEndpointChange = async (value: string) => {
    const urlEndpoint = value || '';
    const isUrlEndpointValid = await isUrlValid(selectedSite?.site || '', urlEndpoint);

    if (isUrlEndpointValid) {
      setIsSiteUrlValid(true);
      try {
        await firebase
          .firestore()
          .collection('sites')
          .doc(selectedSite?.id)
          .update({ urlEndpoint });
      } catch (err) {
        setUpdateError(err as Error);
      } finally {
        setUpdateLoading(false);
      }
    } else {
      setIsSiteUrlValid(false);
    }
  };

  const handleMerchantOfRecordChange = async (value: string) => {
    try {
      await firebase
        .firestore()
        .collection('sites')
        .doc(selectedSite?.id)
        .update({ merchantOfRecord: value });
    } catch (err) {
      setUpdateError(err as Error);
    } finally {
      setUpdateLoading(false);
    }
  };

  const handleSplitPayoutsChange = async (
    { target: { checked } }: ChangeEvent<HTMLInputElement>,
  ) => {
    setUpdateLoading(true);
    try {
      await firebase
        .firestore()
        .collection('sites')
        .doc(selectedSite?.id)
        .update({ splitPayouts: checked, splitTip: checked });
    } catch (err) {
      setUpdateError(err as Error);
    } finally {
      setUpdateLoading(false);
    }
  };

  const handleTransferCommissionChange = async (
    { target: { checked } }: ChangeEvent<HTMLInputElement>,
  ) => {
    setUpdateLoading(true);
    try {
      await firebase
        .firestore()
        .collection('sites')
        .doc(selectedSite?.id)
        .update({ transferCommission: checked });
    } catch (err) {
      setUpdateError(err as Error);
    } finally {
      setUpdateLoading(false);
    }
  };

  const handleSposFeaturesEnabledChange = async (
    { target: { checked } }: ChangeEvent<HTMLInputElement>,
  ) => {
    const defaultSposSettings: SiteData['spos'] = {
      consoleFeaturesEnabled: false,
      orderStatusOnPrint: Status.Complete,
    };
    const currentSposSettings = selectedSite?.spos || defaultSposSettings;
    const updatedSposSettings = {
      ...currentSposSettings,
      consoleFeaturesEnabled: checked,
    };

    setUpdateLoading(true);
    try {
      await firebase
        .firestore()
        .collection('sites')
        .doc(selectedSite?.id)
        .update({ spos: updatedSposSettings });
    } catch (err) {
      setUpdateError(err as Error);
    } finally {
      setUpdateLoading(false);
    }
  };

  const handleUnprocessedOrdersMessageChange = async (
    { target: { checked } }: ChangeEvent<HTMLInputElement>,
  ) => {
    setUpdateLoading(true);
    try {
      await firebase
        .firestore()
        .collection('sites')
        .doc(selectedSite?.id)
        .update({ isUnprocessedOrdersMessageEnabled: checked });
    } catch (err) {
      setUpdateError(err as Error);
    } finally {
      setUpdateLoading(false);
    }
  };

  const handleProcessingFeeSchemeChange = async (value: ProcessingFeeSchemeType) => {
    let labelId: Nullable<Label['id']> = null;
    if (
      value === ProcessingFeeSchemeType.PayToLabel
      && selectedSite != null
    ) {
      if (
        selectedSite.reportingLabels == null
        || selectedSite.reportingLabels.length === 0) return;
      labelId = selectedSite.reportingLabels[0].id;
    }
    const processingFeeSchemeUpdate = {
      labelId,
      type: value,
    };
    if (!isValidProcessingFeeScheme(processingFeeSchemeUpdate)) return;
    try {
      setUpdateLoading(true);
      await firebase
        .firestore()
        .collection('sites')
        .doc(selectedSite?.id)
        .update({ processingFeeScheme: processingFeeSchemeUpdate });
    } catch (err) {
      setUpdateError(err as Error);
    } finally {
      setUpdateLoading(false);
    }
  };

  const handleSchemeLabelSelectChange = async (value: Label['id']) => {
    const processingFeeSchemeUpdate: SiteData['processingFeeScheme'] = {
      type: ProcessingFeeSchemeType.PayToLabel,
      labelId: value,
    };
    if (!isValidProcessingFeeScheme(processingFeeSchemeUpdate)) return;
    try {
      setUpdateLoading(true);
      await firebase
        .firestore()
        .collection('sites')
        .doc(selectedSite?.id)
        .update({ processingFeeScheme: processingFeeSchemeUpdate });
    } catch (err) {
      setUpdateError(err as Error);
    } finally {
      setUpdateLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <Box className={classes.dialogHeadingBox}>
        <DialogTitle>{`${selectedSite ? selectedSite.name : 'Site'} Details`}</DialogTitle>
        <Box className={classes.editButtonBox}>
          {showEditButtonHelperText && (
            <Typography variant="caption">Click to Enable Editing</Typography>
          )}
          <IconButton
            color="primary"
            onClick={handleEnableEditButtonClick}
            className={classes.editIconButton}
          >
            {editsEnabled ? <LockOpen /> : <Lock />}
          </IconButton>
        </Box>
      </Box>
      <DialogContent>
        {listenerLoading
          ? <Box className={classes.loadingSpinnerBox}><CircularProgress /></Box>
          : (
            <>
              <Box className={classes.InfoBox} onClick={handleSwitchBoxClick}>
                <Typography>Site Url Endpoint</Typography>
                <DebouncedTextField
                  className={classes.textField}
                  value={selectedSite?.urlEndpoint || ''}
                  onChange={handleUrlEndpointChange}
                  disabled={!editsEnabled}
                />
              </Box>
              {!isSiteUrlValid && (
                <Typography className={classes.urlValidationError} color="error">
                  Url endpoint is already in use, please try another one.
                </Typography>
              )}
              <Box className={classes.InfoBox}>
                <Typography>Stripe Account Id</Typography>
                <Typography>{selectedSite?.stripeConnect ? selectedSite?.stripeConnect : 'No Stripe Account'}</Typography>
              </Box>
              <Box className={classes.InfoBox} onClick={handleSwitchBoxClick}>
                <Typography>Merchant of Record</Typography>
                <DebouncedTextField
                  className={classes.textField}
                  value={selectedSite?.merchantOfRecord || ''}
                  onChange={handleMerchantOfRecordChange}
                  disabled={!editsEnabled}
                />
              </Box>
              <Box className={classes.InfoBox} onClick={handleSwitchBoxClick}>
                <Typography>Split Payouts</Typography>
                <Checkbox
                  checked={selectedSite?.splitPayouts || false}
                  disabled={!editsEnabled}
                  onChange={handleSplitPayoutsChange}
                  color="primary"
                />
              </Box>
              {selectedSite?.splitPayouts && (
                <>
                  <Box className={classes.simpleSelectInfoBox} onClick={handleSwitchBoxClick}>
                    <SimpleSelect
                      label="Split Payout Processing Fee Scheme"
                      items={processingFeeSchemeOptions}
                      onChange={handleProcessingFeeSchemeChange}
                      allowEmpty={false}
                      size="small"
                      disabled={!editsEnabled}
                      labelWidth={270}
                      value={
                        selectedSite.processingFeeScheme?.type || ProcessingFeeSchemeType.PayToSite
                      }
                    />
                  </Box>
                  {
                    selectedSite.processingFeeScheme?.type === ProcessingFeeSchemeType.PayToLabel
                    && (
                      <Box className={classes.simpleSelectInfoBox} onClick={handleSwitchBoxClick}>
                        <SimpleSelect
                          label="Select reporting label for payout"
                          items={getSiteLabelsForSelect(selectedSite.reportingLabels)}
                          onChange={handleSchemeLabelSelectChange}
                          allowEmpty={false}
                          size="small"
                          disabled={!editsEnabled}
                          labelWidth={240}
                          value={selectedSite.processingFeeScheme?.labelId}
                        />
                      </Box>
                    )
                  }
                </>
              )}
              <Box className={classes.InfoBox} onClick={handleSwitchBoxClick}>
                <Typography>Transfer Commission</Typography>
                <Checkbox
                  checked={selectedSite?.transferCommission || false}
                  disabled={!editsEnabled}
                  onChange={handleTransferCommissionChange}
                  color="primary"
                />
              </Box>
              <Box className={classes.InfoBox} onClick={handleSwitchBoxClick}>
                <Typography>Console SPOS Features</Typography>
                <Checkbox
                  checked={selectedSite?.spos?.consoleFeaturesEnabled || false}
                  disabled={!editsEnabled}
                  onChange={handleSposFeaturesEnabledChange}
                  color="primary"
                />
              </Box>
              <Box className={classes.InfoBox} onClick={handleSwitchBoxClick}>
                <Typography>Outstanding SPOS Order Warning (MS Teams)</Typography>
                <Checkbox
                  checked={selectedSite?.isUnprocessedOrdersMessageEnabled || false}
                  disabled={!editsEnabled}
                  onChange={handleUnprocessedOrdersMessageChange}
                  color="primary"
                />
              </Box>
              {(selectedSite?.splitPayouts && siteLabels.length > 0) && (
                <>
                  <Divider className={classes.divider} />
                  <Typography className={classes.labelSectionHeading} variant="h6">
                    Reporting Label Details
                  </Typography>
                    {siteLabels.map((label) => (
                      <Box key={label.labelText}>
                        <Typography>{`${label.labelText}`}</Typography>
                        <Box className={classes.InfoBox}>
                          <Typography>{`${label.labelStripeId ? `stripe Id: ${label.labelStripeId}` : 'No id'}`}</Typography>
                          <Chip
                            className={classes.stripeStatusChip}
                            label={label.isLabelStripeSetup ? 'Setup Complete' : 'Requires Setup'}
                            color={label.isLabelStripeSetup ? 'primary' : 'secondary'}
                          />
                        </Box>
                      </Box>
                    ))}
                </>
              )}
            </>
          )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Box>
          {updateError && (
            <Typography className={classes.errorTypography} color="error">
              {selectedSite?.name}
              &nbsp;could not be updated. Please try again.
            </Typography>
          )}
          {listenerError && (
            <Typography className={classes.errorTypography} color="error">
              {selectedSite?.name}
              &nbsp;details could not be loaded.
            </Typography>
          )}
        </Box>
        <Button
          variant="contained"
          onClick={onCloseClick}
          disabled={updateLoading}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SiteDetailsDialog;
