import React, { useMemo } from 'react';
import {
  makeStyles,
  Divider,
  Box,
  Paper,
  LinearProgress,
} from '@material-ui/core';

import HeaderItem from '../../../components/HeaderItem/HeaderItem';
import formatMoney from '../../../helpers/formatMoney';
import formatNumber from '../../../helpers/formatNumber';
import hasPermission from '../../../helpers/has-permission';

import SalesReportHeaderData from '../types/SalesReportHeaderData';
import SiteData from '../../../types/SiteData';
import Nullable from '../../../types/Nullable';
import RoleRestrictedAction from '../../../types/RoleRestrictedAction';
import User from '../../../types/User';
import Options from '../types/Options';

type Props = {
  data: Nullable<SalesReportHeaderData>
  siteData: Nullable<SiteData>
  isLoading: boolean
  user: Nullable<User>
  options: Options
};

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    padding: theme.spacing(3),
  },
  divider: {
    marginY: theme.spacing(0.5),
  },
  loading: {
    color: 'grey',
  },
}));

const SalesReportsHeader = ({
  data,
  siteData,
  isLoading,
  user,
  options,
}: Props) => {
  const classes = useStyles();

  const showSiteTipFigure = useMemo(() => (
    !siteData?.splitPayouts
    && hasPermission(RoleRestrictedAction.ViewSiteTipShare, user)
  ), [siteData, user]);

  const showLabelTipFigure = useMemo(() => (
    siteData?.splitPayouts
    && hasPermission(RoleRestrictedAction.ViewLabelTips, user)
  ), [siteData, user]);

  const showSiteTipShareFigure = useMemo(() => (
    siteData?.splitPayouts
    && hasPermission(RoleRestrictedAction.ViewSiteTipShare, user)
    && options.reportingLabels.length === 0
  ), [siteData, user]);

  return (
    <Paper className={isLoading ? classes.loading : ''}>
      {isLoading && <LinearProgress />}
      <Box>
        <Box className={classes.rowContainer} flexWrap="nowrap">
          <HeaderItem
            label="Total Orders"
            value={formatNumber(data?.totalOrders || 0)}
            tooltip="Total number of orders"
          />
          <Divider className={classes.divider} flexItem orientation="vertical" />
          <HeaderItem
            label="Average Order Value"
            value={formatMoney(data?.averageValue || 0, siteData?.currency)}
            tooltip="Average order value"
          />
          {showSiteTipFigure && (
            <>
              <Divider className={classes.divider} flexItem orientation="vertical" />
              <HeaderItem
                label="Tips"
                value={formatMoney(data?.tipValue || 0, siteData?.currency)}
                tooltip="Total value of tips paid to site"
              />
            </>
          )}
          {showLabelTipFigure && (
            <>
              <Divider className={classes.divider} flexItem orientation="vertical" />
              <HeaderItem
                label="Label Tips"
                value={formatMoney(data?.tipShares.item || 0, siteData?.currency)}
                tooltip="Total value of tips paid to reporting labels"
              />
            </>
          )}
          {showSiteTipShareFigure && (
            <>
              <Divider className={classes.divider} flexItem orientation="vertical" />
              <HeaderItem
                label="Site Tips"
                value={formatMoney(data?.tipShares.site || 0, siteData?.currency)}
                tooltip="Total value of tips paid to site"
              />
            </>
          )}
          <Divider className={classes.divider} flexItem orientation="vertical" />
          <HeaderItem
            label="Other Charges"
            value={formatMoney(data?.totalServiceCharge || 0, siteData?.currency)}
            tooltip="Charges such as service charge, processing fees etc"
          />
          <Divider className={classes.divider} flexItem orientation="vertical" />
          <HeaderItem
            label="Total Value"
            value={formatMoney(data?.totalValue || 0, siteData?.currency)}
            tooltip="Total value including tips, discount and other charges"
          />
        </Box>
        <Box className={classes.rowContainer} flexWrap="nowrap">
          <HeaderItem
            label="Discount"
            value={formatMoney(data?.totalDiscount || 0, siteData?.currency)}
            tooltip="Total value of discounts"
          />
          <Divider className={classes.divider} flexItem orientation="vertical" />
          <HeaderItem
            label="Refunded Items"
            value={formatNumber(data?.refundedItems || 0)}
            tooltip="Number of refunds (NB: A single order may contain multiple refunded items)"
          />
          <Divider className={classes.divider} flexItem orientation="vertical" />
          <HeaderItem
            label="Refunded"
            value={formatMoney(Math.abs(data?.totalRefund || 0), siteData?.currency)}
            tooltip="Total value of refunds"
          />
          <Divider className={classes.divider} flexItem orientation="vertical" />
          <HeaderItem
            label="Full Refund Orders"
            value={formatNumber(data?.fullRefunds || 0)}
            tooltip="Total number of fully refunded orders"
          />
          <Divider className={classes.divider} flexItem orientation="vertical" />
          <HeaderItem
            label="Partial Refund Orders"
            value={formatNumber(data?.partialRefunds || 0)}
            tooltip="Number of orders containing a refund"
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default SalesReportsHeader;
