import React from 'react';

import {
  Chip, Grid, Typography, fade, makeStyles,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import SiteData from '../../../types/SiteData';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  activeColor: {
    borderColor: 'transparent',
    backgroundColor: fade(theme.palette.success.main, 0.2),
  },
  activeChipColor: { color: theme.palette.success.main },
  notActiveColor: {
    borderColor: 'transparent',
    backgroundColor: fade(theme.palette.error.main, 0.2),
  },
  notActiveChipColor: { color: theme.palette.error.main },
}));

type Props = {
  siteData: SiteData
};

const SiteTitle = ({ siteData }: Props) => {
  const classes = useStyles();

  const { active, name } = siteData;

  return (
    <Grid className={classes.title}>
      <Typography style={{ marginRight: 10 }} variant="h4">
        {name}
      </Typography>
      {active ? (
        <Chip
          className={classes.activeColor}
          label="Online"
          variant="outlined"
          size="small"
          icon={<CheckCircleIcon className={classes.activeChipColor} />}
        />
      ) : (
        <Chip
          className={classes.activeColor}
          label="Offline"
          variant="outlined"
          size="small"
          icon={<CancelIcon className={classes.notActiveChipColor} />}
        />
      )}
    </Grid>
  );
};

export default SiteTitle;
