import React,
{
  ChangeEvent,
  useCallback,
} from 'react';
import {
  makeStyles,
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@material-ui/core';

import getDayFromNumber from './helpers/getDayFromNumber';

import SiteOpeningTime from '../../types/SiteOpeningTime';

interface Props {
  openingTime: SiteOpeningTime,
  onChange: (updatedOpeningTime: SiteOpeningTime) => void,
  disabled?: boolean
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dayTextContainer: {
    width: 100,
  },
  timeInput: {
    margin: '0 0.5em 0 0.5em',
  },
  closedCheckbox: {
    marginLeft: '0.5em',
  },
});

const OpeningTimeInput = ({ openingTime, onChange, disabled }: Props) => {
  const classes = useStyles();

  const handleClosedCheckboxClick = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    onChange({ ...openingTime, closed: checked });
  }, [openingTime, onChange]);

  const handleOpeningTimeChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onChange({ ...openingTime, open: value, kitchenOpen: value });
  }, [openingTime, onChange]);

  const handleClosingTimeChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onChange({ ...openingTime, close: value, kitchenClose: value });
  }, [openingTime, onChange]);

  return (
    <Box className={classes.container}>
      <Typography className={classes.dayTextContainer}>
        {getDayFromNumber(openingTime.day)}
      </Typography>
      <TextField
        className={classes.timeInput}
        type="time"
        value={openingTime.closed ? '--:--' : openingTime.open}
        onChange={handleOpeningTimeChange}
        disabled={disabled}
      />
      <Typography>until</Typography>
      <TextField
        className={classes.timeInput}
        type="time"
        value={openingTime.closed ? '--:--' : openingTime.close}
        onChange={handleClosingTimeChange}
        disabled={disabled}
      />
      <FormControlLabel
        className={classes.closedCheckbox}
        label="Closed All Day"
        control={(
          <Checkbox
            checked={openingTime.closed}
            onChange={handleClosedCheckboxClick}
            disabled={disabled}
          />
        )}
      />
    </Box>
  );
};

export default OpeningTimeInput;
