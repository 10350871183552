import { Map } from 'immutable';
import {
  GET_ORDER_STATS_START,
  GET_ORDER_STATS_SUCCESS,
  GET_ORDER_STATS_ERROR,
} from '../constants/actions';
import STORE_KEYS from '../constants/store-keys';

const {
  IS_ORDER_STATS_LOADING,
  ORDER_STATS,
  ORDER_STATS_LOADING_ERROR,
} = STORE_KEYS.ORDER_STATS;

const initialState = {};
initialState[IS_ORDER_STATS_LOADING] = false;
initialState[ORDER_STATS] = null;
initialState[ORDER_STATS_LOADING_ERROR] = '';
const initialStateMap = Map(initialState);

export default (state = initialStateMap, action) => {
  switch (action.type) {
    case GET_ORDER_STATS_START:
      return state
        .set(IS_ORDER_STATS_LOADING, true)
        .set(ORDER_STATS, null)
        .set(ORDER_STATS_LOADING_ERROR, '');
    case GET_ORDER_STATS_SUCCESS:
      return state
        .set(IS_ORDER_STATS_LOADING, false)
        .set(ORDER_STATS, action.orderStats);
    case GET_ORDER_STATS_ERROR:
      return state
        .set(IS_ORDER_STATS_LOADING, false)
        .set(ORDER_STATS, null)
        .set(ORDER_STATS_LOADING_ERROR, action.message);
    default:
      return state;
  }
};
