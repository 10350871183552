import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import {
  Chip,
  Box,
  Tooltip,
  IconButton,
  capitalize,
  Typography,
  makeStyles,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { DataGrid, GridRowData } from '@material-ui/data-grid';
import { Delete, Edit } from '@material-ui/icons';

import Toolbar from '../pos-page/components/Toolbar';

import Nullable from '../../types/Nullable';
import ReduxState from '../../types/ReduxState';
import SiteData from '../../types/SiteData';
import User from '../../types/User';
import getSettings from '../../actions/get-settings';
import AddReaderDialog from './components/AddReaderDialog';
import EditReaderDialog from './components/EditReaderDialog';
import apiRequest from '../../helpers/api-request';
import StripeLocationTerminalConfig from '../../types/StripeTerminalConfig';
import uploadImage from '../../helpers/upload-image';
import getImageUrl from '../../helpers/get-image-url';
import getReaderImagePathFromUrl from '../../helpers/get-reader-image-path-from-url';
import getSiteSplashConfigId from '../../helpers/getSiteSplashConfigId';
import getSiteReaderSplashImage from '../../helpers/getSiteReaderSplashImage';
import getSplashImage from './helpers/getSplashImage';
import getFallbackSplashImage from './helpers/getFallbackSplashImage';
import TerminalSplashImage from '../../components/TerminalSplashImage/TerminalSplashImage';

const useStyles = makeStyles((theme) => ({
  splashBox: {
    marginTop: theme.spacing(3),
  },
  waitingBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: theme.spacing(12),
  },
}));

const PosLocationPage = ({
  match: { params: { locationId } },
}: RouteComponentProps<{ locationId: string }>) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const user = useSelector<ReduxState, Nullable<User>>(
    (state) => state.authentication.get('USER'),
  );
  const settings = useSelector<ReduxState, Nullable<SiteData>>(
    (state) => state.settings.get('SETTINGS'),
  );

  const [readers, setReaders] = useState<Array<{
    id: string;
    label: string;
    status: string;
  }>>([]);

  const [searchText, setSearchText] = useState<string>('');
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [tableError, setTableError] = useState<Nullable<Error>>(null);

  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
  const [readerToEdit, setReaderToEdit] = useState<Nullable<{
    id: string;
    label: string;
    status: string;
  }>>(null);
  const [dialogError, setDialogError] = useState<Nullable<Error>>(null);
  const [dialogLoading, setDialogLoading] = useState<boolean>(false);
  const [
    selectedLocation,
    setSelectedLocation,
  ] = useState<Nullable<StripeLocationTerminalConfig>>(null);
  const [
    newLocationConfig,
    setNewLocationConfig,
  ] = useState<Nullable<StripeLocationTerminalConfig>>(null);
  const [locationSplashImage, setLocationSplashImage] = useState<Nullable<File>>(null);
  const [splashError, setSplashError] = useState<Nullable<string>>(null);
  const [splashChanged, setSplashChanged] = useState<boolean>(false);
  const [updatingSplash, setUpdatingSplash] = useState<boolean>(false);

  const siteSplashConfig = useMemo(() => getSiteSplashConfigId(settings), [settings]);
  const siteReaderSplashImage = useMemo(() => getSiteReaderSplashImage(settings), [settings]);

  const splashImage = useMemo(() => getSplashImage(
    selectedLocation, settings, siteSplashConfig, siteReaderSplashImage,
  ), [selectedLocation, settings, siteSplashConfig, siteReaderSplashImage]);

  const fallbackSplashImage = useMemo(() => getFallbackSplashImage(
    selectedLocation, settings, siteSplashConfig, siteReaderSplashImage,
  ), [selectedLocation, settings, siteReaderSplashImage]);

  useEffect(() => {
    const fetchLocationConfig = async () => {
      if (settings != null) {
        try {
          const res = await apiRequest(
            `sites/${settings.id}/locations/splash-screen/${locationId}`,
            'GET',
          );
          setSelectedLocation(res.data);
          setNewLocationConfig(res.data);
        } catch (err) {
          setSplashError('Something went wrong');
        }
      }
    };
    fetchLocationConfig();
  }, [settings]);

  useEffect(() => {
    if (settings == null || settings.id == null) return;
    setTableLoading(false);
    apiRequest(
      `sites/${settings.id}/terminal/locations/${locationId}/readers`,
      'get',
    ).then(({ data }) => {
      setReaders(data);
    }).catch((error) => {
      setTableError(error);
    }).finally(() => {
      setTableLoading(false);
    });
  }, [settings]);

  useEffect(() => {
    if (user != null) {
      dispatch(getSettings(user.site));
    }
  }, [user]);

  const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleClearSearchClick = () => {
    setSearchText('');
  };

  const handleAddReaderClick = () => {
    setAddDialogOpen(true);
  };

  const handleAddDialogSaveClick = async (
    label: string,
    pairingCode: string,
  ) => {
    setDialogError(null);
    setDialogLoading(true);
    try {
      if (settings == null || settings.id == null) {
        throw new Error('Missing siteId.');
      }
      await apiRequest(
        `sites/${settings.id}/terminal/locations/${locationId}/readers`,
        'post',
        { label, pairingCode },
      );
      history.go(0);
    } catch (error) {
      setDialogError(error as Error);
      setDialogLoading(false);
    }
  };

  const handleAddDialogCancelClick = () => {
    setDialogError(null);
    setAddDialogOpen(false);
  };

  const handleEditClick = (row: GridRowData) => {
    const reader = row as { id: string; label: string; status: string };
    setReaderToEdit(reader);
  };

  const handleEditDialogSaveClick = async (
    readerId: string,
    label: string,
  ) => {
    setDialogError(null);
    setDialogLoading(true);
    try {
      if (settings == null || settings.id == null) {
        throw new Error('Missing siteId.');
      }
      await apiRequest(
        `sites/${settings.id}/terminal/locations/${locationId}/readers/${readerId}`,
        'put',
        { label },
      );
      history.go(0);
    } catch (error) {
      setDialogError(error as Error);
      setDialogLoading(false);
    }
  };

  const handleEditDialogCancelClick = () => {
    setDialogError(null);
    setReaderToEdit(null);
  };

  const handleDeleteClick = async (row: GridRowData) => {
    const {
      id: readerId,
    } = row as { id: string; label: string; status: string };
    setTableError(null);
    setTableLoading(true);
    try {
      if (settings == null || settings.id == null) {
        throw new Error('Missing siteId.');
      }
      await apiRequest(
        `sites/${settings.id}/terminal/locations/${locationId}/readers/${readerId}`,
        'delete',
      );
      history.go(0);
    } catch (error) {
      setTableError(error as Error);
      setTableLoading(false);
    }
  };

  const handleSplashSave = async () => {
    if (
      locationSplashImage != null
      && settings != null
      && selectedLocation != null
      && newLocationConfig != null
    ) {
      setSplashChanged(false);
      setUpdatingSplash(true);
      const { ref } = await uploadImage(settings.site, locationSplashImage);
      const readerImageUrl = await getImageUrl(ref.fullPath);
      if (
        selectedLocation.configuration_overrides == null
        || selectedLocation.configuration_overrides.length === 0
        || selectedLocation.configuration_overrides === siteSplashConfig
      ) {
        const { data: { splashConfigId } } = await apiRequest(
          `sites/${settings.id}/locations/splash-screen/${locationId}`,
          'post',
          {
            storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
            readerImagePath: ref.fullPath,
            type: locationSplashImage.type,
            readerImageUrl,
          },
        );
        const returnedConfig = {
          ...selectedLocation,
          configuration_overrides: splashConfigId,
          metadata: {
            splashImage: readerImageUrl,
          },
        };
        setSelectedLocation(returnedConfig);
        setNewLocationConfig(returnedConfig);
      } else {
        await apiRequest(
          `sites/${settings.id}/locations/splash-screen/${selectedLocation.id}`,
          'put',
          {
            splashConfigId: newLocationConfig.configuration_overrides,
            storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
            readerImagePath: ref.fullPath,
            type: locationSplashImage.type,
            readerImageUrl,
          },
        );
      }
      setUpdatingSplash(false);
    }
  };

  const handleSplashChange = (preview: string) => {
    if (selectedLocation != null) {
      setSplashChanged(true);
      setNewLocationConfig({
        ...selectedLocation,
        metadata: {
          splashImage: preview,
        },
      });
    }
  };

  const handleSplashRemove = async () => {
    setSplashChanged(false);
    if (
      settings != null
      && selectedLocation != null
      && typeof selectedLocation.metadata.splashImage === 'string'
    ) {
      if (selectedLocation.metadata.splashImage != null) {
        const readerImagePath = decodeURIComponent(
          getReaderImagePathFromUrl(selectedLocation.metadata.splashImage),
        );
        await apiRequest(
          `sites/${settings.id}/locations/splash-screen/${locationId}`,
          'delete',
          {
            readerImagePath,
            splashConfigId: selectedLocation.configuration_overrides,
            storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
            siteSplashConfig,
          },
        );
        const updateLocation = {
          ...selectedLocation,
          configuration_overrides: siteSplashConfig,
        };
        delete updateLocation.metadata.splashImage;
        setSelectedLocation(updateLocation);
        setNewLocationConfig(updateLocation);
      }
    }
  };

  const splashDescriptionText = useMemo(() => {
    if (settings == null) return 'Peazi';

    return siteReaderSplashImage != null && siteReaderSplashImage.length > 0
      ? `${settings.name}`
      : 'Peazi';
  }, [settings, siteReaderSplashImage]);

  return (
    <>
      <DataGrid
        components={{ Toolbar }}
        columns={[
          { field: 'label', headerName: 'Payment Device', flex: 2 },
          { field: 'id', headerName: 'ID', flex: 2 },
          {
            field: 'status',
            headerName: 'Status',
            flex: 2,
            renderCell: (params) => (
              <Chip
                variant="outlined"
                label={capitalize(params.row.status)}
              />
            ),
          },
          {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => (
              <Box>
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => handleEditClick(params.row)}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Remove">
                  <IconButton
                    onClick={() => handleDeleteClick(params.row)}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Box>
            ),
          },
        ]}
        rows={readers}
        loading={tableLoading}
        error={tableError}
        autoHeight
        pageSize={10}
        rowsPerPageOptions={[10]}
        componentsProps={{
          toolbar: {
            value: searchText,
            onSearchTextChange: handleSearchTextChange,
            onSearchTextClear: handleClearSearchClick,
            onButtonClick: handleAddReaderClick,
            buttonTitle: 'Add Device',
          },
        }}
      />
      <Box
        className={classes.splashBox}
      >
        {updatingSplash && (
          <Box className={classes.waitingBox}>
            <CircularProgress />
          </Box>
        )}
        {splashChanged && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSplashSave}
          >
            Save new splash image
          </Button>
        )}
        {selectedLocation != null && (
          <TerminalSplashImage
            readerSplashImage={splashImage}
            handleChange={handleSplashChange}
            handleRemove={handleSplashRemove}
            setError={setSplashError}
            setSplashImage={setLocationSplashImage}
            descriptionText={
              `You can replace the default
              ${splashDescriptionText} splash screen with your own image.`
            }
            fallbackImage={fallbackSplashImage}
          />
        )}
      </Box>
      {splashError && (
        <Typography>{splashError}</Typography>
      )}
      <AddReaderDialog
        open={addDialogOpen}
        onSaveClick={handleAddDialogSaveClick}
        onCancelClick={handleAddDialogCancelClick}
        error={dialogError}
        loading={dialogLoading}
      />
      {readerToEdit && (
        <EditReaderDialog
          reader={readerToEdit}
          open={readerToEdit != null}
          onSaveClick={handleEditDialogSaveClick}
          onCancelClick={handleEditDialogCancelClick}
          error={dialogError}
          loading={dialogLoading}
        />
      )}
    </>
  );
};

export default PosLocationPage;
