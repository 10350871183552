import React from 'react';

import { Typography, Grid, makeStyles } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(() => ({
  field: {
    marginBottom: 5,
  },
  title: {
    fontWeight: 'bold',
    marginRight: 5,
  },
}));

type Props = {
  title: string
  value: boolean
};

const CheckerField = ({ title, value }: Props) => {
  const classes = useStyles();

  return (
    <Grid
      className={classes.field}
      container
      direction="row"
      alignItems="center"
    >
      <Typography className={classes.title}>{`${title}:`}</Typography>
      {value
        ? <CheckCircleIcon style={{ color: 'green' }} />
        : <CancelIcon style={{ color: 'red' }} />}
    </Grid>
  );
};

export default CheckerField;
