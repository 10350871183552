import User from '../types/User';
import UserRole from '../types/UserRole';
import RoleRestrictedAction from '../types/RoleRestrictedAction';
import never from './never';
import Nullable from '../types/Nullable';

export default (action: RoleRestrictedAction, user: Nullable<User>): boolean => {
  if (user == null) return false;

  const { sites, admin, site } = user;

  if (admin) return true;

  const userConfig = sites.find((config) => config.site === site);
  if (!userConfig) return false;

  const { role, reportingLabels } = userConfig;

  switch (action) {
    case RoleRestrictedAction.ConfigureStripe:
    case RoleRestrictedAction.ViewServiceChargeSummary:
    case RoleRestrictedAction.ViewSiteTipShare:
    case RoleRestrictedAction.EditCategorySequence:
    case RoleRestrictedAction.ViewDiscount:
    case RoleRestrictedAction.ViewCloudPrinter:
    case RoleRestrictedAction.ViewTransactionReportSummaries:
    case RoleRestrictedAction.CreateNewSearchLabels:
      return [UserRole.SuperUser, UserRole.Manager].includes(role) && reportingLabels.length === 0;
    case RoleRestrictedAction.AddProduct:
    case RoleRestrictedAction.SetProductShow:
    case RoleRestrictedAction.RemoveProduct:
    case RoleRestrictedAction.SetProductOutOfStock:
    case RoleRestrictedAction.ViewProduct:
    case RoleRestrictedAction.DownloadProductCSV:
    case RoleRestrictedAction.EditProduct:
    case RoleRestrictedAction.CreateCategory:
    case RoleRestrictedAction.CreateSubCategory:
    case RoleRestrictedAction.EditCategory:
    case RoleRestrictedAction.EditSiteSettings:
    case RoleRestrictedAction.GenerateSlotTimes:
    case RoleRestrictedAction.EditSlotTime:
    case RoleRestrictedAction.RemoveSlotTime:
    case RoleRestrictedAction.RemoveSlotDay:
    case RoleRestrictedAction.CreateSlotDay:
    case RoleRestrictedAction.CreateSlotTime:
    case RoleRestrictedAction.ProcessOrder:
    case RoleRestrictedAction.ViewDiscountCode:
    case RoleRestrictedAction.CreateDiscountCode:
    case RoleRestrictedAction.EditDiscountCode:
    case RoleRestrictedAction.RemoveDiscountCode:
      return [UserRole.SuperUser, UserRole.Manager, UserRole.User].includes(role);
    case RoleRestrictedAction.ViewOrder:
      return (
        [UserRole.SuperUser, UserRole.Manager, UserRole.User, UserRole.Restricted].includes(role)
      );
    case RoleRestrictedAction.UploadProductCSV:
      return false;
    case RoleRestrictedAction.RefundOrder:
    case RoleRestrictedAction.EditReportingLabelText:
    case RoleRestrictedAction.ViewLabelPaymentSettings:
    case RoleRestrictedAction.SetLabelVendorVatStatus:
    case RoleRestrictedAction.ManageLabelStripeAccount:
    case RoleRestrictedAction.SetLabelOffline:
    case RoleRestrictedAction.SetSiteOffline:
    case RoleRestrictedAction.ViewLabelTips:
      return [UserRole.SuperUser, UserRole.Manager].includes(role);
    case RoleRestrictedAction.SetLabelPaymentEnabled:
    case RoleRestrictedAction.ControlAllQuickSettings:
    case RoleRestrictedAction.SetLabelVatStatusAfterInitialSetup:
      return UserRole.SuperUser === role;
    case RoleRestrictedAction.SetLabelPaymentCommission:
      return UserRole.SuperUser === role && reportingLabels.length === 0;
    default:
      never(action);
      return false;
  }
};
