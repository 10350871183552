import React, {
  ChangeEvent,
  useCallback,
  useMemo,
} from 'react';
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  LinearProgress,
  DialogContent,
  FormControlLabel,
  Checkbox,
  TextField,
  InputAdornment,
  DialogContentText,
  Divider,
} from '@material-ui/core';

import hasPermission from '../../../helpers/has-permission';

import RoleRestrictedAction from '../../../types/RoleRestrictedAction';
import User from '../../../types/User';
import PaymentFormValues from '../../../types/PaymentFormValues';
import Label from '../../../types/Label';
import StripeStatementDescriptorDialog from '../../../components/StripeStatementDescriptorDialog/StripeStatementDescriptorDialog';
import KeyField from '../../../components/key-field/key-field';

interface Props {
  open: boolean,
  loading: boolean,
  error: boolean,
  user: User,
  paymentFormValues: PaymentFormValues,
  onPaymentFormValuesChange: (newValue: PaymentFormValues) => void,
  onCancelClick: () => void,
  onPaymentOptionsSaveClick: () => void,
  onSetupStripeButtonClick: () => Promise<void>,
  siteUsesTipjar: boolean,
  storedLabelSettings: Label,
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  commissionInput: {
    width: 150,
    margin: theme.spacing(1.5, 0, 1, 0),
  },
  textInput: {
    width: 250,
    margin: theme.spacing(1, 0, 1, 0),
  },
  stripeButton: {
    width: 400,
    margin: theme.spacing(1, 0, 2, 0),
  },
  errorText: {
    alignSelf: 'center',
  },
  vatCheckbox: {
    margin: theme.spacing(1, 0, 0.5, 0),
  },
  dialogActions: {
    margin: theme.spacing(3),
  },
  stripeDivider: {
    margin: theme.spacing(1, 0, 2),
  },
}));

const LabelPaymentInfoForm = (
  {
    open,
    loading,
    error,
    user,
    paymentFormValues,
    onPaymentFormValuesChange,
    onCancelClick,
    onPaymentOptionsSaveClick,
    onSetupStripeButtonClick,
    siteUsesTipjar,
    storedLabelSettings,
  }: Props,
) => {
  const classes = useStyles();

  const onCommissionPercentChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => (
      onPaymentFormValuesChange({ ...paymentFormValues, commissionPercent: event.target.value })
    ),
    [onPaymentFormValuesChange, paymentFormValues],
  );

  const onTipjarShortcutCodeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => (
      onPaymentFormValuesChange({ ...paymentFormValues, tipjarShortcutCode: event.target.value })
    ),
    [onPaymentFormValuesChange, paymentFormValues],
  );

  const onVatRegisteredChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => (
      onPaymentFormValuesChange({ ...paymentFormValues, vatRegistered: event.target.checked })
    ),
    [onPaymentFormValuesChange, paymentFormValues],
  );

  const onVatNumberChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => (
      onPaymentFormValuesChange({ ...paymentFormValues, vatNumber: event.target.value })
    ),
    [onPaymentFormValuesChange, paymentFormValues],
  );

  const onCompanyNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => (
      onPaymentFormValuesChange({ ...paymentFormValues, companyName: event.target.value })
    ),
    [onPaymentFormValuesChange, paymentFormValues],
  );

  const isFormValid = useMemo(() => {
    const { vatRegistered, vatNumber } = paymentFormValues;
    return (
      !vatRegistered
      || (vatNumber != null && /^[A-Z]{2}[0-9]{9}$/.test(vatNumber))
    );
  }, [paymentFormValues]);

  const isVatRegistrationOptionEnabled = useMemo(() => (
    (hasPermission(RoleRestrictedAction.SetLabelVendorVatStatus, user)
      && storedLabelSettings.vatRegistered !== true)
    || hasPermission(RoleRestrictedAction.SetLabelVatStatusAfterInitialSetup, user)
  ), [user, storedLabelSettings]);

  const isVatRegistrationNumberFieldEnabled = useMemo(() => (
    (hasPermission(RoleRestrictedAction.SetLabelVendorVatStatus, user)
      && paymentFormValues.vatRegistered
      && storedLabelSettings.vatNumber == null)
    || hasPermission(RoleRestrictedAction.SetLabelVatStatusAfterInitialSetup, user)
  ), [user, storedLabelSettings, paymentFormValues]);

  const isCompanyNameFieldEnabled = useMemo(() => (
    (hasPermission(RoleRestrictedAction.SetLabelVendorVatStatus, user)
      && storedLabelSettings.companyName == null)
    || hasPermission(RoleRestrictedAction.SetLabelVatStatusAfterInitialSetup, user)
  ), [user, storedLabelSettings]);

  const isTipjarShortcutCodeFieldEnabled = useMemo(() => (
    (hasPermission(RoleRestrictedAction.SetLabelVendorVatStatus, user)
      && storedLabelSettings.tipjarShortcutCode == null)
    || hasPermission(RoleRestrictedAction.SetLabelVatStatusAfterInitialSetup, user)
  ), [user, storedLabelSettings]);

  return (
    <>
      <Dialog
        open={open}
      >
        {loading && (
          <LinearProgress />
        )}
        <DialogTitle>Reporting Label Payment Settings</DialogTitle>
        <DialogContent className={classes.container}>
          <TextField
            className={classes.commissionInput}
            disabled={!hasPermission(RoleRestrictedAction.SetLabelPaymentCommission, user)}
            value={paymentFormValues.commissionPercent}
            onChange={onCommissionPercentChange}
            variant="outlined"
            label="Site Commission"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
          {siteUsesTipjar && (
            <TextField
              className={classes.textInput}
              disabled={!isTipjarShortcutCodeFieldEnabled}
              value={paymentFormValues.tipjarShortcutCode}
              onChange={onTipjarShortcutCodeChange}
              variant="outlined"
              label="Tipjar Shortcut Code"
            />
          )}
          {paymentFormValues.stripeAccountId.length > 0 && (
            <>
              <Divider className={classes.stripeDivider} />
              <KeyField
                title="Stripe account id"
                normalWeightTitleFont
                info={paymentFormValues.stripeAccountId}
              />
              <StripeStatementDescriptorDialog
                stripeConnectId={storedLabelSettings.stripeAccountId}
                normalWeightTitleFont
                fullWidthButton
              />
            </>
          )}
          <Button
            className={classes.stripeButton}
            disabled={
              !hasPermission(RoleRestrictedAction.ManageLabelStripeAccount, user)
            }
            onClick={onSetupStripeButtonClick}
            color="primary"
            variant="outlined"
          >
            {(
              storedLabelSettings.stripeAccountId != null
              && storedLabelSettings.stripeAccountId.length > 0
            )
              ? 'Visit Stripe Dashboard'
              : 'Set Up Stripe Account'}
          </Button>
          <Divider />
          <FormControlLabel
            className={classes.vatCheckbox}
            control={(
              <Checkbox
                disabled={!isVatRegistrationOptionEnabled}
                checked={paymentFormValues.vatRegistered}
                onChange={onVatRegisteredChange}
              />
            )}
            label="VAT Registered"
          />
          <TextField
            className={classes.textInput}
            disabled={!isVatRegistrationNumberFieldEnabled}
            value={paymentFormValues.vatNumber}
            onChange={onVatNumberChange}
            variant="outlined"
            label="VAT Registration Number"
          />
          <TextField
            className={classes.textInput}
            disabled={!isCompanyNameFieldEnabled}
            value={paymentFormValues.companyName}
            onChange={onCompanyNameChange}
            variant="outlined"
            label="Company Name"
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={onPaymentOptionsSaveClick}
            color="primary"
            variant="contained"
            disabled={!isFormValid}
          >
            Save
          </Button>
          <Button onClick={onCancelClick} color="primary" variant="outlined">Cancel</Button>
        </DialogActions>
        {error && (
          <DialogContentText
            className={classes.errorText}
            color="error"
          >
            Something went wrong. Please try again.
          </DialogContentText>
        )}
      </Dialog>
    </>
  );
};

export default LabelPaymentInfoForm;
