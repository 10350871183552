import Papa from 'papaparse';

import downloadFile from '../../../helpers/downloadFile';

import TextMessageData from '../../../types/TextMessageData';
import csvFriendly from '../../../helpers/csv-friendly';

const downloadTextMessages = (textMessagesData: TextMessageData[], fileName: string) => {
  const checkedData = textMessagesData.map((d) => ({
    site: csvFriendly(d.site),
    billedTo: csvFriendly(d.billedTo),
    messagesSent: csvFriendly(d.messagesSent),
    parts: csvFriendly(d.parts),
    total: csvFriendly(d.price),
  }));
  const data = Papa.unparse(checkedData);

  return downloadFile({
    data,
    fileName,
    fileType: 'text/csv',
  });
};

export default downloadTextMessages;
