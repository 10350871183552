import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Grid, Typography } from '@material-ui/core';

import SubCategoryForm from '../../components/SubCategoryForm/SubCategoryForm';
import Spinner from '../../components/spinner/spinner';

import getSiteMap from '../../actions/get-site-map';
import getProducts from '../../actions/get-products';

import nameToUrl from '../../helpers/name-to-url';
import changeSiteMap from '../../helpers/change-site-map';
import uploadImage from '../../helpers/upload-image';
import getImageUrl from '../../helpers/get-image-url';
import filterCategory from '../category-page/helpers/filter-category';

import STORE_KEYS from '../../constants/store-keys';

const {
  AUTHENTICATION: { USER },
  SITE_MAP: { IS_SITE_MAP_LOADING, SITE_MAP_ID, SITE_MAP },
  PRODUCTS: { IS_PRODUCTS_LOADING, PRODS },
} = STORE_KEYS;

const AddSubCategoryPage = () => {
  const user = useSelector((state) => state.authentication.get(USER));
  const isSiteMapLoading = useSelector((state) => state.siteMap.get(IS_SITE_MAP_LOADING));
  const siteMapId = useSelector((state) => state.siteMap.get(SITE_MAP_ID));
  const siteMap = useSelector((state) => state.siteMap.get(SITE_MAP));
  const isProductsLoading = useSelector((state) => state.products.get(IS_PRODUCTS_LOADING));
  const products = useSelector((state) => state.products.get(PRODS));
  const [errorMessage, setErrorMessage] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const { category } = params;

  useEffect(() => {
    if (user.site) {
      dispatch(getSiteMap(user.site));
      dispatch(getProducts(user.site));
    }
  }, [user]);

  const handleSave = useCallback(async (newSubCat, subCatImage) => {
    let image = '';
    if (subCatImage) {
      image = await uploadImage(user.site, subCatImage)
        .then(({ ref }) => getImageUrl(ref.fullPath));
    }
    const newSiteMap = siteMap.map((map) => {
      if (nameToUrl(map.category) === nameToUrl(category)) {
        return {
          ...map,
          subCategories: map.subCategories.concat([{ ...newSubCat, image }]),
        };
      }
      return map;
    });
    changeSiteMap(siteMapId, { site: user.site, products: newSiteMap })
      .then(() => history.push(`/site-map/${category}`))
      .catch((error) => setErrorMessage(error.message || 'Something went wrong, please try again or contact support.'));
  }, [user, siteMap, siteMapId, category]);

  const handleCancel = useCallback(() => {
    history.push(`/site-map/${nameToUrl(category)}`);
  }, [category]);

  const filteredCategory = useMemo(() => filterCategory(siteMap, category), [siteMap, category]);

  if (isSiteMapLoading || isProductsLoading) return <Spinner />;

  return (
    <Grid>
      <SubCategoryForm
        user={user}
        subCategories={filteredCategory}
        productsToUse={products}
        onSave={handleSave}
        onCancel={handleCancel}
      />
      {errorMessage ? <Typography variant="body1" color="error">{errorMessage}</Typography> : null}
    </Grid>
  );
};

export default AddSubCategoryPage;
