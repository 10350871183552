import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';

interface Props {
  className?: string;
  value: string;
  onChange: (newValue: string) => Promise<void>;
  disabled?: boolean;
}

const DebouncedTextField = ({
  className,
  value: initalValue,
  onChange,
  disabled,
}: Props) => {
  const [value, setValue] = useState<string>(initalValue);

  useEffect(() => {
    const timeout = setTimeout(() => onChange(value), 1000);
    return () => clearTimeout(timeout);
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <TextField
      className={className}
      value={value}
      onChange={handleChange}
      disabled={disabled}
    />
  );
};

export default DebouncedTextField;
