import React from 'react';
import PropTypes from 'prop-types';

import Chip from '@material-ui/core/Chip';
import {
  Payment,
  Apple,
  AccountBalanceWallet,
} from '@material-ui/icons';

const PaymentMethod = ({ method }) => {
  switch (method) {
    case 'google_pay':
      return (
        <Chip
          icon={<Payment />}
          label="Google Pay"
        />
      );
    case 'apple_pay':
      return (
        <Chip
          icon={<Apple />}
          label="Apple Pay"
        />
      );
    case 'link':
      return (
        <Chip
          icon={<Payment />}
          label="Link"
        />
      );
    case 'card':
      return (
        <Chip
          icon={<Payment />}
          label="Card"
        />
      );
    default:
      return (
        <Chip
          icon={<AccountBalanceWallet />}
          label={method || 'Other'}
        />
      );
  }
};

PaymentMethod.propTypes = {
  method: PropTypes.string,
};

PaymentMethod.defaultProps = {
  method: null,
};

export default PaymentMethod;
