import React from 'react';

import Chip from '@material-ui/core/Chip';
import {
  Help,
  Cancel,
  CheckCircle,
  Loop,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';

import Status from '../../types/Status';

type Props = {
  status: Status
};

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(1, 1, 0, 0),
  },
}));

const OrderItemStatus = ({ status }: Props) => {
  const classes = useStyles();
  switch (status) {
    case Status.Outstanding:
      return (
        <Chip
          className={classes.chip}
          icon={<Cancel />}
          label="Outstanding"
          color="secondary"
          variant="outlined"
        />
      );
    case Status.Preparing:
      return (
        <Chip
          className={classes.chip}
          icon={<Loop />}
          label="Preparing"
          color="default"
          variant="outlined"
        />
      );
    case Status.Ready:
      return (
        <Chip
          className={classes.chip}
          icon={<CheckCircle style={{ color: 'green' }} />}
          label="Ready"
          variant="outlined"
        />
      );
    case Status.Complete:
      return (
        <Chip
          className={classes.chip}
          icon={<CheckCircle style={{ color: 'green' }} />}
          label="Processed"
          variant="outlined"
        />
      );
    default:
      return (
        <Chip
          className={classes.chip}
          icon={<Help />}
          label="Unknown"
          color="default"
          variant="outlined"
        />
      );
  }
};

export default OrderItemStatus;
