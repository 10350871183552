import GroupedOrderItems from '../../../types/GroupedOrderItems';
import OrderItem from '../../../types/OrderItem';
import generateItemKey from './generateItemKey';

export default (orders: OrderItem[]): GroupedOrderItems => {
  const quantities: { [key: string]: OrderItem[] } = {};
  orders.map(
    (item) => ({ ...item, key: generateItemKey(item) }),
  ).forEach((item) => {
    if (quantities[item.key] !== undefined) {
      quantities[item.key].push(item);
    } else {
      quantities[item.key] = [item];
    }
  });

  return Object.entries(quantities).map(([key, value]) => (
    {
      id: key,
      quantity: value.reduce((output, item) => output + item.quantity, 0),
      processed: value.every((item) => item.processed),
      name: value[0].name,
      queued: value[0].queued || false,
      addOns: value[0].addOns,
      itemNotes: value[0].itemNotes,
      items: value.map((item) => item.cartId),
      refunded: value.every((item) => item.refunded),
      reportingLabels: value[0].reportingLabels || [],
    }));
};
