const compact = <T>(values: Array<T | undefined | null>) => {
  const compactArray: T[] = [];
  if (values) {
    values.forEach((value) => {
      if (value != null) compactArray.push(value);
    });
  }
  return compactArray;
};

export default compact;
