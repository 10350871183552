import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DateTime } from 'luxon';

import {
  Grid, makeStyles, Paper, Typography,
} from '@material-ui/core';

import GiftCardHistoryTable from './components/GiftCardHistoryTable';
import InfoField from '../../components/info-field/info-field';
import Spinner from '../../components/spinner/spinner';
import ListField from '../../components/list-field/list-field';
import PaymentMethodChip from '../../components/PaymentMethodChip/PaymentMethodChip';

import formatMoney from '../../helpers/formatMoney';
import initialiseOrderDataListener from '../../helpers/initialiseOrderDataListener';
import initialiseGiftCardDataListener from '../../helpers/initialiseGiftCardDataListener';

import GiftCard from '../../types/GiftCard';
import Nullable from '../../types/Nullable';
import OrderData from '../../types/OrderData';
import GiftCardSource from '../../types/GiftCardSource';

const useStyles = makeStyles((theme) => ({
  giftCardInfo: { padding: theme.spacing(2) },
}));

const GiftCardPage = () => {
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [giftCard, setGiftCard] = useState<Nullable<GiftCard>>(null);
  const [orderData, setOrderData] = useState<Nullable<OrderData>>(null);
  const [error, setError] = useState<Nullable<Error>>(null);

  const classes = useStyles();
  const params = useParams<{ giftCardId: string }>();

  useEffect(() => {
    const unsubscribeFromGiftCard = initialiseGiftCardDataListener(
      params.giftCardId, setGiftCard, setError,
    );
    return () => unsubscribeFromGiftCard();
  }, [params]);

  useEffect(() => {
    if (giftCard && giftCard.orderNumber) {
      const unsubscribeFromOrderData = initialiseOrderDataListener(
        giftCard.orderNumber, setOrderData, setError,
      );
      return () => unsubscribeFromOrderData();
    }
    return () => {};
  }, [giftCard]);

  useEffect(() => {
    if (
      giftCard != null
      && (
        orderData != null
        || giftCard.paymentMethod === GiftCardSource.Import
      )
    ) {
      setIsDataLoading(false);
    } else {
      setIsDataLoading(true);
    }
  }, [giftCard, orderData]);

  if (isDataLoading) return <Spinner />;

  if (error) {
    return (
      <Grid>
        <Typography variant="body1" color="error" gutterBottom>
          Something went wrong, please try again or contact support.
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid>
      <Paper className={classes.giftCardInfo}>
        <Grid>
          <InfoField
            title="Gift Card Name"
            info={giftCard?.name || ''}
          />
          <InfoField
            title="Code"
            info={giftCard?.code || ''}
          />
          <InfoField
            title="Balance"
            info={formatMoney(giftCard?.balance || 0)}
          />
          <InfoField
            title="Order Number"
            info={giftCard?.orderNumber || ''}
          />
          <InfoField
            title="Purchase Date"
            info={DateTime
              .fromMillis(giftCard?.purchaseDate || 0)
              .toLocaleString(DateTime.DATETIME_MED)}
          />
          <InfoField
            title="Expiry Date"
            info={giftCard?.expiryDate != null
              ? DateTime
                .fromMillis(giftCard.expiryDate)
                .toLocaleString()
              : 'No Expiry'}
          />
          <Grid
            style={{ marginBottom: 5 }}
            container
            direction="row"
            alignItems="center"
          >
            <Typography style={{ marginRight: 5, fontWeight: 'bold' }}>Payment Method:</Typography>
            <PaymentMethodChip method={giftCard?.paymentMethod} size="small" />
          </Grid>
          <InfoField
            title="Recipient Name"
            info={giftCard?.recipientName || ''}
          />
          <InfoField
            title="Recipient Email"
            info={giftCard?.recipientEmail || ''}
          />
          <InfoField
            title="Recipient Message"
            info={giftCard?.recipientMessage || ''}
          />
          <ListField
            title="Sites"
            list={giftCard?.sites || []}
          />
          {giftCard?.cancelGiftCardReason != null && (
            <InfoField
              title="Cancellation Reason"
              info={giftCard.cancelGiftCardReason}
            />
          )}
        </Grid>
      </Paper>
      <GiftCardHistoryTable
        giftCard={giftCard}
        orderData={orderData}
      />
    </Grid>
  );
};

export default GiftCardPage;
