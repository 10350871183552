import Joi from 'joi';
import CharityDonation from '../../../types/CharityDonation';
import CharityDonationType from '../../../types/CharityDonationType';
import extractEnumValues from '../../../helpers/extractEnumValues';

export const charityDonationSchema = Joi.object({
  value: Joi.number().when('type', {
    is: Joi.string().valid(
      CharityDonationType.FixedValue,
      CharityDonationType.Percentage,
    ),
    then: Joi.number().positive(),
  }).required(),
  plu: Joi.number().positive().required(),
  displayName: Joi.string().required(),
  isCompulsory: Joi.boolean().required(),
  type: Joi.string().valid(...extractEnumValues(CharityDonationType)).required(),
  isActive: Joi.boolean().required(),
});

const isValidCharityDonation = (
  testValue: unknown,
): testValue is CharityDonation => charityDonationSchema.validate(testValue).error == null;

export default isValidCharityDonation;
