import React, { useMemo, useState } from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Divider,
  Grid,
  Typography,
  Box,
  makeStyles,
  Button,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';

import OutlinedTextField from '../outlined-text-field/outlined-text-field';
import MultipleSelect from './components/multiple-select';
import SimpleSelect from '../SimpleSelect/SimpleSelect';

import compact from '../../helpers/compact';

import ProductOption from '../../types/ProductOption';
import ProductOptionType from '../../types/ProductOptionType';
import Choice from '../../types/Choice';
import ProductData from '../../types/ProductData';

type Props = {
  index: number
  option: ProductOption
  products: ProductData[]
  onChange: (newOption: ProductOption) => void
  onRemove: (index: number) => void
};

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    marginBottom: 10,
  },
}));

const types = [
  { value: ProductOptionType.Variety, label: 'Variety' },
  { value: ProductOptionType.AddOn, label: 'Add On' },
];

const OptionsForm = ({
  index, option, products, onChange, onRemove,
}: Props) => {
  const classes = useStyles();
  const [productOption, setProductOption] = useState<ProductOption>(option);
  const [expanded, setExpanded] = useState(false);

  const handleChange = <T extends keyof ProductOption>(name: T, value: ProductOption[T]) => {
    const newOption = {
      ...productOption,
      [name]: value,
    };

    if (
      productOption.type === ProductOptionType.AddOn
      && newOption.type === ProductOptionType.Variety
    ) {
      newOption.minChoices = 1;
      newOption.maxChoices = 1;
    }

    onChange(newOption);
    setProductOption(newOption);
  };

  const handleAccordionChange = () => {
    setExpanded((value) => !value);
  };

  const defaultValues = useMemo(() => {
    const choices = productOption.choices.map((choice) => choice.plu) || [];
    if (choices.length === 0) return [];
    return choices.map((plu) => products.find((choice) => plu === choice.plu));
  }, [productOption]);

  return (
    <Grid className={classes.root} key={productOption.type}>
      <Accordion expanded={expanded} onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{`Option ${index + 1}`}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            direction="column"
          >
            <SimpleSelect<ProductOptionType>
              label="Type"
              items={types}
              value={productOption.type}
              onChange={(value) => handleChange('type', value)}
            />
            <OutlinedTextField
              id="outlined-min-choices"
              classOption="flex"
              label="Option Title"
              placeholder="E.g. Choose your size"
              value={productOption.title}
              onChange={(event) => handleChange('title', event.target.value)}
            />
            {productOption.type === ProductOptionType.AddOn
              && (
                <Box>
                  <OutlinedTextField
                    id="outlined-min-choices"
                    classOption="flex"
                    type="number"
                    label="Min Choices"
                    value={productOption.minChoices}
                    onChange={(event) => handleChange('minChoices', Number(event.target.value))}
                    required
                  />
                  <OutlinedTextField
                    id="outlined-max-choices"
                    classOption="flex"
                    type="number"
                    label="Max Choices"
                    value={productOption.maxChoices}
                    onChange={(event) => handleChange('maxChoices', Number(event.target.value))}
                    required
                  />
                </Box>
              )}
            <MultipleSelect
              defaultValues={compact(defaultValues)}
              options={products}
              changeOption={(choices: Choice[]) => handleChange('choices', choices)}
              helperText="Only existing products can be added as choices"
            />
          </Grid>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => onRemove(index)}
            startIcon={<DeleteIcon />}
          >
            Remove Option
          </Button>
        </AccordionActions>
      </Accordion>
    </Grid>
  );
};

export default OptionsForm;
