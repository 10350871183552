import React,
{
  ChangeEvent,
  useCallback,
  useState,
} from 'react';
import firebase from 'firebase/app';
import {
  Grid,
  InputLabel,
  Select,
  TextField,
  FormControl,
  MenuItem,
  Button,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import withStyles, { ClassNameMap } from '@material-ui/core/styles/withStyles';

import apiRequest from '../../helpers/api-request';
import isUserRole from '../../containers/edit-user-page/helpers/isUserRole';
import isReportingLabelIdArray from '../../containers/edit-user-page/helpers/isReportingLabelArray';

import SiteOptionsInput from './components/site-options-input';

import Label from '../../types/Label';
import SiteData from '../../types/SiteData';
import UserRole from '../../types/UserRole';
import UserSiteConfig from '../../types/UserSiteConfig';
import Nullable from '../../types/Nullable';
import ResetPasswordDialog from '../ResetPasswordDialog/ResetPasswordDialog';

const styles = () => ({
  textInput: {
    marginBottom: 20,
    width: 350,
  },
  saveButton: {
    marginTop: 10,
    marginBottom: 10,
    width: 350,
  },
  passwordButton: {
    marginBottom: 20,
    width: 350,
  },
  errorText: {
    marginTop: 20,
  },
});

interface Props {
  classes: ClassNameMap<'textInput' | 'saveButton' | 'passwordButton' | 'errorText'>,
  userId: Nullable<string>,
  name: string,
  email: string,
  admin: boolean,
  sites: Array<UserSiteConfig>,
  allSites: Array<SiteData>,
  allLabels: { [site: string]: Label[] },
  onSave: () => Promise<void>,
  onNameChange: (event: ChangeEvent<HTMLInputElement>) => void,
  onEmailChange: (event: ChangeEvent<HTMLInputElement>) => void,
  onAdminChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void,
  onSitesChange: (updatedSites: Array<UserSiteConfig>) => void,
  isValidUser: boolean,
}

const UserForm = ({
  classes,
  userId,
  name,
  email,
  admin,
  sites,
  allSites,
  allLabels,
  onSave,
  onNameChange,
  onEmailChange,
  onAdminChange,
  onSitesChange,
  isValidUser,
}: Props) => {
  const [passwordDialogOpen, setPasswordDialogOpen] = useState<boolean>(false);

  const handleSitesChange = useCallback((event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    if (Array.isArray(value) && value.every((element: unknown) => typeof element === 'string')) {
      const currentSiteNames = sites.map((site) => site.site);

      if (value.length === sites.length - 1) {
        const removedSiteIndex = currentSiteNames
          .findIndex((siteName) => !value.includes(siteName));
        const newSites = [...sites];
        newSites.splice(removedSiteIndex, 1);
        onSitesChange(newSites);
      }

      value.forEach((siteName) => {
        if (!currentSiteNames.includes(siteName)) {
          const newSites = [...sites];
          newSites.push({ site: siteName, reportingLabels: [], role: UserRole.User });
          onSitesChange(newSites);
        }
      });
    }
  }, [onSitesChange, sites]);

  const roleChangeHandlerFactory = useCallback((site: SiteData['site']) => (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    const updatedSiteIndex = sites.findIndex((siteConfig) => siteConfig.site === site);
    if (isUserRole(value) && updatedSiteIndex !== -1) {
      const newSites = [...sites];
      newSites.splice(updatedSiteIndex, 1, { ...sites[updatedSiteIndex], role: value });
      onSitesChange(newSites);
    }
  }, [sites, onSitesChange]);

  const labelsChangeHandlerFactory = useCallback((site: SiteData['name']) => (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    const updatedSiteIndex = sites.findIndex((siteConfig) => siteConfig.site === site);
    if (value === 'clear-select') {
      const newSites = [...sites];
      newSites[updatedSiteIndex].reportingLabels = [];
      onSitesChange(newSites);
    } else {
      const newLabelIds = [allLabels[site].find((label) => label.text === value)?.id];
      if (isReportingLabelIdArray(newLabelIds, allLabels[site]) && newLabelIds.length < 2) {
        const newSites = [...sites];
        newSites[updatedSiteIndex].reportingLabels = newLabelIds;
        onSitesChange(newSites);
      }
    }
  }, [sites, onSitesChange, allLabels]);

  const handlePasswordDialogOpen = useCallback(() => {
    setPasswordDialogOpen(true);
  }, []);

  const handlePasswordDialogCancel = useCallback(() => {
    setPasswordDialogOpen(false);
  }, []);

  const handlePasswordDialogSave = useCallback(
    async (newPassword: string) => {
      if (userId == null) throw new Error('Missing User Id');
      const idToken = await firebase.auth().currentUser?.getIdToken();
      await apiRequest(`user/${userId}/password`, 'PUT', { password: newPassword }, { Authorization: `Bearer ${idToken}` });
      setPasswordDialogOpen(false);
    },
    [userId],
  );

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      <TextField
        label="Name"
        className={classes.textInput}
        variant="outlined"
        value={name}
        onChange={onNameChange}
      />
      <TextField
        label="Email"
        className={classes.textInput}
        variant="outlined"
        value={email}
        onChange={onEmailChange}
      />
      {userId != null && (
        <Button
          className={classes.passwordButton}
          variant="outlined"
          color="primary"
          onClick={handlePasswordDialogOpen}
        >
          Set New Password
        </Button>
      )}
      <FormControl style={{ width: 350, marginBottom: 20 }} variant="outlined">
        <InputLabel id="sites-select-label">Sites</InputLabel>
        <Select
          multiple
          labelId="sites-select-label"
          labelWidth={40}
          value={sites.map((config) => config.site)}
          onChange={handleSitesChange}
          MenuProps={{ variant: 'menu' }}
        >
          {allSites.map(({ id, site, name: siteName }) => (
            <MenuItem key={id} value={site}>{siteName}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {sites.map((siteConfig) => (
        <SiteOptionsInput
          key={siteConfig.site}
          siteConfig={siteConfig}
          labelSelectOptions={allLabels[siteConfig.site]}
          roleChangeHandlerFactory={roleChangeHandlerFactory}
          labelsChangeHandlerFactory={labelsChangeHandlerFactory}
        />
      ))}
      <FormControlLabel control={<Checkbox checked={admin} onChange={onAdminChange} />} label="Admin" />
      <Grid item>
        <Button
          className={classes.saveButton}
          variant="contained"
          color="primary"
          onClick={onSave}
          disabled={!isValidUser}
        >
          Save
        </Button>
      </Grid>
      <ResetPasswordDialog
        open={passwordDialogOpen}
        onCancel={handlePasswordDialogCancel}
        onSave={handlePasswordDialogSave}
        requireCurrentPassword={false}
      />
    </Grid>
  );
};

export default withStyles(styles)(UserForm);
