import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  ChangeEvent,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  Typography,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import Spinner from '../../components/spinner/spinner';

import UserSiteConfig from '../../types/UserSiteConfig';
import Nullable from '../../types/Nullable';
import User from '../../types/User';
import ReduxState from '../../types/ReduxState';
import AppUrl from '../../types/AppUrl';
import SiteData from '../../types/SiteData';
import Label from '../../types/Label';

import getUsers from '../../actions/get-users';
import getSettings from '../../actions/get-settings';

import request from '../../helpers/request';
import labelsFromIds from '../../components/label-input/helpers/labels-from-ids';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: theme.spacing(52),
  },
  title: {
    margin: theme.spacing(2, 0, 1),
  },
  inputLabel: {
    marginLeft: theme.spacing(1),
  },
  inputs: {
    margin: theme.spacing(2, 0, 4),
    minWidth: theme.spacing(52),
  },
  divider: {
    marginTop: theme.spacing(4),
    minWidth: theme.spacing(52),
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    margin: theme.spacing(4, 0),
  },
}));

const EditPosUser: React.FC = () => {
  const [POSPin, setPOSPin] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Nullable<string>>(null);
  const classes = useStyles();
  const params = useParams<{ userId: string }>();
  const { userId } = params;
  const dispatch = useDispatch();
  const history = useHistory();
  const allUsers = useSelector<ReduxState, User[]>((state) => state.users.get('USERS'));
  const authenticatedUser = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));
  const settings = useSelector<ReduxState, Nullable<SiteData>>((state) => state.settings.get('SETTINGS'));
  const user: Nullable<User> = allUsers.find(({ id }) => userId === id) || null;
  const userSiteConfig: UserSiteConfig | undefined = useMemo(
    () => authenticatedUser?.sites.find((siteConfig) => siteConfig.site === authenticatedUser.site),
    [authenticatedUser],
  );
  const [reportingLabel, setReportingLabel] = useState<Nullable<Label>>(() => {
    if (
      user == null
      || userSiteConfig == null
      || settings == null
      || settings.reportingLabels == null
    ) return null;
    const { sites } = user;
    const config = sites.find(({ site }) => site === userSiteConfig.site);
    if (config == null) return null;
    const { reportingLabels } = config;
    return labelsFromIds(reportingLabels, settings.reportingLabels)[0];
  });

  useEffect(() => {
    if (authenticatedUser != null) {
      dispatch(getUsers());
      dispatch(getSettings(authenticatedUser.site));
      setLoading(false);
    }
  }, [authenticatedUser]);

  useEffect(() => {
    if (userSiteConfig) {
      const currentUsersSite = user?.sites.filter((siteConfig: UserSiteConfig) => (
        userSiteConfig.site === siteConfig.site
      ))[0];
      if (currentUsersSite && currentUsersSite.POSPin) {
        setPOSPin(currentUsersSite.POSPin);
      }
    }
  }, [user]);

  const handleChangePin = (event: React.ChangeEvent<{ value: string }>) => {
    const { target: { value } } = event;
    if (/^.{0,4}$/.test(value)) setPOSPin(value);
  };

  const userToUpdate = useMemo(() => {
    if (user) {
      const updateSitesArrayOnUser = user.sites.map((siteConfig: UserSiteConfig) => {
        if (userSiteConfig && siteConfig.site !== userSiteConfig.site) return siteConfig;

        return {
          ...siteConfig,
          POSPin,
          reportingLabels: reportingLabel != null ? [reportingLabel.id] : [],
        };
      });
      user.sites = updateSitesArrayOnUser;
      return user;
    }
    return null;
  }, [POSPin, reportingLabel]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (!userToUpdate) throw new Error('Could not load user');
      const {
        name,
        email,
        admin,
        sites,
      } = userToUpdate;
      await request(
        'updateUser',
        'POST',
        {
          uid: userId,
          name,
          email,
          admin,
          sites,
        },
      );
      history.push(`${AppUrl.POSHome}?tab=3`);
    } catch (e) {
      setError('Something went wrong, please try again');
      setLoading(false);
    }
  };

  const labelSelectOptions = useMemo(() => {
    if (settings != null && settings.reportingLabels) {
      return settings.reportingLabels
        .filter((label) => (label.active != null ? label.active : true));
    }
    return [];
  }, [settings]);

  const reportingLabelChangeHandler = useCallback((event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    if (value === 'clear-select') {
      setReportingLabel(null);
    } else if (settings != null && settings.reportingLabels) {
      const newLabel = settings.reportingLabels
        .filter((label) => (label.active != null ? label.active : true))
        .find(({ text }) => text === value);
      if (newLabel) setReportingLabel(newLabel);
    }
  }, [settings]);

  if (loading || !settings) return <Spinner />;

  return (
    <Box className={classes.container}>
      <Typography variant="h6" className={classes.title}>Edit POS user</Typography>
      <Typography variant="subtitle1" className={classes.subtitle}>
        {`Change the settings for POS user ${user?.name} (${user?.email})`}
      </Typography>
      <Typography color="textSecondary">
        Users are restricted to selling items with a matching Reporting Label if selected
      </Typography>
      <FormControl className={classes.inputs} variant="outlined">
        <InputLabel id="label-select-label">Reporting Label</InputLabel>
        <Select
          labelId="label-select-label"
          labelWidth={settings.site.length + 130}
          value={reportingLabel ? reportingLabel.text : ''}
          onChange={reportingLabelChangeHandler}
          MenuProps={{ variant: 'menu' }}
        >
          {labelSelectOptions.map(({ id, text }) => (
            <MenuItem key={id} value={text}>{text}</MenuItem>
          ))}
          <MenuItem key="clear-select" value="clear-select">
            <Typography variant="button" color="primary">No Label</Typography>
          </MenuItem>
        </Select>
      </FormControl>
      <Typography color="textSecondary">Change 4-number PIN for Peazi POS login</Typography>
      <TextField
        variant="outlined"
        type="number"
        value={POSPin}
        onChange={handleChangePin}
        className={classes.inputs}
      />
      <Button
        variant="contained"
        disabled={POSPin.length < 4}
        onClick={handleSubmit}
        color="primary"
      >
        Save Changes
      </Button>
      {error && <Typography variant="body1" color="error">{error}</Typography>}
    </Box>
  );
};

export default EditPosUser;
