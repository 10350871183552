import React,
{
  useCallback,
  useState,
  useEffect,
  ChangeEvent,
} from 'react';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import MaterialTable, { MTableToolbar } from 'material-table';
import {
  Grid,
  Typography,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Box,
  Button,
  makeStyles,
  DialogContentText,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import OrderStatus from '../OrderStatus/OrderStatus';
import FailedStatus from './components/FailedStatus';

import hasPermission from '../../helpers/has-permission';
import formatMoney from '../../helpers/formatMoney';
import getUnpaidOrders from './helpers/getUnpaidOrders';

import Nullable from '../../types/Nullable';
import RoleRestrictedAction from '../../types/RoleRestrictedAction';
import User from '../../types/User';
import Query from '../../types/Query';
import TableElement from './types/TableElement';
import SiteData from '../../types/SiteData';

type Props = {
  user: Nullable<User>
  site?: string
  siteData: Nullable<SiteData>
};

const useStyles = makeStyles({
  clearButton: {
    marginLeft: '1em',
  },
  advancedOptionsDialog: {
    overflowY: 'hidden',
  },
  advancedOptionsSubmitContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  advancedOptionsButton: {
    margin: '5% 7.5% 10% 10%',
  },
  advancedOptionsErrorText: {
    margin: '0 0 5% 5%',
  },
});

const UnpaidOrdersTable = ({ user, site, siteData }: Props) => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const [advancedOptionsError, setAdvancedOptionsError] = useState<Nullable<string>>(null);
  const history = useHistory();
  const tableRef = React.createRef<TableElement>();

  const [advancedSearchDialogOpen, setAdvancedSearchDialogOpen] = useState<boolean>(false);
  const [last4, setLast4] = useState<string>('');

  useEffect(() => {
    if (tableRef.current != null) {
      tableRef.current.onQueryChange();
    }
  }, [tableRef]);

  const getData = useCallback((query: Query) => {
    setErrorMessage(null);
    return getUnpaidOrders(last4, site, query)
      .catch((error) => {
        setErrorMessage(error.message || 'Something went wrong getting Sales Reports');
        return Promise.resolve({
          data: [],
          page: 0,
          totalCount: 0,
        });
      });
  }, [site, user, last4]);

  const handleAdvancedSearchClear = useCallback(
    () => setLast4(''),
    [setLast4],
  );

  const handleAdvancedSearchOpen = useCallback(
    () => setAdvancedSearchDialogOpen(true),
    [setAdvancedSearchDialogOpen],
  );

  const handleLast4Change = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setLast4((currentValue) => {
        const newValue = event.target.value;
        if (/^[0-9]{0,4}$/.test(newValue)) return newValue;
        return currentValue;
      });
      setAdvancedOptionsError(null);
    },
    [setLast4, setAdvancedOptionsError],
  );

  const handleAdvancedSearchClose = useCallback(
    () => {
      if (last4.length === 0 || last4.length === 4) {
        setAdvancedSearchDialogOpen(false);
        setAdvancedOptionsError(null);
      } else {
        setAdvancedOptionsError(
          'Please enter a 4 digit number for \'Last Four Digits of Card Number\', or clear that field.',
        );
      }
    },
    [setAdvancedSearchDialogOpen, setAdvancedOptionsError, last4],
  );

  return (
    <Grid>
      {errorMessage && (
        <Typography align="center" color="error">{errorMessage}</Typography>
      )}
      <MaterialTable
        title={`${site || ''} Unpaid Orders`}
        tableRef={tableRef}
        columns={[
          { title: 'Order Number', field: 'order_number' },
          { title: 'Site', field: 'site' },
          { title: 'Date', field: 'created', render: (rowData) => moment(rowData.created).format('Do MMMM YYYY, HH:mm:ss') },
          { title: 'Reason', field: 'failure_code', render: (rowData) => <FailedStatus reason={rowData.failure_code} /> },
          { title: 'Last 4', field: 'last4' },
          { title: 'Status', field: 'status', render: (rowData) => <OrderStatus status={rowData.status} /> },
          { title: 'Total', field: 'amount', render: (rowData) => formatMoney(rowData.amount, siteData?.currency) },
        ]}
        data={getData}
        actions={[
          (rowData) => ({
            icon: 'visibility',
            tooltip: 'View Order',
            disabled: !hasPermission(RoleRestrictedAction.ViewOrder, user),
            onClick: () => history.push(`/orders/${rowData.order_number}`),
          }),
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: () => tableRef.current && tableRef.current.onQueryChange(),
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
        }}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar
                {...props /* eslint-disable-line react/jsx-props-no-spreading */}
              />
              <div style={{ padding: 10 }}>
                <Chip
                  icon={<AddIcon />}
                  label="Advanced Search"
                  onClick={handleAdvancedSearchOpen}
                  variant="outlined"
                />
                {last4 !== ''
                  ? (
                    <Chip
                      className={classes.clearButton}
                      icon={<CloseIcon />}
                      label="Clear"
                      onClick={handleAdvancedSearchClear}
                      color="secondary"
                      variant="outlined"
                    />
                  )
                  : null}
              </div>
            </div>
          ),
        }}
      />
      <Dialog
        open={advancedSearchDialogOpen}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Advanced Search Options</DialogTitle>
        <DialogContent className={classes.advancedOptionsDialog}>
          <DialogContentText>
            <TextField
              label="Last Four Digits of Card Number"
              variant="outlined"
              value={last4}
              onChange={handleLast4Change}
              fullWidth
            />
          </DialogContentText>
        </DialogContent>
        <Box className={classes.advancedOptionsSubmitContainer}>
          {advancedOptionsError && (
            <Typography color="secondary" className={classes.advancedOptionsErrorText}>
              {advancedOptionsError}
            </Typography>
          )}
          <Button
            variant="outlined"
            color="primary"
            onClick={handleAdvancedSearchClose}
            disabled={advancedOptionsError != null}
            className={classes.advancedOptionsButton}
          >
            OK
          </Button>
        </Box>
      </Dialog>
    </Grid>
  );
};

export default UnpaidOrdersTable;
