import React, { useMemo, useState } from 'react';

import {
  DataGrid, GridColumns, GridCellParams, GridSortItem,
} from '@material-ui/data-grid';
import {
  Paper,
  Typography,
  makeStyles,
  Box,
} from '@material-ui/core';

import ProgressBar from '../../../components/ProgressBar/ProgressBar';

import formatMoney from '../../../helpers/formatMoney';

import SiteData from '../../../types/SiteData';
import Nullable from '../../../types/Nullable';

type Props = {
  data: { id: string, value: number }[]
  siteData: Nullable<SiteData>
  dateRangeText: string
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  title: {
    padding: theme.spacing(3),
  },
  titleBox: {
    display: 'inline',
  },
  tableContainer: {
    height: 400,
    width: '100%',
  },
  progressBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
}));

const LabelBreakdownSection = ({ data, siteData, dateRangeText }: Props) => {
  const [sortModel, setSortModel] = useState<GridSortItem[]>([
    {
      field: 'value',
      sort: 'desc',
    },
  ]);
  const classes = useStyles();

  const columns: GridColumns = useMemo(() => [
    { field: 'id', headerName: 'Vendor', width: 150 },
    {
      field: 'value',
      headerName: 'Value',
      width: 150,
      renderCell: (params: GridCellParams) => formatMoney(params.row.value, siteData?.currency),
    },
    {
      field: 'percentage',
      headerName: 'Percentage',
      width: 150,
      renderCell: (params: GridCellParams) => (
        <div className={classes.progressBar}>
          <ProgressBar value={params.row.percentage} />
          <Typography variant="subtitle2">
            {Math.round(params.row.percentage)}
            %
          </Typography>
        </div>
      ),
    },
  ], [data]);

  return (
    <Paper className={classes.container}>
      <Box className={classes.tableContainer}>
        <DataGrid
          components={{
            Toolbar: () => (
              <Typography className={classes.title}>
                <Box className={classes.titleBox} fontWeight="fontWeightBold">Vendors</Box>
                &nbsp;–&nbsp;
                {dateRangeText}
              </Typography>
            ),
          }}
          rows={data}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
        />
      </Box>
    </Paper>
  );
};

export default LabelBreakdownSection;
