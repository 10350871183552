import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Grid } from '@material-ui/core';

import Spinner from '../../components/spinner/spinner';
import SimpleCard from '../../components/simple-card/simple-card';

import formatMoney from '../../helpers/formatMoney';

import getSites from '../../actions/get-sites';
import getUsers from '../../actions/get-users';
import getOrderStats from '../../actions/get-order-stats';
import getUnprocessedOrders from '../../actions/get-unprocessed-orders';

import AppUrl from '../../types/AppUrl';

const AdminDashbaordPage = () => {
  const isSitesLoading = useSelector((state) => state.sites.get('IS_SITES_LOADING'));
  const numberOfSites = useSelector((state) => state.sites.get('NUMBER_OF_SITES'));
  const settings = useSelector((state) => state.settings.get('SETTINGS'));
  const isUsersLoading = useSelector((state) => state.users.get('IS_USERS_LOADING'));
  const numberOfUsers = useSelector((state) => state.users.get('NUMBER_OF_USERS'));
  const isOrderStatsLoading = useSelector((state) => state
    .siteOrderStats.get('IS_ORDER_STATS_LOADING'));
  const orderStats = useSelector((state) => state.siteOrderStats.get('ORDER_STATS'));
  const isUnprocessedOrdersLoading = useSelector((state) => state
    .unprocessedOrders.get('IS_UNPROCESSED_ORDERS_LOADING'));
  const numberOfUnprocessedOrders = useSelector((state) => state
    .unprocessedOrders.get('NUMBER_OF_UNPROCESSED_ORDERS'));
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getSites());
    dispatch(getUsers());
    dispatch(getOrderStats());
    dispatch(getUnprocessedOrders());
  }, []);

  if (isSitesLoading
    || isUsersLoading
    || isOrderStatsLoading
    || isUnprocessedOrdersLoading) return <Spinner />;

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid
        container
        direction="row"
      >
        <SimpleCard
          title="Sites"
          mainText={numberOfSites}
          color="green"
          onClick={() => history.push(AppUrl.Sites)}
        />
        <SimpleCard
          title="Users"
          mainText={numberOfUsers}
          color="green"
          onClick={() => history.push(AppUrl.Users)}
        />
      </Grid>
      {orderStats != null ? (
        <>
          <Grid
            container
            direction="row"
          >
            <SimpleCard
              title="Total Orders"
              mainText={orderStats.numberOfOrders}
              color="green"
              onClick={() => history.push(AppUrl.Orders)}
            />
            <SimpleCard
              title="Total Sales"
              mainText={formatMoney(orderStats.amountOfSales || 0, settings?.currency)}
              color="green"
              onClick={() => history.push(AppUrl.Orders)}
            />
          </Grid>
          <Grid
            container
            direction="row"
          >
            <SimpleCard
              title="Todays Orders"
              mainText={orderStats.numberOfOrdersToday}
              color="green"
              onClick={() => history.push(AppUrl.Orders)}
            />
            <SimpleCard
              title="Todays Sales"
              mainText={formatMoney(orderStats.amountOfSalesToday || 0, settings?.currency)}
              color="green"
              onClick={() => history.push(AppUrl.Orders)}
            />
          </Grid>
        </>
      ) : null}
      <Grid
        container
        direction="row"
      >
        <SimpleCard
          title="Unprocessed Orders"
          mainText={numberOfUnprocessedOrders || 0}
          color={numberOfUnprocessedOrders > 0 ? 'red' : 'green'}
          onClick={() => history.push(AppUrl.UnprocessedOrders)}
        />
      </Grid>
    </Grid>
  );
};

export default AdminDashbaordPage;
