import React, { ChangeEvent, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Grid,
  FormControl,
  Select,
  MenuItem,
  makeStyles,
  Button,
  IconButton,
  InputLabel,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PublishIcon from '@material-ui/icons/Publish';
import DownloadIcon from '@material-ui/icons/GetApp';
import LoopIcon from '@material-ui/icons/Loop';
import FilterListIcon from '@material-ui/icons/FilterList';
import MenuIcon from '@material-ui/icons/Menu';

import SearchToolbar from './SearchToolbar';

import extractProducts from '../helpers/extract-products';
import hasPermission from '../../../helpers/has-permission';
import downloadProducts from '../helpers/downloadProducts';

import AppUrl from '../../../types/AppUrl';
import RoleRestrictedAction from '../../../types/RoleRestrictedAction';
import User from '../../../types/User';
import ProductData from '../../../types/ProductData';
import SiteData from '../../../types/SiteData';
import ProductFilter from '../../../types/ProductFilter';
import Nullable from '../../../types/Nullable';
import Label from '../../../types/Label';

type Props = {
  searchText: string
  onChange: (value: string) => void
  clearSearch: () => void
  user: Nullable<User>
  onRestock: () => void
  products: ProductData[]
  siteData: SiteData
  filter: string
  onFilterChange: (productFilter: string) => void
  reportingLabelOptions: Label[]
  selectedReportingLabels: Label['id'][]
  onReportingLabelSelectChange: (event: ChangeEvent<{ value: unknown }>) => void
  isReportingLabelSelectDisabled: boolean
};

const useStyles = makeStyles(() => ({
  toolbar: {
    margin: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  filtersBox: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  reportingLabelSelect: {
    width: '50%',
  },
}));

const Toolbar = ({
  searchText,
  onChange,
  clearSearch,
  user,
  onRestock,
  products,
  siteData,
  filter,
  onFilterChange,
  reportingLabelOptions,
  selectedReportingLabels,
  onReportingLabelSelectChange,
  isReportingLabelSelectDisabled,
}: Props) => {
  const [headerContentSet, setHeaderContentSet] = useState<'default' | 'filters'>('default');
  const classes = useStyles();
  const history = useHistory();

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  }, [onChange]);

  const handleFilterChange = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    const { target } = event;
    const newFilter = target.value as string;
    onFilterChange(newFilter);
  }, [onFilterChange]);

  const handleFilterClick = () => {
    setHeaderContentSet((current) => (current === 'default' ? 'filters' : 'default'));
  };

  return (
    <Grid className={classes.toolbar}>
      {headerContentSet === 'default' && (
        <Grid
          container
          direction="row"
          justify="space-between"
        >
          <Grid>
            <FormControl variant="outlined" size="small">
              <Select
                value={filter}
                onChange={handleFilterChange}
                displayEmpty
              >
                <MenuItem value={ProductFilter.All}>All Products</MenuItem>
                <MenuItem value={ProductFilter.SoldOut}>Sold Out Products</MenuItem>
                <MenuItem value={ProductFilter.Hidden}>Hidden Products</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid>
            <SearchToolbar
              value={searchText}
              onChange={handleChange}
              clearSearch={clearSearch}
            />
            <Button
              style={{ margin: 5 }}
              startIcon={<LoopIcon />}
              onClick={onRestock}
              variant="outlined"
              color="primary"
            >
              Restock
            </Button>
            {products != null && products.length !== 0 && (
              <Button
                style={{ margin: 5 }}
                startIcon={<DownloadIcon />}
                onClick={() => downloadProducts(extractProducts(products, siteData), `${siteData.site}_products.csv`)}
                variant="outlined"
                color="primary"
              >
                Download Products
              </Button>
            )}
            <Button
              style={{ margin: 5 }}
              startIcon={<PublishIcon />}
              onClick={() => history.push(AppUrl.UploadProducts)}
              disabled={!hasPermission(RoleRestrictedAction.UploadProductCSV, user)}
              variant="outlined"
              color="primary"
            >
              Upload Products
            </Button>
            <Button
              style={{ margin: 5 }}
              startIcon={<AddIcon />}
              onClick={() => history.push(AppUrl.AddProduct)}
              disabled={!hasPermission(RoleRestrictedAction.AddProduct, user)}
              variant="contained"
              color="primary"
            >
              Add Product
            </Button>
          </Grid>
        </Grid>
      )}
      {headerContentSet === 'filters' && (
        <Box className={classes.filtersBox}>
          <FormControl className={classes.reportingLabelSelect} variant="outlined" size="small">
            <InputLabel id="reporting-label-select-label">Reporting Labels</InputLabel>
            <Select
              labelId="reporting-label-select-label"
              labelWidth={125}
              multiple
              value={selectedReportingLabels}
              onChange={onReportingLabelSelectChange}
              displayEmpty
              MenuProps={{ variant: 'menu' }}
              disabled={isReportingLabelSelectDisabled}
            >
              {reportingLabelOptions.map((label) => (
                <MenuItem key={label.id} value={label.id}>
                  {label.text}
                </MenuItem>
              ))}
              <MenuItem value="no-label">
                No Label
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}
      {!isReportingLabelSelectDisabled && (
        <IconButton
          onClick={handleFilterClick}
        >
          {headerContentSet === 'default' ? <FilterListIcon /> : <MenuIcon />}
        </IconButton>
      )}
    </Grid>
  );
};

export default Toolbar;
