import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import getKioskLink from '../helpers/getKioskLink';

import Kiosk from '../../../types/Kiosk';
import Nullable from '../../../types/Nullable';
import SiteData from '../../../types/SiteData';

import SnackBar from '../../../components/snack-bar/snack-bar';
import InfoField from '../../../components/info-field/info-field';

interface Props {
  selectedKiosk: Nullable<Kiosk>
  setSelectedKiosk: Dispatch<SetStateAction<Nullable<Kiosk>>>
  siteUrlEndpoint: SiteData['urlEndpoint']
}

const KioskUrlDetailsDialog = ({
  selectedKiosk,
  setSelectedKiosk,
  siteUrlEndpoint,
}: Props) => {
  const [openLinkToClipboardSnackbar, setOpenLinkToClipboardSnackbar] = useState<boolean>(false);

  const handleCopyLinkToClipboard = () => {
    navigator.clipboard.writeText(getKioskLink(siteUrlEndpoint, selectedKiosk));
    setOpenLinkToClipboardSnackbar(true);
  };

  return (
    <Dialog
      open={selectedKiosk != null}
    >
      <DialogTitle>
        {`Kiosk URL for ${selectedKiosk?.name}`}
      </DialogTitle>
      <DialogContent>
        <InfoField
          title="Url link"
          info={getKioskLink(siteUrlEndpoint, selectedKiosk)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleCopyLinkToClipboard}
        >
          Copy link to clipboard
        </Button>
        <Button
          variant="outlined"
          onClick={() => setSelectedKiosk(null)}
        >
          Close
        </Button>
      </DialogActions>
      <SnackBar
        open={openLinkToClipboardSnackbar}
        message="Kiosk Url saved to clipboard"
        onClose={() => setOpenLinkToClipboardSnackbar(false)}
      />
    </Dialog>
  );
};

export default KioskUrlDetailsDialog;
