import Joi from 'joi';

import GiftCard from '../types/GiftCard';

const isGiftCard = (value: unknown): value is GiftCard => {
  const schema = Joi.object({
    id: Joi.string().required(),
    name: Joi.string().required(),
    code: Joi.string().required(),
    balance: Joi.number().required(),
    sites: Joi.array().items(Joi.string()),
    recipientEmail: Joi.string().required(),
    recipientName: Joi.string().required(),
    recipientMessage: Joi.string(),
    paymentMethod: Joi.string(),
    purchaseDate: Joi.number().required(),
    posSellerId: Joi.string(),
    customerId: Joi.string(),
    orderNumber: Joi.string(),
    expiryDate: Joi.number(),
    validFrom: Joi.number(),
    cartId: Joi.string(),
    cancelGiftCardReason: Joi.string(),
  });

  return schema.validate(value, { allowUnknown: true }).error == null;
};

export default isGiftCard;
