import React, { MouseEvent, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';

import AppUrl from '../../../types/AppUrl';
import Nullable from '../../../types/Nullable';
import User from '../../../types/User';

import logout from '../../../actions/logout';

type Props = {
  user: User,
};

const UserMenu = ({ user }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<Nullable<EventTarget & Element>>(null);

  const handleOpen = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpen = useMemo(() => anchorEl != null, [anchorEl]);
  return (
    <>
      <IconButton
        aria-label="alert-menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpen}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isOpen}
        onClose={handleClose}
      >
        <MenuItem component={Link} to={AppUrl.Home}>Home</MenuItem>
        <MenuItem component={Link} to={AppUrl.Profile}>Profile</MenuItem>
        {user && user.admin && <MenuItem component={Link} to={AppUrl.Admin}>Admin</MenuItem>}
        <MenuItem onClick={() => dispatch(logout(history))}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
