import Nullable from '../types/Nullable';
import SiteData from '../types/SiteData';
import EditableSiteData from '../types/EditableSiteData';

const getSiteReaderSplashImage = (siteData: Nullable<SiteData | EditableSiteData>) => {
  if (siteData == null) return '';

  const testModeReaderSplashImage = siteData.testModeReaderSplashImage || '';
  const prodReaderSplashImage = siteData.readerSplashImage || '';

  return siteData.testMode ? testModeReaderSplashImage : prodReaderSplashImage;
};

export default getSiteReaderSplashImage;
