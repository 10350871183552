import React,
{
  ChangeEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import findProductOnSiteMap from '../helpers/findProductOnSiteMap';
import removeProductFromSiteMap from '../helpers/removeProductFromSiteMap';
import Category from '../../../types/Category';
import addProductToSiteMap from '../helpers/addProductToSiteMap';
import ErrorDialog from '../../ErrorDialog/ErrorDialog';

interface Props {
  plu: number,
  siteMap: Category[]
  setSiteMap: React.Dispatch<Category[]>
}

const AddToMenuForm = ({ plu, siteMap, setSiteMap }: Props) => {
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
  const [category, setCategory] = useState<string>('');
  const [subCategory, setSubCategory] = useState<string>('');

  const locations = useMemo(
    () => findProductOnSiteMap(plu, siteMap),
    [plu, siteMap],
  );

  const handleDeleteClick = useCallback((location: { category: string, subcategory: string }) => {
    try {
      const newSiteMap = removeProductFromSiteMap(plu, location, siteMap);
      setSiteMap(newSiteMap);
    } catch (error) {
      setErrorDialogOpen(true);
    }
  }, [plu, siteMap]);

  const handleAddSubcategoryClick = useCallback(() => {
    setAddDialogOpen(true);
  }, []);

  const handleAddSubcategoryCancel = useCallback(() => {
    setAddDialogOpen(false);
  }, []);

  const handleAddSubcategorySave = useCallback(() => {
    try {
      const newSiteMap = addProductToSiteMap(siteMap, plu, category, subCategory);
      setSiteMap(newSiteMap);
    } catch (error) {
      setErrorDialogOpen(true);
    } finally {
      setAddDialogOpen(false);
    }
  }, [siteMap, plu, category, subCategory]);

  const handleCategorySelectChange = useCallback((event: ChangeEvent<{ value: unknown }>) => {
    const { target: { value } } = event;
    if (typeof value === 'string') {
      setCategory(value);
    }
  }, []);

  const handleSubCategorySelectChange = useCallback((event: ChangeEvent<{ value: unknown }>) => {
    const { target: { value } } = event;
    if (typeof value === 'string') {
      setSubCategory(value);
    }
  }, []);

  const handleErrorOkClick = useCallback(() => setErrorDialogOpen(false), []);

  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      <List>
        {locations.map((location) => (
          <ListItem key={`${location.category}:${location.subcategory}`} divider>
            <ListItemText primary={`${location.category} / ${location.subcategory}`} />
            <ListItemSecondaryAction>
              <Button
                variant="text"
                color="default"
                onClick={() => handleDeleteClick(location)}
                startIcon={<Delete />}
              >
                Remove
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        {locations.length === 0 && (
          <ListItem divider>
            <ListItemText primary="Product does not appear in any subcategories" />
          </ListItem>
        )}
      </List>
      <Grid style={{ paddingTop: 10 }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleAddSubcategoryClick}
        >
          Add to Menu
        </Button>
      </Grid>
      <Dialog open={addDialogOpen}>
        <DialogTitle>Add Product To Menu</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select the Category and Subcategory where this product should appear on the Menu.
          </DialogContentText>
          <FormControl fullWidth>
            <InputLabel id="category-input-label">Category</InputLabel>
            <Select
              labelId="category-input-label"
              value={category}
              onChange={handleCategorySelectChange}
            >
              {siteMap.map(({ category: categoryName }) => (
                <MenuItem key={categoryName} value={categoryName}>{categoryName}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="subcategory-input-label">Subcategory</InputLabel>
            <Select
              labelId="subcategory-input-label"
              value={subCategory}
              onChange={handleSubCategorySelectChange}
              disabled={category === ''}
            >
              {siteMap
                .find(({ category: categoryName }) => categoryName === category)?.subCategories
                .map(({ name: subCategoryName }) => (
                  <MenuItem
                    key={subCategoryName}
                    value={subCategoryName}
                  >
                    {subCategoryName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={handleAddSubcategoryCancel}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleAddSubcategorySave}>
            Add to Menu
          </Button>
        </DialogActions>
      </Dialog>
      <ErrorDialog open={errorDialogOpen} onOkButtonClick={handleErrorOkClick} />
    </Box>
  );
};

export default AddToMenuForm;
