import firebase from 'firebase/app';

const setSiteWaitingTimes = (
  siteId: string,
  waitingTimes: { foodWaitingTime: number, drinksWaitingTime: number },
) => (
  firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .update({
      waitingTimes,
    })
);

export default setSiteWaitingTimes;
