import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Grid, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';

import KiosksTable from './components/KiosksTable';
import KioskOptions from './components/KioskOptions';
import Spinner from '../../components/spinner/spinner';

import getSettings from '../../actions/get-settings';

import ReduxState from '../../types/ReduxState';
import Nullable from '../../types/Nullable';
import SiteData from '../../types/SiteData';
import User from '../../types/User';

import useUrlParams from '../../hooks/useUrlParams';

const KioskPage = () => {
  const dispatch = useDispatch();
  const authenticatedUser = useSelector<ReduxState, Nullable<User>>(
    (state) => state.authentication.get('USER'),
  );
  const settings = useSelector<ReduxState, Nullable<SiteData>>(
    (state) => state.settings.get('SETTINGS'),
  );

  const {
    values: [tab],
    setters: [onTabChange],
  } = useUrlParams(['tab']);

  useEffect(() => {
    if (authenticatedUser != null && settings == null) {
      dispatch(getSettings(authenticatedUser.site));
    }
  }, [authenticatedUser]);

  const activeTab = useMemo(() => {
    if (['1', '2'].includes(tab || '')) return tab;
    return '1';
  }, [tab]);

  const handleTabChange = useCallback((event: React.ChangeEvent<{}>, newActiveTab: string) => (
    onTabChange(newActiveTab)
  ), []);

  const tabs = useMemo(() => {
    const tabsAvailable = [
      <Tab label="Kiosks" value="1" key="1" />,
      <Tab label="Kiosk Options" value="2" key="2" />,
    ];
    return tabsAvailable;
  }, []);

  if (!settings || !authenticatedUser) return <Spinner />;

  return (
    <Grid>
      <TabContext value={activeTab || '1'}>
        <Box>
          <TabList onChange={handleTabChange}>{tabs}</TabList>
        </Box>
        <TabPanel value="1">
          <KiosksTable siteData={settings} />
        </TabPanel>
        <TabPanel value="2">
          <KioskOptions authenticatedUser={authenticatedUser} />
        </TabPanel>
      </TabContext>
    </Grid>
  );
};

export default KioskPage;
