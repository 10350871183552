import firebase from 'firebase/app';

import Label from '../types/Label';
import SiteData from '../types/SiteData';

const setLabelOffline = (siteId: SiteData['id'], ladelId: Label['id']) => firebase
  .firestore()
  .collection('sites')
  .doc(siteId)
  .update({ offlineLabels: firebase.firestore.FieldValue.arrayRemove(ladelId) });

export default setLabelOffline;
