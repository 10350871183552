const getDefaultTimes = () => [0, 1, 2, 3, 4, 5, 6].map((day) => ({
  day,
  open: '00:00',
  close: '00:00',
  kitchenOpen: '00:00',
  kitchenClose: '00:00',
  closed: false,
}));

export default getDefaultTimes;
