import downloadFile from '../../../helpers/downloadFile';

import ProductsReportData from '../../../types/ProductsReportData';

const downloadProductsReport = (productsData: ProductsReportData[]) => {
  const productsReport = productsData.reduce<string[]>((acc, salesReport) => {
    const {
      plu, name, reportingLabels, quantity, total,
    } = salesReport;
    acc.push([
      plu,
      `"${name}"`,
      reportingLabels.join(';'),
      quantity,
      total,
    ].join(','));
    return acc;
  }, []);

  return downloadFile({
    data: ['PLU,Name,Reporting Labels,Quantity,Total', ...productsReport].join('\n'),
    fileName: 'productsReport.csv',
    fileType: 'text/csv',
  });
};

export default downloadProductsReport;
