import React from 'react';
import { useSelector } from 'react-redux';

import { Grid } from '@material-ui/core';

import UnpaidOrdersTable from '../../components/UnpaidOrdersTable/UnpaidOrdersTable';

import ReduxState from '../../types/ReduxState';
import SiteData from '../../types/SiteData';
import User from '../../types/User';
import Nullable from '../../types/Nullable';

const SiteUnpaidOrdersPage = () => {
  const user = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));
  const settings = useSelector<ReduxState, Nullable<SiteData>>(
    (state) => state.settings.get('SETTINGS'),
  );

  return (
    <Grid>
      <UnpaidOrdersTable user={user} site={user?.site || ''} siteData={settings} />
    </Grid>
  );
};

export default SiteUnpaidOrdersPage;
