import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  AppBar, Grid, IconButton, Toolbar, Typography, Tooltip, makeStyles,
} from '@material-ui/core';
import {
  Brightness4, Brightness7,
} from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';

import NotificationMenu from './notification-menu';
import UserMenu from './user-menu';
import HelpMenu from './HelpMenu';

import capitalize from '../../../helpers/capitalize';

const useStyles = makeStyles((theme) => ({
  homeButton: {
    marginRight: theme.spacing(2),
  },
}));

const AppHeader = ({
  user, notifications, location, darkState, onToggle, onOpen,
}) => {
  const siteData = useSelector((state) => state.settings.get('SETTINGS'));
  const classes = useStyles();

  const pages = location.pathname.split('/');
  const path = location.pathname;

  const title = useMemo(() => {
    if (path === '/' && siteData != null) return siteData.name;
    if (path === '/') return 'Home';
    if (
      (path.includes('manage-site-users') && path.includes('edit'))
      || (path.includes('pos') && path.includes('user'))
    ) return 'Edit User';
    return capitalize(pages[pages.length - 1].replace(/-/g, ' '));
  }, [location, siteData]);

  return (
    <AppBar position="static">
      <Toolbar>
        <Grid
          alignItems="center"
          justify="space-between"
          container
        >
          <IconButton
            className={classes.homeButton}
            onClick={onOpen}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6">
            {title}
          </Typography>
          <Grid>
            <HelpMenu />
            <Tooltip title="Toggle light/dark theme">
              <IconButton
                onClick={onToggle}
                color="inherit"
              >
                {darkState ? <Brightness7 /> : <Brightness4 />}
              </IconButton>
            </Tooltip>
            <NotificationMenu notifications={notifications} />
            <UserMenu user={user} />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

AppHeader.propTypes = {
  user: PropTypes.shape({}),
  notifications: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  darkState: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
};

AppHeader.defaultProps = {
  user: null,
};

export default AppHeader;
