import React, { useCallback, useState } from 'react';

import {
  Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid, Typography,
} from '@material-ui/core';

import AdornmentTextField from '../../../components/adornment-text-field/adornment-text-field';
import OutlinedTextField from '../../../components/outlined-text-field/outlined-text-field';
import SimpleSelect from '../../../components/SimpleSelect/SimpleSelect';
import BackdropSpinner from '../../../components/backdrop-spinner/backdrop-spinner';
import SnackBar from '../../../components/snack-bar/snack-bar';

import hasPermission from '../../../helpers/has-permission';
import request from '../../../helpers/request';
import getRefundAmountLeft from '../helpers/getRefundAmountLeft';
import formatMoney from '../../../helpers/formatMoney';
import getCurrencyAdornment from '../../../helpers/getCurrencyAdornment';

import RoleRestrictedAction from '../../../types/RoleRestrictedAction';
import OrderData from '../../../types/OrderData';
import SiteData from '../../../types/SiteData';
import User from '../../../types/User';
import Nullable from '../../../types/Nullable';

const refundReasons = [
  { label: 'Duplicate', value: 'duplicate' },
  { label: 'Fraudulent', value: 'fraudulent' },
  { label: 'Requested by customer', value: 'requested_by_customer' },
  { label: 'Other', value: 'other' },
];

type Props = {
  siteData: Nullable<SiteData>
  orderData: OrderData
  user: User
};

const RefundConfirmationByAmount = ({ siteData, orderData, user }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [snackOpen, setSnackOpen] = useState<boolean>(false);
  const [amount, setAmount] = useState<string | number>(getRefundAmountLeft(orderData));
  const [refundReason, setRefundReason] = useState<string>('');
  const [additionalInfo, setAdditionalInfo] = useState<string>('');
  const [reverseTransfer] = useState<boolean>(true);
  const [refundError, setRefundError] = useState<Nullable<String>>(null);
  const [refundRequested, setRefundRequested] = useState<boolean>(false);
  const [spinner, setSpinner] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    setSnackOpen(false);
  }, []);

  const handleConfirm = () => {
    const requestedAmount = Number(amount);
    const refundAmount = Number(getRefundAmountLeft(orderData));
    if (requestedAmount >= 0 && requestedAmount <= refundAmount) {
      if (orderData.paymentIntent && orderData.paymentIntent.id) {
        setSpinner(true);
        request(
          'stripeRefundAmount',
          'POST',
          {
            charge: orderData.paymentIntent.id,
            amount: Number(amount) * 100,
            refundReason,
            additionalInfo,
            reverseTransfer,
          },
        )
          .then(() => {
            setRefundRequested(true);
            setSpinner(false);
            setOpen(false);
            setSnackOpen(true);
            setRefundReason('');
            setAdditionalInfo('');
          })
          .catch(() => {
            setSpinner(false);
            setRefundError('Something went wrong');
            handleClose();
          });
      } else {
        setRefundError('Order is missing charge ID, please contact support.');
      }
    } else {
      setRefundError(`Amount must be under ${formatMoney(refundAmount, siteData?.currency)}`);
    }
  };

  const handlePriceChange = useCallback((event) => {
    const { value } = event.target;
    setAmount(value);
  }, []);

  const handleReasonChange = useCallback((value) => {
    setRefundReason(value);
  }, []);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => setOpen(true)}
        disabled={!hasPermission(RoleRestrictedAction.RefundOrder, user) || refundRequested}
      >
        {refundRequested ? 'Refund Requested' : 'Refund Order'}
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Refund order: ${orderData.orderNumber}`}</DialogTitle>
        <DialogContent dividers>
          <BackdropSpinner open={spinner} />
          <DialogContentText id="alert-dialog-description">
            Refunds take 5-10 days to appear on a customers statement
          </DialogContentText>
          <AdornmentTextField
            id="outlined-adornment-amount"
            label="Amount"
            value={amount}
            onChange={handlePriceChange}
            adornment={getCurrencyAdornment(siteData?.currency)}
            labelWidth={60}
            required
          />
          <SimpleSelect label="Refund Reason" value={refundReason} items={refundReasons} onChange={handleReasonChange} labelWidth={110} />
          <OutlinedTextField
            id="outlined-name"
            classOption="flex"
            label="Additional Information"
            value={additionalInfo}
            onChange={(event) => setAdditionalInfo(event.target.value)}
          />
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            {refundError ? <Typography variant="body1" color="error" gutterBottom>{refundError}</Typography> : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus disabled={amount === 0 || refundReason.length === 0}>
            Refund
          </Button>
        </DialogActions>
      </Dialog>
      <SnackBar
        open={snackOpen}
        onClose={handleClose}
        message="Refund successful - order will be updated soon."
      />
    </div>
  );
};

export default RefundConfirmationByAmount;
