import apiRequest from './api-request';

type StripeAccountDetails = {
  stripeAccountDetails: {
    detailsSubmitted: boolean;
    statementDescriptor: string;
  }
};

const getStripeAccountDetails = async (stripeId: string): Promise<StripeAccountDetails> => {
  const { data } = await apiRequest(`stripe/account/${stripeId}`, 'GET');
  return { stripeAccountDetails: data };
};

export default getStripeAccountDetails;
