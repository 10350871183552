import Axios, { AxiosRequestConfig, Method } from 'axios';

const apiUrl = process.env.API_GATEWAY_URL;

const apiRequest = <T extends {}, U extends {}>(
  endpoint: string,
  method: Method,
  body?: U,
  headers?: T,
) => {
  const options: AxiosRequestConfig = {
    method,
    url: `${apiUrl}/${endpoint}`,
    headers,
  };
  if (body) {
    options.data = JSON.stringify(body);
  }

  return Axios(options);
};

export default apiRequest;
