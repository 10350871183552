import { Map } from 'immutable';
import {
  GET_SITES_START,
  GET_SITES_SUCCESS,
  GET_SITES_ERROR,
} from '../constants/actions';
import ReduxAction from '../types/ReduxAction';
import ReduxState from '../types/ReduxState';

const initialStateMap: ReduxState['sites'] = Map()
  .set('IS_SITES_LOADING', false)
  .set('SITE_NAMES', [])
  .set('NUMBER_OF_SITES', 0)
  .set('SITES', [])
  .set('SITES_LOADING_ERROR', '');

export default (
  state: ReduxState['sites'] = initialStateMap,
  action: ReduxAction,
) => {
  switch (action.type) {
    case GET_SITES_START:
      return state
        .set('IS_SITES_LOADING', true)
        .set('SITE_NAMES', [])
        .set('NUMBER_OF_SITES', 0)
        .set('SITES', [])
        .set('SITES_LOADING_ERROR', '');
    case GET_SITES_SUCCESS:
      return state
        .set('IS_SITES_LOADING', false)
        .set('SITE_NAMES', action.siteNames)
        .set('NUMBER_OF_SITES', action.numberOfSites)
        .set('SITES', action.sites);
    case GET_SITES_ERROR:
      return state
        .set('IS_SITES_LOADING', false)
        .set('SITES_LOADING_ERROR', action.message);
    default:
      return state;
  }
};
