import { firestore } from 'firebase/app';

import Nullable from '../types/Nullable';
import GiftCard from '../types/GiftCard';
import isGiftCard from './isGiftCard';

const initialiseGiftCardDataListener = (
  giftCardId: GiftCard['id'],
  setGiftCardData: React.Dispatch<React.SetStateAction<Nullable<GiftCard>>>,
  setError: React.Dispatch<React.SetStateAction<Nullable<Error>>>,
) => (
  firestore()
    .collection('giftcards')
    .doc(giftCardId)
    .onSnapshot((querySnapshot) => {
      const data = { ...querySnapshot.data() };
      if (isGiftCard(data)) {
        setGiftCardData(data);
      } else {
        setError(new Error('Gift Card data could not be loaded successfully.'));
      }
    },
    (error) => {
      setError(error);
    })
);

export default initialiseGiftCardDataListener;
