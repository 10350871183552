import React,
{
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@material-ui/core';

import Spinner from '../../components/spinner/spinner';
import UsersTable from '../../components/UsersTable/UsersTable';

import getUsers from '../../actions/get-users';

import apiRequest from '../../helpers/api-request';

import ReduxState from '../../types/ReduxState';
import Nullable from '../../types/Nullable';
import User from '../../types/User';

const ManageSiteUsersPage = () => {
  const history = useHistory();
  const authenticatedUser = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));
  const isUsersLoading = useSelector<ReduxState, boolean>((state) => state.users.get('IS_USERS_LOADING'));
  const users = useSelector<ReduxState, User[]>((state) => state.users.get('USERS'));
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const userSiteConfig = useMemo(
    () => authenticatedUser?.sites.find((siteConfig) => siteConfig.site === authenticatedUser.site),
    [authenticatedUser],
  );

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const filteredUsers = useMemo(() => (
    users.filter((user) => {
      if (user.admin) return false;
      const userSites = user.sites.map((siteConfig) => siteConfig.site);
      return userSiteConfig && userSites.includes(userSiteConfig.site);
    })
  ), [users, userSiteConfig]);

  const onAdd = useCallback(() => history.push('/manage-site-users/add'), [history]);

  const onRemove = useCallback(async (uid: User['id']) => {
    setLoading(true);
    try {
      await apiRequest('remove-site-user', 'POST', { uid, site: authenticatedUser?.site });
      setLoading(false);
      dispatch(getUsers());
    } catch {
      setLoading(false);
      setError(true);
    }
  }, [setLoading, setError, dispatch]);

  const onEdit = useCallback((userId: string) => history.push(`manage-site-users/edit/${userId}`), [history]);

  const disableRemoveCallback = useCallback(
    (rowData: User) => rowData.id === authenticatedUser?.id,
    [authenticatedUser],
  );

  const disableEditCallback = useCallback(
    (rowData: User) => rowData.id === authenticatedUser?.id,
    [authenticatedUser],
  );

  const handleErrorOkClick = useCallback(() => setError(false), [setError]);

  if (isUsersLoading || !authenticatedUser || loading) return <Spinner />;

  return (
    <Grid>
      <UsersTable
        tableData={filteredUsers}
        onAdd={onAdd}
        onRemove={onRemove}
        onEdit={onEdit}
        disableRemoveCallback={disableRemoveCallback}
        disableEditCallback={disableEditCallback}
        siteUsers
      />
      <Dialog
        open={error}
      >
        <DialogTitle>Something went wrong</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The user could not be removed - please try again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorOkClick} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default ManageSiteUsersPage;
