/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Paper, Grid, List, ListItem, ListItemText, makeStyles, ListSubheader,
} from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import OutlinedTextField from '../outlined-text-field/outlined-text-field';

import reorder from './helpers/reorder';
import move from './helpers/move';

const useStyles = makeStyles({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 300,
    height: 500,
    overflow: 'auto',
    margin: 10,
  },
});

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: 10 * 2,
  margin: `0 0 ${10}px 0`,
  background: isDragging ? 'lightgreen' : 'grey',
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : '#424242',
  padding: 20,
  width: 300,
});

const DragAndDropList = ({
  productsToUse,
  productsUsed,
  unremovableProducts,
  onChange,
}) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [left, setLeft] = useState(productsToUse);
  const [right, setRight] = useState(
    productsUsed.sort((a, b) => a.sequence - b.sequence),
  );
  const [isDropDisabled, setIsDropDisabled] = useState(false);

  const handleChange = (r) => {
    onChange('products', r.map(({ plu }, index) => ({ plu, sequence: index + 1 })));
  };

  const getList = (id) => (id === 'available-products' ? left : right);

  const onDragStart = ({ source: { index, droppableId } }) => {
    // 'droppableId' references the Droppable where the currently in-flight Draggable originated
    if (droppableId === 'selected-products' && unremovableProducts.includes(right[index].plu)) setIsDropDisabled(true);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    setIsDropDisabled(false);

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index,
      );

      if (source.droppableId === 'selected-products') {
        setRight(items);
        handleChange(items);
      }
    } else {
      const res = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination,
      );

      setLeft(res['available-products']);
      setRight(res['selected-products']);
      handleChange(res['selected-products']);
    }
  };

  const getIndex = (plu) => left.map((l) => l.plu).indexOf(plu);

  const productsToAdd = search.length !== 0
    ? left.filter((l) => l.name.toLowerCase().includes(search.toLowerCase()))
    : left;

  return (
    <Grid container direction="row" spacing={2} justify="center" alignItems="center" className={classes.root}>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Paper className={classes.paper}>
          <Droppable droppableId="available-products" isDropDisabled={isDropDisabled}>
            {(provided, snapshot) => (
              <List
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                dense
                component="div"
                role="list"
              >
                <ListSubheader>
                  Products to add
                  <OutlinedTextField
                    id="outlined-search"
                    label="Search"
                    classOption="normal"
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                  />
                </ListSubheader>
                {productsToAdd.map((item) => {
                  const labelId = `transfer-list-item-${item.plu}-label`;
                  return (
                    <Draggable
                      key={labelId}
                      draggableId={labelId}
                      index={getIndex(item.plu)}
                    >
                      {(prov, snap) => (
                        <ListItem
                          ref={prov.innerRef}
                          {...prov.draggableProps}
                          {...prov.dragHandleProps}
                          style={getItemStyle(
                            snap.isDragging,
                            prov.draggableProps.style,
                          )}
                        >
                          <ListItemText id={labelId} primary={`${item.name} (${item.plu})`} />
                        </ListItem>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </Paper>
        <Paper className={classes.paper}>
          <Droppable droppableId="selected-products">
            {(provided, snapshot) => (
              <List
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                dense
                component="div"
                role="list"
              >
                <ListSubheader>Added products</ListSubheader>
                {right.map((item, index) => {
                  const labelId = `transfer-list-item-${item.plu}-label`;
                  return (
                    <Draggable
                      key={labelId}
                      draggableId={labelId}
                      index={index}
                    >
                      {(prov, snap) => (
                        <ListItem
                          ref={prov.innerRef}
                          {...prov.draggableProps}
                          {...prov.dragHandleProps}
                          style={getItemStyle(
                            snap.isDragging,
                            prov.draggableProps.style,
                          )}
                        >
                          <ListItemText id={labelId} primary={`${item.name} (${item.plu})`} secondary={`Sequence: ${index + 1}`} />
                        </ListItem>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </Paper>
      </DragDropContext>
    </Grid>
  );
};

DragAndDropList.propTypes = {
  productsToUse: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  productsUsed: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  unremovableProducts: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DragAndDropList;
