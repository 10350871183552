import React, { useState } from 'react';

import {
  Dialog, DialogTitle, DialogContent, DialogActions, Typography, Grid,
} from '@material-ui/core';

import CheckBox from '../../../components/check-box/check-box';
import OutlinedTextField from '../../../components/outlined-text-field/outlined-text-field';
import OutlinedButton from '../../../components/outlined-button/outlined-button';

type Props = {
  open: boolean
  onSave: (daysToDo: number, regenerate: boolean) => void
  handleClose: () => void
  message: string
};

const SlotTimesDialog = ({
  open, onSave, handleClose, message,
}: Props) => {
  const [days, setDays] = useState<string>('7');
  const [regenerate, setRegenerate] = useState<boolean>(false);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Generate Slot Times</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          {regenerate
            ? 'Regenerating slot times will replace any slot time that exist!'
            : 'Generating slot times will add to the slot times that exist!'}
        </Typography>
        <CheckBox
          label="Regenerate"
          checked={regenerate}
          onChange={() => setRegenerate((value) => !value)}
        />
        <OutlinedTextField
          id="outlined-days-to-do"
          classOption="flex"
          type="number"
          label="Days in advance"
          value={days}
          onChange={(event) => setDays(event.target.value)}
          required
        />
        <Grid>
          {message ? <Typography gutterBottom>{message}</Typography> : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <OutlinedButton
          label="Cancel"
          color="secondary"
          onClick={handleClose}
        />
        <OutlinedButton
          label="Generate"
          color="primary"
          onClick={() => onSave(Number(days), regenerate)}
          disabled={days.length === 0}
        />
      </DialogActions>
    </Dialog>
  );
};

export default SlotTimesDialog;
