import firebase from 'firebase/app';

import EditedDiscount from '../types/EditedDiscount';

const createDiscount = (discount: EditedDiscount, site: string) => firebase
  .firestore()
  .collection('discounts')
  .doc()
  .set({ ...discount, site });

export default createDiscount;
