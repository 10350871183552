import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';

import { Grid } from '@material-ui/core';

import Spinner from '../../components/spinner/spinner';
import SimpleCard from '../../components/simple-card/simple-card';

import initialiseDiscountsListener from '../../helpers/initialiseDiscountsListener';

import getProducts from '../../actions/get-products';
import getSiteMap from '../../actions/get-site-map';
import getSettings from '../../actions/get-settings';
import getOrderStats from '../../actions/get-order-stats';
import getUnprocessedOrders from '../../actions/get-unprocessed-orders';

import hasPermission from '../../helpers/has-permission';
import formatMoney from '../../helpers/formatMoney';

import RoleRestrictedAction from '../../types/RoleRestrictedAction';
import AppUrl from '../../types/AppUrl';

const SiteDashboardPage = () => {
  const user = useSelector((state) => state.authentication.get('USER'));
  const isProductsLoading = useSelector((state) => state.products.get('IS_PRODUCTS_LOADING'));
  const amountOfProducts = useSelector((state) => state.products.get('AMOUNT_OF_PRODUCTS'));
  const isSiteMapLoading = useSelector((state) => state.siteMap.get('IS_SITE_MAP_LOADING'));
  const siteMap = useSelector((state) => state.siteMap.get('SITE_MAP'));
  const isSettingsLoading = useSelector((state) => state.settings.get('IS_SETTINGS_LOADING'));
  const settings = useSelector((state) => state.settings.get('SETTINGS'));
  const isSiteActive = useSelector((state) => state.settings.get('IS_SITE_ACTIVE'));
  const isOrderStatsLoading = useSelector((state) => state
    .siteOrderStats.get('IS_ORDER_STATS_LOADING'));
  const orderStats = useSelector((state) => state.siteOrderStats.get('ORDER_STATS'));
  const isUnprocessedOrdersLoading = useSelector((state) => state
    .unprocessedOrders.get('IS_UNPROCESSED_ORDERS_LOADING'));
  const numberOfUnprocessedOrders = useSelector((state) => state
    .unprocessedOrders.get('NUMBER_OF_UNPROCESSED_ORDERS'));
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [discounts, setDiscounts] = useState([]);
  const [error, setError] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const { role } = user;

  const onDiscountsLoad = useCallback((discountsData) => {
    setIsDataLoading(false);
    setDiscounts(discountsData);
  }, []);

  const onDiscountsError = useCallback((err) => {
    setIsDataLoading(false);
    setError(err);
  }, []);

  useEffect(() => {
    if (user != null && user.site) {
      setIsDataLoading(true);
      dispatch(getProducts(user.site));
      dispatch(getSiteMap(user.site));
      dispatch(getSettings(user.site));
      dispatch(getOrderStats(user.site));
      dispatch(getUnprocessedOrders(user.site));
      return initialiseDiscountsListener(user.site, onDiscountsLoad, onDiscountsError);
    }
    return () => { };
  }, []);

  const userSiteConfig = useMemo(
    () => user.sites.find((data) => data.site === user.site),
    [user],
  );

  if (userSiteConfig.reportingLabels
    && userSiteConfig.reportingLabels.length !== 0) return <Redirect to={AppUrl.Home} />;

  if (isProductsLoading
    || isSiteMapLoading
    || isSettingsLoading
    || isOrderStatsLoading
    || isUnprocessedOrdersLoading
    || isDataLoading) return <Spinner />;

  const management = role === 'admin' || role === 'manager';

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid
        container
        direction="row"
      >
        <SimpleCard title="Products" mainText={amountOfProducts} color="green" onClick={() => history.push(AppUrl.Products)} />
        <SimpleCard title="Categories" mainText={siteMap.length} color="green" onClick={() => history.push(AppUrl.SiteMap)} />
        <SimpleCard title="Status" mainText={isSiteActive ? 'Online' : 'Offline'} color={isSiteActive ? 'green' : 'red'} onClick={() => history.push(AppUrl.Settings)} disabled={!management} />
      </Grid>
      {orderStats ? (
        <>
          <Grid
            container
            direction="row"
          >
            <SimpleCard title="Todays Orders" mainText={orderStats.numberOfOrdersToday || 0} color="green" onClick={() => history.push(AppUrl.SiteOrders)} />
            <SimpleCard
              title="Todays Sales"
              mainText={formatMoney(orderStats.amountOfSalesToday || 0, settings?.currency)}
              color="green"
              onClick={() => history.push(AppUrl.SiteOrders)}
            />
            <SimpleCard title="Unprocessed Orders" mainText={numberOfUnprocessedOrders || 0} color={numberOfUnprocessedOrders !== 0 ? 'red' : 'Green'} onClick={() => history.push(AppUrl.SiteUnprocessedOrders)} />
          </Grid>
          <Grid
            container
            direction="row"
          >
            <SimpleCard title="Overall Orders" mainText={orderStats.numberOfOrders || 0} color="green" onClick={() => history.push(AppUrl.SiteOrders)} />
            <SimpleCard
              title="Overall Sales"
              mainText={formatMoney(orderStats.amountOfSales || 0, settings?.currency)}
              color="green"
              onClick={() => history.push(AppUrl.SiteOrders)}
            />
          </Grid>
        </>
      ) : null}
      <Grid
        container
        direction="row"
      >
        {hasPermission(RoleRestrictedAction.ViewDiscountCode, user)
          ? <SimpleCard title="Discounts" mainText={discounts.length} color="green" onClick={() => history.push(AppUrl.Discounts)} />
          : null}
        {error?.message}
      </Grid>
    </Grid>
  );
};

export default SiteDashboardPage;
