import React, { ChangeEvent } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import MultipleSelect from '../../../components/options-form/components/multiple-select';
import ProductData from '../../../types/ProductData';
import Choice from '../../../types/Choice';

const useStyles = makeStyles({
  accordian: {
    margin: '12px 0',
    borderRadius: '4px',
    '&:before': {
      display: 'none',
    },
  },
  accordianHeading: {
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accordianDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  productSelect: {
    margin: '24px 0',
    width: '50%',
  },
});

interface Props {
  expanded: boolean;
  onExpandedChange: (
    _event: ChangeEvent<{}>,
    isExpanded: boolean,
  ) => void;
  heading: string;
  value: number[];
  options: ProductData[];
  onSelectChange: (
    options: Choice[],
  ) => Promise<void>;
}

const PopularProductsSelect = ({
  expanded,
  onExpandedChange,
  heading,
  value,
  options,
  onSelectChange,
}: Props) => {
  const classes = useStyles();

  const selectedProducts = value
    .map((plu) => options.find((product) => product.plu === plu))
    .filter((product) => product != null) as ProductData[];

  return (
    <Accordion
      className={classes.accordian}
      expanded={expanded}
      onChange={onExpandedChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
      >
        <Typography className={classes.accordianHeading}>{heading}</Typography>
        <Typography color="textSecondary">
          {`${value.length} products`}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordianDetails}>
        <Typography variant="body2" color="textSecondary">
          Select your popular products for quicker access on POS
        </Typography>
        <MultipleSelect
          className={classes.productSelect}
          options={options}
          changeOption={(newOptions) => onSelectChange(newOptions)}
          defaultValues={selectedProducts}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default PopularProductsSelect;
