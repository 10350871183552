import React,
{
  useCallback,
  useMemo,
  useEffect,
  useState,
} from 'react';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import {
  Box,
  Button,
  Grid,
  Tab,
  makeStyles,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowForward } from '@material-ui/icons';

import POSLocationsTable from './components/POSLocationsTable';
import PopularProducts from './components/PopularProducts';
import POSUsersTable from './components/POSUsersTable';
import POSOptions from './components/POSOptions';
import Spinner from '../../components/spinner/spinner';
import POSOtherPaymentsTable from './components/POSOtherPaymentsTable';

import getSettings from '../../actions/get-settings';
import getProducts from '../../actions/get-products';

import initialiseUsersListener from '../../helpers/initialiseUsersListener';

import ReduxState from '../../types/ReduxState';
import Nullable from '../../types/Nullable';
import SiteData from '../../types/SiteData';
import User from '../../types/User';
import ProductData from '../../types/ProductData';

import useUrlParams from '../../hooks/useUrlParams';

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const POSPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const authenticatedUser = useSelector<ReduxState, Nullable<User>>(
    (state) => state.authentication.get('USER'),
  );
  const settings = useSelector<ReduxState, Nullable<SiteData>>(
    (state) => state.settings.get('SETTINGS'),
  );
  const isProductsLoading = useSelector<ReduxState, boolean>(
    (state) => state.products.get('IS_PRODUCTS_LOADING'),
  );
  const products = useSelector<ReduxState, ProductData[]>(
    (state) => state.products.get('PRODS'),
  );
  const [isUsersLoading, setIsUsersLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const {
    values: [tab],
    setters: [onTabChange],
  } = useUrlParams(['tab']);

  const activeTab = useMemo(() => {
    if (['1', '2', '3', '4', '5'].includes(tab || '')) return tab;
    return '1';
  }, [tab]);

  const onUsersLoad = useCallback((usersData: User[]) => {
    setIsUsersLoading(false);
    setUsers(usersData);
  }, []);

  const onUsersError = useCallback(() => {
    setIsUsersLoading(false);
    setUsers([]);
  }, []);

  useEffect(() => {
    setIsUsersLoading(true);
    const unsubscribe = initialiseUsersListener(onUsersLoad, onUsersError);
    return () => unsubscribe();
  }, [onUsersLoad, onUsersError]);

  useEffect(() => {
    if (authenticatedUser != null) {
      dispatch(getSettings(authenticatedUser.site));
      dispatch(getProducts(authenticatedUser.site));
    }
  }, [authenticatedUser]);

  const handleTabChange = useCallback((event: React.ChangeEvent<{}>, newActiveTab: string) => (
    onTabChange(newActiveTab)
  ), []);

  const tabs = useMemo(() => {
    const tabsAvailable = [
      <Tab label="POS locations" value="1" key="1" />,
      <Tab label="Popular products" value="2" key="2" />,
      <Tab label="POS users" value="3" key="3" />,
      <Tab label="POS Options" value="4" key="4" />,
      <Tab label="Additional payment types" value="5" key="5" />,
    ];
    return tabsAvailable;
  }, []);

  const handleGoToPOSClick = () => {
    if (settings != null) {
      let urlDomain = 'https://pos.peazi.com';
      if (process.env.NODE_ENV === 'development') {
        urlDomain = 'https://pos.dev.peazi.com';
      }
      if (process.env.NODE_ENV === 'staging') {
        urlDomain = 'https://pos.stage.peazi.com';
      }
      window.open(`${urlDomain}/${settings.urlEndpoint}`);
    }
  };

  const posUsers = useMemo(() => {
    if (authenticatedUser == null) return [];
    return users.filter((user) => {
      const config = user.sites.find(({ site }) => site === authenticatedUser.site);
      return config != null && config.POSUser === true;
    });
  }, [authenticatedUser, users]);

  if (!settings || !authenticatedUser) return <Spinner />;

  return (
    <Grid>
      <Box
        className={classes.buttonContainer}
      >
        <Button
          endIcon={<ArrowForward />}
          variant="outlined"
          onClick={handleGoToPOSClick}
        >
          Go to POS
        </Button>
      </Box>
      <TabContext value={activeTab || '1'}>
        <Box>
          <TabList onChange={handleTabChange}>{tabs}</TabList>
        </Box>
        <TabPanel value="1">
          <POSLocationsTable
            siteId={settings?.id || null}
            siteAddress={settings?.address || null}
          />
        </TabPanel>
        <TabPanel value="2">
          <PopularProducts
            user={authenticatedUser}
            products={products}
            isProductsLoading={isProductsLoading}
          />
        </TabPanel>
        <TabPanel value="3">
          <POSUsersTable
            authenticatedUser={authenticatedUser}
            isUsersLoading={isUsersLoading}
            users={posUsers}
            settings={settings}
          />
        </TabPanel>
        <TabPanel value="4">
          <POSOptions
            authenticatedUser={authenticatedUser}
          />
        </TabPanel>
        <TabPanel value="5">
          <POSOtherPaymentsTable
            settings={settings}
            users={users}
          />
        </TabPanel>
      </TabContext>
    </Grid>
  );
};

export default POSPage;
