import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Button,
  Drawer,
  Typography,
  Grid,
  makeStyles,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import LaunchIcon from '@material-ui/icons/Launch';
import apiRequest from '../../helpers/api-request';

import Spinner from '../../components/spinner/spinner';
import SiteForm from '../../components/site-form/site-form';
import SiteProfile from './components/site-profile';
import AccountsInfoDialog from '../../components/AccountsInfoDialog/AccountsInfoDialog';
import SiteTitle from './components/SiteTitle';

import getSettings from '../../actions/get-settings';
import updateSiteSettings from '../../actions/update-site-settings';

import setupStripe from './helpers/setup-stripe';
import hasPermission from '../../helpers/has-permission';
import uploadImage from '../../helpers/upload-image';
import getImageUrl from '../../helpers/get-image-url';
import getReaderImagePathFromUrl from '../../helpers/get-reader-image-path-from-url';

import AppUrl from '../../types/AppUrl';
import ReduxState from '../../types/ReduxState';
import User from '../../types/User';
import Dispatch from '../../types/Dispatch';
import Nullable from '../../types/Nullable';
import SiteData from '../../types/SiteData';
import RoleRestrictedAction from '../../types/RoleRestrictedAction';
import AccountsInfo from '../../types/AccountsInfo';
import Country from '../../types/Country';

const useStyles = makeStyles((theme) => ({
  mainSection: { marginBottom: theme.spacing(10) },
  alert: { marginBottom: 20 },
  buttons: { padding: 10, paddingRight: 20 },
  backButton: { marginRight: 10 },
}));

const SettingsPage = () => {
  const user = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));
  const isSettingsLoading = useSelector<ReduxState, boolean>((state) => state.settings
    .get('IS_SETTINGS_LOADING'));
  const settings = useSelector<ReduxState, Nullable<SiteData>>((state) => state.settings.get('SETTINGS'));
  const updateSettingsError = useSelector<ReduxState, string>((state) => state.settings
    .get('SITE_SETTINGS_ERROR'));
  const [updating, setUpdating] = useState(false);
  const [edit, setEdit] = useState(false);
  const classes = useStyles();
  const dispatch: Dispatch = useDispatch();
  const history = useHistory();
  const [accountsInfoDialogOpen, setAccountsInfoDialogOpen] = useState<boolean>(false);

  const hasStripeAccount = (settings != null && settings.stripeConnect);

  useEffect(() => {
    if (user != null) {
      dispatch(getSettings(user.site));
    }
  }, [user]);

  const onSave = async (
    siteData: SiteData,
    logoImage: Nullable<File>,
    readerImage: Nullable<File>,
  ) => {
    if (!settings) return null;
    setUpdating(true);
    setEdit(false);

    const { testMode } = settings;
    const { offlineLabels, ...newSettings } = siteData;

    if (logoImage != null) {
      const { ref } = await uploadImage(newSettings.site, logoImage);
      const siteLogoUrl = await getImageUrl(ref.fullPath);
      newSettings.logo = siteLogoUrl;
    }

    const siteSplashConfigId = testMode ? (settings.testModeSplashConfigId || '') : (settings.splashConfigId || '');
    const newSplashConfigId = testMode ? (siteData.testModeSplashConfigId || '') : (siteData.splashConfigId || '');
    const siteReaderSplashImage = testMode ? (settings.testModeReaderSplashImage || '') : (settings.readerSplashImage || '');
    const newSiteReaderSplashImage = testMode ? (siteData.testModeReaderSplashImage || '') : (siteData.readerSplashImage || '');

    if (readerImage != null) {
      const { ref } = await uploadImage(newSettings.site, readerImage);
      const readerImageUrl = await getImageUrl(ref.fullPath);
      if (newSplashConfigId.length === 0) {
        if (testMode) {
          newSettings.testModeReaderSplashImage = readerImageUrl;
        } else {
          newSettings.readerSplashImage = readerImageUrl;
        }
        const { data: { splashConfigId } } = await apiRequest(
          `sites/${siteData.id}/terminal/configurations/splash-screen`,
          'post',
          {
            storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
            readerImagePath: ref.fullPath,
            type: readerImage.type,
          },
        );
        if (testMode) {
          newSettings.testModeSplashConfigId = splashConfigId;
        } else {
          newSettings.splashConfigId = splashConfigId;
        }
      } else {
        if (testMode) {
          newSettings.testModeReaderSplashImage = readerImageUrl;
        } else {
          newSettings.readerSplashImage = readerImageUrl;
        }
        await apiRequest(
          `sites/${siteData.id}/terminal/configurations/splash-screen`,
          'put',
          {
            splashConfigId: newSplashConfigId,
            storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
            readerImagePath: ref.fullPath,
            type: readerImage.type,
          },
        );
      }
    } else if (siteReaderSplashImage !== newSiteReaderSplashImage) {
      if (siteReaderSplashImage.length > 0) {
        const readerImagePath = decodeURIComponent(
          getReaderImagePathFromUrl(siteReaderSplashImage),
        );
        await apiRequest(
          `sites/${siteData.id}/terminal/configurations/splash-screen`,
          'delete',
          {
            readerImagePath,
            splashConfigId: siteSplashConfigId,
            storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
          },
        );
        if (testMode) {
          delete newSettings.testModeReaderSplashImage;
          delete newSettings.testModeSplashConfigId;
        } else {
          delete newSettings.readerSplashImage;
          delete newSettings.splashConfigId;
        }
      }
    }
    setUpdating(false);
    return dispatch(updateSiteSettings(settings.id || '', newSettings));
  };

  const handleAccountsInfoSubmit = async ({
    country,
    companyName,
    companyAddress,
    accountsEmail,
  }: AccountsInfo & { country: Country }) => {
    if (!settings) return null;
    setUpdating(true);
    await apiRequest('xero/contacts', 'POST', {
      site: settings.site,
      companyName,
      companyAddress,
      accountsEmail,
    });
    const url = await setupStripe(settings, country);
    return window.location.replace(url);
  };

  const handleSetupStripeClick = async () => {
    if (!settings) return null;
    const url = await setupStripe(settings, Country.GB);
    return window.location.replace(url);

    // if (hasStripeAccount) {

    //   const url = await setupStripe(settings, null);
    //   return window.location.replace(url);
    // }
    // setAccountsInfoDialogOpen(true);
    // return null;
  };

  const handleAccountsInfoDialogClose = () => {
    setAccountsInfoDialogOpen(false);
  };

  if (isSettingsLoading || updating) return <Spinner />;

  if (!settings) {
    return (
      <Grid>
        No Settings found for site.
      </Grid>
    );
  }

  const errorMessage = updateSettingsError.length > 0
    ? <Typography variant="body1" color="error">{updateSettingsError}</Typography>
    : null;

  return (
    <Grid>
      <Grid className={classes.mainSection}>
        {settings.testMode && (
          <Alert
            className={classes.alert}
            severity="warning"
          >
            {`${settings.name} is currently in TESTMODE`}
          </Alert>
        )}
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ marginBottom: 10 }}
        >
          <SiteTitle siteData={settings} />
          <Button
            color="primary"
            variant="outlined"
            onClick={handleSetupStripeClick}
            disabled={!user || !hasPermission(RoleRestrictedAction.ConfigureStripe, user)}
            aria-controls={accountsInfoDialogOpen ? 'accounts-form-dialog' : undefined}
            aria-haspopup="true"
            aria-expanded={accountsInfoDialogOpen ? 'true' : undefined}
            endIcon={<LaunchIcon />}
          >
            {hasStripeAccount ? 'Go to Stripe' : 'Setup Stripe'}
          </Button>
          <AccountsInfoDialog
            open={accountsInfoDialogOpen}
            onFormSubmit={handleAccountsInfoSubmit}
            onCloseClick={handleAccountsInfoDialogClose}
          />
        </Grid>
        {edit
          ? <SiteForm siteData={settings} onSave={onSave} onCancel={() => setEdit(false)} />
          : (
            <SiteProfile
              siteSettings={settings}
              user={user}
              onLoading={setUpdating}
            />
          )}
        {errorMessage}
      </Grid>
      {edit ? null : (
        <Drawer anchor="bottom" variant="permanent">
          <Grid
            className={classes.buttons}
            container
            direction="row"
            justify="flex-end"
          >
            <Button
              className={classes.backButton}
              variant="outlined"
              color="primary"
              onClick={() => history.push(AppUrl.Home)}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
              onClick={() => setEdit((value) => !value)}
              disabled={!hasPermission(RoleRestrictedAction.EditSiteSettings, user)}
            >
              Edit Settings
            </Button>
          </Grid>
        </Drawer>
      )}
    </Grid>
  );
};

export default SettingsPage;
