import React,
{
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  makeStyles,
  Box,
  Typography,
  Button,
} from '@material-ui/core';

import createPaymentRequirementsText from '../helpers/createPaymentRequirementsText';
import determineCurrentPayee from '../helpers/determineCurrentPayee';
import apiRequest from '../../../helpers/api-request';

import LabelPaymentInfoForm from './LabelPaymentInfoForm';

import Label from '../../../types/Label';
import Payee from '../../../types/Payee';
import SiteData from '../../../types/SiteData';
import isStripeRequestSuccessResponse from '../helpers/isStripeRequestSuccessReponse';
import User from '../../../types/User';
import PaymentFormValues from '../../../types/PaymentFormValues';

interface Props {
  label: Label,
  onLabelEditSave: (updatedLabel: Label) => Promise<void>,
  siteId: SiteData['id'],
  authenticatedUser: User,
  settings: SiteData,
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  secondaryText: {
    margin: '2.5% 0 0 0',
  },
  italicSecondaryText: {
    margin: '2.5% 0 0 2.5%',
    fontStyle: 'italic',
  },
  optionsButton: {
    margin: '2.5% 0 0 0',
    alignSelf: 'flex-end',
  },
});

const PaymentInformation = (
  {
    label,
    onLabelEditSave,
    siteId,
    authenticatedUser,
    settings,
  }: Props,
) => {
  const classes = useStyles();

  const [optionsDialogOpen, setOptionsDialogOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dialogError, setDialogError] = useState<boolean>(false);
  const currentPayee: Payee = useMemo(() => determineCurrentPayee(label), [label]);
  const siteUsesTipjar = settings.useTipjar || false;

  const [paymentFormValues, setPaymentFormValues] = useState<PaymentFormValues>({
    commissionPercent: label.commissionPercent ? String(label.commissionPercent * 100) : '0',
    vatRegistered: label.vatRegistered || false,
    vatNumber: label.vatNumber || 'GB',
    companyName: label.companyName || '',
    tipjarShortcutCode: label.tipjarShortcutCode || '',
    stripeAccountId: label.stripeAccountId || '',
  });

  const onPaymentFormValuesChange = useCallback(
    (newValue: PaymentFormValues) => setPaymentFormValues(newValue),
    [setPaymentFormValues],
  );

  const handleOptionsClick = useCallback(
    () => setOptionsDialogOpen(true),
    [setOptionsDialogOpen],
  );

  const onOptionsDialogCancelClick = useCallback(
    () => {
      setOptionsDialogOpen(false);
      setPaymentFormValues({
        commissionPercent: label.commissionPercent ? String(label.commissionPercent * 100) : '0',
        vatRegistered: label.vatRegistered || false,
        vatNumber: label.vatNumber || 'GB',
        companyName: label.companyName || '',
        tipjarShortcutCode: label.tipjarShortcutCode || '',
        stripeAccountId: label.stripeAccountId || '',
      });
    },
    [setOptionsDialogOpen, label],
  );

  const onPaymentOptionsSaveClick = async () => {
    setLoading(true);
    const {
      vatRegistered,
      vatNumber,
      companyName,
      commissionPercent,
      tipjarShortcutCode,
    } = paymentFormValues;
    const updatedLabel: Label = {
      ...label,
      vatRegistered,
      vatNumber,
      companyName,
      commissionPercent: Number(commissionPercent) / 100,
      tipjarShortcutCode,
    };
    if (updatedLabel.vatNumber === 'GB' || updatedLabel.vatNumber === '') {
      delete updatedLabel.vatNumber;
    }
    if (updatedLabel.companyName === '') {
      delete updatedLabel.companyName;
    }
    try {
      await onLabelEditSave(updatedLabel);
      setOptionsDialogOpen(false);
    } catch {
      setDialogError(true);
    } finally {
      setLoading(false);
    }
  };

  const onSetupStripeButtonClick = useCallback(
    async () => {
      setLoading(true);
      try {
        if (!siteId) throw new Error('Missing siteId');
        const { data } = await apiRequest(
          'label-stripe-setup',
          'POST',
          {
            siteId,
            label,
            country: settings?.country || 'GB',
          },
        );
        if (!isStripeRequestSuccessResponse(data)) {
          throw new Error('Unexpected error response recieved from /label-stripe-setup');
        }
        const { url } = data;
        window.location.replace(url);
      } catch {
        setDialogError(true);
      } finally {
        setLoading(false);
      }
    },
    [label],
  );

  return (
    <Box className={classes.container}>
      {currentPayee === Payee.Label
        ? (
          <Box className={classes.container}>
            <Typography>
              Payments for products with this Reporting Label will be paid to the Stripe account
              associated with this Reporting Label.
            </Typography>
          </Box>
        )
        : (
          <Box className={classes.container}>
            <Typography>
              Payments for products with this Reporting Label will be paid to the Stripe account
              associated with the Site.
            </Typography>
            <Typography className={classes.secondaryText} variant="body2" color="textSecondary">
              The following options will need to configured before payments can be made to a
              Stripe account associated with this Reporting Label.
            </Typography>
            <Typography className={classes.italicSecondaryText} variant="body2" color="textSecondary">
              {createPaymentRequirementsText(label)}
            </Typography>
          </Box>
        )}
      <Button
        className={classes.optionsButton}
        size="small"
        variant="outlined"
        color="primary"
        onClick={handleOptionsClick}
      >
        Payment Processing Options
      </Button>
      <LabelPaymentInfoForm
        open={optionsDialogOpen}
        loading={loading}
        error={dialogError}
        user={authenticatedUser}
        onCancelClick={onOptionsDialogCancelClick}
        paymentFormValues={paymentFormValues}
        onPaymentFormValuesChange={onPaymentFormValuesChange}
        onPaymentOptionsSaveClick={onPaymentOptionsSaveClick}
        onSetupStripeButtonClick={onSetupStripeButtonClick}
        siteUsesTipjar={siteUsesTipjar}
        storedLabelSettings={label}
      />
    </Box>
  );
};

export default PaymentInformation;
