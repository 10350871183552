import firebase from 'firebase/app';
import moment from 'moment';

import {
  GET_SLOT_TIMES_START,
  GET_SLOT_TIMES_SUCCESS,
  GET_SLOT_TIMES_ERROR,
} from '../constants/actions';

export default (site, day) => (dispatch) => {
  dispatch({
    type: GET_SLOT_TIMES_START,
  });
  firebase
    .firestore()
    .collection('slottimes')
    .where('site', '==', site)
    .where('slotCapacity', '>=', 1)
    .onSnapshot((snapshots) => {
      const slotTimes = [];
      snapshots.forEach((snapshot) => {
        slotTimes.push({ ...snapshot.data(), id: snapshot.id });
      });
      const filteredSlotTimes = day ? slotTimes.filter((time) => time.day === day) : slotTimes;
      const sortedSlotTimes = filteredSlotTimes.sort((a, b) => moment(`${a.day} ${a.time}`) - moment(`${b.day} ${b.time}`));
      const mappedDays = slotTimes.map((slot) => slot.day);
      const slotDays = slotTimes.filter((item, pos) => mappedDays.indexOf(item.day) === pos);
      dispatch({
        type: GET_SLOT_TIMES_SUCCESS,
        slotDays,
        slotTimes: sortedSlotTimes,
      });
    }, () => {
      dispatch({
        type: GET_SLOT_TIMES_ERROR,
        message: 'Failed to fetch slot times.',
      });
    });
};
