import { Map } from 'immutable';
import {
  GET_SETTINGS_START,
  GET_SETTINGS_SUCCESS,
  UPDATE_SITE_SETTINGS,
  UPDATE_SITE_SETTINGS_ERROR,
  UPDATING_SITE_SETTINGS,
  GET_SETTINGS_ERROR,
} from '../constants/actions';

import ReduxAction from '../types/ReduxAction';
import ReduxState from '../types/ReduxState';

const initialStateMap: ReduxState['settings'] = Map();

const initialState = initialStateMap
  .set('IS_SETTINGS_LOADING', false)
  .set('IS_SITE_ACTIVE', false)
  .set('SETTINGS', null)
  .set('SITE_SETTINGS_ERROR', '')
  .set('SETTINGS_LOADING_ERROR', '');

export default (
  state: ReduxState['settings'] = initialState,
  action: ReduxAction,
) => {
  switch (action.type) {
    case GET_SETTINGS_START:
      return state
        .set('IS_SETTINGS_LOADING', true)
        .set('IS_SITE_ACTIVE', false)
        .set('SETTINGS', null)
        .set('SETTINGS_LOADING_ERROR', '');
    case GET_SETTINGS_SUCCESS:
      return state
        .set('IS_SETTINGS_LOADING', false)
        .set('IS_SITE_ACTIVE', action.isSiteActive)
        .set('SETTINGS', action.settings);
    case UPDATING_SITE_SETTINGS:
      return state
        .set('IS_SETTINGS_LOADING', true)
        .set('IS_SITE_ACTIVE', false)
        .set('SETTINGS', null)
        .set('SETTINGS_LOADING_ERROR', '');
    case UPDATE_SITE_SETTINGS:
      return state
        .set('IS_SETTINGS_LOADING', false)
        .set('SETTINGS', action.settings);
    case UPDATE_SITE_SETTINGS_ERROR:
      return state
        .set('IS_SETTINGS_LOADING', false)
        .set('SITE_SETTINGS_ERROR', action.message);
    case GET_SETTINGS_ERROR:
      return state
        .set('IS_SETTINGS_LOADING', false)
        .set('SETTINGS_LOADING_ERROR', action.message);
    default:
      return state;
  }
};
