import isPaymentMethod from './isPaymentMethod';

import PaymentIntent from '../../../types/PaymentIntent';
import OrderPaymentType from '../../../types/OrderPaymentType';
import Nullable from '../../../types/Nullable';

const getPaymentMethod = (
  paymentIntent?: PaymentIntent,
): Nullable<OrderPaymentType> => {
  if (paymentIntent == null) return null;
  if (paymentIntent.payment_method_details == null) return null;

  if (
    paymentIntent.payment_method_details.card != null
    && paymentIntent.payment_method_details.card.wallet
  ) {
    return isPaymentMethod(paymentIntent.payment_method_details.card.wallet.type)
      ? paymentIntent.payment_method_details.card.wallet.type
      : null;
  }

  return isPaymentMethod(paymentIntent.payment_method_details.type)
    ? paymentIntent.payment_method_details.type
    : null;
};

export default getPaymentMethod;
