import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

const useUrlParams = (params: string[]) => {
  const { location, replace } = useHistory();

  const urlSearchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location],
  );

  const paramValues = useMemo(
    () => params.map((param) => urlSearchParams.get(param)),
    [params, urlSearchParams],
  );

  const paramSetters = useMemo(
    () => params.map((param) => (newValue: string) => {
      urlSearchParams.set(param, newValue);
      replace(`${location.pathname}?${urlSearchParams.toString()}`);
    }),
    [params, urlSearchParams, location, replace],
  );

  return {
    values: paramValues,
    setters: paramSetters,
  };
};

export default useUrlParams;
