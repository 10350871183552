import OrderSource from '../types/OrderSource';

const getOrderSourceName = (orderSource: OrderSource) => {
  switch (orderSource) {
    case OrderSource.OrderPay:
      return 'Order & Pay App';
    case OrderSource.POS:
      return 'POS';
    case OrderSource.Kiosk:
      return 'Kiosk';
    default:
      return 'Unknown';
  }
};

export default getOrderSourceName;
