import React, { useMemo } from 'react';
import {
  LineChart,
  Line, XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import {
  Paper,
  Typography,
  makeStyles,
  Box,
} from '@material-ui/core';

import getColour from '../helpers/getColour';

import TimelineData from '../types/TimelineData';

type Props = {
  data: TimelineData[],
  dateRangeText: string,
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(4),
  },
  titleBox: {
    display: 'inline',
  },
}));

const SalesReportTimeline = ({ data, dateRangeText }: Props) => {
  const classes = useStyles();
  const mappedData = useMemo(() => data.map((d) => ({ ...d.data, date: d.date })), [data]);
  const labels = useMemo(() => {
    if (mappedData == null) return [];
    if (mappedData.length === 0) return [];
    return Object.keys(mappedData[0]).filter((d) => d !== 'date');
  }, [mappedData]);

  return (
    <Paper className={classes.container}>
      <Typography>
        <Box className={classes.titleBox} fontWeight="fontWeightBold">Total Orders</Box>
        &nbsp;–&nbsp;
        {dateRangeText}
      </Typography>
      <ResponsiveContainer width="99%" height={300}>
        <LineChart
          data={mappedData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip labelStyle={{ color: '#666' }} />
          <Legend
            verticalAlign="top"
            align="right"
            height={40}
          />
          {labels.map((label, index) => (
            <Line
              key={label}
              dot={false}
              type="monotone"
              dataKey={label}
              stroke={getColour(index)}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default SalesReportTimeline;
