/* eslint-disable react/prop-types */
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase';

import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Switch,
  TextField,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { Delete, Edit, Language } from '@material-ui/icons';
import { DataGrid, GridRowData } from '@material-ui/data-grid';

import ErrorDialog from '../../../components/ErrorDialog/ErrorDialog';
import MultipleSelect from '../../../components/multiple-select/multiple-select';
import CustomChip from '../../../components/CustomChip/CustomChip';
import TableHeader from './TableHeader';
import ReaderButton from './ReaderButton';
import KioskUrlDetailsDialog from './KioskUrlDetailsDialog';

import isKiosk from '../../../helpers/isKiosk';
import apiRequest from '../../../helpers/api-request';

import Kiosk from '../../../types/Kiosk';
import Nullable from '../../../types/Nullable';
import SiteData from '../../../types/SiteData';
import Label from '../../../types/Label';

const useStyles = makeStyles((theme) => ({
  dialogActions: { margin: theme.spacing(3) },
  switch: { margin: theme.spacing(3, 0, 3) },
  cloudPrinterTextField: { margin: theme.spacing(3, 0, 0) },
  readerLabelField: { marginBottom: theme.spacing(3) },
  chipBox: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  remainingChips: { marginLeft: theme.spacing(1) },
}));

type Props = {
  siteData: Nullable<SiteData>
};

const KiosksTable = ({ siteData }: Props) => {
  const classes = useStyles();

  const history = useHistory();
  const theme = useTheme();

  const [kiosks, setKiosks] = useState<Kiosk[]>([]);
  const [error, setError] = useState<Nullable<Error>>(null);
  const [currentKioskUrlDetails, setCurrentKioskUrlDetails] = useState<Nullable<Kiosk>>(null);

  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
  const [newKioskName, setNewKioskName] = useState<string>('');
  const [addDialogLoading, setAddDialogLoading] = useState<boolean>(false);

  const [currentKioskId, setCurrentKioskId] = useState<Nullable<string>>(null);
  const [currentKioskName, setCurrentKioskName] = useState<string>('');
  const [currentKioskActive, setCurrentKioskActive] = useState<boolean>(false);
  const [currentKioskLabels, setCurrentKioskLabels] = useState<Array<Label['id']>>([]);
  const [currentKioskCloudPrinter, setCurrentKioskCloudPrinter] = useState<string>('');
  const [currentKioskPrinterEnabled, setCurrentKioskPrinterEnabled] = useState<boolean>(false);
  const [editDialogLoading, setEditDialogLoading] = useState<boolean>(false);

  const [kioskToConnect, setKioskToConnect] = useState<Nullable<string>>(null);
  const [readerLabel, setReaderLabel] = useState<string>('');
  const [readerPairingCode, setReaderPairingCode] = useState<string>('');
  const [connectReaderDialogLoading, setConnectReaderDialogLoading] = useState<boolean>(false);

  const [kioskToDelete, setKioskToDelete] = useState<Nullable<Kiosk>>(null);
  const [deleteDialogLoading, setDeleteDialogLoading] = useState<boolean>(false);

  const [kioskToDisconnect, setKioskToDisconnect] = useState<Nullable<string>>(null);
  const [disconnectDialogLoading, setDisconnectDialogLoading] = useState<boolean>(false);

  useEffect(() => {
    if (siteData == null) return undefined;
    const unsub = firebase
      .firestore()
      .collection('sites')
      .doc(siteData.id)
      .collection('kiosks')
      .onSnapshot((snapshot) => {
        const results: Kiosk[] = [];
        snapshot.docs.forEach((doc) => {
          const data = { id: doc.id, ...doc.data() };
          if (isKiosk(data)) results.push(data);
        });
        setKiosks(results);
      }, (err) => {
        setError(err);
      });
    return () => unsub();
  }, [siteData?.id]);

  const reportingLabelOptions = useMemo(() => {
    if (
      siteData != null
      && siteData.reportingLabels != null
      && siteData.reportingLabels.length > 0
    ) {
      return siteData.reportingLabels.map((label) => (
        { label: label.text, value: label.id }
      ));
    }
    return [];
  }, [siteData]);

  const siteLabels = useMemo(() => {
    if (
      siteData != null
      && siteData.reportingLabels != null
    ) return siteData.reportingLabels;
    return [];
  }, []);

  const handleAddClick = () => {
    setAddDialogOpen(true);
  };

  const handleAddDialogCancelClick = () => {
    setAddDialogOpen(false);
    setNewKioskName('');
  };

  const handleNewKioskNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;
    setNewKioskName(value);
  };

  const handleAddDialogSaveClick = async () => {
    try {
      setAddDialogLoading(true);
      if (siteData == null) throw new Error('Missing site settings data.');
      await firebase
        .firestore()
        .collection('sites')
        .doc(siteData.id)
        .collection('kiosks')
        .doc()
        .set({
          name: newKioskName,
          isActive: false,
          terminalLocation: null,
          cloudPrinter: null,
        });
    } catch (err) {
      setError(err as Error);
    } finally {
      setAddDialogOpen(false);
      setNewKioskName('');
      setAddDialogLoading(false);
    }
  };

  const editHandlerFactory = (row: GridRowData) => () => {
    const {
      id,
      isActive,
      name,
      reportingLabels,
      cloudPrinter,
      isPrinterEnabled,
    } = row;
    setCurrentKioskActive(isActive);
    setCurrentKioskName(name);
    setCurrentKioskId(id);
    setCurrentKioskLabels(() => {
      if (reportingLabels != null) return reportingLabels;
      return [];
    });
    setCurrentKioskCloudPrinter(cloudPrinter || '');
    setCurrentKioskPrinterEnabled(isPrinterEnabled || false);
  };

  const handleKioskNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;
    setCurrentKioskName(value);
  };

  const handleKioskActiveSwitchChange = () => {
    setCurrentKioskActive((currentValue) => !currentValue);
  };

  const handleLabelsChange = (labels: Label['id'][]) => {
    setCurrentKioskLabels(labels);
  };

  const handleKioskCloudPrinterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;
    setCurrentKioskCloudPrinter(value);
  };

  const handlePrinterActiveSwitchChange = () => {
    setCurrentKioskPrinterEnabled((currentValue) => !currentValue);
  };

  const handleEditDialogSaveClick = () => {
    try {
      setEditDialogLoading(true);
      if (siteData == null) throw new Error('Missing site settings data.');
      if (currentKioskId == null) throw new Error('No kiosk selected.');
      const cloudPrinter = currentKioskCloudPrinter.length > 0
        ? currentKioskCloudPrinter
        : null;
      firebase
        .firestore()
        .collection('sites')
        .doc(siteData.id)
        .collection('kiosks')
        .doc(currentKioskId)
        .update({
          name: currentKioskName,
          isActive: currentKioskActive,
          reportingLabels: currentKioskLabels,
          cloudPrinter,
          isPrinterEnabled: currentKioskPrinterEnabled,
        });
    } catch (err) {
      setError(err as Error);
    } finally {
      setCurrentKioskId(null);
      setCurrentKioskName('');
      setCurrentKioskActive(false);
      setCurrentKioskCloudPrinter('');
      setCurrentKioskPrinterEnabled(false);
      setEditDialogLoading(false);
    }
  };

  const handleEditDialogCancelClick = () => {
    setCurrentKioskId(null);
    setCurrentKioskActive(false);
    setCurrentKioskName('');
    setCurrentKioskCloudPrinter('');
    setCurrentKioskPrinterEnabled(false);
  };

  const connectReaderFactory = (kioskId: string) => () => {
    setKioskToConnect(kioskId);
  };

  const handleReaderLabelChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;
    setReaderLabel(value);
  };

  const handleReaderPairingCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;
    setReaderPairingCode(value);
  };

  const handleConnectReaderSaveClick = async () => {
    try {
      setConnectReaderDialogLoading(true);
      if (siteData == null) throw new Error('Missing site settings data.');
      await apiRequest(
        `sites/${siteData.id}/kiosk/${kioskToConnect}/reader`,
        'POST',
        { label: readerLabel, pairingCode: readerPairingCode },
      );
    } catch (err) {
      setError(err as Error);
    } finally {
      setConnectReaderDialogLoading(false);
      setKioskToConnect(null);
      setReaderLabel('');
      setReaderPairingCode('');
    }
  };

  const handleConnectReaderCancelClick = () => {
    setKioskToConnect(null);
    setReaderLabel('');
    setReaderPairingCode('');
  };

  const deleteHandlerFactory = (row: GridRowData) => () => {
    setKioskToDelete({
      id: row.id,
      name: row.name,
      isActive: row.isActive,
      terminalLocation: row.terminalLocation,
      reportingLabels: row.reportingLabels,
      cloudPrinter: row.cloudPrinter,
    });
  };

  const kioskUrlDetailsHandlerFactory = (row: GridRowData) => () => {
    setCurrentKioskUrlDetails({
      id: row.id,
      name: row.name,
      isActive: row.isActive,
      terminalLocation: row.terminalLocation,
      reportingLabels: row.reportingLabels,
      cloudPrinter: row.cloudPrinter,
    });
  };

  const handleDeleteConfirmClick = async () => {
    try {
      setDeleteDialogLoading(true);
      if (siteData == null) throw Error('Missing site settings data.');
      if (kioskToDelete == null) throw new Error('No kiosk selected.');
      if (kioskToDelete.terminalLocation != null) {
        throw new Error('Cannot delete kiosk with connected reader.');
      }
      await firebase
        .firestore()
        .collection('sites')
        .doc(siteData.id)
        .collection('kiosks')
        .doc(kioskToDelete.id)
        .delete();
    } catch (err) {
      setError(err as Error);
    } finally {
      setDeleteDialogLoading(false);
      setKioskToDelete(null);
    }
  };

  const handleDeleteCancelClick = () => {
    setKioskToDelete(null);
  };

  const disconnectReaderFactory = (kioskId: string) => () => {
    setKioskToDisconnect(kioskId);
  };

  const handleDisconnectConfirmClick = async () => {
    try {
      setDisconnectDialogLoading(true);
      if (siteData == null) throw new Error('Missing site settings data.');
      await apiRequest(
        `sites/${siteData.id}/kiosk/${kioskToDisconnect}/reader`,
        'DELETE',
      );
    } catch (err) {
      setError(err as Error);
    } finally {
      setKioskToDisconnect(null);
    }
  };

  const handleDisconnectCancelClick = () => {
    setKioskToDisconnect(null);
  };

  const handleErrorOkClick = () => {
    history.go(0);
  };

  return (
    <>
      <DataGrid
        components={{ Header: TableHeader }}
        componentsProps={{ header: { onAddClick: handleAddClick } }}
        rows={kiosks}
        columns={[
          { field: 'name', headerName: 'Name', flex: 1 },
          {
            field: 'isActive',
            headerName: 'Status',
            flex: 0.5,
            renderCell: (params) => (
              <Chip
                label={params.row.isActive ? 'Active' : 'Offline'}
              />
            ),
          },
          {
            field: 'reportingLabels',
            headerName: 'Reporting labels',
            flex: 1.5,
            renderCell: (params) => {
              if (params.row.reportingLabels != null) {
                const kioskLabels = [...params.row.reportingLabels];
                if (kioskLabels.length > 0) {
                  const matchedLabel = siteLabels
                    .find(({ id }) => kioskLabels[0] === id);
                  return (
                    <Box className={classes.chipBox}>
                      <CustomChip
                        label={matchedLabel != null ? matchedLabel.text : ''}
                        color={theme.palette.primary.main}
                      />
                      {kioskLabels.length > 1 && (
                        <Typography
                          className={classes.remainingChips}
                          color="primary"
                        >
                          {`+${kioskLabels.length - 1}... `}
                        </Typography>
                      )}
                    </Box>
                  );
                }
              }
              return (
                <CustomChip
                  label="All labels"
                  color={theme.palette.primary.main}
                />
              );
            },
          },
          {
            field: 'terminalLocation',
            headerName: 'Reader',
            flex: 1,
            renderCell: (params) => (
              <ReaderButton
                siteId={siteData?.id || null}
                locationId={params.row.terminalLocation}
                onConnectClick={connectReaderFactory(params.row.id)}
                onDisconnectClick={disconnectReaderFactory(params.row.id)}
              />
            ),
          },
          {
            field: 'other actions',
            headerName: 'Actions',
            flex: 0.75,
            renderCell: (params) => (
              <>
                <IconButton onClick={kioskUrlDetailsHandlerFactory(params.row)}>
                  <Language />
                </IconButton>
                <IconButton onClick={editHandlerFactory(params.row)}>
                  <Edit />
                </IconButton>
                <IconButton onClick={deleteHandlerFactory(params.row)}>
                  <Delete />
                </IconButton>
              </>
            ),
          },
        ]}
        autoHeight
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
      <Dialog maxWidth="sm" fullWidth open={addDialogOpen}>
        {addDialogLoading && <LinearProgress />}
        <DialogTitle>Add Kiosk</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            label="Name"
            value={newKioskName}
            onChange={handleNewKioskNameChange}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddDialogSaveClick}
            disabled={addDialogLoading || newKioskName.length === 0}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={handleAddDialogCancelClick}
            disabled={addDialogLoading}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog maxWidth="sm" fullWidth open={currentKioskId != null}>
        {editDialogLoading && <LinearProgress />}
        <DialogTitle>Edit Kiosk</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            label="Name"
            value={currentKioskName}
            onChange={handleKioskNameChange}
          />
          <FormControlLabel
            className={classes.switch}
            label="Active"
            control={(
              <Switch
                checked={currentKioskActive}
                onChange={handleKioskActiveSwitchChange}
              />
            )}
          />
          <MultipleSelect
            label="ReportingLabels"
            defaultValues={currentKioskLabels}
            options={reportingLabelOptions}
            onChange={handleLabelsChange}
          />
          <TextField
            className={classes.cloudPrinterTextField}
            fullWidth
            variant="outlined"
            label="Cloud Printer Serial Number"
            value={currentKioskCloudPrinter}
            onChange={handleKioskCloudPrinterChange}
          />
          <FormControlLabel
            className={classes.switch}
            label="Print Receipts"
            disabled={currentKioskCloudPrinter.length === 0}
            control={(
              <Switch
                checked={currentKioskPrinterEnabled}
                onChange={handlePrinterActiveSwitchChange}
              />
            )}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleEditDialogSaveClick}
            disabled={editDialogLoading || currentKioskName.length === 0}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={handleEditDialogCancelClick}
            disabled={editDialogLoading}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog maxWidth="sm" fullWidth open={kioskToConnect != null}>
        {connectReaderDialogLoading && <LinearProgress />}
        <DialogTitle>Connect Reader</DialogTitle>
        <DialogContent>
          <TextField
            className={classes.readerLabelField}
            fullWidth
            variant="outlined"
            label="Label"
            value={readerLabel}
            onChange={handleReaderLabelChange}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Pairing Code"
            value={readerPairingCode}
            onChange={handleReaderPairingCodeChange}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConnectReaderSaveClick}
            disabled={(
              connectReaderDialogLoading
              || readerLabel.length === 0
              || readerPairingCode.length === 0
            )}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={handleConnectReaderCancelClick}
            disabled={connectReaderDialogLoading}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog maxWidth="sm" fullWidth open={kioskToDelete != null}>
        {deleteDialogLoading && <LinearProgress />}
        <DialogTitle>Delete Kiosk</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Any connected Readers must be disconnected before a Kiosk can be deleted.
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDeleteConfirmClick}
            disabled={deleteDialogLoading || kioskToDelete?.terminalLocation != null}
          >
            Delete
          </Button>
          <Button
            variant="outlined"
            onClick={handleDeleteCancelClick}
            disabled={deleteDialogLoading}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog maxWidth="sm" fullWidth open={kioskToDisconnect != null}>
        {disconnectDialogLoading && <LinearProgress />}
        <DialogTitle>Disconnect Reader</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Click &apos;Confirm&apos; to disconnect the connected reader from this Kiosk.
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDisconnectConfirmClick}
            disabled={disconnectDialogLoading}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            onClick={handleDisconnectCancelClick}
            disabled={disconnectDialogLoading}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <KioskUrlDetailsDialog
        setSelectedKiosk={setCurrentKioskUrlDetails}
        selectedKiosk={currentKioskUrlDetails}
        siteUrlEndpoint={siteData != null && siteData.urlEndpoint != null ? siteData.urlEndpoint : ''}
      />
      <ErrorDialog
        open={error != null}
        onOkButtonClick={handleErrorOkClick}
        message={error?.message}
      />
    </>
  );
};

export default KiosksTable;
