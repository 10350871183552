import React, { useCallback, useMemo, useState } from 'react';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';

import OutlinedButton from '../../outlined-button/outlined-button';

import convertCsvData from '../helpers/convertCsvData';

import DiscountCode from '../../../types/DiscountCode';
import Nullable from '../../../types/Nullable';

type Props = {
  existingCodes: DiscountCode['code'][]
  open: boolean
  onClose: () => void
  onSave: (data: DiscountCode[]) => void
};

const useStyles = makeStyles(() => ({
  dialogContent: { padding: 10 },
  uploadSection: { marginBottom: 10 },
  input: { display: 'none' },
}));

const UploadCodesDialog = ({
  existingCodes, open, onClose, onSave,
}: Props) => {
  const [fileName, setName] = useState<Nullable<string>>(null);
  const [fileData, setFileData] = useState<Nullable<string | ArrayBuffer>>(null);
  const classes = useStyles();
  const fileReader = new FileReader();

  const handleOnChange = useCallback((e) => {
    const file = e.target.files[0];

    setName(null);
    setFileData(null);

    if (file) {
      fileReader.onload = (event) => {
        if (event && event.target) {
          const csvOutput = event.target.result;

          setName(file.name || '');
          setFileData(csvOutput);
        }
      };

      fileReader.readAsText(file);
    }
  }, []);

  const convertedCsv = useMemo(() => convertCsvData(fileData, existingCodes), [fileData]);

  const handleSave = useCallback(() => onSave(convertedCsv?.data || []), [convertedCsv]);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="upload-title" fullWidth>
      <DialogTitle id="upload-title">Upload Discount Codes</DialogTitle>
      <DialogContent dividers>
        <Grid
          className={classes.dialogContent}
          direction="column"
          justify="center"
          alignItems="center"
          container
        >
          <DialogContentText>Please Select file:</DialogContentText>
          <div className={classes.uploadSection}>
            <label htmlFor="contained-button-file">
              <input
                id="contained-button-file"
                className={classes.input}
                type="file"
                accept=".csv"
                onChange={handleOnChange}
              />
              <Button variant="outlined" color="secondary" component="span">
                Select File
              </Button>
            </label>
          </div>
          {fileName && <DialogContentText>{`File to upload: ${fileName}`}</DialogContentText>}
          {convertedCsv?.numberOfCodes
            ? <DialogContentText>{`Number of discount codes to upload: ${convertedCsv.numberOfCodes}`}</DialogContentText>
            : null}
          {convertedCsv?.numberOfDuplicates
            ? <DialogContentText>{`Number of duplicated discount codes to ignore: ${convertedCsv.numberOfDuplicates}`}</DialogContentText>
            : null}
          {convertedCsv?.validationError
            ? <Typography variant="body1" color="error">{convertedCsv.validationError}</Typography>
            : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <OutlinedButton
          label="Cancel"
          color="secondary"
          onClick={onClose}
        />
        <OutlinedButton label="Upload" color="primary" onClick={handleSave} disabled={!convertedCsv?.isValid} />
      </DialogActions>
    </Dialog>
  );
};

export default UploadCodesDialog;
