import React, { useCallback, useMemo, useState } from 'react';

import {
  Button, Menu, IconButton, Grid, Typography, makeStyles,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import LaunchIcon from '@material-ui/icons/Launch';

import Nullable from '../../../types/Nullable';

const useStyles = makeStyles(() => ({
  menuContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 20,
  },
}));

const HelpMenu = () => {
  const [anchorEl, setAnchorEl] = useState<Nullable<EventTarget & Element>>(null);
  const classes = useStyles();

  const handleOpen = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const isOpen = useMemo(() => anchorEl != null, [anchorEl]);

  return (
    <>
      <IconButton
        aria-label="alert-menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpen}
        color="inherit"
      >
        <HelpIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isOpen}
        onClose={handleClose}
      >
        <Grid className={classes.menuContent}>
          <Typography variant="subtitle1" gutterBottom>
            How can we help?
          </Typography>
          <Button
            color="primary"
            variant="text"
            size="small"
            endIcon={<LaunchIcon />}
          >
            Visit Help Centre
          </Button>
        </Grid>
      </Menu>
    </>
  );
};

export default HelpMenu;
