import request from '../../../helpers/request';
import getQueryString from './getQueryString';

import PaymentMethodsReportData from '../types/PaymentMethodsReportData';
import Options from '../types/Options';

const getPaymentMethodsReport = async (
  site: string, options: Options, userId?: string, reportingLabels?: string[],
): Promise<PaymentMethodsReportData> => {
  const endpoint = getQueryString('paymentMethodsReport', site, options, userId, undefined, reportingLabels);

  const result = await request(endpoint, 'GET');
  return result;
};

export default getPaymentMethodsReport;
