import firebase from 'firebase/app';
import {
  GET_SETTINGS_START,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_ERROR,
} from '../constants/actions';

import isSiteData from '../helpers/isSiteData';

import Dispatch from '../types/Dispatch';
import Nullable from '../types/Nullable';
import SiteData from '../types/SiteData';

export default (site: string) => (dispatch: Dispatch) => {
  dispatch({
    type: GET_SETTINGS_START,
  });
  firebase
    .firestore()
    .collection('sites')
    .where('site', '==', site)
    .get()
    .then((snapshots) => {
      const settings: Nullable<SiteData> = null;
      if (snapshots.size === 0) {
        dispatch({
          type: GET_SETTINGS_SUCCESS,
          isSiteActive: false,
          siteSlotTimes: [],
          settings,
        });
      } else {
        const document = snapshots.docs[0];
        const data = { ...document.data(), id: document.id };

        if (!isSiteData(data)) {
          dispatch({
            type: GET_SETTINGS_SUCCESS,
            isSiteActive: false,
            siteSlotTimes: [],
            settings,
          });
        } else {
          dispatch({
            type: GET_SETTINGS_SUCCESS,
            isSiteActive: data.active,
            settings: data,
          });
        }
      }
    })
    .catch(() => {
      dispatch({
        type: GET_SETTINGS_ERROR,
        message: 'Failed to fetch settings.',
      });
    });
};
