import { Map } from 'immutable';

import {
  GET_ORDER_DATA_START,
  GET_ORDER_DATA_SUCCESS,
  GET_ORDER_DATA_ERROR,
} from '../constants/actions';

import ReduxAction from '../types/ReduxAction';
import ReduxState from '../types/ReduxState';

const initialStateMap: ReduxState['orderData'] = Map();

const initialState = initialStateMap
  .set('IS_ORDER_DATA_LOADING', false)
  .set('ORDER_DATA', null)
  .set('ORDER_DATA_LOADING_ERROR', '');

const orderData = (
  state: ReduxState['orderData'] = initialState,
  action: ReduxAction,
) => {
  switch (action.type) {
    case GET_ORDER_DATA_START:
      return state
        .set('IS_ORDER_DATA_LOADING', true)
        .set('ORDER_DATA', null)
        .set('ORDER_DATA_LOADING_ERROR', '');
    case GET_ORDER_DATA_SUCCESS:
      return state
        .set('IS_ORDER_DATA_LOADING', false)
        .set('ORDER_DATA', action.orderData);
    case GET_ORDER_DATA_ERROR:
      return state
        .set('IS_ORDER_DATA_LOADING', false)
        .set('ORDER_DATA_LOADING_ERROR', action.message);
    default:
      return state;
  }
};

export default orderData;
