import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Breadcrumbs, Grid, Link, makeStyles, Typography,
} from '@material-ui/core';

import capitalize from '../../helpers/capitalize';

type Props = {
  url: string
};

const useStyles = makeStyles(() => ({
  pageLocation: { marginBottom: 10 },
}));

const PageLocation = ({ url }: Props) => {
  const classes = useStyles();
  const pages = url.split('/').filter((d) => d.length > 0);

  if (pages.length === 0) return null;

  const activePage = pages[pages.length - 1];
  pages.pop();

  return (
    <Grid className={classes.pageLocation}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          color="inherit"
          component={RouterLink}
          to="/"
        >
          Home
        </Link>
        {
          pages.map((page) => {
            const label = capitalize(page.replace(/-/g, ' '));

            return (
              <Link
                key={page}
                color="inherit"
                component={RouterLink}
                to={`${url.substring(0, url.indexOf(page))}${page}`}
              >
                {label}
              </Link>
            );
          })
        }
        <Typography color="textPrimary">{activePage ? capitalize(activePage.replace(/-/g, ' ')) : null}</Typography>
      </Breadcrumbs>
    </Grid>
  );
};

export default PageLocation;
