import request from '../../../helpers/request';
import getQueryString from './getQueryString';

import Query from '../../../types/Query';
import UnpaidOrdersResult from '../types/UnpaidOrdersResult';
import Nullable from '../../../types/Nullable';

const getUnpaidOrders = async (
  last4: string, site?: Nullable<string>, query?: Query,
): Promise<UnpaidOrdersResult> => {
  const endpoint = getQueryString(last4, site, query);

  const result = await request(endpoint, 'GET');
  return {
    data: result.data,
    page: result.page,
    totalCount: result.totalCount,
  };
};

export default getUnpaidOrders;
