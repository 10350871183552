import React, { useMemo } from 'react';
import {
  FormControl,
  Select,
  Typography,
  InputLabel,
  Button,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  Box,
  Switch,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { Add, CheckCircle, ExpandMore } from '@material-ui/icons';

import capitalize from '../../../helpers/capitalize';

import Label from '../../../types/Label';
import InvolvesKitchen from '../../../types/InvolvesKitchen';
import Status from '../../../types/Status';

const useStyles = makeStyles((theme) => ({
  accordion: { marginBottom: theme.spacing(1) },
  button: {
    margin: theme.spacing(0.25, 1, 0.25, 0),
  },
  panelContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  labelsSelector: {
    minWidth: theme.spacing(40),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  statusSelector: {
    marginTop: theme.spacing(2),
  },
}));

interface Props {
  outstandingOrdersCount: number,
  todaysOrdersCount: number,
  showCompleted: boolean,
  involvesKitchen: InvolvesKitchen,
  onInvolvesKitchenChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onShowCompletedClick: () => void,
  labelSelectOptions: Label[],
  labelSelectValue: Label[],
  onLabelSelectChange: (event: React.ChangeEvent<{ value: unknown }>) => void,
  orderFlowSelectOptions: Status[],
  orderFlowColumns: Status[],
  onOrderFlowSelectChange: (event: React.ChangeEvent<{ value: unknown }>) => void,
  outstandingOrdersAlert: boolean,
  onOutstandingOrdersAlertClick: () => void,
}

const Panel = ({
  outstandingOrdersCount,
  todaysOrdersCount,
  showCompleted,
  involvesKitchen,
  onInvolvesKitchenChange,
  onShowCompletedClick,
  labelSelectOptions,
  labelSelectValue,
  onLabelSelectChange,
  orderFlowSelectOptions,
  orderFlowColumns,
  onOrderFlowSelectChange,
  outstandingOrdersAlert,
  onOutstandingOrdersAlertClick,
}: Props) => {
  const classes = useStyles();

  const showLabelSelect: boolean = useMemo(
    () => labelSelectOptions.length !== 1,
    [labelSelectOptions],
  );

  return (
    <Accordion className={classes.accordion} defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6">Dispense Options</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box className={classes.panelContainer}>
          <Box>
            <Typography
              display="block"
              variant="h6"
            >
              {`${outstandingOrdersCount} Outstanding Orders`}
            </Typography>
            <Typography
              display="block"
              variant="overline"
            >
              {`${todaysOrdersCount} Orders Today`}
            </Typography>
          </Box>
          <Box className={classes.labelsSelector}>
            {showLabelSelect
              && (
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    id="label-select"
                  >
                    Reporting Labels
                  </InputLabel>
                  <Select
                    multiple
                    labelId="label-select"
                    labelWidth={125}
                    value={labelSelectValue.map((label) => label.text)}
                    onChange={onLabelSelectChange}
                    MenuProps={{ variant: 'menu' }}
                  >
                    {labelSelectOptions.map((label) => (
                      <MenuItem key={label.id} value={label.text}>
                        {label.text}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            <Box className={classes.statusSelector}>
              <FormControl fullWidth variant="outlined">
                <InputLabel
                  id="swimlane-select"
                >
                  Active Status Swimlanes
                </InputLabel>
                <Select
                  multiple
                  labelId="swimlane-select"
                  labelWidth={180}
                  value={orderFlowColumns.map((status) => status)}
                  onChange={onOrderFlowSelectChange}
                  MenuProps={{ variant: 'menu' }}
                >
                  {orderFlowSelectOptions.map((status) => (
                    <MenuItem key={status} value={status}>
                      {capitalize(status)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box>
            <FormControl>
              <RadioGroup
                row
                value={involvesKitchen}
                onChange={onInvolvesKitchenChange}
              >
                <FormControlLabel value={InvolvesKitchen.All} control={<Radio />} label="All" />
                <FormControlLabel value={InvolvesKitchen.Drinks} control={<Radio />} label="Drinks" />
                <FormControlLabel value={InvolvesKitchen.Food} control={<Radio />} label="Food" />
              </RadioGroup>
            </FormControl>
            <Box>
              <FormControlLabel
                label="Outstanding Orders Alert"
                control={(
                  <Switch
                    checked={outstandingOrdersAlert}
                    onChange={onOutstandingOrdersAlertClick}
                  />
                )}
              />
            </Box>
          </Box>
          <Box>
            <Button
              className={classes.button}
              variant="outlined"
              color={showCompleted ? 'primary' : 'default'}
              endIcon={showCompleted ? <CheckCircle /> : <Add />}
              onClick={onShowCompletedClick}
            >
              <Typography variant="overline">Show Completed Orders</Typography>
            </Button>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default Panel;
