import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import firebase from 'firebase/app';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, useMediaQuery } from '@material-ui/core';

import AlertProvider from '../../contexts/AlertContextProvider';

import AuthRouter from './components/auth-router';
import AppRouter from './components/app-router';
import Spinner from '../../components/spinner/spinner';

import { getPrimaryColors, getSecondaryColors } from '../../helpers/getThemeColours';

import getNotifications from '../../actions/get-notifications';
import logout from '../../actions/logout';
import verify from '../../actions/verify';

import STORE_KEYS from '../../constants/store-keys';

const {
  NOTIFICATIONS: { IS_NOTIFICATIONS_LOADING, ACTIVE_NOTIFICATIONS },
  AUTHENTICATION: { USER, IS_AUTHORISED, IS_VERIFYING_USER },
} = STORE_KEYS;

const App = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const isNotificationLoading = useSelector((state) => state.notifications
    .get(IS_NOTIFICATIONS_LOADING));
  const notifications = useSelector((state) => state.notifications.get(ACTIVE_NOTIFICATIONS));
  const user = useSelector((state) => state.authentication.get(USER));
  const isAuthorised = useSelector((state) => state.authentication.get(IS_AUTHORISED));
  const isVerifyingUser = useSelector((state) => state.authentication.get(IS_VERIFYING_USER));
  const [authCheckFinished, setAuthCheckFinished] = React.useState(false);
  const [darkState, setDarkState] = React.useState(false);
  const dispatch = useDispatch();

  const theme = React.useMemo(
    () => createMuiTheme({
      palette: {
        type: darkState ? 'dark' : 'light',
        primary: getPrimaryColors(darkState),
        secondary: getSecondaryColors(darkState),
      },
    }),
    [darkState],
  );

  const handleThemeChange = () => {
    setDarkState(!darkState);
  };

  useEffect(() => {
    setDarkState(prefersDarkMode);
    if (!user) {
      firebase
        .auth()
        .onAuthStateChanged((userData) => {
          if (!authCheckFinished) {
            setAuthCheckFinished(true);
          }
          if (userData) {
            dispatch(verify(userData.uid));
          } else if (isAuthorised) {
            dispatch(logout());
          }
        });
    }
    if (user && user.site) {
      dispatch(getNotifications(user.site));
    }
  }, [prefersDarkMode, user]);

  if (!authCheckFinished || isVerifyingUser || isNotificationLoading) return <Spinner />;

  return (
    <ThemeProvider theme={theme}>
      <AlertProvider>
        <CssBaseline />
        <Router>
          {isAuthorised
            ? (
              <AppRouter
                notifications={notifications}
                user={user}
                darkState={darkState}
                onToggle={handleThemeChange}
              />
            )
            : <AuthRouter />}
        </Router>
      </AlertProvider>
    </ThemeProvider>
  );
};

export default App;
