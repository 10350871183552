/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useMemo } from 'react';
import {
  Checkbox, Box, makeStyles, Typography,
} from '@material-ui/core';

import ProductTimesInput from './ProductTimesInput';

import ProductTime from '../../../types/ProductTime';

interface Props {
  times: ProductTime[];
  onChange: (newTimes: ProductTime[]) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  title: { width: 85 },
  checkbox: {
    marginLeft: 25,
    marginRight: 55,
  },
}));

const ProductTimesForm = ({ times, onChange }: Props) => {
  const classes = useStyles();

  const toTimes = useMemo(() => times.map(({ to }) => to), [times]);
  const fromTimes = useMemo(() => times.map(({ from }) => from), [times]);
  const hiddenTimes = useMemo(() => times.map(({ hidden }) => hidden || false), [times]);

  const handleFromTimesChange = useCallback(
    (value: string, day: number) => {
      const newTimes = times.map((time, i) => (i === day ? { ...time, from: value } : time));
      onChange(newTimes);
    },
    [times, onChange],
  );

  const handleToTimesChange = useCallback(
    (value: string, day: number) => {
      const newTimes = times.map((time, i) => (i === day ? { ...time, to: value } : time));
      onChange(newTimes);
    },
    [times, onChange],
  );

  const handleHiddenTimesChange = useCallback(
    (value: boolean, day: number) => {
      const newTimes = times.map((time, i) => (i === day ? { ...time, hidden: value } : time));
      onChange(newTimes);
    },
    [times, onChange],
  );

  return (
    <div className={classes.root}>
      <Box className={classes.rowContainer}>
        <Typography className={classes.title} variant="body1" color="inherit" gutterBottom>
          From:
        </Typography>
        <ProductTimesInput
          times={fromTimes}
          hiddenTimes={hiddenTimes}
          onChange={handleFromTimesChange}
        />
      </Box>
      <Box className={classes.rowContainer}>
        <Typography className={classes.title} variant="body1" color="inherit" gutterBottom>
          To:
        </Typography>
        <ProductTimesInput
          times={toTimes}
          hiddenTimes={hiddenTimes}
          onChange={handleToTimesChange}
        />
      </Box>
      <Box className={classes.rowContainer}>
        <Typography className={classes.title} variant="body1" color="inherit" gutterBottom>
          Hidden:
        </Typography>
        {times.map((time, i) => (
          <Checkbox
            key={time.day}
            className={classes.checkbox}
            checked={time.hidden}
            onChange={(event) => handleHiddenTimesChange(event.target.checked, i)}
            value="primary"
          />
        ))}
      </Box>
    </div>
  );
};

export default ProductTimesForm;
