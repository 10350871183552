import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Grid, IconButton, Tooltip, makeStyles,
} from '@material-ui/core';
import { DataGrid, GridColumns, GridCellParams } from '@material-ui/data-grid';
import DeleteIcon from '@material-ui/icons/Delete';

import Toolbar from './components/Toolbar';
import UploadCodesDialog from './components/UploadCodesDialog';

import hasPermission from '../../helpers/has-permission';

import DiscountCode from '../../types/DiscountCode';
import ReduxState from '../../types/ReduxState';
import RoleRestrictedAction from '../../types/RoleRestrictedAction';
import User from '../../types/User';
import Nullable from '../../types/Nullable';

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: 10,
    height: 500,
    paddingBottom: theme.spacing(5),
  },
}));

type Props = {
  discountCodes: DiscountCode[]
  existingCodes: DiscountCode['code'][]
  onCreate: () => void
  onRemove: (code: DiscountCode['code']) => void
  onUpload: (codes: DiscountCode[]) => void
};

const DiscountCodesTable = ({
  discountCodes, existingCodes, onCreate, onRemove, onUpload,
}: Props) => {
  const user = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const classes = useStyles();

  const handleOpen = useCallback(() => setIsOpen(true), []);

  const handleClose = useCallback(() => setIsOpen(false), []);

  const handleSave = useCallback((data: DiscountCode[]) => {
    onUpload(data);
    setIsOpen(false);
  }, []);

  const handleSearchTextChange = useCallback((text: string) => setSearchText(text), []);

  const handleSearchTextClear = useCallback(() => setSearchText(''), []);

  const discountCodesList = useMemo(() => {
    if (searchText == null || searchText.length === 0) return discountCodes;

    const searchTerm = searchText.toLowerCase();

    return discountCodes.filter((discount) => discount.code.toLowerCase().includes(searchTerm));
  }, [discountCodes, searchText]);

  const columns: GridColumns = useMemo(() => [
    { field: 'code', headerName: 'Discount Code', width: 750 },
    {
      field: 'price',
      headerName: 'Usages',
      width: 400,
      renderCell: (params: GridCellParams) => (params.row.maxUsages != null
        ? `${params.row.usages || 0}/${params.row.maxUsages}`
        : 'Unlimited'),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params: GridCellParams) => (
        <Grid>
          <Tooltip title="Remove Discount Code">
            <IconButton
              onClick={() => onRemove(params.row.code)}
              color="inherit"
              disabled={!hasPermission(RoleRestrictedAction.RemoveDiscountCode, user)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ),
    },
  ], []);

  return (
    <Grid className={classes.table}>
      <DataGrid
        components={{ Toolbar }}
        rows={discountCodesList.map((code) => ({ ...code, id: code.code }))}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        componentsProps={{
          toolbar: {
            discountCodes: discountCodesList,
            value: searchText,
            onChange: handleSearchTextChange,
            clearSearch: handleSearchTextClear,
            user,
            onCreate,
            onUpload: handleOpen,
          },
        }}
      />
      <UploadCodesDialog
        existingCodes={existingCodes}
        open={isOpen}
        onClose={handleClose}
        onSave={handleSave}
      />
    </Grid>
  );
};

export default DiscountCodesTable;
