import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@material-ui/core';

import UnprocessedOrdersTable from './components/unprocessed-orders-table';
import Spinner from '../../components/spinner/spinner';

import getUnprocessedOrders from '../../actions/get-unprocessed-orders';

import processOrder from '../../helpers/process-order';

import ReduxState from '../../types/ReduxState';
import SiteData from '../../types/SiteData';
import User from '../../types/User';
import OrderData from '../../types/OrderData';
import Nullable from '../../types/Nullable';

const UnprocessedOrdersPage = () => {
  const user = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));
  const isUnprocessedOrdersLoading = useSelector<ReduxState, boolean>((state) => state
    .unprocessedOrders.get('IS_UNPROCESSED_ORDERS_LOADING'));
  const unprocessedOrders = useSelector<ReduxState, OrderData[]>((state) => state.unprocessedOrders.get('UNPROCESSED_ORDERS'));
  const settings = useSelector<ReduxState, Nullable<SiteData>>(
    (state) => state.settings.get('SETTINGS'),
  );
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const site = location.pathname.includes('admin') ? '' : user?.site;

  useEffect(() => {
    dispatch(getUnprocessedOrders(site));
  }, []);

  const onProcessOrder = (orderNumber: string) => {
    if (user != null) {
      setLoading(true);
      processOrder(orderNumber, user.name)
        .then(() => {
          setLoading(false);
        });
    }
  };

  if (isUnprocessedOrdersLoading || loading) return <Spinner />;

  return (
    <Grid>
      <UnprocessedOrdersTable
        siteData={settings}
        user={user}
        processOrder={onProcessOrder}
        unprocessedOrders={unprocessedOrders}
      />
    </Grid>
  );
};

export default UnprocessedOrdersPage;
