import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Dialog, DialogTitle, DialogContent, DialogActions,
} from '@material-ui/core';

import OutlinedTextField from '../../../components/outlined-text-field/outlined-text-field';
import SimpleSelect from '../../../components/SimpleSelect/SimpleSelect';
import OutlinedButton from '../../../components/outlined-button/outlined-button';

import getDays from '../helpers/get-days';

const types = [
  { value: 'collection', label: 'Collection' },
  { value: 'delivery', label: 'Delivery' },
];

const SlotTimesDialog = ({
  open, onSave, handleClose, selectedSlotTime, siteData,
}) => {
  const [day, setDay] = useState('');
  const [time, setTime] = useState('');
  const [slotCapacity, setSlotCapacity] = useState(0);
  const [type, setType] = useState('collection');
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (selectedSlotTime && count < 1) {
      setDay(selectedSlotTime.day);
      setTime(selectedSlotTime.time || '');
      setSlotCapacity(selectedSlotTime.slotCapacity);
      setType(selectedSlotTime.type);
      setCount(1);
    }
  });

  const onClose = () => {
    setDay('');
    setTime('');
    setSlotCapacity(0);
    setType('collection');
    setCount(0);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Create a Slot Time</DialogTitle>
      <DialogContent>
        <SimpleSelect
          label="Day *"
          items={getDays()}
          value={day}
          onChange={(v) => setDay(v)}
          labelWidth={40}
          disabled={siteData?.allDaySlots || false}
        />
        <OutlinedTextField
          id="outlined-code"
          classOption="flex"
          label="Time"
          value={time}
          onChange={(event) => setTime(event.target.value)}
          disabled={siteData?.allDaySlots || false}
        />
        <OutlinedTextField
          id="outlined-slot-capacity"
          classOption="flex"
          type="number"
          label="Slot Capacity"
          value={slotCapacity}
          onChange={(event) => setSlotCapacity(event.target.value)}
          required
        />
        <SimpleSelect
          label="Type of Slot *"
          items={types}
          value={type}
          onChange={setType}
          labelWidth={100}
        />
      </DialogContent>
      <DialogActions>
        <OutlinedButton
          label="Cancel"
          color="secondary"
          onClick={handleClose}
        />
        <OutlinedButton
          label="Save"
          color="primary"
          onClick={() => onSave({
            day, time, type, slotCapacity: Number(slotCapacity),
          })}
          disabled={day.length === 0 || time.length === 0 || slotCapacity.length === 0}
        />
      </DialogActions>
    </Dialog>
  );
};

SlotTimesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedSlotTime: PropTypes.shape(),
  siteData: PropTypes.shape().isRequired,
};

SlotTimesDialog.defaultProps = {
  selectedSlotTime: null,
};

export default SlotTimesDialog;
