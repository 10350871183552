import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Grid } from '@material-ui/core';

import NotificationsTable from '../../components/notifications-table/notifications-table';
import Spinner from '../../components/spinner/spinner';

import clearNotification from '../../helpers/clearNotification';

import NotificationData from '../../types/NotificationData';
import ReduxState from '../../types/ReduxState';

const NotificationsPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const notifications = useSelector<ReduxState, NotificationData[]>((state) => state.notifications.get('NOTIFICATIONS'));

  const onClear = async (alertId: string) => {
    setLoading(true);
    await clearNotification(alertId);
    setLoading(false);
  };

  if (loading) return <Spinner />;

  return (
    <Grid>
      <NotificationsTable
        notifications={notifications}
        clearNotification={onClear}
      />
    </Grid>
  );
};

export default NotificationsPage;
