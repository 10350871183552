import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';
import {
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  makeStyles,
  Paper,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';

import DateField from '../../../components/date-field/date-field';
import DateTimeField from '../../../components/date-time-field/date-time-field';
import MultipleSelect from '../../../components/multiple-select/multiple-select';

import DateType from '../types/DateType';
import Options from '../types/Options';
import Label from '../../../types/Label';
import User from '../../../types/User';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  labelSelect: {
    width: 260,
  },
  radioButtonText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  radioButtonTooltipIcon: {
    marginLeft: theme.spacing(1),
    color: '#0000008A',
  },
  timestampBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: 675,
    margin: theme.spacing(1, 0),
  },
  timestampInputBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  startTimestampTextField: {
    margin: theme.spacing(1, 2, 1, 0),
  },
  endTimestampTextField: {
    margin: theme.spacing(1, 0),
  },
  timestampInput: {
    width: 180,
  },
  buttonBox: {
    margin: theme.spacing(1, 0),
  },
  searchButton: {
    marginLeft: theme.spacing(2),
  },
}));

type Props = {
  options: Options,
  onOptionsChange: (newOption: Options) => void,
  onClear: () => void,
  onSearch: () => void,
  userLabels: Label[],
  siteLabels: Label[],
  posUsers: User[],
  showPosServers: boolean,
};

const SalesReportFilter = ({
  options,
  onOptionsChange,
  onClear,
  onSearch,
  userLabels,
  siteLabels,
  posUsers,
  showPosServers,
}: Props) => {
  const classes = useStyles();

  const {
    type, start, end, reportingLabels,
  } = options;

  const handleTypeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const now = DateTime.now().startOf('day');
    const lastWeek = now.minus({ weeks: 1 });

    switch (value) {
      case DateType.TradingDate:
        onOptionsChange({
          ...options,
          start: lastWeek.toFormat('yyyy-MM-dd'),
          end: now.toFormat('yyyy-MM-dd'),
          type: DateType.TradingDate,
        });
        break;
      case DateType.TransactionDate:
        onOptionsChange({
          ...options,
          start: lastWeek.toFormat('yyyy-MM-dd\'T\'HH:mm'),
          end: now.toFormat('yyyy-MM-dd\'T\'HH:mm'),
          type: DateType.TransactionDate,
        });
        break;
      default:
        throw new Error('Invalid input for date type input');
    }
  }, [options, onOptionsChange]);

  const handleStartChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onOptionsChange({ ...options, start: event.target.value });
  }, [options, onOptionsChange]);

  const handleEndChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onOptionsChange({ ...options, end: event.target.value });
  }, [options, onOptionsChange]);

  const handleReportingLabelsChange = useCallback((value: string[]) => {
    onOptionsChange({ ...options, reportingLabels: value });
  }, [options, onOptionsChange]);

  const handlePosServerChange = (event: ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as string;
    if (newValue === '') {
      onOptionsChange({ ...options, posServer: null });
    } else {
      onOptionsChange({ ...options, posServer: newValue });
    }
  };

  const isFormValid = useMemo(() => {
    const validLabel = userLabels.length !== 0 ? reportingLabels.length !== 0 : true;
    return validLabel && start.length > 0 && end.length > 0;
  }, [type, options]);

  const selectOptions = useMemo(() => {
    const labels = userLabels != null && userLabels.length !== 0
      ? userLabels
      : siteLabels;
    return [
      { label: 'No Labels', value: 'none' },
      ...labels.map((opt) => ({ label: opt.text, value: opt.id })),
    ];
  }, [userLabels, siteLabels]);

  const handleSearch = useCallback(() => {
    onSearch();
  }, [onSearch]);

  return (
    <Paper className={classes.container}>
      {showPosServers ? (
        <>
          <FormControl variant="outlined" className={classes.labelSelect}>
            <InputLabel id="server-select-label">
              POS Server
            </InputLabel>
            <Select
              labelId="server-select-label"
              labelWidth={95}
              value={options.posServer || ''}
              onChange={handlePosServerChange}
            >
              {posUsers.map((user) => (
                <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
              ))}
              <MenuItem value="">
                <Typography variant="button" color="primary">None</Typography>
              </MenuItem>
            </Select>
          </FormControl>
        </>
      ) : (
        <>
          <MultipleSelect
            className={classes.labelSelect}
            label="Reporting Labels"
            defaultValues={reportingLabels || []}
            options={selectOptions}
            onChange={handleReportingLabelsChange}
          />
        </>
      )}
      <Box className={classes.timestampBox}>
        <RadioGroup value={type} onChange={handleTypeChange}>
          <FormControlLabel
            value={DateType.TradingDate}
            control={<Radio />}
            label={(
              <Typography className={classes.radioButtonText}>
                Trading/Payout date
                <Info className={classes.radioButtonTooltipIcon} fontSize="small" />
              </Typography>
            )}
          />
          <FormControlLabel
            value={DateType.TransactionDate}
            control={<Radio />}
            label={(
              <Typography className={classes.radioButtonText}>
                Transaction date
                <Info className={classes.radioButtonTooltipIcon} fontSize="small" />
              </Typography>
            )}
          />
        </RadioGroup>
        {type === DateType.TradingDate && (
          <Box className={classes.timestampInputBox}>
            <DateField
              textFieldClassName={classes.startTimestampTextField}
              inputClassName={classes.timestampInput}
              label="Start Date"
              value={start || ''}
              onChange={handleStartChange}
            />
            <DateField
              textFieldClassName={classes.endTimestampTextField}
              inputClassName={classes.timestampInput}
              label="End Date"
              value={end || ''}
              onChange={handleEndChange}
            />
          </Box>
        )}
        {type === DateType.TransactionDate && (
          <Box className={classes.timestampInputBox}>
            <DateTimeField
              textFieldClassName={classes.startTimestampTextField}
              inputClassName={classes.timestampInput}
              label="Start Date"
              value={start || ''}
              onChange={handleStartChange}
            />
            <DateTimeField
              textFieldClassName={classes.endTimestampTextField}
              inputClassName={classes.timestampInput}
              label="End Date"
              value={end || ''}
              onChange={handleEndChange}
            />
          </Box>
        )}
      </Box>
      <Box className={classes.buttonBox}>
        <Button
          onClick={onClear}
          size="large"
          variant="outlined"
          color="secondary"
        >
          Clear
        </Button>
        <Button
          onClick={handleSearch}
          disabled={!isFormValid}
          size="large"
          variant="outlined"
          color="primary"
          className={classes.searchButton}
        >
          Search
        </Button>
      </Box>
    </Paper>
  );
};

export default SalesReportFilter;
