import firebase from 'firebase/app';

import { UPDATING_SITE_SETTINGS, UPDATE_SITE_SETTINGS, UPDATE_SITE_SETTINGS_ERROR } from '../constants/actions';

import isSiteData from '../helpers/isSiteData';

import Dispatch from '../types/Dispatch';
import SiteData from '../types/SiteData';

export default (id: string, updatedSettings: Partial<SiteData>) => (dispatch: Dispatch) => {
  dispatch({
    type: UPDATING_SITE_SETTINGS,
  });
  const firebaseCall = firebase
    .firestore()
    .collection('sites')
    .doc(id);
  firebaseCall
    .update(updatedSettings)
    .then(() => {
      firebaseCall
        .get()
        .then((snapshot) => {
          const settings = <SiteData>{ ...snapshot.data(), id: snapshot.id };
          dispatch({
            type: UPDATE_SITE_SETTINGS,
            settings: isSiteData(settings) ? settings : null,
          });
        });
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_SITE_SETTINGS_ERROR,
        message: error.message,
      });
    });
};
