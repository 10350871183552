import React, { useCallback } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import CharityDonationType from '../../../types/CharityDonationType';

import CheckBox from '../../check-box/check-box';

import CharityDonation from '../../../types/CharityDonation';
import OutlinedTextField from '../../outlined-text-field/outlined-text-field';

const useStyles = makeStyles((theme) => ({
  donationBox: {
    marginLeft: theme.spacing(3),
    maxWidth: theme.spacing(50),
  },
  amountBox: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

interface Props {
  charityDonation: CharityDonation
  onChange: (value: CharityDonation) => void
}

const CharityDonationForm = ({
  charityDonation,
  onChange,
}: Props) => {
  const classes = useStyles();

  const handleIsCompulsoryChange = useCallback((isCompulsory: CharityDonation['isCompulsory']) => {
    const newCharityData = { ...charityDonation, isCompulsory };
    onChange(newCharityData);
  }, [charityDonation]);

  const handleNameChange = useCallback((newName: CharityDonation['displayName']) => {
    const newCharityData = { ...charityDonation, displayName: newName };
    onChange(newCharityData);
  }, [charityDonation]);

  const handleAmountChange = useCallback((newAmount: CharityDonation['value']) => {
    if (charityDonation.type === CharityDonationType.FixedValue) {
      if (newAmount >= 0) {
        const newCharityData = { ...charityDonation, value: Math.floor(newAmount * 100) / 100 };
        onChange(newCharityData);
      }
    }
    if (charityDonation.type === CharityDonationType.Percentage) {
      if (newAmount >= 0 && newAmount < 100) {
        const newCharityData = { ...charityDonation, value: Math.floor(newAmount * 100) / 10000 };
        onChange(newCharityData);
      }
    }
  }, [charityDonation]);

  const handleTypeChange = useCallback((_, newType: CharityDonationType) => {
    if (newType != null) {
      const newCharityData = { ...charityDonation, type: newType, value: 0 };
      onChange(newCharityData);
    }
  }, [charityDonation]);

  const handlePluChange = useCallback((newPlu: CharityDonation['plu']) => {
    if (newPlu >= 0) {
      const newCharityData = { ...charityDonation, plu: newPlu };
      onChange(newCharityData);
    }
  }, [charityDonation]);

  return (
    <Box className={classes.donationBox}>
      <CheckBox
        label="Charity Donation Compulsory"
        checked={charityDonation.isCompulsory}
        onChange={(event) => handleIsCompulsoryChange(event.target.checked)}
      />
      <OutlinedTextField
        id="charity-donation-name"
        label="Charity Name"
        classOption="flex"
        type="text"
        value={charityDonation.displayName}
        onChange={(event) => handleNameChange(event.target.value)}
      />
      <Box className={classes.amountBox}>
        <OutlinedTextField
          id="charity-donation-amount"
          label="Charity Amount"
          classOption="flex"
          type="number"
          value={
            charityDonation.type === CharityDonationType.FixedValue
              ? charityDonation.value
              : charityDonation.value * 100
          }
          onChange={(event) => handleAmountChange(Number(event.target.value))}
          disabled={charityDonation.type === CharityDonationType.RoundUp}
        />
        <ToggleButtonGroup
          style={{ height: 56 }}
          value={charityDonation.type}
          exclusive
          onChange={handleTypeChange}
        >
          <ToggleButton style={{ width: 56 }} value={CharityDonationType.FixedValue} aria-label="fixed-value">
            £
          </ToggleButton>
          <ToggleButton style={{ width: 56 }} value={CharityDonationType.Percentage} aria-label="percentage">
            %
          </ToggleButton>
          <ToggleButton style={{ width: 120 }} value={CharityDonationType.RoundUp} aria-label="round-up">
            Round up
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <OutlinedTextField
        id="charity-donation-plu"
        label="Charity PLU"
        classOption="flex"
        type="number"
        value={charityDonation.plu}
        onChange={(event) => handlePluChange(Number(event.target.value))}
      />
    </Box>
  );
};

export default CharityDonationForm;
