import request from '../../../helpers/request';
import getQueryString from './getQueryString';

import Options from '../types/Options';
import TransactionsReportHeaderData from '../../../types/TransactionsReportHeaderData';

const getTransactionsReportHeader = async (
  site: string, options: Options, userId?: string, reportingLabels?: string[],
): Promise<TransactionsReportHeaderData> => {
  const endpoint = getQueryString('transactionsReportHeader', site, options, userId, undefined, reportingLabels);

  const result = await request(endpoint, 'GET');
  return result;
};

export default getTransactionsReportHeader;
