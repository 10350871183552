import EditableSiteData from 'src/types/EditableSiteData';
import Nullable from '../types/Nullable';
import SiteData from '../types/SiteData';

const getSiteSplashConfigId = (siteData: Nullable<SiteData | EditableSiteData>) => {
  if (siteData == null) return '';

  const testModeSplashConfigId = siteData.testModeSplashConfigId || '';
  const prodSplashConfigId = siteData.splashConfigId || '';

  return siteData.testMode ? testModeSplashConfigId : prodSplashConfigId;
};

export default getSiteSplashConfigId;
