import React, { useCallback, useMemo } from 'react';
import { Grid } from '@material-ui/core';

import AdornmentTextField from '../../adornment-text-field/adornment-text-field';
import OutlinedTextField from '../../outlined-text-field/outlined-text-field';
import CheckBox from '../../check-box/check-box';
import SimpleSelect from '../../SimpleSelect/SimpleSelect';

import getDefaultCollectionTimes from '../helpers/getDefaultCollectionTimes';
import getDefaultDeliveryTimes from '../helpers/getDefaultDeliveryTimes';
import getDefaultServiceCharge from '../helpers/getDefaultServiceCharge';
import getCurrencyAdornment from '../../../helpers/getCurrencyAdornment';
import getDefaultCharityDonation from '../helpers/getDefaultCharityDonation';

import SiteWaitingTimes from '../../../types/SiteWaitingTimes';
import EditableSiteData from '../../../types/EditableSiteData';
import CharityDonationForm from './charityDonationForm';
import CharityDonation from '../../../types/CharityDonation';
import CollectCustomerNumber from '../../../types/CollectCustomerNumber';

type Props = {
  settings: EditableSiteData
  onChange: (newSiteData: EditableSiteData) => void
};

const SiteOptionsForm = ({ settings, onChange }: Props) => {
  const {
    waitingTimes,
    hideFreeText,
    cardTransactionWarningValue,
    gratuitiesPLU,
    defaultGratuity,
    allowDiscountCodes,
    serviceCharge,
    highPricedAddonsFirst,
    collection,
    delivery,
    useTipjar,
    tipjarShortcutCode,
    allowConsoleNotifications,
    collectCustomerName,
    locationDescriptor,
    splitPayouts,
    siteTipSharePercentage,
    charityDonation,
    notifications,
    giftCardOptions: {
      allowGiftCards,
    },
    collectCustomerNumber,
  } = settings;
  const isCollection = collection && collection.length !== 0;
  const isDelivery = delivery && delivery.length !== 0;
  const allowServiceCharge = serviceCharge !== null && serviceCharge !== undefined;
  const enableGratuities = gratuitiesPLU !== null && gratuitiesPLU !== undefined;

  const handlePropertyChange = <T extends keyof EditableSiteData>(
    property: T, value: EditableSiteData[T],
  ) => {
    const newSiteData = { ...settings, [property]: value };
    onChange(newSiteData);
  };

  const handleWaitingTimesPropertyChange = <T extends keyof SiteWaitingTimes>(
    property: T, value: SiteWaitingTimes[T],
  ) => {
    const currentWaitingTimesValue: SiteWaitingTimes = waitingTimes != null
      ? waitingTimes
      : { drinksWaitingTime: 0, foodWaitingTime: 0 };
    const newSiteData = {
      ...settings, waitingTimes: { ...currentWaitingTimesValue, [property]: value },
    };
    onChange(newSiteData);
  };

  const handleServiceChargePercentageChange = useCallback(
    (value: string) => {
      const currentServiceCharge = serviceCharge || getDefaultServiceCharge;
      const newSiteData = {
        ...settings, serviceCharge: { ...currentServiceCharge, percentage: value },
      };
      onChange(newSiteData);
    },
    [serviceCharge, onChange],
  );

  const handleServiceChargeValueChange = useCallback(
    (value: string) => {
      const currentServiceCharge = serviceCharge || getDefaultServiceCharge;
      const newSiteData = {
        ...settings, serviceCharge: { ...currentServiceCharge, value },
      };
      onChange(newSiteData);
    },
    [serviceCharge, onChange],
  );

  const handleServiceChargePluChange = useCallback(
    (value: string) => {
      const currentServiceCharge = serviceCharge || getDefaultServiceCharge;
      const newSiteData = {
        ...settings, serviceCharge: { ...currentServiceCharge, plu: value },
      };
      onChange(newSiteData);
    },
    [serviceCharge, onChange],
  );

  const handleServiceChargeNameChange = useCallback(
    (value: string) => {
      const currentServiceCharge = serviceCharge || getDefaultServiceCharge;
      const newSiteData = {
        ...settings, serviceCharge: { ...currentServiceCharge, chargeName: value },
      };
      onChange(newSiteData);
    },
    [serviceCharge, onChange],
  );

  const handleAllowGiftCardsClick = useCallback(
    (value: boolean) => {
      const currentGiftCardOptions = { ...settings.giftCardOptions };
      const newSiteData = {
        ...settings, giftCardOptions: { ...currentGiftCardOptions, allowGiftCards: value },
      };
      onChange(newSiteData);
    },
    [serviceCharge, onChange],
  );

  const handleServiceChargeCompulsory = useCallback(
    (value: boolean) => {
      const currentServiceCharge = serviceCharge || getDefaultServiceCharge;
      const newSiteData = {
        ...settings, serviceCharge: { ...currentServiceCharge, compulsory: value },
      };
      onChange(newSiteData);
    },
    [serviceCharge, onChange],
  );

  const handleCharityDonationsClick = (checked: boolean) => {
    const newSiteData = {
      ...settings,
      charityDonation: { ...charityDonation || getDefaultCharityDonation, isActive: checked },
    };
    onChange(newSiteData);
  };

  const handleCharityDonationChange = useCallback((value: CharityDonation) => {
    const currentCharityDonation = value || getDefaultCharityDonation;
    const newSiteData = {
      ...settings,
      charityDonation: currentCharityDonation,
    };
    onChange(newSiteData);
  }, [charityDonation, onChange]);

  const handleGratuitiesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    const newSiteData = { ...settings, defaultGratuity: 0, gratuitiesPLU: checked ? 0 : null };
    onChange(newSiteData);
  };

  const handleSiteTipSharePercentageChange = (value: string) => {
    const newValue = Number(value) / 100;
    if (newValue < 0 || newValue > 1) return;
    const newSiteData = { ...settings, siteTipSharePercentage: newValue };
    onChange(newSiteData);
  };

  const siteTipShareAmount = useMemo(() => {
    if (siteTipSharePercentage == null) return 0;
    return (Math.round(siteTipSharePercentage * 1000) / 10).toString();
  }, [siteTipSharePercentage]);

  return (
    <Grid>
      <OutlinedTextField
        id="drinks-waiting-time"
        label="Drink Waiting Time"
        classOption="flex"
        value={waitingTimes && waitingTimes.drinksWaitingTime ? waitingTimes.drinksWaitingTime : ''}
        onChange={(event) => handleWaitingTimesPropertyChange('drinksWaitingTime', Number(event.target.value))}
      />
      <OutlinedTextField
        id="food-waiting-time"
        label="Food Waiting Time"
        classOption="flex"
        value={waitingTimes && waitingTimes.foodWaitingTime ? waitingTimes.foodWaitingTime : ''}
        onChange={(event) => handleWaitingTimesPropertyChange('foodWaitingTime', Number(event.target.value))}
      />
      <CheckBox
        label="Hide Free Text"
        checked={hideFreeText || false}
        onChange={(event) => handlePropertyChange('hideFreeText', event.target.checked)}
      />
      <CheckBox
        label="High Priced Addons First"
        checked={highPricedAddonsFirst || false}
        onChange={(event) => handlePropertyChange('highPricedAddonsFirst', event.target.checked)}
      />
      <AdornmentTextField
        id="card-transaction-warning-value"
        label="Card transaction warning value"
        labelWidth={225}
        value={cardTransactionWarningValue}
        onChange={(event) => handlePropertyChange('cardTransactionWarningValue', event.target.value)}
        adornment={getCurrencyAdornment(settings.currency)}
        required
      />
      <CheckBox
        label="Enable Gratuities"
        checked={enableGratuities}
        onChange={handleGratuitiesChange}
      />
      {enableGratuities && (
        <>
          <OutlinedTextField
            id="gratuities-plu"
            label="Gratuities PLU"
            classOption="flex"
            value={gratuitiesPLU}
            onChange={(event) => handlePropertyChange('gratuitiesPLU', Number(event.target.value))}
          />
          <SimpleSelect
            label="Default Gratuity"
            items={[
              { label: '0%', value: 0 },
              { label: '5%', value: 5 },
              { label: '10%', value: 10 },
              { label: '15%', value: 15 },
            ]}
            value={defaultGratuity}
            onChange={(value) => handlePropertyChange('defaultGratuity', value)}
            labelWidth={130}
          />
          {splitPayouts && (
            <OutlinedTextField
              type="number"
              id="site-tip-share"
              label="Site tip share (%)"
              classOption="flex"
              value={siteTipShareAmount}
              onChange={(event) => handleSiteTipSharePercentageChange(event.target.value)}
            />
          )}
        </>
      )}
      <CheckBox
        label="Allow Discount Codes"
        checked={allowDiscountCodes || false}
        onChange={(event) => handlePropertyChange('allowDiscountCodes', event.target.checked)}
      />
      <CheckBox
        label="Allow Gift Cards"
        checked={allowGiftCards || false}
        onChange={(event) => handleAllowGiftCardsClick(event.target.checked)}
      />
      <CheckBox
        label="Service Charge"
        checked={allowServiceCharge}
        onChange={(event) => handlePropertyChange(
          'serviceCharge',
          event.target.checked ? getDefaultServiceCharge : null,
        )}
      />
      {allowServiceCharge && (
        <>
          <CheckBox
            label="Service Charge Compulsory"
            checked={serviceCharge.compulsory || false}
            onChange={(event) => handleServiceChargeCompulsory(event.target.checked)}
          />
          <OutlinedTextField
            id="service-charge-percentage"
            label="Service Charge Percentage"
            classOption="flex"
            type="number"
            value={serviceCharge.percentage}
            onChange={(event) => handleServiceChargePercentageChange(event.target.value)}
          />
          <OutlinedTextField
            id="additional-charge-value"
            label={`Additional Charge Value (${getCurrencyAdornment(settings.currency)})`}
            classOption="flex"
            type="number"
            value={serviceCharge.value}
            onChange={(event) => handleServiceChargeValueChange(event.target.value)}
          />
          <OutlinedTextField
            id="charge-plu"
            label="Charge PLU"
            classOption="flex"
            type="number"
            value={serviceCharge.plu}
            onChange={(event) => handleServiceChargePluChange(event.target.value)}
          />
          <OutlinedTextField
            id="service-charge-name"
            label="Service Charge Name"
            classOption="flex"
            placeholder="Service Charge"
            value={serviceCharge.chargeName}
            onChange={(event) => handleServiceChargeNameChange(event.target.value)}
          />
        </>
      )}
      <CheckBox
        label="Charity Donation"
        checked={charityDonation?.isActive || false}
        onChange={(event) => handleCharityDonationsClick(event.target.checked)}
      />
      {charityDonation?.isActive && (
        <CharityDonationForm
          charityDonation={charityDonation || getDefaultCharityDonation}
          onChange={handleCharityDonationChange}
        />
      )}
      <CheckBox
        label="Order & Pay Notifications"
        checked={notifications || false}
        onChange={(event) => handlePropertyChange('notifications', event.target.checked)}
      />
      {notifications && (
        <SimpleSelect
          label="Collect Customer Number At Checkout"
          items={[
            { label: 'Never', value: CollectCustomerNumber.Never },
            { label: 'Optional', value: CollectCustomerNumber.Optional },
            { label: 'Always', value: CollectCustomerNumber.Always },
          ]}
          value={collectCustomerNumber || CollectCustomerNumber.Never}
          onChange={(value) => handlePropertyChange('collectCustomerNumber', value)}
          labelWidth={175}
          allowEmpty={false}
        />
      )}
      <CheckBox
        label="Collection"
        checked={isCollection || false}
        onChange={(event) => handlePropertyChange('collection', event.target.checked ? getDefaultCollectionTimes() : [])}
      />
      <CheckBox
        label="Delivery"
        checked={isDelivery || false}
        onChange={(event) => handlePropertyChange('delivery', event.target.checked ? getDefaultDeliveryTimes() : [])}
      />
      <CheckBox
        label="Enable Tipjar Tip Forwarding"
        checked={useTipjar || false}
        onChange={(event) => handlePropertyChange('useTipjar', event.target.checked)}
      />
      {useTipjar && (
        <OutlinedTextField
          id="tipjar-shortcut-code"
          label="Tipjar Shortcut Code"
          classOption="flex"
          value={tipjarShortcutCode || ''}
          onChange={(event) => handlePropertyChange('tipjarShortcutCode', event.target.value)}
        />
      )}
      <CheckBox
        label="Allow Console Notifications"
        checked={allowConsoleNotifications || false}
        onChange={(event) => handlePropertyChange('allowConsoleNotifications', event.target.checked)}
      />
      <SimpleSelect
        label="Collect Customer Name"
        items={[
          { label: 'Never', value: 'never' },
          { label: 'Optional', value: 'optional' },
          { label: 'Always', value: 'always' },
        ]}
        value={collectCustomerName || 'never'}
        onChange={(value) => handlePropertyChange('collectCustomerName', value)}
        labelWidth={175}
        allowEmpty={false}
      />
      <OutlinedTextField
        id="tipjar-shortcut-code"
        label="Location Descriptor (Table Number Text)"
        classOption="flex"
        value={locationDescriptor || ''}
        onChange={(event) => handlePropertyChange('locationDescriptor', event.target.value)}
        helperText="Default is 'Table Number' if left empty"
      />
    </Grid>
  );
};

export default SiteOptionsForm;
