import firebase from 'firebase/app';

import {
  GET_SLOT_DAYS_START,
  GET_SLOT_DAYS_SUCCESS,
  GET_SLOT_DAYS_ERROR,
} from '../constants/actions';

import Dispatch from '../types/Dispatch';
import SlotTime from '../types/SlotTime';

export default (site: string) => (dispatch: Dispatch) => {
  dispatch({
    type: GET_SLOT_DAYS_START,
  });
  firebase
    .firestore()
    .collection('slottimes')
    .where('site', '==', site)
    .where('slotCapacity', '>=', 1)
    .get()
    .then((snapshots) => {
      const slots: SlotTime[] = [];
      snapshots.forEach((snapshot) => {
        slots.push(<SlotTime>{ ...snapshot.data(), id: snapshot.id });
      });
      const mappedDays = slots.map((slot) => slot.day);
      const slotDays = slots.filter((item, pos) => mappedDays.indexOf(item.day) === pos);
      dispatch({
        type: GET_SLOT_DAYS_SUCCESS,
        slotDays,
      });
    }, () => {
      dispatch({
        type: GET_SLOT_DAYS_ERROR,
        message: 'Failed to fetch slot times.',
      });
    });
};
