const isImportedGiftCard = (giftCard: string[]) => {
  const [giftCardName, balance, email, name] = giftCard;
  const validationErrors = [];

  if (giftCardName === '') validationErrors.push('Product Name');
  if (balance === '') validationErrors.push('Balance');
  if (email === '') validationErrors.push('Recipient Email');
  if (name === '') validationErrors.push('Recipient Name');

  return {
    isValid: validationErrors.length === 0,
    errorString: validationErrors.length > 0 ? `Missing ${validationErrors.join(', ')}` : '',
  };
};

export default isImportedGiftCard;
