import React, { useEffect, useState, useMemo } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';

import Spinner from '../../../components/spinner/spinner';
import SalesReportTimeline from './SalesReportTimeline';
import ProductChartSection from './ProductChartSection';
import LabelBreakdownSection from './LabelBreakdownSection';
import RushTimeSection from './RushTimeSection';
import BestsellersTableSection from './BestsellersTableSection';
import getSalesGraphs from '../helpers/getSalesGraphs';

import Nullable from '../../../types/Nullable';
import User from '../../../types/User';
import Options from '../types/Options';
import SalesGraphsResult from '../types/SalesGraphsResult';
import SiteData from '../../../types/SiteData';

type Props = {
  site: Nullable<SiteData>
  user: Nullable<User>
  options: Options
};

const useStyles = makeStyles((theme) => ({
  columnBox: {
    [theme.breakpoints.up('sm')]: {
      width: '49%',
    },
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  rowBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
}));

const SalesGraphs = ({ site, options, user }: Props) => {
  const classes = useStyles();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  const [
    timelineData,
    setTimelineData,
  ] = useState<SalesGraphsResult['timelineData']>([]);
  const [
    labelBreakdownData,
    setLabelBreakdownData,
  ] = useState<SalesGraphsResult['labelBreakdownData']>([]);
  const [
    productChartData,
    setProductChartData,
  ] = useState<Nullable<SalesGraphsResult['productChartData']>>(null);
  const [
    bestsellersData,
    setBestsellersData,
  ] = useState<SalesGraphsResult['bestsellersData']>([]);
  const [
    rushTimeData,
    setRushTimeData,
  ] = useState<SalesGraphsResult['rushTimeData']>([]);

  useEffect(() => {
    getSalesGraphs(user?.site || '', options, user?.id, options.reportingLabels)
      .then((salesReportData) => {
        setTimelineData(salesReportData.timelineData);
        setLabelBreakdownData(salesReportData.labelBreakdownData);
        setProductChartData(salesReportData.productChartData);
        setBestsellersData(salesReportData.bestsellersData);
        setRushTimeData(salesReportData.rushTimeData);
      })
      .catch(() => setErrorMessage('Something went wrong getting Sales Reports'))
      .finally(() => setLoading(false));
  }, [options]);

  const dateRangeText = useMemo(() => {
    const { start, end } = options;
    return `${start.split('-').reverse().join('/')} to ${end.split('-').reverse().join('/')}`;
  }, [options]);

  if (errorMessage) {
    return <Typography align="center" color="error">{errorMessage}</Typography>;
  }

  if (loading) return <Spinner />;

  return (
    <>
      <SalesReportTimeline
        data={timelineData}
        dateRangeText={dateRangeText}
      />
      <Box className={classes.rowBox}>
        <Box className={classes.columnBox}>
          <ProductChartSection
            data={productChartData}
            dateRangeText={dateRangeText}
          />
          <LabelBreakdownSection
            data={labelBreakdownData}
            siteData={site}
            dateRangeText={dateRangeText}
          />
        </Box>
        <Box className={classes.columnBox}>
          <BestsellersTableSection
            bestsellersData={bestsellersData}
            dateRangeText={dateRangeText}
          />
          <RushTimeSection
            rushTimeData={rushTimeData}
            dateRangeText={dateRangeText}
          />
        </Box>
      </Box>
    </>
  );
};

export default SalesGraphs;
