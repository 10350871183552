import React, { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Divider,
  Paper,
  makeStyles,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core';

import SimpleSelect from '../../SimpleSelect/SimpleSelect';

import getCurrencyAdornment from '../../../helpers/getCurrencyAdornment';
import generatePluNumber from '../helpers/generatePluNumber';

import EditableProductData from '../../../types/EditableProductData';
import VatRate from '../../../types/VatRate';
import Nullable from '../../../types/Nullable';
import SiteData from '../../../types/SiteData';
import Product from '../../../types/Product';

type Props = {
  product: EditableProductData
  onChange: <T extends keyof EditableProductData>(label: T, value: EditableProductData[T]) => {}
  vatRates: VatRate[]
  siteData: Nullable<SiteData>
  plusInUse: Product['plu'][]
};

const useStyles = makeStyles((theme) => ({
  mainSection: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingBottom: theme.spacing(3),
  },
  textInput: {
    width: 350,
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  inputErrorText: {
    marginBottom: theme.spacing(5),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2.5),
    width: 350,
  },
}));

const ProductDetails = ({
  product, onChange, vatRates, siteData, plusInUse,
}: Props) => {
  const [pluInputError, setPluInputError] = useState<Nullable<string>>(null);
  const classes = useStyles();

  const {
    plu, name, pluName, price, description, vatRate, addonDescription,
  } = product;

  const handlePluChange = useCallback((event) => {
    const { target: { value } } = event;

    const pluNumber = Number(value);

    if (pluNumber < 0 || pluNumber > 2147483647) {
      setPluInputError('Please choose a PLU value between 0 and 2147483647.');
    } else if (plusInUse.includes(pluNumber)) {
      setPluInputError('PLU is in use, please choose another PLU value');
    } else {
      setPluInputError(null);
    }

    onChange('plu', value);
  }, [plusInUse, onChange]);

  useEffect(() => {
    if (Number(plu) === 0) {
      onChange('plu', generatePluNumber(plusInUse));
    }
  }, []);

  return (
    <Paper>
      <Box className={classes.mainSection}>
        <TextField
          className={classes.textInput}
          label="Product Name"
          variant="outlined"
          value={name}
          onChange={(event) => onChange('name', event.target.value)}
          required
        />
        <TextField
          className={classes.textInput}
          type="number"
          label="PLU"
          variant="outlined"
          value={plu}
          onChange={handlePluChange}
          helperText={pluInputError}
          error={pluInputError != null}
          required
        />
        <TextField
          className={classes.textInput}
          label="PLU Name"
          variant="outlined"
          value={pluName}
          onChange={(event) => onChange('pluName', event.target.value)}
          required
        />
        <TextField
          className={classes.textInput}
          label="Description"
          variant="outlined"
          value={description}
          onChange={(event) => onChange('description', event.target.value)}
          multiline
        />
        <TextField
          className={classes.textInput}
          label="Add On Description"
          variant="outlined"
          value={addonDescription}
          onChange={(event) => onChange('addonDescription', event.target.value)}
        />
        <Divider className={classes.divider} />
        <FormControl className={classes.textInput} variant="outlined">
          <InputLabel htmlFor="price-field">Price</InputLabel>
          <OutlinedInput
            id="price-field"
            value={price}
            onChange={(event) => onChange('price', event.target.value)}
            startAdornment={<InputAdornment position="start">{getCurrencyAdornment(siteData?.currency)}</InputAdornment>}
            labelWidth={35}
            required
          />
        </FormControl>
        <SimpleSelect<number>
          label="Vat Rate"
          items={vatRates}
          value={vatRate}
          onChange={(value) => onChange('vatRate', typeof value === 'number' ? value : null)}
          labelWidth={60}
        />
      </Box>
    </Paper>
  );
};

export default ProductDetails;
