import React, {
  ChangeEvent,
  useCallback,
  useState,
  useMemo,
} from 'react';
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  Fade,
  DialogContentText,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PasswordField from '../password-field/password-field';

interface Props {
  open: boolean;
  onSave: (newPassword: string, currentPassword: string) => Promise<void>;
  onCancel: () => void;
  requireCurrentPassword: boolean;
}

const ResetPasswordDialog = ({
  open,
  onSave,
  onCancel,
  requireCurrentPassword,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>('');
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState<boolean>(false);

  const handleCurrentPasswordChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setCurrentPassword(event.target.value),
    [],
  );

  const handleNewPasswordChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  }, []);

  const handleNewPasswordConfirmationChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setNewPasswordConfirmation(event.target.value),
    [],
  );

  const handleSaveClick = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      await onSave(newPassword, currentPassword);
      setSuccessSnackbarOpen(true);
      setCurrentPassword('');
      setNewPassword('');
      setNewPasswordConfirmation('');
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [currentPassword, newPassword, newPasswordConfirmation]);

  const handleCancelClick = useCallback(() => {
    onCancel();
    setCurrentPassword('');
    setNewPassword('');
    setNewPasswordConfirmation('');
  }, []);

  const handleSuccessSnackbarClose = useCallback(() => {
    setSuccessSnackbarOpen(false);
  }, []);

  const validNewPassword = useMemo(() => (
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@?$#%&])(.+){8,}$/.test(newPassword)
    && newPassword === newPasswordConfirmation
  ), [newPassword, newPasswordConfirmation]);

  return (
    <Box>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <Fade in={loading}>
          <LinearProgress />
        </Fade>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
          {requireCurrentPassword && (
            <PasswordField
              id="current-password-input"
              label="Current Password"
              labelWidth={130}
              value={currentPassword}
              onChange={handleCurrentPasswordChange}
              disabled={loading}
            />
          )}
          <PasswordField
            id="new-password-input"
            label="New Password"
            labelWidth={110}
            value={newPassword}
            onChange={handleNewPasswordChange}
            helperText="Passwords must be at least 8 characters in length and contain one capital letter, number and special character."
            disabled={loading}
          />
          <PasswordField
            id="confirm-password-input"
            label="Confirm New Password"
            labelWidth={175}
            value={newPasswordConfirmation}
            onChange={handleNewPasswordConfirmationChange}
            helperText="Re-enter new password"
            disabled={loading}
          />
          {error && (
            <DialogContentText>
              Password could not be updated. Please try again and contact support if this problem
              persists.
            </DialogContentText>
          )}
          <DialogActions>
            <Button
              variant="outlined"
              onClick={handleCancelClick}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleSaveClick}
              disabled={!validNewPassword || loading}
            >
              Save
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={3000}
      >
        <Alert onClose={handleSuccessSnackbarClose} severity="success">New Password Saved!</Alert>
      </Snackbar>
    </Box>
  );
};

export default ResetPasswordDialog;
