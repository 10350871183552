import React,
{
  ChangeEvent,
  useMemo,
  useState,
} from 'react';

import {
  Box,
  Button,
  Drawer,
  Grid,
  Tab,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';

import SiteInformationForm from './components/site-information-form';
import SiteOptionsForm from './components/site-options-form';
import OnlineSettingsForm from './components/online-settings-form';
import OpeningTimesForm from './components/opening-times-form';
import SiteOrderFlow from './components/SiteOrderFlow';

import isColourValid from '../../containers/settings-page/helpers/is-colour-valid';

import Nullable from '../../types/Nullable';
import SiteData from '../../types/SiteData';
import isValidServiceCharge from './helpers/isValidServiceCharge';
import EditableSiteData from '../../types/EditableSiteData';
import isSiteData from '../../helpers/isSiteData';
import createEditableSiteData from './helpers/createEditableSiteData';
import READER_IMAGE from '../../constants/readerImage';
import SiteLogoImage from './components/SiteLogoImage';
import TerminalSplashImage from '../TerminalSplashImage/TerminalSplashImage';
import isValidCharityDonation from './helpers/isValidCharityDonation';
import getSiteSplashConfigId from '../../helpers/getSiteSplashConfigId';
import getSiteReaderSplashImage from '../../helpers/getSiteReaderSplashImage';

type Props = {
  siteData: SiteData
  onSave: (
    site: SiteData,
    selectedFile: Nullable<File>,
    splashImage: Nullable<File>,
  ) => void
  onCancel: () => void
};

const useStyles = makeStyles({
  root: { margin: 10 },
  buttons: { padding: 10, paddingRight: 20 },
  cancelButton: { marginRight: 10 },
});

const SiteForm = ({ siteData, onSave, onCancel }: Props) => {
  const classes = useStyles();
  const [edited, setEdited] = useState<boolean>(false);
  const [site, setSite] = useState<EditableSiteData>(createEditableSiteData(siteData));
  const [selectedFile, setSelectedFile] = useState<Nullable<File>>(null);
  const [splashImage, setSplashImage] = useState<Nullable<File>>(null);
  const [tabValue, setTabValue] = useState<string>('1');
  const [error, setError] = useState<Nullable<string>>(null);

  const {
    collection,
    delivery,
    logo,
    siteThemeColour,
    vatRegistered,
    vatNumber,
    charityDonation,
  } = site;
  const isCollection = collection && collection.length !== 0;
  const isDelivery = delivery && delivery.length !== 0;

  const handleTabChange = (event: ChangeEvent<{}>, newValue: string) => {
    setTabValue(newValue);
  };

  const isCharityDonationValid = useMemo(() => {
    if (charityDonation == null) return true;
    if (!charityDonation.isActive) return true;
    return isValidCharityDonation(charityDonation);
  }, [charityDonation]);

  const handleSave = async () => {
    let validSiteData: SiteData = { ...site, serviceCharge: null };
    const { serviceCharge } = site;
    if (serviceCharge != null) {
      const validServiceCharge = {
        plu: Number(serviceCharge.plu),
        chargeName: serviceCharge.chargeName,
        percentage: Number(serviceCharge.percentage),
        value: Number(serviceCharge.value),
        compulsory: serviceCharge?.compulsory || false,
      };
      validSiteData = { ...site, serviceCharge: validServiceCharge };
    }
    if (isSiteData(validSiteData)) {
      onSave(validSiteData, selectedFile || null, splashImage || null);
    }
  };

  const handleChange = (newSiteData: EditableSiteData) => {
    setSite(newSiteData);
    setEdited(true);
  };

  const siteSplashConfig = useMemo(() => getSiteSplashConfigId(site), [site]);
  const siteReaderSplashImage = useMemo(() => getSiteReaderSplashImage(site), [site]);

  const handleSplashChange = (preview: string) => {
    if (site.testMode) {
      setSite({ ...site, testModeReaderSplashImage: preview });
    } else {
      setSite({ ...site, readerSplashImage: preview });
    }
    setEdited(true);
  };

  const handleSplashRemove = () => {
    if (site.testMode) {
      setSite({
        ...site,
        testModeReaderSplashImage: '',
        testModeSplashConfigId: '',
      });
    } else {
      setSite({
        ...site,
        readerSplashImage: '',
        splashConfigId: '',
      });
    }
    setEdited(true);
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Box>
          <TabContext value={tabValue}>
            <Box>
              <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                <Tab label="Site Information" value="1" />
                <Tab label="Site images" value="2" />
                <Tab label="Site Options" value="3" />
                <Tab label="Site Order Flow" value="4" />
                <Tab label="Site Times" value="5" />
                <Tab label="Online Options" value="6" disabled={!isCollection && !isDelivery} />
              </TabList>
            </Box>
            <TabPanel value="1">
              <SiteInformationForm settings={site} onChange={handleChange} />
            </TabPanel>
            <TabPanel value="2">
              <SiteLogoImage
                logo={logo}
                handleChange={handleChange}
                setSelectedFile={setSelectedFile}
                site={site}
              />
              <TerminalSplashImage
                readerSplashImage={
                  (siteSplashConfig.length === 0)
                    ? READER_IMAGE.Default
                    : siteReaderSplashImage
                }
                setError={setError}
                handleChange={handleSplashChange}
                handleRemove={handleSplashRemove}
                setSplashImage={setSplashImage}
                descriptionText="You can replace the default Peazi splash screen with your own image."
              />
            </TabPanel>
            <TabPanel value="3">
              <SiteOptionsForm
                settings={site}
                onChange={handleChange}
              />
            </TabPanel>
            <TabPanel value="4">
              <SiteOrderFlow siteData={site} onChange={handleChange} />
            </TabPanel>
            <TabPanel value="5">
              <OpeningTimesForm settings={site} onChange={handleChange} />
            </TabPanel>
            <TabPanel value="6">
              <OnlineSettingsForm settings={site} onChange={handleChange} />
            </TabPanel>
          </TabContext>
        </Box>
        <Drawer anchor="bottom" variant="permanent">
          <Grid
            className={classes.buttons}
            container
            direction="row"
            justify="flex-end"
          >
            <Button
              className={classes.cancelButton}
              variant="outlined"
              color="secondary"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={
                !edited
                || !isColourValid(siteThemeColour || '')
                || (site.serviceCharge != null && !isValidServiceCharge(site.serviceCharge))
                || (vatRegistered && !/^[A-Z]{2}[0-9]{9}$/.test(vatNumber || ''))
                || error != null
                || !isCharityDonationValid
              }
            >
              Save
            </Button>
            {error && <Typography color="error">{error}</Typography>}
          </Grid>
        </Drawer>
      </Grid>
    </div>
  );
};

export default SiteForm;
