import firebase from 'firebase/app';
import Label from '../../../types/Label';

const setLabels = async (
  siteId: string,
  labelId: string,
  currentLabels: Label[],
  updatedLabel: Label,
) => {
  const siteRef = firebase.firestore().collection('sites').doc(siteId);

  const updatedLabels = currentLabels.map(
    (label) => (label.id === labelId ? updatedLabel : label),
  );

  await siteRef
    .update({
      reportingLabels: updatedLabels,
    });
};

export default setLabels;
