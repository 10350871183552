import ProductType from '../../../types/ProductType';

const defaultProduct = {
  name: '',
  pluName: '',
  type: ProductType.Product,
  description: '',
  plu: '',
  price: 0,
  addonDescription: '',
  times: [],
  over18: false,
  show: true,
  nonDiscountable: false,
  involvesKitchen: false,
  soldOut: false,
  options: [],
  image: null,
  allergens: [],
  userLabels: [],
  reportingLabels: [],
};

export default defaultProduct;
