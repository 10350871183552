import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Button, Typography, Grid, makeStyles, Box,
} from '@material-ui/core';

import LaunchIcon from '@material-ui/icons/Launch';

import Spinner from '../../components/spinner/spinner';

import hasPermission from '../../helpers/has-permission';

import getSettings from '../../actions/get-settings';

import AppUrl from '../../types/AppUrl';
import ReduxState from '../../types/ReduxState';
import User from '../../types/User';
import Nullable from '../../types/Nullable';
import RoleRestrictedAction from '../../types/RoleRestrictedAction';
import SiteData from '../../types/SiteData';
import setupStripe from '../settings-page/helpers/setup-stripe';
import addStripeConnectActiveFlag from './helpers/addStripeConnectDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: 400,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(3, 1.5),
  },
}));

const StripeCompletePage = () => {
  const dispatch = useDispatch();
  const user = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));
  const settings = useSelector<ReduxState, Nullable<SiteData>>((state) => state.settings.get('SETTINGS'));
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [stripeSetupComplete, setStripeSetupComplete] = useState<boolean>(false);
  const classes = useStyles();

  const handleSetupStripeClick = async () => {
    if (!settings) return null;
    const url = await setupStripe(settings, null);
    return window.location.replace(url);
  };

  useEffect(() => {
    if (user != null) {
      dispatch(getSettings(user.site));
    }
  }, []);

  useEffect(() => {
    if (
      user != null
      && settings != null
      && settings.stripeConnect != null
    ) {
      addStripeConnectActiveFlag(
        user.site,
        settings.stripeConnect,
        setStripeSetupComplete,
        setIsLoading,
      );
    }
  }, [user, settings]);

  if (isLoading) return <Spinner />;

  return (
    <Grid
      className={classes.root}
      container
      direction="column"
    >
      <Typography
        variant="h6"
      >
        {stripeSetupComplete ? 'Stripe setup complete' : 'You still need to submit information to Stripe'}
      </Typography>
      <Box flexDirection="row">
        {!stripeSetupComplete && (
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            size="large"
            onClick={handleSetupStripeClick}
            disabled={!user || !hasPermission(RoleRestrictedAction.ConfigureStripe, user)}
            endIcon={<LaunchIcon />}
          >
            Go to Stripe
          </Button>
        )}
        <Button
          className={classes.button}
          color="primary"
          variant={stripeSetupComplete ? 'contained' : 'outlined'}
          size="large"
          component={Link}
          to={AppUrl.Home}
        >
          Home
        </Button>
      </Box>
    </Grid>
  );
};

export default StripeCompletePage;
