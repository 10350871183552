import Papa from 'papaparse';

import isDiscountCodes from '../../../helpers/isDiscountCodes';

const convertCsvData = (fileData, existingCodes) => {
  if (fileData == null) return null;

  const { data } = Papa.parse(fileData, { header: true });

  const mappedData = data.map(({ code, maxUsages, usages }) => ({
    code,
    maxUsages: maxUsages && maxUsages !== '' ? Number(maxUsages) : null,
    usages: usages && usages !== '' ? Number(usages) : 0,
  }));

  const isValid = isDiscountCodes(mappedData);

  const filteredData = mappedData.filter((d) => !existingCodes.includes(d.code));

  const duplicatedData = mappedData.filter((d) => existingCodes.includes(d.code));

  return {
    data: filteredData,
    numberOfCodes: isValid ? filteredData.length : null,
    numberOfDuplicates: isValid ? duplicatedData.length : null,
    isValid,
    validationError: !isValid ? 'CSV is not valid, please try again or contact support.' : null,
  };
};

export default convertCsvData;
