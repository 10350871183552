import React from 'react';
import { DataGrid, GridCellParams } from '@material-ui/data-grid';
import { Paper, makeStyles } from '@material-ui/core';

import formatMoney from '../../../helpers/formatMoney';
import formatNumber from '../../../helpers/formatNumber';

import SalesReportResult from '../types/SalesGraphsResult';
import SiteData from '../../../types/SiteData';
import Nullable from '../../../types/Nullable';

interface Props {
  paymentMethodsData: SalesReportResult['paymentMethodsData']
  siteData: Nullable<SiteData>
  isLoading: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: { marginTop: theme.spacing(3) },
  table: { border: 'none' },
}));

const PaymentMethodsTable = ({ paymentMethodsData, siteData, isLoading }: Props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.container}>
      <DataGrid
        className={classes.table}
        autoHeight
        rows={paymentMethodsData.map((row) => ({ id: row.name || '?', ...row }))}
        loading={isLoading}
        columns={[
          { field: 'name', headerName: 'Name', flex: 1 },
          {
            field: 'value',
            headerName: 'Total Value',
            flex: 1,
            renderCell: (params: GridCellParams) => formatMoney(
              params.row.value,
              siteData?.currency,
            ),
          },
          {
            field: 'count',
            headerName: 'Orders',
            flex: 1,
            renderCell: (params: GridCellParams) => formatNumber(params.row.count),
          },
        ]}
      />
    </Paper>
  );
};

export default PaymentMethodsTable;
