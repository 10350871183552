import AppUrl from '../types/AppUrl';
import Route from '../types/Route';
import UserRole from '../types/UserRole';
import {
  ADMIN_ROUTES,
  MANAGER_ROUTES,
  USER_ROUTES,
  RESTRICTED_ROUTES,
  SUPERUSER_ROUTES,
  ORDERS_ROUTES,
  SITE_SETTINGS_ROUTES,
} from '../constants/routes';
import User from '../types/User';

const getRoutes = (user: User): Route<AppUrl>[] => {
  const {
    site,
    admin,
  } = user;
  const userSiteConfig = user.sites.find((config) => config.site === site);

  const hasReportingLabels = userSiteConfig
    ? userSiteConfig.reportingLabels.length > 0
    : false;

  if (admin) return [...ADMIN_ROUTES, ...ORDERS_ROUTES, ...SITE_SETTINGS_ROUTES];

  switch (userSiteConfig?.role) {
    case UserRole.Manager:
      if (hasReportingLabels) return [...MANAGER_ROUTES, ...ORDERS_ROUTES];
      return [...MANAGER_ROUTES, ...ORDERS_ROUTES, ...SITE_SETTINGS_ROUTES];
    case UserRole.User:
      if (hasReportingLabels) return USER_ROUTES;
      return [...USER_ROUTES, ...ORDERS_ROUTES];
    case UserRole.Restricted:
      if (hasReportingLabels) return RESTRICTED_ROUTES;
      return [...RESTRICTED_ROUTES, ...ORDERS_ROUTES];
    case UserRole.SuperUser:
      return [...SUPERUSER_ROUTES, ...ORDERS_ROUTES, ...SITE_SETTINGS_ROUTES];
    default:
      throw new Error('User requires role');
  }
};

export default getRoutes;
