import * as Joi from 'joi';

import extractEnumValues from './extractEnumValues';

import SiteData from '../types/SiteData';
import Currency from '../types/Currency';
import Language from '../types/Language';
import Status from '../types/Status';
import { charityDonationSchema } from '../components/site-form/helpers/isValidCharityDonation';
import CustomerAuthProvider from '../types/CustomerAuthProvider';
import { processingFeeSchemeSchema } from '../containers/sites-page/helpers/isValidProcessingFeeScheme';

const isSiteData = (value: unknown): value is SiteData => {
  const schema = Joi.object({
    id: Joi.string().required(),
    active: Joi.bool().required(),
    address: Joi.object({
      latitude: Joi.number(),
      longitude: Joi.number(),
      line1: Joi.string().required(),
      line2: Joi.string().allow(''),
      city: Joi.string().required(),
      country: Joi.string().required(),
      postCode: Joi.string().required(),
    }),
    apiKey: Joi.string().required(),
    collection: Joi.array().items({
      firstCollection: Joi.string(),
      lastCollection: Joi.string(),
      leadTime: Joi.number(),
      day: Joi.number(),
    }).allow(null),
    cardTransactionWarningValue: Joi.string().allow(''),
    delivery: Joi.array().items({
      firstDelivery: Joi.string(),
      lastDelivery: Joi.string(),
      leadTime: Joi.number(),
      day: Joi.number(),
    }).allow(null),
    deliveryCharge: Joi.number().allow(''),
    deliveryDistance: Joi.number(),
    email: Joi.string().allow(''),
    hideFreeText: Joi.boolean(),
    highPricedAddonsFirst: Joi.boolean(),
    gratuitiesPLU: Joi.number().allow(null).allow(''),
    logo: Joi.string().allow(''),
    minimumDelivery: Joi.number(),
    name: Joi.string().required(),
    openingTimes: Joi.array().items({
      closed: Joi.boolean(),
      close: Joi.string(),
      kitchenOpen: Joi.string(),
      open: Joi.string(),
      kitchenClose: Joi.string(),
      day: Joi.number(),
    }),
    orderCompleteText: Joi.string().allow(''),
    phoneNumber: Joi.string().allow(''),
    privacyPolicyLink: Joi.string().allow(''),
    sheetId: Joi.string().allow(''),
    site: Joi.string().required(),
    slotCapacity: Joi.number(),
    slotTime: Joi.number(),
    stripeCharges: Joi.object({
      percentage: Joi.number(),
      transaction: Joi.number(),
    }),
    stripeConnect: Joi.string().allow(''),
    stripeConnectActive: Joi.boolean(),
    serviceCharge: Joi.object({
      percentage: Joi.number().strict().required(),
      value: Joi.number().strict().required(),
      plu: Joi.number().strict().required(),
      chargeName: Joi.string().required(),
      compulsory: Joi.boolean(),
    }).allow(null),
    termsLink: Joi.string().allow(''),
    theme: Joi.object({
      primary: Joi.string(),
    }),
    testMode: Joi.boolean(),
    waitingTimes: Joi.object({
      drinksWaitingTime: Joi.number(),
      foodWaitingTime: Joi.number(),
    }),
    welcomeMessage: Joi.string().allow(''),
    allowDiscountCodes: Joi.boolean(),
    notifications: Joi.boolean(),
    refundByItem: Joi.boolean(),
    language: Joi.string().valid(...extractEnumValues(Language)),
    currency: Joi.string().valid(...extractEnumValues(Currency)),
    readerSplashImage: Joi.string().allow(''),
    splashConfigId: Joi.string().allow(''),
    charityDonation: charityDonationSchema,
    spos: {
      orderStatusOnPrint: Joi.string().valid(...extractEnumValues(Status)).required(),
      consoleFeaturesEnabled: Joi.boolean().required(),
    },
    merchantOfRecord: Joi.string().allow(''),
    isUnprocessedOrdersMessageEnabled: Joi.boolean(),
    customersOptions: Joi.object({
      authProviders: Joi.array().items(Joi.string().valid(
        ...extractEnumValues(CustomerAuthProvider),
      )).required(),
      appFeatures: Joi.object({
        showSignUpOption: Joi.boolean().required(),
        populateCustomerName: Joi.boolean().required(),
      }).required(),
    }).required(),
    processingFeeScheme: processingFeeSchemeSchema,
    welcomeModal: Joi.object({
      hideOpeningTimes: Joi.boolean().required(),
      welcomeMessage: Joi.string().allow('').required(),
      termsLink: Joi.string().allow('').required(),
      privacyPolicyLink: Joi.string().allow('').required(),
    }).required(),
    giftCardOptions: Joi.object({
      allowPersonalMessage: Joi.boolean(),
      allowServiceCharge: Joi.boolean(),
      allowCharityDonation: Joi.boolean(),
      customMessage: Joi.string().allow(''),
      expiry: Joi.number(),
      prefixCode: Joi.string().required(),
    }).required(),
  }).unknown(true).required();
  const result = schema.validate(value, { allowUnknown: true });

  return result.error == null;
};

export default isSiteData;
