import React, { ChangeEvent, useState, useEffect } from 'react';
import firebase from 'firebase/app';
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Switch,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Spinner from '../../../components/spinner/spinner';
import initialiseSiteListener from '../../../helpers/initialiseSiteListener';
import SiteData from '../../../types/SiteData';
import User from '../../../types/User';
import Nullable from '../../../types/Nullable';
import ErrorDialog from '../../../components/ErrorDialog/ErrorDialog';

interface Props {
  authenticatedUser: User;
}

const useStyles = makeStyles((theme) => ({
  formCard: {
    marginBottom: theme.spacing(3),
  },
  cardTitle: {
    marginBottom: theme.spacing(1),
  },
  drawerButtonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  saveButton: {
    margin: theme.spacing(2, 2, 2, 2),
  },
}));

const POSOptions = ({ authenticatedUser }: Props) => {
  const classes = useStyles();
  const [settings, setSettings] = useState<Nullable<SiteData>>(null);
  const [error, setError] = useState<Nullable<Error>>(null);

  useEffect(() => {
    if (authenticatedUser != null) {
      initialiseSiteListener(authenticatedUser.site, setSettings, setError);
    }
  }, [authenticatedUser]);

  const handleTableNumberChange = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      if (settings == null) throw new Error('Missing site settings.');

      const { target: { checked } } = event;

      await firebase
        .firestore()
        .collection('sites')
        .doc(settings.id)
        .update({
          posCompulsoryTableNumber: checked,
        });
    } catch (err) {
      setError(err as Error);
    }
  };

  const handleOnlineSwitchChange = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      if (settings == null) throw new Error('Missing site settings.');

      const { target: { checked } } = event;

      await firebase
        .firestore()
        .collection('sites')
        .doc(settings.id)
        .update({
          isPosOnline: checked,
        });
    } catch (err) {
      setError(err as Error);
    }
  };

  const labelSwitchHandlerFactory = (
    labelId: string,
  ) => async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      if (settings == null) throw new Error('Missing site settings.');

      const { target: { checked } } = event;
      let updatedPosOfflineLabels = settings.posOfflineLabels || [];
      if (checked) {
        updatedPosOfflineLabels = updatedPosOfflineLabels.filter((id) => id !== labelId);
      } else {
        updatedPosOfflineLabels = [...updatedPosOfflineLabels, labelId];
      }

      await firebase
        .firestore()
        .collection('sites')
        .doc(settings.id)
        .update({
          posOfflineLabels: updatedPosOfflineLabels,
        });
    } catch (err) {
      setError(err as Error);
    }
  };

  const handleErrorOkClick = () => {
    setError(null);
  };

  if (!settings) return <Spinner />;

  return (
    <>
      <Card className={classes.formCard}>
        <CardContent>
          <Typography className={classes.cardTitle} variant="h6">
            Site Settings
          </Typography>
          <FormControlLabel
            label={`Compulsory ${settings?.locationDescriptor || 'Table Number'}`}
            control={(
              <Checkbox
                checked={settings.posCompulsoryTableNumber || false}
                onChange={handleTableNumberChange}
              />
            )}
          />
          <FormControlLabel
            label="Pos Online"
            control={(
              <Switch
                checked={settings.isPosOnline || false}
                onChange={handleOnlineSwitchChange}
              />
            )}
          />
        </CardContent>
      </Card>
      <Card className={classes.formCard}>
        <CardContent>
          <Typography className={classes.cardTitle} variant="h6">
            Reporting Label Settings
          </Typography>
          {settings.reportingLabels?.map((label) => (
            <FormControlLabel
              key={label.id}
              label={`${label.text} Online`}
              control={(
                <Switch
                  checked={(
                    settings.posOfflineLabels != null
                      ? !settings.posOfflineLabels.includes(label.id)
                      : true
                  )}
                  onChange={labelSwitchHandlerFactory(label.id)}
                />
              )}
            />
          ))}
        </CardContent>
      </Card>
      <ErrorDialog open={error != null} onOkButtonClick={handleErrorOkClick} />
    </>
  );
};

export default POSOptions;
