const debounce = <A extends Array<unknown>, R>(fn: (...args: A) => R, wait: number) => {
  let timeout: ReturnType<typeof setTimeout>;

  return (...args: A) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn(...args), wait);
  };
};

export default debounce;
