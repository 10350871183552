const generatePluNumber = (plusInUse: number[]) => {
  let plu = 1;

  while (plusInUse.includes(plu)) {
    plu += 1;
  }

  return plu;
};

export default generatePluNumber;
