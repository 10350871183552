import RefundReason from '../../../types/RefundReason';

const refundReasons = [
  { label: 'Duplicate', value: RefundReason.Duplicate },
  { label: 'Fraudulent', value: RefundReason.Fraudulent },
  { label: 'Requested by customer', value: RefundReason.RequestedByCustomer },
  { label: 'Other', value: RefundReason.Other },
];

export default refundReasons;
