import firebase from 'firebase/app';

const updateSite = (site, data) => firebase
  .firestore()
  .collection('sites')
  .where('site', '==', site)
  .get()
  .then((snapshots) => snapshots.forEach((snapshot) => firebase
    .firestore()
    .collection('sites')
    .doc(snapshot.id)
    .update(data)));

export default updateSite;
