import React, { ReactElement, useMemo } from 'react';

import {
  Typography, Grid, Link, makeStyles,
} from '@material-ui/core';

import Nullable from '../../types/Nullable';

const useStyles = makeStyles(() => ({
  field: {
    marginBottom: 5,
  },
  title: {
    marginRight: 5,
  },
  value: {
    marginRight: 5,
  },
}));

type Props = {
  title: string
  normalWeightTitleFont?: boolean
  info: Nullable<string | number>
  icon?: ReactElement
};

const InfoField = ({
  title,
  info,
  icon,
  normalWeightTitleFont = false,
}: Props) => {
  const classes = useStyles();

  const infoText = useMemo(() => {
    if (info == null) return '';
    if (typeof info !== 'string') return info;
    if (!info.includes('https') || !info.includes('http')) return info;

    return (
      <Link href={info} underline="always">
        {info || ''}
      </Link>
    );
  }, []);

  return (
    <Grid
      className={classes.field}
      container
      direction="row"
      alignItems="center"
    >
      <Typography
        className={classes.title}
        style={{ fontWeight: normalWeightTitleFont ? 'normal' : 'bold' }}
      >
        {`${title}:`}
      </Typography>
      <Typography className={classes.value}>{infoText}</Typography>
      {icon || null}
    </Grid>
  );
};

export default InfoField;
