import React, { ChangeEvent, useEffect, useState } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  DialogContentText,
  Box,
  TextField,
  Typography,
  makeStyles,
  LinearProgress,
  Grid,
} from '@material-ui/core';

import CheckBox from '../check-box/check-box';
import LabelPlacement from '../check-box/types/LabelPlacement';
import QuantitySelector from '../QuantitySelector/QuantitySelector';
import ErrorDialog from '../ErrorDialog/ErrorDialog';
import SnackBar from '../snack-bar/snack-bar';
import SimpleSelect from '../SimpleSelect/SimpleSelect';
import OutlinedTextField from '../outlined-text-field/outlined-text-field';

import formatMoney from '../../helpers/formatMoney';
import round from '../../helpers/round';

import refundReasons from '../../containers/OrderPage/constants/refundReasons';

import RefundReason from '../../types/RefundReason';
import Nullable from '../../types/Nullable';

interface Params {
  open: boolean;
  showSplitTipMessage: boolean;
  items: {
    name: string;
    cartId: string;
    price: number;
    discountedPrice: number;
    discountedSubTotal: number;
    quantity: number;
    quantityRefunded: number;
    tipValue: number;
    addOns: {
      name: string;
      plu: string;
      price: number;
      discountedPrice: number;
      quantity: number;
      quantityRefunded: number;
    }[];
    serviceCharge: boolean;
    tip: boolean;
    charityDonation: boolean;
    isGiftCard: boolean;
  }[];
  allowGiftCardRefund?: boolean
  paymentMethods: { id: string; label: string; amount: number; amountRefunded: number }[];
  onSubmit: (
    items: { cartId: string; quantity: number; addOns: { plu: string; quantity: number }[] }[],
    paymentMethods: { id: string; amount: string }[],
    reason: string,
    additionalInfo: string,
  ) => Promise<void>;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  itemRefundTotalText: { marginTop: theme.spacing(2) },
  heading: {
    margin: theme.spacing(3, 0, 4, 0),
  },
  paymentMethodBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '55%',
  },
  metadataFieldsBox: { marginTop: theme.spacing(2) },
}));

const RefundDialog = ({
  open,
  showSplitTipMessage,
  items,
  allowGiftCardRefund = false,
  paymentMethods,
  onSubmit,
  onClose,
}: Params) => {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Nullable<Error>>(null);
  const [snackOpen, setSnackOpen] = useState<boolean>(false);

  const [itemsToRefund, setItemsToRefund] = useState<{
    cartId: string;
    quantity: number;
    addOns: { plu: string; quantity: number }[]
  }[]>(items.map((item) => ({
    cartId: item.cartId,
    quantity: 0,
    addOns: item.addOns.map((addOn) => ({ plu: addOn.plu, quantity: 0 })),
  })));
  const [paymentMethodsToRefund, setPaymentMethodsToRefund] = useState<{
    id: string;
    amount: string;
  }[]>(paymentMethods.map((paymentMethod) => ({
    id: paymentMethod.id,
    amount: '0',
  })));
  const [refundReason, setRefundReason] = useState<RefundReason>(RefundReason.Other);
  const [additionalInfo, setAdditionalInfo] = useState<string>('');

  const handleClose = () => {
    setItemsToRefund(items.map((item) => ({
      cartId: item.cartId,
      quantity: 0,
      addOns: item.addOns.map((addOn) => ({ plu: addOn.plu, quantity: 0 })),
    })));
    setPaymentMethodsToRefund(paymentMethods.map((paymentMethod) => ({
      id: paymentMethod.id,
      amount: '0',
    })));
    setRefundReason(RefundReason.Other);
    setAdditionalInfo('');
    onClose();
  };

  const itemCheckboxChangeHandlerFactory = (
    cartId: string,
  ) => ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
    setItemsToRefund((currentItemsToRefund) => currentItemsToRefund.map((itemToRefund) => {
      if (itemToRefund.cartId === cartId) return { ...itemToRefund, quantity: checked ? 1 : 0 };
      return itemToRefund;
    }));
  };

  const addOnCheckboxChangeHandlerFactory = (
    cartId: string, plu: string,
  ) => ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
    setItemsToRefund((currentItemsToRefund) => currentItemsToRefund.map((itemToRefund) => {
      if (itemToRefund.cartId === cartId) {
        return {
          ...itemToRefund,
          addOns: itemToRefund.addOns
            .map((addOn) => (addOn.plu === plu ? { ...addOn, quantity: checked ? 1 : 0 } : addOn)),
        };
      }
      return itemToRefund;
    }));
  };

  const quantityChangeHandlerFactory = (cartId: string) => (
    newValue: number,
  ) => {
    setItemsToRefund((currentItemsToRefund) => currentItemsToRefund.map((itemToRefund) => {
      if (itemToRefund.cartId === cartId) return { ...itemToRefund, quantity: newValue };
      return itemToRefund;
    }));
  };

  const addOnQuantityChangeHandlerFactory = (cartId: string, plu: string) => (
    newValue: number,
  ) => {
    setItemsToRefund((currentItemsToRefund) => currentItemsToRefund.map((itemToRefund) => {
      if (itemToRefund.cartId === cartId) {
        return {
          ...itemToRefund,
          addOns: itemToRefund.addOns
            .map((addOn) => (addOn.plu === plu ? { ...addOn, quantity: newValue } : addOn)),
        };
      }
      return itemToRefund;
    }));
  };

  const methodAmountChangeHandlerFactory = (id: string) => (
    { target: { value } }: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPaymentMethodsToRefund((currentPaymentMethodsToRefund) => (
      currentPaymentMethodsToRefund.map((paymentMethodToRefund) => {
        if (paymentMethodToRefund.id === id) return { ...paymentMethodToRefund, amount: value };
        return paymentMethodToRefund;
      })
    ));
  };

  const handleProcessButtonClick = async () => {
    setLoading(true);
    try {
      await onSubmit(itemsToRefund, paymentMethodsToRefund, refundReason, additionalInfo);
      handleClose();
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  };

  const handleErrorOkClick = () => setError(null);

  const handleSnackClose = () => setSnackOpen(false);

  const itemsToRefundTotal = itemsToRefund.reduce((total, itemToRefund) => {
    const item = items.find(({ cartId }) => cartId === itemToRefund.cartId);
    if (item == null) return total;
    const addOnsRefundTotal = itemToRefund.addOns.reduce((addOnTotal, addOnToRefund) => {
      const addOn = item.addOns.find(({ plu }) => plu === addOnToRefund.plu);
      if (addOn == null) return addOnTotal;
      return addOnTotal + (addOnToRefund.quantity * addOn.discountedPrice);
    }, 0);

    if (itemToRefund.quantity === 0 && addOnsRefundTotal === 0) {
      return total;
    }
    const itemRefundTotal = (itemToRefund.quantity * item.discountedPrice);
    const tipShareToRefund = (
      (addOnsRefundTotal + itemRefundTotal) / (item.discountedSubTotal * item.quantity)
    ) * item.tipValue;
    return round(
      (total + addOnsRefundTotal + itemRefundTotal + tipShareToRefund),
    );
  }, 0);

  useEffect(() => {
    if (paymentMethods.length === 1) {
      setPaymentMethodsToRefund((currentPaymentMethodsToRefund) => (
        [{
          id: currentPaymentMethodsToRefund[0].id,
          amount: String(itemsToRefundTotal),
        }]
      ));
    }
  }, [itemsToRefundTotal]);

  const paymentMethodsToRefundTotal = paymentMethodsToRefund
    .reduce((acc, paymentMethodToRefund) => (
      acc + round(Number(paymentMethodToRefund.amount))
    ), 0);

  const isSelectionValid = itemsToRefundTotal > 0
    && (itemsToRefundTotal === paymentMethodsToRefundTotal);

  const isGiftCardIncludedInOrder = items.some((item) => item.isGiftCard);

  const checkBoxLabel = (itemOrAddon: {
    name: string,
    quantity: number
    quantityRefunded: number
  }) => {
    const {
      name,
      quantity,
      quantityRefunded,
    } = itemOrAddon;
    let label = name;
    if (quantity === quantityRefunded) {
      label += ' (Already Refunded)';
    } else if (quantityRefunded > 0) {
      label += ` (${quantityRefunded} Already Refunded)`;
    }
    return label;
  };

  return (
    <>
      <Dialog open={open}>
        {loading && <LinearProgress />}
        <DialogTitle>
          Process Refund
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Refunds can take 5-10 days to appear on the customer&apos;s bank statement
          </DialogContentText>
          {showSplitTipMessage && (
            <DialogContentText>
              Any tips have been shared between vendors in ratio with their sales on this order.
              Tip amounts will be refunded alongside the relevant items where possible.
            </DialogContentText>
          )}
          <Box className={classes.metadataFieldsBox}>
            <SimpleSelect
              label="Refund Reason"
              value={refundReason}
              items={refundReasons}
              onChange={setRefundReason}
              labelWidth={110}
            />
            <OutlinedTextField
              id="outlined-name"
              classOption="flex"
              label="Additional Information"
              value={additionalInfo}
              onChange={(event) => setAdditionalInfo(event.target.value)}
            />
          </Box>
          {items
            .map((item) => {
              const itemToRefund = itemsToRefund.find(({ cartId }) => cartId === item.cartId);
              if (itemToRefund == null) return <></>;
              return (
                <Grid key={item.cartId}>
                  <CheckBox
                    label={checkBoxLabel(item)}
                    checked={itemToRefund.quantity > 0}
                    onChange={itemCheckboxChangeHandlerFactory(item.cartId)}
                    labelPlacement={LabelPlacement.End}
                    disabled={(item.quantity <= item.quantityRefunded)
                      || (item.isGiftCard && !allowGiftCardRefund)}
                  />
                  {!(item.tip || item.serviceCharge || item.charityDonation) && (
                    <QuantitySelector
                      value={itemToRefund.quantity}
                      maxValue={item.quantity - item.quantityRefunded}
                      onChange={quantityChangeHandlerFactory(item.cartId)}
                      disabled={itemToRefund.quantity === 0}
                    />
                  )}
                  {item.addOns.map((addOn) => {
                    const addOnToRefund = itemToRefund.addOns.find(({ plu }) => plu === addOn.plu);
                    if (addOnToRefund == null) return <></>;
                    return (
                      <Grid key={`${item.cartId}-${addOn.plu}`}>
                        <CheckBox
                          label={checkBoxLabel(addOn)}
                          checked={addOnToRefund.quantity > 0}
                          onChange={addOnCheckboxChangeHandlerFactory(item.cartId, addOn.plu)}
                          labelPlacement={LabelPlacement.End}
                          disabled={addOn.quantity <= addOn.quantityRefunded}
                        />
                        <QuantitySelector
                          value={addOnToRefund.quantity}
                          maxValue={item.quantity - item.quantityRefunded}
                          onChange={addOnQuantityChangeHandlerFactory(item.cartId, addOn.plu)}
                          disabled={addOnToRefund.quantity === 0}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              );
            })}
          <DialogContentText className={classes.itemRefundTotalText}>
            {`The total to be refunded is ${formatMoney(itemsToRefundTotal)}`}
          </DialogContentText>
          {isGiftCardIncludedInOrder && !allowGiftCardRefund && (
            <DialogContentText className={classes.itemRefundTotalText}>
              (*) Gift cards can only be refunded via the &apos;Gift Card Management&apos; page
            </DialogContentText>
          )}
          <Typography className={classes.heading} variant="h6">Payment Methods to Refund</Typography>
          {paymentMethods.map((paymentMethod) => {
            const paymentMethodToRefund = paymentMethodsToRefund
              .find(({ id }) => paymentMethod.id === id);
            if (paymentMethodToRefund == null) return <></>;
            return (
              <Grid key={paymentMethod.id}>
                <Typography>{paymentMethod.label}</Typography>
                <Box className={classes.paymentMethodBox}>
                  <TextField
                    value={paymentMethodToRefund.amount}
                    onChange={methodAmountChangeHandlerFactory(paymentMethod.id)}
                  />
                  <Typography>
                    {`out of ${formatMoney(paymentMethod.amount - paymentMethod.amountRefunded)}`}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="outlined"
              disabled={loading}
            >
              Close
            </Button>
            <Button
              onClick={handleProcessButtonClick}
              variant="outlined"
              color="primary"
              disabled={!isSelectionValid || loading}
            >
              Proceed
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <ErrorDialog
        open={error != null}
        message={error?.message}
        onOkButtonClick={handleErrorOkClick}
      />
      <SnackBar
        open={snackOpen}
        onClose={handleSnackClose}
        message="Refund successful - order will be updated soon."
      />
    </>
  );
};

export default RefundDialog;
