import PeaziNotification from '../../../types/PeaziNotification';

const isNotification = (value: any): value is PeaziNotification => {
  if (value == null || typeof value !== 'object') return false;
  if (value.title == null || typeof value.title !== 'string') return false;
  if (value.message == null || typeof value.message !== 'string') return false;
  if (value.type == null || typeof value.type !== 'string') return false;
  return true;
};

export default isNotification;
