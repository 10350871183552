import {
  Assessment,
  Category,
  Dashboard,
  Fastfood,
  Home,
  MoneyOff,
  Undo,
  Settings,
  SettingsApplications,
  RestaurantMenu,
  VideoLabel,
  TurnedIn,
  Group,
  Print,
  Tablet,
  Storefront,
  Contacts,
  Message,
  CardGiftcard,
} from '@material-ui/icons';
import AppUrl from '../types/AppUrl';
import AuthUrl from '../types/AuthUrl';
import Route from '../types/Route';
import RouteChip from '../types/RouteChip';

export const AUTH_ROUTES: Route<AuthUrl>[] = [
  {
    url: AuthUrl.Login,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AuthUrl.ForgotPassword,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
];

export const ORDERS_ROUTES: Route<AppUrl>[] = [
  {
    url: AppUrl.SiteOrders,
    icon: RestaurantMenu,
    label: 'Orders',
    showOnHome: true,
    showOnDrawer: true,
    showOnAdmin: false,
  },
  {
    url: AppUrl.SiteUnpaidOrders,
    icon: RestaurantMenu,
    label: 'Unpaid Orders',
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.RefundedOrders,
    icon: Undo,
    label: 'Refunded Orders',
    showOnHome: true,
    showOnDrawer: true,
    showOnAdmin: false,
  },
  {
    url: AppUrl.SiteUnprocessedOrders,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.Order,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
];

export const SITE_SETTINGS_ROUTES: Route<AppUrl>[] = [
  {
    url: AppUrl.Settings,
    icon: Settings,
    label: 'Settings',
    showOnHome: true,
    showOnDrawer: true,
    showOnAdmin: false,
  },
];

export const RESTRICTED_ROUTES: Route<AppUrl>[] = [
  {
    url: AppUrl.Home,
    icon: Home,
    label: 'Home',
    showOnHome: false,
    showOnDrawer: true,
    showOnAdmin: false,
  },
  {
    url: AppUrl.Profile,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.SiteMap,
    icon: Category,
    label: 'Categories',
    showOnHome: true,
    showOnDrawer: true,
    showOnAdmin: false,
  },
  {
    url: AppUrl.Category,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.SubCategory,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.Products,
    icon: Fastfood,
    label: 'Products',
    showOnHome: true,
    showOnDrawer: true,
    showOnAdmin: false,
  },
  {
    url: AppUrl.ViewProduct,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.Slots,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.SlotTimes,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.DispenseScreen,
    icon: VideoLabel,
    label: 'Dispense Screen',
    showOnHome: true,
    showOnDrawer: true,
    showOnAdmin: false,
  },
  {
    url: AppUrl.CollectionScreen,
    icon: VideoLabel,
    label: 'Collection Screen',
    showOnHome: true,
    showOnDrawer: true,
    showOnAdmin: false,
    chip: RouteChip.Beta,
  },
  {
    url: AppUrl.Notifications,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.Notification,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.ReportingLabelManagement,
    icon: TurnedIn,
    label: 'Reporting Labels',
    showOnHome: true,
    showOnDrawer: true,
    showOnAdmin: false,
  },
];

export const USER_ROUTES: Route<AppUrl>[] = [
  ...RESTRICTED_ROUTES,
  {
    url: AppUrl.QuickSettings,
    icon: SettingsApplications,
    label: 'Quick Settings',
    showOnHome: true,
    showOnDrawer: true,
    showOnAdmin: false,
  },
  {
    url: AppUrl.AddSubCategory,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.EditSubCategory,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.AddProduct,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.EditProduct,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
];

export const MANAGER_ROUTES: Route<AppUrl>[] = [
  ...USER_ROUTES,
  {
    url: AppUrl.CloudPrinters,
    icon: Print,
    label: 'Cloud Printers',
    showOnHome: true,
    showOnDrawer: true,
    showOnAdmin: false,
  },
  {
    url: AppUrl.StripeSetup,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.StripeComplete,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.Discounts,
    icon: MoneyOff,
    label: 'Discounts',
    showOnHome: true,
    showOnDrawer: true,
    showOnAdmin: false,
  },
  {
    url: AppUrl.AddDiscountPage,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.EditDiscountPage,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.Reports,
    icon: Assessment,
    label: 'Reports',
    showOnHome: true,
    showOnDrawer: true,
    showOnAdmin: false,
  },
];

export const SUPERUSER_ROUTES: Route<AppUrl>[] = [
  ...MANAGER_ROUTES,
  {
    url: AppUrl.SiteDashboard,
    icon: Dashboard,
    label: 'Dashboard',
    showOnHome: true,
    showOnDrawer: true,
    showOnAdmin: false,
  },
  {
    url: AppUrl.ManageSiteUsers,
    icon: Group,
    label: 'Users',
    showOnHome: true,
    showOnDrawer: true,
    showOnAdmin: false,
  },
  {
    url: AppUrl.AddSiteUser,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.EditSiteUser,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.POSHome,
    icon: Tablet,
    label: 'Peazi POS',
    showOnHome: true,
    showOnDrawer: true,
    showOnAdmin: false,
  },
  {
    url: AppUrl.AddPOSUser,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.EditPosUser,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.AddPosAdditionalPaymentType,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.EditPosAdditionalPaymentType,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.POSLocation,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.Kiosk,
    icon: Storefront,
    label: 'Kiosk',
    showOnHome: true,
    showOnDrawer: true,
    showOnAdmin: false,
    chip: RouteChip.Beta,
  },
  {
    url: AppUrl.Customers,
    icon: Contacts,
    label: 'Customers',
    showOnHome: true,
    showOnDrawer: true,
    showOnAdmin: false,
    chip: RouteChip.Beta,
  },
  {
    url: AppUrl.Customer,
    label: null,
    icon: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.GiftCards,
    icon: CardGiftcard,
    label: 'Gift Cards',
    showOnHome: true,
    showOnDrawer: true,
    showOnAdmin: false,
    chip: RouteChip.Beta,
  },
  {
    url: AppUrl.AddGiftCardProduct,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.EditGiftCardProduct,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.GiftCard,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
];

export const ADMIN_ROUTES: Route<AppUrl>[] = [
  ...SUPERUSER_ROUTES,
  {
    url: AppUrl.Admin,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.AdminDashbaord,
    icon: Dashboard,
    label: 'Dashboard',
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: true,
  },
  {
    url: AppUrl.Orders,
    icon: RestaurantMenu,
    label: 'All Orders',
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: true,
  },
  {
    url: AppUrl.UnprocessedOrders,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.UnpaidOrders,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.Sites,
    icon: Settings,
    label: 'Peazi Sites',
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: true,
  },
  {
    url: AppUrl.CreateSite,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.Users,
    icon: Group,
    label: 'Peazi Users',
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: true,
  },
  {
    url: AppUrl.AddUser,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.EditUser,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.UploadProducts,
    icon: null,
    label: null,
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: false,
  },
  {
    url: AppUrl.TextMessages,
    icon: Message,
    label: 'Text Messages',
    showOnHome: false,
    showOnDrawer: false,
    showOnAdmin: true,
  },
];
