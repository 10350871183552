import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridRowData,
} from '@material-ui/data-grid';
import {
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import getUsers from '../../../actions/get-users';

import Spinner from '../../../components/spinner/spinner';
import Toolbar from './Toolbar';
import ConfirmationDialog from '../../../components/confirmation-dialog/confirmation-dialog';

import request from '../../../helpers/request';
import isNotNullOrUndefined from '../../../helpers/is-not-null-or-undefined';
import resetUserAttempts from '../../../helpers/resetUserAttempts';

import Nullable from '../../../types/Nullable';
import User from '../../../types/User';
import AppUrl from '../../../types/AppUrl';
import SiteData from '../../../types/SiteData';
import UserSiteConfig from '../../../types/UserSiteConfig';

interface Props {
  authenticatedUser: Nullable<User>;
  isUsersLoading: boolean;
  users: User[];
  settings: SiteData;
}

const POSUsersTable: React.FC<Props> = ({
  authenticatedUser,
  isUsersLoading,
  users,
  settings,
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<GridRowData | null>(null);
  const userSiteConfig = useMemo(
    () => authenticatedUser?.sites.find((siteConfig) => siteConfig.site === authenticatedUser.site),
    [authenticatedUser],
  );

  const rows: GridRowData[] = useMemo(() => {
    if (authenticatedUser == null) return [];
    return users.map((user) => {
      const config = user.sites.find(({ site }) => site === authenticatedUser.site);
      if (config == null || config.POSUser !== true) return null;
      const { reportingLabels: [labelId] } = config;
      const reportingLabel = settings.reportingLabels?.find(({ id }) => labelId === id);
      return {
        id: user.id,
        name: user.name,
        posPin: config.POSPin,
        loginAttempts: config.loginAttempts || 0,
        reportingLabel: reportingLabel != null ? reportingLabel.text : '',
      };
    }).filter(isNotNullOrUndefined);
  }, [users, authenticatedUser]);

  const userToUpdate = useCallback((uid: string) => {
    const updateUser = users.filter((user) => user.id === uid)[0];
    const updateSitesArrayOnUser = updateUser.sites.map((siteConfig: UserSiteConfig) => {
      if (userSiteConfig && siteConfig.site !== userSiteConfig.site) return siteConfig;

      return {
        ...siteConfig,
        POSUser: false,
        POSPin: '',
      };
    });
    updateUser.sites = updateSitesArrayOnUser;
    return updateUser;
  }, [selectedUser]);

  const handleDeleteUser = async () => {
    setLoading(true);
    try {
      if (selectedUser) {
        const {
          name,
          email,
          admin,
          sites,
        } = userToUpdate(selectedUser.id);
        await request(
          'updateUser',
          'POST',
          {
            uid: selectedUser.id,
            name,
            email,
            admin,
            sites,
          },
        );
        dispatch(getUsers());
        setLoading(false);
        setOpenDeleteUserDialog(false);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const columns: GridColumns = useMemo(() => [
    { headerName: 'Name', field: 'name', flex: 2 },
    {
      headerName: 'Reporting Label',
      flex: 2,
      field: 'reportingLabel',
    },
    {
      headerName: 'PIN',
      field: 'posPin',
      flex: 2,
      renderCell: () => <Typography>****</Typography>,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Grid>
          <Tooltip title="Reset user attempts">
            <IconButton
              onClick={() => {
                const updateUser = users.filter((user) => user.id === params.row.id)[0];
                setLoading(true);

                return resetUserAttempts(updateUser)
                  .then(() => {
                    dispatch(getUsers());
                    setLoading(false);
                  })
                  .catch(() => {
                    setLoading(false);
                  });
              }}
              color="inherit"
              disabled={params.row.loginAttempts === 0}
            >
              <RotateLeftIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit user">
            <IconButton
              onClick={() => {
                history.push(`/pos/user/${params.row.id}`);
              }}
              color="inherit"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Remove user">
            <IconButton
              onClick={() => {
                setOpenDeleteUserDialog(true);
                setSelectedUser(params.row);
              }}
              color="inherit"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ),
    },
  ], []);

  const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchText('');
  };

  if (isUsersLoading || loading) return <Spinner />;

  return (
    <>
      <DataGrid
        components={{ Toolbar }}
        columns={columns}
        rows={rows}
        autoHeight
        pageSize={10}
        rowsPerPageOptions={[10]}
        componentsProps={{
          toolbar: {
            value: searchText,
            onSearchTextChange: handleSearchTextChange,
            onSearchTextClear: handleClearSearch,
            onButtonClick: () => {
              history.push(AppUrl.AddPOSUser);
            },
            buttonTitle: 'Add user',
          },
        }}
      />
      <ConfirmationDialog
        title="Remove user from POS"
        message={`Are you sure you want to remove ${selectedUser?.name || ''} as a POS user?`}
        open={openDeleteUserDialog}
        onSave={handleDeleteUser}
        onClose={() => setOpenDeleteUserDialog(false)}
      />
    </>
  );
};

export default POSUsersTable;
