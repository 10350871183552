import React, {
  useCallback, useMemo, useState, useEffect,
} from 'react';

import {
  Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText,
} from '@material-ui/core';

import OutlinedTextField from '../../../components/outlined-text-field/outlined-text-field';
import OutlinedButton from '../../../components/outlined-button/outlined-button';
import SimpleSelect from '../../../components/SimpleSelect/SimpleSelect';
import statusOptions from '../../../components/site-form/constants/statusOptions';

import CloudPrinterData from '../../../types/CloudPrinterData';
import Label from '../../../types/Label';
import Nullable from '../../../types/Nullable';
import Status from '../../../types/Status';
import SiteData from '../../../types/SiteData';

type Props = {
  open: boolean
  selectedCloudPrinter: Nullable<CloudPrinterData>
  onSave: (cloudPrinter: CloudPrinterData) => void
  onClose: () => void
  cloudPrinters: CloudPrinterData[]
  labels: Label[]
  labelRequired: boolean
  settings: Nullable<SiteData>
};

const CloudPrinterDialog = ({
  open, selectedCloudPrinter, onSave, onClose, cloudPrinters, labels, labelRequired, settings,
}: Props) => {
  const [id, setId] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [reportingLabels, setReportingLabels] = useState<string[]>([]);
  const [numberOfCopies, setNumberOfCopies] = useState<number>(1);
  const [statusOnPrint, setStatusOnPrint] = useState<Status>(Status.Complete);

  const isLabelAvailable = useMemo(() => {
    const [selectedLabelId] = reportingLabels;
    if (
      selectedCloudPrinter != null
      && selectedLabelId === selectedCloudPrinter.reportingLabels[0]
    ) return true;
    if (selectedLabelId == null) return true;
    return !cloudPrinters
      .map(({ reportingLabels: [labelId] }) => labelId)
      .includes(selectedLabelId);
  }, [reportingLabels, cloudPrinters]);

  useEffect(() => {
    if (selectedCloudPrinter) {
      setId(selectedCloudPrinter.id);
      setName(selectedCloudPrinter.name);
      setReportingLabels(selectedCloudPrinter.reportingLabels);
      setNumberOfCopies(selectedCloudPrinter.numberOfCopies || 1);
      setStatusOnPrint(selectedCloudPrinter.statusOnPrint || Status.Complete);
    } else {
      setId('');
      setName('');
      setReportingLabels([]);
      setNumberOfCopies(1);
      setStatusOnPrint(Status.Complete);
    }
  }, [selectedCloudPrinter]);

  const resetValues = useCallback(() => {
    setId('');
    setName('');
    setReportingLabels([]);
  }, [setId, setName]);

  const handleSave = useCallback(() => {
    onSave({
      id, name, reportingLabels, numberOfCopies, statusOnPrint,
    });
  }, [onSave, id, name, reportingLabels, numberOfCopies, statusOnPrint]);

  const handleClose = useCallback(() => {
    resetValues();
    onClose();
  }, [resetValues, onClose]);

  const handleIdChange = useCallback((event) => setId(event.target.value), [setId]);

  const handleNameChange = useCallback((event) => setName(event.target.value), [setName]);

  const handleLabelChange = useCallback((value) => {
    if (value === '') {
      setReportingLabels([]);
    } else {
      setReportingLabels([value]);
    }
  }, [setReportingLabels]);

  const handleCopiesChange = useCallback((value) => setNumberOfCopies(value), [setNumberOfCopies]);

  const handleOnPrintChange = useCallback((value) => setStatusOnPrint(value), [setStatusOnPrint]);

  const labelsValue = useMemo(() => {
    if (reportingLabels.length === 0) return '';
    return reportingLabels[0];
  }, [reportingLabels]);

  const isDisabled = useMemo(() => (id.length === 0
    || !isLabelAvailable
    || name.length === 0)
    || (
      labelRequired
      && labelsValue.length === 0
    ), [id, name, isLabelAvailable, labelsValue, labelRequired]);

  const labelSelections = useMemo(() => labels
    .map((label) => ({ label: label.text, value: label.id })), [labels]);

  const copiesSelections = useMemo(() => [1, 2, 3, 4, 5, 6, 7, 8, 9]
    .map((num) => ({ label: num.toString(), value: num })), [labels]);

  const statusSelections = useMemo(() => {
    const availableOptions = statusOptions
      .filter((option) => settings?.orderFlow?.statusBoard?.includes(option.id))
      .map((status) => (
        { label: status.title, value: status.id }
      ));
    availableOptions.push({ label: 'Complete', value: Status.Complete });
    return availableOptions;
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle
        id="form-dialog-title"
      >
        {`${selectedCloudPrinter != null ? 'Edit' : 'Add'} Cloud Printer`}
      </DialogTitle>
      <DialogContent>
        <OutlinedTextField
          id="outlined-id"
          classOption="flex"
          label="Printer ID"
          value={id}
          onChange={handleIdChange}
          helperText="ID needs to be in the example format - N12345J123456"
          required
        />
        <OutlinedTextField
          id="outlined-name"
          classOption="flex"
          label="Printer Name"
          value={name}
          onChange={handleNameChange}
          required
        />
        <SimpleSelect
          label="Reporting Label"
          value={labelsValue}
          error={!isLabelAvailable}
          items={labelSelections}
          onChange={handleLabelChange}
          labelWidth={100}
          allowEmpty={!labelRequired}
        />
        <SimpleSelect
          label="Status of order when printed"
          value={statusOnPrint}
          items={statusSelections}
          onChange={handleOnPrintChange}
          labelWidth={210}
          allowEmpty={false}
        />
        {!isLabelAvailable && (
          <DialogContentText color="error">
            A Cloud Printer is already assigned to this Reporting Label
          </DialogContentText>
        )}
        <SimpleSelect
          label="No. of Copies"
          value={numberOfCopies}
          items={copiesSelections}
          onChange={handleCopiesChange}
          labelWidth={100}
          allowEmpty={false}
        />
      </DialogContent>
      <DialogActions>
        <OutlinedButton
          label="Cancel"
          color="secondary"
          onClick={handleClose}
        />
        <OutlinedButton
          label="Save"
          color="primary"
          onClick={handleSave}
          disabled={isDisabled}
        />
      </DialogActions>
    </Dialog>
  );
};

export default CloudPrinterDialog;
