import { Map } from 'immutable';
import {
  GET_NOTIFICATIONS_START,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_ERROR,
} from '../constants/actions';
import ReduxAction from '../types/ReduxAction';
import ReduxState from '../types/ReduxState';

const initialStateMap: ReduxState['notifications'] = Map();

const initialState = initialStateMap
  .set('IS_NOTIFICATIONS_LOADING', false)
  .set('ACTIVE_NOTIFICATIONS', [])
  .set('NOTIFICATIONS', [])
  .set('NOTIFICATIONS_LOADING_ERROR', '');

export default (
  state: ReduxState['notifications'] = initialState,
  action: ReduxAction,
) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_START:
      return state
        .set('IS_NOTIFICATIONS_LOADING', true)
        .set('ACTIVE_NOTIFICATIONS', [])
        .set('NOTIFICATIONS', [])
        .set('NOTIFICATIONS_LOADING_ERROR', '');
    case GET_NOTIFICATIONS_SUCCESS:
      return state
        .set('IS_NOTIFICATIONS_LOADING', false)
        .set('ACTIVE_NOTIFICATIONS', action.activeNotifications)
        .set('NOTIFICATIONS', action.notifications);
    case GET_NOTIFICATIONS_ERROR:
      return state
        .set('IS_NOTIFICATIONS_LOADING', false)
        .set('NOTIFICATIONS_LOADING_ERROR', action.message);
    default:
      return state;
  }
};
