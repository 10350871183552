import { Map } from 'immutable';
import {
  GET_UNPROCESSED_ORDERS_START,
  GET_UNPROCESSED_ORDERS_SUCCESS,
  GET_UNPROCESSED_ORDERS_ERROR,
} from '../constants/actions';

import ReduxAction from '../types/ReduxAction';
import ReduxState from '../types/ReduxState';

const initialStateMap: ReduxState['unprocessedOrders'] = Map();

const initialState = initialStateMap
  .set('IS_UNPROCESSED_ORDERS_LOADING', false)
  .set('NUMBER_OF_UNPROCESSED_ORDERS', 0)
  .set('UNPROCESSED_ORDERS', [])
  .set('UNPROCESSED_ORDERS_LOADING_ERROR', '');

export default (
  state: ReduxState['unprocessedOrders'] = initialState,
  action: ReduxAction,
) => {
  switch (action.type) {
    case GET_UNPROCESSED_ORDERS_START:
      return state
        .set('IS_UNPROCESSED_ORDERS_LOADING', true)
        .set('UNPROCESSED_ORDERS', [])
        .set('NUMBER_OF_UNPROCESSED_ORDERS', 0)
        .set('UNPROCESSED_ORDERS_LOADING_ERROR', '');
    case GET_UNPROCESSED_ORDERS_SUCCESS:
      return state
        .set('IS_UNPROCESSED_ORDERS_LOADING', false)
        .set('UNPROCESSED_ORDERS', action.unprocessedOrders)
        .set('NUMBER_OF_UNPROCESSED_ORDERS', action.numberOfUnprocessedOrders);
    case GET_UNPROCESSED_ORDERS_ERROR:
      return state
        .set('IS_UNPROCESSED_ORDERS_LOADING', false)
        .set('UNPROCESSED_ORDERS_LOADING_ERROR', action.message);
    default:
      return state;
  }
};
