import isCollectionTime from './isCollectionTime';

import CollectionTime from '../../../types/CollectionTime';
import DeliveryTime from '../../../types/DeliveryTime';

const getFirstValue = (time: CollectionTime | DeliveryTime): string => {
  if (isCollectionTime(time)) {
    return time.firstCollection;
  }

  return time.firstDelivery;
};

export default getFirstValue;
