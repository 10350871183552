import React, { ChangeEvent, useState, useEffect } from 'react';
import firebase from 'firebase/app';
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Switch,
  Typography,
  makeStyles,
} from '@material-ui/core';

import Spinner from '../../../components/spinner/spinner';
import ErrorDialog from '../../../components/ErrorDialog/ErrorDialog';
import SimpleSelect from '../../../components/SimpleSelect/SimpleSelect';

import initialiseSiteListener from '../../../helpers/initialiseSiteListener';

import SiteData from '../../../types/SiteData';
import User from '../../../types/User';
import Nullable from '../../../types/Nullable';
import CollectKioskCustomerName from '../../../types/CollectKioskCustomerName';
import KioskOrderingType from '../../../types/KioskOrderingType';

interface Props {
  authenticatedUser: User;
}

const useStyles = makeStyles((theme) => ({
  formCard: {
    marginBottom: theme.spacing(3),
  },
  cardTitle: {
    marginBottom: theme.spacing(2),
  },
  drawerButtonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  saveButton: {
    margin: theme.spacing(2, 2, 2, 2),
  },
}));

const KioskOptions = ({ authenticatedUser }: Props) => {
  const classes = useStyles();
  const [settings, setSettings] = useState<Nullable<SiteData>>(null);
  const [error, setError] = useState<Nullable<Error>>(null);

  useEffect(() => {
    if (authenticatedUser != null) {
      initialiseSiteListener(authenticatedUser.site, setSettings, setError);
    }
  }, [authenticatedUser]);

  const handleNameChange = async (value: CollectKioskCustomerName) => {
    try {
      if (settings == null) throw new Error('Missing site settings.');

      await firebase
        .firestore()
        .collection('sites')
        .doc(settings.id)
        .update({ collectKioskCustomerName: value });
    } catch (err) {
      setError(err as Error);
    }
  };

  const handleOrderingTypeChange = async (value: KioskOrderingType) => {
    try {
      if (settings == null) throw new Error('Missing site settings.');

      await firebase
        .firestore()
        .collection('sites')
        .doc(settings.id)
        .update({ kioskOrderingType: value });
    } catch (err) {
      setError(err as Error);
    }
  };

  const handleTableNumberChange = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      if (settings == null) throw new Error('Missing site settings.');

      const { target: { checked } } = event;

      await firebase
        .firestore()
        .collection('sites')
        .doc(settings.id)
        .update({ kioskCompulsoryTableNumber: checked });
    } catch (err) {
      setError(err as Error);
    }
  };

  const handleOnlineSwitchChange = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      if (settings == null) throw new Error('Missing site settings.');

      const { target: { checked } } = event;

      await firebase
        .firestore()
        .collection('sites')
        .doc(settings.id)
        .update({ isKioskOnline: checked });
    } catch (err) {
      setError(err as Error);
    }
  };

  const handleErrorOkClick = () => {
    setError(null);
  };

  if (!settings) return <Spinner />;

  return (
    <>
      <Card className={classes.formCard}>
        <CardContent>
          <Typography className={classes.cardTitle} variant="h6">
            Site Settings
          </Typography>
          <SimpleSelect
            label="Kiosk Ordering Type"
            items={[
              { label: 'Only for Takeaway', value: KioskOrderingType.OnlyTakeaway },
              { label: 'Only for Eat In', value: KioskOrderingType.OnlyEatIn },
              { label: 'Both', value: KioskOrderingType.Both },
            ]}
            value={settings.kioskOrderingType || KioskOrderingType.OnlyTakeaway}
            onChange={handleOrderingTypeChange}
            labelWidth={163}
            allowEmpty={false}
          />
          <SimpleSelect
            label="Collect Customer Name"
            items={[
              { label: 'Never', value: CollectKioskCustomerName.Never },
              { label: 'Only for Takeaway', value: CollectKioskCustomerName.OnlyTakeaway },
              { label: 'Always', value: CollectKioskCustomerName.Always },
            ]}
            value={settings.collectKioskCustomerName || CollectKioskCustomerName.Never}
            onChange={handleNameChange}
            labelWidth={163}
            allowEmpty={false}
          />
          <FormControlLabel
            label={`Compulsory ${settings?.locationDescriptor || 'Table Number'}`}
            control={(
              <Checkbox
                checked={settings.kioskCompulsoryTableNumber || false}
                onChange={handleTableNumberChange}
              />
            )}
          />
          <FormControlLabel
            label="Kiosk Online"
            control={(
              <Switch
                checked={settings.isKioskOnline || false}
                onChange={handleOnlineSwitchChange}
              />
            )}
          />
        </CardContent>
      </Card>
      <ErrorDialog open={error != null} onOkButtonClick={handleErrorOkClick} />
    </>
  );
};

export default KioskOptions;
