import React from 'react';

import {
  Grid, FormControlLabel, Checkbox, makeStyles,
} from '@material-ui/core';

import LabelPlacement from './types/LabelPlacement';

const useStyles = makeStyles(() => ({
  checkbox: {
    marginBottom: 20,
  },
}));

type Props = {
  label: string
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  labelPlacement?: LabelPlacement
  disabled?: boolean
};

const CheckBox = ({
  label,
  checked,
  onChange,
  labelPlacement,
  disabled = false,
}: Props) => {
  const classes = useStyles();
  return (
    <Grid className={classes.checkbox}>
      <FormControlLabel
        control={(
          <Checkbox
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            value="primary"
          />
        )}
        label={label}
        labelPlacement={labelPlacement || LabelPlacement.Start}
      />
    </Grid>
  );
};

export default CheckBox;
