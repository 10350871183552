import React from 'react';

type Props = {
  value: number,
};

const ProgressBar = ({ value }: Props) => {
  const containerStyles = {
    height: 10,
    width: '100%',
  };

  const fillerStyles = {
    height: '100%',
    width: `${value}%`,
    backgroundColor: '#2074d4',
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles} />
    </div>
  );
};

export default ProgressBar;
