import firebase from 'firebase';
import InvolvesKitchen from '../../../types/InvolvesKitchen';
import Label from '../../../types/Label';
import User from '../../../types/User';

const saveDispenseSelection = (
  userId: User['id'],
  involvesKitchen: InvolvesKitchen,
  reportingLabels: Array<Label['id']>,
) => (
  firebase.firestore()
    .collection('userdata')
    .doc(userId)
    .update({
      dispenseSelection: {
        involvesKitchen,
        reportingLabels,
      },
    })
);

export default saveDispenseSelection;
