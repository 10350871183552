import React,
{
  useState,
  ChangeEvent,
  useMemo,
  useCallback,
} from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles, Grid, Typography } from '@material-ui/core';

import UserForm from '../../components/user-form/user-form';
import Spinner from '../../components/spinner/spinner';

import retrieveReportingLabels from '../edit-user-page/helpers/retrieveReportingLabels';
import validateEmail from '../../helpers/validate-email';
import apiRequest from '../../helpers/api-request';

import SiteData from '../../types/SiteData';
import Nullable from '../../types/Nullable';
import ReduxState from '../../types/ReduxState';
import User from '../../types/User';
import UserSiteConfig from '../../types/UserSiteConfig';
import isValidUserRoles from '../../types/isValidUserRoles';

const useStyles = makeStyles({
  title: {
    marginBottom: 20,
  },
});

const AddUserPage = () => {
  const history = useHistory();
  const classes = useStyles();

  const authenticatedUser = useSelector<ReduxState, Nullable<User>>(
    (state) => state.authentication.get('USER'),
  );
  const allSites = useSelector<ReduxState, SiteData[]>(
    (state) => state.sites.get('SITES'),
  );
  const isUsersLoading = useSelector<ReduxState, boolean>(
    (state) => state.users.get('IS_USERS_LOADING'),
  );
  const isSitesLoading = useSelector<ReduxState, boolean>(
    (state) => state.sites.get('IS_SITES_LOADING'),
  );

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [admin, setAdmin] = useState<boolean>(false);
  const [sites, setSites] = useState<Array<UserSiteConfig>>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Nullable<string>>(null);

  const onSave = async () => {
    setLoading(true);
    try {
      await apiRequest(
        'create-user',
        'POST',
        {
          name,
          email,
          admin,
          sites,
          site: sites[0].site,
          createdTime: moment().valueOf(),
          createdBy: authenticatedUser?.id,
        },
      );
      history.push('/admin/users');
    } catch (e) {
      setError('User could not be updated, please try again.');
      setLoading(false);
    }
  };

  const onNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, [setName]);

  const onEmailChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, [setEmail]);

  const onAdminChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => (
    setAdmin(checked)
  ), [setAdmin]);

  const onSitesChange = useCallback(
    (updatedSites: Array<UserSiteConfig>) => setSites(updatedSites),
    [setSites],
  );

  const allLabels = useMemo(() => {
    if (sites) {
      return retrieveReportingLabels(sites.map(({ site }) => site), allSites);
    }
    return {};
  }, [sites, allSites]);

  const isValidUser = useMemo(() => (
    !validateEmail(email)
    && name.length > 0
    && sites.length > 0
    && isValidUserRoles(sites)
  ), [name, email, sites]);

  if (isUsersLoading || isSitesLoading || loading) return <Spinner />;

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid item className={classes.title}>
        <Typography variant="h6">Create User</Typography>
      </Grid>
      <UserForm
        userId={null}
        name={name}
        email={email}
        admin={admin}
        sites={sites}
        allSites={allSites}
        allLabels={allLabels}
        onSave={onSave}
        onNameChange={onNameChange}
        onEmailChange={onEmailChange}
        onSitesChange={onSitesChange}
        onAdminChange={onAdminChange}
        isValidUser={isValidUser}
      />
      {error && <Typography variant="body1" color="error">{error}</Typography>}
    </Grid>
  );
};

export default AddUserPage;
