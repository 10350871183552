import getChoices from './get-choices';
import getChoicesQuantities from './get-choices-quantities';
import getAddOnDescription from './get-add-on-description';
import getDescriptionList from './get-description-list';
import csvFriendly from '../../../helpers/csv-friendly';
import getLabelText from './get-label-text';
import getAddOnTitle from './getAddOnTitle';

import ProductType from '../../../types/ProductType';

export default (data, siteData) => {
  const dataList = [];
  data.forEach(((d) => {
    const subCatList = d.subCategory.split(';');
    const subCatSeqList = d.subCategorySequence.split(';');
    const subCatImageList = d.subCategoryImage.split(';');
    subCatList.forEach((subCategory, i) => {
      dataList.push({
        ...d,
        subCategory,
        subCategorySequence: subCatSeqList[i],
        subCatImage: subCatImageList[i],
      });
    });
  }));
  const list = getDescriptionList(dataList);
  return dataList.map((d) => {
    const isOptionsValid = d.options;
    const times = d.times && d.times.length !== 0;
    const allergens = d.allergens
      ? d.allergens.join(';')
      : '';
    const discountCodes = d.discountCodes && d.discountCodes.length !== 0
      ? d.discountCodes.join(';')
      : '';
    const userLabels = getLabelText(d.userLabels, siteData.userLabels);
    const reportingLabels = getLabelText(d.reportingLabels, siteData.reportingLabels);

    return ({
      Group: csvFriendly(d.category),
      GroupSequence: csvFriendly(d.categorySequence || 0),
      GroupImage: csvFriendly(d.categoryImage || ''),
      Category: csvFriendly(d.subCategory),
      CategorySequence: csvFriendly(d.subCategorySequence || 0),
      CategoryImage: csvFriendly(d.subCategoryImage || ''),
      PLU: csvFriendly(d.plu),
      PLUName: csvFriendly(d.pluName),
      Price: Math.round(csvFriendly(d.price * 100)),
      Show: csvFriendly(d.show ? 'Y' : 'N'),
      NiceName: csvFriendly(d.name),
      Description: csvFriendly(d.description),
      To: csvFriendly(times ? d.times.map((t) => t.to).join(';') : ''),
      From: csvFriendly(times ? d.times.map((t) => t.from).join(';') : ''),
      Over18: csvFriendly(d.over18 ? 'Y' : 'N'),
      Sequence: csvFriendly(d.sequence || 0),
      Image: csvFriendly(d.image || ''),
      SoldOut: csvFriendly(d.soldOut ? 'Y' : 'N'),
      UserLabels: csvFriendly(userLabels),
      ReportingLabels: csvFriendly(reportingLabels),
      DiscountCodes: csvFriendly(discountCodes),
      NonDiscountable: csvFriendly(d.nonDiscountable ? 'Y' : 'N'),
      InvolvesKitchen: csvFriendly(d.involvesKitchen ? 'Y' : 'N'),
      AddonDescription: csvFriendly(getAddOnDescription(d, list)),
      Addon1: csvFriendly(isOptionsValid ? getChoices(d, 0) : ''),
      Addon1Title: csvFriendly(getAddOnTitle(d, 0)),
      Addon1QTY: csvFriendly(isOptionsValid ? getChoicesQuantities(d, 0) : ''),
      Addon2: csvFriendly(isOptionsValid ? getChoices(d, 1) : ''),
      Addon2Title: csvFriendly(getAddOnTitle(d, 1)),
      Addon2QTY: csvFriendly(isOptionsValid ? getChoicesQuantities(d, 1) : ''),
      Addon3: csvFriendly(isOptionsValid ? getChoices(d, 2) : ''),
      Addon3Title: csvFriendly(getAddOnTitle(d, 2)),
      Addon3QTY: csvFriendly(isOptionsValid ? getChoicesQuantities(d, 2) : ''),
      Addon4: csvFriendly(isOptionsValid ? getChoices(d, 3) : ''),
      Addon4Title: csvFriendly(getAddOnTitle(d, 3)),
      Addon4QTY: csvFriendly(isOptionsValid ? getChoicesQuantities(d, 3) : ''),
      Addon5: csvFriendly(isOptionsValid ? getChoices(d, 4) : ''),
      Addon5Title: csvFriendly(getAddOnTitle(d, 4)),
      Addon5QTY: csvFriendly(isOptionsValid ? getChoicesQuantities(d, 4) : ''),
      Addon6: csvFriendly(isOptionsValid ? getChoices(d, 5) : ''),
      Addon6Title: csvFriendly(getAddOnTitle(d, 5)),
      Addon6QTY: csvFriendly(isOptionsValid ? getChoicesQuantities(d, 5) : ''),
      Addon7: csvFriendly(isOptionsValid ? getChoices(d, 6) : ''),
      Addon7Title: csvFriendly(getAddOnTitle(d, 6)),
      Addon7QTY: csvFriendly(isOptionsValid ? getChoicesQuantities(d, 6) : ''),
      Addon8: csvFriendly(isOptionsValid ? getChoices(d, 7) : ''),
      Addon8Title: csvFriendly(getAddOnTitle(d, 7)),
      Addon8QTY: csvFriendly(isOptionsValid ? getChoicesQuantities(d, 7) : ''),
      Addon9: csvFriendly(isOptionsValid ? getChoices(d, 8) : ''),
      Addon9Title: csvFriendly(getAddOnTitle(d, 8)),
      Addon9QTY: csvFriendly(isOptionsValid ? getChoicesQuantities(d, 8) : ''),
      Addon10: csvFriendly(isOptionsValid ? getChoices(d, 9) : ''),
      Addon10Title: csvFriendly(getAddOnTitle(d, 9)),
      Addon10QTY: csvFriendly(isOptionsValid ? getChoicesQuantities(d, 9) : ''),
      Allergens: csvFriendly(allergens),
      VATRate: csvFriendly(d.vatRate != null ? d.vatRate : ''),
      Type: csvFriendly(d.type || ProductType.Product),
    });
  });
};
