import * as React from 'react';
import { Dispatch, useMemo } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  LinearProgress,
} from '@material-ui/core';
import OutlinedButton from '../../../components/outlined-button/outlined-button';
import OutlinedTextField from '../../../components/outlined-text-field/outlined-text-field';

type Props = {
  open: boolean
  giftCardCode: string
  onSubmit: () => void
  onClose: () => void
  cancellationReason: string
  setCancellationReason: Dispatch<React.SetStateAction<string>>
  isLoading: boolean
};

const CancellationDialog = ({
  open, giftCardCode, onSubmit, onClose, cancellationReason, setCancellationReason, isLoading,
}: Props) => {
  const handleReasonChange = (value: string) => {
    setCancellationReason(value);
  };

  const isCancelReasonValid = useMemo(() => (
    cancellationReason.length > 0 && !isLoading
  ), [cancellationReason]);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Cancel Gift Card</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
        >
          {`Are you sure you want to cancel Gift Card: (${giftCardCode})? This will set the gift card balance to zero without refunding`}
        </DialogContentText>
        <OutlinedTextField
          id="cancellation-reason"
          label="Reason for cancellation"
          classOption="flex"
          value={cancellationReason}
          onChange={(event) => handleReasonChange(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <OutlinedButton
          label="Abort"
          color="secondary"
          onClick={onClose}
          disabled={isLoading}
        />
        <OutlinedButton
          label="Confirm"
          color="primary"
          onClick={onSubmit}
          disabled={!isCancelReasonValid}
        />
      </DialogActions>
      {isLoading && (
        <LinearProgress />
      )}
    </Dialog>
  );
};

export default CancellationDialog;
