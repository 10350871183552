import Category from '../../../types/Category';
import copySiteMap from './copySiteMap';

const addProductToSiteMap = (
  siteMap: Category[],
  plu: number,
  categoryName: string,
  subCategoryName: string,
): Category[] => {
  const newSiteMap = copySiteMap(siteMap);
  const categoryToEdit = newSiteMap.find(({ category }) => category === categoryName);
  if (categoryToEdit == null) throw new Error('Category does not exist');
  const subCategoryToEdit = categoryToEdit.subCategories
    .find((subCategory) => subCategory.name === subCategoryName);
  if (subCategoryToEdit == null) throw new Error('Subcategory does not exist');
  subCategoryToEdit.products.push({ plu, sequence: subCategoryToEdit.products.length + 1 });
  return newSiteMap;
};

export default addProductToSiteMap;
