import React from 'react';

import {
  FormControlLabel,
  Switch,
  makeStyles,
  FormGroup,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  switch: {
    marginBottom: 30,
  },
}));

type Props = {
  label: string
  value: boolean
  onChange: (value: boolean) => void
  disabled: boolean
  className?: string
};

const FormSwitch = ({
  label,
  value,
  onChange,
  disabled,
  className,
}: Props) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };
  return (
    <FormGroup row>
      <FormControlLabel
        className={classes.switch}
        label={label}
        labelPlacement="end"
        control={(
          <Switch
            checked={value}
            onChange={handleChange}
            name={label}
            color="primary"
            disabled={disabled}
            className={className}
          />
        )}
      />
    </FormGroup>
  );
};

export default FormSwitch;
