import { firestore } from 'firebase/app';
import { DateTime } from 'luxon';

import isImportJob from './isImportJob';

import SiteData from '../types/SiteData';
import ImportJob from '../types/ImportJob';

const initialiseImportJobsListener = (
  site: SiteData['site'],
  setImportJobs: React.Dispatch<React.SetStateAction<ImportJob[]>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
) => (
  firestore()
    .collection('importJobs')
    .where('type', '==', 'gift card')
    .where('site', '==', site)
    .where('created', '>', DateTime.now().minus({ minutes: 20 }).toMillis())
    .onSnapshot((snapshot) => {
      const jobsInSnapshot: ImportJob[] = [];
      snapshot
        .docs
        .forEach((doc) => {
          const data = { id: doc.id, ...doc.data() };
          if (isImportJob(data)) jobsInSnapshot.push(data);
        });
      setImportJobs(jobsInSnapshot);
    },
    () => {
      setError('Unable to fetch in-progress imports.');
    })
);

export default initialiseImportJobsListener;
