import React, { useMemo } from 'react';

import {
  Box, Divider, Grid, Paper, Typography, makeStyles,
} from '@material-ui/core';

import CheckerField from '../../../components/CheckerField/CheckerField';
import InfoField from '../../../components/info-field/info-field';

import formatMoney from '../../../helpers/formatMoney';

import SiteData from '../../../types/SiteData';
import capitalize from '../../../helpers/capitalize';

const useStyles = makeStyles(() => ({
  paper: { height: '100%' },
  sectionTitle: {
    marginTop: 20,
    marginLeft: 20,
    marginBottom: 5,
  },
  mainSection: {
    margin: 20,
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

type Props = {
  settings: SiteData
};

const SettingsSection = ({ settings }: Props) => {
  const classes = useStyles();

  const {
    currency,
    waitingTimes,
    hideFreeText,
    highPricedAddonsFirst,
    cardTransactionWarningValue,
    gratuitiesPLU,
    defaultGratuity,
    serviceCharge,
  } = settings;

  const gratuitiesEnabled = useMemo(() => gratuitiesPLU != null, [gratuitiesPLU]);

  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} variant="outlined">
        <Typography className={classes.sectionTitle} variant="h6">Site Options</Typography>
        <Divider />
        <Box className={classes.mainSection}>
          {waitingTimes ? (
            <>
              <InfoField
                title="Drinks Waiting Time"
                info={`${waitingTimes.drinksWaitingTime || 0} minutes`}
              />
              <InfoField
                title="Food Waiting Time"
                info={`${waitingTimes.foodWaitingTime || 0} minutes`}
              />
              <Divider className={classes.divider} />
            </>
          ) : null}
          <CheckerField
            title="Hide Free Text"
            value={hideFreeText || false}
          />
          <CheckerField
            title="High Priced Addons First"
            value={highPricedAddonsFirst || false}
          />
          <InfoField
            title="Card Transaction Warning Value"
            info={cardTransactionWarningValue
              && cardTransactionWarningValue.length
              ? formatMoney(Number(cardTransactionWarningValue), currency)
              : 'No'}
          />
          <CheckerField
            title="Allow Console Notifications"
            value={settings.allowConsoleNotifications || false}
          />
          <Divider className={classes.divider} />
          <CheckerField
            title="Enable Gratuities"
            value={gratuitiesEnabled}
          />
          {gratuitiesEnabled && (
            <>
              <InfoField
                title="Gratuities PLU"
                info={gratuitiesPLU && gratuitiesPLU.toString().length ? gratuitiesPLU : 'No'}
              />
              <InfoField
                title="Default Gratuity"
                info={defaultGratuity && defaultGratuity.toString().length ? `${defaultGratuity}%` : '0%'}
              />
            </>
          )}
          <Divider className={classes.divider} />
          <CheckerField
            title="Enable Service Charge"
            value={serviceCharge != null}
          />
          {serviceCharge != null && (
            <>
              <InfoField
                title="Service Charge Name"
                info={serviceCharge.chargeName}
              />
              <InfoField
                title="Service Charge PLU"
                info={serviceCharge.plu}
              />
              <InfoField
                title="Service Charge Percentage"
                info={serviceCharge.percentage ? `${serviceCharge.percentage}%` : '0%'}
              />
              <InfoField
                title="Additional Charge Value"
                info={formatMoney(serviceCharge.value || 0, settings.currency)}
              />
              <CheckerField
                title="Compulsory Service Charge"
                value={serviceCharge?.compulsory || false}
              />
            </>
          )}
          <InfoField
            title="Allow Console Notifications"
            info={settings.allowConsoleNotifications ? 'Yes' : 'No'}
          />
          <InfoField
            title={`Compulsory ${settings?.locationDescriptor || 'Table Number'} in POS`}
            info={settings.posCompulsoryTableNumber ? 'Yes' : 'No'}
          />
          <InfoField
            title="Collect Customer Name"
            info={capitalize(settings.collectCustomerName || 'Never')}
          />
          <InfoField
            title="Collect Table Number"
            info={capitalize(settings.collectTableNumber || 'Always')}
          />
          <InfoField
            title="Location Descriptor"
            info={settings.locationDescriptor || 'Table Number'}
          />
        </Box>
      </Paper>
    </Grid>
  );
};

export default SettingsSection;
