import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = () => ({
  field: {
    width: 350,
    display: 'inlineBlock',
    marginRight: 20,
    marginBottom: 20,
  },
});

const OutlinedTextField = ({
  classes, id, label, labelWidth, value, adornment, onChange, required, error, helperText,
}) => (
  <FormControl className={classes.field} variant="outlined">
    <InputLabel htmlFor={id}>{label}</InputLabel>
    <OutlinedInput
      id={id}
      value={value}
      onChange={onChange}
      startAdornment={<InputAdornment position="start">{adornment}</InputAdornment>}
      labelWidth={labelWidth || 40}
      required={required}
      error={error}
    />
    {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
  </FormControl>
);

OutlinedTextField.propTypes = {
  classes: PropTypes.shape().isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelWidth: PropTypes.number,
  adornment: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

OutlinedTextField.defaultProps = {
  labelWidth: 40,
  value: null,
  required: false,
  error: false,
  helperText: null,
};

export default withStyles(styles)(OutlinedTextField);
