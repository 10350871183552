import React from 'react';

import {
  Box,
  Button,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';

type Props = {
  refundButtonTitle: string
  onRefundButtonClick: () => void
  disableRefundButton: boolean
  cancelButtonTitle: string
  onCancelButtonClick: () => void
  disableCancelButton: boolean
};

const useStyles = makeStyles((t) => ({
  toolbar: {
    margin: t.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    marginLeft: t.spacing(2.625),
  },
}));

const Toolbar = ({
  refundButtonTitle,
  onRefundButtonClick,
  disableRefundButton,
  cancelButtonTitle,
  onCancelButtonClick,
  disableCancelButton,
}: Props) => {
  const classes = useStyles();

  return (
    <Grid className={classes.toolbar}>
      <Box>
        <Typography style={{ marginRight: 5 }} variant="h6">Gift Card History</Typography>
      </Box>
      <Box>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          size="medium"
          onClick={onRefundButtonClick}
          disabled={disableRefundButton}
        >
          {refundButtonTitle}
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          size="medium"
          onClick={onCancelButtonClick}
          disabled={disableCancelButton}
        >
          {cancelButtonTitle}
        </Button>
      </Box>
    </Grid>
  );
};

export default Toolbar;
