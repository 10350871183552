import React from 'react';

import Chip from '@material-ui/core/Chip';
import NotificationType from '../../types/NotificationType';

type Props = {
  type: NotificationType
};

const NotificationsType = ({ type }: Props) => {
  switch (type) {
    case NotificationType.Warning:
      return (
        <Chip
          label="Warning"
          style={{ backgroundColor: 'orange' }}
          variant="outlined"
        />
      );
    case NotificationType.Error:
      return (
        <Chip
          label="Error"
          style={{ backgroundColor: 'red' }}
          variant="outlined"
        />
      );
    default:
      return (
        <Chip
          label="Unknown"
          color="default"
          variant="outlined"
        />
      );
  }
};

export default NotificationsType;
