import React, { useState, useCallback, useMemo } from 'react';
import {
  Dialog,
  TextField,
  Fade,
  LinearProgress,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

import isEmailValid from '../../../helpers/isEmailValid';

interface Props {
  open: boolean;
  onCancelClick: () => void;
  onDownload: (userEmail: string) => Promise<void>;
  loading: boolean;
  error: boolean;
}

const ReportDownloadDialog = ({
  open, onCancelClick, onDownload, loading, error,
}: Props) => {
  const [userEmail, setUserEmail] = useState<string>('');

  const handleEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setUserEmail(event.target.value);
  }, []);

  const emailAddressValid = useMemo(() => isEmailValid(userEmail), [userEmail]);

  const handleDownloadClick = useCallback(() => onDownload(userEmail), [userEmail]);

  return (
    <Dialog open={open}>
      <Fade in={loading}>
        <LinearProgress />
      </Fade>
      <DialogTitle>Create Report</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Due to the potential size of this file, it will be sent via email to to address
          entered below.
        </DialogContentText>
        <DialogContentText>
          Please enter a valid email address and click &apos;Send&apos;.
        </DialogContentText>
        <TextField
          value={userEmail}
          onChange={handleEmailChange}
          disabled={loading}
          fullWidth
          label="Email Address"
          variant="outlined"
        />
        {error && (
          <DialogContentText color="error" style={{ marginTop: 20 }}>
            Report could not be created. Please try again, and contact support if this issue
            persists.
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancelClick}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={handleDownloadClick}
          color="primary"
          variant="outlined"
          disabled={!emailAddressValid || loading}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportDownloadDialog;
