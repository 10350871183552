import * as Joi from 'joi';

import extractEnumValues from '../../../helpers/extractEnumValues';

import NewSiteData from '../../../types/NewSiteData';
import Currency from '../../../types/Currency';
import Language from '../../../types/Language';

const isNewSiteData = (value: unknown): value is NewSiteData => {
  const schema = Joi.object({
    active: Joi.bool().required(),
    address: Joi.object({
      latitude: Joi.number(),
      longitude: Joi.number(),
      line1: Joi.string().required(),
      line2: Joi.string().allow(''),
      city: Joi.string().required(),
      country: Joi.string().required(),
      postCode: Joi.string().required(),
    }).allow(null),
    collection: Joi.array().items({
      firstCollection: Joi.string(),
      lastCollection: Joi.string(),
      leadTime: Joi.number(),
      day: Joi.number(),
    }).allow(null),
    delivery: Joi.array().items({
      firstDelivery: Joi.string(),
      lastDelivery: Joi.string(),
      leadTime: Joi.number(),
      day: Joi.number(),
    }).allow(null),
    email: Joi.string().allow(''),
    name: Joi.string().required(),
    site: Joi.string().required(),
    language: Joi.string().valid(...extractEnumValues(Language)),
    currency: Joi.string().valid(...extractEnumValues(Currency)),
  }).unknown(true).required();
  const result = schema.validate(value, { allowUnknown: true });

  return result.error == null;
};

export default isNewSiteData;
