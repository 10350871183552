import SiteData from '../../../types/SiteData';

const getSiteLabelsForSelect = (siteLabels: SiteData['reportingLabels'] | undefined) => {
  if (siteLabels == null) return [];
  return siteLabels.map((label) => (
    { label: label.text, value: label.id }
  ));
};

export default getSiteLabelsForSelect;
