import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';

import { DataGrid, GridCellParams } from '@material-ui/data-grid';

import ErrorDialog from '../../components/ErrorDialog/ErrorDialog';
import Spinner from '../../components/spinner/spinner';
import Toolbar from './components/Toolbar';

import request from '../../helpers/request';
import formatMoney from '../../helpers/formatMoney';

import Nullable from '../../types/Nullable';
import TextMessageData from '../../types/TextMessageData';

const TextMessagesPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<string>(DateTime.now().minus({ days: 7 }).toSQLDate());
  const [endDate, setEndDate] = useState<string>(DateTime.now().toSQLDate());
  const [textMessages, setTextMessages] = useState<TextMessageData[]>([]);
  const [error, setError] = useState<Nullable<Error>>(null);
  const history = useHistory();

  useEffect(() => {
    request(`textMessagesReport?startDate=${startDate}&endDate=${endDate}`, 'GET')
      .then((response: TextMessageData[]) => setTextMessages(response))
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  }, []);

  const handleErrorOkClick = () => {
    history.go(0);
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;
    setStartDate(value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;
    setEndDate(value);
  };

  const handleRefreshButtonClick = () => {
    setIsLoading(true);
    request(`textMessagesReport?startDate=${startDate}&endDate=${endDate}`, 'GET')
      .then((response: TextMessageData[]) => setTextMessages(response))
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  if (isLoading) return <Spinner />;

  return (
    <>
      <DataGrid
        components={{ Toolbar }}
        rows={textMessages}
        columns={[
          { field: 'billedTo', headerName: 'Billed To', flex: 1 },
          { field: 'site', headerName: 'Site', flex: 1 },
          { field: 'messagesSent', headerName: 'Number of Messages', flex: 0.5 },
          { field: 'parts', headerName: 'Number of Parts', flex: 0.5 },
          {
            field: 'price',
            headerName: 'Price',
            flex: 0.5,
            renderCell: (params: GridCellParams) => formatMoney(params.row.price),
          },
        ]}
        autoHeight
        pageSize={10}
        rowsPerPageOptions={[10]}
        componentsProps={{
          toolbar: {
            textMessages,
            startDate,
            endDate,
            onStartDateChange: handleStartDateChange,
            onEndDateChange: handleEndDateChange,
            onRefreshButtonClick: handleRefreshButtonClick,
          },
        }}
      />
      <ErrorDialog
        open={error != null}
        onOkButtonClick={handleErrorOkClick}
        message={error?.message}
      />
    </>
  );
};

export default TextMessagesPage;
