enum Allergen {
  Celery = 'Celery',
  Gluten = 'Gluten',
  Shellfish = 'Shellfish',
  Molluscs = 'Molluscs',
  Eggs = 'Eggs',
  Fish = 'Fish',
  Lupin = 'Lupin',
  Milk = 'Milk',
  Mustard = 'Mustard',
  Peanuts = 'Peanuts',
  Sesame = 'Sesame',
  Soybeans = 'Soybeans',
  Sulphites = 'Sulphites',
  TreeNuts = 'Tree Nuts',
}

export default Allergen;
