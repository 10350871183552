import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';

const styles = () => ({
  margin: {
    margin: 1,
  },
});

class FabIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      classes, children, to, size, color,
    } = this.props;
    return to ? (
      <Fab component={Link} to={to} className={classes.margin} size={size} color={color} aria-label="publish">
        {children}
      </Fab>
    ) : (
      <Fab className={classes.margin} size={size} color={color} aria-label="publish">
        {children}
      </Fab>
    );
  }
}

FabIcon.propTypes = {
  classes: PropTypes.shape().isRequired,
  to: PropTypes.string,
  size: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

FabIcon.defaultProps = {
  to: null,
};

export default withStyles(styles)(FabIcon);
