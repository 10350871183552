import React, { useMemo } from 'react';

import {
  Box, Divider, Paper, makeStyles,
} from '@material-ui/core';

import CheckBox from '../../check-box/check-box';
import ProductTimesForm from './ProductTimesForm';

import ProductData from '../../../types/ProductData';
import ProductTime from '../../../types/ProductTime';

type Props = {
  product: ProductData;
  onResetTimesChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onTimesChange: (newTimes: ProductTime[]) => void;
  onCheckBoxChange: (label: string, event: React.ChangeEvent<HTMLInputElement>) => void
};

const useStyles = makeStyles((theme) => ({
  mainSection: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  divider: {
    marginTop: 10,
    marginBottom: 20,
  },
}));

const ProductOptions = ({
  product, onResetTimesChange, onTimesChange, onCheckBoxChange,
}: Props) => {
  const classes = useStyles();

  const {
    over18, show, involvesKitchen, soldOut, nonDiscountable, times, freeTextByItem,
  } = product;

  const allowProductTimes = useMemo(() => times != null && times.length > 0, [times]);

  return (
    <Paper>
      <Box className={classes.mainSection}>
        <CheckBox
          label="Show Product"
          checked={show}
          onChange={(event) => onCheckBoxChange('show', event)}
        />
        <CheckBox
          label="Sold Out"
          checked={soldOut}
          onChange={(event) => onCheckBoxChange('soldOut', event)}
        />
        <Divider className={classes.divider} />
        <CheckBox
          label="Over 18"
          checked={over18}
          onChange={(event) => onCheckBoxChange('over18', event)}
        />
        <CheckBox
          label="Involves Kitchen"
          checked={involvesKitchen}
          onChange={(event) => onCheckBoxChange('involvesKitchen', event)}
        />
        <CheckBox
          label="Add Guests to Add Notes"
          checked={freeTextByItem || false}
          onChange={(event) => onCheckBoxChange('freeTextByItem', event)}
        />
        <CheckBox
          label="Non Discountable"
          checked={nonDiscountable}
          onChange={(event) => onCheckBoxChange('nonDiscountable', event)}
        />
        <Divider className={classes.divider} />
        <CheckBox
          label="Allow Product Times"
          checked={allowProductTimes}
          onChange={onResetTimesChange}
        />
        {allowProductTimes ? (
          <ProductTimesForm
            times={times || []}
            onChange={onTimesChange}
          />
        ) : null}
      </Box>
    </Paper>
  );
};

export default ProductOptions;
