import React from 'react';

import {
  Cell,
  PieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import {
  Grid,
  Paper,
  Typography,
  Box,
  makeStyles,
} from '@material-ui/core';

import Nullable from '../../../types/Nullable';

type Props = {
  data: Nullable<{ food: number, drink: number }>
  dateRangeText: string
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  titleBox: {
    display: 'inline',
  },
}));

const ProductChartSection = ({ data, dateRangeText }: Props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.container}>
      <Typography>
        <Box className={classes.titleBox} fontWeight="fontWeightBold">Food vs Drink</Box>
        &nbsp;–&nbsp;
        {dateRangeText}
      </Typography>
      <Box className={classes.chartContainer}>
        <Grid container style={{ height: 500 }} item xs={10}>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                dataKey="value"
                data={[{ name: 'Food', value: data?.food }, { name: 'Drinks', value: data?.drink }]}
              >
                <Cell fill="#03DAC6" />
                <Cell fill="#6200EE" />
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </Grid>
        <Grid container item xs={2} direction="column" justify="center">
          <Grid container direction="row" alignItems="center" wrap="nowrap">
            <div style={{
              backgroundColor: '#03DAC6',
              width: 10,
              height: 10,
              borderRadius: '50%',
              marginRight: 10,
            }}
            />
            <Typography variant="body1" noWrap>Food</Typography>
          </Grid>
          <Grid container direction="row" alignItems="center" wrap="nowrap">
            <div style={{
              backgroundColor: '#6200EE',
              width: 10,
              height: 10,
              borderRadius: '50%',
              marginRight: 10,
            }}
            />
            <Typography variant="body1" noWrap>Drinks</Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default ProductChartSection;
