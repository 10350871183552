import Joi from 'joi';
import Kiosk from '../types/Kiosk';

const isKiosk = (value: unknown): value is Kiosk => {
  const schema = Joi.object({
    id: Joi.string().required(),
    name: Joi.string().required(),
    isActive: Joi.boolean().required(),
    terminalLocation: Joi.string().allow(null).required(),
    reportingLabels: Joi.array().items(Joi.string()),
    cloudPrinter: Joi.string().allow(null),
    isPrinterEnabled: Joi.boolean(),
  });

  return schema.validate(value).error == null;
};

export default isKiosk;
