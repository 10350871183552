import firebase from 'firebase/app';

import isVATRate from '../helpers/isVATRate';

import VatRate from '../types/VatRate';
import Dispatch from '../types/Dispatch';

import {
  GET_VAT_RATES_START,
  GET_VAT_RATES_SUCCESS,
  GET_VAT_RATES_ERROR,
} from '../constants/actions';

export default () => (dispatch: Dispatch) => {
  dispatch({
    type: GET_VAT_RATES_START,
  });
  firebase
    .firestore()
    .collection('vatrates')
    .get()
    .then((snapshots) => {
      const vatRates: VatRate[] = [];
      snapshots
        .forEach((snapshot) => {
          const data = snapshot.data();
          if (isVATRate(data)) {
            vatRates.push(data);
          }
        });
      dispatch({
        type: GET_VAT_RATES_SUCCESS,
        vatRates,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_VAT_RATES_ERROR,
        message: 'Failed to fetch vat rates codes.',
      });
    });
};
