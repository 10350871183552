import Label from '../../../types/Label';
import Payee from '../../../types/Payee';

const determineCurrentPayee = ({
  vatRegistered,
  stripeAccountId,
  stripeAccountSetupComplete,
  commissionPercent,
}: Label): Payee => (
  vatRegistered != null
  && stripeAccountId != null
  && stripeAccountSetupComplete
  && commissionPercent != null
    ? Payee.Label
    : Payee.Site
);

export default determineCurrentPayee;
