import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Button, Typography, Grid, makeStyles,
} from '@material-ui/core';

import CheckBox from '../../components/check-box/check-box';
import Spinner from '../../components/spinner/spinner';
import OutlinedTextField from '../../components/outlined-text-field/outlined-text-field';
import ImageUpload from '../../components/image-upload/image-upload';
import EditAddressDialog from '../../components/site-form/components/EditAddressDialog';
import SimpleSelect from '../../components/SimpleSelect/SimpleSelect';

import createSite from '../../helpers/create-site';
import validateEmail from '../../helpers/validate-email';
import getSiteUrl from '../../helpers/get-site-url';
import formatSiteAddress from '../../helpers/formatSiteAddress';
import getDefaultCollectionTimes from './helpers/getDefaultCollectionTimes';
import getDefaultDeliveryTimes from './helpers/getDefaultDeliveryTimes';
import isNewSiteData from './helpers/isNewSiteData';

import updateUserSite from '../../actions/update-user-site';

import AppUrl from '../../types/AppUrl';
import User from '../../types/User';
import ReduxState from '../../types/ReduxState';
import Nullable from '../../types/Nullable';
import Language from '../../types/Language';
import Currency from '../../types/Currency';
import SiteAddress from '../../types/SiteAddress';
import NewSiteData from '../../types/NewSiteData';
import SiteData from '../../types/SiteData';

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: 10,
  },
  imageForm: {
    marginBottom: 10,
  },
  error: {
    marginTop: 10,
  },
  siteLogoGrid: {
    padding: 10,
    width: '150px',
    height: '100px',
    marginBottom: 20,
  },
  siteLogo: {
    width: '150px',
    height: '100px',
    objectFit: 'contain',
    objectPosition: '50% 50%',
  },
}));

const CreateSitePage = () => {
  const [siteData, setSiteData] = useState<NewSiteData>({
    name: '',
    site: '',
    urlEndpoint: '',
    email: '',
    active: true,
    collection: null,
    delivery: null,
    language: Language.English,
    currency: Currency.GBP,
    address: null,
    customersOptions: {
      authProviders: [],
      appFeatures: { populateCustomerName: false, showSignUpOption: false },
    },
    welcomeModal: {
      hideOpeningTimes: false,
      welcomeMessage: '',
      termsLink: '',
      privacyPolicyLink: '',
    },
  });
  const [image, setImage] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<Nullable<File>>(null);
  const [addressDialogOpen, setAddressDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const user = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));
  const sites = useSelector<ReduxState, SiteData[]>((state) => state.sites.get('SITES'));
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    name, urlEndpoint, email, active, collection, delivery, language, currency, address,
  } = siteData;

  const usedGiftCardPreFixes = useMemo(() => (
    sites.map((site) => site.giftCardOptions.prefixCode)
  ), [sites]);

  const handleImageChange = (file: File, preview: string) => {
    setSelectedFile(file);
    setImage(preview);
  };

  const handleImageRemove = () => {
    setSelectedFile(null);
    setImage('');
  };

  const handleChangeName = useCallback((newName: string) => {
    setSiteData((data) => ({
      ...data,
      name: newName,
      site: newName.toLowerCase().replace(/\s/g, ''),
      urlEndpoint: newName.toLowerCase().replace(/\s/g, ''),
    }));
  }, []);

  const handleChange = useCallback(<T extends keyof NewSiteData>(
    label: T, value: NewSiteData[T],
  ) => {
    setSiteData((data) => ({ ...data, [label]: value }));
  }, []);

  const handleAddressClick = useCallback(() => setAddressDialogOpen(true), []);

  const handleAddressDialogCancel = useCallback(() => setAddressDialogOpen(false), []);

  const handleAddressDialogSubmit = useCallback((value: SiteAddress) => {
    setSiteData((data) => ({ ...data, address: value }));
    setAddressDialogOpen(false);
  }, []);

  const handleSave = useCallback(() => {
    setIsLoading(true);
    setErrorMessage(null);

    if (isNewSiteData(siteData)) {
      createSite(siteData, selectedFile, usedGiftCardPreFixes)
        .then(() => {
          dispatch(updateUserSite(user, siteData.site));
          setIsLoading(false);
          history.push(AppUrl.UploadProducts);
        })
        .catch((error) => {
          setIsLoading(false);
          setErrorMessage(error.message || 'Failed to create site, please try again or contact support.');
        });
    } else {
      setIsLoading(false);
      setErrorMessage('Site Data is invalid, please try again or contact support.');
    }
  }, [siteData, selectedFile, user]);

  if (isLoading) return <Spinner />;

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid className={classes.title}>
        <Typography variant="h6">Create Site</Typography>
      </Grid>
      <OutlinedTextField
        id="outlined-name"
        label="Site Name"
        classOption="flex"
        value={name}
        onChange={(event) => handleChangeName(event.target.value)}
        required
      />
      <OutlinedTextField
        id="outlined-name"
        label="Site URL"
        classOption="flex"
        value={getSiteUrl(urlEndpoint)}
        required
        disabled
      />
      <OutlinedTextField
        id="outlined-email"
        label="Email"
        classOption="flex"
        value={email}
        onChange={(event) => handleChange('email', event.target.value)}
        error={email != null && email.length > 0 && validateEmail(email)}
        helperText={email != null && email.length > 0 && validateEmail(email) ? 'Invalid Email' : null}
        required
      />
      <CheckBox
        label="Active"
        checked={active}
        onChange={(event) => handleChange('active', event.target.checked)}
      />
      <SimpleSelect<Currency>
        label="Payment Currency"
        value={currency}
        items={[
          { label: 'GBP (£)', value: Currency.GBP },
          { label: 'EUR (€)', value: Currency.EUR },
        ]}
        labelWidth={140}
        onChange={(newValue) => handleChange('currency', newValue)}
        allowEmpty={false}
      />
      <SimpleSelect<Language>
        label="App Language"
        value={language}
        items={[
          { label: 'English', value: Language.English },
          { label: 'Spanish', value: Language.Spanish },
        ]}
        labelWidth={110}
        onChange={(newValue) => handleChange('language', newValue)}
        allowEmpty={false}
      />
      <OutlinedTextField
        id="site-address"
        label="Site Address"
        classOption="flex"
        value={address != null ? formatSiteAddress(address) : ''}
        onClick={handleAddressClick}
      />
      <CheckBox
        label="Collection"
        checked={collection != null && collection.length > 0}
        onChange={(event) => handleChange('collection', event.target.checked ? getDefaultCollectionTimes() : null)}
      />
      <CheckBox
        label="Delivery"
        checked={delivery != null && delivery.length > 0}
        onChange={(event) => handleChange('delivery', event.target.checked ? getDefaultDeliveryTimes() : null)}
      />
      <Grid className={classes.imageForm}>
        <ImageUpload
          buttonLabel="Upload Site Logo"
          onChange={handleImageChange}
          onRemove={handleImageRemove}
          currentImage={image}
          description="The image should have an aspect ratio of 1:1 and best resolution is 400x400."
          previewGridStyles={classes.siteLogoGrid}
          previewStyles={classes.siteLogo}
        />
      </Grid>
      <Grid>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
        >
          Create Site
        </Button>
      </Grid>
      {errorMessage && <Typography className={classes.error} variant="body1" color="error">{errorMessage}</Typography>}
      <EditAddressDialog
        open={addressDialogOpen}
        address={
          address
          || (
            {
              line1: '',
              city: '',
              country: '',
              postCode: '',
            }
          )
        }
        onCancelCick={handleAddressDialogCancel}
        onSubmitClick={handleAddressDialogSubmit}
      />
    </Grid>
  );
};

export default CreateSitePage;
