import React, { useMemo } from 'react';

import moment from 'moment';

import {
  Box,
  Divider,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';

import CheckerField from '../../../components/CheckerField/CheckerField';
import InfoField from '../../../components/info-field/info-field';

import ProductData from '../../../types/ProductData';

const useStyles = makeStyles(() => ({
  paper: { height: '100%' },
  sectionTitle: {
    marginTop: 20,
    marginLeft: 20,
    marginBottom: 5,
  },
  mainSection: {
    margin: 20,
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

type Props = {
  product: ProductData,
};

const ProductSettingsSection = ({ product }: Props) => {
  const classes = useStyles();

  const {
    over18,
    show,
    involvesKitchen,
    soldOut,
    nonDiscountable,
    freeTextByItem,
    times,
  } = product;

  const allowProductTimes = useMemo(() => times != null && times.length > 0, []);

  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} variant="outlined">
        <Typography className={classes.sectionTitle} variant="h6">Settings</Typography>
        <Divider />
        <Box className={classes.mainSection}>
          <CheckerField title="Show" value={show} />
          <CheckerField title="Sold Out" value={soldOut} />
          <Divider className={classes.divider} />
          <CheckerField title="Over 18" value={over18} />
          <CheckerField title="Involves Kitchen" value={involvesKitchen} />
          <CheckerField title="Allow Guests to Add Notes" value={freeTextByItem || false} />
          <CheckerField title="Non Discountable" value={nonDiscountable} />
          <Divider className={classes.divider} />
          <CheckerField title="Allow Product Times" value={allowProductTimes} />
          {times != null && times.length > 0 && times.map((time) => (
            <InfoField
              key={time.day}
              title={moment().isoWeekday(time.day).format('dddd')}
              info={`${time.hidden ? 'Not Available' : `${time.from} to ${time.to}`}`}
            />
          ))}
        </Box>
      </Paper>
    </Grid>
  );
};

export default ProductSettingsSection;
