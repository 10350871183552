import { PrimaryPeaziColour, SecondaryPeaziColour } from '../types/PeaziConsoleColour';

export const getPrimaryColors = (prefersDarkMode: boolean) => ({
  main: prefersDarkMode
    ? PrimaryPeaziColour.PrimaryDarkModeGreenMain
    : PrimaryPeaziColour.PrimaryLightModeGreenMain,
  dark: prefersDarkMode
    ? PrimaryPeaziColour.PrimaryDarkModeGreenDark
    : PrimaryPeaziColour.PrimaryLightModeGreenDark,
  light: prefersDarkMode
    ? PrimaryPeaziColour.PrimaryDarkModeGreenLight
    : PrimaryPeaziColour.PrimaryLightModeGreenLight,
  contrast: prefersDarkMode
    ? PrimaryPeaziColour.PrimaryDarkModeGreenContrast
    : PrimaryPeaziColour.PrimaryLightModeGreenContrast,
});

export const getSecondaryColors = (prefersDarkMode: boolean) => ({
  main: prefersDarkMode
    ? SecondaryPeaziColour.SecondaryDarkModeRedMain
    : SecondaryPeaziColour.SecondaryLightModeRedMain,
  dark: prefersDarkMode
    ? SecondaryPeaziColour.SecondaryDarkModeRedDark
    : SecondaryPeaziColour.SecondaryLightModeRedDark,
  light: prefersDarkMode
    ? SecondaryPeaziColour.SecondaryDarkModeRedLight
    : SecondaryPeaziColour.SecondaryLightModeRedLight,
  contrast: prefersDarkMode
    ? SecondaryPeaziColour.SecondaryDarkModeRedContrast
    : SecondaryPeaziColour.SecondaryLightModeRedContrast,
});
