import { ComponentType } from 'react';

import ForgotPasswordPage from '../containers/ForgotPasswordPage/ForgotPasswordPage';
import LoginPage from '../containers/LoginPage/LoginPage';

import AuthUrl from '../types/AuthUrl';

const AUTH_URL_SCREEN_MAP: Record<AuthUrl, ComponentType<any>> = {
  [AuthUrl.Login]: LoginPage,
  [AuthUrl.ForgotPassword]: ForgotPasswordPage,
};

export default AUTH_URL_SCREEN_MAP;
