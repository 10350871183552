import firebase from 'firebase/app';
import { UPDATE_USER_SITE } from '../constants/actions';

export default (user, site) => (dispatch) => firebase
  .firestore()
  .collection('userdata')
  .doc(user.id)
  .update({ site })
  .then(() => {
    dispatch({
      type: UPDATE_USER_SITE,
      user: { ...user, site },
    });
  });
