export enum PrimaryPeaziColour {
  PrimaryLightModeGreenMain = '#1B8183',
  PrimaryLightModeGreenDark = '#035761',
  PrimaryLightModeGreenLight = '#2B9DA0',
  PrimaryLightModeGreenContrast = '#FFFFFF',
  PrimaryDarkModeGreenMain = '#A1E4E1',
  PrimaryDarkModeGreenDark = '#7CB0AE',
  PrimaryDarkModeGreenLight = '#B8E5E3',
  PrimaryDarkModeGreenContrast = 'rgba(0, 0, 0, 0.87)',
}

export enum SecondaryPeaziColour {
  SecondaryLightModeRedMain = '#f44336',
  SecondaryLightModeRedDark = '#ba000d',
  SecondaryLightModeRedLight = '#ff7961',
  SecondaryLightModeRedContrast = '#000',
  SecondaryDarkModeRedMain = '#f44336',
  SecondaryDarkModeRedDark = '#ba000d',
  SecondaryDarkModeRedLight = '#ff7961',
  SecondaryDarkModeRedContrast = 'rgba(0, 0, 0, 0.87)',
}
