import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar } from '@material-ui/core';

const SnackBar = ({ open, onClose, message }) => (
  <Snackbar
    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    open={open}
    onClose={onClose}
    message={message}
    autoHideDuration={5000}
  />
);

SnackBar.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default SnackBar;
