import { Map } from 'immutable';

import {
  GET_ORDERS_START,
  GET_INHOUSE_ORDERS_SUCCESS,
  GET_ORDERS_ERROR,
  GET_ONLINE_ORDERS_SUCCESS,
} from '../constants/actions';

import ReduxAction from '../types/ReduxAction';
import ReduxState from '../types/ReduxState';

const initialStateMap: ReduxState['orders'] = Map();

const initialState = initialStateMap
  .set('ARE_INHOUSE_ORDERS_LOADING', false)
  .set('ARE_ONLINE_ORDERS_LOADING', false)
  .set('INHOUSE_ORDERS', [])
  .set('ONLINE_ORDERS', [])
  .set('NUMBER_OF_INHOUSE_ORDERS', 0)
  .set('NUMBER_OF_ONLINE_ORDERS', 0)
  .set('AMOUNT_OF_INHOUSE_SALES', 0)
  .set('AMOUNT_OF_ONLINE_SALES', 0)
  .set('UNPROCESSED_INHOUSE_ORDERS', [])
  .set('UNPROCESSED_ONLINE_ORDERS', [])
  .set('NUMBER_OF_UNPROCESSED_INHOUSE_ORDERS', 0)
  .set('NUMBER_OF_UNPROCESSED_ONLINE_ORDERS', 0)
  .set('ORDERS_LOADING_ERROR', '');

export default (
  state: ReduxState['orders'] = initialState,
  action: ReduxAction,
) => {
  switch (action.type) {
    case GET_ORDERS_START:
      return state
        .set('ARE_INHOUSE_ORDERS_LOADING', true)
        .set('ARE_ONLINE_ORDERS_LOADING', true)
        .set('INHOUSE_ORDERS', [])
        .set('ONLINE_ORDERS', [])
        .set('NUMBER_OF_UNPROCESSED_INHOUSE_ORDERS', 0)
        .set('NUMBER_OF_UNPROCESSED_ONLINE_ORDERS', 0)
        .set('ORDERS_LOADING_ERROR', '');
    case GET_INHOUSE_ORDERS_SUCCESS:
      return state
        .set('ARE_INHOUSE_ORDERS_LOADING', false)
        .set('INHOUSE_ORDERS', action.orders)
        .set('NUMBER_OF_INHOUSE_ORDERS', action.numberOfOrders)
        .set('AMOUNT_OF_INHOUSE_SALES', action.amountOfSales)
        .set('UNPROCESSED_INHOUSE_ORDERS', action.unprocessedOrders)
        .set('NUMBER_OF_UNPROCESSED_INHOUSE_ORDERS', action.numberOfUnprocessedOrders);
    case GET_ONLINE_ORDERS_SUCCESS:
      return state
        .set('ARE_ONLINE_ORDERS_LOADING', false)
        .set('ONLINE_ORDERS', action.orders)
        .set('NUMBER_OF_ONLINE_ORDERS', action.numberOfOrders)
        .set('AMOUNT_OF_ONLINE_SALES', action.amountOfSales)
        .set('UNPROCESSED_ONLINE_ORDERS', action.unprocessedOrders)
        .set('NUMBER_OF_UNPROCESSED_ONLINE_ORDERS', action.numberOfUnprocessedOrders);
    case GET_ORDERS_ERROR:
      return state
        .set('ARE_INHOUSE_ORDERS_LOADING', false)
        .set('ARE_ONLINE_ORDERS_LOADING', false)
        .set('ORDERS_LOADING_ERROR', action.message);
    default:
      return state;
  }
};
