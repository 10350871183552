import React, {
  useCallback,
  useMemo,
} from 'react';

import { Grid } from '@material-ui/core';

import CheckBox from '../../../components/check-box/check-box';
import QuantitySelector from '../../../components/QuantitySelector/QuantitySelector';

import OrderItem from '../../../types/OrderItem';
import LabelPlacement from '../../../components/check-box/types/LabelPlacement';
import ItemToRefund from '../types/ItemToRefund';
import AddOn from '../../../types/AddOn';
import RefundAddOn from './RefundAddOn';

type Props = {
  orderItem: OrderItem
  itemsToRefund: ItemToRefund[]
  addItemtoRefund: (newItemToRefund: ItemToRefund) => void,
  removeItemToRefund: (cartId: string) => void,
  updateItemToRefund: (updatedItemToRefund: ItemToRefund) => void,
};

const RefundItem = ({
  orderItem,
  itemsToRefund,
  addItemtoRefund,
  removeItemToRefund,
  updateItemToRefund,
}: Props) => {
  const quantityAlreadyRefunded = useMemo(() => orderItem.quantityRefunded || 0, [orderItem]);
  const discountAmount = useMemo(() => orderItem.mainItemDiscountAmount || 0, [orderItem]);
  const isAlreadyRefunded = useMemo(
    () => quantityAlreadyRefunded >= orderItem.quantity,
    [orderItem, quantityAlreadyRefunded],
  );

  const existingItemToRefund = useMemo(
    () => itemsToRefund.find(({ cartId }) => cartId === orderItem.cartId) || null,
    [orderItem, itemsToRefund],
  );

  const onCheckboxClick = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const addOnItemsToRefund = existingItemToRefund?.refundedAddOns.length || 0;
    const { checked } = event.target;
    if (!checked) {
      if (existingItemToRefund == null) {
        // existingItemToRefund cannot be null when checked is false
        throw new Error('No existing item to refund on Checkbox checked: false event');
      }
      if (addOnItemsToRefund > 0) {
        updateItemToRefund({
          ...existingItemToRefund,
          refundAmount: 0,
          quantity: 0,
        });
        return;
      }
      removeItemToRefund(orderItem.cartId);
      return;
    }
    if (existingItemToRefund == null) {
      addItemtoRefund({
        cartId: orderItem.cartId,
        plu: orderItem.plu,
        quantity: 1,
        discountAmount: orderItem.discountAmount || 0,
        price: orderItem.price - (discountAmount / orderItem.quantity),
        refundAmount: orderItem.price - (discountAmount / orderItem.quantity),
        refundedAddOns: [],
        reportingLabels: orderItem.reportingLabels || [],
        netTotalRefunded: orderItem.netAmount / orderItem.quantity,
      });
      return;
    }
    if (addOnItemsToRefund === 0) {
      /* checked cannot be true if existingItemToRefund is not null and
      addOnItemsToRefund is not greater than zero */
      throw new Error('Existing item to refund without add on items to refund on Checkbox checked: true event');
    }
    updateItemToRefund({
      ...existingItemToRefund,
      refundAmount: orderItem.price - (discountAmount / orderItem.quantity),
      quantity: 1,
      netTotalRefunded: orderItem.netAmount / orderItem.quantity,
    });
  }, [addItemtoRefund, removeItemToRefund, updateItemToRefund, existingItemToRefund, orderItem]);

  const onQuantityChange = useCallback((newQuantity: number) => {
    if (existingItemToRefund == null) {
      // QuantitySelector is hidden if existingItemToRefund is null
      throw new Error('No existing item to refund on item to refund quantity update event');
    }
    const addOnItemsToRefund = existingItemToRefund.refundedAddOns.length || 0;
    if (newQuantity === 0 && addOnItemsToRefund === 0) {
      removeItemToRefund(orderItem.cartId);
      return;
    }
    updateItemToRefund({
      ...existingItemToRefund,
      quantity: newQuantity,
      refundAmount: newQuantity * (orderItem.price - (discountAmount / orderItem.quantity)),
      netTotalRefunded: (orderItem.netAmount / orderItem.quantity) * newQuantity,
    });
  }, [updateItemToRefund, removeItemToRefund, orderItem, existingItemToRefund]);

  const onAddOnCheckboxClick = useCallback((
    addOn: AddOn,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { checked } = event.target;
    if (checked) {
      if (existingItemToRefund != null) {
        updateItemToRefund({
          ...existingItemToRefund,
          refundedAddOns: [
            ...existingItemToRefund.refundedAddOns,
            {
              plu: addOn.plu,
              name: addOn.name,
              price: addOn.price,
              refundAmount: addOn.price - ((addOn.discountAmount || 0) / orderItem.quantity),
              quantity: 1,
              netTotalRefunded: addOn.netAmount / orderItem.quantity,
            },
          ],
        });
        return;
      }
      addItemtoRefund({
        cartId: orderItem.cartId,
        plu: orderItem.plu,
        price: orderItem.price - discountAmount,
        quantity: 0,
        discountAmount: orderItem.discountAmount || 0,
        refundAmount: 0,
        reportingLabels: orderItem.reportingLabels || [],
        netTotalRefunded: 0,
        refundedAddOns: [{
          plu: addOn.plu,
          name: addOn.name,
          price: addOn.price,
          refundAmount: addOn.price - ((addOn.discountAmount || 0) / orderItem.quantity),
          quantity: 1,
          netTotalRefunded: addOn.netAmount / orderItem.quantity,
        }],
      });
      return;
    }
    if (existingItemToRefund == null) {
      // checked cannot be false if existingItemToRefund is null
      throw new Error('No existing item to refund on add on Checkbox checked: false event');
    }
    if (existingItemToRefund.quantity > 0) {
      updateItemToRefund({
        ...existingItemToRefund,
        refundedAddOns: existingItemToRefund
          .refundedAddOns.filter(({ plu }) => plu !== addOn.plu),
      });
      return;
    }
    removeItemToRefund(existingItemToRefund.cartId);
  }, [updateItemToRefund, removeItemToRefund, addItemtoRefund, existingItemToRefund, orderItem]);

  const onAddOnQuantityChange = useCallback((
    plu: number,
    newQuantity: number,
  ) => {
    if (existingItemToRefund == null) {
      // QuantitySelector is hidden if existingItemToRefund is null
      throw new Error('No existing item to refund on add on quantity change event');
    }
    if (newQuantity === 0) {
      if (existingItemToRefund.quantity === 0) {
        removeItemToRefund(existingItemToRefund.cartId);
        return;
      }
      updateItemToRefund({
        ...existingItemToRefund,
        refundedAddOns: existingItemToRefund.refundedAddOns.filter((addOn) => addOn.plu !== plu),
      });
      return;
    }
    const newRefundedAddOns = existingItemToRefund.refundedAddOns.map((addOn) => {
      if (addOn.plu !== plu) return addOn;
      return {
        ...addOn,
        refundAmount: (addOn.refundAmount / addOn.quantity) * newQuantity,
        quantity: newQuantity,
        netTotalRefunded: (addOn.netTotalRefunded / addOn.quantity) * newQuantity,
      };
    });
    updateItemToRefund({
      ...existingItemToRefund,
      refundedAddOns: newRefundedAddOns,
    });
  }, [updateItemToRefund, removeItemToRefund, addItemtoRefund, existingItemToRefund]);

  return (
    <Grid>
      <CheckBox
        label={`${orderItem.quantity}x ${orderItem.name}`}
        checked={isAlreadyRefunded || ((existingItemToRefund?.quantity || 0) > 0)}
        disabled={isAlreadyRefunded}
        onChange={onCheckboxClick}
        labelPlacement={LabelPlacement.End}
      />
      {existingItemToRefund != null && orderItem.quantity > 1 && (
        <QuantitySelector
          onChange={(value) => onQuantityChange(value)}
          value={existingItemToRefund.quantity}
          maxValue={orderItem.quantity - quantityAlreadyRefunded}
        />
      )}
      {orderItem.addOns && orderItem.addOns.map((addOn) => RefundAddOn(
        existingItemToRefund,
        addOn,
        orderItem.quantity,
        onAddOnCheckboxClick,
        onAddOnQuantityChange,
      ))}
    </Grid>
  );
};

export default RefundItem;
