import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { DateTime } from 'luxon';

import { Grid, IconButton, makeStyles } from '@material-ui/core';
import {
  DataGrid, GridColumns, GridCellParams,
} from '@material-ui/data-grid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';

import useUrlParams from '../../hooks/useUrlParams';

import NotificationsType from '../notifications-type/notifications-type';
import NotificationData from '../../types/NotificationData';

type Props = {
  notifications: NotificationData[]
  clearNotification: (id: string) => void
};

const useStyles = makeStyles(() => ({
  table: {
    height: 600,
    width: '100%',
  },
}));

const NotificationsTable = ({
  notifications,
  clearNotification,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const { values: [page], setters: [onPageChange] } = useUrlParams(['page']);

  const columns: GridColumns = useMemo(() => [
    { field: 'message', headerName: 'Message', width: 400 },
    {
      field: 'time',
      headerName: 'Time Created',
      width: 175,
      valueFormatter: ({ value }) => DateTime
        .fromMillis(typeof value === 'number' ? value : 0)
        .toLocaleString(DateTime.DATETIME_MED),
    },
    {
      field: 'timeSince',
      headerName: 'Time Since',
      width: 175,
      renderCell: (params: GridCellParams) => DateTime
        .fromMillis(typeof params.row.time === 'number' ? params.row.time : 0)
        .toRelative(),
    },
    {
      field: 'active',
      headerName: 'Active',
      type: 'boolean',
      width: 200,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 130,
      renderCell: (params: GridCellParams) => <NotificationsType type={params.row.type} />,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 130,
      renderCell: (params: GridCellParams) => (
        <Grid>
          <IconButton
            onClick={() => history.push(`/notifications/${params.row.id}`)}
            color="inherit"
          >
            <VisibilityIcon />
          </IconButton>
          {params.row.active && (
            <IconButton
              onClick={() => clearNotification(params.row.id)}
              color="inherit"
            >
              <SettingsBackupRestoreIcon />
            </IconButton>
          )}
        </Grid>
      ),
    },
  ], [notifications]);

  return (
    <Grid className={classes.table}>
      <DataGrid
        rows={notifications}
        columns={columns}
        pageSize={10}
        page={Number(page || 0)}
        onPageChange={(newPage) => onPageChange(String(newPage))}
        rowsPerPageOptions={[10]}
      />
    </Grid>
  );
};

export default NotificationsTable;
