import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import { Grid } from '@material-ui/core';

import Spinner from '../../components/spinner/spinner';
import DiscountsTable from '../../components/DiscountsTable/DiscountsTable';

import updateDiscount from '../../helpers/updateDiscount';
import removeDiscount from '../../helpers/removeDiscount';
import initialiseDiscountsListener from '../../helpers/initialiseDiscountsListener';

import Discount from '../../types/Discount';
import DiscountStatus from '../../types/DiscountStatus';
import ReduxState from '../../types/ReduxState';
import User from '../../types/User';
import Nullable from '../../types/Nullable';
import hasPermission from '../../helpers/has-permission';
import RoleRestrictedAction from '../../types/RoleRestrictedAction';

const DiscountsPage = () => {
  const user = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));
  const isSettingsLoading = useSelector<ReduxState, boolean>((state) => state.settings
    .get('IS_SETTINGS_LOADING'));
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Nullable<Error>>(null);

  const userSiteConfig = useMemo(() => {
    if (user != null) return user.sites.find(({ site }) => site === user.site);
    return null;
  }, [user]);

  const onDiscountsLoad = useCallback((discountsData: Discount[]) => {
    const discountsToShow = discountsData.filter((discount) => (
      hasPermission(RoleRestrictedAction.ViewDiscount, user)
      || discount.reportingLabels.some((discountLabel) => (
        userSiteConfig?.reportingLabels.some((userLabel) => userLabel === discountLabel)
      ))
    ));
    setIsLoading(false);
    setDiscounts(discountsToShow);
  }, []);

  const onDiscountsError = useCallback((err: Nullable<Error>) => {
    setIsLoading(false);
    setError(err);
  }, []);

  useEffect(() => {
    if (user != null && user.site) {
      setIsLoading(true);
      return initialiseDiscountsListener(user.site, onDiscountsLoad, onDiscountsError);
    }
    return () => {};
  }, [user]);

  const handleUpdateDiscount = useCallback(async (
    id: string, details: { status: DiscountStatus },
  ) => {
    setIsLoading(true);
    try {
      await updateDiscount(id, details);
      setIsLoading(false);
    } catch {
      setError(new Error(`Could not update discount ${id}`));
      setIsLoading(false);
    }
  }, []);

  const handleRemoveDiscount = useCallback(async (id: string) => {
    setIsLoading(true);
    try {
      await removeDiscount(id);
      setIsLoading(false);
    } catch {
      setError(new Error(`Could not remove discount ${id}`));
      setIsLoading(false);
    }
  }, []);

  if (isSettingsLoading || isLoading) return <Spinner />;

  return (
    <Grid>
      {error?.message}
      <DiscountsTable
        discounts={discounts}
        onUpdate={handleUpdateDiscount}
        onRemove={handleRemoveDiscount}
      />
    </Grid>
  );
};

export default DiscountsPage;
