import React, { useMemo } from 'react';

import { Grid, makeStyles } from '@material-ui/core';

import ActionCard from '../../components/ActionCard/ActionCard';

import getAdminRoutes from './helpers/getAdminRoutes';
import isAdminRoute from './helpers/isAdminRoute';

const useStyles = makeStyles({
  menuLink: {
    cursor: 'pointer',
    marginBottom: 20,
    '&:hover': {
      opacity: 0.5,
    },
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuSection: {
    marginTop: 20,
  },
});

const AdminPage = () => {
  const classes = useStyles();

  const routes = useMemo(() => getAdminRoutes().filter((route) => isAdminRoute(route)), []);

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid container spacing={2} className={classes.menuSection}>
        {routes.map((route) => <ActionCard route={route} />)}
      </Grid>
    </Grid>
  );
};

export default AdminPage;
