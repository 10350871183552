import nameToUrl from '../../../helpers/name-to-url';

const filterSiteMap = (menu, category, subCategory) => {
  if (!menu || menu.length === 0) return [];
  const subCatList = menu
    .find((map) => nameToUrl(map.category) === category).subCategories;
  const subCatProducts = subCatList
    .find((subCat) => nameToUrl(subCat.name) === subCategory);
  return subCatProducts;
};

export default filterSiteMap;
