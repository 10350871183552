import React from 'react';
import PropTypes from 'prop-types';

import { Chip, makeStyles } from '@material-ui/core';
import {
  Payment,
  Apple,
  AccountBalanceWallet,
  CardGiftcard,
} from '@material-ui/icons';

import OrderPaymentType from '../../types/OrderPaymentType';
import Nullable from '../../types/Nullable';
import POSOtherPaymentType from '../../types/POSOtherPaymentType';

const useStyles = makeStyles(() => ({
  method: {
    marginRight: 10,
  },
}));

type Props = {
  method: Nullable<OrderPaymentType> | POSOtherPaymentType['name'];
  size?: 'medium' | 'small'
};

const PaymentMethodChip = ({ method, size }: Props) => {
  const classes = useStyles();
  switch (method) {
    case OrderPaymentType.GooglePay:
      return (
        <Chip
          className={classes.method}
          icon={<Payment />}
          label="Google Pay"
          color="primary"
          size={size}
        />
      );
    case OrderPaymentType.ApplePay:
      return (
        <Chip
          className={classes.method}
          icon={<Apple />}
          label="Apple Pay"
          color="primary"
          size={size}
        />
      );
    case OrderPaymentType.Card:
      return (
        <Chip
          className={classes.method}
          icon={<Payment />}
          label="Card"
          color="primary"
          size={size}
        />
      );
    case OrderPaymentType.CardPresent:
      return (
        <Chip
          className={classes.method}
          icon={<Payment />}
          label="Card Present"
          color="primary"
          size={size}
        />
      );
    case OrderPaymentType.GiftCard:
      return (
        <Chip
          className={classes.method}
          icon={<CardGiftcard />}
          label="Gift Card"
          color="primary"
        />
      );
    default:
      return (
        <Chip
          className={classes.method}
          icon={<AccountBalanceWallet />}
          label={method}
          color="primary"
          size={size}
        />
      );
  }
};

PaymentMethodChip.propTypes = {
  method: PropTypes.string,
};

PaymentMethodChip.defaultProps = {
  method: null,
};

export default PaymentMethodChip;
