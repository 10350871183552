import { combineReducers } from 'redux';

import authentication from './authentication';
import notifications from './notifications';
import orderData from './orderData';
import orders from './orders';
import products from './products';
import settings from './settings';
import siteMap from './site-map';
import siteOrderStats from './site-order-stats';
import siteOrders from './site-orders';
import sites from './sites';
import slotTimes from './slot-times';
import slotDays from './slotDays';
import stats from './stats';
import unprocessedOrders from './unprocessed-orders';
import users from './users';
import vatRates from './vatRates';

export default combineReducers({
  authentication,
  notifications,
  orderData,
  orders,
  products,
  settings,
  siteMap,
  siteOrderStats,
  siteOrders,
  sites,
  slotTimes,
  slotDays,
  stats,
  unprocessedOrders,
  users,
  vatRates,
});
