import { firestore } from 'firebase/app';

import isGiftCardProduct from './isGiftCardProduct';

const getGiftCardProduct = (productId: string) => firestore()
  .doc(`masterproducts/${productId}`)
  .get()
  .then((doc) => {
    if (doc.exists) {
      const data = { ...doc.data(), id: doc.id };
      if (isGiftCardProduct(data)) return data;
      return Promise.reject(new Error('Gift Card Product is not valid'));
    }
    return Promise.reject(new Error('Gift Card Product was not found'));
  });

export default getGiftCardProduct;
