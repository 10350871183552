type Props = {
  data: string
  fileName: string
  fileType: string
};

const downloadFile = ({ data, fileName, fileType }: Props) => {
  const blob = new Blob([data], { type: fileType });

  const element = document.createElement('a');
  element.download = fileName;
  element.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  element.dispatchEvent(clickEvt);
  element.remove();
};

export default downloadFile;
