import SiteData from '../../../types/SiteData';
import Label from '../../../types/Label';

const retrieveReportingLabels = (sites: Array<SiteData['site']>, allSites: SiteData[]): { [site: string]: Label[] } => {
  const relevantSites = allSites.filter(({ site }) => sites?.includes(site));
  const labels: { [site: string]: Label[] } = { all: [] };
  relevantSites.forEach((site: SiteData) => {
    if (site.reportingLabels) {
      labels[site.site] = site.reportingLabels;
      labels.all = labels.all.concat(site.reportingLabels);
    }
  });
  return labels;
};

export default retrieveReportingLabels;
