import request from '../../../helpers/request';
import getQueryString from './getQueryString';

import Options from '../types/Options';
import Query from '../../../types/Query';
import TransactionsReportResult from '../types/TransactionsReportResult';

const getTransactionsReport = async (
  site: string, options: Options, query: Query, userId?: string, reportingLabels?: string[],
): Promise<TransactionsReportResult> => {
  const endpoint = getQueryString('transactionsReport', site, options, userId, query, reportingLabels);

  const result = await request(endpoint, 'GET');
  return {
    data: result.data,
    page: result.page,
    totalCount: result.totalCount,
  };
};

export default getTransactionsReport;
