import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography,
} from '@material-ui/core';

import OutlinedButton from '../../../components/outlined-button/outlined-button';

import changeSiteMap from '../../../helpers/change-site-map';
import nameToUrl from '../../../helpers/name-to-url';

import STORE_KEYS from '../../../constants/store-keys';

const {
  AUTHENTICATION: { USER },
  SITE_MAP: { SITE_MAP_ID, SITE_MAP },
} = STORE_KEYS;

const RemoveSubCategoryDialog = ({
  open, subCategoryToRemove, onSave, handleClose,
}) => {
  const user = useSelector((state) => state.authentication.get(USER));
  const siteMapId = useSelector((state) => state.siteMap.get(SITE_MAP_ID));
  const siteMap = useSelector((state) => state.siteMap.get(SITE_MAP));
  const [errorMessage, setErrorMessage] = useState(null);
  const params = useParams();

  const { category } = params;

  const handleSave = () => {
    const newSiteMap = siteMap.map((map) => {
      if (nameToUrl(map.category) === category) {
        return {
          ...map,
          subCategories: map.subCategories.filter((subCat) => subCat.name !== subCategoryToRemove),
        };
      }
      return map;
    });
    changeSiteMap(siteMapId, { site: user.site, products: newSiteMap })
      .then(() => {
        setErrorMessage(null);
        onSave();
      })
      .catch((error) => setErrorMessage(error.message));
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Remove Sub Category</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {`Are you sure you want to remove ${subCategoryToRemove}?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <OutlinedButton
          label="Cancel"
          color="secondary"
          onClick={handleClose}
        />
        <OutlinedButton label="Confirm" color="primary" onClick={handleSave} />
        {errorMessage ? <Typography variant="body1" color="error">{errorMessage}</Typography> : null}
      </DialogActions>
    </Dialog>
  );
};

RemoveSubCategoryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  subCategoryToRemove: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

RemoveSubCategoryDialog.defaultProps = {
  subCategoryToRemove: null,
};

export default RemoveSubCategoryDialog;
