import moment from 'moment';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { Grid, Typography, makeStyles } from '@material-ui/core';

import InfoField from '../../components/info-field/info-field';
import NotificationsType from '../../components/notifications-type/notifications-type';

import NotificationData from '../../types/NotificationData';
import ReduxState from '../../types/ReduxState';

const useStyles = makeStyles(() => ({
  field: {
    marginBottom: 5,
  },
  title: {
    fontWeight: 'bold',
    marginRight: 10,
  },
}));

interface MatchParams {
  notificationId: string;
}

const NotificationPage = () => {
  const notifications = useSelector<ReduxState, NotificationData[]>((state) => state.notifications.get('NOTIFICATIONS'));
  const classes = useStyles();
  const match = useRouteMatch<MatchParams>();
  const { params } = match;

  const notification = useMemo(() => notifications
    .find(({ id }) => id === params.notificationId), [notifications, params]);

  if (!notification) {
    return (
      <Grid>
        <Typography>Notification does not exist</Typography>
      </Grid>
    );
  }

  return (
    <Grid>
      <InfoField title="Title" info={notification.title} />
      <Grid
        className={classes.field}
        container
        direction="row"
        alignItems="center"
      >
        <Typography className={classes.title}>Type:</Typography>
        <NotificationsType type={notification.type} />
      </Grid>
      <InfoField title="Time" info={moment(notification.time).format('Do MMMM YYYY hh:mm')} />
      <InfoField title="Message" info={notification.message} />
    </Grid>
  );
};

export default NotificationPage;
