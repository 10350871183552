import { DateTime } from 'luxon';

import GiftCard from '../../../types/GiftCard';

const getGiftCardStats = (giftCards: GiftCard[]) => {
  const activeGiftCards = giftCards.filter((giftCard) => {
    if (giftCard.expiryDate == null) return true;
    return DateTime.now().toMillis() < giftCard.expiryDate;
  });
  const expiredGiftCards = giftCards.filter((giftCard) => {
    if (giftCard.expiryDate == null) return false;
    return DateTime.now().toMillis() > giftCard.expiryDate;
  });
  const numberOfCards = activeGiftCards.length;
  const total = activeGiftCards
    .reduce((runningTotal, { balance }) => runningTotal + balance, 0) || 0;
  const numberOfExpiredCards = expiredGiftCards.length;
  const expiredTotal = expiredGiftCards
    .reduce((runningTotal, { balance }) => runningTotal + balance, 0) || 0;

  return {
    numberOfCards, total, numberOfExpiredCards, expiredTotal,
  };
};

export default getGiftCardStats;
