import Label from '../../../types/Label';

const isReportingLabelId = (value: unknown, labels: Label[]): value is Label['id'] => {
  if (typeof value === 'string') {
    return labels.map(({ id }) => id).includes(value);
  }
  return false;
};

export default isReportingLabelId;
