import React, {
  useState, useMemo, useCallback, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Grid } from '@material-ui/core';

import Spinner from '../../components/spinner/spinner';
import DiscountForm from '../../components/DiscountForm/DiscountForm';

import updateDiscount from '../../helpers/updateDiscount';
import getDiscount from '../../helpers/getDiscount';

import AppUrl from '../../types/AppUrl';
import Discount from '../../types/Discount';
import ReduxState from '../../types/ReduxState';
import SiteData from '../../types/SiteData';
import User from '../../types/User';
import Nullable from '../../types/Nullable';
import EditedDiscount from '../../types/EditedDiscount';
import getSettings from '../../actions/get-settings';

const EditDiscountPage = () => {
  const user = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));
  const isSettingsLoading = useSelector<ReduxState, boolean>((state) => state.settings
    .get('IS_SETTINGS_LOADING'));
  const settings = useSelector<ReduxState, Nullable<SiteData>>((state) => state.settings.get('SETTINGS'));
  const [error, setError] = useState<Nullable<Error>>(null);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [selectedDiscount, setSelectedDiscount] = useState<Nullable<Discount>>(null);
  const history = useHistory();
  const params = useParams<{ discountId: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsDataLoading(true);
    getDiscount(params.discountId)
      .then((discount) => {
        setSelectedDiscount(discount);
        setIsDataLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsDataLoading(false);
      });
  }, [params]);

  useEffect(() => {
    if (settings == null && user != null) dispatch(getSettings(user.site));
  }, [settings, user]);

  const onSave = useCallback((discountDetails: EditedDiscount) => {
    if (user != null && selectedDiscount != null) {
      updateDiscount(selectedDiscount.id || '', discountDetails)
        .then(() => history.push(AppUrl.Discounts))
        .catch(() => setError(new Error(`Could not save discount ${discountDetails.name}`)));
    }
  }, [user, selectedDiscount]);

  const userLabels = useMemo(() => {
    if (!user) return [];
    if (!settings) return [];
    if (!settings.reportingLabels) return [];
    const userSiteConfig = user.sites.find((siteConfig) => siteConfig.site === user.site);
    const labels = userSiteConfig?.reportingLabels || [];
    return settings.reportingLabels
      .filter((label) => (label.active != null ? label.active : true))
      .filter(({ id }) => labels.includes(id));
  }, [settings, user]);

  const siteLabels = useMemo(() => {
    if (!settings) return [];
    if (!settings.reportingLabels) return [];
    return settings.reportingLabels
      .filter((label) => (label.active != null ? label.active : true));
  }, [settings]);

  if (isSettingsLoading || isDataLoading) return <Spinner />;

  return (
    <Grid>
      <DiscountForm
        initialValue={selectedDiscount}
        userLabels={userLabels}
        siteLabels={siteLabels}
        onSave={onSave}
      />
      {error?.message}
    </Grid>
  );
};

export default EditDiscountPage;
