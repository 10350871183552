/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';

import MaterialTable, { MTableToolbar } from 'material-table';
import { Chip, Grid, Typography } from '@material-ui/core';

import getActivityReport from '../helpers/getActivityReport';
import useUrlParams from '../../../hooks/useUrlParams';

import Spinner from '../../../components/spinner/spinner';

import DateType from '../types/DateType';
import Options from '../types/Options';
import Query from '../../../types/Query';
import SiteData from '../../../types/SiteData';
import TableElement from '../types/TableElement';
import ToolbarProps from '../types/ToolbarProps';
import User from '../../../types/User';
import Nullable from '../../../types/Nullable';

type Props = {
  user: Nullable<User>
  siteData: Nullable<SiteData>
  options: Options
};

const ActivityReportSection = ({
  user, siteData, options,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const [fetchedData, setFetchedData] = useState<boolean>(false);
  const tableRef = React.createRef<TableElement>();
  const history = useHistory();
  const location = useLocation();

  const {
    values: [page, search],
    setters: [onPageChange, onSearchChange],
  } = useUrlParams(['ActivityPage', 'ActivitySearch']);

  useEffect(() => {
    if (page == null || search == null) {
      history.replace(
        `${location.pathname}${location.search.length > 0 ? `${location.search}&` : '?'}ActivityPage=0&ActivitySearch=`,
      );
    }
  }, []);

  const site = useMemo(() => {
    if (!user) return '';
    return user.site;
  }, [user]);

  const siteLabels = useMemo(() => {
    if (!siteData) return [];
    if (!siteData.reportingLabels) return [];
    return siteData.reportingLabels;
  }, [siteData]);

  const activeReportingLabels = useMemo(() => options.reportingLabels, [siteLabels, options]);

  const refreshData = useCallback(() => {
    if (tableRef.current != null) {
      tableRef.current.onQueryChange({ options });
    }
  }, [tableRef, options]);

  const getData = useCallback(async (query: Query) => {
    setErrorMessage(null);
    try {
      const queryOptions = { ...query };
      if (!fetchedData && page != null && search != null) {
        queryOptions.page = Number(page);
        queryOptions.search = search;
      }
      const data = await getActivityReport(
        site, options, queryOptions, user?.id, activeReportingLabels,
      );
      if (fetchedData) {
        if ((query.search || '') !== (search || '')) {
          onSearchChange(query.search || '');
        }
        if ((query.page || 0) !== Number(page || 0)) {
          onPageChange(String(query.page || 0));
        }
      }
      setFetchedData(true);
      return data;
    } catch (error) {
      const { message } = error as Error;
      setErrorMessage(message || 'Something went wrong getting Activity Report');
      return {
        data: [],
        page: 0,
        totalCount: 0,
      };
    }
  }, [site, options, user, fetchedData, page, search]);

  const labelString = useMemo(() => {
    if (options.start === '' || options.end === '') return null;
    const type = options.type === DateType.TradingDate
      ? 'Trading'
      : 'Transaction';
    return `${type} Date: ${moment(options.start).format('DD/MM/YYYY')} to ${moment(options.end).format('DD/MM/YYYY')}`;
  }, [options]);

  const renderLabelChip = useMemo(() => {
    if (options.reportingLabels == null) return null;
    if (options.reportingLabels.length === 0) return null;

    const names = siteLabels
      .filter((label) => options.reportingLabels.includes(label.id))
      .map(({ text }) => text);

    return (
      <Chip
        label={`Reporting Label: ${names.join(', ')}`}
        color="secondary"
        variant="outlined"
      />
    );
  }, [options]);

  if (page == null || search == null) return <Spinner />;

  return (
    <Grid>
      {options && errorMessage === null && (
        <MaterialTable
          title="Activity Report"
          tableRef={tableRef}
          columns={[
            { title: 'Event', field: 'event' },
            { title: 'Update', field: 'updated', render: (rowData) => moment(rowData.updated).format('Do MMMM YYYY, HH:mm:ss') },
            {
              title: 'Reporting Label',
              field: 'reporting_label',
              render: (rowData) => <Chip label={rowData.reporting_label} />,
            },
          ]}
          data={getData}
          actions={[
            {
              icon: 'refresh',
              tooltip: 'Refresh Data',
              isFreeAction: true,
              onClick: refreshData,
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            pageSize: 10,
            searchText: search,
            debounceInterval: 1000,
            sorting: false,
          }}
          components={{
            Toolbar: (props: ToolbarProps) => (
              <div>
                <MTableToolbar {...props} />
                <div style={{ padding: 10 }}>
                  {labelString && (
                    <Chip
                      style={{ marginRight: 10 }}
                      label={labelString}
                      color="secondary"
                      variant="outlined"
                    />
                  )}
                  {renderLabelChip}
                </div>
              </div>
            ),
          }}
        />
      )}
      {errorMessage && (
        <Typography align="center" color="error">{errorMessage}</Typography>
      )}
    </Grid>
  );
};

export default ActivityReportSection;
