enum OrderStatusType {
  Complete = 'complete',
  Succeeded = 'succeeded',
  Failed = 'failed',
  Outstanding = 'outstanding',
  Unpaid = 'unpaid',
  PartialRefund = 'partial-refund',
  FullRefund = 'full-refund',
  Cancelled = 'cancelled',
  Error = 'error',
}

export default OrderStatusType;
