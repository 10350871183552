import React from 'react';
import { Fade, CircularProgress, makeStyles } from '@material-ui/core';

interface Props {
  loading: boolean;
}

const useStyles = makeStyles({
  spinner: {
    width: '2em',
  },
});

const FadeInSpinner = ({ loading }: Props) => {
  const classes = useStyles();

  return (
    <Fade in={loading} timeout={{ enter: 1500 }}>
      <CircularProgress className={classes.spinner} />
    </Fade>
  );
};

export default FadeInSpinner;
