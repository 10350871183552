import React, { useState, useMemo } from 'react';
import moment, { Duration } from 'moment';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Tooltip,
  ListItem,
  ListItemText,
  List,
  makeStyles,
  Box,
  Divider,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';

import OrderData from '../../../types/OrderData';

import useInterval from '../../../hooks/useInterval';

import calculateOrderAge from '../helpers/calculateOrderAge';
import groupOrderItems from '../helpers/groupOrderItems';
import capitalizeString from '../../../helpers/capitalize-string';
import conditionalText from '../../../helpers/conditionalText';
import sortOrderItems from '../helpers/sortOrderItems';
import getLabelTextFromId from '../../../helpers/getLabelTextFromId';
import getOrderSourceName from '../../../helpers/getOrderSourceName';

import Label from '../../../types/Label';
import CollectTableNumber from '../../../types/CollectTableNumber';
import Status from '../../../types/Status';

const useStyles = makeStyles((theme) => ({
  orderHeader: {
    color: 'black',
    backgroundColor: '#b8dc94',
    padding: theme.spacing(0.5, 2),
  },
  unprocessedOrderHeader: {
    color: 'black',
    backgroundColor: '#e84c4c',
    padding: theme.spacing(0.5, 2),
  },
  warningHeader: {
    color: 'black',
    backgroundColor: '#fff48c',
    padding: theme.spacing(0.5, 2),
  },
  headerContentBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerStatusButton: {
    backgroundColor: 'white',
    color: 'black',
  },
  flowCard: {
    width: '100%',
  },
  chipBox: {
    marginBottom: theme.spacing(0.5),
  },
  orderInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  addOnListItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  addOnList: {
    paddingTop: 0,
    paddingBottom: theme.spacing(0.5),
  },
  orderDivider: {
    marginBottom: theme.spacing(1),
  },
  statusDialogButton: {
    margin: theme.spacing(1),
  },
  content: {
    '&.MuiCardContent-root:last-child': {
      padding: theme.spacing(0.5, 2),
    },
  },
  orderInfoDivider: {
    width: '100%',
    marginBottom: theme.spacing(0.5),
  },
}));

interface Props {
  showCompleted: boolean,
  filteredOrder: OrderData,
  showLabels: boolean,
  siteReportingLabels?: Label[]
  inHouseCollectionLabels?: string[]
  collectTableNumberStatus: CollectTableNumber
  statusSelectOptions: Status[]
  statusUpdateHandlerFactory: (
    (orderNumber: string, cartIds: string[], newStatus: Status) => () => void
  )
}

const OrderCard = ({
  showCompleted,
  filteredOrder,
  showLabels,
  siteReportingLabels,
  inHouseCollectionLabels,
  collectTableNumberStatus,
  statusSelectOptions,
  statusUpdateHandlerFactory,
}: Props) => {
  const classes = useStyles();
  const [orderAge, setOrderAge] = useState<Duration>(calculateOrderAge(filteredOrder));
  const [statusDialogOpen, setStatusDialogOpen] = useState<boolean>(false);

  const sortedOrderItemsByLabel = useMemo(() => sortOrderItems(filteredOrder.order),
    [filteredOrder]);

  const groupedOrderItems = useMemo(() => groupOrderItems(sortedOrderItemsByLabel),
    [filteredOrder]);

  const orderAgeMinutes = orderAge ? orderAge.asMinutes() : 0;
  const isOlderThan15Minutes = useMemo(() => orderAgeMinutes > 15, [orderAge]);
  const ageText = useMemo(() => `${orderAge.humanize()} ago`, [orderAge]);
  const isOrderProcessed = useMemo(() => (
    filteredOrder.orderProcessed || filteredOrder.order.every((item) => item.processed)
  ), [filteredOrder]);

  const headerTitle: string = useMemo(() => {
    if (collectTableNumberStatus === CollectTableNumber.Never) return `Collection: ${filteredOrder.collectionNumber}`;
    const filteredOrderLabels = filteredOrder.order.reduce((output: string[], orderItem) => {
      const orderItemLabels: string[] = [];
      if (
        orderItem.reportingLabels == null
        || orderItem.reportingLabels.length === 0
      ) return [...output, 'noLabelProduct'];
      orderItem.reportingLabels.forEach((labelId) => orderItemLabels.push(labelId));
      return output.concat(orderItemLabels);
    }, []);
    if (filteredOrder.onlineOrder) {
      return `${capitalizeString(filteredOrder.onlineType)} at ${filteredOrder.slotTime}`;
    }
    if (
      inHouseCollectionLabels != null
      && inHouseCollectionLabels.length > 0
      && inHouseCollectionLabels.some((collectionLabel) => (
        filteredOrderLabels.some((orderLabel) => collectionLabel === orderLabel)
      ))
    ) {
      if (
        filteredOrder.tableNumber.length > 0
        && inHouseCollectionLabels.every((collectionLabel) => (
          filteredOrderLabels.some((orderLabel) => collectionLabel !== orderLabel)
        ))
      ) {
        return `Table ${filteredOrder.tableNumber}, Collection: ${filteredOrder.collectionNumber}`;
      }
      return `Collection: ${filteredOrder.collectionNumber}`;
    }
    return `Table ${filteredOrder.tableNumber}`;
  }, [filteredOrder, inHouseCollectionLabels]);

  const headerClass = useMemo(() => {
    if (isOrderProcessed) return classes.orderHeader;
    if (isOlderThan15Minutes) return classes.unprocessedOrderHeader;
    return classes.warningHeader;
  }, [filteredOrder, classes]);

  const labelText = (labelId: Label['id']) => {
    if (siteReportingLabels != null) {
      return getLabelTextFromId(labelId, siteReportingLabels) || 'No Label';
    }
    return 'No label';
  };

  useInterval(() => {
    setOrderAge(calculateOrderAge(filteredOrder));
  }, 60000);

  const handleUpdateStatusClick = () => setStatusDialogOpen(true);

  const handleStatusDialogCloseClick = () => setStatusDialogOpen(false);

  const showDetailsDivider = useMemo(() => (
    filteredOrder.userDetails.name != null
    || (filteredOrder.userDetails.selectedAddress
      && filteredOrder.userDetails.selectedAddress.length > 0)
    || filteredOrder.userDetails.phoneNumber != null
    || filteredOrder.notes.length > 0
    || filteredOrder.source != null
  ), [filteredOrder]);

  if (filteredOrder.order.length === 0) return null;
  if (!showCompleted && isOrderProcessed) return null;

  return (
    <>
      <Card elevation={5} className={classes.flowCard}>
        <CardHeader
          title={(
            <Box className={classes.headerContentBox}>
              <Typography variant="h5">{headerTitle}</Typography>
              <Button
                className={classes.headerStatusButton}
                variant="outlined"
                onClick={handleUpdateStatusClick}
              >
                Update Status
              </Button>
            </Box>
          )}
          className={headerClass}
        />
        <CardContent className={classes.content}>
          <Box className={classes.orderInfo}>
            <Typography color="primary" gutterBottom variant="body2">{filteredOrder.orderNumber}</Typography>
            {filteredOrder.onlineOrder
              ? (
                <Typography gutterBottom>
                  {`Customer: ${filteredOrder.userDetails.name}`}
                </Typography>
              )
              : (
                <Tooltip title={moment(filteredOrder.orderTime).format('Do MMMM YYYY, HH:mm:ss')} placement="top">
                  <Typography color="primary" gutterBottom variant="body2">{`Placed ${ageText}`}</Typography>
                </Tooltip>
              )}
          </Box>
          <Divider className={classes.orderDivider} />
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
          >
            {groupedOrderItems.map((item, index, array) => (
              <Grid key={item.id}>
                {(
                  showLabels
                  && (index === 0
                    || (
                      array != null
                      && array[index].reportingLabels != null
                      && array[index].reportingLabels[0]
                      !== array[index - 1].reportingLabels[0]))
                ) && (
                <Chip
                  className={classes.chipBox}
                  label={labelText(item.reportingLabels[0])}
                  variant="outlined"
                  color="primary"
                  size="small"
                />
                )}
                <Typography variant="body2">
                  {`${item.name}${item.quantity > 1
                    ? ` x ${item.quantity}`
                    : ''
                  } ${
                    conditionalText(item.refunded || false, '(Refunded)')
                  } ${
                    conditionalText(item.queued, '(In Print Queue)')
                  }`}
                </Typography>
                <List className={classes.addOnList}>
                  {item.addOns && item.addOns.map((addOn) => (
                    <ListItem className={classes.addOnListItem} key={addOn.name}>
                      <ListItemText className={classes.addOnListItem} secondary={`- ${addOn.name} ${addOn.refunded ? '(Refunded)' : ''}`} />
                    </ListItem>
                  ))}
                  {item.itemNotes && item.itemNotes.length > 0
                    && (
                      <ListItem className={classes.addOnListItem}>
                        <ListItemText primary={`- "${item.itemNotes}"`} />
                      </ListItem>
                    )}
                </List>
              </Grid>
            ))}
            {showDetailsDivider && (
              <Divider className={classes.orderInfoDivider} />
            )}
            {filteredOrder.source && (
              <Typography variant="body2" color="primary" gutterBottom>
                {`Order Source: ${getOrderSourceName(filteredOrder.source)}`}
              </Typography>
            )}
            {filteredOrder.userDetails.name && (
              <Typography variant="body2" color="primary" gutterBottom>{`Name: ${filteredOrder.userDetails.name}`}</Typography>
            )}
            {filteredOrder.userDetails.selectedAddress
            && filteredOrder.userDetails.selectedAddress.length > 0 && (
              <Typography variant="body2" color="primary" gutterBottom>{`Address: ${filteredOrder.userDetails.selectedAddress}`}</Typography>
            )}
            {filteredOrder.userDetails.phoneNumber && (
              <Typography variant="body2" color="primary" gutterBottom>{`Phone: ${filteredOrder.userDetails.phoneNumber}`}</Typography>
            )}
            {filteredOrder.notes.length > 0 && (
              <Typography variant="body2" color="primary" gutterBottom>{`Notes: ${filteredOrder.notes}`}</Typography>
            )}
          </Grid>
        </CardContent>
      </Card>
      <Dialog maxWidth="sm" fullWidth open={statusDialogOpen}>
        <DialogTitle>{headerTitle}</DialogTitle>
        <DialogContent>
          {statusSelectOptions.map((option) => (
            <Button
              className={classes.statusDialogButton}
              variant="outlined"
              color="primary"
              size="large"
              onClick={(
                statusUpdateHandlerFactory(
                  filteredOrder.orderNumber,
                  filteredOrder.order.map(({ cartId }) => cartId),
                  option,
                )
              )}
            >
              {option}
            </Button>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStatusDialogCloseClick}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrderCard;
