import * as Joi from 'joi';
import Product from '../types/Product';
import extractEnumValues from './extractEnumValues';
import Allergen from '../types/Allergen';

const isProduct = (value: unknown): value is Product => {
  const schema = Joi.object({
    name: Joi.string().required(),
    site: Joi.string(),
    pluName: Joi.string().allow('').required(),
    description: Joi.string().allow('').required(),
    plu: Joi.number().required(),
    price: Joi.number().required(),
    addOnDescription: Joi.string(),
    times: Joi.array().items(Joi.object({
      day: Joi.number().required(),
      from: Joi.string().required(),
      hidden: Joi.boolean(),
      to: Joi.string().required(),
    })),
    over18: Joi.boolean().required(),
    show: Joi.boolean().required(),
    nonDiscountable: Joi.boolean(),
    discountCodes: Joi.array().items(Joi.string()),
    involvesKitchen: Joi.boolean().required(),
    soldOut: Joi.boolean().required(),
    options: Joi.array().items(Joi.object({
      id: Joi.string(),
      title: Joi.string().allow(''),
      choices: Joi.array().items(Joi.object({
        displayName: Joi.string().allow('').required(),
        plu: Joi.number().required(),
      })).required(),
      maxChoices: Joi.number().required(),
      minChoices: Joi.number().required(),
      type: Joi.string().required(),
    })),
    image: Joi.string().allow('').allow(null),
    allergens: Joi.array().items(Joi.string().valid(...extractEnumValues(Allergen))),
    subCategory: Joi.string(),
    userLabels: Joi.array().items(Joi.string()),
    reportingLabels: Joi.array().items(Joi.string()),
    freeTextByItem: Joi.boolean(),
    vatRate: Joi.number().allow(null),
  });

  return schema.validate(value, { allowUnknown: true }).error == null;
};

export default isProduct;
