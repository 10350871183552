/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import Label from '../../types/Label';
import LabelCreator from '../LabelCreator/LabelCreator';
import LabelSelect from './components/label-select';

interface Props {
  multiple: boolean,
  value: Label['id'][],
  onChange: (value: Label['id'][]) => void,
  labels: Label[],
  onLabelSave: (label: Label) => Promise<void>,
  inputLabel: string,
  inputLabelWidth: number,
  canCreateLabels: boolean,
  canClearLabels: boolean,
  disabled: boolean,
  width?: string | number
}

const LabelInput = ({
  multiple,
  value,
  onChange,
  labels,
  onLabelSave,
  inputLabel,
  inputLabelWidth,
  width,
  canCreateLabels,
  canClearLabels,
  disabled,
}: Props) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <div>
      <LabelSelect
        multiple={multiple}
        value={value}
        labels={labels}
        onChange={onChange}
        setDialogOpen={setDialogOpen}
        inputLabel={inputLabel}
        inputLabelWidth={inputLabelWidth}
        width={width || '50%'}
        canCreateLabels={canCreateLabels}
        canClearLabels={canClearLabels}
        disabled={disabled}
      />
      <LabelCreator
        open={dialogOpen}
        setOpen={setDialogOpen}
        onLabelSave={onLabelSave}
      />
    </div>
  );
};

export default LabelInput;
