import request from './request';

import SiteData from '../types/SiteData';

const updateStripeStatementDescriptor = (
  stripeConnectId: SiteData['stripeConnect'],
  newStatementDescriptor: string,
) => {
  if (stripeConnectId != null && stripeConnectId.length > 0) {
    return request(
      'updateStripeAccount',
      'POST',
      {
        stripeAccountId: stripeConnectId,
        updatedData: {
          settings: {
            payments: {
              statement_descriptor: newStatementDescriptor,
            },
          },
        },
      },
    );
  }
  return () => {};
};

export default updateStripeStatementDescriptor;
