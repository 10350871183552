import firebase from 'firebase/app';

import SiteOpeningTime from '../../../types/SiteOpeningTime';

const getSiteOpeningTimes = async (site: string) => {
  let openingTimes: SiteOpeningTime[] = [];
  try {
    const snapshots = await firebase
      .firestore()
      .collection('sites')
      .where('site', '==', site)
      .get();
    snapshots.forEach((snapshot) => {
      openingTimes = snapshot.data().openingTimes || [];
    });
    return openingTimes;
  } catch (e) {
    return openingTimes;
  }
};

export default getSiteOpeningTimes;
