import React, { ChangeEvent } from 'react';

import { Grid } from '@material-ui/core';

import AdornmentTextField from '../../adornment-text-field/adornment-text-field';
import OutlinedTextField from '../../outlined-text-field/outlined-text-field';
import CheckBox from '../../check-box/check-box';

import getCurrencyAdornment from '../../../helpers/getCurrencyAdornment';

import SiteData from '../../../types/SiteData';
import EditableSiteData from '../../../types/EditableSiteData';

type Props = {
  settings: EditableSiteData
  onChange: (newSiteData: EditableSiteData) => void
};

const OnlineSettingsForm = ({ settings, onChange }: Props) => {
  const {
    slotCapacity,
    slotTime,
    lastOrdersTime,
    deliveryDistance,
    minimumDelivery,
    deliveryCharge,
    collection,
    delivery,
    currency,
    cloudPrinterSettings,
    allDaySlots,
    confirmationTextMessages,
  } = settings;
  const isCollection = collection && collection.length !== 0;
  const isDelivery = delivery && delivery.length !== 0;

  if (!isCollection && !isDelivery) return null;

  const handleChange = <T extends keyof SiteData>(label: T, value: SiteData[T]) => {
    const newSiteData = { ...settings, [label]: value };
    onChange(newSiteData);
  };

  const handlePrintPhoneClick = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { checked } } = event;

    const newCloudPrinterSettings = cloudPrinterSettings != null
      ? { ...cloudPrinterSettings, printCustomerPhone: checked }
      : { printCustomerPhone: checked };

    onChange({
      ...settings,
      cloudPrinterSettings: newCloudPrinterSettings,
    });
  };

  return (
    <Grid>
      <OutlinedTextField
        id="slot-capacity"
        type="number"
        label="Slot Capacity"
        classOption="flex"
        value={slotCapacity || ''}
        onChange={(event) => handleChange('slotCapacity', Number(event.target.value))}
      />
      <OutlinedTextField
        id="slot-time"
        type="number"
        label="Slot Time (minutes)"
        classOption="flex"
        value={slotTime || ''}
        onChange={(event) => handleChange('slotTime', Number(event.target.value))}
      />
      <CheckBox
        label="Confirmation Text Messages"
        checked={confirmationTextMessages || false}
        onChange={(event) => handleChange('confirmationTextMessages', event.target.checked)}
      />
      <CheckBox
        label="Allow Last Orders Time"
        checked={lastOrdersTime != null}
        onChange={(event) => handleChange('lastOrdersTime', event.target.checked ? '' : null)}
      />
      {lastOrdersTime != null && (
        <OutlinedTextField
          id="time"
          label="Last Orders Time"
          type="time"
          classOption="flex"
          value={lastOrdersTime || null}
          onChange={(event) => handleChange('lastOrdersTime', event.target.value)}
          helperText="Last orders time on current day for the following day"
          inputLabelProps={{ shrink: true }}
        />
      )}
      {isDelivery && (
        <>
          <OutlinedTextField
            id="delivery-distance"
            type="number"
            label="Delivery Distance (KM)"
            classOption="flex"
            value={deliveryDistance || ''}
            onChange={(event) => handleChange('deliveryDistance', Number(event.target.value))}
          />
          <OutlinedTextField
            id="minimum-delivery"
            type="number"
            label="Minimum Delivery (Pound)"
            classOption="flex"
            value={minimumDelivery || ''}
            onChange={(event) => handleChange('minimumDelivery', Number(event.target.value))}
          />
          <AdornmentTextField
            id="delivery-charge"
            label="Delivery Charge"
            labelWidth={120}
            value={deliveryCharge || ''}
            onChange={(event) => handleChange('deliveryCharge', Number(event.target.value))}
            adornment={getCurrencyAdornment(currency)}
            required
          />
        </>
      )}
      <CheckBox
        label="Print Customer Phone Number (Sunmi Cloud Printer)"
        checked={cloudPrinterSettings?.printCustomerPhone || false}
        onChange={handlePrintPhoneClick}
      />
      <CheckBox
        label="All Day Slots"
        checked={allDaySlots || false}
        onChange={(event) => handleChange('allDaySlots', event.target.checked)}
      />
    </Grid>
  );
};

export default OnlineSettingsForm;
