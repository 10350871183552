import Joi from 'joi';

import ProductData from '../types/ProductData';

const isGiftCardProduct = (value: unknown): value is ProductData => {
  const schema = Joi.object({
    id: Joi.string().required(),
    name: Joi.string().required(),
    price: Joi.number().required(),
    show: Joi.boolean().required(),
  });

  return schema.validate(value, { allowUnknown: true }).error == null;
};

export default isGiftCardProduct;
