import firebase from 'firebase/app';

const setSiteOffline = (siteId: string, value: boolean) => (
  firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .update({
      active: value,
    })
);

export default setSiteOffline;
