import React, { useState, useEffect, ChangeEvent } from 'react';
import firebase from 'firebase';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Checkbox,
  FormControlLabel,
  Button,
  Box,
  Typography,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';

import isOrderData from '../../../helpers/isOrderData';
import Nullable from '../../../types/Nullable';
import OrderData from '../../../types/OrderData';

interface Props {
  selectedOrderNumber: Nullable<string>;
  onCloseClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  loadingSpinnerBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  errorTypography: {
    marginLeft: theme.spacing(2),
  },
}));

const SposOptionsDialog = ({
  selectedOrderNumber,
  onCloseClick,
}: Props) => {
  const classes = useStyles();

  const [orderListenerData, setOrderListenerData] = useState<Nullable<OrderData>>(null);
  const [orderListenerError, setOrderListenerError] = useState<Nullable<Error>>(null);
  const [updateError, setUpdateError] = useState<Nullable<Error>>(null);

  useEffect(() => {
    setOrderListenerError(null);
    if (selectedOrderNumber == null) return undefined;
    const unsub = firebase
      .firestore()
      .collection('orders')
      .where('orderNumber', '==', selectedOrderNumber)
      .onSnapshot(
        (snapshot) => {
          const doc = snapshot.docs[0];
          const data = { id: doc.id, ...doc.data() };
          if (!isOrderData(data)) {
            setOrderListenerError(new Error('Unable to load order data.'));
          } else {
            setOrderListenerData(data);
          }
        },
        (err) => {
          setOrderListenerError(err);
          setOrderListenerData(null);
        },
      );
    return () => {
      unsub();
      setOrderListenerData(null);
    };
  }, [selectedOrderNumber]);

  const handleProcessedBySposCheckboxChange = (
    { target: { checked } }: ChangeEvent<HTMLInputElement>,
  ) => {
    firebase
      .firestore()
      .collection('orders')
      .doc(orderListenerData?.id)
      .update({ processedBySpos: checked })
      .catch((err) => {
        setUpdateError(err);
      });
  };

  return (
    <Dialog
      open={selectedOrderNumber != null}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        {selectedOrderNumber}
        &nbsp;SPOS Options
      </DialogTitle>
      <DialogContent>
        {orderListenerData == null && (
          <Box className={classes.loadingSpinnerBox}><CircularProgress /></Box>
        )}
        {orderListenerData != null && (
          <FormControlLabel
            control={(
              <Checkbox
                checked={orderListenerData?.processedBySpos || false}
                onChange={handleProcessedBySposCheckboxChange}
              />
            )}
            label="Processed by SPOS"
          />
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Box>
          {orderListenerError && (
            <Typography className={classes.errorTypography} color="error">
              {selectedOrderNumber}
              &nbsp;data could not be fetched.
            </Typography>
          )}
          {updateError && (
            <Typography className={classes.errorTypography} color="error">
              {selectedOrderNumber}
              &nbsp;data could not be updated.
            </Typography>
          )}
        </Box>
        <Button
          variant="contained"
          onClick={onCloseClick}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SposOptionsDialog;
