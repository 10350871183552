import isCollectionTime from './isCollectionTime';

import CollectionTime from '../../../types/CollectionTime';
import DeliveryTime from '../../../types/DeliveryTime';

const getLastValue = (time: CollectionTime | DeliveryTime): string => {
  if (isCollectionTime(time)) {
    return time.lastCollection;
  }

  return time.lastDelivery;
};

export default getLastValue;
