import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  ChangeEvent,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AppUrl from '../../types/AppUrl';
import UserSiteConfig from '../../types/UserSiteConfig';
import Nullable from '../../types/Nullable';
import ReduxState from '../../types/ReduxState';
import User from '../../types/User';
import SiteData from '../../types/SiteData';
import Label from '../../types/Label';

import Spinner from '../../components/spinner/spinner';

import getUsers from '../../actions/get-users';
import getSettings from '../../actions/get-settings';

import request from '../../helpers/request';
import labelsFromIds from '../../components/label-input/helpers/labels-from-ids';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: theme.spacing(52),
  },
  title: {
    margin: theme.spacing(2, 0, 1),
  },
  inputLabel: {
    marginLeft: theme.spacing(1),
  },
  inputs: {
    marginTop: theme.spacing(2),
    minWidth: theme.spacing(52),
  },
  subtitle: {
    margin: theme.spacing(4, 0, 1),
  },
  button: {
    marginTop: theme.spacing(5),
  },
}));

const POSUserPage: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const [POSPin, setPOSPin] = useState<string>('');
  const [reportingLabel, setReportingLabel] = useState<Nullable<Label>>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Nullable<string>>(null);

  const authenticatedUser = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));
  const isUsersLoading = useSelector<ReduxState, boolean>((state) => state.users.get('IS_USERS_LOADING'));
  const users = useSelector<ReduxState, User[]>((state) => state.users.get('USERS'));
  const settings = useSelector<ReduxState, Nullable<SiteData>>((state) => state.settings.get('SETTINGS'));

  const userSiteConfig = useMemo(
    () => authenticatedUser?.sites.find((siteConfig) => siteConfig.site === authenticatedUser.site),
    [authenticatedUser],
  );

  useEffect(() => {
    if (authenticatedUser != null) {
      dispatch(getUsers());
      dispatch(getSettings(authenticatedUser.site));
    }
  }, [authenticatedUser]);

  useEffect(() => {
    if (
      selectedUserId === ''
      || userSiteConfig == null
      || settings == null
      || settings.reportingLabels == null
    ) return;
    const selectedUser = users.filter((user) => user.id === selectedUserId)[0];
    const { sites } = selectedUser;
    const config = sites.find(({ site }) => site === userSiteConfig.site);
    if (config == null) return;
    const { reportingLabels } = config;
    setReportingLabel(
      labelsFromIds(reportingLabels, settings.reportingLabels)[0],
    );
  }, [selectedUserId]);

  const filteredSiteUsers = useMemo(() => (
    users.filter((user) => {
      if (user.admin) return false;
      const userSites = user.sites.map((siteConfig) => siteConfig.site);
      return userSiteConfig && userSites.includes(userSiteConfig.site);
    })
  ), [users, userSiteConfig]);

  const filteredNonPOSUsers = useMemo(() => (
    filteredSiteUsers.filter((user) => {
      const isNotPOSUser = user.sites.map((siteConfig) => (
        siteConfig.site === userSiteConfig?.site && !siteConfig.POSUser
      ));
      return isNotPOSUser.includes(true);
    })
  ), [filteredSiteUsers]);

  const userToUpdate = useCallback((uid: string) => {
    const updateUser = filteredSiteUsers.filter((user) => user.id === uid)[0];
    const updateSitesArrayOnUser = updateUser.sites.map((siteConfig: UserSiteConfig) => {
      if (userSiteConfig && siteConfig.site !== userSiteConfig.site) return siteConfig;

      return {
        ...siteConfig,
        POSUser: true,
        POSPin,
        reportingLabels: reportingLabel != null ? [reportingLabel.id] : [],
      };
    });
    updateUser.sites = updateSitesArrayOnUser;
    return updateUser;
  }, [selectedUserId, POSPin, reportingLabel]);

  const handleChangeUser = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { target: { value } } = event;
    if (typeof value === 'string') setSelectedUserId(value);
  };

  const handleChangePin = (event: React.ChangeEvent<{ value: string }>) => {
    const { target: { value } } = event;
    if (/^.{0,4}$/.test(value)) setPOSPin(value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const {
        name,
        email,
        admin,
        sites,
      } = userToUpdate(selectedUserId);
      await request(
        'updateUser',
        'POST',
        {
          uid: selectedUserId,
          name,
          email,
          admin,
          sites,
        },
      );
      history.push(`${AppUrl.POSHome}?tab=3`);
    } catch (e) {
      setError('User could not be updated, please try again.');
      setLoading(false);
    }
  };

  const labelSelectOptions = useMemo(() => {
    if (settings != null && settings.reportingLabels) {
      return settings.reportingLabels;
    }
    return [];
  }, [settings]);

  const reportingLabelChangeHandler = useCallback((event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    if (value === 'clear-select') {
      setReportingLabel(null);
    } else if (settings != null && settings.reportingLabels) {
      const newLabel = settings.reportingLabels.find(({ text }) => text === value);
      if (newLabel) setReportingLabel(newLabel);
    }
  }, [settings]);

  if (isUsersLoading || loading || !settings) return <Spinner />;

  return (
    <Box className={classes.container}>
      <Typography variant="h6" className={classes.title}>Create POS User</Typography>
      <Typography color="textSecondary">Only existing users can have access to POS.</Typography>
      <FormControl variant="outlined" className={classes.inputs}>
        <InputLabel htmlFor="select-user">Select user</InputLabel>
        <Select
          value={selectedUserId}
          onChange={handleChangeUser}
          variant="outlined"
          inputProps={{ id: 'select-user' }}
          labelWidth={85}
        >
          {filteredNonPOSUsers.length > 0 ? (
            filteredNonPOSUsers.map((user) => (
              <MenuItem value={user.id} key={user.id}>{user.name}</MenuItem>
            ))
          ) : (
            <MenuItem value="no-users">No users available</MenuItem>
          )}
        </Select>
      </FormControl>
      <Typography variant="subtitle1" className={classes.subtitle}>Reporting Label</Typography>
      <Typography color="textSecondary">Users are restricted to selling items with a matching Reporting Label if selected</Typography>
      <FormControl className={classes.inputs} variant="outlined">
        <InputLabel id="label-select-label">Reporting Label</InputLabel>
        <Select
          labelId="label-select-label"
          labelWidth={settings.site.length + 130}
          value={reportingLabel ? reportingLabel.text : ''}
          onChange={reportingLabelChangeHandler}
          MenuProps={{ variant: 'menu' }}
        >
          {labelSelectOptions.map(({ id, text }) => (
            <MenuItem key={id} value={text}>{text}</MenuItem>
          ))}
          <MenuItem key="clear-select" value="clear-select">
            <Typography variant="button" color="primary">No Label</Typography>
          </MenuItem>
        </Select>
      </FormControl>
      <Typography variant="subtitle1" className={classes.subtitle}>Peazi POS PIN</Typography>
      <Typography color="textSecondary">Assign a 4-number PIN for Peazi POS login</Typography>
      <TextField
        variant="outlined"
        type="number"
        value={POSPin}
        onChange={handleChangePin}
        className={classes.inputs}
      />
      <Button
        variant="contained"
        disabled={selectedUserId === '' || POSPin.length < 4 || filteredNonPOSUsers.length === 0}
        onClick={handleSubmit}
        color="primary"
        className={classes.button}
      >
        Create user
      </Button>
      {error && <Typography variant="body1" color="error">{error}</Typography>}
    </Box>
  );
};

export default POSUserPage;
