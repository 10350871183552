import React, { useState, useMemo } from 'react';
import {
  Paper,
  makeStyles,
  Box,
  Typography,
  Button,
  ButtonGroup,
} from '@material-ui/core';
import {
  GridSortItem,
  GridColumns,
  DataGrid,
  GridCellParams,
} from '@material-ui/data-grid';

import formatNumber from '../../../helpers/formatNumber';

interface Props {
  bestsellersData: { name: string; value: number; involvesKitchen: boolean }[];
  dateRangeText: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  titleBox: {
    display: 'inline',
  },
  tableContainer: {
    height: 400,
    width: '100%',
  },
  toolbar: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filterButtonGroup: {
    height: '50%',
  },
}));

const BestsellersTableSection = ({ bestsellersData, dateRangeText }: Props) => {
  const classes = useStyles();

  const [filterOption, setFilterOption] = useState<'all' | 'food' | 'drink'>('all');
  const [sortModel, setSortModel] = useState<GridSortItem[]>([
    {
      field: 'value',
      sort: 'desc',
    },
  ]);

  const filterClickHandlerFactory = (option: 'all' | 'food' | 'drink') => () => {
    setFilterOption(option);
  };

  const rows = bestsellersData.filter((row) => (
    filterOption === 'all'
    || (row.involvesKitchen && filterOption === 'food')
    || (!row.involvesKitchen && filterOption === 'drink')
  ));

  const columns: GridColumns = useMemo(() => [
    { field: 'rank', headerName: 'Number', width: 150 },
    { field: 'name', headerName: 'Name', width: 150 },
    {
      field: 'value',
      headerName: 'Ordered',
      width: 150,
      renderCell: (params: GridCellParams) => formatNumber(params.row.value),
    },
  ], [bestsellersData]);

  return (
    <Paper className={classes.container}>
      <Box className={classes.tableContainer}>
        <DataGrid
          rows={rows.map((item, i) => ({ id: item.name, rank: i + 1, ...item }))}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          components={{
            Toolbar: () => (
              <Box className={classes.toolbar}>
                <Typography>
                  <Box className={classes.titleBox} fontWeight="fontWeightBold">
                    Bestselling Products
                  </Box>
                  &nbsp;–&nbsp;
                  {dateRangeText}
                </Typography>
                <ButtonGroup
                  className={classes.filterButtonGroup}
                  size="small"
                >
                  <Button
                    onClick={filterClickHandlerFactory('all')}
                    color={filterOption === 'all' ? 'primary' : 'default'}
                  >
                    All
                  </Button>
                  <Button
                    onClick={filterClickHandlerFactory('food')}
                    color={filterOption === 'food' ? 'primary' : 'default'}
                  >
                    Food
                  </Button>
                  <Button
                    onClick={filterClickHandlerFactory('drink')}
                    color={filterOption === 'drink' ? 'primary' : 'default'}
                  >
                    Drink
                  </Button>
                </ButtonGroup>
              </Box>
            ),
          }}
        />
      </Box>
    </Paper>
  );
};

export default BestsellersTableSection;
