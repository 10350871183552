import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Grid } from '@material-ui/core';

import Spinner from '../../components/spinner/spinner';
import SlotsTable from './components/SlotsTable';
import SlotDayDialog from './components/slot-days-dialog';

import getSlotDays from '../../actions/getSlotDays';
import updateSlotDays from '../../actions/updateSlotDays';

import request from '../../helpers/request';

const SlotsPage = () => {
  const user = useSelector((state) => state.authentication.get('USER'));
  const isSlotDaysLoading = useSelector((state) => state.slotDays.get('IS_SLOT_DAYS_LOADING'));
  const slotDays = useSelector((state) => state.slotDays.get('SLOT_DAYS'));
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [updating, setUpdating] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (user != null) {
      dispatch(getSlotDays(user.site));
    }
  }, [user]);

  const createDay = (slotDay) => {
    setUpdating(true);
    setErrorMessage(null);
    request('createSlotDay', 'POST', { site: user.site, slotDay })
      .then(() => {
        setUpdating(false);
        setOpen(false);
        dispatch(getSlotDays(user.site));
      })
      .catch(() => {
        setUpdating(false);
        setErrorMessage('Failed to create slot day, please try again or contact support.');
      });
  };

  const removeDay = (day) => {
    setUpdating(true);
    request('removeSlotDay', 'POST', { site: user.site, day })
      .then(() => {
        dispatch(updateSlotDays(slotDays.filter((slot) => slot.day !== day)));
        setUpdating(false);
      })
      .catch(() => setUpdating(false));
  };

  if (isSlotDaysLoading || updating) return <Spinner />;

  return (
    <Grid>
      <SlotsTable
        slotDays={slotDays}
        history={history}
        onCreate={() => setOpen(true)}
        onRemove={removeDay}
        user={user}
      />
      <SlotDayDialog
        open={open}
        onSave={createDay}
        handleClose={() => setOpen(false)}
        errorMessage={errorMessage}
      />
    </Grid>
  );
};

export default SlotsPage;
