import Product from '../../../types/Product';

const getAddOnTitle = (data: Product, index: number) => {
  if (data.options == null || data.options.length === 0) return '';
  const addOn = data.options[index];
  if (addOn == null) return '';
  return addOn.title;
};

export default getAddOnTitle;
