enum GiftCardHistoryAction {
  Create = 'create',
  Redeem = 'redeem',
  Refund = 'refund',
  Refunded = 'refunded',
  Cancel = 'cancel',
  ReIssue = 'reissue',
}

export default GiftCardHistoryAction;
