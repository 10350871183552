import firebase from 'firebase/app';
import {
  GET_PRODUCTS_START,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
} from '../constants/actions';

import ProductType from '../types/ProductType';

export default (site) => (dispatch) => {
  dispatch({
    type: GET_PRODUCTS_START,
  });
  firebase
    .firestore()
    .collection('sitemap')
    .where('site', '==', site)
    .get()
    .then((snaps) => {
      const maps = [];
      snaps.forEach((snap) => {
        snap.data().products.forEach((product) => {
          product.subCategories.forEach((subCat) => {
            subCat.products.forEach((prod) => {
              maps.push({
                ...prod,
                category: product.category,
                categorySequence: product.sequence,
                categoryImage: product.image,
                subCategory: subCat.name,
                subCategorySequence: subCat.sequence,
                subCategoryImage: subCat.image,
              });
            });
          });
        });
      });
      firebase
        .firestore()
        .collection('masterproducts')
        .where('site', '==', site)
        .get()
        .then((snapshots) => {
          const prods = [];
          let productId = '';
          snapshots.forEach((snapshot) => {
            productId = snapshot.id;
            prods.push({
              ...snapshot.data(),
              id: snapshot.id,
            });
          });
          const products = prods.map((prod) => {
            const prodInfo = maps.find((map) => map.plu === prod.plu);
            const filteredInfo = maps.filter((map) => map.plu === prod.plu);
            const mapedSubCat = filteredInfo.map((map, i) => `${map.subCategory}${(i + 1 !== filteredInfo.length ? ';' : '')}`).join('');
            const mapedSubCatSeq = filteredInfo.map((map, i) => `${map.subCategorySequence}${(i + 1 !== filteredInfo.length ? ';' : '')}`).join('');
            const mapedSubCatImage = filteredInfo
              .filter(({ subCategoryImage }) => subCategoryImage != null && subCategoryImage !== '')
              .map(({ subCategoryImage }) => subCategoryImage)
              .join(';');
            return {
              ...prod,
              sequence: prodInfo ? prodInfo.sequence : '',
              category: prodInfo ? prodInfo.category : '',
              categorySequence: prodInfo ? prodInfo.categorySequence : '',
              categoryImage: prodInfo ? prodInfo.categoryImage : '',
              subCategory: prodInfo ? mapedSubCat : '',
              subCategorySequence: prodInfo ? mapedSubCatSeq : '',
              subCategoryImage: prodInfo ? mapedSubCatImage : '',
            };
          });
          dispatch({
            type: GET_PRODUCTS_SUCCESS,
            amountOfProducts: products.length,
            prods: prods.filter((p) => (p.type || ProductType.Product) !== ProductType.GiftCard),
            products,
            productId,
          });
        });
    })
    .catch(() => {
      dispatch({
        type: GET_PRODUCTS_ERROR,
        message: 'Failed to fetch Products.',
      });
    });
};
