import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { DateTime } from 'luxon';

import { DataGrid, GridCellParams } from '@material-ui/data-grid';
import {
  capitalize,
  Grid,
  makeStyles,
} from '@material-ui/core';

import Toolbar from './Toolbar';
import GiftCardHistoryActionChip from '../../../components/GiftCardHistoryActionChip/GiftCardHistoryActionChip';
import ErrorDialog from '../../../components/ErrorDialog/ErrorDialog';
import RefundDialog from '../../../components/RefundDialog/RefundDialog';

import formatMoney from '../../../helpers/formatMoney';
import apiRequest from '../../../helpers/api-request';
import round from '../../../helpers/round';

import GiftCardHistory from '../../../types/GiftCardHistory';
import Nullable from '../../../types/Nullable';
import GiftCardHistoryAction from '../../../types/GiftCardHistoryAction';
import ReduxState from '../../../types/ReduxState';
import SiteData from '../../../types/SiteData';
import OrderData from '../../../types/OrderData';
import ProductType from '../../../types/ProductType';
import BalancePaymentMethod from '../../../types/BalancePaymentMethod';
import GiftCard from '../../../types/GiftCard';
import User from '../../../types/User';
import CancellationDialog from './CancellationDialog';

const useStyles = makeStyles((theme) => ({
  table: { marginTop: theme.spacing(1) },
}));

type Props = {
  giftCard: Nullable<GiftCard>
  orderData: Nullable<OrderData>
};

type RefundItem = {
  cartId: string; quantity: number; addOns: { plu: string; quantity: number }[]
};

const GiftCardHistoryTable = ({
  giftCard,
  orderData,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [giftCardHistory, setGiftCardHistory] = useState<GiftCardHistory[]>([]);
  const [error, setError] = useState<Nullable<Error>>(null);
  const [refundDialogOpen, setRefundDialogOpen] = useState<boolean>(false);
  const [cancelDialogOpen, setCancelDialogOpen] = useState<boolean>(false);
  const [cancellationReason, setCancellationReason] = useState<string>('');
  const settings = useSelector<ReduxState, Nullable<SiteData>>((state) => state.settings.get('SETTINGS'));
  const user = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));

  const classes = useStyles();
  const history = useHistory();

  const getGiftCardHistory = useCallback(async (id: string) => {
    setIsLoading(true);
    setError(null);
    try {
      const { data } = await apiRequest(
        `gift-card/${id}/history`,
        'GET',
      );
      setGiftCardHistory(data);
    } catch {
      setError(new Error('Could not get Gift Card History'));
    } finally {
      setIsLoading(false);
    }
  }, [giftCard]);

  useEffect(() => {
    if (giftCard != null && giftCard.id != null) {
      getGiftCardHistory(giftCard.id);
    }
  }, [giftCard]);

  const handleErrorOkClick = () => {
    history.go(0);
  };

  const refundableItems = useMemo(() => {
    if (orderData == null || giftCard == null) return [];

    const { order: items } = orderData;

    const pricedItem = items.find((item) => (
      item.type === ProductType.GiftCard
      && item.cartId === giftCard.cartId
    ));
    if (pricedItem == null) return [];

    return [{
      name: `${pricedItem.name} (${giftCard.code})`,
      cartId: pricedItem.cartId,
      price: pricedItem.price,
      discountedPrice:
        round(pricedItem.price - ((pricedItem.mainItemDiscountAmount || 0) / pricedItem.quantity)),
      discountedSubTotal:
        round(pricedItem.subTotal - ((pricedItem.discountAmount || 0) / pricedItem.quantity)),
      quantity: 1,
      quantityRefunded: 0,
      tipValue: pricedItem.tipValue || 0,
      addOns: [],
      serviceCharge: false,
      tip: false,
      charityDonation: false,
      isGiftCard: true,
    }];
  }, [orderData, settings]);

  const nonRefundingItems: RefundItem[] = useMemo(() => {
    if (orderData == null || giftCard == null) return [];
    return orderData.order.reduce((items: RefundItem[], currentItem) => {
      if (giftCard.cartId === currentItem.cartId) return items;
      const addOns = currentItem.addOns.map((addOn) => (
        {
          plu: String(addOn.plu),
          quantity: 0,
        }
      ));
      const itemToAdd: RefundItem = {
        cartId: currentItem.cartId,
        quantity: 0,
        addOns,
      };
      return [...items, itemToAdd];
    }, []);
  }, [orderData, giftCard]);

  const refundablePaymentMethods = useMemo(() => {
    if (orderData == null || orderData.payments == null) return [];

    const { payments } = orderData;
    return payments.map((payment) => {
      let label: string;
      if (payment.type === BalancePaymentMethod.GiftCard) {
        label = 'Gift Card';
      } else if (payment.type === BalancePaymentMethod.Stripe) {
        label = 'Card';
      } else {
        label = capitalize(payment.name);
      }
      return {
        id: payment.id,
        amount: payment.amount,
        amountRefunded: payment.amountRefunded,
        label,
      };
    });
  }, [orderData, giftCard]);

  const handleRefundDialogSubmit = async (
    items: RefundItem[],
    paymentMethods: { id: string; amount: string }[],
    reason: string,
    additionalInfo: string,
  ) => {
    if (giftCard != null) {
      try {
        const allItems = items.concat(nonRefundingItems);
        await apiRequest(
          `multiTenderOrder/${giftCard.orderNumber}/refund`,
          'POST',
          {
            items: allItems,
            paymentMethods: paymentMethods
              .map(({ id, amount }) => ({ id, amount: Number(amount) })),
            reason,
            additionalInfo,
            user: user?.email || '',
            giftCardId: giftCard.id,
          },
        );
      } catch (err) {
        setError(new Error('Something went wrong processing the refund.'));
      }
    }
  };

  const handleCancelGiftCardSubmit = async () => {
    try {
      setIsLoading(true);
      await apiRequest(
        `gift-card/${giftCard?.id}/cancel`,
        'POST',
        {
          user: user?.email,
          site: settings?.site,
          reason: cancellationReason,
        },
      );
    } catch (err) {
      setError(new Error('Something went wrong with processing the refund.'));
    } finally {
      setCancellationReason('');
      setCancelDialogOpen(false);
      setIsLoading(false);
    }
  };

  const handleRefundButtonClick = async () => {
    if (orderData == null) return;
    setRefundDialogOpen(true);
  };

  const handleCancelButtonClick = async () => {
    setCancelDialogOpen(true);
  };

  const handleRefundDialogCloseClick = () => setRefundDialogOpen(false);

  const handleCancelDialogCloseClick = () => {
    setCancellationReason('');
    setCancelDialogOpen(false);
  };

  const isGiftCardRefundable = useMemo(() => (
    giftCardHistory.length === 1
    && giftCardHistory[0].action === GiftCardHistoryAction.Create
    && giftCard?.cartId != null
    && !isLoading
  ), [giftCardHistory, giftCard, isLoading]);

  const isGiftCardCancelable = useMemo(() => (
    !giftCardHistory.some((cardHistory) => cardHistory.action === GiftCardHistoryAction.Cancel)
    && !isLoading
  ), [giftCardHistory, isLoading]);

  return (
    <Grid className={classes.table}>
      <DataGrid
        components={{ Toolbar }}
        rows={giftCardHistory}
        columns={[
          {
            field: 'timestamp',
            headerName: 'Date',
            flex: 1,
            renderCell: (params: GridCellParams) => DateTime
              .fromISO(params.row.timestamp)
              .toLocaleString(DateTime.DATETIME_MED),
          },
          {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            renderCell: (params: GridCellParams) => (
              <GiftCardHistoryActionChip action={params.row.action} />
            ),
          },
          {
            field: 'amount',
            headerName: 'Amount Change',
            flex: 1,
            renderCell: (params: GridCellParams) => (
              formatMoney(params.row.amount)
            ),
          },
          {
            field: 'balance',
            headerName: 'Balance',
            flex: 1,
            renderCell: (params: GridCellParams) => (
              formatMoney(params.row.balance)
            ),
          },
        ]}
        autoHeight
        pageSize={10}
        rowsPerPageOptions={[10]}
        loading={isLoading}
        componentsProps={{
          toolbar: {
            refundButtonTitle: 'Refund Gift Card',
            disableRefundButton: !isGiftCardRefundable,
            onRefundButtonClick: handleRefundButtonClick,
            cancelButtonTitle: 'Cancel Gift Card',
            onCancelButtonClick: handleCancelButtonClick,
            disableCancelButton: !isGiftCardCancelable,
          },
        }}
      />
      <ErrorDialog
        open={error != null}
        onOkButtonClick={handleErrorOkClick}
        message={error?.message || 'Something went wrong, please try again or contact support.'}
      />
      <RefundDialog
        open={refundDialogOpen}
        showSplitTipMessage={!!settings?.splitPayouts}
        paymentMethods={refundablePaymentMethods}
        items={refundableItems}
        allowGiftCardRefund
        onClose={handleRefundDialogCloseClick}
        onSubmit={handleRefundDialogSubmit}
      />
      <CancellationDialog
        open={cancelDialogOpen}
        giftCardCode={giftCard?.code || ''}
        onSubmit={handleCancelGiftCardSubmit}
        onClose={handleCancelDialogCloseClick}
        cancellationReason={cancellationReason}
        setCancellationReason={setCancellationReason}
        isLoading={isLoading}
      />
    </Grid>
  );
};

export default GiftCardHistoryTable;
