import * as Joi from 'joi';

import User from '../types/User';

const isUser = (value: unknown): value is User => {
  const schema = Joi.object({
    id: Joi.string().required(),
    admin: Joi.boolean(),
    createdTime: Joi.number(),
    createdBy: Joi.string(),
    name: Joi.string().required(),
    email: Joi.string().required(),
    site: Joi.string().required(),
    sites: Joi.array().items(
      Joi.object({
        site: Joi.string().required(),
        role: Joi.string().valid('manager', 'user', 'restricted', 'superuser').required(),
        reportingLabels: Joi.array().items(Joi.string()).required(),
        POSPin: Joi.string().allow(''),
        POSUser: Joi.boolean(),
        loginAttempts: Joi.number(),
        accountLocked: Joi.boolean(),
      }),
    ).required(),
    dispenseSelection: Joi.object({
      involvesKitchen: Joi.string().valid('All', 'Food', 'Drinks'),
      reportingLabels: Joi.array().items(Joi.string()),
    }),
  });

  const joiResult = schema.validate(value);
  return joiResult.error == null;
};

export default isUser;
