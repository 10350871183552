import React from 'react';
import { Box, Chip } from '@material-ui/core';

const Labels = ({ labels }: { labels: string[] }) => (
  <Box>
    {labels.map((label) => (
      <Chip key={label} label={label} />
    ))}
  </Box>
);

export default Labels;
