import OrderData from '../../../types/OrderData';

const getRefundAmountLeft = (orderData: OrderData) => {
  const { paymentIntent } = orderData;

  if (!orderData || !paymentIntent) return 0;

  if (!paymentIntent.amount || !paymentIntent.amount_refunded) return orderData.total.toFixed(2);

  return ((paymentIntent.amount - paymentIntent.amount_refunded) / 100).toFixed(2);
};

export default getRefundAmountLeft;
