import React,
{
  useEffect,
  useState,
  useMemo,
  ChangeEvent,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Grid, Typography } from '@material-ui/core';

import UserForm from '../../components/user-form/user-form';
import Spinner from '../../components/spinner/spinner';

import getSites from '../../actions/get-sites';
import getUsers from '../../actions/get-users';

import request from '../../helpers/request';
import retrieveReportingLabels from './helpers/retrieveReportingLabels';
import validateEmail from '../../helpers/validate-email';

import User from '../../types/User';
import SiteData from '../../types/SiteData';
import ReduxState from '../../types/ReduxState';
import Nullable from '../../types/Nullable';
import isValidUserRoles from '../../types/isValidUserRoles';
import UserSiteConfig from '../../types/UserSiteConfig';

const useStyles = makeStyles({
  title: {
    marginBottom: 20,
  },
});

interface Props {
  history: { push: (path: string) => void },
  match: { params: { userId: string } },
}

const EditUserPage = ({ history, match }: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const allUsers = useSelector<ReduxState, User[]>(
    (state) => state.users.get('USERS'),
  );
  const allSites = useSelector<ReduxState, SiteData[]>(
    (state) => state.sites.get('SITES'),
  );
  const isUsersLoading = useSelector<ReduxState, boolean>(
    (state) => state.users.get('IS_USERS_LOADING'),
  );
  const isSitesLoading = useSelector<ReduxState, boolean>(
    (state) => state.sites.get('IS_SITES_LOADING'),
  );

  const user: Nullable<User> = allUsers.find(({ id }) => match.params.userId === id) || null;

  const [name, setName] = useState<string>(user?.name || '');
  const [email, setEmail] = useState<string>(user?.email || '');
  const [admin, setAdmin] = useState<boolean>(user?.admin || false);
  const [sites, setSites] = useState<Array<UserSiteConfig>>(user?.sites || []);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Nullable<string>>(null);

  useEffect(() => {
    dispatch(getSites());
    dispatch(getUsers());
  }, []);

  const onSave = async () => {
    setLoading(true);
    try {
      await request(
        'updateUser',
        'POST',
        {
          uid: user?.id,
          name,
          email,
          admin,
          sites,
        },
      );
      history.push('/admin/users');
    } catch (e) {
      setError('User could not be updated, please try again.');
      setLoading(false);
    }
  };

  const allLabels = useMemo(() => {
    if (sites) {
      return retrieveReportingLabels(sites.map(({ site }) => site), allSites);
    }
    return {};
  }, [sites, allSites]);

  const onNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, [setName]);

  const onEmailChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, [setEmail]);

  const onAdminChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => (
    setAdmin(checked)
  ), [setAdmin]);

  const onSitesChange = useCallback(
    (updatedSites: Array<UserSiteConfig>) => setSites(updatedSites),
    [setSites],
  );

  const isValidUser = useMemo(() => (
    !validateEmail(email) && name.length > 0 && sites.length > 0 && isValidUserRoles(sites)
  ), [name, email, sites]);

  if (isUsersLoading || isSitesLoading || loading) return <Spinner />;

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid item className={classes.title}>
        <Typography variant="h6">Edit User</Typography>
      </Grid>
      <UserForm
        userId={user?.id || null}
        name={name}
        email={email}
        admin={admin}
        sites={sites}
        allSites={allSites}
        allLabels={allLabels}
        onSave={onSave}
        onNameChange={onNameChange}
        onEmailChange={onEmailChange}
        onAdminChange={onAdminChange}
        onSitesChange={onSitesChange}
        isValidUser={isValidUser}
      />
      {error && <Typography variant="body1" color="error">{error}</Typography>}
    </Grid>
  );
};

export default EditUserPage;
