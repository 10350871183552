import never from './never';

import InteractionColourLabel from '../types/InteractionColourLabel';
import InteractionColourValue from '../types/InteractionColourValue';

const getColourLabel = (colour: InteractionColourValue) => {
  switch (colour) {
    case InteractionColourValue.Green:
      return InteractionColourLabel.Green;
    case InteractionColourValue.Blue:
      return InteractionColourLabel.Blue;
    case InteractionColourValue.Indigo:
      return InteractionColourLabel.Indigo;
    case InteractionColourValue.DeepPurple:
      return InteractionColourLabel.DeepPurple;
    case InteractionColourValue.Purple:
      return InteractionColourLabel.Purple;
    default:
      never(colour);
      return colour;
  }
};

export default getColourLabel;
