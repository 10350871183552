import React, { useState } from 'react';
import firebase from 'firebase/app';

import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';

import Spinner from '../../../components/spinner/spinner';
import ErrorDialog from '../../../components/ErrorDialog/ErrorDialog';
import CheckBox from '../../../components/check-box/check-box';
import SimpleSelect from '../../../components/SimpleSelect/SimpleSelect';

import expiryDates from '../constants/expiryDates';

import SiteData from '../../../types/SiteData';
import Nullable from '../../../types/Nullable';

interface Props {
  siteData: SiteData;
  resetSiteData: () => void;
}

const useStyles = makeStyles((theme) => ({
  formCard: {
    marginBottom: theme.spacing(3),
  },
  cardTitle: {
    marginBottom: theme.spacing(1),
  },
  saveButton: { marginTop: theme.spacing(2) },
}));

const GiftCardsOptions = ({ siteData, resetSiteData }: Props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customMessage, setCustomMessage] = useState<string>(siteData.giftCardOptions?.customMessage || '');
  const [allowPersonalMessage, setAllowPersonalMessage] = useState<boolean>(
    siteData?.giftCardOptions?.allowPersonalMessage || false,
  );
  const [allowServiceCharge, setAllowServiceCharge] = useState<boolean>(
    siteData?.giftCardOptions?.allowServiceCharge || false,
  );
  const [allowCharityDonation, setAllowCharityDonation] = useState<boolean>(
    siteData?.giftCardOptions?.allowCharityDonation || false,
  );
  const [expiry, setExpiry] = useState<number>(siteData?.giftCardOptions?.expiry || 0);
  const [error, setError] = useState<Nullable<Error>>(null);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      if (siteData == null) throw new Error('Missing site settings.');

      await firebase
        .firestore()
        .collection('sites')
        .doc(siteData.id)
        .update({
          giftCardOptions: {
            allowPersonalMessage,
            allowServiceCharge,
            allowCharityDonation,
            customMessage,
            expiry,
            prefixCode: siteData.giftCardOptions.prefixCode,
          },
        });
      resetSiteData();
      setIsLoading(false);
    } catch (err) {
      setError(err as Error);
      setIsLoading(false);
    }
  };

  const handleErrorOkClick = () => {
    setError(null);
  };

  if (isLoading) return <Spinner />;

  return (
    <>
      <Card className={classes.formCard}>
        <CardContent>
          <Typography className={classes.cardTitle} variant="h6">
            Gift Card Options
          </Typography>
          <TextField
            label="Custom gift card message"
            value={customMessage}
            onChange={(event) => setCustomMessage(event.target.value)}
            variant="outlined"
            margin="normal"
            helperText="Default: 'Enjoy a bit of food & drink on us!'"
            fullWidth
          />
          <CheckBox
            label="Allow user to enter personal message"
            checked={allowPersonalMessage}
            onChange={(event) => setAllowPersonalMessage(event.target.checked)}
          />
          <CheckBox
            label="Allow Service Charge"
            checked={allowServiceCharge}
            onChange={(event) => setAllowServiceCharge(event.target.checked)}
          />
          <CheckBox
            label="Allow Charity Donation"
            checked={allowCharityDonation}
            onChange={(event) => setAllowCharityDonation(event.target.checked)}
          />
          <SimpleSelect<number>
            label="Gift Card Expiry"
            items={expiryDates}
            value={expiry}
            onChange={(value) => setExpiry(value || 0)}
            labelWidth={60}
            fullWidth
          />
          <Button className={classes.saveButton} color="primary" variant="outlined" onClick={handleSave} fullWidth>
            Save
          </Button>
        </CardContent>
      </Card>
      <ErrorDialog open={error != null} onOkButtonClick={handleErrorOkClick} />
    </>
  );
};

export default GiftCardsOptions;
