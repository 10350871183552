import React from 'react';

import { Chip, makeStyles } from '@material-ui/core';
import {
  AddCircleOutline, Redeem, Cancel, Undo, Loop,
} from '@material-ui/icons';

import GiftCardHistoryAction from '../../types/GiftCardHistoryAction';

const useStyles = makeStyles(() => ({
  action: { marginRight: 10 },
}));

type Props = {
  action: GiftCardHistoryAction;
};

const GiftCardHistoryActionChip = ({ action }: Props) => {
  const classes = useStyles();
  switch (action) {
    case GiftCardHistoryAction.Create:
      return (
        <Chip
          className={classes.action}
          icon={<AddCircleOutline />}
          label="Created"
          color="primary"
        />
      );
    case GiftCardHistoryAction.Redeem:
      return (
        <Chip
          className={classes.action}
          icon={<Redeem />}
          label="Redeemed"
          color="primary"
        />
      );
    case GiftCardHistoryAction.Refund:
      return (
        <Chip
          className={classes.action}
          icon={<Undo />}
          label="Refund"
          color="primary"
        />
      );
    case GiftCardHistoryAction.Refunded:
      return (
        <Chip
          className={classes.action}
          icon={<Cancel />}
          label="Refunded"
          color="primary"
        />
      );
    case GiftCardHistoryAction.Cancel:
      return (
        <Chip
          className={classes.action}
          icon={<Cancel />}
          label="Cancelled"
          color="primary"
        />
      );
    case GiftCardHistoryAction.ReIssue:
      return (
        <Chip
          className={classes.action}
          icon={<Loop />}
          label="Re-issued"
          color="primary"
        />
      );
    default:
      return (
        <Chip
          className={classes.action}
          label={action}
          color="primary"
        />
      );
  }
};

export default GiftCardHistoryActionChip;
