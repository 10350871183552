import React from 'react';
import { TextField, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  fields: {
    marginRight: 20,
    marginBottom: 20,
  },
}));

type Props = {
  label: string
  value: string
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  error?: boolean
  helperText?: string
};

const DateField = ({
  label, value, onChange, disabled, error, helperText,
}: Props) => {
  const classes = useStyles();

  return (
    <TextField
      className={classes.fields}
      margin="normal"
      label={label}
      variant="outlined"
      type="date"
      value={value}
      onChange={onChange}
      InputLabelProps={{
        shrink: true,
      }}
      disabled={disabled || false}
      error={error || false}
      helperText={helperText || null}
    />
  );
};

export default DateField;
