import React, { PropsWithChildren } from 'react';

import { Box } from '@material-ui/core';

type Props = {
  tabValue: number
  value: number
};

const TabPanel = ({ tabValue, value, children }: PropsWithChildren<Props>) => (
  <div role="tabpanel" hidden={value !== tabValue}>
    <Box>
      {children}
    </Box>
  </div>
);

export default TabPanel;
