import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

const BackdropSpinner = ({
  classes,
  open,
}) => (
  <Backdrop className={classes.backdrop} open={open}>
    <CircularProgress color="inherit" />
  </Backdrop>
);

BackdropSpinner.propTypes = {
  classes: PropTypes.shape().isRequired,
  open: PropTypes.bool,
};

BackdropSpinner.defaultProps = {
  open: false,
};

export default withStyles(styles)(BackdropSpinner);
