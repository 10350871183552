import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';

const styles = () => ({
  root: {
    marginBottom: 10,
    marginRight: 10,
  },
});

const OutlinedButton = ({
  classes,
  color,
  label,
  onClick,
  disabled,
}) => (
  <Grid>
    <Button
      className={classes.root}
      color={color}
      variant="outlined"
      onClick={onClick}
      size="large"
      disabled={disabled}
    >
      {label}
    </Button>
  </Grid>
);

OutlinedButton.propTypes = {
  color: PropTypes.string.isRequired,
  classes: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

OutlinedButton.defaultProps = {
  disabled: false,
};

export default withStyles(styles)(OutlinedButton);
