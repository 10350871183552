import React from 'react';

import Chip from '@material-ui/core/Chip';
import {
  Help,
  Cancel,
  CheckCircle,
  Redo,
} from '@material-ui/icons';

import OrderStatusType from '../../types/OrderStatusType';

type Props = {
  status: string
};

const OrderStatus = ({ status }: Props) => {
  switch (status) {
    case OrderStatusType.Succeeded:
      return (
        <Chip
          icon={<CheckCircle style={{ color: 'green' }} />}
          label="Processed"
          variant="outlined"
        />
      );
    case OrderStatusType.Complete:
      return (
        <Chip
          icon={<CheckCircle style={{ color: 'green' }} />}
          label="Processed"
          variant="outlined"
        />
      );
    case OrderStatusType.FullRefund:
      return (
        <Chip
          icon={<Redo />}
          label="Full Refund"
          color="default"
          variant="outlined"
        />
      );
    case OrderStatusType.PartialRefund:
      return (
        <Chip
          icon={<Redo />}
          label="Partial Refund"
          color="default"
          variant="outlined"
        />
      );
    case OrderStatusType.Outstanding:
      return (
        <Chip
          icon={<Cancel />}
          label="Outstanding"
          color="secondary"
          variant="outlined"
        />
      );
    case OrderStatusType.Unpaid:
      return (
        <Chip
          icon={<Cancel />}
          label="Unpaid"
          color="secondary"
          variant="outlined"
        />
      );
    case OrderStatusType.Failed:
      return (
        <Chip
          icon={<Cancel />}
          label="Unpaid"
          color="secondary"
          variant="outlined"
        />
      );
    case OrderStatusType.Error:
      return (
        <Chip
          icon={<Cancel />}
          label="Error - contact support"
          color="secondary"
          variant="outlined"
        />
      );
    case OrderStatusType.Cancelled:
      return (
        <Chip
          icon={<Cancel />}
          label="Cancelled"
          color="secondary"
          variant="outlined"
        />
      );
    default:
      return (
        <Chip
          icon={<Help />}
          label="Unknown"
          color="default"
          variant="outlined"
        />
      );
  }
};

export default OrderStatus;
