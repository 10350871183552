import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Button, Grid, Typography, makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import SearchToolbar from '../../SearchToolbar/SearchToolbar';

import hasPermission from '../../../helpers/has-permission';

import AppUrl from '../../../types/AppUrl';
import RoleRestrictedAction from '../../../types/RoleRestrictedAction';
import User from '../../../types/User';
import Nullable from '../../../types/Nullable';

type Props = {
  value: string
  onChange: (value: string) => void
  clearSearch: () => void
  user: Nullable<User>
};

const useStyles = makeStyles(() => ({
  toolbar: { margin: 20 },
}));

const Toolbar = ({
  value, onChange, clearSearch, user,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const handleCreate = useCallback(() => history.push(AppUrl.AddDiscountPage), []);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  }, [onChange]);

  return (
    <Grid className={classes.toolbar}>
      <Grid
        container
        direction="row"
        justify="space-between"
      >
        <Typography style={{ marginRight: 5 }} variant="h6">Discounts</Typography>
        <Grid>
          <SearchToolbar
            placeholder="Search Discounts"
            value={value}
            onChange={handleChange}
            clearSearch={clearSearch}
          />
          <Button
            style={{ marginBottom: 10 }}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleCreate}
            disabled={!hasPermission(RoleRestrictedAction.CreateDiscountCode, user)}
          >
            Add Discount
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Toolbar;
