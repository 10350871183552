import React, { ChangeEvent } from 'react';

import {
  makeStyles,
  Box,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';

import labelsFromIds from '../../label-input/helpers/labels-from-ids';

import UserSiteConfig from '../../../types/UserSiteConfig';
import SiteData from '../../../types/SiteData';
import UserRole from '../../../types/UserRole';
import Label from '../../../types/Label';

const useStyles = makeStyles(() => ({
  radioGroup: {
    display: 'block',
    marginBottom: 10,
  },
}));

interface Props {
  siteConfig: UserSiteConfig,
  labelSelectOptions: Label[],
  roleChangeHandlerFactory: (site: SiteData['site']) => ((event: React.ChangeEvent<HTMLInputElement>, value: string) => void),
  labelsChangeHandlerFactory: (site: SiteData['site']) => ((event: ChangeEvent<{ value: unknown }>) => void),
}

const SiteOptionsInput = ({
  siteConfig,
  labelSelectOptions,
  roleChangeHandlerFactory,
  labelsChangeHandlerFactory,
}: Props) => {
  const classes = useStyles();

  const { site } = siteConfig;
  let selectedLabelText = '';
  const selectedLabel = labelsFromIds([siteConfig.reportingLabels[0]], labelSelectOptions || [])[0];
  if (selectedLabel) selectedLabelText = selectedLabel.text;

  return (
    <Box>
      <FormControl className={classes.radioGroup}>
        <FormLabel id="role-radio-group-label">{`Role at ${site}`}</FormLabel>
        <RadioGroup
          row
          aria-labelledby="role-radio-group-label"
          defaultValue={UserRole.User}
          value={siteConfig.role}
          onChange={roleChangeHandlerFactory(site)}
        >
          <FormControlLabel value={UserRole.User} control={<Radio />} label="User" />
          <FormControlLabel value={UserRole.Manager} control={<Radio />} label="Manager" />
          <FormControlLabel value={UserRole.SuperUser} control={<Radio />} label="Super User" />
        </RadioGroup>
      </FormControl>
      {labelSelectOptions != null
        && labelSelectOptions.length > 0
        && (
          <FormControl style={{ width: 350, marginBottom: 20 }} variant="outlined">
            <InputLabel id={`${site}-labels-select-label`}>{`Labels for ${site}`}</InputLabel>
            <Select
              labelId={`${site}-labels-select-label`}
              labelWidth={site.length + 130}
              value={selectedLabelText}
              onChange={labelsChangeHandlerFactory(site)}
              MenuProps={{ variant: 'menu' }}
            >
              {labelSelectOptions.map(({ id, text }) => (
                <MenuItem key={id} value={text}>{text}</MenuItem>
              ))}
              <MenuItem key="clear-select" value="clear-select">
                <Typography variant="button" color="primary">No Label</Typography>
              </MenuItem>
            </Select>
          </FormControl>
        )}
    </Box>
  );
};

export default SiteOptionsInput;
