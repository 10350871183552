import React from 'react';
import {
  Box,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';

interface Props {
  label: string;
  tooltip?: string;
  value: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(4, 0),
  },
  titleText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  tooltipIcon: {
    marginLeft: theme.spacing(1),
    color: '#0000008A',
  },
}));

const HeaderItem = ({
  label,
  tooltip,
  value,
}: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container} width="16%">
      <Typography className={classes.titleText} color="textSecondary" align="center">
        {label}
        {tooltip != null && (
          <Tooltip title={tooltip}>
            <Info className={classes.tooltipIcon} fontSize="small" />
          </Tooltip>
        )}
      </Typography>
      <Typography variant="h5">
        {value}
      </Typography>
    </Box>
  );
};

export default HeaderItem;
