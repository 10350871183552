import isNotNullOrUndefined from '../../../helpers/is-not-null-or-undefined';
import Label from '../../../types/Label';

const labelsFromIds = (labelIds: string[], labels: Label[]): Label[] => (
  labelIds
    .map((labelId) => labels.find((label) => label.id === labelId))
    .filter(isNotNullOrUndefined)
);

export default labelsFromIds;
