import { DateTime } from 'luxon';
import Papa from 'papaparse';

import downloadFile from '../../../helpers/downloadFile';
import csvFriendly from '../../../helpers/csv-friendly';

import GiftCardAuditTrail from '../../../types/GiftCardAuditTrail';

const downloadReport = (auditTrail: GiftCardAuditTrail[], site: string) => {
  const fileName = `${site}_gift_card_audit_trail.csv`;
  const csvData = auditTrail.map((trail) => ({
    giftCardId: csvFriendly(trail.giftCardId),
    code: csvFriendly(trail.code),
    source: csvFriendly(trail.source),
    action: csvFriendly(trail.action),
    user: csvFriendly(trail.user || ''),
    amount: csvFriendly(trail.amount),
    balance: csvFriendly(trail.balance),
    timestamp: csvFriendly(
      DateTime.fromISO(trail.timestamp).toLocaleString(DateTime.DATETIME_MED),
    ),
  }));
  const data = Papa.unparse(csvData);

  downloadFile({
    data,
    fileName,
    fileType: 'text/csv',
  });
};

export default downloadReport;
