import React,
{
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import {
  Box,
  Chip,
  Typography,
  Button,
  CardContent,
  Card,
  CardActions,
  makeStyles,
} from '@material-ui/core';
import MaterialTable from 'material-table';

import Spinner from '../../components/spinner/spinner';
import LabelCreator from '../../components/LabelCreator/LabelCreator';
import LabelEditor from './components/LabelEditor';
import PaymentInformation from './components/PaymentInformation';

import editReportingLabel from './helpers/editReportingLabel';
import setStripeSetupComplete from './helpers/setStripeSetupComplete';
import hasPermission from '../../helpers/has-permission';
import initialiseSiteListener from '../../helpers/initialiseSiteListener';
import apiRequest from '../../helpers/api-request';

import useUrlParams from '../../hooks/useUrlParams';

import RoleRestrictedAction from '../../types/RoleRestrictedAction';
import Nullable from '../../types/Nullable';
import ReduxState from '../../types/ReduxState';
import SiteData from '../../types/SiteData';
import User from '../../types/User';
import Label from '../../types/Label';
import getStripeAccountDetails from '../../helpers/getStripeAccountDetails';

type RowData = (
  Label
  & { labelTextEl: ReactElement, paymentInfoEl?: ReactElement, offline: boolean }
);

const useStyles = makeStyles({
  online: {
    color: 'green',
    borderColor: 'green',
  },
});

const ReportingLabelManagementPage = () => {
  const classes = useStyles();

  const authenticatedUser = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));

  const [siteData, setSiteData] = useState<Nullable<SiteData>>(null);
  const [error, setError] = useState<Nullable<Error>>(null);

  const [labelCreatorOpen, setLabelCreatorOpen] = useState<boolean>(false);
  const [labelToEdit, setLabelToEdit] = useState<Nullable<Label>>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [redirectError, setRedirectError] = useState<boolean>(false);

  const {
    values: [page, search, stripeRedirect, labelId],
    setters: [onPageChange, onSearchChange],
  } = useUrlParams(['page', 'search', 'stripeRedirect', 'labelId']);

  useEffect(() => {
    // the user is redirected to this page following Stripe account setup
    const handleStripeRedirect = async () => {
      if (
        authenticatedUser != null
        && stripeRedirect != null
        && labelId != null
        && siteData != null
        && siteData.reportingLabels != null
      ) {
        const filteredLabel = siteData.reportingLabels.find((label) => label.id === labelId);
        if (filteredLabel != null && filteredLabel.stripeAccountId != null) {
          try {
            setLoading(true);
            const {
              stripeAccountDetails: {
                detailsSubmitted: isStripeSetupComplete,
              },
            } = await getStripeAccountDetails(
              filteredLabel.stripeAccountId,
            );
            if (isStripeSetupComplete) {
              await setStripeSetupComplete(labelId, authenticatedUser.site);
            }
          } catch {
            setRedirectError(true);
          } finally {
            setLoading(false);
          }
        }
      }
    };

    handleStripeRedirect();
  }, [siteData]);

  useEffect(() => {
    if (authenticatedUser) {
      return initialiseSiteListener(authenticatedUser.site, setSiteData, setError);
    }
    return () => {};
  }, [authenticatedUser]);

  const siteReportingLabels = useMemo(() => {
    if (siteData?.reportingLabels == null) return [];
    return siteData.reportingLabels.map((label) => (
      { ...label, active: label.active != null ? label.active : true }
    ));
  }, [siteData]);

  const handleAddLabelClick = useCallback(
    () => setLabelCreatorOpen(true),
    [setLabelCreatorOpen],
  );

  const handleEditLabelClick = useCallback(
    (rowData: RowData) => {
      const label = siteReportingLabels.find(({ id }) => id === rowData.id);
      if (label) setLabelToEdit(label);
    },
    [setLabelToEdit, siteReportingLabels],
  );

  const onReportingLabelSave = useCallback(async (label: Label) => {
    if (!siteData) throw new Error('site settings could not be found');
    if (!authenticatedUser) throw new Error('current user could not be found');
    await apiRequest(
      'create-label',
      'POST',
      {
        siteId: siteData.id,
        label,
      },
    );
    setLabelCreatorOpen(false);
  }, [siteData]);

  const onLabelEditSave = useCallback(async (updatedLabel: Label) => {
    if (!siteData) throw new Error('site settings could not be found');
    if (!authenticatedUser) throw new Error('current user could not be found');
    await editReportingLabel(siteData, updatedLabel);
    setLabelToEdit(null);
  }, [setLabelToEdit, siteData, authenticatedUser]);

  const onLabelEditCancel = useCallback(() => setLabelToEdit(null), [setLabelToEdit]);

  const tableColumns = useMemo(() => [
    {
      title: 'Label Text',
      field: 'labelTextEl',
      customSort: (a: RowData, b: RowData) => (a.text > b.text ? 1 : -1),
    },
    {
      title: 'Status',
      field: 'offline',
      customSort: (a: RowData, b: RowData) => (a.offline > b.offline ? 1 : -1),
      render: (rowData: RowData) => (rowData.offline
        ? (
          <Chip
            label="Offline"
            color="secondary"
            variant="outlined"
          />
        )
        : (
          <Chip
            className={classes.online}
            label="Online"
            variant="outlined"
          />
        )),
    },
    {
      title: 'Active',
      field: 'active',
      render: (rowData: RowData) => (rowData.active != null && !rowData.active
        ? (
          <Chip
            label="Inactive"
            color="secondary"
            variant="outlined"
          />
        )
        : (
          <Chip
            className={classes.online}
            label="Active"
            variant="outlined"
          />
        )),
    },
    { title: 'Payment Processing Information', field: 'paymentInfoEl', sorting: false },
  ], []);

  const tableData = useMemo(() => {
    if (siteData == null || siteData.reportingLabels == null) return [];
    const offlineLabels = siteData.offlineLabels || [];
    // the next line is required because MaterialTable mutates data passed to it.
    const data = siteReportingLabels.map((label) => {
      const rowData: RowData = {
        ...label,
        offline: offlineLabels.includes(label.id),
        labelTextEl: <Typography>{label.text}</Typography>,
      };
      if (
        authenticatedUser != null
        && hasPermission(RoleRestrictedAction.ViewLabelPaymentSettings, authenticatedUser)
      ) {
        rowData.paymentInfoEl = (
          <PaymentInformation
            label={label}
            onLabelEditSave={onLabelEditSave}
            siteId={siteData.id}
            authenticatedUser={authenticatedUser}
            settings={siteData}
          />
        );
      }
      return rowData;
    });
    if (authenticatedUser == null) return [];
    const config = authenticatedUser.sites.find((s) => s.site === authenticatedUser.site);
    if (
      config != null
      && config.reportingLabels.length > 0
    ) {
      return data.filter((row) => config.reportingLabels.includes(row.id));
    }
    return data;
  }, [siteData, siteReportingLabels]);

  const tableActions = useMemo(() => [
    {
      icon: 'add',
      tooltip: 'Create Reporting Label',
      isFreeAction: true,
      onClick: handleAddLabelClick,
    },
    (label: RowData) => ({
      icon: 'edit',
      tooltip: 'Edit Reporting Label',
      onClick: () => handleEditLabelClick(label),
      disabled: (
        authenticatedUser == null
        || !hasPermission(RoleRestrictedAction.EditReportingLabelText, authenticatedUser)
      ),
    }),
  ], [handleAddLabelClick, handleEditLabelClick]);

  const isError = useMemo(() => redirectError || error, [redirectError, error]);

  if (loading || !siteData || !authenticatedUser) return <Spinner />;

  const { name: siteName } = siteData;

  return (
    <Box>
      {isError && (
        <Typography>
          Something went wrong. Please refresh this page in order to fetch the latest data.
        </Typography>
      )}
      {tableData.length > 0
        ? (
          <MaterialTable
            title={`Reporting Labels for ${siteName}`}
            columns={tableColumns}
            data={tableData}
            actions={tableActions}
            options={{
              actionsColumnIndex: -1,
              searchText: search || '',
              initialPage: Number(page || 0),
              sorting: true,
            }}
            onSearchChange={onSearchChange}
            onChangePage={(newPage) => onPageChange(String(newPage))}
          />
        ) : (
          <Card>
            <CardContent>
              <Typography variant="h6">
                {`There are currently no Reporting Labels for ${siteName}`}
              </Typography>
            </CardContent>
            <CardActions>
              <Button color="primary" onClick={handleAddLabelClick}>Create A Reporting Label</Button>
            </CardActions>
          </Card>
        )}
      <LabelCreator
        open={labelCreatorOpen}
        setOpen={setLabelCreatorOpen}
        onLabelSave={onReportingLabelSave}
      />
      {labelToEdit != null
        && (
          <LabelEditor
            label={labelToEdit}
            onLabelEditSave={onLabelEditSave}
            onLabelEditCancel={onLabelEditCancel}
          />
        )}
    </Box>
  );
};

export default ReportingLabelManagementPage;
