import React, { useMemo } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import CheckBox from '../../../components/check-box/check-box';
import QuantitySelector from '../../../components/QuantitySelector/QuantitySelector';
import AddOn from '../../../types/AddOn';
import ItemToRefund from '../types/ItemToRefund';
import LabelPlacement from '../../../components/check-box/types/LabelPlacement';

const useStyles = makeStyles(() => ({
  addOns: {
    marginLeft: 10,
  },
}));

const RefundAddOn = (
  parentItem: ItemToRefund | null,
  addOn: AddOn,
  orderQuantity: number,
  onCheckboxClick: (addOn: AddOn, event: React.ChangeEvent<HTMLInputElement>) => void,
  onQuantityChange: (addOnPlu: number, newQuantity: number) => void,
) => {
  const classes = useStyles();

  const addOnQuantityAlreadyRefunded = useMemo(
    () => addOn.quantityRefunded || 0,
    [addOn],
  );
  const addOnAlreadyRefunded = useMemo(
    () => addOnQuantityAlreadyRefunded === orderQuantity,
    [addOnQuantityAlreadyRefunded, orderQuantity],
  );
  const existingAddOnItemToRefund = useMemo(() => (
    parentItem != null
      ? parentItem.refundedAddOns.find(({ plu }) => addOn.plu === plu) || null
      : null
  ), [parentItem, addOn]);

  return (
    <Grid key={addOn.plu} className={classes.addOns}>
      <CheckBox
        label={`${orderQuantity}x ${addOn.name}`}
        checked={addOnAlreadyRefunded || (existingAddOnItemToRefund != null)}
        disabled={addOnAlreadyRefunded}
        onChange={(event) => onCheckboxClick(addOn, event)}
        labelPlacement={LabelPlacement.End}
      />
      {existingAddOnItemToRefund != null && orderQuantity > 1 && (
        <QuantitySelector
          onChange={(value) => onQuantityChange(addOn.plu, value)}
          value={existingAddOnItemToRefund.quantity}
          maxValue={orderQuantity - addOnQuantityAlreadyRefunded}
        />
      )}
    </Grid>
  );
};

export default RefundAddOn;
