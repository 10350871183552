import Status from '../../../types/Status';

const getStatusTitle = (status: Status) => {
  switch (status) {
    case Status.Preparing:
      return 'Preparing...';
    case Status.Ready:
      return 'Please Collect';
    case Status.Complete:
      return 'Complete';
    default:
      return status;
  }
};

export default getStatusTitle;
