import CharityDonation from 'src/types/CharityDonation';
import CharityDiscountType from '../../../types/CharityDonationType';

const getDefaultCharityDonation: CharityDonation = {
  value: 0,
  plu: 0,
  displayName: '',
  isCompulsory: false,
  type: CharityDiscountType.FixedValue,
  isActive: false,
};

export default getDefaultCharityDonation;
