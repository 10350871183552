import React, { useCallback, useMemo } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import Label from '../../../types/Label';
import labelsFromIds from '../helpers/labels-from-ids';
import isNotNullOrUndefined from '../../../helpers/is-not-null-or-undefined';

interface Props {
  multiple: boolean,
  value: Label['id'][],
  labels: Label[],
  onChange: (newValue: Label['id'][]) => void,
  setDialogOpen: (value: boolean) => void,
  inputLabel: string,
  inputLabelWidth: number,
  canCreateLabels: boolean,
  canClearLabels: boolean,
  disabled: boolean,
  width: number | string
}

const LabelSelect = ({
  multiple,
  value,
  labels,
  onChange,
  setDialogOpen,
  inputLabel,
  inputLabelWidth = 125,
  width,
  canCreateLabels,
  canClearLabels,
  disabled,
}: Props) => {
  const selectedLabelNames = useMemo(
    () => labelsFromIds(value, labels).map((label) => label.text),
    [value, labels],
  );

  const handleChange = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    let { value: newValue } = event.target;
    if (!multiple) {
      newValue = [newValue];
    }
    const eventValue = newValue as string[];
    if (eventValue.includes('create-label')) {
      setDialogOpen(true);
    } else if (eventValue.includes('clear-select')) {
      onChange([]);
    } else {
      const newLabelSelection = eventValue.map(
        (labelName) => labels.find((label) => label.text === labelName)?.id,
      ).filter(isNotNullOrUndefined);
      onChange(newLabelSelection);
    }
  }, [labels, onChange]);

  return (
    <FormControl style={{ minWidth: width }} variant="outlined">
      <InputLabel id="label">{inputLabel}</InputLabel>
      <Select
        multiple={multiple}
        labelId="label"
        labelWidth={inputLabelWidth}
        value={selectedLabelNames}
        onChange={handleChange}
        MenuProps={{ variant: 'menu' }}
        disabled={disabled}
      >
        {labels.map(
          (label) => <MenuItem key={label.id} value={label.text}>{label.text}</MenuItem>,
        )}
        {canCreateLabels && (
          <MenuItem key="create-label" value="create-label">
            <Typography variant="button" color="primary">Create New Label</Typography>
          </MenuItem>
        )}
        {canClearLabels && (
          <MenuItem key="clear-select" value="clear-select">
            <Typography variant="button" color="error">Clear</Typography>
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default LabelSelect;
