import firebase from 'firebase/app';

import User from 'src/types/User';

const resetUserAttempts = (user: User) => firebase
  .firestore()
  .collection('userdata')
  .doc(user.id)
  .update({
    sites: user.sites.map((userSite) => {
      if (userSite.site !== user.site) return userSite;
      return {
        ...userSite,
        loginAttempts: 0,
        accountLocked: false,
      };
    }),
  });

export default resetUserAttempts;
