import firebase from 'firebase/app';

const stockProducts = async (
  site: string,
  labelId?: string,
) => {
  const productsCollection = firebase
    .firestore()
    .collection('masterproducts');

  let query = productsCollection
    .where('site', '==', site)
    .where('soldOut', '==', true);
  if (labelId != null) {
    query = query.where('reportingLabels', 'array-contains', labelId);
  }
  const snapshot = await query.get();

  const batch = firebase.firestore().batch();
  snapshot.forEach(({ id }) => {
    batch.update(productsCollection.doc(id), { soldOut: false });
  });
  await batch.commit();
};

export default stockProducts;
