import React, { useCallback, useState } from 'react';

import {
  Box,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';

import LoadingSwitch from './LoadingSwitch';
import FadeInSpinner from './FadeInSpinner';

import debounce from '../../../helpers/debounce';

import Label from '../../../types/Label';

const useStyles = makeStyles(() => ({
  formHeading: {
    marginBottom: 15,
  },
  textInput: {
    marginBottom: 20,
    marginRight: 20,
    width: 150,
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  waitingTimesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));

type Props = {
  label: Label,
  onLabelWaitingTimeChange: (drinksWaitingTime: number, foodWaitingTime: number) => Promise<void>,
  isLabelOffline: boolean,
  onLabelOfflineStatusChange: (value: boolean) => Promise<void>,
  displayLabelStatusSwitch: boolean,
};

const LabelBox = ({
  label,
  onLabelWaitingTimeChange,
  isLabelOffline,
  onLabelOfflineStatusChange,
  displayLabelStatusSwitch,
}: Props) => {
  const classes = useStyles();
  const [labelOfflineLoading, setLabelOfflineLoading] = useState<boolean>(false);
  const [waitingTimesLoading, setWaitingTimesLoading] = useState<boolean>(false);
  const [drinksWaitingTime, setDrinksWaitingTime] = useState<number>(label.drinksWaitingTime || 0);
  const [foodWaitingTime, setFoodWaitingTime] = useState<number>(label.foodWaitingTime || 0);

  const handleOfflineStatusChange = useCallback(
    async (value: boolean) => {
      setLabelOfflineLoading(true);
      await onLabelOfflineStatusChange(value);
      setLabelOfflineLoading(false);
    },
    [setLabelOfflineLoading, label],
  );

  const updateDrinksWaitingTime = useCallback(
    debounce(
      (async (newDrinksWaitingTime: number) => {
        setWaitingTimesLoading(true);
        await onLabelWaitingTimeChange(newDrinksWaitingTime, label.foodWaitingTime || 0);
        setWaitingTimesLoading(false);
      }),
      1000,
    ),
    [setWaitingTimesLoading, label],
  );

  const handleDrinksWaitingTimeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newDrinksWaitingTime = Number(event.target.value);
      if (!Number.isNaN(newDrinksWaitingTime)) {
        setDrinksWaitingTime(newDrinksWaitingTime);
        updateDrinksWaitingTime(newDrinksWaitingTime);
      }
    },
    [setDrinksWaitingTime],
  );

  const updateFoodWaitingTime = useCallback(
    debounce(
      (async (newFoodWaitingTime: number) => {
        setWaitingTimesLoading(true);
        await onLabelWaitingTimeChange(label.drinksWaitingTime || 0, newFoodWaitingTime);
        setWaitingTimesLoading(false);
      }),
      1000,
    ),
    [setWaitingTimesLoading],
  );

  const handleFoodWaitingTimeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newFoodWaitingTime = Number(event.target.value);
      if (!Number.isNaN(newFoodWaitingTime)) {
        setFoodWaitingTime(newFoodWaitingTime);
        updateFoodWaitingTime(newFoodWaitingTime);
      }
    },
    [setFoodWaitingTime],
  );

  return (
    <Box>
      <Typography variant="subtitle1" className={classes.formHeading}>{label.text}</Typography>
      <Box className={classes.optionsContainer}>
        {displayLabelStatusSwitch && (
          <LoadingSwitch
            label={isLabelOffline ? 'Offline' : 'Online'}
            active={!isLabelOffline}
            onChange={handleOfflineStatusChange}
            disabled={labelOfflineLoading}
            loading={labelOfflineLoading}
          />
        )}
        <Box className={classes.waitingTimesContainer}>
          <TextField
            label="Drink Waiting Time"
            className={classes.textInput}
            variant="outlined"
            value={drinksWaitingTime}
            disabled={waitingTimesLoading}
            onChange={handleDrinksWaitingTimeChange}
          />
          <TextField
            label="Food Waiting Time"
            className={classes.textInput}
            variant="outlined"
            value={foodWaitingTime}
            disabled={waitingTimesLoading}
            onChange={handleFoodWaitingTimeChange}
          />
          {waitingTimesLoading && (
            <FadeInSpinner loading={waitingTimesLoading} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LabelBox;
