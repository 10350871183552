import React, { useCallback, useState } from 'react';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
} from '@material-ui/core';

import OutlinedTextField from '../../outlined-text-field/outlined-text-field';

import isFormDiscountCode from '../../../helpers/isFormDiscountCode';

import DiscountCode from '../../../types/DiscountCode';
import FormDiscountCode from '../../../types/FormDiscountCode';
import Nullable from '../../../types/Nullable';

type Props = {
  codes: DiscountCode['code'][]
  open: boolean
  onAdd: (discountCode: DiscountCode) => void
  onClose: () => void
};

const useStyles = makeStyles(() => ({
  item: {
    padding: 5,
  },
}));

const DiscountCodeDialog = ({
  codes, open, onAdd, onClose,
}: Props) => {
  const [discountCode, setDiscountCode] = useState<FormDiscountCode>({
    code: '',
    maxUsages: '',
  });
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const classes = useStyles();

  const { code, maxUsages } = discountCode;

  const handleChange = useCallback(<T extends keyof FormDiscountCode>(
    label: T, val: FormDiscountCode[T],
  ) => {
    setDiscountCode({ ...discountCode, [label]: val });
  }, [discountCode]);

  const handleClose = useCallback(() => {
    setDiscountCode({
      code: '',
      maxUsages: '',
    });
    setErrorMessage(null);
    onClose();
  }, [onClose]);

  const handleAdd = useCallback(() => {
    setErrorMessage(null);
    if (codes.includes(code)) {
      setErrorMessage('Discount Code already in use.');
    } else if (!isFormDiscountCode(discountCode)) {
      setErrorMessage('Discount Code is not valid.');
    } else {
      onAdd({
        ...discountCode,
        maxUsages: discountCode.maxUsages === '' ? null : Number(discountCode.maxUsages),
        usages: 0,
      });
      handleClose();
    }
  }, [codes, discountCode, handleClose]);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="discount-code-dialog" fullWidth>
      <DialogTitle id="discount-code-dialog">Create Discount Code</DialogTitle>
      <DialogContent dividers>
        <OutlinedTextField
          id="code"
          classOption="flex"
          label="Discount Code"
          value={code}
          onChange={(event) => handleChange('code', event.target.value)}
          required
        />
        <OutlinedTextField
          id="outlined-code-usages"
          classOption="flex"
          type="number"
          label="Max Code Usages"
          value={maxUsages}
          onChange={(event) => handleChange('maxUsages', event.target.value)}
          helperText="Unlimited Usages if left blank."
        />
        {errorMessage && (
          <Typography className={classes.item} variant="body1" color="error" gutterBottom>{errorMessage}</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleAdd} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiscountCodeDialog;
