import React, { Dispatch, SetStateAction } from 'react';

import {
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ImageUpload from '../../image-upload/image-upload';
import EditableSiteData from '../../../types/EditableSiteData';
import Nullable from '../../../types/Nullable';

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    paddingLeft: theme.spacing(3),
  },
  siteLogoGrid: {
    padding: 10,
    width: theme.spacing(18.75),
    height: theme.spacing(12.5),
    marginBottom: 20,
  },
  siteLogo: {
    width: theme.spacing(18.75),
    height: theme.spacing(12.5),
    objectFit: 'contain',
    objectPosition: '50% 50%',
  },
}));

type Props = {
  logo: string | undefined
  handleChange: (newSiteData: EditableSiteData) => void
  setSelectedFile: Dispatch<SetStateAction<Nullable<File>>>
  site: EditableSiteData
};

const SiteLogoImage = ({
  logo,
  handleChange,
  setSelectedFile,
  site,
}: Props) => {
  const classes = useStyles();

  const handleLogoImageChange = (file: File, preview: string) => {
    setSelectedFile(file);
    handleChange({ ...site, logo: preview });
  };

  const handleLogoImageRemove = () => {
    setSelectedFile(null);
    handleChange({ ...site, logo: '' });
  };

  return (
    <>
      <Typography variant="h6">Site Logo</Typography>
      <Box className={classes.sectionContainer}>
        <ImageUpload
          id="logo"
          buttonLabel="Upload site logo"
          onChange={handleLogoImageChange}
          onRemove={handleLogoImageRemove}
          currentImage={logo}
          description="The image should have an aspect ratio of 3:2 and best resolution is 150x100."
          previewGridStyles={classes.siteLogoGrid}
          previewStyles={classes.siteLogo}
        />
      </Box>
    </>
  );
};

export default SiteLogoImage;
