import Allergen from '../../../types/Allergen';

const Allergens = [
  Allergen.Celery,
  Allergen.Gluten,
  Allergen.Shellfish,
  Allergen.Molluscs,
  Allergen.Eggs,
  Allergen.Fish,
  Allergen.Lupin,
  Allergen.Milk,
  Allergen.Mustard,
  Allergen.Peanuts,
  Allergen.Sesame,
  Allergen.Soybeans,
  Allergen.Sulphites,
  Allergen.TreeNuts,
];

export default Allergens;
