import React from 'react';
import { Link } from 'react-router-dom';

import {
  Avatar,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Grid,
  Typography,
} from '@material-ui/core';

import getRoutes from '../../helpers/getRoutes';
import User from '../../types/User';
import isDrawerRoute from './helpers/isDrawerRoute';
import RouteLabelWithChip from '../RouteLabelWithChip/RouteLabelWithChip';

const useStyles = makeStyles((theme) => ({
  userArea: {
    marginTop: 20,
    marginBottom: 20,
  },
  image: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: 10,
  },
  list: {
    width: 250,
  },
}));

interface Props {
  open: boolean
  onClose: () => void
  user: User
}

const AppDrawer = ({
  open, onClose, user,
}: Props) => {
  const classes = useStyles();
  const routes = getRoutes(user);

  return (
    <Drawer open={open} onClose={onClose}>
      <div
        className={classes.list}
        role="presentation"
        onClick={onClose}
        onKeyDown={onClose}
      >
        {user && user.name ? (
          <>
            <Grid
              className={classes.userArea}
              direction="column"
              alignItems="center"
              container
            >
              <Avatar className={classes.image} />
              <Typography>{user.name || ''}</Typography>
              <Typography>{`(${user.site || ''})`}</Typography>
            </Grid>
            <Divider />
          </>
        ) : null}
        <List className={classes.list}>
          {routes.map((route) => isDrawerRoute(route) && (
            <ListItem button key={route.label} component={Link} to={route.url}>
              <ListItemIcon><route.icon /></ListItemIcon>
              <ListItemText
                primary={route.chip != null
                  ? (
                    <RouteLabelWithChip labelText={route.label} chip={route.chip} />
                  ) : route.label}
              />
            </ListItem>
          ))}
        </List>
      </div>
    </Drawer>
  );
};

export default AppDrawer;
