import RefundReason from '../../../types/RefundReason';

const convertRefundReason = (refundReason: string): string => {
  switch (refundReason) {
    case RefundReason.Duplicate:
      return 'Duplicate';
    case RefundReason.Fraudulent:
      return 'Fraudulent';
    case RefundReason.RequestedByCustomer:
      return 'Requested by Customer';
    default:
      return 'Other';
  }
};

export default convertRefundReason;
