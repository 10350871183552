import firebase from 'firebase/app';
import {
  GET_SITE_MAP_START,
  GET_SITE_MAP_SUCCESS,
  GET_SITE_MAP_ERROR,
} from '../constants/actions';

export default (site) => (dispatch) => {
  dispatch({
    type: GET_SITE_MAP_START,
  });
  firebase
    .firestore()
    .collection('sitemap')
    .where('site', '==', site)
    .get()
    .then((snapshots) => {
      const siteMaps = [];
      let siteMapId = '';
      snapshots.forEach((snapshot) => {
        siteMapId = snapshot.id;
        snapshot.data().products.forEach((product) => {
          siteMaps.push(product);
        });
      });
      dispatch({
        type: GET_SITE_MAP_SUCCESS,
        siteMaps,
        siteMapId,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_SITE_MAP_ERROR,
        message: 'Failed to fetch Site Map.',
      });
    });
};
