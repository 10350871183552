import { Dispatch, SetStateAction } from 'react';
import updateSite from './update-site';
import getStripeAccountDetails from '../../../helpers/getStripeAccountDetails';

const addStripeConnectActiveFlag = async (
  site: string,
  stripeId: string,
  setStripeSetupComplete: Dispatch<SetStateAction<boolean>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
) => {
  try {
    const {
      stripeAccountDetails: {
        detailsSubmitted: isStripeSetupComplete,
      },
    } = await getStripeAccountDetails(stripeId);
    if (isStripeSetupComplete) {
      setStripeSetupComplete(true);
      await updateSite(site, { stripeConnectActive: true });
    } else {
      setStripeSetupComplete(false);
    }
  } catch (error) {
    setStripeSetupComplete(false);
  }
  setIsLoading(false);
};

export default addStripeConnectActiveFlag;
