import React from 'react';

import {
  Checkbox, Grid, makeStyles, Typography,
} from '@material-ui/core';

import OnlineTimes from './online-times';
import OpeningTimes from './opening-times';
import CheckBox from '../../check-box/check-box';

import getDefaultTimes from '../helpers/getDefaultTimes';
import getDefaultCollectionTimes from '../helpers/getDefaultCollectionTimes';
import getDefaultDeliveryTimes from '../helpers/getDefaultDeliveryTimes';

import CollectionTime from '../../../types/CollectionTime';
import DeliveryTime from '../../../types/DeliveryTime';
import EditableSiteData from '../../../types/EditableSiteData';
import OnlineTimeType from '../../../types/OnlineTimeType';

type Props = {
  settings: EditableSiteData
  onChange: (newSiteData: EditableSiteData) => void
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  h6: {
    width: 285,
  },
  checkbox: {
    marginRight: 80,
  },
}));

const OpeningTimesForm = ({ settings, onChange }: Props) => {
  const classes = useStyles();

  const collection: CollectionTime[] = settings.collection && settings.collection.length !== 0
    ? settings.collection
    : getDefaultCollectionTimes();
  const delivery: DeliveryTime[] = settings.delivery && settings.delivery.length !== 0
    ? settings.delivery
    : getDefaultDeliveryTimes();
  const openingTimes = settings.openingTimes || getDefaultTimes();
  const allowCollection = settings.collection && settings.collection.length !== 0;
  const allowDelivery = settings.delivery && settings.delivery.length !== 0;

  const handleChange = (name: string, value: string | boolean, day: number) => {
    const newTimes = openingTimes.map((time, i) => (i === day ? { ...time, [name]: value } : time));
    onChange({ ...settings, openingTimes: newTimes });
  };

  const handleCollectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCollection = event.target.checked ? getDefaultCollectionTimes() : null;
    onChange({ ...settings, collection: newCollection });
  };

  const handleDeliveryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDelivery = event.target.checked ? getDefaultDeliveryTimes() : null;
    onChange({ ...settings, delivery: newDelivery });
  };

  return (
    <div className={classes.root}>
      {
        allowCollection || allowDelivery
          ? (
            <>
              <CheckBox label="Allow Collection" checked={allowCollection || false} onChange={handleCollectionChange} />
              {allowCollection && (
                <OnlineTimes
                  type={OnlineTimeType.Collection}
                  settings={settings}
                  onlineTime={collection}
                  onChange={onChange}
                />
              )}
              <CheckBox label="Allow Delivery" checked={allowDelivery || false} onChange={handleDeliveryChange} />
              {allowDelivery && (
                <OnlineTimes
                  type={OnlineTimeType.Delivery}
                  settings={settings}
                  onlineTime={delivery}
                  onChange={onChange}
                />
              )}
            </>
          )
          : (
            <>
              <OpeningTimes type="Non-kitchen" times={openingTimes} onChange={handleChange} />
              <OpeningTimes type="Kitchen" times={openingTimes} onChange={handleChange} />
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <Typography classes={{ h6: classes.h6 }} variant="h6" color="inherit" gutterBottom>
                  Closed
                </Typography>
                {openingTimes.map((time, i) => (
                  <Checkbox
                    className={classes.checkbox}
                    checked={time.closed}
                    onChange={(event) => handleChange('closed', event.target.checked, i)}
                    value="primary"
                  />
                ))}
              </Grid>
            </>
          )
      }
    </div>
  );
};

export default OpeningTimesForm;
