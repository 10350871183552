import React,
{
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Button,
  IconButton,
  Snackbar,
  makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { DragDropContext } from 'react-beautiful-dnd';

import Nullable from '../../types/Nullable';
import ReduxState from '../../types/ReduxState';
import User from '../../types/User';
import Label from '../../types/Label';
import OrderData from '../../types/OrderData';
import SiteData from '../../types/SiteData';
import InvolvesKitchen from '../../types/InvolvesKitchen';
import ProductType from '../../types/ProductType';

import getOrders from '../../actions/get-orders';

import Panel from './components/panel';

import getSiteTradeDate from './helpers/get-site-trade-date';
import ordersSelector from './helpers/all-orders-selector';
import initialiseUserListener from './helpers/initlialiseUserListener';
import initialiseSiteListener from '../../helpers/initialiseSiteListener';
import labelsFromIds from '../../components/label-input/helpers/labels-from-ids';
import isLabelArray from '../edit-user-page/helpers/isLabelArray';
import saveDispenseSelection from './helpers/save-dispense-selection';
import isInvolvesKitchen from './helpers/isInvolvesKitchen';
import filterOrderBySelectedLabels from './helpers/filterOrderBySelectedLabels';
import OrderFlowLane from './components/OrderFlowLane';
import Status from '../../types/Status';
import updateOrder from './helpers/update-order';
import OrderItem from '../../types/OrderItem';
import apiRequest from '../../helpers/api-request';
import reopenOrder from './helpers/reopen-order';
import calculateOrderAge from './helpers/calculateOrderAge';
import notifyUser from './helpers/notifyUser';

const useStyles = makeStyles({
  swimLaneContainer: {
    height: '100%',
  },
});

const DispenseScreenPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const authenticatedUser = useSelector<ReduxState, Nullable<User>>(
    (state) => state.authentication.get('USER'),
  );
  const orders = useSelector<
  ReduxState,
  {
    all: { inhouse: OrderData[], online: OrderData[] },
    outstanding: { inhouse: OrderData[], online: OrderData[] },
  }
  >(ordersSelector);

  const [user, setUser] = useState<Nullable<User>>(null);
  const [site, setSite] = useState<Nullable<SiteData>>(null);
  const [showCompleted, setShowCompleted] = useState<boolean>(false);
  const [involvesKitchen, setInvolvesKitchen] = useState<InvolvesKitchen>(InvolvesKitchen.All);
  const [labelSelectValue, setLabelSelectValue] = useState<Label[]>([]);
  const [outstandingOrdersAlert, setOutstandingOrdersAlert] = useState<boolean>(false);
  const [error, setError] = useState<Nullable<Error>>(null);
  const [showErrorOpen, setShowErrorOpen] = useState<boolean>(false);
  const [ordersToShow, setOrdersToShow] = useState<OrderData[]>([]);
  const [orderFlowColumns, setOrderFlowColumns] = useState<Status[]>(
    [Status.Outstanding, Status.Complete],
  );
  const [snackbarOrders, setSnackbarOrders] = useState<OrderData[]>([]);

  useEffect(() => {
    if (authenticatedUser) {
      initialiseUserListener(authenticatedUser.id, setUser, setError);
      initialiseSiteListener(authenticatedUser.site, setSite, setError);
      getSiteTradeDate(authenticatedUser.site)
        .then((tradeDate) => dispatch(getOrders(authenticatedUser.site, tradeDate)));
    }
  }, [authenticatedUser]);

  useEffect(() => {
    if (authenticatedUser && authenticatedUser.dispenseSelection) {
      const { dispenseSelection } = authenticatedUser;
      setInvolvesKitchen(dispenseSelection.involvesKitchen);
      if (site && site.reportingLabels && dispenseSelection.reportingLabels) {
        setLabelSelectValue(labelsFromIds(dispenseSelection.reportingLabels, site.reportingLabels));
      }
    }
  }, [site]);

  useEffect(() => {
    if (user) {
      saveDispenseSelection(
        user.id,
        involvesKitchen,
        labelSelectValue.map((label) => label.id),
      );
    }
  }, [involvesKitchen, labelSelectValue]);

  const orderFlowSelectOptions: Status[] = useMemo(() => {
    if (
      site != null
      && site.orderFlow != null
      && site.orderFlow.statusBoard != null
      && site.orderFlow.statusBoard.length !== 0
    ) {
      return [Status.Outstanding, ...site.orderFlow.statusBoard, Status.Complete];
    }
    return [Status.Outstanding, Status.Complete];
  }, [site]);

  const onOrderFlowSelectChange = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    if (Array.isArray(value)) {
      setOrderFlowColumns(orderFlowSelectOptions.filter((status) => value.includes(status)));
    }
  }, [site, orderFlowSelectOptions]);

  const userSiteConfig = useMemo(
    () => user?.sites.find((siteConfig) => siteConfig.site === site?.site),
    [user, site],
  );

  const onShowCompletedClick = useCallback(() => (
    setShowCompleted((currentValue) => !currentValue)
  ), []);

  const onOutstandingOrdersAlertClick = useCallback(() => (
    setOutstandingOrdersAlert((currentValue) => !currentValue)
  ), []);

  const onInvolvesKitchenChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (isInvolvesKitchen(value)) {
      setInvolvesKitchen(value);
    }
  }, [setInvolvesKitchen]);

  const labelSelectOptions = useMemo(() => {
    if (
      (userSiteConfig && userSiteConfig.reportingLabels.length === 0)
      && (site && site.reportingLabels && site.reportingLabels.length > 0)
    ) {
      return site.reportingLabels;
    }

    if (
      (userSiteConfig && userSiteConfig.reportingLabels.length > 0)
      && (site && site.reportingLabels && site.reportingLabels.length > 0)
    ) {
      const relevantLabelIds = userSiteConfig.reportingLabels.filter(
        (labelId) => site.reportingLabels?.some(({ id }) => id === labelId),
      );
      return labelsFromIds(relevantLabelIds, site.reportingLabels);
    }
    return [];
  }, [userSiteConfig, site]);

  useEffect(() => {
    if (userSiteConfig
      && userSiteConfig.reportingLabels.length > 0
      && labelSelectOptions.length === 1) {
      setLabelSelectValue(labelSelectOptions);
    }
  }, [userSiteConfig, labelSelectOptions]);

  const onLabelSelectChange = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    if (Array.isArray(value) && site && site.reportingLabels) {
      const newLabelSelection = value.map((labelText) => (
        labelSelectOptions.find((label) => label.text === labelText)
      ));
      if (isLabelArray(newLabelSelection)) setLabelSelectValue(newLabelSelection);
    }
  }, [site, labelSelectOptions, setLabelSelectValue]);

  useEffect(() => {
    if (showCompleted) {
      setOrdersToShow(orders.all.inhouse.concat(orders.all.online));
    } else {
      setOrdersToShow(orders.outstanding.inhouse.concat(orders.outstanding.online));
    }
  }, [orders, showCompleted]);

  const orderCountsLabels = useMemo(() => {
    if (userSiteConfig?.reportingLabels.length === 1) {
      return labelSelectOptions;
    }
    return labelSelectValue;
  }, [labelSelectOptions, labelSelectValue]);

  const outstandingOrdersCount = useMemo(() => {
    const { inhouse, online } = orders.outstanding;
    return (
      filterOrderBySelectedLabels(inhouse, orderCountsLabels, true).length
      + filterOrderBySelectedLabels(online, orderCountsLabels, true).length
    );
  }, [orders, orderCountsLabels]);

  const todaysOrdersCount = useMemo(() => {
    const { inhouse, online } = orders.all;
    return (
      filterOrderBySelectedLabels(inhouse, orderCountsLabels).length
      + filterOrderBySelectedLabels(online, orderCountsLabels).length
    );
  }, [orders, orderCountsLabels]);

  const handleShowErrorClick = useCallback(
    () => setShowErrorOpen((currentValue) => !currentValue),
    [setShowErrorOpen],
  );

  const handleErrorOkClick = useCallback(() => setError(null), [setError]);

  const filterOrdersByColumn = useCallback((columnStatus: Status) => (
    ordersToShow.filter((orderItem) => orderItem.order.some((item) => {
      if (item.reportingLabels != null && labelSelectValue.length > 0) {
        return (item.reportingLabels.some((reportingLabel) => (
          labelSelectValue.some((value) => value.id === reportingLabel)
        ))
          && item.status === columnStatus);
      }
      return (
        item.status === columnStatus
        && item.serviceCharge !== true
        && item.tip !== true
        && item.refunded !== true
        && item.discount !== true
        && item.charityDonation !== true
        && (item.type || '') !== ProductType.GiftCard
      );
    }))
  ), [orders, ordersToShow, labelSelectValue]);

  const onDragEnd = async (result: any) => {
    const { draggableId, source, destination } = result;
    if (destination == null) return;
    const fullOrderId = draggableId.replace(`/${source.droppableId}`, '');

    if (
      source.droppableId !== destination.droppableId
    ) {
      const foundOrder = ordersToShow.find((order) => order.id === fullOrderId);
      const cartIds: OrderItem['cartId'][] = [];
      if (foundOrder != null) {
        const newOrder: OrderData = {
          ...foundOrder,
          order: foundOrder.order.map((orderItem) => {
            if (
              orderItem.serviceCharge
              || orderItem.tip
              || orderItem.refunded
              || orderItem.discount
              || orderItem.charityDonation
            ) {
              return { ...orderItem };
            }
            let { status } = orderItem;
            if (
              labelSelectValue.length > 0
              && orderItem.reportingLabels?.some((reportingLabel) => (
                labelSelectValue.some((selectLabel) => selectLabel.id === reportingLabel)
              ))
              && orderItem.status === source.droppableId
            ) {
              status = destination.droppableId;
              cartIds.push(orderItem.cartId);
            } else if (
              labelSelectValue.length === 0
              && orderItem.status === source.droppableId
            ) {
              status = destination.droppableId;
              cartIds.push(orderItem.cartId);
            }
            return {
              ...orderItem,
              status,
              processed: status === Status.Complete,
            };
          }),
        };

        setOrdersToShow((current) => {
          const updatedOrder = current.map((order) => {
            if (order.id === newOrder.id) {
              return newOrder;
            }
            return order;
          });
          return updatedOrder;
        });
        if (
          destination.droppableId !== Status.Complete
          && source.droppableId !== Status.Complete
        ) {
          await notifyUser(site, foundOrder, cartIds, destination.droppableId);
          await updateOrder(newOrder);
        } else if (destination.droppableId === Status.Complete) {
          // complete order
          await apiRequest(`order/${fullOrderId}/process`, 'POST', { cartIds });
        } else if (source.droppableId === Status.Complete) {
          // reopen order
          await reopenOrder(newOrder, cartIds, user);
        }
      }
    }
  };

  const statusUpdateHandlerFactory = (
    orderNumber: string,
    cartIds: string[],
    newStatus: Status,
  ) => async () => {
    const order = ordersToShow.find((orderToShow) => orderToShow.orderNumber === orderNumber);
    if (order == null) return;

    const updatedOrder = {
      ...order,
      order: order.order.map((item) => {
        if (cartIds.includes(item.cartId)) {
          return {
            ...item,
            status: newStatus,
            statusChange: true,
            processed: newStatus === Status.Complete,
          };
        }
        return item;
      }),
    };

    // optimistically update orders held in state
    setOrdersToShow((currentOrdersToShow) => currentOrdersToShow.map((orderToShow) => {
      if (orderToShow.orderNumber === orderNumber) return updatedOrder;
      return orderToShow;
    }));

    if (newStatus === Status.Complete) {
      await apiRequest(`order/${order.id}/process`, 'POST', { cartIds });
      return;
    }

    if (order.orderProcessed === true) {
      await reopenOrder(updatedOrder, cartIds, user);
      return;
    }

    await notifyUser(site, updatedOrder, cartIds, newStatus);
    await updateOrder(updatedOrder);
  };

  const getOutstandingOrders = useCallback(() => {
    const outstandingOrders = filterOrdersByColumn(Status.Outstanding);
    const filteredOrders = outstandingOrders.filter((o) => {
      const orderAge = calculateOrderAge(o);
      const orderAgeMinutes = orderAge ? orderAge.asMinutes() : 0;
      return orderAgeMinutes > 5;
    });
    setSnackbarOrders(filteredOrders);
  }, [filterOrdersByColumn]);

  useEffect(() => {
    getOutstandingOrders();
    const interval = setInterval(() => {
      getOutstandingOrders();
    }, 300000); // 5 minutes
    return () => clearInterval(interval);
  }, [filterOrdersByColumn]);

  const snackbarContent = useMemo(() => {
    if (!outstandingOrdersAlert) return null;
    if (orderFlowColumns.includes(Status.Outstanding)) return null;
    if (snackbarOrders.length === 0) return null;

    return (
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open>
        <Alert severity="error">
          {`There is ${snackbarOrders.length} orders older than 5 minutes in Outstanding.`}
        </Alert>
      </Snackbar>
    );
  }, [outstandingOrdersAlert, snackbarOrders, orderFlowColumns]);

  return (
    <div>
      <Panel
        outstandingOrdersCount={outstandingOrdersCount}
        todaysOrdersCount={todaysOrdersCount}
        showCompleted={showCompleted}
        onShowCompletedClick={onShowCompletedClick}
        involvesKitchen={involvesKitchen}
        onInvolvesKitchenChange={onInvolvesKitchenChange}
        labelSelectOptions={labelSelectOptions}
        labelSelectValue={labelSelectValue}
        onLabelSelectChange={onLabelSelectChange}
        orderFlowSelectOptions={orderFlowSelectOptions}
        orderFlowColumns={orderFlowColumns}
        onOrderFlowSelectChange={onOrderFlowSelectChange}
        outstandingOrdersAlert={outstandingOrdersAlert}
        onOutstandingOrdersAlertClick={onOutstandingOrdersAlertClick}
      />
      <Grid className={classes.swimLaneContainer} container spacing={2}>
        <DragDropContext onDragEnd={onDragEnd}>
          {orderFlowColumns.map((column) => (
            <OrderFlowLane
              key={column}
              columnStatus={column}
              filterOrdersByColumn={filterOrdersByColumn}
              involvesKitchen={involvesKitchen}
              labelSelectValue={labelSelectValue}
              user={user}
              showCompleted={showCompleted}
              siteReportingLabels={site?.reportingLabels}
              inHouseCollectionLabels={site?.inHouseCollectionLabels}
              collectTableNumberStatus={site?.collectTableNumber}
              statusSelectOptions={orderFlowSelectOptions}
              statusUpdateHandlerFactory={statusUpdateHandlerFactory}
              swimlanesCount={orderFlowColumns.length}
            />
          ))}
        </DragDropContext>
      </Grid>
      <Dialog
        open={error != null}
      >
        <DialogTitle>Something went wrong</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please try again and contact support if this problem persists.
          </DialogContentText>
          <DialogContentText variant="caption">
            show error
            <IconButton onClick={handleShowErrorClick} size="small">
              {showErrorOpen ? <ArrowDropUp /> : <ArrowDropDown />}
            </IconButton>
          </DialogContentText>
          {showErrorOpen && (
            <DialogContentText variant="caption">
              {error?.message}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorOkClick} color="primary">OK</Button>
        </DialogActions>
      </Dialog>
      {snackbarContent}
    </div>
  );
};

export default DispenseScreenPage;
