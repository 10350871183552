import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Box, Button, Divider, Grid, Paper, Typography, makeStyles,
} from '@material-ui/core';

import CheckerField from '../../../components/CheckerField/CheckerField';
import InfoField from '../../../components/info-field/info-field';
import SlotTimesDialog from './slot-times-dialog';

import request from '../../../helpers/request';
import hasPermission from '../../../helpers/has-permission';
import formatMoney from '../../../helpers/formatMoney';

import AppUrl from '../../../types/AppUrl';
import SiteData from '../../../types/SiteData';
import User from '../../../types/User';
import Nullable from '../../../types/Nullable';
import RoleRestrictedAction from '../../../types/RoleRestrictedAction';

const useStyles = makeStyles(() => ({
  paper: { height: '100%' },
  sectionTitle: {
    marginTop: 20,
    marginLeft: 20,
    marginBottom: 5,
  },
  mainSection: {
    margin: 20,
  },
}));

type Props = {
  settings: SiteData
  isCollection: boolean
  isDelivery: boolean
  setLoading: (value: boolean) => void
  user: Nullable<User>
};

const OnlineSection = ({
  settings, isCollection, isDelivery, setLoading, user,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const classes = useStyles();

  const {
    currency,
    site,
    slotCapacity,
    slotTime,
    deliveryDistance,
    minimumDelivery,
    deliveryCharge,
    confirmationTextMessages,
  } = settings;

  const onSave = useCallback((daysToDo: number, regenerate: boolean) => {
    setLoading(true);
    request(
      regenerate ? 'generateSiteSlots' : 'updateSiteSlotTimes',
      'POST',
      {
        site,
        daysToDo,
      },
    )
      .then(() => {
        setMessage('Successfully generated');
        setOpen(false);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setMessage('Failed to update slot times, please try again or contact support.');
      });
  }, [site]);

  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} variant="outlined">
        <Typography className={classes.sectionTitle} variant="h6">Online Ordering</Typography>
        <Divider />
        <Box className={classes.mainSection}>
          <CheckerField
            title="Collection"
            value={isCollection}
          />
          <CheckerField
            title="Delivery"
            value={isDelivery}
          />
          <InfoField
            title="Slot Capacity"
            info={slotCapacity || 0}
          />
          <InfoField
            title="Slot Time"
            info={`${slotTime} minutes`}
          />
          <InfoField
            title="Last Orders Time"
            info={`${settings.lastOrdersTime || 'Not set'}`}
          />
          <InfoField
            title="All Day Slots"
            info={settings.allDaySlots ? 'Yes' : 'No'}
          />
          <CheckerField
            title="Confirmation Text Messages"
            value={confirmationTextMessages || false}
          />
          {isDelivery && (
            <>
              <InfoField
                title="Delivery Distance (KM)"
                info={`${deliveryDistance} km`}
              />
              <InfoField
                title="Minimum Delivery"
                info={formatMoney(minimumDelivery || 0, currency)}
              />
              <InfoField
                title="Delivery Charge"
                info={formatMoney(deliveryCharge || 0, currency)}
              />
            </>
          )}
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ marginTop: 10 }}
          >
            <Button
              style={{ marginRight: 10 }}
              color="primary"
              variant="outlined"
              component={Link}
              to={AppUrl.Slots}
            >
              View Slots
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setOpen(true)}
              disabled={!user || !hasPermission(RoleRestrictedAction.GenerateSlotTimes, user)}
            >
              Generate Slot Times
            </Button>
          </Grid>
        </Box>
      </Paper>
      <SlotTimesDialog
        message={message}
        open={open}
        onSave={onSave}
        handleClose={() => setOpen(false)}
      />
    </Grid>
  );
};

export default OnlineSection;
