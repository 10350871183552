import React from 'react';

import {
  Box,
  Divider,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';

import ListField from '../../../components/list-field/list-field';

import labelsFromIds from '../../../components/label-input/helpers/labels-from-ids';

import ProductData from '../../../types/ProductData';
import Label from '../../../types/Label';

const useStyles = makeStyles(() => ({
  paper: { height: '100%' },
  sectionTitle: {
    marginTop: 20,
    marginLeft: 20,
    marginBottom: 5,
  },
  mainSection: {
    margin: 20,
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

type Props = {
  product: ProductData,
  reportingLabels: Label[],
  userLabels: Label[],
};

const ProductLabelsSection = ({
  product,
  reportingLabels: allReportingLabels,
  userLabels: allUserLabels,
}: Props) => {
  const classes = useStyles();

  const { allergens, userLabels, reportingLabels } = product;

  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} variant="outlined">
        <Typography className={classes.sectionTitle} variant="h6">Labels</Typography>
        <Divider />
        <Box className={classes.mainSection}>
          <ListField
            title="Allergens"
            list={allergens || []}
            variant="outlined"
          />
          <Divider className={classes.divider} />
          <ListField
            title="Search Labels"
            list={userLabels
              ? labelsFromIds(userLabels, allUserLabels).map(({ text }) => text)
              : []}
            variant="outlined"
          />
          <Divider className={classes.divider} />
          <ListField
            title="Reporting Labels"
            list={reportingLabels
              ? labelsFromIds(reportingLabels, allReportingLabels).map(({ text }) => text)
              : []}
            color="primary"
          />
        </Box>
      </Paper>
    </Grid>
  );
};

export default ProductLabelsSection;
