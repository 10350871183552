enum ProductFormTabValue {
  Details = 'details',
  Image = 'image',
  Options = 'options',
  AddOns = 'addOns',
  Labels = 'labels',
  Times = 'times',
  AddToMenu = 'menu',
}

export default ProductFormTabValue;
