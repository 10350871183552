import DaysOfTheWeek from '../types/DaysOfTheWeek';

const getDays = () => [
  { value: DaysOfTheWeek.Monday, label: DaysOfTheWeek.Monday },
  { value: DaysOfTheWeek.Tuesday, label: DaysOfTheWeek.Tuesday },
  { value: DaysOfTheWeek.Wednesday, label: DaysOfTheWeek.Wednesday },
  { value: DaysOfTheWeek.Thursday, label: DaysOfTheWeek.Thursday },
  { value: DaysOfTheWeek.Friday, label: DaysOfTheWeek.Friday },
  { value: DaysOfTheWeek.Saturday, label: DaysOfTheWeek.Saturday },
  { value: DaysOfTheWeek.Sunday, label: DaysOfTheWeek.Sunday },
];

export default getDays;
