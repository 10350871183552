import React, { useState, ChangeEvent } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  DialogContentText,
} from '@material-ui/core';
import Nullable from '../../../types/Nullable';

interface Props {
  reader: {
    id: string;
    label: string;
    status: string;
  },
  open: boolean;
  onCancelClick: () => void;
  onSaveClick: (readerId: string, label: string) => Promise<void>;
  error: Nullable<Error>;
  loading: boolean;
}

const EditReaderDialog = ({
  reader,
  open,
  onCancelClick,
  onSaveClick,
  error,
  loading,
}: Props) => {
  const [label, setLabel] = useState<string>(reader.label);

  const handleLabelChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;
    setLabel(value);
  };

  return (
    <Dialog
      open={open}
    >
      <DialogTitle>
        Edit Device
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Label"
          value={label}
          onChange={handleLabelChange}
          variant="outlined"
          margin="normal"
          fullWidth
        />
        {error && (
          <DialogContentText>
            Something went wrong. Please contact support if this problem persists.
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onCancelClick}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSaveClick(reader.id, label)}
          disabled={loading}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditReaderDialog;
