import React from 'react';

import {
  Box,
  Divider,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';

import ListField from '../../../components/list-field/list-field';
import InfoField from '../../../components/info-field/info-field';

import ProductOption from '../../../types/ProductOption';

const useStyles = makeStyles(() => ({
  paper: { height: '100%' },
  sectionTitle: {
    marginTop: 20,
    marginLeft: 20,
    marginBottom: 5,
  },
  mainSection: {
    margin: 20,
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

type Props = {
  options: ProductOption[],
};

const ProductAddOnsSection = ({ options }: Props) => {
  const classes = useStyles();

  if (options.length === 0) return null;

  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} variant="outlined">
        <Typography className={classes.sectionTitle} variant="h6">Add-ons</Typography>
        <Divider />
        <Box className={classes.mainSection}>
          {
            options.map((option, i) => {
              const choices = option.choices.map((choice) => `${choice.displayName} (${choice.plu})`);
              return (
                <Grid key={option.type}>
                  <InfoField
                    title="Add-on Type"
                    info={option.type}
                  />
                  <InfoField
                    title="Add-on Title"
                    info={option.title}
                  />
                  <InfoField
                    title="Min Choices"
                    info={option.minChoices.toString()}
                  />
                  <InfoField
                    title="Max Choices"
                    info={option.maxChoices}
                  />
                  <ListField
                    title="Choices"
                    list={choices}
                    variant="outlined"
                  />
                  {(i + 1 < options.length) && <Divider className={classes.divider} />}
                </Grid>
              );
            })
          }
        </Box>
      </Paper>
    </Grid>
  );
};

export default ProductAddOnsSection;
