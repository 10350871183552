import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import {
  Chip,
  Box,
  Collapse,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import DownloadIcon from '@material-ui/icons/GetApp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Spinner from '../../../components/spinner/spinner';
import getQueryString from '../helpers/getQueryString';
import request from '../../../helpers/request';
import Options from '../types/Options';
import CommissionReportData from '../../../types/CommissionReportData';
import downloadCommissionReport from '../helpers/downloadCommissionReport';
import SiteData from '../../../types/SiteData';
import Nullable from '../../../types/Nullable';
import getSearchPeriod from '../helpers/getSearchPeriod';

interface Props {
  options: Options;
  site: Nullable<string>;
  siteVatNo: SiteData['vatNumber'];
  companyName: SiteData['companyName'];
}

const useStyles = makeStyles((theme) => ({
  tableHeadCell: { fontWeight: 'bold' },
  downloadButton: {
    margin: theme.spacing(3, 0, 0, 3),
  },
  commissionSummaryBox: {
    margin: theme.spacing(1),
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  detailsTitle: {
    textDecoration: 'underline',
  },
  detailsHeadCell: {
    fontWeight: 'bold',
    width: '25%',
  },
}));

const CommissionReport = ({
  options,
  site,
  siteVatNo,
  companyName,
}: Props) => {
  const classes = useStyles();

  const [tableData, setTableData] = useState<Array<CommissionReportData>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (site == null) return;

    const queryString = getQueryString(
      'commissionReport',
      site,
      options,
      undefined,
      undefined,
      options.reportingLabels,
    );
    request(queryString, 'GET')
      .then((response) => setTableData(response))
      .catch(() => setError('Commission Report could not be created. Please try again.'))
      .finally(() => setIsLoading(false));
  }, []);

  const searchPeriod = useMemo(() => getSearchPeriod(
    options.type, options.start, options.end,
  ), [options]);

  const handleDownload = useCallback(() => downloadCommissionReport(
    tableData,
    companyName || site,
    siteVatNo,
    options.type,
    searchPeriod,
  ), [tableData]);

  if (isLoading) return <Spinner />;

  if (error.length > 0) return <Typography align="center" color="error">{error}</Typography>;

  if (tableData.length === 0) {
    return (
      <Typography align="center">
        No commission was recorded for sales of items with the selected Reporting Label(s) during
        the chosen date range.
      </Typography>
    );
  }

  const CommissionRow = (props: { row: CommissionReportData }) => {
    const { row } = props;
    const [open, setOpen] = useState<boolean>(false);

    return (
      <>
        <TableRow>
          <TableCell>
            {row.commissionDetails.length > 0 && (
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </TableCell>
          <TableCell>{row.sourceName}</TableCell>
          <TableCell>{row.destinationName}</TableCell>
          <TableCell>{row.grossSales.toFixed(2)}</TableCell>
          <TableCell>{row.netSales.toFixed(2)}</TableCell>
          <TableCell>{row.netCommission.toFixed(2)}</TableCell>
          <TableCell>{row.vat.toFixed(2)}</TableCell>
          <TableCell>{row.grossCommission.toFixed(2)}</TableCell>
          <TableCell>{row.amountTransferred.toFixed(2)}</TableCell>
          <TableCell>{row.amountDue.toFixed(2)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} unmountOnExit>
              <Box className={classes.commissionSummaryBox}>
                <Typography className={classes.detailsTitle}>
                  {`${row.sourceName} detailed commission on sales`}
                </Typography>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.detailsHeadCell}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        className={classes.detailsHeadCell}
                      >
                        Net Commission&nbsp;(£)
                      </TableCell>
                      <TableCell
                        className={classes.detailsHeadCell}
                      >
                        VAT&nbsp;(£)
                      </TableCell>
                      <TableCell
                        className={classes.detailsHeadCell}
                      >
                        Gross Commission&nbsp;(£)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.commissionDetails.map((detail) => (
                      <TableRow key={`${row.destinationName}-${detail.paymentMethod}`}>
                        <TableCell component="th" scope="row">
                          {detail.paymentMethod}
                        </TableCell>
                        <TableCell>{detail.netCommission.toFixed(2)}</TableCell>
                        <TableCell>{detail.vat.toFixed(2)}</TableCell>
                        <TableCell>{detail.grossCommission.toFixed(2)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Chip
        className={classes.downloadButton}
        icon={<DownloadIcon />}
        label="Download Commission Report"
        onClick={handleDownload}
        color="primary"
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeadCell} />
            <TableCell className={classes.tableHeadCell}>Source</TableCell>
            <TableCell className={classes.tableHeadCell}>Destination</TableCell>
            <TableCell className={classes.tableHeadCell}>Gross Sales&nbsp;(£)</TableCell>
            <TableCell className={classes.tableHeadCell}>Net Sales&nbsp;(£)</TableCell>
            <TableCell className={classes.tableHeadCell}>Net Commission&nbsp;(£)</TableCell>
            <TableCell className={classes.tableHeadCell}>VAT&nbsp;(£)</TableCell>
            <TableCell className={classes.tableHeadCell}>Gross Commission&nbsp;(£)</TableCell>
            <TableCell className={classes.tableHeadCell}>Commission Paid&nbsp;(£)</TableCell>
            <TableCell className={classes.tableHeadCell}>Commission Due&nbsp;(£)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row) => (
            <CommissionRow key={row.sourceName} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CommissionReport;
