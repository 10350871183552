import firebase from 'firebase';
import Label from '../types/Label';
import SiteData from '../types/SiteData';

const saveReportingLabel = (site: SiteData, label: Label) => {
  let reportingLabels = [label];
  if (site.reportingLabels) {
    reportingLabels = reportingLabels.concat(site.reportingLabels);
  }
  return firebase.firestore()
    .collection('sites')
    .doc(site.id)
    .update({
      reportingLabels,
    });
};

export default saveReportingLabel;
