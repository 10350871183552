import { firestore } from 'firebase/app';

import isSiteData from './isSiteData';

import SiteData from '../types/SiteData';
import Nullable from '../types/Nullable';

const initialiseSiteListener = (
  site: SiteData['site'],
  setSite: React.Dispatch<React.SetStateAction<Nullable<SiteData>>>,
  setError: React.Dispatch<React.SetStateAction<Nullable<Error>>>,
) => (
  firestore()
    .collection('sites')
    .where('site', '==', site)
    .onSnapshot((querySnapshot) => {
      const queryResults: SiteData[] = [];
      querySnapshot.forEach((doc) => {
        const data = { ...doc.data(), id: doc.id };
        if (isSiteData(data)) queryResults.push(data);
      });
      if (queryResults.length > 0) {
        setSite(queryResults[0]);
      } else {
        setError(new Error(`site data for ${site} could not be loaded successfully.`));
      }
    },
    (error) => {
      setError(error);
    })
);

export default initialiseSiteListener;
