import React from 'react';

import { Box, Button, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

interface Props {
  onAddClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: theme.spacing(3, 3, 0, 0),
  },
}));

const TableHeader = ({ onAddClick }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={onAddClick}
      >
        Add Gift Card
      </Button>
    </Box>
  );
};

export default TableHeader;
