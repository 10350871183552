import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Typography, Grid, makeStyles, Paper, TextField, Button,
} from '@material-ui/core';

import Spinner from '../../components/spinner/spinner';
import SiteCard from './components/SiteCard';
import getSites from '../../actions/get-sites';
import AppUrl from '../../types/AppUrl';
import SiteDetailsDialog from './components/SiteDetailsDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchBarPaper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(2),
  },
}));

const SitesPage = () => {
  const classes = useStyles();
  const isSitesLoading = useSelector((state) => state.sites.get('IS_SITES_LOADING'));
  const sites = useSelector((state) => state.sites.get('SITES'));
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSite, setSelectedSite] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getSites());
  }, []);

  const siteList = useMemo(() => {
    if (searchTerm === '') return sites;
    return sites.filter((site) => site.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [sites, searchTerm]);

  const handleCreateSiteClick = () => {
    history.push(AppUrl.CreateSite);
  };

  const handleDetailsCloseClick = () => {
    setSelectedSite(null);
  };

  if (isSitesLoading) return <Spinner />;

  if (sites.length === 0) {
    return (
      <Typography className={classes.root} variant="h6">Sorry but there is currently no sites.</Typography>
    );
  }

  return (
    <Grid>
      <Paper className={classes.searchBarPaper}>
        <TextField
          label="Search Site Name"
          variant="outlined"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          fullWidth
        />
        <Button
          onClick={handleCreateSiteClick}
          className={classes.button}
          size="small"
          variant="contained"
          color="primary"
        >
          Create Site
        </Button>
      </Paper>
      <Grid
        container
        spacing={2}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        {siteList.map((site) => (
          <SiteCard
            site={site}
            setSelectedSite={setSelectedSite}
          />
        ))}
      </Grid>
      <SiteDetailsDialog
        open={selectedSite != null}
        selectedSiteId={selectedSite?.id}
        onCloseClick={handleDetailsCloseClick}
      />
    </Grid>
  );
};

export default SitesPage;
