import firebase from 'firebase/app';
import { v4 as uuidv4 } from 'uuid';

import uploadImage from './upload-image';
import getImageUrl from './get-image-url';
import createDefaultOpeningTimes from './createDefaultOpeningTimes';
import generateRandomCode from './generateRandomCode';

const createSite = async (newSite, image, usedGiftCardPreFixes) => {
  const {
    name,
    site,
    urlEndpoint,
    email,
    active,
    collection,
    delivery,
    language,
    currency,
    address,
    customersOptions,
  } = newSite;

  let logo = '';
  let newGiftCardPrefix = '';

  const firebaseCollection = firebase
    .firestore()
    .collection('sites');

  if (image && image.length !== 0) {
    logo = await uploadImage(site, image)
      .then(({ ref }) => getImageUrl(ref.fullPath));
  }

  while (newGiftCardPrefix === '') {
    const newPrefix = generateRandomCode(9999);
    if (!usedGiftCardPreFixes.includes(newPrefix)) {
      newGiftCardPrefix = newPrefix;
    }
  }

  const defaultSiteData = {
    name,
    site,
    urlEndpoint,
    email,
    phoneNumber: '',
    logo,
    apiKey: uuidv4(),
    active,
    language,
    currency,
    customersOptions,
    giftCardOptions: {
      prefixCode: newGiftCardPrefix,
    },
  };

  const siteData = collection || delivery ? {
    ...defaultSiteData,
    sheetId: '',
    collection,
    delivery,
    slotCapacity: 2,
    slotTime: 10,
  } : {
    ...defaultSiteData,
    openingTimes: createDefaultOpeningTimes(),
    waitingTimes: {
      drinksWaitingTime: 0,
      foodWaitingTime: 0,
    },
  };

  if (address != null) {
    siteData.address = address;
  }

  return firebaseCollection
    .where('urlEndpoint', '==', urlEndpoint)
    .get()
    .then((snapshot) => {
      if (snapshot.empty) {
        return firebaseCollection
          .add(siteData);
      }
      return Promise.reject(new Error('Sorry but site already in use, please use another name or contact support'));
    });
};

export default createSite;
