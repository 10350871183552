import Joi from 'joi';
import SiteServiceCharge from '../../../types/SiteServiceCharge';

const isValidServiceCharge = (value: unknown): value is SiteServiceCharge => (
  Joi.object({
    percentage: Joi.number().required(),
    value: Joi.number().required(),
    plu: Joi.number().required(),
    chargeName: Joi.string().required(),
    compulsory: Joi.boolean(),
  }).validate(value)
    .error == null
);

export default isValidServiceCharge;
