import React, { Dispatch, SetStateAction } from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import SearchToolbar from './SearchToolbar';

type Props = {
  value: string
  onSearchTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSearchTextClear: () => void
  buttonTitle: string
  onButtonClick: () => void
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  hideSearch?: boolean
};

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(3.25, 3, 1.5),
  },
  button: {
    marginLeft: theme.spacing(2.625),
  },
}));

const SearchBar = ({
  value,
  onSearchTextChange,
  onSearchTextClear,
  buttonTitle,
  onButtonClick,
  hideSearch,
}: Props) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.toolbar}
    >
      {!hideSearch && (
        <SearchToolbar
          value={value}
          onSearchTextChange={onSearchTextChange}
          onSearchTextClear={onSearchTextClear}
        />
      )}
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        size="medium"
        startIcon={<AddIcon fontSize="small" />}
        onClick={onButtonClick}
      >
        {buttonTitle}
      </Button>
    </Box>
  );
};

export default SearchBar;
