import React, { ReactElement, useCallback } from 'react';

import {
  InputLabel, FormControl, FormHelperText, MenuItem, Select, makeStyles, Typography,
} from '@material-ui/core';

import Nullable from '../../types/Nullable';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  formControl: {
    width: 350,
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

type Props<T extends string | number> = {
  label: string
  value?: Nullable<T>
  items: { label: string, value: T, icon?: ReactElement }[]
  error?: boolean
  labelWidth?: number
  onChange: (value: T) => void
  helperText?: string
  allowEmpty?: boolean
  disabled?: boolean
  size?: 'small' | 'medium'
  fullWidth?: boolean
};

const SimpleSelect = <T extends string | number>({
  label,
  value,
  items,
  error,
  onChange,
  helperText,
  allowEmpty = true,
  disabled = false,
  size = 'medium',
  fullWidth = false,
}: Props<T>) => {
  const classes = useStyles();

  const handleChange = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as T);
  }, [onChange]);

  return (
    <FormControl variant="outlined" size={size} className={fullWidth ? classes.fullWidth : classes.formControl}>
      <InputLabel id="label">{label}</InputLabel>
      <Select
        labelId="label"
        value={value != null ? value : ''}
        onChange={handleChange}
        label={label}
        error={error || false}
        disabled={disabled}
      >
        {allowEmpty && <MenuItem value="">Not Set</MenuItem>}
        {
          items.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <Typography className={classes.menuItem}>
                {item.icon || null}
                {item.label}
              </Typography>
            </MenuItem>
          ))
        }
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SimpleSelect;
