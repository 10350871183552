import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import {
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import firebase from 'firebase';
import moment from 'moment';

import { makeStyles } from '@material-ui/core';

import getRouteComponent from '../../../helpers/getAppRouteComponent';
import AppUrl from '../../../types/AppUrl';
import AppHeader from './app-header';
import AppDrawer from '../../../components/app-drawer/app-drawer';
import PageLocation from '../../../components/PageLocation/PageLocation';

import AlertContext from '../../../contexts/AlertContext';

import getRoutes from '../../../helpers/getRoutes';
import isNotification from '../helpers/isNotification';

import NotificationData from '../../../types/NotificationData';
import PeaziNotification from '../../../types/PeaziNotification';
import User from '../../../types/User';
import NoMatchPage from '../../no-match-page/no-match-page';

type Props = {
  notifications: NotificationData[]
  user: User
  logout: () => void
  darkState: boolean
  onToggle: () => void
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(5),
  },
  list: {
    width: 250,
  },
}));

const AppRouter = ({
  notifications, user, darkState, onToggle,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();
  const location = useLocation();
  const routes = getRoutes(user);

  const { setMessage } = useContext(AlertContext);

  const handleNotification = useCallback((notification: PeaziNotification) => {
    setMessage({
      type: notification.type,
      title: notification.title,
      message: notification.message,
    });
  }, [setMessage]);

  useEffect(() => {
    const subscribeToNotifications = (
      onNotification: (notification: PeaziNotification) => void, site: string,
    ) => {
      let unsubscribe = firebase
        .firestore()
        .collection('notifications')
        .where('site', '==', site)
        .where('time', '>=', moment().valueOf())
        .orderBy('time')
        .limit(1)
        .onSnapshot((snapshots) => {
          const document = snapshots.docs[0];

          if (!document) return;

          const data = document.data();

          if (!isNotification(data)) throw new Error('Malformed Notification');

          onNotification(data);

          unsubscribe();

          unsubscribe = subscribeToNotifications(onNotification, site);
        });

      return () => {
        unsubscribe();
      };
    };
    subscribeToNotifications(handleNotification, 'thepubofwillpatioarea');
  }, []);

  return (
    <>
      <AppHeader
        user={user}
        notifications={notifications}
        location={location}
        darkState={darkState}
        onToggle={onToggle}
        onOpen={() => setOpen(true)}
      />
      <div className={classes.root}>
        <PageLocation url={location.pathname} />
        <Switch>
          {
            routes.map((route) => (
              <Route<{}, AppUrl>
                key={route.url}
                path={route.url}
                component={getRouteComponent(route)}
                exact
              />
            ))
          }
          <Route
            key="nomatch"
            path="nomatch"
            component={NoMatchPage}
            exact
          />
        </Switch>
      </div>
      <AppDrawer open={open} onClose={() => setOpen(false)} user={user} />
    </>
  );
};

export default AppRouter;
