import React,
{
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Grid, makeStyles } from '@material-ui/core';

import getProducts from '../../actions/get-products';
import getSites from '../../actions/get-sites';

import ConfirmationDialog from '../../components/confirmation-dialog/confirmation-dialog';
import Spinner from '../../components/spinner/spinner';
import ProductsTable from '../../components/ProductsTable/ProductsTable';

import removeProduct from '../../helpers/remove-product';
import changeProduct from '../../helpers/change-product';
import stockProducts from './helpers/stock-products';
import canUserViewProduct from '../../helpers/can-user-view-product';
import setLabelOnline from '../../helpers/setLabelOnline';
import setLabelOffline from '../../helpers/setLabelOffline';
import useUrlParams from '../../hooks/useUrlParams';

import STORE_KEYS from '../../constants/store-keys';

const {
  AUTHENTICATION: { USER },
  PRODUCTS: { IS_PRODUCTS_LOADING, PRODUCTS },
  SITES: { SITES, IS_SITES_LOADING },
} = STORE_KEYS;

const useStyles = makeStyles({
  buttonContainer: {
    marginBottom: 10,
  },
  button: {
    marginRight: 20,
  },
});

const ProductsPage = () => {
  const classes = useStyles();
  const user = useSelector((state) => state.authentication.get(USER));
  const isSitesLoading = useSelector((state) => state.sites.get(IS_SITES_LOADING));
  const allSites = useSelector((state) => state.sites.get(SITES));
  const isProductsLoading = useSelector((state) => state.products.get(IS_PRODUCTS_LOADING));
  const products = useSelector((state) => state.products.get(PRODUCTS));
  const [selectedReportingLabels, setSelectedReportingLabels] = useState([]);
  const [open, setOpen] = useState(false);
  const [onlineDialogOpen, setOnlineDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const dispatch = useDispatch();

  const {
    values: [searchText, filter, currentTablePage],
    setters: [onSearchTextChange, onFilterChange, onTablePageChange],
  } = useUrlParams(['search', 'filter', 'page']);

  useEffect(() => {
    dispatch(getSites());
    if (user.site) {
      dispatch(getProducts(user.site));
    }
  }, []);

  const maxPage = useMemo(() => {
    if (products.length > 0 && products.length % 10 === 0) return (products.length / 10) - 1;
    return Math.floor(products.length / 10);
  }, [products]);

  const site = useMemo(() => allSites.find((siteData) => siteData.site === user.site), [allSites]);

  const userSiteConfig = useMemo(
    () => user.sites.find((userConfig) => userConfig.site === user.site),
    [user],
  );

  const reportingLabel = useMemo(() => {
    if (site) {
      const { reportingLabels } = site;
      if (reportingLabels && userSiteConfig.reportingLabels.length === 1) {
        return reportingLabels.find(({ id }) => userSiteConfig.reportingLabels.includes(id));
      }
      return null;
    }
    return null;
  }, [site, userSiteConfig]);

  const isLabelOffline = useMemo(() => {
    if (site) {
      if (site.offlineLabels == null || reportingLabel == null) return false;
      return site.offlineLabels.includes(reportingLabel.id);
    }
    return null;
  }, [site, reportingLabel]);

  const onlineToggleText = useMemo(
    () => (isLabelOffline ? 'Online' : 'Offline'),
    [isLabelOffline],
  );

  const filteredProducts = useMemo(() => (
    products.filter((product) => {
      if (selectedReportingLabels.length === 0) return products;

      if (product.reportingLabels == null || product.reportingLabels.length === 0) {
        return selectedReportingLabels.includes('no-label');
      }
      return product.reportingLabels.some((labelId) => selectedReportingLabels.includes(labelId));
    })
  ), [products, selectedReportingLabels]);

  const numberOfProductsUpdated = useMemo(
    () => products.filter(
      (product) => userSiteConfig && canUserViewProduct(product, userSiteConfig),
    ).length,
    [products, userSiteConfig],
  );

  const onSearchTextClear = useCallback(() => {
    onSearchTextChange('');
  }, [onSearchTextChange]);

  const handleRemove = (productId) => {
    setLoading(true);
    if (
      Number(currentTablePage) !== 0
      && maxPage === Number(currentTablePage)
      && products.length - (maxPage * 10) === 1
    ) onTablePageChange(maxPage - 1);
    removeProduct(productId)
      .then(() => {
        dispatch(getProducts(user.site));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleProductChange = (id, newProduct) => {
    changeProduct(id, newProduct)
      .then(() => {
        dispatch(getProducts(site.site));
      });
  };

  const handleStockProducts = () => {
    setLoading(true);
    setErrorMessage(null);
    stockProducts(
      site.site,
      reportingLabel?.id || null,
    )
      .then(() => {
        dispatch(getProducts(site.site));
        setLoading(false);
        setOpen(false);
      })
      .catch(() => {
        setErrorMessage('Failed to restock products, please try again or contact support.');
        setLoading(false);
      });
  };

  const handleOnlineToggleClick = useCallback(
    () => setOnlineDialogOpen(true),
    [setOnlineDialogOpen],
  );

  const handleOnlineToggleConfirm = useCallback(async () => {
    setLoading(true);
    try {
      if (isLabelOffline) {
        await setLabelOffline(site.id, reportingLabel.id);
      } else {
        await setLabelOnline(site.id, reportingLabel.id);
      }
      setOnlineDialogOpen(false);
      dispatch(getSites());
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  }, [setLoading, site, isLabelOffline, setOnlineDialogOpen]);

  const handleOnlineToggleCancel = useCallback(
    () => setOnlineDialogOpen(false),
    [setOnlineDialogOpen],
  );

  useEffect(() => {
    setSelectedReportingLabels(userSiteConfig.reportingLabels);
  }, [userSiteConfig]);

  const reportingLabelOptions = useMemo(() => {
    if (site == null || site.reportingLabels == null) return [];
    if (
      userSiteConfig == null
      || userSiteConfig.reportingLabels == null
      || userSiteConfig.reportingLabels.length === 0
    ) {
      return site.reportingLabels;
    }
    return userSiteConfig.reportingLabels
      .map((labelId) => site.reportingLabels.find((label) => label.id === labelId));
  }, [userSiteConfig, site]);

  const handleReportingLabelSelectChange = (event) => {
    const { target: { value } } = event;
    setSelectedReportingLabels(value);
  };

  if (isProductsLoading || isSitesLoading || loading) return <Spinner />;

  return (
    <Grid>
      <Grid className={classes.buttonContainer}>
        {reportingLabel != null
        && (
          <Button
            color="secondary"
            variant="outlined"
            size="large"
            className={classes.button}
            onClick={handleOnlineToggleClick}
          >
            {`Set ${reportingLabel.text} Items ${onlineToggleText}`}
          </Button>
        )}
      </Grid>
      <ProductsTable
        products={filteredProducts}
        siteData={site}
        onRemove={handleRemove}
        changeProduct={handleProductChange}
        currentPage={Number(currentTablePage || 0)}
        onPageChange={onTablePageChange}
        searchText={searchText || ''}
        onSearchTextChange={onSearchTextChange}
        onSearchTextClear={onSearchTextClear}
        filter={filter || ''}
        onFilterChange={onFilterChange}
        onRestock={() => setOpen(true)}
        reportingLabelOptions={reportingLabelOptions}
        selectedReportingLabels={selectedReportingLabels}
        onReportingLabelSelectChange={handleReportingLabelSelectChange}
        isReportingLabelSelectDisabled={reportingLabel != null}
      />
      <ConfirmationDialog
        title="Restock Products"
        message="Are you sure you want to restock all products?"
        open={open}
        onSave={handleStockProducts}
        onClose={() => setOpen(false)}
        errorMessage={errorMessage}
      />
      {reportingLabel != null
      && (
        <ConfirmationDialog
          title={`Set Label ${onlineToggleText}`}
          message={`
            Set all products with Reporting Label ${reportingLabel.text} ${onlineToggleText}.
            This change will affect ${numberOfProductsUpdated} products - are you sure?
          `}
          open={onlineDialogOpen}
          onSave={handleOnlineToggleConfirm}
          onClose={handleOnlineToggleCancel}
          errorMessage={errorMessage}
        />
      )}
    </Grid>
  );
};

export default ProductsPage;
