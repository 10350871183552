import React, { useCallback, useMemo, useState } from 'react';

import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  makeStyles,
  OutlinedInput,
  TextField,
  Typography,
} from '@material-ui/core';

import AddToMenuForm from '../product-form/components/AddToMenuForm';
import ImageUpload from '../image-upload/image-upload';
import SimpleSelect from '../SimpleSelect/SimpleSelect';
import CheckBox from '../check-box/check-box';
import MultipleSelect from '../multiple-select/multiple-select';

import getCurrencyAdornment from '../../helpers/getCurrencyAdornment';

import EditableGiftCardProduct from '../../types/EditableGiftCardProduct';
import Nullable from '../../types/Nullable';
import Category from '../../types/Category';
import VatRate from '../../types/VatRate';

import defaultGiftCardProduct from './constants/defaultGiftCardProduct';
import amountOptionsList from './constants/amountOptionsList';

const useStyles = makeStyles((theme) => ({
  imageGrid: {
    padding: 10,
    width: 100,
    height: 100,
    marginBottom: 20,
  },
  image: {
    backgroundColor: 'black',
    width: 100,
    height: 100,
    objectFit: 'contain',
    objectPosition: '50% 50%',
  },
  textInput: {
    marginBottom: theme.spacing(2),
  },
  inputErrorText: {
    marginBottom: theme.spacing(5),
  },
  amountOptionsSelect: { marginBottom: theme.spacing(2) },
}));

type Props = {
  siteMap: Category[]
  selectedProduct?: Nullable<EditableGiftCardProduct>
  vatRates: VatRate[]
  onSave: (product: EditableGiftCardProduct, newMenu: Category[], image: Nullable<File>) => void
};

const GiftCardProductForm = ({
  siteMap, selectedProduct, vatRates, onSave,
}: Props) => {
  const [pluInputError, setPluInputError] = useState<boolean>(false);
  const [newMenu, setNewMenu] = useState<Category[]>([]);
  const [selectedFile, setSelectedFile] = useState<Nullable<File>>(null);
  const [
    giftCardProduct, setGiftCardProduct,
  ] = useState<EditableGiftCardProduct>(selectedProduct || defaultGiftCardProduct);
  const classes = useStyles();

  const {
    name,
    plu,
    description,
    price,
    isCustomValueEnabled,
    amountOptions,
    vatRate,
    nonDiscountable,
    image,
  } = giftCardProduct;

  const handleChange = useCallback(<T extends keyof EditableGiftCardProduct>(
    label: T, val: EditableGiftCardProduct[T],
  ) => {
    setGiftCardProduct((p) => ({ ...p, [label]: val }));
  }, []);

  const handlePluChange = useCallback((event) => {
    const { target: { value } } = event;

    const pluNumber = Number(value);
    if (pluNumber >= 0 && pluNumber <= 2147483647) {
      handleChange('plu', value);
      setPluInputError(false);
    } else {
      setPluInputError(true);
    }
  }, [handleChange]);

  const handleImageChange = (file: File, preview: string) => {
    setSelectedFile(file);
    handleChange('image', preview);
  };

  const handleImageRemove = () => {
    setSelectedFile(null);
    handleChange('image', '');
  };

  const handleSave = () => {
    const newProduct = {
      ...giftCardProduct,
      plu: Number(giftCardProduct.plu),
      price: Number(giftCardProduct.price),
    };
    onSave(newProduct, newMenu, selectedFile);
  };

  const menu = useMemo(() => {
    if (newMenu.length === 0) return siteMap;
    return newMenu;
  }, [siteMap, newMenu]);

  return (
    <>
      <TextField
        className={classes.textInput}
        label="Product Name"
        variant="outlined"
        value={name}
        onChange={(event) => {
          handleChange('name', event.target.value);
          handleChange('pluName', event.target.value);
        }}
        required
      />
      <TextField
        className={classes.textInput}
        type="number"
        label="PLU"
        variant="outlined"
        value={plu}
        onChange={handlePluChange}
        required
      />
      {pluInputError && (
        <Typography className={classes.inputErrorText} color="secondary">
          Please choose a PLU value between 0 and 2147483647.
        </Typography>
      )}
      <TextField
        className={classes.textInput}
        label="Description"
        variant="outlined"
        value={description}
        onChange={(event) => handleChange('description', event.target.value)}
        multiline
      />
      <FormControl className={classes.textInput} variant="outlined">
        <InputLabel htmlFor="price-field">{isCustomValueEnabled ? 'Default Price' : 'Price'}</InputLabel>
        <OutlinedInput
          id="price-field"
          value={price}
          onChange={(event) => handleChange('price', event.target.value)}
          startAdornment={<InputAdornment position="start">{getCurrencyAdornment()}</InputAdornment>}
          labelWidth={isCustomValueEnabled ? 100 : 40}
          required
        />
      </FormControl>
      <CheckBox
        label="Allow custom amounts"
        checked={isCustomValueEnabled || false}
        onChange={(event) => handleChange('isCustomValueEnabled', event.target.checked)}
      />
      <MultipleSelect
        className={classes.amountOptionsSelect}
        label="Amount options"
        defaultValues={amountOptions || []}
        options={amountOptionsList.map((value) => ({ label: `£${value}`, value }))}
        onChange={(amounts) => handleChange('amountOptions', amounts)}
      />
      <SimpleSelect<number>
        label="Vat Rate"
        items={vatRates}
        value={vatRate}
        onChange={(value) => handleChange('vatRate', typeof value === 'number' ? value : null)}
        labelWidth={60}
        fullWidth
      />
      <CheckBox
        label="Non Discountable"
        checked={nonDiscountable}
        onChange={(event) => handleChange('nonDiscountable', event.target.checked)}
      />
      <Box style={{ padding: 10 }}>
        <Typography variant="h6">Gift Card Image</Typography>
        <ImageUpload
          buttonLabel="Upload Image"
          onChange={handleImageChange}
          onRemove={handleImageRemove}
          currentImage={image}
          description="The image should have an aspect ratio of 3:2 and best resolution is 150x100."
          previewGridStyles={classes.imageGrid}
          previewStyles={classes.image}
        />
      </Box>
      <Box style={{ padding: 10 }}>
        <Typography variant="h6">Add to Menu</Typography>
        <AddToMenuForm plu={Number(plu)} siteMap={menu} setSiteMap={setNewMenu} />
      </Box>
      <Button onClick={handleSave} variant="contained" color="primary">Save</Button>
    </>
  );
};

export default GiftCardProductForm;
