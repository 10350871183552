import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import {
  Avatar, Box, Button, Divider, Grid, Paper, Typography, makeStyles, useTheme,
} from '@material-ui/core';
import Brightness1Icon from '@material-ui/icons/Brightness1';

import InfoField from '../../../components/info-field/info-field';
import KeyField from '../../../components/key-field/key-field';
import ListField from '../../../components/list-field/list-field';
import CheckerField from '../../../components/CheckerField/CheckerField';
import StripeStatementDescriptorDialog from '../../../components/StripeStatementDescriptorDialog/StripeStatementDescriptorDialog';

import getSiteUrl from '../../../helpers/get-site-url';
import getColourCode from '../../../helpers/getColourCode';
import getColourLabel from '../../../helpers/getColourLabel';
import formatSiteAddress from '../../../helpers/formatSiteAddress';

import AppUrl from '../../../types/AppUrl';
import SiteData from '../../../types/SiteData';
import InteractionColourValue from '../../../types/InteractionColourValue';

const useStyles = makeStyles(() => ({
  paper: { height: '100%' },
  sectionTitle: {
    marginTop: 20,
    marginLeft: 20,
    marginBottom: 5,
  },
  mainSection: {
    margin: 20,
  },
  logo: {
    width: '150px',
    height: '100px',
    objectFit: 'contain',
    objectPosition: '50% 50%',
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

type Props = {
  settings: SiteData
};

const SiteInfoSection = ({ settings }: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const {
    name,
    urlEndpoint,
    logo,
    email,
    phoneNumber,
    address,
    apiKey,
    stripeConnectActive,
    stripeConnect,
    orderCompleteText,
    sheetId,
    siteThemeColour,
    interactionColour,
    site,
    reportingLabels,
    userLabels,
    vatRegistered,
    vatNumber,
    companyName,
    welcomeModal,
  } = settings;

  const prefersDarkMode = useMemo(() => theme.palette.type === 'dark', [theme]);

  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} variant="outlined">
        <Typography className={classes.sectionTitle} variant="h6">Site Information</Typography>
        <Divider />
        <Box className={classes.mainSection}>
          <InfoField
            title="Email"
            info={email || 'No Email setup'}
          />
          <InfoField
            title="Phone Number"
            info={phoneNumber || 'No Number'}
          />
          <InfoField
            title="Site Link"
            info={getSiteUrl(urlEndpoint || site)}
          />
          <InfoField
            title="Site Address"
            info={address != null ? formatSiteAddress(address) : ''}
          />
          <Divider className={classes.divider} />
          <KeyField
            title="API Key"
            info={apiKey}
          />
          {stripeConnectActive ? (
            <KeyField
              title="Stripe Account"
              info={stripeConnect}
            />
          ) : (
            <InfoField
              title="Stripe Account"
              info="Not Setup"
            />
          )}
          <Divider className={classes.divider} />
          <InfoField
            title="Welcome Message"
            info={welcomeModal.welcomeMessage || 'No message'}
          />
          <InfoField
            title="Order Complete Text"
            info={orderCompleteText || 'No text'}
          />
          <InfoField
            title="Terms & Conditions link"
            info={welcomeModal.termsLink || ''}
          />
          <InfoField
            title="Privacy Policy Link"
            info={welcomeModal.privacyPolicyLink || ''}
          />
          <CheckerField
            title="Hide Opening Times On Welcome Pop Up"
            value={welcomeModal.hideOpeningTimes || false}
          />
          <CheckerField
            title="VAT Registered"
            value={vatRegistered || false}
          />
          {vatRegistered && (
            <InfoField
              title="VAT Registration Number"
              info={vatNumber || 'Not Set'}
            />
          )}
          <InfoField
            title="Company Name"
            info={companyName || ''}
          />
          {sheetId && (
            <InfoField
              title="Google Sheet ID"
              info={sheetId}
            />
          )}
          <Divider className={classes.divider} />
          {siteThemeColour && (
            <InfoField
              title="Site Theme Colour"
              info={siteThemeColour}
              icon={<Brightness1Icon style={{ color: siteThemeColour }} />}
            />
          )}
          <InfoField
            title="InteractionColour"
            info={getColourLabel(interactionColour || InteractionColourValue.Green)}
            icon={(
              <Brightness1Icon
                style={{
                  color: getColourCode(
                    interactionColour || InteractionColourValue.Green, prefersDarkMode,
                  ),
                }}
              />
            )}
          />
          <Typography style={{ fontWeight: 'bold' }}>Site Logo:</Typography>
          <Avatar variant="rounded" alt={name} src={logo} className={classes.logo} />
          <Divider className={classes.divider} />
          {reportingLabels && (
            <ListField
              title="Reporting Labels"
              list={reportingLabels.map((label) => label.text)}
            />
          )}
          {userLabels && (
            <ListField
              title="User Labels"
              list={userLabels.map((label) => label.text)}
            />
          )}
          <Button
            style={{ marginTop: 5 }}
            color="primary"
            variant="outlined"
            component={Link}
            to={AppUrl.ReportingLabelManagement}
          >
            Go To Reporting Labels
          </Button>
          <Divider className={classes.divider} />
          <StripeStatementDescriptorDialog stripeConnectId={settings.stripeConnect} />
        </Box>
      </Paper>
    </Grid>
  );
};

export default SiteInfoSection;
