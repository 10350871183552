import firebase from 'firebase/app';

import { LOGIN_START, AUTHORISATION_ERROR } from '../constants/actions';

export default (email, password) => (dispatch) => {
  dispatch({
    type: LOGIN_START,
  });

  // Success case will be picked up from the auth state change
  firebase.auth()
    .signInWithEmailAndPassword(email, password)
    .catch(() => {
      dispatch({
        type: AUTHORISATION_ERROR,
        message: 'Failed to login.',
      });
    });
};
