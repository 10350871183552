import firebase from 'firebase/app';
import SiteData from '../types/SiteData';
import Choice from '../types/Choice';

const updatePopularProducts = async (
  siteId: string,
  popularProducts: SiteData['popularProducts'],
  labelId: string,
  options: Choice[],
) => (
  firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .update({
      popularProducts: {
        ...popularProducts,
        [labelId]: options.map((option) => option.plu),
      },
    })
);

export default updatePopularProducts;
