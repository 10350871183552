import React, {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Drawer,
  Paper,
  Button,
  Grid,
  Typography,
  makeStyles,
  fade,
  TextField,
} from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';

import MultipleSelect from '../multiple-select/multiple-select';
import DateField from '../DateField/DateField';
import DiscountCodesTable from '../DiscountCodesTable/DiscountCodesTable';
import DiscountCodeDialog from './components/DiscountCodeDialog';
import Spinner from '../spinner/spinner';

import getDays from '../../helpers/getDays';
import isDiscount from '../../helpers/isDiscount';
import discountToFormValues from './helpers/discountToFormValues';
import initialiseDiscountsListener from '../../helpers/initialiseDiscountsListener';

import AppUrl from '../../types/AppUrl';
import Discount from '../../types/Discount';
import DiscountCode from '../../types/DiscountCode';
import DiscountFormValues from '../../types/DiscountFormValues';
import DiscountType from '../../types/DiscountType';
import DiscountStatus from '../../types/DiscountStatus';
import EditedDiscount from '../../types/EditedDiscount';
import Label from '../../types/Label';
import User from '../../types/User';
import ReduxState from '../../types/ReduxState';
import Nullable from '../../types/Nullable';
import hasPermission from '../../helpers/has-permission';
import RoleRestrictedAction from '../../types/RoleRestrictedAction';

const useStyles = makeStyles((theme) => ({
  discountForm: {
    padding: 1,
  },
  header: {
    marginBottom: 10,
  },
  title: {
    margin: 20,
  },
  form: {
    margin: 20,
  },
  errorMessage: {
    padding: 10,
  },
  typeSelection: {
    marginRight: 20,
    marginBottom: 20,
  },
  buttons: { padding: 10, paddingRight: 20 },
  cancelButton: { marginRight: 10 },
  toggleOn: {
    width: 56,
    '&.Mui-selected, &.Mui-selected:hover': {
      color: theme.palette.success.main,
      backgroundColor: fade(theme.palette.success.main, 0.2),
    },
  },
  toggleOff: {
    width: 56,
    '&.Mui-selected, &.Mui-selected:hover': {
      color: theme.palette.error.main,
      backgroundColor: fade(theme.palette.error.main, 0.2),
    },
  },
  multipleSelect: {
    width: 350,
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

type Props = {
  initialValue?: Nullable<Discount>
  userLabels: Label[]
  siteLabels: Label[]
  onSave: (data: EditedDiscount) => void
};

const DiscountForm = ({
  initialValue, userLabels, siteLabels, onSave,
}: Props) => {
  const user = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const [discount, setDiscount] = useState<DiscountFormValues>(
    discountToFormValues(initialValue),
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const classes = useStyles();
  const history = useHistory();

  const onDiscountsLoad = useCallback((discountsData: Discount[]) => {
    setIsLoading(false);
    setDiscounts(discountsData);
  }, []);

  const onDiscountsError = useCallback(() => {
    setIsLoading(false);
    setDiscounts([]);
  }, []);

  useEffect(() => {
    if (user != null && user.site) {
      setIsLoading(true);
      return initialiseDiscountsListener(user.site, onDiscountsLoad, onDiscountsError);
    }
    return () => { };
  }, [user, onDiscountsLoad, onDiscountsError]);

  const {
    codes,
    name,
    value,
    type,
    from,
    to,
    days,
    reportingLabels,
    plu,
    minOrderValue,
    status,
  } = discount;

  const isDatesValid = useMemo(() => {
    if (discount.from === '' || discount.to === '') return true;
    return discount.to >= discount.from;
  }, [discount]);

  const handleSave = useCallback(() => {
    setErrorMessage(null);
    const newDiscount = {
      ...discount,
      value: Number(discount.value),
      plu: Number(discount.plu),
      minOrderValue: Number(discount.minOrderValue),
    };
    if (isDiscount(newDiscount) && isDatesValid) {
      onSave(newDiscount);
    } else {
      setErrorMessage('Please make sure all required fields are filled in.');
    }
  }, [discount, isDatesValid]);

  const handleCancel = useCallback(() => history.push(AppUrl.Discounts), []);

  const labelOptions = useMemo(() => {
    const labels = userLabels != null && userLabels.length !== 0
      ? userLabels
      : siteLabels;
    return [
      { label: 'No Labels', value: 'none' },
      ...labels.map((label) => ({ label: label.text, value: label.id })),
    ];
  }, [userLabels, siteLabels]);

  const title = useMemo(() => {
    if (initialValue != null) return 'Edit Discount';
    return 'Create Discount';
  }, [initialValue]);

  const handleChange = useCallback(<T extends keyof DiscountFormValues>(
    label: T, val: DiscountFormValues[T],
  ) => {
    const newDiscount = { ...discount, [label]: val };
    setDiscount(newDiscount);
  }, [discount]);

  const isFixedLabel = useMemo(() => {
    if (
      userLabels != null
      && !hasPermission(RoleRestrictedAction.ViewDiscount, user)
    ) {
      const userLabelIds = userLabels.map((uLabel) => uLabel.id);
      handleChange('reportingLabels', userLabelIds);
      return true;
    }
    return false;
  }, [userLabels]);

  const handleTypeChange = useCallback((_, newType: DiscountType) => {
    if (newType != null) {
      const newDiscount = { ...discount, type: newType, value: '' };
      setDiscount(newDiscount);
    }
  }, [discount]);

  const handleActiveChange = useCallback((_, newStatus: DiscountStatus) => {
    if (newStatus != null) {
      handleChange('status', newStatus);
    }
  }, [handleChange]);

  const discountAmount = useMemo(() => {
    const editedValue = Number(value);
    return type === DiscountType.Percentage && editedValue > 0 && editedValue < 1
      ? (Math.floor((editedValue * 100))).toString()
      : value;
  }, [type, value]);

  const handleAmountChange = useCallback((val: string) => {
    if (type === DiscountType.Percentage && val.length > 2) return;
    const newValue = type === DiscountType.Percentage && Number(val) > 1
      ? (Number(val) / 100).toString()
      : val;
    handleChange('value', newValue);
  }, [handleChange, type, value]);

  const handleOpen = useCallback(() => setIsOpen(true), []);

  const handleRemove = useCallback((discountCode: DiscountCode['code']) => {
    const newCodes = codes.filter((code) => code.code !== discountCode);
    handleChange('codes', newCodes);
  }, [handleChange, codes]);

  const handleCreate = useCallback((discountCode: DiscountCode) => {
    handleChange('codes', [...codes, discountCode]);
    setIsOpen(false);
  }, [handleChange, codes]);

  const handleUploadCodes = useCallback((discountCodes: DiscountCode[]) => {
    setDiscount((oldDiscount) => ({
      ...oldDiscount,
      codes: oldDiscount.codes.concat(discountCodes),
    }));
  }, []);

  const handleClose = useCallback(() => setIsOpen(false), []);

  const existingCodes = useMemo(() => {
    const allCodes = discounts.reduce<string[]>((output, dis) => [
      ...output, ...dis.codes.map(({ code }) => code),
    ], []);

    return [...codes.map((code) => code.code), ...allCodes];
  }, [codes, discounts]);

  if (isLoading) return <Spinner />;

  return (
    <Grid className={classes.header}>
      <Paper className={classes.discountForm}>
        <Grid>
          <Grid className={classes.title}>
            <Typography variant="h6">{`1. ${title}`}</Typography>
          </Grid>
          <Grid className={classes.form} container>
            <TextField
              variant="outlined"
              label="Discount Name"
              style={{ width: 350, marginRight: 20, marginBottom: 20 }}
              value={name}
              onChange={(event) => handleChange('name', event.target.value)}
              required
            />
            <TextField
              type="number"
              variant="outlined"
              style={{ width: 219, marginRight: 20, marginBottom: 20 }}
              label="Discount Amount"
              value={discountAmount}
              onChange={(event) => handleAmountChange(event.target.value)}
              required
            />
            <Grid className={classes.typeSelection}>
              <ToggleButtonGroup
                style={{ height: 56 }}
                value={type}
                exclusive
                onChange={handleTypeChange}
              >
                <ToggleButton style={{ width: 56 }} value={DiscountType.FixedValue} aria-label="fixed value">
                  £
                </ToggleButton>
                <ToggleButton style={{ width: 56 }} value={DiscountType.Percentage} aria-label="percentage">
                  %
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <DateField
              label="Valid From"
              value={from}
              onChange={(event) => handleChange('from', event.target.value)}
            />
            <DateField
              label="Valid To"
              value={to}
              onChange={(event) => handleChange('to', event.target.value)}
              disabled={from === ''}
              error={!isDatesValid}
              helperText={isDatesValid ? '' : 'Date is not valid or before from date'}
            />
            <MultipleSelect
              className={classes.multipleSelect}
              label="Valid on Days of Week"
              defaultValues={days || []}
              options={getDays()}
              onChange={(newDays) => handleChange('days', newDays)}
              helperText="All days if left blank."
            />
            {isFixedLabel ? (
              <TextField
                type="string"
                variant="outlined"
                style={{ width: 350, marginRight: 20, marginBottom: 20 }}
                label="Reporting Label"
                value={userLabels.length > 0 ? userLabels[0].text : ''}
                disabled
              />
            ) : (
              <MultipleSelect
                className={classes.multipleSelect}
                label="Valid Reporting Labels"
                defaultValues={reportingLabels || []}
                options={labelOptions}
                onChange={(newReportingLabels) => handleChange('reportingLabels', newReportingLabels)}
              />
            )}
            <TextField
              type="number"
              variant="outlined"
              style={{ width: 350, marginRight: 20, marginBottom: 20 }}
              label="PLU"
              value={plu || ''}
              onChange={(event) => handleChange('plu', event.target.value)}
              required
            />
            <TextField
              type="number"
              variant="outlined"
              style={{ width: 350, marginRight: 20 }}
              label="Min. Order Value"
              value={minOrderValue}
              onChange={(event) => handleChange('minOrderValue', event.target.value)}
              helperText="No minimum if left blank."
            />
            <Grid style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography style={{ paddingRight: 5, paddingTop: 15 }} variant="subtitle1">Is Discount Active?</Typography>
              <ToggleButtonGroup
                style={{ height: 56 }}
                value={status}
                exclusive
                onChange={handleActiveChange}
              >
                <ToggleButton
                  classes={{ root: classes.toggleOn }}
                  value={DiscountStatus.On}
                  aria-label={DiscountStatus.On}
                >
                  On
                </ToggleButton>
                <ToggleButton
                  classes={{ root: classes.toggleOff }}
                  value={DiscountStatus.Off}
                  aria-label={DiscountStatus.Off}
                >
                  Off
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <DiscountCodesTable
        discountCodes={codes}
        existingCodes={existingCodes}
        onCreate={handleOpen}
        onRemove={handleRemove}
        onUpload={handleUploadCodes}
      />
      <DiscountCodeDialog
        codes={existingCodes}
        open={isOpen}
        onAdd={handleCreate}
        onClose={handleClose}
      />
      {errorMessage && (
        <Typography className={classes.errorMessage} variant="body1" color="error">{errorMessage}</Typography>
      )}
      <Drawer anchor="bottom" variant="permanent">
        <Grid
          className={classes.buttons}
          container
          direction="row"
          justify="flex-end"
        >
          <Button className={classes.cancelButton} onClick={handleCancel} variant="outlined" color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save Discount
          </Button>
        </Grid>
      </Drawer>
    </Grid>
  );
};

export default DiscountForm;
