import React from 'react';
import PropTypes from 'prop-types';

import {
  Card, CardActions, CardContent, Button, Typography, withStyles,
} from '@material-ui/core';

const styles = () => ({
  simpleCard: {
    paddingBottom: 10,
    paddingLeft: 20,
  },
  card: {
    width: 250,
  },
  title: {
    fontSize: 14,
  },
  green: {
    display: 'inline-block',
    color: 'green',
  },
  red: {
    display: 'inline-block',
    color: 'red',
  },
});

const SimpleCard = ({
  classes, title, mainText, secondaryText, color, onClick, hideButton, disabled,
}) => (
  <div className={classes.simpleCard}>
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {title}
        </Typography>
        <Typography className={classes[color]} variant="h5" component="h2">
          {mainText}
          <Typography className={classes.red}>{secondaryText}</Typography>
        </Typography>
      </CardContent>
      {!hideButton ? (
        <CardActions>
          <Button size="small" onClick={onClick} disabled={disabled}>Learn More</Button>
        </CardActions>
      ) : null}
    </Card>
  </div>
);

SimpleCard.propTypes = {
  classes: PropTypes.shape().isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  mainText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  secondaryText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  hideButton: PropTypes.bool,
  disabled: PropTypes.bool,
};

SimpleCard.defaultProps = {
  hideButton: false,
  secondaryText: null,
  onClick: null,
  disabled: false,
};

export default withStyles(styles)(SimpleCard);
