import React, { useMemo } from 'react';

import {
  Box, Button, Grid, Paper, Typography, makeStyles,
} from '@material-ui/core';

import OptionsForm from '../../options-form/options-form';

import ProductOption from '../../../types/ProductOption';
import ProductData from '../../../types/ProductData';

type Props = {
  product: ProductData
  products: ProductData[]
  onClick: () => void
  onChange: (newOption: ProductOption, newOptionIndex: number) => void
  onRemove: (index: number) => void
};

const useStyles = makeStyles((theme) => ({
  mainSection: {
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
    marginRight: 5,
  },
}));

const ProductAddOns = ({
  product, products, onClick, onChange, onRemove,
}: Props) => {
  const classes = useStyles();

  const options = useMemo(() => product.options, [product]);

  const optionsForms = useMemo(() => {
    if (options == null) return null;
    if (options.length === 0) return null;

    return (
      <>
        <Typography variant="h6" gutterBottom>Options</Typography>
        {options.map((option, index) => (
          <OptionsForm
            key={option.id}
            index={index}
            products={products}
            option={option}
            onChange={(newOption) => onChange(newOption, index)}
            onRemove={onRemove}
          />
        ))}
      </>
    );
  }, [options, onRemove]);

  return (
    <Box>
      <Paper>
        <Box className={classes.mainSection}>
          <Typography variant="h6" gutterBottom>Create Add-ons</Typography>
          <Grid
            container
            direction="row"
            alignItems="center"
          >
            <Typography className={classes.title} variant="body1">Variety:</Typography>
            <Typography color="textSecondary" variant="body1">
              Offer different versions of the same product (e.g. different sizes or flavours)
            </Typography>
          </Grid>
          <Grid
            style={{ paddingBottom: 20 }}
            container
            direction="row"
            alignItems="center"
          >
            <Typography className={classes.title} variant="body1">Add-on:</Typography>
            <Typography color="textSecondary" variant="body1">
              Recommend other products from your menu (e.g. sides or desserts)
            </Typography>
          </Grid>
          <Button color="primary" variant="outlined" onClick={onClick}>
            Add An Option
          </Button>
        </Box>
      </Paper>
      {optionsForms}
    </Box>
  );
};

export default ProductAddOns;
