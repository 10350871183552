import READER_IMAGE from '../../../constants/readerImage';

import StripeLocationTerminalConfig from '../../../types/StripeTerminalConfig';
import Nullable from '../../../types/Nullable';
import SiteData from '../../../types/SiteData';

const getFallbackSplashImage = (
  selectedLocation: Nullable<StripeLocationTerminalConfig>,
  siteData: Nullable<SiteData>,
  siteSplashConfig: string,
  siteReaderSplashImage: string,
) => {
  if (siteData == null) return READER_IMAGE.Default;

  // if there is selected location and a site splash image then use that
  if (
    selectedLocation != null
    && selectedLocation.configuration_overrides != null
    && selectedLocation.configuration_overrides.length > 0
    && siteSplashConfig !== ''
    && siteReaderSplashImage !== ''
  ) return siteReaderSplashImage;

  return READER_IMAGE.Default;
};

export default getFallbackSplashImage;
