import firebase from 'firebase/app';

import NotificationData from '../types/NotificationData';
import Dispatch from '../types/Dispatch';

import {
  GET_NOTIFICATIONS_START,
  GET_NOTIFICATIONS_SUCCESS,
} from '../constants/actions';

export default (site: string) => (dispatch: Dispatch) => {
  dispatch({
    type: GET_NOTIFICATIONS_START,
  });

  firebase
    .firestore()
    .collection('notifications')
    .where('site', '==', site)
    .onSnapshot((snapshots) => {
      const notifications: NotificationData[] = [];
      snapshots.forEach((snapshot) => notifications
        .push(<NotificationData>{ ...snapshot.data(), id: snapshot.id }));

      dispatch({
        type: GET_NOTIFICATIONS_SUCCESS,
        activeNotifications: notifications.filter((notification) => notification.active),
        notifications,
      });
    });
};
