import firebase from 'firebase/app';

import isSiteData from '../../../helpers/isSiteData';

import Label from '../../../types/Label';
import SiteData from '../../../types/SiteData';

const setStripeSetupComplete = (
  labelId: Label['id'],
  site: SiteData['site'],
) => (
  firebase
    .firestore()
    .collection('sites')
    .where('site', '==', site)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((snapshot) => {
        const data = snapshot.data();
        const { id } = snapshot;
        const siteData: unknown = { id, ...data };
        if (!isSiteData(siteData) || siteData.reportingLabels == null) {
          throw new Error('Invalid site data');
        }
        const { reportingLabels } = siteData;
        const updatedLabels: Label[] = reportingLabels.map(
          (currentLabel) => (
            currentLabel.id === labelId
              ? { ...currentLabel, stripeAccountSetupComplete: true }
              : currentLabel
          ),
        );
        return firebase
          .firestore()
          .collection('sites')
          .doc(siteData.id)
          .update({ reportingLabels: updatedLabels });
      });
    })
);

export default setStripeSetupComplete;
