import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button, Grid, Typography, makeStyles,
} from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';

import Spinner from '../../components/spinner/spinner';
import SimpleSelect from '../../components/SimpleSelect/SimpleSelect';
import ActionCard from '../../components/ActionCard/ActionCard';

import getRoutes from '../../helpers/getRoutes';
import isHomeRoute from './helpers/isHomeRoute';
import getSiteUrl from '../../helpers/get-site-url';

import getSettings from '../../actions/get-settings';
import getSites from '../../actions/get-sites';
import updateUserSite from '../../actions/update-user-site';

import User from '../../types/User';
import ReduxState from '../../types/ReduxState';
import Nullable from '../../types/Nullable';
import SiteData from '../../types/SiteData';

const useStyles = makeStyles({
  button: {
    marginRight: 10,
    marginLeft: 10,
  },
  menuLink: {
    cursor: 'pointer',
    marginBottom: 20,
    '&:hover': {
      opacity: 0.5,
    },
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuSection: {
    marginTop: 20,
  },
});

const HomePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const user = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));
  const settings = useSelector<ReduxState, Nullable<SiteData>>((state) => state.settings.get('SETTINGS'));
  const isSettingsLoading = useSelector<ReduxState, boolean>((state) => state.settings.get('IS_SETTINGS_LOADING'));
  const siteNames = useSelector<ReduxState, Array<{ value: string, label: string }>>((state) => state.sites.get('SITE_NAMES'));
  const isSitesLoading = useSelector<ReduxState, boolean>((state) => state.sites.get('IS_SITES_LOADING'));

  useEffect(() => {
    if (user == null) return;
    const { site } = user;
    if (site) {
      dispatch(getSettings(site));
      dispatch(getSites());
    }
  }, [user]);

  const onUpdate = useCallback((site) => {
    dispatch(updateUserSite(user, site));
  }, [user]);

  const routes = useMemo(() => {
    if (user == null) return [];
    return getRoutes(user).filter((route) => isHomeRoute(route));
  }, [user]);

  const availableSites = useMemo(() => {
    if (user == null) return [];
    const sites = user.sites.map((siteConfig) => siteConfig.site);
    if (sites != null) return siteNames.filter((site) => sites.indexOf(site.value) >= 0);
    return [];
  }, [user, siteNames]);

  const siteSelector = useMemo(() => {
    if (availableSites.length <= 1 || user == null) return null;
    return (
      <SimpleSelect
        label="Site"
        items={availableSites}
        value={user.site}
        onChange={onUpdate}
        allowEmpty={false}
        size="small"
      />
    );
  }, [availableSites, onUpdate, user]);

  if (isSettingsLoading || isSitesLoading || user == null) return <Spinner />;

  if (!settings) {
    return (
      <Grid>
        {siteSelector}
        <Typography variant="h6">Sorry but site does not exist, please select another one.</Typography>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid>
        {siteSelector}
        <Button
          className={classes.button}
          color="primary"
          variant="outlined"
          size="large"
          target="_blank"
          href={getSiteUrl(settings.urlEndpoint)}
          endIcon={<LaunchIcon />}
        >
          Open Peazi Site
        </Button>
      </Grid>
      <Grid container spacing={2} className={classes.menuSection}>
        {routes.map((route) => isHomeRoute(route) && (
          <ActionCard key={route.label} route={route} />
        ))}
      </Grid>
    </Grid>
  );
};

export default HomePage;
