/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  ChangeEvent, useCallback, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Button,
  Grid,
  Paper,
  Link,
  TextField,
  Typography,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';

import peaziLogo from '../../images/peazi-logo.svg';
import peaziLogoDark from '../../images/peazi-logo-dark.svg';

import login from '../../actions/login';

import isEmailValid from '../../helpers/isEmailValid';

import AuthUrl from '../../types/AuthUrl';
import ReduxState from '../../types/ReduxState';
import Nullable from '../../types/Nullable';

const useStyles = makeStyles(((theme) => ({
  loginContainer: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    width: theme.spacing(50),
  },
  loginForm: {
    margin: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 100,
    height: 'auto',
  },
  loginText: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  link: { cursor: 'pointer' },
})));

const LoginPage = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const isLoggingIn = useSelector<ReduxState, boolean>((state) => state.authentication.get('IS_LOGGING_IN'));
  const errorMessage = useSelector<ReduxState, Nullable<string>>(
    (state) => state.authentication.get('AUTHORISATION_ERROR_MESSAGE'),
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const isFormValid = useMemo(() => isEmailValid(email) && password.length > 0, [email, password]);

  const handleLogin = useCallback(() => {
    dispatch(login(email, password));
  }, [email, password]);

  const onEmailChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, [setEmail]);

  const onPasswordChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }, [setPassword]);

  const onForgetPassword = useCallback(() => {
    history.push(AuthUrl.ForgotPassword);
  }, []);

  return (
    <Box className={classes.loginContainer}>
      <Paper className={classes.paper} elevation={3}>
        <Grid className={classes.loginForm}>
          <img className={classes.image} src={prefersDarkMode ? peaziLogoDark : peaziLogo} alt="Peazi Logo" />
          <Typography
            className={classes.loginText}
            variant="h6"
          >
            Log in to Console
          </Typography>
          <TextField
            label="Email"
            variant="outlined"
            type="email"
            margin="normal"
            autoComplete="email"
            value={email}
            onChange={onEmailChange}
            fullWidth
            required
            autoFocus
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            margin="normal"
            autoComplete="current-password"
            value={password}
            onChange={onPasswordChange}
            fullWidth
            required
          />
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={handleLogin}
            disabled={!isFormValid || isLoggingIn}
            fullWidth
          >
            Log in
          </Button>
          {errorMessage && <Typography variant="body1" color="error">{errorMessage}</Typography>}
          <Link className={classes.link} onClick={onForgetPassword}>
            Forgot password?
          </Link>
        </Grid>
      </Paper>
    </Box>
  );
};

export default LoginPage;
