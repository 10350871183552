import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  Box, Typography, Paper, makeStyles, Divider, Drawer, Grid, Button, Avatar,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import getSiteMap from '../../actions/get-site-map';
import getProducts from '../../actions/get-products';

import Spinner from '../../components/spinner/spinner';
import SubCategoryProductsList from './components/SubCategoryProductsList';

import filterSiteMap from './helpers/filterSiteMap';
import nameToUrl from '../../helpers/name-to-url';
import hasPermission from '../../helpers/has-permission';
import canUserViewProduct from '../../helpers/can-user-view-product';

import RoleRestrictedAction from '../../types/RoleRestrictedAction';

const useStyles = makeStyles((theme) => ({
  paper: { marginBottom: theme.spacing(10) },
  sectionTitle: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  mainSection: {
    margin: theme.spacing(2),
  },
  buttons: { padding: 10, paddingRight: 20 },
  backButton: { marginRight: 10 },
  imageGrid: {
    padding: 10,
    width: '100%',
    marginBottom: 20,
  },
  image: {
    aspectRatio: '30 / 7',
    backgroundColor: 'black',
    width: '100%',
    maxWidth: '600px',
    height: 'auto',
    objectFit: 'contain',
    objectPosition: '50% 50%',
  },
}));

const SubCategoryPage = () => {
  const user = useSelector((state) => state.authentication.get('USER'));
  const isSiteMapLoading = useSelector((state) => state.siteMap.get('IS_SITE_MAP_LOADING'));
  const siteMap = useSelector((state) => state.siteMap.get('SITE_MAP'));
  const isProductsLoading = useSelector((state) => state.products.get('IS_PRODUCTS_LOADING'));
  const products = useSelector((state) => state.products.get('PRODS'));
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const { category, subCategory } = params;

  useEffect(() => {
    if (user.site) {
      dispatch(getSiteMap(user.site));
      dispatch(getProducts(user.site));
    }
  }, []);

  const userSiteConfig = useMemo(
    () => user.sites.find((site) => site.site === user.site),
    [user],
  );

  const filteredSiteMap = useMemo(
    () => filterSiteMap(siteMap, category, subCategory),
    [siteMap, category, subCategory],
  );

  const userCanEdit = useMemo(() => {
    const { products: productsInSubCategory } = filteredSiteMap;
    if (
      productsInSubCategory == null
      || productsInSubCategory.length === 0
      || products.length === 0
      || userSiteConfig == null
    ) return true;
    const productsWithInfo = productsInSubCategory
      .map(({ plu }) => products.find((product) => product.plu === plu))
      .filter((productInfo) => productInfo != null);
    const userCanViewProducts = productsWithInfo.some(
      (completeProduct) => canUserViewProduct(completeProduct, userSiteConfig),
    );
    return userCanViewProducts && hasPermission(RoleRestrictedAction.EditCategory, user);
  }, [user, filteredSiteMap, products]);

  const handleBackClick = useCallback(() => {
    history.push(`/site-map/${nameToUrl(category)}`);
  }, [category]);

  const handleEditClick = useCallback(() => {
    history.push(`/site-map/${nameToUrl(category)}/${nameToUrl(subCategory)}/edit`);
  }, [category, subCategory]);

  if (filteredSiteMap.length === 0 || products.length === 0) return null;

  if (isSiteMapLoading || isProductsLoading) return <Spinner />;

  return (
    <Grid>
      <Paper className={classes.paper} variant="outlined">
        <Typography className={classes.sectionTitle} variant="h6">{filteredSiteMap.name}</Typography>
        <Divider />
        <Box className={classes.mainSection}>
          <Typography variant="body1" gutterBottom>{`Name: ${filteredSiteMap.name}`}</Typography>
          {filteredSiteMap.image && (
            <Grid className={classes.imageGrid}>
              <Avatar variant="rounded" src={filteredSiteMap.image} className={classes.image} />
            </Grid>
          )}
          <Typography variant="body1" gutterBottom>{`Sequence: ${filteredSiteMap.sequence}`}</Typography>
          <Typography variant="body1" gutterBottom>Products:</Typography>
          <SubCategoryProductsList items={filteredSiteMap.products} products={products} />
        </Box>
      </Paper>
      <Drawer anchor="bottom" variant="permanent">
        <Grid
          className={classes.buttons}
          container
          direction="row"
          justify="flex-end"
        >
          <Button
            className={classes.backButton}
            variant="outlined"
            color="primary"
            onClick={handleBackClick}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleEditClick}
            disabled={!userCanEdit}
            endIcon={<EditIcon />}
          >
            Edit Sub Category
          </Button>
        </Grid>
      </Drawer>
    </Grid>
  );
};

export default SubCategoryPage;
