import * as Joi from 'joi';
import ProcessingFeeScheme from '../../../types/ProcessingFeeScheme';
import extractEnumValues from '../../../helpers/extractEnumValues';
import ProcessingFeeSchemeType from '../../../types/ProcessingFeeSchemeType';

export const processingFeeSchemeSchema = Joi.object({
  type: Joi.string().valid(...extractEnumValues(ProcessingFeeSchemeType)).required(),
  labelId: Joi.when('type', {
    is: Joi.string().valid(
      ProcessingFeeSchemeType.PayToLabel,
    ),
    then: Joi.string().required(),
    otherwise: null,
  }),
});

const isValidProcessingFeeScheme = (value: unknown): value is ProcessingFeeScheme => (
  processingFeeSchemeSchema.validate(value).error == null
);

export default isValidProcessingFeeScheme;
