import Nullable from '../../../types/Nullable';
import Query from '../../../types/Query';

const getQueryString = (last4: string, site?: Nullable<string>, query?: Query) => {
  let endpoint = 'unpaidOrders';

  if (site != null && site.length !== 0) {
    endpoint += `?site=${encodeURIComponent(site)}`;
  }

  if (query != null && query.pageSize != null && query.page != null) {
    endpoint += `${endpoint.includes('?') ? '&' : '?'}pageSize=${query.pageSize}&page=${query.page}`;
  }

  if (query && query.search && query.search !== '') {
    endpoint += `${endpoint.includes('?') ? '&' : '?'}search=${encodeURIComponent(query.search)}`;
  }

  if (last4 !== '') {
    endpoint += `${endpoint.includes('?') ? '&' : '?'}last4=${encodeURIComponent(last4)}`;
  }

  return endpoint;
};

export default getQueryString;
