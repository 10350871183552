import Discount from '../../../types/Discount';
import DiscountType from '../../../types/DiscountType';
import DiscountStatus from '../../../types/DiscountStatus';
import Nullable from '../../../types/Nullable';

const discountToFormValues = (initialValue?: Nullable<Discount>) => {
  if (initialValue == null) {
    return {
      codes: [],
      name: '',
      value: '',
      type: DiscountType.FixedValue,
      from: '',
      to: '',
      days: [],
      reportingLabels: [],
      plu: null,
      minOrderValue: '0',
      status: DiscountStatus.On,
    };
  }

  return {
    ...initialValue,
    value: initialValue.value.toString(),
    plu: initialValue.plu != null ? initialValue.plu.toString() : null,
    minOrderValue: initialValue.minOrderValue.toString(),
  };
};

export default discountToFormValues;
