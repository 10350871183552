import { firestore } from 'firebase/app';

import isOrderData from './isOrderData';

import OrderData from '../types/OrderData';
import Nullable from '../types/Nullable';

const initialiseOrderDataListener = (
  orderNumber: OrderData['orderNumber'],
  setOrderData: React.Dispatch<React.SetStateAction<Nullable<OrderData>>>,
  setError: React.Dispatch<React.SetStateAction<Nullable<Error>>>,
) => (
  firestore()
    .collection('orders')
    .where('orderNumber', '==', orderNumber)
    .onSnapshot((querySnapshot) => {
      const queryResults: OrderData[] = [];
      querySnapshot.forEach((doc) => {
        const data = { ...doc.data(), id: doc.id };
        if (isOrderData(data)) queryResults.push(data);
      });
      if (queryResults.length > 0) {
        setOrderData(queryResults[0]);
      } else {
        setError(new Error(`Order data for ${orderNumber} could not be loaded successfully.`));
      }
    },
    (error) => {
      setError(error);
    })
);

export default initialiseOrderDataListener;
