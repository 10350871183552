import React from 'react';

import {
  Box, Divider, Grid, Paper, Typography, makeStyles,
} from '@material-ui/core';

import ListField from '../../../components/list-field/list-field';

import SiteData from '../../../types/SiteData';

const useStyles = makeStyles(() => ({
  paper: { height: '100%' },
  sectionTitle: {
    marginTop: 20,
    marginLeft: 20,
    marginBottom: 5,
  },
  mainSection: {
    margin: 20,
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

type Props = {
  siteData: SiteData
};

const OrderFlowSection = ({ siteData }: Props) => {
  const classes = useStyles();

  const { orderFlow } = siteData;

  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} variant="outlined">
        <Typography className={classes.sectionTitle} variant="h6">Site Order Flow</Typography>
        <Divider />
        <Box className={classes.mainSection}>
          <ListField
            title="Shown on Status Board"
            list={orderFlow?.statusBoard || []}
          />
          <ListField
            title="Notify User (Text)"
            list={orderFlow?.notifyUserText || []}
          />
          <ListField
            title="Notify User (Email)"
            list={orderFlow?.notifyUserEmail || []}
          />
        </Box>
      </Paper>
    </Grid>
  );
};

export default OrderFlowSection;
