import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@material-ui/core';

import Spinner from '../../components/spinner/spinner';
import SlotTimesTable from './components/SlotTimesTable';
import SlotTimesDialog from './components/slot-times-dialog';

import getSlotTimes from '../../actions/get-slot-times';
import getSettings from '../../actions/get-settings';

import createSlotTime from './helpers/create-slot-time';
import updateSlotTime from './helpers/update-slot-time';
import removeSlotTime from './helpers/remove-slot-time';

import STORE_KEYS from '../../constants/store-keys';

const {
  SLOT_TIMES: { IS_SLOT_TIMES_LOADING, SLOT_TIMES },
  AUTHENTICATION: { USER },
} = STORE_KEYS;

const SlotTimesPage = ({ match }) => {
  const user = useSelector((state) => state.authentication.get(USER));
  const isSiteDataLoading = useSelector((state) => state.settings.get('IS_SETTINGS_LOADING'));
  const siteData = useSelector((state) => state.settings.get('SETTINGS'));
  const isSlotTimesLoading = useSelector((state) => state.slotTimes.get(IS_SLOT_TIMES_LOADING));
  const slotTimes = useSelector((state) => state.slotTimes.get(SLOT_TIMES));
  const [open, setOpen] = useState(false);
  const [selectedSlotTime, setSelectedSlotTime] = useState(null);
  const [updating, setUpdating] = useState(false);
  const dispatch = useDispatch();
  const { day } = match.params;

  useEffect(() => {
    dispatch(getSlotTimes(user.site, day));
    dispatch(getSettings(user.site));
  }, []);

  const onUpdate = (id, slotTime) => {
    setUpdating(true);
    updateSlotTime(id, slotTime)
      .then(() => {
        setUpdating(false);
        setOpen(false);
        setSelectedSlotTime(null);
      });
  };

  const onSave = (slotTime) => {
    if (selectedSlotTime) {
      onUpdate(selectedSlotTime.id, slotTime);
    } else {
      createSlotTime({ ...slotTime, site: user.site })
        .then(() => setOpen(false));
    }
  };

  const onEdit = (slotTime) => {
    setOpen(true);
    setSelectedSlotTime(slotTime);
  };

  const onRemove = (slotTimeId) => {
    setUpdating(true);
    removeSlotTime(slotTimeId)
      .then(() => setUpdating(false));
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedSlotTime(null);
  };

  if (isSiteDataLoading || isSlotTimesLoading || updating) return <Spinner />;

  return (
    <Grid>
      <SlotTimesTable
        slotTimes={slotTimes}
        onCreate={() => setOpen(true)}
        onEdit={onEdit}
        onRemove={onRemove}
        user={user}
        siteData={siteData}
      />
      <SlotTimesDialog
        open={open}
        onSave={onSave}
        selectedSlotTime={selectedSlotTime}
        handleClose={handleClose}
        siteData={siteData}
      />
    </Grid>
  );
};

SlotTimesPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape(),
  }).isRequired,
};

export default SlotTimesPage;
