import React, { useCallback } from 'react';

import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import {
  Paper, Grid, Typography, IconButton, makeStyles,
} from '@material-ui/core';

type Props = {
  label?: string
  onChange: (newValue: number) => void
  value: number
  maxValue?: number
  disabled?: boolean
};

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 125,
  },
  number: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const QuantitySelector = ({
  label, onChange, value, maxValue, disabled,
}: Props) => {
  const classes = useStyles();

  const increment = useCallback(() => onChange(value + 1), [onChange, value]);

  const decrement = useCallback(() => onChange(Math.max(1, value - 1)), [onChange, value]);

  return (
    <Grid container justify="center">
      {label && (
        <Grid className={classes.label} item>
          <Typography>{label}</Typography>
        </Grid>
      )}
      <Paper variant="outlined">
        <Grid item xs={12}>
          <Grid container direction="row" justify="center">
            <Grid item>
              <IconButton
                key="decrement"
                className="quantity-button quantity-button-left"
                aria-label="Decrement"
                onClick={decrement}
                disabled={disabled || (value === 1)}
              >
                <RemoveIcon />
              </IconButton>
            </Grid>
            <Grid className={classes.number} item>
              <Typography>{value}</Typography>
            </Grid>
            <Grid item>
              <IconButton
                key="increment"
                className="quantity-button quantity-button-right"
                aria-label="Increment"
                onClick={increment}
                disabled={disabled || (maxValue ? value === maxValue : false)}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default QuantitySelector;
