import OrderPaymentType from '../../../types/OrderPaymentType';

const isPaymentMethod = (value: any): value is OrderPaymentType => [
  OrderPaymentType.ApplePay,
  OrderPaymentType.GooglePay,
  OrderPaymentType.Card,
  OrderPaymentType.CardPresent,
].includes(value);

export default isPaymentMethod;
