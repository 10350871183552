import request from '../../../helpers/request';

const getOrders = async (query, site, refundedOrders, status, reportingLabels) => {
  const {
    pageSize, page, search, advancedSearch, orderBy, orderDirection,
  } = query;
  let endpoint = `orders?pageSize=${pageSize}&page=${page}&search=${encodeURIComponent(search)}&site=${encodeURIComponent(site || '')}&refundedOrders=${refundedOrders ? '1' : ''}&orderNumber=${encodeURIComponent(advancedSearch.orderNumber || '')}&tableNumber=${encodeURIComponent(advancedSearch.tableNumber || '')}&amount=${encodeURIComponent(advancedSearch.amount || '')}&last4=${encodeURIComponent(advancedSearch.last4 || '')}&timeAfter=${advancedSearch.timeAfter || ''}&timeBefore=${advancedSearch.timeBefore || ''}&status=${status}`;
  reportingLabels.forEach((label) => {
    endpoint += `&reportingLabels[]=${label}`;
  });
  if (orderBy != null) {
    endpoint += `&orderBy=${orderBy.field}&direction=${orderDirection}`;
  }
  try {
    const result = await request(endpoint, 'GET');
    return {
      data: result.data,
      page: result.page,
      totalCount: result.totalCount,
    };
  } catch (e) {
    return {
      data: [],
      page: 0,
      totalCount: 0,
    };
  }
};

export default getOrders;
