import { Map } from 'immutable';
import {
  GET_PRODUCTS_START,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
} from '../constants/actions';
import STORE_KEYS from '../constants/store-keys';

const {
  IS_PRODUCTS_LOADING,
  AMOUNT_OF_PRODUCTS,
  PRODS,
  PRODUCTS,
  PRODUCT_ID,
  PRODUCTS_LOADING_ERROR,
} = STORE_KEYS.PRODUCTS;

const initialState = {};
initialState[IS_PRODUCTS_LOADING] = false;
initialState[AMOUNT_OF_PRODUCTS] = 0;
initialState[PRODS] = [];
initialState[PRODUCTS] = [];
initialState[PRODUCT_ID] = '';
initialState[PRODUCTS_LOADING_ERROR] = '';
const initialStateMap = Map(initialState);

export default (state = initialStateMap, action) => {
  switch (action.type) {
    case GET_PRODUCTS_START:
      return state
        .set(IS_PRODUCTS_LOADING, true)
        .set(PRODS, [])
        .set(PRODUCTS, [])
        .set(PRODUCT_ID, '')
        .set(PRODUCTS_LOADING_ERROR, '');
    case GET_PRODUCTS_SUCCESS:
      return state
        .set(IS_PRODUCTS_LOADING, false)
        .set(AMOUNT_OF_PRODUCTS, action.amountOfProducts)
        .set(PRODUCT_ID, action.productId)
        .set(PRODS, action.prods)
        .set(PRODUCTS, action.products);
    case GET_PRODUCTS_ERROR:
      return state
        .set(IS_PRODUCTS_LOADING, false)
        .set(PRODUCTS_LOADING_ERROR, action.message);
    default:
      return state;
  }
};
