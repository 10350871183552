import READER_IMAGE from '../../../constants/readerImage';

import StripeLocationTerminalConfig from '../../../types/StripeTerminalConfig';
import Nullable from '../../../types/Nullable';
import SiteData from '../../../types/SiteData';

const getSplashImage = (
  selectedLocation: Nullable<StripeLocationTerminalConfig>,
  siteData: Nullable<SiteData>,
  siteSplashConfig: string,
  siteReaderSplashImage: string,
) => {
  // Get selected location splash image (if has one)
  if (
    selectedLocation != null
    && selectedLocation.configuration_overrides != null
    && selectedLocation.configuration_overrides.length > 0
    && selectedLocation.metadata.splashImage != null
  ) {
    return selectedLocation.metadata.splashImage;
  }

  // Get site config splash image (if has one)
  if (siteData != null && siteSplashConfig.length > 0) {
    return siteReaderSplashImage;
  }

  // Just use default
  return READER_IMAGE.Default;
};

export default getSplashImage;
