import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

import OutlinedButton from '../outlined-button/outlined-button';

type Props = {
  title?: string
  description?: string
  open: boolean
  onRemove: () => void
  handleClose: () => void
};

const RemoveItemDialog = ({
  title, description, open, onRemove, handleClose,
}: Props) => (
  <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">{title || ''}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">{description || ''}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <OutlinedButton
        label="Cancel"
        color="secondary"
        onClick={handleClose}
      />
      <OutlinedButton label="Confirm" color="primary" onClick={onRemove} />
    </DialogActions>
  </Dialog>
);

export default RemoveItemDialog;
