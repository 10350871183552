import React from 'react';

import Chip from '@material-ui/core/Chip';
import { CreditCard, Help } from '@material-ui/icons';

type Props = {
  reason: string
};

const FailedStatus = ({ reason }: Props) => {
  switch (reason) {
    case 'card_declined':
      return (
        <Chip
          icon={<CreditCard />}
          label="Card Declined"
          color="default"
          variant="outlined"
        />
      );
    case 'expired_card':
      return (
        <Chip
          icon={<CreditCard />}
          label="Fraudulent"
          color="default"
          variant="outlined"
        />
      );
    case 'fraudulent':
      return (
        <Chip
          icon={<CreditCard />}
          label="Card Expired"
          color="default"
          variant="outlined"
        />
      );
    case 'incorrect_number':
      return (
        <Chip
          icon={<CreditCard />}
          label="Incorrect Card Number"
          color="default"
          variant="outlined"
        />
      );
    case 'incorrect_cvc':
      return (
        <Chip
          icon={<CreditCard />}
          label="Incorrect Card CVC"
          color="default"
          variant="outlined"
        />
      );
    case 'incorrect_zip':
      return (
        <Chip
          icon={<CreditCard />}
          label="Incorrect Postcode"
          color="default"
          variant="outlined"
        />
      );
    case 'insufficient_funds':
      return (
        <Chip
          icon={<CreditCard />}
          label="Insufficient Funds"
          color="default"
          variant="outlined"
        />
      );
    default:
      return (
        <Chip
          icon={<Help />}
          label={reason}
          color="default"
          variant="outlined"
        />
      );
  }
};

export default FailedStatus;
