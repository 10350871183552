import Label from '../../../types/Label';
import OrderData from '../../../types/OrderData';

const filterOrderBySelectedLabels = (
  orderArray: OrderData[],
  filterLabels: Label[],
  isOutstanding = false,
) => {
  if (filterLabels.length === 0) return orderArray;
  const filterLabelIds = filterLabels.map((label) => label.id);
  const filteredOrder = orderArray.filter(({ order }) => (
    order.some(({ reportingLabels, processed }) => (
      reportingLabels?.some((label) => (
        filterLabelIds.some((filteredLabel) => {
          if (isOutstanding) {
            return (filteredLabel === label) && processed !== true;
          }
          return (filteredLabel === label);
        })
      ))
    ))
  ));
  return filteredOrder;
};

export default filterOrderBySelectedLabels;
