import { firestore } from 'firebase/app';

import isDiscount from './isDiscount';

const getDiscount = (discountId: string) => firestore()
  .doc(`discounts/${discountId}`)
  .get()
  .then((doc) => {
    if (doc.exists) {
      const data = { ...doc.data(), id: doc.id };
      if (isDiscount(data)) return data;
      return Promise.reject(new Error('Discount is not valid'));
    }
    return Promise.reject(new Error('Discount was not found'));
  });

export default getDiscount;
