import moment from 'moment';
import OrderData from '../../../types/OrderData';
import slotTimeToMilliseconds from '../../../helpers/slotTimeToMilliseconds';

export default (orderData: OrderData) => {
  if (orderData.onlineOrder && orderData.slotTime) {
    return moment.duration(moment().diff(moment(slotTimeToMilliseconds(orderData.slotTime))));
  }
  return moment.duration(moment().diff(moment(orderData.orderTime)));
};
