import { DateTime } from 'luxon';

import DateType from '../types/DateType';
import Label from '../../../types/Label';

const getDefaultOptions = (reportingLabels: Label[], posServer: string | null) => ({
  type: DateType.TradingDate,
  start: DateTime.now().minus({ weeks: 1 }).toFormat('yyyy-MM-dd'),
  end: DateTime.now().toFormat('yyyy-MM-dd'),
  reportingLabels: reportingLabels?.map((label) => label.id) || [],
  posServer,
});

export default getDefaultOptions;
