import SiteData from '../../../types/SiteData';
import Kiosk from '../../../types/Kiosk';
import Nullable from '../../../types/Nullable';

export default (siteUrlEndpoint: SiteData['urlEndpoint'], selectedKiosk: Nullable<Kiosk>) => {
  if (selectedKiosk == null) return '';
  switch (process.env.NODE_ENV) {
    case 'development':
      return `https://kiosk.dev.peazi.com/${siteUrlEndpoint}?id=${selectedKiosk.id}`;
    case 'staging':
      return `https://kiosk.stage.peazi.com/${siteUrlEndpoint}?id=${selectedKiosk.id}`;
    default:
      return `https://kiosk.peazi.com/${siteUrlEndpoint}?id=${selectedKiosk.id}`;
  }
};
