import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import {
  Accordion, AccordionSummary, AccordionDetails, Button, Grid, Typography, makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import UploadZone from '../../components/upload-zone/upload-zone';
import Spinner from '../../components/spinner/spinner';
import OutlinedButton from '../../components/outlined-button/outlined-button';

import request from '../../helpers/request';
import uploadFile from './helpers/upload-file';

import getProducts from '../../actions/get-products';

import STORE_KEYS from '../../constants/store-keys';

import { HOME } from '../../constants/urls';
import getImageUrl from '../../helpers/get-image-url';

const {
  AUTHENTICATION: { USER },
  PRODUCTS: { PRODUCT_ID },
} = STORE_KEYS;

const useStyles = makeStyles(() => ({
  accordion: {
    marginTop: 20,
    marginBottom: 20,
  },
  buttonGroup: {
    marginBottom: 20,
  },
  button: {
    marginRight: 10,
  },
}));

const UploadPage = () => {
  const classes = useStyles();
  const user = useSelector((state) => state.authentication.get(USER));
  const productId = useSelector((state) => state.products.get(PRODUCT_ID));
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const [fileMetaData, setFileMetaData] = useState(null);
  const [fileData, setFileData] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.site) {
      dispatch(getProducts(user.site));
    }
  }, [user.site]);

  const userSiteConfig = useMemo(() => user.sites.find(({ site }) => site === user.site), []);

  const onDrop = (data) => {
    setFileMetaData(data.fileName);
    setFileData(data.fileData);
  };

  const onSend = () => {
    const { site } = user;
    setUploading(true);
    setError(false);
    setMessage(null);

    request(
      'transformFile',
      'POST',
      {
        productId,
        site,
        fileMetaData,
        fileData,
        reportingLabels: userSiteConfig.reportingLabels || [],
      },
      false,
    )
      .then(() => {
        uploadFile(site, fileMetaData);

        setUploading(false);
        setError(false);
        setMessage('File has been successfully uploaded.');
        setFileData([]);

        history.push(HOME);
      })
      .catch((errorMessage) => {
        setUploading(false);
        setError(true);
        setMessage(errorMessage);
      });
  };

  const handleDownload = async (file) => {
    const link = await getImageUrl(file);
    window.location.href = link;
  };

  if (uploading) return <Spinner />;

  return (
    <Grid>
      <Typography variant="h6">
        {`Product Upload for ${user.site}. Please upload a CSV file with the following fields:`}
      </Typography>
      <Grid className={classes.accordion}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>File setup</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li>GroupSequence</li>
              <li>Group</li>
              <li>CategorySequence</li>
              <li>Category</li>
              <li>Sequence</li>
              <li>PLU</li>
              <li>PLUName</li>
              <li>Price</li>
              <li>Show</li>
              <li>NiceName</li>
              <li>Description</li>
              <li>Over18</li>
              <li>SoldOut</li>
              <li>DiscountCodes</li>
              <li>NonDiscountable</li>
              <li>InvolvesKitchen</li>
              <li>AddonDescription</li>
              <li>Addon1</li>
              <li>Addon1QTY</li>
              <li>Addon2</li>
              <li>Addon2QTY</li>
              <li>Addon3</li>
              <li>Addon3QTY</li>
              <li>Addon4</li>
              <li>Addon4QTY</li>
            </ul>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid className={classes.buttonGroup}>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          size="large"
          onClick={() => handleDownload('test/peazi-template.csv')}
        >
          Download Template File
        </Button>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={() => handleDownload('test/example-products-file.csv')}
        >
          Download Example File
        </Button>
      </Grid>
      <Typography variant="body1">Please validate your CSV file via https://csvlint.io before uploading</Typography>
      <UploadZone onDrop={onDrop} />
      {fileData.length > 0 ? <Typography variant="body1" gutterBottom>{`Products ready to upload to ${user.site}`}</Typography> : null}
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <OutlinedButton label="Upload" color="primary" onClick={onSend} disabled={fileData.length === 0} />
        <Button color="secondary" variant="outlined" size="large" component={Link} to={HOME}>
          Skip
        </Button>
      </Grid>
      {message
        ? <Typography variant="body1" color={error ? 'error' : 'primary'}>{message}</Typography>
        : null}
    </Grid>
  );
};

export default UploadPage;
