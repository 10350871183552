import React, {
  ChangeEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Grid, IconButton, Paper, Tooltip, makeStyles,
} from '@material-ui/core';
import { DataGrid, GridColumns, GridCellParams } from '@material-ui/data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';

import CustomChip from '../CustomChip/CustomChip';
import RemoveItemDialog from '../RemoveItemDialog/RemoveItemDialog';
import Toolbar from './components/Toolbar';

import hasPermission from '../../helpers/has-permission';
import formatMoney from '../../helpers/formatMoney';

import RoleRestrictedAction from '../../types/RoleRestrictedAction';
import ReduxState from '../../types/ReduxState';
import User from '../../types/User';
import ProductData from '../../types/ProductData';
import SiteData from '../../types/SiteData';
import ProductFilter from '../../types/ProductFilter';
import Nullable from '../../types/Nullable';
import Label from '../../types/Label';
import ProductType from '../../types/ProductType';

type Props = {
  products: ProductData[]
  siteData: SiteData
  onRemove: (id: string) => void
  changeProduct: (id: string, data: any) => void
  currentPage: number
  onPageChange: (page: number) => void
  searchText: string
  onSearchTextChange: (newSearchText: string) => void
  onSearchTextClear: () => void
  filter: string
  onFilterChange: (newFilterOption: ProductFilter) => void
  onRestock: () => void
  reportingLabelOptions: Label[]
  selectedReportingLabels: Label['id'][]
  onReportingLabelSelectChange: (event: ChangeEvent<{ value: unknown }>) => void
  isReportingLabelSelectDisabled: boolean
};

const useStyles = makeStyles(() => ({
  table: {
    height: 725,
    width: '100%',
  },
  toolbar: {
    margin: 5,
  },
  search: {
    marginLeft: 'auto',
    marginRight: 0,
  },
}));

const ProductsTable = ({
  products,
  siteData,
  onRemove,
  changeProduct,
  currentPage,
  onPageChange,
  searchText,
  onSearchTextChange,
  onSearchTextClear,
  filter,
  onFilterChange,
  onRestock,
  reportingLabelOptions,
  selectedReportingLabels,
  onReportingLabelSelectChange,
  isReportingLabelSelectDisabled,
}: Props) => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [productToRemove, setProductToRemove] = useState<Nullable<ProductData | any>>(null);
  const user = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));
  const history = useHistory();
  const classes = useStyles();

  const handleRemove = useCallback(() => {
    if (productToRemove && productToRemove.id) {
      onRemove(productToRemove.id);
    }
  }, [onRemove, productToRemove]);

  const openRemoveDialog = useCallback(setProductToRemove, [setProductToRemove]);

  const closeRemoveDialog = useCallback(() => setProductToRemove(null), [setProductToRemove]);

  const description = useMemo(() => (productToRemove
    ? `Are you sure you want to remove ${productToRemove.name}`
    : ''), [productToRemove]);

  const productsList = useMemo(() => {
    const prods = products.filter((p) => (p.type || ProductType.Product) !== ProductType.GiftCard);
    const filteredProducts = filter === ProductFilter.All
      ? prods
      : prods.filter((product) => {
        if (filter === ProductFilter.SoldOut) return product.soldOut;
        if (filter === ProductFilter.Hidden) return !product.show;
        return true;
      });
    if (searchText == null || searchText.length === 0) return filteredProducts;
    const searchTerm = searchText.toLowerCase();
    return filteredProducts.filter((product) => product.name.toLowerCase().includes(searchTerm)
      || product.plu.toString().toLowerCase().includes(searchTerm));
  }, [products, searchText, filter]);

  const renderOnlineStatus = useCallback((params: GridCellParams) => {
    if (
      params.row.reportingLabels == null
      || params.row.reportingLabels.length === 0
      || siteData == null
      || siteData.offlineLabels == null
      || siteData.offlineLabels.length === 0
    ) return <CustomChip color="green" label="Online" tooltip="Product is Online" />;
    return params.row.reportingLabels
      .some((label: string) => (siteData != null && siteData.offlineLabels != null
        ? siteData.offlineLabels.includes(label)
        : false))
      ? <CustomChip color="red" label="Offline" tooltip="Product is offline" />
      : <CustomChip color="green" label="Online" tooltip="Product is online" />;
  }, [siteData]);

  const columns: GridColumns = useMemo(() => [
    {
      field: 'plu',
      headerName: 'PLU',
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 3,
      minWidth: 160,
    },
    {
      field: 'price',
      headerName: 'Price',
      flex: 1,
      minWidth: 100,
      renderCell: (params: GridCellParams) => formatMoney(params.row.price, siteData.currency),
    },
    {
      field: 'online',
      headerName: 'Online Status',
      flex: 1.25,
      minWidth: 120,
      renderCell: renderOnlineStatus,
    },
    {
      field: 'show',
      headerName: 'Shown Status',
      flex: 1.25,
      minWidth: 120,
      renderCell: (params: GridCellParams) => (
        <CustomChip
          color={params.row.show ? 'green' : 'red'}
          label={params.row.show ? 'Shown' : 'Hidden'}
          onClick={() => changeProduct(params.row.id, { show: !params.row.show })}
          tooltip={params.row.show ? 'Hide Product' : 'Show Product'}
        />
      ),
    },
    {
      field: 'soldOut',
      headerName: 'Stock',
      flex: 1.25,
      minWidth: 120,
      renderCell: (params: GridCellParams) => (
        <CustomChip
          color={params.row.soldOut ? 'red' : 'green'}
          label={params.row.soldOut ? 'Out Of Stock' : 'Instock'}
          onClick={() => changeProduct(params.row.id, {
            name: params.row.name, soldOut: !params.row.soldOut,
          })}
          tooltip={params.row.soldOut ? 'Change to instock' : 'Change to out of stock'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 130,
      renderCell: (params: GridCellParams) => (
        <Grid>
          <Tooltip title="View Product">
            <IconButton
              onClick={() => history.push(`/products/${params.row.plu}`)}
              color="inherit"
              disabled={!hasPermission(RoleRestrictedAction.ViewProduct, user)}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Remove Product">
            <IconButton
              onClick={() => openRemoveDialog(params.row)}
              color="inherit"
              disabled={!hasPermission(RoleRestrictedAction.RemoveProduct, user)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ),
    },
  ], []);

  return (
    <Grid>
      <Paper className={classes.table}>
        <DataGrid
          components={{ Toolbar }}
          rows={productsList}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 25]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          page={currentPage}
          onPageChange={onPageChange}
          componentsProps={{
            toolbar: {
              searchText,
              onChange: onSearchTextChange,
              clearSearch: onSearchTextClear,
              user,
              onRestock,
              products,
              siteData,
              filter,
              onFilterChange,
              reportingLabelOptions,
              selectedReportingLabels,
              onReportingLabelSelectChange,
              isReportingLabelSelectDisabled,
            },
          }}
        />
      </Paper>
      <RemoveItemDialog
        title="Remove Product"
        description={description}
        open={productToRemove != null}
        onRemove={handleRemove}
        handleClose={closeRemoveDialog}
      />
    </Grid>
  );
};

export default ProductsTable;
