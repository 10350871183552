import React, {
  useEffect, useMemo, useState, useCallback, ReactNode,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box, Grid, Tab, Typography, makeStyles,
} from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';

import Spinner from '../../components/spinner/spinner';
import SalesReportFilter from './components/SalesReportFilter';
import ProductsReportSection from './components/ProductsReportSection';
import SalesReportSection from './components/SalesReportSection';
import TransactionsReportSection from './components/TransactionReportSection';
import PayoutsReportSection from './components/PayoutsReportSection';
import ActivityReportSection from './components/ActivityReportSection';
import PosServerReport from './components/PosServerReport';
import SalesGraphs from './components/SalesGraphs';
import CommissionReport from './components/CommissionReport';
import DiscountsReport from './components/DiscountsReport';

import getDefaultOptions from './helpers/getDefaultOptions';

import getSettings from '../../actions/get-settings';
import getUsers from '../../actions/get-users';

import ReduxState from '../../types/ReduxState';
import User from '../../types/User';
import SiteData from '../../types/SiteData';
import Nullable from '../../types/Nullable';
import Options from './types/Options';
import ReconciliationReport from './components/ReconciliationReport';

const useStyles = makeStyles((theme) => ({
  tabList: {
    borderBottom: '1px solid #e8e8e8',
  },
  tab: {
    padding: theme.spacing(0, 4),
  },
}));

const ReportsPage = () => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState<string>('1');
  const [isSearched, setIsSearched] = useState<boolean>(false);

  const user = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));
  const users = useSelector<ReduxState, User[]>(
    (state) => state.users.get('USERS'),
  );
  const isSettingsLoading = useSelector<ReduxState, boolean>((state) => state.settings
    .get('IS_SETTINGS_LOADING'));
  const settings = useSelector<ReduxState, Nullable<SiteData>>((state) => state.settings.get('SETTINGS'));
  const dispatch = useDispatch();

  const userLabels = useMemo(() => {
    if (!user) return [];
    if (!settings) return [];
    if (!settings.reportingLabels) return [];
    const userSiteConfig = user.sites.find((siteConfig) => siteConfig.site === user.site);
    const labels = userSiteConfig?.reportingLabels || [];
    return settings.reportingLabels.filter(({ id }) => labels.includes(id));
  }, [settings, user]);

  const [options, setOptions] = useState<Options>(getDefaultOptions(userLabels, null));

  const siteLabels = useMemo(() => {
    if (!settings) return [];
    if (!settings.reportingLabels) return [];
    return settings.reportingLabels;
  }, [settings]);

  const posUsers = useMemo(() => {
    if (user == null || settings == null) return [];
    return users.filter((u) => {
      const config = u.sites.find(({ site }) => site === settings.site);
      return config != null && config.POSUser === true;
    });
  }, [user, users, settings]);

  useEffect(() => {
    dispatch(getUsers());
    if (user != null) {
      dispatch(getSettings(user.site));
    }
  }, [user]);

  const handleOptionsChange = useCallback((newOption: Options) => {
    setIsSearched(false);
    setOptions(newOption);
    setIsSearched(false);
  }, []);

  const handleSearch = useCallback(() => {
    setIsSearched(true);
  }, []);

  const handleClear = useCallback(() => {
    setOptions(getDefaultOptions(userLabels, null));
    setIsSearched(false);
  }, [getDefaultOptions]);

  const handleTabChange = useCallback((event: React.ChangeEvent<{}>, newActiveTab: string) => {
    setActiveTab(newActiveTab);
  }, []);

  const renderTabContent = useCallback((content: ReactNode) => {
    if (!isSearched) return <Typography align="center">Please enter a filter and click search</Typography>;
    return content;
  }, [isSearched]);

  const tabs = useMemo(() => {
    const tabsAvailable = [
      <Tab className={classes.tab} label="Sales" value="1" />,
      <Tab className={classes.tab} label="Graphs" value="2" />,
      <Tab className={classes.tab} label="Products" value="3" />,
      <Tab className={classes.tab} label="Transactions" value="4" />,
      <Tab className={classes.tab} label="Payouts" value="5" />,
      <Tab className={classes.tab} label="Activity" value="6" />,
      <Tab className={classes.tab} label="POS Server" value="7" />,
      <Tab className={classes.tab} label="Commission" value="8" />,
      <Tab className={classes.tab} label="Reconciliation" value="9" />,
      <Tab className={classes.tab} label="Discounts" value="10" />,
    ];
    return tabsAvailable;
  }, [settings]);

  if (isSettingsLoading) return <Spinner />;

  return (
    <Grid>
      <SalesReportFilter
        options={options}
        onOptionsChange={handleOptionsChange}
        onClear={handleClear}
        onSearch={handleSearch}
        userLabels={userLabels}
        siteLabels={siteLabels}
        posUsers={posUsers}
        showPosServers={activeTab === '7'}
      />
      <Box>
        <TabContext value={activeTab}>
          <Box>
            <TabList
              className={classes.tabList}
              indicatorColor="primary"
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabs}
            </TabList>
          </Box>
          <TabPanel value="1">
            {renderTabContent(
              <SalesReportSection
                user={user}
                siteData={settings}
                options={options}
              />,
            )}
          </TabPanel>
          <TabPanel value="2">
            {renderTabContent(
              <SalesGraphs
                site={settings}
                user={user}
                options={options}
              />,
            )}
          </TabPanel>
          <TabPanel value="3">
            {renderTabContent(
              <ProductsReportSection
                user={user}
                siteData={settings}
                options={options}
              />,
            )}
          </TabPanel>
          <TabPanel value="4">
            {renderTabContent(
              <TransactionsReportSection
                user={user}
                siteData={settings}
                options={options}
              />,
            )}
          </TabPanel>
          <TabPanel value="5">
            {renderTabContent(
              <PayoutsReportSection
                user={user}
                siteData={settings}
                options={options}
              />,
            )}
          </TabPanel>
          <TabPanel value="6">
            {renderTabContent(
              <ActivityReportSection
                user={user}
                siteData={settings}
                options={options}
              />,
            )}
          </TabPanel>
          <TabPanel value="7">
            {renderTabContent(
              <PosServerReport
                options={options}
                siteData={settings}
              />,
            )}
          </TabPanel>
          <TabPanel value="8">
            {renderTabContent(
              <CommissionReport
                options={options}
                site={settings?.site || null}
                siteVatNo={settings?.vatNumber}
                companyName={settings?.companyName}
              />,
            )}
          </TabPanel>
          <TabPanel value="9">
            {renderTabContent(
              <ReconciliationReport
                site={settings?.site || null}
                options={options}
                siteLabels={settings?.reportingLabels || []}
                siteName={settings?.name || null}
              />,
            )}
          </TabPanel>
          <TabPanel value="10">
            {renderTabContent(
              <DiscountsReport
                site={settings?.site || null}
                options={options}
              />,
            )}
          </TabPanel>
        </TabContext>
      </Box>
    </Grid>
  );
};

export default ReportsPage;
