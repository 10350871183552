import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import {
  Typography, Grid, makeStyles, Drawer, Button,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import Spinner from '../../components/spinner/spinner';
import ProductDetailsSection from './components/ProductDetailsSection';
import ProductSettingsSection from './components/ProductSettingsSection';
import ProductAddOnsSection from './components/ProductAddOnsSection';
import ProductLabelsSection from './components/ProductLabelsSection';

import hasPermission from '../../helpers/has-permission';
import canUserEdit from '../../helpers/can-user-view-product';

import getProducts from '../../actions/get-products';
import getSites from '../../actions/get-sites';

import AppUrl from '../../types/AppUrl';
import ReduxState from '../../types/ReduxState';
import ProductData from '../../types/ProductData';
import SiteData from '../../types/SiteData';
import User from '../../types/User';
import Dispatch from '../../types/Dispatch';
import Nullable from '../../types/Nullable';
import RoleRestrictedAction from '../../types/RoleRestrictedAction';

interface Params {
  productId: string;
}

const useStyles = makeStyles((theme) => ({
  productData: {
    paddingBottom: theme.spacing(5),
  },
  buttons: { padding: 10, paddingRight: 20 },
  cancelButton: { marginRight: 10 },
}));

const ViewProductPage = () => {
  const user = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));
  const userSiteConfig = useMemo(
    () => user?.sites.find((config) => config.site === user.site),
    [user],
  );
  const sites = useSelector<ReduxState, SiteData[]>((state) => state.sites.get('SITES'));
  const isProductsLoading = useSelector<ReduxState, boolean>((state) => state.products.get('IS_PRODUCTS_LOADING'));
  const isSitesLoading = useSelector<ReduxState, boolean>((state) => state.sites.get('IS_SITES_LOADING'));
  const products = useSelector<ReduxState, ProductData[]>((state) => state.products.get('PRODS'));
  const classes = useStyles();
  const dispatch: Dispatch = useDispatch();
  const params = useParams<Params>();

  useEffect(() => {
    if (user !== null) {
      dispatch(getProducts(user.site));
      dispatch(getSites());
    }
  }, [user]);

  const site = useMemo(() => (
    sites.find((siteData) => siteData.site === user?.site)
  ), [user, sites]);

  const reportingLabels = useMemo(() => {
    if (site && site.reportingLabels) return site.reportingLabels;
    return [];
  }, [site]);

  const userLabels = useMemo(() => {
    if (site && site.userLabels) return site.userLabels;
    return [];
  }, [site]);

  const product = useMemo(() => products
    .find(({ plu }) => plu === Number(params.productId)), [products, params]);

  const productOptions = useMemo(() => {
    if (product == null || product.options == null) return [];
    const { options } = product;
    return options
      .map((option) => {
        const { choices } = option;
        const validChoices = [...choices].filter(
          (choice) => products.some(({ plu }) => plu === choice.plu),
        );
        return { ...option, choices: validChoices };
      })
      .filter((option) => option.choices.length > 0);
  }, [product, products]);

  const userCanEdit = useMemo(() => {
    if (!product || !user) return false;
    return (
      user
      && userSiteConfig
      && canUserEdit(product, userSiteConfig)
      && hasPermission(RoleRestrictedAction.EditProduct, user)
    );
  }, [user, product]);

  if (isProductsLoading || isSitesLoading) return <Spinner />;

  if (!product) {
    return (
      <Grid>
        <Typography display="inline" variant="h6" color="inherit" gutterBottom>
          Product does not exist
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid>
      <Grid className={classes.productData} container spacing={3}>
        <ProductDetailsSection product={product} siteData={site} />
        <ProductSettingsSection product={product} />
        <ProductAddOnsSection options={productOptions} />
        <ProductLabelsSection
          product={product}
          reportingLabels={reportingLabels}
          userLabels={userLabels}
        />
      </Grid>
      <Drawer anchor="bottom" variant="permanent">
        <Grid
          className={classes.buttons}
          container
          direction="row"
          justify="flex-end"
        >
          <Button
            className={classes.cancelButton}
            component={Link}
            to={AppUrl.Products}
            variant="outlined"
            color="primary"
          >
            Back
          </Button>
          <Button
            component={Link}
            to={AppUrl.EditProduct.replace(':productId', params.productId)}
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            disabled={!userCanEdit}
          >
            Edit Product
          </Button>
        </Grid>
      </Drawer>
    </Grid>
  );
};

export default ViewProductPage;
