import * as Joi from 'joi';

import CollectionTime from '../../../types/CollectionTime';

const isCollectionTime = (value: unknown): value is CollectionTime => {
  const schema = Joi.object({
    firstCollection: Joi.string().required(),
    lastCollection: Joi.string().required(),
    leadTime: Joi.number().required(),
    day: Joi.number().required(),
  }).required();
  const result = schema.validate(value, { allowUnknown: true });

  return result.error == null;
};

export default isCollectionTime;
