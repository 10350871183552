import React from 'react';

import moment from 'moment';

import {
  Box, Divider, Grid, Paper, Typography, makeStyles,
} from '@material-ui/core';

import InfoField from '../../../components/info-field/info-field';

import SiteData from '../../../types/SiteData';

const useStyles = makeStyles(() => ({
  paper: { height: '100%' },
  sectionTitle: {
    marginTop: 20,
    marginLeft: 20,
    marginBottom: 5,
  },
  mainSection: {
    margin: 20,
  },
}));

type Props = {
  settings: SiteData
};

const OpeningTimesSection = ({ settings }: Props) => {
  const classes = useStyles();

  const { openingTimes } = settings;

  if (openingTimes == null) return null;

  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} variant="outlined">
        <Typography className={classes.sectionTitle} variant="h6">Opening Times</Typography>
        <Divider />
        <Box className={classes.mainSection}>
          {openingTimes.map((time) => (
            <InfoField
              key={time.day}
              title={moment().isoWeekday(time.day + 1).format('dddd')}
              info={`${time.closed ? 'Closed' : `${time.open} to ${time.close}`}`}
            />
          ))}
        </Box>
      </Paper>
    </Grid>
  );
};

export default OpeningTimesSection;
