import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, makeStyles,
} from '@material-ui/core';

import OutlinedTextField from '../../../components/outlined-text-field/outlined-text-field';
import OutlinedButton from '../../../components/outlined-button/outlined-button';
import ImageUpload from '../../../components/image-upload/image-upload';

import changeSiteMap from '../../../helpers/change-site-map';
import uploadImage from '../../../helpers/upload-image';
import getImageUrl from '../../../helpers/get-image-url';
import hasPermission from '../../../helpers/has-permission';

import STORE_KEYS from '../../../constants/store-keys';
import RoleRestrictedAction from '../../../types/RoleRestrictedAction';

const {
  AUTHENTICATION: { USER },
  SITE_MAP: { SITE_MAP_ID, SITE_MAP },
} = STORE_KEYS;

const useStyles = makeStyles({
  largeImageGrid: {
    padding: 10,
    width: '100%',
    marginBottom: 20,
  },
  largeImage: {
    aspectRatio: '30 / 7',
    backgroundColor: 'black',
    width: '100%',
    maxWidth: '600px',
    height: 'auto',
    maxHeight: '140px',
    objectFit: 'contain',
    objectPosition: '50% 50%',
  },
});

const CategoryDialog = ({
  open, onSave, categoryToEdit, handleClose,
}) => {
  const user = useSelector((state) => state.authentication.get(USER));
  const siteMapId = useSelector((state) => state.siteMap.get(SITE_MAP_ID));
  const siteMap = useSelector((state) => state.siteMap.get(SITE_MAP));
  const [category, setCategory] = useState('');
  const [catImage, setCatImage] = useState('');
  const [sequence, setSequence] = useState(0);
  const [count, setCount] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const classes = useStyles();

  const isCategoryNameDuplicate = useMemo(() => {
    const orginalCatName = categoryToEdit ? categoryToEdit.category : '';
    return siteMap.map((map) => map.category).includes(category) && orginalCatName !== category;
  }, [category, categoryToEdit, siteMap]);

  useEffect(() => {
    if (categoryToEdit && count < 1) {
      setCategory(categoryToEdit.category);
      setCatImage(categoryToEdit.image || '');
      setSequence(categoryToEdit.sequence);
      setCount(1);
    } else {
      const highestSequence = siteMap.reduce((output, currentCat) => (
        currentCat.sequence > output ? currentCat.sequence : output
      ), 0);
      setSequence(() => (
        siteMap.length === 0 ? 0 : highestSequence + 1
      ));
    }
  }, [categoryToEdit]);

  const onClose = () => {
    setCategory('');
    setSequence(0);
    setCatImage('');
    setSelectedFile('');
    setCount(0);
    setErrorMessage(null);
    handleClose();
  };

  const handleImageChange = (file, preview) => {
    setSelectedFile(file);
    setCatImage(preview);
  };

  const handleImageRemove = () => {
    setSelectedFile('');
    setCatImage('');
  };

  const handleSave = async () => {
    let image = catImage || '';
    let newSiteMap = siteMap;

    if (selectedFile) {
      image = await uploadImage(user.site, selectedFile)
        .then(({ ref }) => getImageUrl(ref.fullPath));
    }

    if (categoryToEdit) {
      newSiteMap = siteMap.map((map) => {
        if (map.category === categoryToEdit.category) {
          return {
            ...map, category, image, sequence,
          };
        }
        return map;
      });
    } else {
      newSiteMap = siteMap.concat({
        category,
        image,
        sequence,
        subCategories: [],
      });
    }
    try {
      await changeSiteMap(siteMapId, { site: user.site, products: newSiteMap });
    } catch (error) {
      setErrorMessage(error.message);
    }
    setSelectedFile(null);
    setErrorMessage(null);
    onClose();
    onSave();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{categoryToEdit ? 'Edit Category' : 'Add Category'}</DialogTitle>
      <DialogContent>
        <OutlinedTextField
          id="category"
          classOption="flex"
          label="Category Name"
          value={category}
          onChange={(event) => setCategory(event.target.value)}
          helperText={isCategoryNameDuplicate ? 'Category name has already been used, please try another' : null}
          error={isCategoryNameDuplicate}
          required
        />
        <ImageUpload
          buttonLabel="Upload Category Image"
          onChange={handleImageChange}
          onRemove={handleImageRemove}
          currentImage={catImage}
          description="The best resolution is 600 x 140."
          large
          previewGridStyles={classes.largeImageGrid}
          previewStyles={classes.largeImage}
        />
        <OutlinedTextField
          id="outlined-sequence"
          classOption="flex"
          type="number"
          label="Sequence"
          value={sequence}
          onChange={(event) => setSequence(Number(event.target.value))}
          required
          disabled={
            !hasPermission(RoleRestrictedAction.EditCategorySequence, user)
          }
        />
      </DialogContent>
      <DialogActions>
        <OutlinedButton
          label="Cancel"
          color="secondary"
          onClick={onClose}
        />
        <OutlinedButton label="Save" color="primary" disabled={isCategoryNameDuplicate || category.length === 0} onClick={handleSave} />
        <Grid>
          {errorMessage ? <Typography variant="body1" color="error">{errorMessage}</Typography> : null}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

CategoryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  categoryToEdit: PropTypes.shape({
    category: PropTypes.string,
    image: PropTypes.string,
    sequence: PropTypes.number,
  }),
  handleClose: PropTypes.func.isRequired,
};

CategoryDialog.defaultProps = {
  categoryToEdit: null,
};

export default CategoryDialog;
