import React, {
  ChangeEvent, useCallback, useMemo, useState,
} from 'react';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';

import BackdropSpinner from '../backdrop-spinner/backdrop-spinner';

import isEmailValid from '../../helpers/isEmailValid';
import apiRequest from '../../helpers/api-request';

import Nullable from '../../types/Nullable';

type Props = {
  orderId: string
  open: boolean
  onSave: () => void
  onClose: () => void
};

const useStyles = makeStyles(() => ({
  item: {
    marginBottom: 20,
  },
}));

const ReceiptDialog = ({
  orderId, open, onSave, onClose,
}: Props) => {
  const [email, setEmail] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const classes = useStyles();

  const handleEmailChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, []);

  const handleClose = useCallback(() => {
    setEmail('');
    onClose();
  }, [onClose]);

  const handleSendClick = useCallback(async () => {
    setErrorMessage(null);
    setIsLoading(true);
    try {
      await apiRequest('user-receipt', 'POST', { orderId, userEmail: email });
      setIsLoading(false);
      onSave();
    } catch (error) {
      setErrorMessage('Sorry there was an error sending receipt, please try again.');
      setIsLoading(false);
      throw (error);
    }
  }, [orderId, apiRequest, email, onSave]);

  const isFormValid = useMemo(() => isEmailValid(email), [email]);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="receipt-dialog" fullWidth>
      <BackdropSpinner open={isLoading} />
      <DialogTitle id="receipt-dialog">Send Receipt</DialogTitle>
      <DialogContent dividers>
        <DialogContentText className={classes.item}>
          Email address provided will be used for no other purposes
          than to send a receipt of this order.
        </DialogContentText>
        <TextField
          className={classes.item}
          label="Email Address"
          type="email"
          variant="outlined"
          onChange={handleEmailChange}
          required
          autoFocus
          fullWidth
        />
        {errorMessage && (
          <Typography className={classes.item} variant="body1" color="error" gutterBottom>{errorMessage}</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSendClick} color="primary" disabled={!isFormValid}>
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReceiptDialog;
