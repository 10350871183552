import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Grid } from '@material-ui/core';

import Spinner from '../../components/spinner/spinner';
import DiscountForm from '../../components/DiscountForm/DiscountForm';

import createDiscount from '../../helpers/createDiscount';

import AppUrl from '../../types/AppUrl';
import EditedDiscount from '../../types/EditedDiscount';
import ReduxState from '../../types/ReduxState';
import SiteData from '../../types/SiteData';
import User from '../../types/User';
import Nullable from '../../types/Nullable';
import getSettings from '../../actions/get-settings';

const AddDiscountPage = () => {
  const user = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));
  const isSettingsLoading = useSelector<ReduxState, boolean>((state) => state.settings
    .get('IS_SETTINGS_LOADING'));
  const settings = useSelector<ReduxState, Nullable<SiteData>>((state) => state.settings.get('SETTINGS'));
  const [error, setError] = useState<Nullable<Error>>(null);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (settings == null && user != null) dispatch(getSettings(user.site));
  }, [settings, user]);

  const onSave = useCallback((discountDetails: EditedDiscount) => {
    if (user != null) {
      createDiscount(discountDetails, user.site)
        .then(() => history.push(AppUrl.Discounts))
        .catch(() => setError(new Error(`Could not save discount ${discountDetails.name}`)));
    }
  }, [user]);

  const userLabels = useMemo(() => {
    if (!user) return [];
    if (!settings) return [];
    if (!settings.reportingLabels) return [];
    const userSiteConfig = user.sites.find((siteConfig) => siteConfig.site === user.site);
    const labels = userSiteConfig?.reportingLabels || [];
    return settings.reportingLabels
      .filter(({ id }) => labels.includes(id))
      .filter((label) => (label.active != null ? label.active : true));
  }, [settings, user]);

  const siteLabels = useMemo(() => {
    if (!settings) return [];
    if (!settings.reportingLabels) return [];
    return settings.reportingLabels
      .filter((label) => (label.active != null ? label.active : true));
  }, [settings]);

  if (isSettingsLoading) return <Spinner />;

  return (
    <Grid>
      <DiscountForm
        userLabels={userLabels}
        siteLabels={siteLabels}
        onSave={onSave}
      />
      {error?.message}
    </Grid>
  );
};

export default AddDiscountPage;
