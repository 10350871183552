import firebase from 'firebase/app';

import { LOGOUT } from '../constants/actions';
import AppUrl from '../types/AppUrl';

export default (history) => (dispatch) => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      dispatch({
        type: LOGOUT,
      });
      if (history != null) history.push(AppUrl.Home);
    })
    .catch(() => {
      // TODO handle logout error
    });
};
