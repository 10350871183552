import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
} from '@material-ui/core';

interface Props {
  open: boolean;
  onOkButtonClick: () => void;
  message?: string;
}

const ErrorDialog = ({ open, onOkButtonClick, message }: Props) => (
  <Dialog
    open={open}
  >
    <DialogTitle>Something went wrong</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Please try again and contact support if this problem persists.
      </DialogContentText>
      {message && (
        <DialogContentText>
          {`Error Message: ${message}`}
        </DialogContentText>
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={onOkButtonClick} color="primary">OK</Button>
    </DialogActions>
  </Dialog>
);

export default ErrorDialog;
