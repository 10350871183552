import React, { ChangeEvent } from 'react';

import {
  Checkbox,
  Grid,
  makeStyles,
  Typography,
  Box,
  Select,
  MenuItem,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
} from '@material-ui/core';

import SimpleSelect from '../../SimpleSelect/SimpleSelect';

import statusOptions from '../constants/statusOptions';

import isStatus from '../../../helpers/isStatus';

import SiteData from '../../../types/SiteData';
import EditableSiteData from '../../../types/EditableSiteData';
import Status from '../../../types/Status';
import CollectTableNumber from '../../../types/CollectTableNumber';

type Props = {
  siteData: EditableSiteData
  onChange: (newSiteData: EditableSiteData) => void
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  checkbox: { marginRight: 80 },
  sposOptionsContainer: { marginTop: theme.spacing(2) },
  statusOnSposPrintSelect: { minWidth: 300, margin: theme.spacing(1, 1, 1, 0) },
  formCard: {
    marginBottom: theme.spacing(3),
  },
  cardTitle: {
    marginBottom: theme.spacing(1),
  },
}));

const SiteOrderFlow = ({ siteData, onChange }: Props) => {
  const classes = useStyles();

  const handleStatusBoardChange = (status: Status, value: boolean) => {
    if (siteData.orderFlow == null) {
      onChange({
        ...siteData,
        orderFlow: { statusBoard: [status], notifyUserText: [], notifyUserEmail: [] },
      });
    } else {
      const statusBoard = siteData.orderFlow.statusBoard || [];
      const newStatusBoard = value
        ? statusBoard.concat([status])
        : statusBoard.filter((board) => board !== status);

      const newSiteData = {
        ...siteData,
        orderFlow: {
          ...siteData.orderFlow,
          statusBoard: statusOptions.map((o) => o.id).filter((o) => newStatusBoard.includes(o)),
        },
      };

      onChange(newSiteData);
    }
  };

  const handleNotifyUserTextChange = (status: Status, value: boolean) => {
    if (siteData.orderFlow == null) {
      onChange({
        ...siteData,
        orderFlow: { statusBoard: [], notifyUserText: [status], notifyUserEmail: [] },
      });
    } else {
      const notifyUser = siteData.orderFlow.notifyUserText || [];
      const newNotifyUser = value
        ? notifyUser.concat([status])
        : notifyUser.filter((board) => board !== status);

      const newSiteData = {
        ...siteData,
        orderFlow: {
          ...siteData.orderFlow,
          notifyUserText: statusOptions.map((o) => o.id).filter((o) => newNotifyUser.includes(o)),
        },
      };

      onChange(newSiteData);
    }
  };

  const handleNotifyUserEmailChange = (status: Status, value: boolean) => {
    if (siteData.orderFlow == null) {
      onChange({
        ...siteData,
        orderFlow: { statusBoard: [], notifyUserText: [], notifyUserEmail: [status] },
      });
    } else {
      const notifyUser = siteData.orderFlow.notifyUserEmail || [];
      const newNotifyUser = value
        ? notifyUser.concat([status])
        : notifyUser.filter((board) => board !== status);

      const newSiteData = {
        ...siteData,
        orderFlow: {
          ...siteData.orderFlow,
          notifyUserEmail: statusOptions.map((o) => o.id).filter((o) => newNotifyUser.includes(o)),
        },
      };

      onChange(newSiteData);
    }
  };

  const handleStatusOnSposPrintSelectChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown; }>,
  ) => {
    const { target: { value } } = event;
    if (!isStatus(value)) return;

    const defaultSposSettings: SiteData['spos'] = {
      consoleFeaturesEnabled: false,
      orderStatusOnPrint: Status.Complete,
    };
    const currentSposSettings = siteData.spos || defaultSposSettings;
    const updatedSposSettings = {
      ...currentSposSettings,
      orderStatusOnPrint: value,
    };

    onChange({
      ...siteData,
      spos: updatedSposSettings,
    });
  };

  const handlePropertyChange = <T extends keyof EditableSiteData>(
    property: T, value: EditableSiteData[T],
  ) => {
    const newSiteData = { ...siteData, [property]: value };
    onChange(newSiteData);
  };

  const handleInHouseCollectionLabelsChange = (
    labelId: string,
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { target: { checked } } = event;
    let updatedInHouseCollectionLabels: string[] = siteData.inHouseCollectionLabels || [];
    if (checked) {
      updatedInHouseCollectionLabels = [...updatedInHouseCollectionLabels, labelId];
    } else {
      updatedInHouseCollectionLabels = updatedInHouseCollectionLabels
        .filter((id) => id !== labelId);
    }
    const newSiteData = {
      ...siteData,
      inHouseCollectionLabels: updatedInHouseCollectionLabels,
    };
    onChange(newSiteData);
  };

  return (
    <Grid className={classes.root} container spacing={1}>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={12}>
          <SimpleSelect
            label="Collect Table Number"
            items={[
              { label: 'Never (all orders are for in-house collection)', value: CollectTableNumber.Never },
              { label: 'By selected label(s)', value: CollectTableNumber.ByLabel },
              { label: 'Always (all orders are for table service)', value: CollectTableNumber.Always },
            ]}
            value={siteData.collectTableNumber || CollectTableNumber.Always}
            onChange={(value) => handlePropertyChange('collectTableNumber', value)}
            labelWidth={160}
            allowEmpty={false}
          />
          {(
            siteData.collectTableNumber === CollectTableNumber.ByLabel
            && siteData.reportingLabels != null
            && siteData.reportingLabels.length > 0
          ) && (
            <Card className={classes.formCard}>
              <CardContent>
                <Typography className={classes.cardTitle} variant="subtitle1">
                  Select reporting Labels For In-House Collection (table number not required)
                </Typography>
                <FormControlLabel
                  key="noLabelProduct"
                  label="Products with no label"
                  control={(
                    <Switch
                      checked={(
                        siteData.inHouseCollectionLabels != null
                          ? siteData.inHouseCollectionLabels.includes('noLabelProduct')
                          : false
                      )}
                      onChange={(e) => handleInHouseCollectionLabelsChange('noLabelProduct', e)}
                    />
                  )}
                />
                {siteData.reportingLabels?.map((label) => (
                  <FormControlLabel
                    key={label.id}
                    label={label.text}
                    control={(
                      <Switch
                        checked={(
                          siteData.inHouseCollectionLabels != null
                            ? siteData.inHouseCollectionLabels.includes(label.id)
                            : false
                        )}
                        onChange={(e) => handleInHouseCollectionLabelsChange(label.id, e)}
                      />
                    )}
                  />
                ))}
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6" color="inherit">
            Status
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6" color="inherit">
            Status Board
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6" color="inherit">
            Notify User (Text)
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6" color="inherit">
            Notify User (Email)
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={3}>
          <Typography variant="subtitle1" color="inherit">
            Outstanding
          </Typography>
          <Typography variant="body2" color="textSecondary">Always shown on Dispense Screen</Typography>
        </Grid>
        <Grid item xs={3}>
          <Checkbox
            checked
            value="primary"
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <Checkbox
            checked={false}
            value="primary"
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <Checkbox
            checked={false}
            value="primary"
            disabled
          />
        </Grid>
      </Grid>
      {statusOptions.map((option) => (
        <Grid key={option.id} container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <Typography variant="subtitle1" color="inherit">
              {option.title}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Checkbox
              checked={(siteData.orderFlow?.statusBoard || []).includes(option.id)}
              value="primary"
              onChange={(event) => handleStatusBoardChange(option.id, event.target.checked)}
            />
          </Grid>
          <Grid item xs={3}>
            <Checkbox
              checked={(siteData.orderFlow?.notifyUserText || []).includes(option.id)}
              value="primary"
              onChange={(event) => handleNotifyUserTextChange(option.id, event.target.checked)}
            />
          </Grid>
          <Grid item xs={3}>
            <Checkbox
              checked={(siteData.orderFlow?.notifyUserEmail || []).includes(option.id)}
              value="primary"
              onChange={(event) => handleNotifyUserEmailChange(option.id, event.target.checked)}
            />
          </Grid>
        </Grid>
      ))}
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={3}>
          <Typography variant="subtitle1" color="inherit">
            Complete
          </Typography>
          <Typography variant="body2" color="textSecondary">Always shown on Dispense Screen</Typography>
        </Grid>
        <Grid item xs={3}>
          <Checkbox
            checked
            value="primary"
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <Checkbox
            checked={false}
            value="primary"
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <Checkbox
            checked={false}
            value="primary"
            disabled
          />
        </Grid>
      </Grid>
      {siteData.spos?.consoleFeaturesEnabled && (
        <Box className={classes.sposOptionsContainer}>
          <Typography variant="h6">SPOS Integration</Typography>
          <Typography>
            Choose the status an order should be assigned following confirmation it has been sent to
            print on SPOS.
          </Typography>
          <Select
            className={classes.statusOnSposPrintSelect}
            variant="outlined"
            value={siteData.spos?.orderStatusOnPrint || Status.Complete}
            onChange={handleStatusOnSposPrintSelectChange}
          >
            {statusOptions
              .filter((option) => siteData.orderFlow?.statusBoard?.includes(option.id))
              .map((status) => <MenuItem value={status.id}>{status.title}</MenuItem>)}
            <MenuItem value={Status.Complete}>Complete</MenuItem>
          </Select>
        </Box>
      )}
    </Grid>
  );
};

export default SiteOrderFlow;
