import firebase from 'firebase/app';

export default (id, siteMap) => {
  if (!id || id.length === 0) {
    return firebase
      .firestore()
      .collection('sitemap')
      .add(siteMap);
  }
  return firebase
    .firestore()
    .collection('sitemap')
    .doc(id)
    .set(siteMap);
};
