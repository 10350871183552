import request from '../../../helpers/request';

import SiteData from '../../../types/SiteData';

const setupStripe = (settings: SiteData, country: string | null) => {
  const { site, stripeConnect } = settings;
  if (!stripeConnect || stripeConnect.length === 0) {
    return request(
      'createStripeAccount',
      'POST',
      {
        site,
        country,
      },
    )
      .then(() => request(
        'linkStripeAccount',
        'POST',
        {
          site,
        },
      ));
  }
  return request(
    'linkStripeAccount',
    'POST',
    {
      site,
    },
  );
};

export default setupStripe;
