import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Grid,
  Icon,
  Typography,
  fade,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  actionCard: { height: 152 },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 18,
  },
  chipComponent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flexEnd',
    alignItems: 'flex-start',
  },
  chip: {
    borderRadius: '4px',
    fontSize: '11px',
    height: '18px',
    width: '48px',
    letterSpacing: '0.4px',
  },
  icon: {
    background: fade(theme.palette.primary.main, 0.2),
    width: 50,
    height: 50,
    marginBottom: theme.spacing(1),
  },
}));

type Props = {
  route: any
};

const ActionCard = ({ route }: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = useCallback(() => {
    history.push(route.url);
  }, [history]);

  return (
    <Grid item xs={12} sm={4} md={2}>
      <Card>
        <CardActionArea className={classes.actionCard} onClick={handleClick}>
          <Grid style={{ textAlign: 'right', marginTop: 5, marginRight: 5 }}>
            {route.chip != null && (
              <Chip
                className={classes.chip}
                size="small"
                variant="outlined"
                label={route.chip}
                color="primary"
              />
            )}
          </Grid>
          <CardContent>
            <Grid
              className={classes.cardContent}
              style={{ paddingTop: route.chip != null ? 0 : 18 }}
            >
              <Avatar className={classes.icon}>
                {route.icon ? <Icon color="primary" fontSize="small"><route.icon /></Icon> : route.label.substring(0, 1)}
              </Avatar>
              <Typography variant="subtitle1">{route.label}</Typography>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default ActionCard;
