import { Map } from 'immutable';
import {
  AUTHORISATION_ERROR,
  AUTHORISATION_SUCCESS,
  LOGIN_START,
  VERIFICATION_START,
  LOGOUT,
  UPDATE_USER_SITE,
} from '../constants/actions';
import ReduxAction from '../types/ReduxAction';
import ReduxState from '../types/ReduxState';

const initialStateMap: ReduxState['authentication'] = Map()
  .set('IS_LOGGING_IN', false)
  .set('IS_VERIFYING_USER', false)
  .set('AUTHORISATION_ERROR_MESSAGE', '')
  .set('USER', null)
  .set('IS_AUTHORISED', false);

export default (
  state: ReduxState['authentication'] = initialStateMap,
  action: ReduxAction,
) => {
  switch (action.type) {
    case LOGIN_START:
      return state
        .set('IS_LOGGING_IN', true)
        .set('AUTHORISATION_ERROR_MESSAGE', '');
    case VERIFICATION_START:
      return state
        .set('IS_LOGGING_IN', false)
        .set('IS_VERIFYING_USER', true)
        .set('AUTHORISATION_ERROR_MESSAGE', '');
    case AUTHORISATION_SUCCESS:
      return state
        .set('IS_AUTHORISED', true)
        .set('USER', action.user)
        .set('IS_VERIFYING_USER', false);
    case UPDATE_USER_SITE:
      return state
        .set('IS_AUTHORISED', true)
        .set('USER', action.user)
        .set('IS_VERIFYING_USER', false);
    case AUTHORISATION_ERROR:
      return state
        .set('IS_LOGGING_IN', false)
        .set('IS_VERIFYING_USER', false)
        .set('AUTHORISATION_ERROR_MESSAGE', action.message);
    case LOGOUT:
      return initialStateMap;
    default:
      return state;
  }
};
