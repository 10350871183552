import React from 'react';

import { TextField, IconButton, makeStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

type Props = {
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  clearSearch: () => void
};

const useStyles = makeStyles((theme) => ({
  textField: {
    marginRight: 10,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    margin: theme.spacing(1, 0.5, 1.5),
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(0.5),
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
}));

const SearchToolbar = ({ value, onChange, clearSearch }: Props) => {
  const classes = useStyles();

  return (
    <TextField
      variant="standard"
      value={value}
      onChange={onChange}
      placeholder="Search Discount Codes"
      className={classes.textField}
      InputProps={{
        startAdornment: <SearchIcon fontSize="small" />,
        endAdornment: (
          <IconButton
            title="Clear"
            aria-label="Clear"
            size="small"
            style={{ visibility: value ? 'visible' : 'hidden' }}
            onClick={clearSearch}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        ),
      }}
    />
  );
};

export default SearchToolbar;
