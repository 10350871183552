import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import getSettings from '../../actions/get-settings';

import OrdersTable from '../../components/orders-table/orders-table';
import Spinner from '../../components/spinner/spinner';

import ReduxState from '../../types/ReduxState';
import User from '../../types/User';
import Nullable from '../../types/Nullable';
import SiteData from '../../types/SiteData';

const RefundedOrdersPage = () => {
  const dispatch = useDispatch();
  const user = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));
  const settings = useSelector<ReduxState, Nullable<SiteData>>((state) => state.settings.get('SETTINGS'));

  useEffect(() => {
    if (user != null) {
      dispatch(getSettings(user.site));
    }
  }, [user]);

  const userSiteConfig = useMemo(() => user?.sites.find((site) => site.site === user.site), [user]);

  const site = useMemo(() => (user != null ? user.site : ''), [user]);

  if (!settings) return <Spinner />;

  return (
    <OrdersTable
      site={site}
      refundedOrders
      siteData={settings}
      reportingLabels={userSiteConfig?.reportingLabels || []}
    />
  );
};

export default RefundedOrdersPage;
