import firebase from 'firebase/app';

const deleteImage = (imageUrl: string) => {
  const imageLocation = imageUrl
    .split('com/o/')[1]
    .split('?alt')[0]
    .replace(/%2F/g, '/')
    .replace(/%20/g, ' ')
    .replace(/%26/g, '&');

  const storageRef = firebase.storage().ref();

  return storageRef
    .child(imageLocation)
    .delete();
};

export default deleteImage;
