import React from 'react';

import { Box, makeStyles } from '@material-ui/core';

import FormSwitch from '../../../components/FormSwitch/FormSwitch';
import FadeInSpinner from './FadeInSpinner';

interface Props {
  label: string;
  active: boolean;
  loading: boolean;
  disabled: boolean;
  onChange: (value: boolean) => Promise<void>;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  switch: {
    marginRight: '1em',
  },
});

const LoadingSwitch = ({
  label,
  active,
  loading,
  disabled,
  onChange,
}: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <FormSwitch
        label={label}
        value={active}
        disabled={disabled}
        onChange={onChange}
        className={classes.switch}
      />
      <FadeInSpinner loading={loading} />
    </Box>
  );
};

export default LoadingSwitch;
