import React from 'react';

import {
  List, ListItem, ListItemText, makeStyles,
} from '@material-ui/core';

import SiteMapProduct from '../../../types/SiteMapProduct';
import ProductData from '../../../types/ProductData';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxWidth: 360,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 600,
  },
}));

type Props = {
  items: SiteMapProduct[],
  products: ProductData[],
};

const SubCategoryProductsList = ({ items, products }: Props) => {
  const classes = useStyles();
  return (
    <List className={classes.root}>
      {items.sort((a, b) => a.sequence - b.sequence).map((item) => {
        const productInfo = products.find((prod) => prod.plu === item.plu);

        if (productInfo == null) return null;

        return (
          <ListItem key={item.plu}>
            <ListItemText primary={`${productInfo?.name || ''} (${item.plu})`} secondary={`Sequence: ${item.sequence}`} />
          </ListItem>
        );
      })}
    </List>
  );
};

export default SubCategoryProductsList;
