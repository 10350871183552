import React from 'react';
import { Chip } from '@material-ui/core';

import never from '../../helpers/never';
import ImportJobStatus from '../../types/ImportJobStatus';

interface Props {
  status: ImportJobStatus;
}

const ImportJobStatusChip = ({ status }: Props) => {
  switch (status) {
    case ImportJobStatus.Started:
      return <Chip variant="outlined" color="default" label="Started" />;
    case ImportJobStatus.Completed:
      return <Chip variant="outlined" color="primary" label="Completed" />;
    case ImportJobStatus.Failed:
      return <Chip variant="outlined" color="secondary" label="Failed" />;
    default:
      never(status);
      return <></>;
  }
};

export default ImportJobStatusChip;
