import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@material-ui/core';

import getSettings from '../../actions/get-settings';

import OrdersTable from '../../components/orders-table/orders-table';
import Spinner from '../../components/spinner/spinner';

import processOrder from '../../helpers/process-order';

import ReduxState from '../../types/ReduxState';
import User from '../../types/User';
import Nullable from '../../types/Nullable';
import SiteData from '../../types/SiteData';

const OrdersPage = () => {
  const dispatch = useDispatch();
  const user = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));
  const settings = useSelector<ReduxState, Nullable<SiteData>>((state) => state.settings.get('SETTINGS'));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user != null) {
      dispatch(getSettings(user.site));
    }
  }, [user]);

  const userSiteConfig = useMemo(() => user?.sites.find((site) => site.site === user.site), [user]);

  const onProcessOrder = (orderNumber: string) => {
    if (user != null) {
      setLoading(true);
      processOrder(orderNumber, user.name)
        .then(() => {
          setLoading(false);
        });
    }
  };

  if (loading || !settings) return <Spinner />;

  return (
    <Grid>
      <OrdersTable
        processOrder={onProcessOrder}
        siteData={settings}
        reportingLabels={userSiteConfig?.reportingLabels || []}
      />
    </Grid>
  );
};

export default OrdersPage;
