import React,
{
  useState,
  useMemo,
  useCallback,
} from 'react';
import firebase from 'firebase/app';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Avatar,
  Button,
  Typography,
  Grid,
  IconButton,
  Tooltip,
  Fab,
  makeStyles,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import InfoField from '../../components/info-field/info-field';
import ListField from '../../components/list-field/list-field';

import changePassword from './helpers/change-password';
import logout from '../../actions/logout';

import ReduxState from '../../types/ReduxState';
import User from '../../types/User';
import Nullable from '../../types/Nullable';
import ResetPasswordDialog from '../../components/ResetPasswordDialog/ResetPasswordDialog';

const useStyles = makeStyles((theme) => ({
  absolute: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
  profileSection: {
    marginBottom: 10,
  },
  avatar: {
    height: 150,
    width: 150,
  },
  profileInfo: {
    marginLeft: 10,
  },
}));

const ProfilePage = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const user = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));

  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState<boolean>(false);

  const userSiteConfig = useMemo(
    () => user?.sites.find((config) => config.site === user.site),
    [user],
  );

  const userSites = useMemo(() => user?.sites.map((config) => config.site), [user]);

  const handleChangePasswordClick = useCallback(() => {
    setChangePasswordDialogOpen(true);
  }, []);

  const onPasswordDialogCancel = useCallback(() => {
    setChangePasswordDialogOpen(false);
  }, []);

  const onPasswordDialogSave = useCallback(async (
    newPassword: string,
    currentPassword: string,
  ) => {
    if (user == null) throw new Error('Missing user data');
    const credential = firebase
      .auth
      .EmailAuthProvider
      .credential(
        user.email,
        currentPassword,
      );
    const { currentUser } = firebase
      .auth();
    if (currentUser == null) throw new Error('No user logged in');
    await currentUser.reauthenticateWithCredential(credential);
    await changePassword(newPassword);
    setChangePasswordDialogOpen(false);
  }, [user]);

  if (!user || !userSiteConfig) return null;

  return (
    <Grid>
      <Grid className={classes.profileSection} container direction="row">
        <Avatar alt="Remy Sharp" className={classes.avatar} variant="rounded" />
        <Grid className={classes.profileInfo}>
          <Typography variant="h4" gutterBottom>{`${user.name} (${userSiteConfig.role})`}</Typography>
          <Typography variant="body1" gutterBottom>{user.site}</Typography>
          <Typography variant="body1" gutterBottom>{user.email}</Typography>
        </Grid>
      </Grid>
      <Grid className={classes.profileSection}>
        {user.sites && user.sites.length !== 0 ? (
          <ListField
            title="Available Sites"
            list={userSites && userSites.length !== 0 ? userSites : []}
          />
        ) : (
          <InfoField
            title="Available Sites"
            info="All Sites"
          />
        )}
        {userSiteConfig.reportingLabels
          && userSiteConfig.reportingLabels.length > 0
          && (
            <ListField
              title="User Reporting Labels"
              list={userSiteConfig.reportingLabels}
            />
          )}
      </Grid>
      <Button
        color="secondary"
        variant="outlined"
        onClick={handleChangePasswordClick}
        size="large"
      >
        Change Password
      </Button>
      <IconButton onClick={() => dispatch(logout(history))}>
        <Tooltip title="Logout">
          <Fab color="secondary" className={classes.absolute}>
            <ExitToAppIcon />
          </Fab>
        </Tooltip>
      </IconButton>
      <ResetPasswordDialog
        open={changePasswordDialogOpen}
        onSave={onPasswordDialogSave}
        onCancel={onPasswordDialogCancel}
        requireCurrentPassword
      />
    </Grid>
  );
};

export default ProfilePage;
