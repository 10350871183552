import React, { useMemo, useState } from 'react';

import { Button, Grid, useTheme } from '@material-ui/core';
import Brightness1Icon from '@material-ui/icons/Brightness1';

import OutlinedTextField from '../../outlined-text-field/outlined-text-field';
import CheckBox from '../../check-box/check-box';
import SimpleSelect from '../../SimpleSelect/SimpleSelect';
import EditAddressDialog from './EditAddressDialog';

import validateEmail from '../../../helpers/validate-email';
import getColourCode from '../../../helpers/getColourCode';
import formatSiteAddress from '../../../helpers/formatSiteAddress';

import SiteData from '../../../types/SiteData';
import SiteAddress from '../../../types/SiteAddress';
import EditableSiteData from '../../../types/EditableSiteData';
import InteractionColourLabel from '../../../types/InteractionColourLabel';
import InteractionColourValue from '../../../types/InteractionColourValue';
import WelcomeModal from '../../../types/WelcomeModal';

type Props = {
  settings: EditableSiteData
  onChange: (newSiteData: EditableSiteData) => void
};

const SiteInformationForm = ({ settings, onChange }: Props) => {
  const theme = useTheme();
  const [addressDialogOpen, setAddressDialogOpen] = useState<boolean>(false);

  const {
    name,
    active,
    testMode,
    email,
    phoneNumber,
    sheetId,
    stripeConnect,
    orderCompleteText,
    siteThemeColour,
    interactionColour,
    address,
    vatRegistered,
    vatNumber,
    companyName,
    welcomeModal,
  } = settings;

  const blankAddress: SiteAddress = {
    line1: '',
    city: '',
    country: '',
    postCode: '',
  };

  const prefersDarkMode = useMemo(() => theme.palette.type === 'dark', [theme]);

  const handlePropertyChange = <T extends keyof SiteData>(
    property: T, value: SiteData[T],
  ) => {
    const newSiteData = { ...settings, [property]: value };
    onChange(newSiteData);
  };

  const handleWelcomeModalChange = <T extends keyof WelcomeModal>(
    property: T, value: WelcomeModal[T],
  ) => {
    const newSiteData = {
      ...settings,
      welcomeModal: {
        ...settings.welcomeModal,
        [property]: value,
      },
    };
    onChange(newSiteData);
  };

  const handleAddressClick = () => {
    setAddressDialogOpen(true);
  };

  const handleAddressCancelClick = () => {
    setAddressDialogOpen(false);
  };

  const handleAddressSubmitClick = (newAddress: SiteAddress) => {
    handlePropertyChange('address', newAddress);
    setAddressDialogOpen(false);
  };

  return (
    <Grid>
      <OutlinedTextField
        id="name"
        label="Site Name"
        classOption="flex"
        value={name}
        onChange={(event) => handlePropertyChange('name', event.target.value)}
      />
      <CheckBox label="Online" checked={active} onChange={(event) => handlePropertyChange('active', event.target.checked)} />
      <CheckBox label="Test Mode" checked={testMode || false} onChange={(event) => handlePropertyChange('testMode', event.target.checked)} />
      <OutlinedTextField
        id="email"
        label="Email"
        classOption="flex"
        value={email}
        onChange={(event) => handlePropertyChange('email', event.target.value)}
        error={validateEmail(email)}
        helperText={validateEmail(email) ? 'Invalid Email' : null}
      />
      <OutlinedTextField
        id="phone-number"
        label="Phone Number"
        classOption="flex"
        value={phoneNumber}
        onChange={(event) => handlePropertyChange('phoneNumber', event.target.value)}
      />
      <OutlinedTextField
        id="site-address"
        label="Site Address"
        classOption="flex"
        value={address != null ? formatSiteAddress(address) : ''}
        onClick={handleAddressClick}
      />
      <OutlinedTextField
        id="google-sheet-id"
        label="Google Sheet ID"
        classOption="flex"
        value={sheetId}
        onChange={(event) => handlePropertyChange('sheetId', event.target.value)}
      />
      <OutlinedTextField
        id="stripe-account"
        label="Stripe Account"
        classOption="flex"
        value={stripeConnect}
        onChange={(event) => handlePropertyChange('stripeConnect', event.target.value)}
      />
      <OutlinedTextField
        id="order-complete-text"
        label="Order Complete Text"
        classOption="flex"
        value={orderCompleteText}
        onChange={(event) => handlePropertyChange('orderCompleteText', event.target.value)}
      />
      <OutlinedTextField
        id="welcome-message"
        label="Welcome Message"
        classOption="flex"
        value={welcomeModal.welcomeMessage}
        onChange={(event) => handleWelcomeModalChange('welcomeMessage', event.target.value)}
      />
      <OutlinedTextField
        id="terms-conditions-link"
        label="Terms & Conditions link"
        classOption="flex"
        value={welcomeModal?.termsLink || ''}
        onChange={(event) => handleWelcomeModalChange('termsLink', event.target.value)}
      />
      <OutlinedTextField
        id="privacy-policy-link"
        label="Privacy Policy Link"
        classOption="flex"
        value={welcomeModal?.privacyPolicyLink || ''}
        onChange={(event) => handleWelcomeModalChange('privacyPolicyLink', event.target.value)}
      />
      <CheckBox
        label="Hide Opening Times On Welcome Pop-up"
        checked={welcomeModal?.hideOpeningTimes || false}
        onChange={(event) => handleWelcomeModalChange('hideOpeningTimes', event.target.checked)}
      />
      <CheckBox
        label="VAT Registered"
        checked={vatRegistered || false}
        onChange={(event) => handlePropertyChange('vatRegistered', event.target.checked)}
      />
      {vatRegistered && (
        <OutlinedTextField
          id="vat-number"
          label="VAT Registration Number"
          classOption="flex"
          value={vatNumber != null ? vatNumber : 'GB'}
          onChange={(event) => handlePropertyChange('vatNumber', event.target.value)}
        />
      )}
      <OutlinedTextField
        id="companyName"
        label="Company Name"
        classOption="flex"
        value={companyName}
        onChange={(event) => handlePropertyChange('companyName', event.target.value)}
      />
      <OutlinedTextField
        id="site-theme-colour"
        label="Site Theme Colour"
        classOption="flex"
        value={siteThemeColour}
        onChange={(event) => handlePropertyChange('siteThemeColour', event.target.value)}
        helperText="Add your own colour code, e.g. #000000"
      />
      <Grid>
        <SimpleSelect
          label="Interaction colour"
          items={[
            {
              label: InteractionColourLabel.Green,
              value: InteractionColourValue.Green,
              icon: (
                <Brightness1Icon
                  style={{
                    color: getColourCode(InteractionColourValue.Green, prefersDarkMode),
                    marginRight: 5,
                  }}
                />
              ),
            },
            {
              label: InteractionColourLabel.Blue,
              value: InteractionColourValue.Blue,
              icon: (
                <Brightness1Icon
                  style={{
                    color: getColourCode(InteractionColourValue.Blue, prefersDarkMode),
                    marginRight: 5,
                  }}
                />
              ),
            },
            {
              label: InteractionColourLabel.Indigo,
              value: InteractionColourValue.Indigo,
              icon: (
                <Brightness1Icon
                  style={{
                    color: getColourCode(InteractionColourValue.Indigo, prefersDarkMode),
                    marginRight: 5,
                  }}
                />
              ),
            },
            {
              label: InteractionColourLabel.DeepPurple,
              value: InteractionColourValue.DeepPurple,
              icon: (
                <Brightness1Icon
                  style={{
                    color: getColourCode(InteractionColourValue.DeepPurple, prefersDarkMode),
                    marginRight: 5,
                  }}
                />
              ),
            },
            {
              label: InteractionColourLabel.Purple,
              value: InteractionColourValue.Purple,
              icon: (
                <Brightness1Icon
                  style={{
                    color: getColourCode(InteractionColourValue.Purple, prefersDarkMode),
                    marginRight: 5,
                  }}
                />
              ),
            },
          ]}
          value={interactionColour || InteractionColourValue.Green}
          onChange={(value) => handlePropertyChange('interactionColour', value)}
          labelWidth={130}
          helperText="Select the colour of buttons and hyperlinks"
          allowEmpty={false}
        />
        <Button
          color="primary"
          variant="contained"
          size="small"
          style={{
            marginLeft: 20,
            backgroundColor: getColourCode(
              interactionColour || InteractionColourValue.Green,
              prefersDarkMode,
            ),
            color: prefersDarkMode ? '#000000' : '#FFFFFF',
            marginTop: 15,
            borderRadius: 8,
          }}
        >
          Example Button
        </Button>
      </Grid>
      <EditAddressDialog
        open={addressDialogOpen}
        address={address || blankAddress}
        onCancelCick={handleAddressCancelClick}
        onSubmitClick={handleAddressSubmitClick}
      />
    </Grid>
  );
};

export default SiteInformationForm;
