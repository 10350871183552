import moment from 'moment';

import updateOrder from './update-order';

import OrderData from '../../../types/OrderData';
import OrderItem from '../../../types/OrderItem';
import User from '../../../types/User';
import Nullable from '../../../types/Nullable';
import Status from '../../../types/Status';

const reopenOrder = (orderData: OrderData, reopenedCartIds: OrderItem['cartId'][], user: Nullable<User>) => {
  const newOrderData = {
    ...orderData,
    orderProcessed: false,
    status: Status.Outstanding,
    reopenedBy: user ? user.name : '',
    reopenedOn: moment().valueOf(),
    completedBy: '',
    completedOn: null,
  };

  return updateOrder(newOrderData);
};

export default reopenOrder;
