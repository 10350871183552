import React,
{
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Typography } from '@material-ui/core';

import getSiteMap from '../../actions/get-site-map';
import getProducts from '../../actions/get-products';

import SiteMapTable from './components/SiteMapTable';
import CategoryDialog from './components/category-dialog';
import Spinner from '../../components/spinner/spinner';

import changeSiteMap from '../../helpers/change-site-map';
import canUserViewProduct from '../../helpers/can-user-view-product';

import STORE_KEYS from '../../constants/store-keys';

const {
  AUTHENTICATION: { USER },
  SITE_MAP: { IS_SITE_MAP_LOADING, SITE_MAP_ID, SITE_MAP },
  PRODUCTS: { IS_PRODUCTS_LOADING, PRODUCTS },
} = STORE_KEYS;

const SiteMapPage = () => {
  const user = useSelector((state) => state.authentication.get(USER));
  const isSiteMapLoading = useSelector((state) => state.siteMap.get(IS_SITE_MAP_LOADING));
  const siteMapId = useSelector((state) => state.siteMap.get(SITE_MAP_ID));
  const siteMap = useSelector(
    (state) => state.siteMap.get(SITE_MAP).sort((a, b) => a.sequence - b.sequence),
  );
  const allProducts = useSelector((state) => state.products.get(PRODUCTS));
  const allProductsLoading = useSelector((state) => state.products.get(IS_PRODUCTS_LOADING));
  const [open, setOpen] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.site) {
      dispatch(getSiteMap(user.site));
      dispatch(getProducts(user.site));
    }
  }, []);

  const userSiteConfig = useMemo(
    () => user.sites.find(({ site }) => site === user.site),
    [user],
  );

  const handleClose = () => {
    setCategoryToEdit(null);
    setOpen(false);
  };

  const handleEdit = (category) => {
    setCategoryToEdit(category);
    setOpen(true);
  };

  const handleSave = () => {
    handleClose();
    dispatch(getSiteMap(user.site));
  };

  const handleRemove = (categoryToRemove) => {
    setUpdating(true);
    const newSiteMap = siteMap.filter((map) => map.category !== categoryToRemove);
    changeSiteMap(siteMapId, { site: user.site, products: newSiteMap })
      .then(() => {
        setUpdating(false);
        dispatch(getSiteMap(user.site));
      })
      .catch(() => {
        setUpdating(false);
        setError('Something went wrong and the chosen category could not be deleted. Please refresh the page.');
      });
  };

  const viewableCategories = useMemo(() => {
    if (
      siteMap == null
      || userSiteConfig == null
      || allProducts.length === 0
    ) return [];
    return siteMap.filter(({ subCategories }) => {
      if (subCategories.length === 0) return true;
      return subCategories.some(({ products }) => {
        if (products.length === 0) return true;
        return products.some((product) => {
          const productWithInfo = allProducts.find(({ plu }) => product.plu === plu);
          if (productWithInfo == null) return true;
          return canUserViewProduct(productWithInfo, userSiteConfig);
        });
      });
    });
  }, [siteMap, allProducts, userSiteConfig]);

  const editableCategories = useMemo(() => (
    viewableCategories.filter(({ subCategories }) => {
      if (subCategories.length === 0) return true;
      return subCategories.every(({ products }) => (
        products.every((product) => {
          const productWithInfo = allProducts.find(({ plu }) => product.plu === plu);
          if (productWithInfo == null) return true;
          return canUserViewProduct(productWithInfo, userSiteConfig);
        })
      ));
    }).map(({ category }) => category)
  ), [viewableCategories, allProducts, userSiteConfig]);

  if (isSiteMapLoading || updating || allProductsLoading) return <Spinner />;

  return (
    <Grid>
      {error != null && (
        <Typography color="error">{error}</Typography>
      )}
      <SiteMapTable
        siteMap={viewableCategories}
        onCreate={() => setOpen(true)}
        onRemove={handleRemove}
        onEdit={handleEdit}
        editableCategories={editableCategories}
        user={user}
      />
      <CategoryDialog
        open={open}
        onSave={handleSave}
        categoryToEdit={categoryToEdit}
        handleClose={handleClose}
      />
    </Grid>
  );
};

SiteMapPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default SiteMapPage;
