import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  makeStyles,
  Card,
  CardContent,
  Typography,
  Box,
  Chip,
  capitalize,
  Divider,
} from '@material-ui/core';
import { Email, Phone, VpnKey } from '@material-ui/icons';
import { DateTime } from 'luxon';

import ErrorDialog from '../../components/ErrorDialog/ErrorDialog';
import Spinner from '../../components/spinner/spinner';
import getSettings from '../../actions/get-settings';
import apiRequest from '../../helpers/api-request';
import formatMoney from '../../helpers/formatMoney';

import Nullable from '../../types/Nullable';
import ReduxState from '../../types/ReduxState';
import User from '../../types/User';
import SiteData from '../../types/SiteData';

const useStyles = makeStyles((theme) => ({
  displayName: {
    marginBottom: theme.spacing(4),
  },
  detailsListHeading: {
    marginBottom: theme.spacing(2),
  },
  detailsListItem: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  listItemLabel: {
    width: '20%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  listItemLabelIcon: {
    marginRight: theme.spacing(1),
  },
  loginMethodChip: {
    width: '7em',
    marginRight: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

const CustomerPage = () => {
  const params = useParams<{ id: string }>();
  const history = useHistory();

  const classes = useStyles();

  const dispatch = useDispatch();
  const authenticatedUser = useSelector<ReduxState, Nullable<User>>(
    (state) => state.authentication.get('USER'),
  );
  const settings = useSelector<ReduxState, Nullable<SiteData>>(
    (state) => state.settings.get('SETTINGS'),
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [customer, setCustomer] = useState<Nullable<{
    id: string;
    displayName: string;
    email: Nullable<string>;
    phoneNumber: Nullable<string>;
    signUpMethods: string[];
    ordersCount: number;
    paymentsTotal: number;
    lastSeenTimestamp: number;
  }>>(null);

  useEffect(() => {
    if (authenticatedUser == null) return;
    dispatch(getSettings(authenticatedUser.site));
  }, [authenticatedUser]);

  useEffect(() => {
    if (settings == null) return;
    setLoading(true);
    apiRequest(
      `sites/${settings.id}/customers/${params.id}`,
      'GET',
    )
      .then((response) => {
        setCustomer(response.data.customer);
      }).catch(() => {
        setError(true);
      }).finally(() => {
        setLoading(false);
      });
  }, [settings?.id]);

  const handleErrorDialogOkButtonClick = () => history.go(0);

  if (loading) return <Spinner />;

  return (
    <>
      <Card>
        <CardContent>
          <Typography
            color="textSecondary"
            gutterBottom
          >
            Customer
          </Typography>
          <Typography
            className={classes.displayName}
            variant="h5"
          >
            {customer?.displayName}
          </Typography>
          <Typography
            className={classes.detailsListHeading}
            color="textSecondary"
          >
            Account Details
          </Typography>
          <Box className={classes.detailsListItem}>
            <Typography className={classes.listItemLabel}>
              <Email
                className={classes.listItemLabelIcon}
                fontSize="small"
              />
              Email
            </Typography>
            <Typography>
              {customer?.email || '(unknown)'}
            </Typography>
          </Box>
          <Box className={classes.detailsListItem}>
            <Typography className={classes.listItemLabel}>
              <Phone
                className={classes.listItemLabelIcon}
                fontSize="small"
              />
              Phone
            </Typography>
            <Typography>
              {customer?.phoneNumber || '(unknown)'}
            </Typography>
          </Box>
          <Box className={classes.detailsListItem}>
            <Typography className={classes.listItemLabel}>
              <VpnKey
                className={classes.listItemLabelIcon}
                fontSize="small"
              />
              Login Methods
            </Typography>
            {customer?.signUpMethods.map((method) => (
              <Chip
                key={method}
                className={classes.loginMethodChip}
                label={capitalize(method)}
              />
            ))}
            {customer?.signUpMethods.length === 0 && (
              <Typography>
                (unknown)
              </Typography>
            )}
          </Box>
          <Divider className={classes.divider} />
          <Typography
            className={classes.detailsListHeading}
            color="textSecondary"
          >
            Sales History
          </Typography>
          <Box className={classes.detailsListItem}>
            <Typography className={classes.listItemLabel}>
              Total Orders
            </Typography>
            <Typography>
              {customer?.ordersCount}
            </Typography>
          </Box>
          <Box className={classes.detailsListItem}>
            <Typography className={classes.listItemLabel}>
              Payments Total
            </Typography>
            <Typography>
              {formatMoney(customer?.paymentsTotal || 0)}
            </Typography>
          </Box>
          <Box className={classes.detailsListItem}>
            <Typography className={classes.listItemLabel}>
              Last Visit
            </Typography>
            <Typography>
              {DateTime.fromMillis(customer?.lastSeenTimestamp || 0).toLocaleString()}
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <ErrorDialog
        open={error}
        onOkButtonClick={handleErrorDialogOkButtonClick}
      />
    </>
  );
};

export default CustomerPage;
