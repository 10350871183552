import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import MaterialTable from 'material-table';

import useUrlParams from '../../hooks/useUrlParams';

import User from '../../types/User';
import ReduxState from '../../types/ReduxState';
import Nullable from '../../types/Nullable';
import UserSiteConfig from '../../types/UserSiteConfig';
import UserRole from '../../types/UserRole';

type Props = {
  tableData: User[],
  onRemove?: (id: string) => void,
  onEdit?: (id: string) => void,
  onAdd?: () => void,
  disableEditCallback?: (rowData: User) => boolean,
  disableRemoveCallback?: (rowData: User) => boolean,
  siteUsers?: boolean,
};

const UsersTable = ({
  tableData,
  onRemove,
  onEdit,
  onAdd,
  disableEditCallback,
  disableRemoveCallback,
  siteUsers,
}: Props) => {
  const {
    values: [page, search],
    setters: [onPageChange, onSearchChange],
  } = useUrlParams(['page', 'search']);

  const authenticatedUser = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));

  const userSiteConfig = useMemo(
    () => authenticatedUser?.sites.find((siteConfig) => siteConfig.site === authenticatedUser.site),
    [authenticatedUser],
  );

  type ColumnProps = {
    title: string;
    field?: string;
    render?: (rowData: User) => UserRole;
  };

  const columns: ColumnProps[] = [
    { title: 'Name', field: 'name' },
    { title: 'Email', field: 'email' },
  ];

  if (siteUsers) {
    columns.splice(1, 0, {
      title: 'Role',
      render: (rowData: User) => rowData.sites.filter((siteConfig: UserSiteConfig) => (
        siteConfig.site === userSiteConfig?.site
      ))[0].role,
    });
  }

  const actions = [];
  if (onAdd != null) {
    actions.push(
      {
        icon: 'add',
        tooltip: 'Add User',
        isFreeAction: true,
        onClick: onAdd,
      },
    );
  }
  if (onEdit != null) {
    actions.push(
      (rowData: User) => ({
        icon: 'edit',
        tooltip: 'Edit User',
        onClick: () => onEdit(rowData.id),
        disabled: disableEditCallback != null ? disableEditCallback(rowData) : false,
      }),
    );
  }
  if (onRemove != null) {
    actions.push(
      (rowData: User) => ({
        icon: 'delete',
        tooltip: 'Remove User',
        onClick: () => onRemove(rowData.id),
        disabled: disableRemoveCallback != null ? disableRemoveCallback(rowData) : false,
      }),
    );
  }

  return (
    <MaterialTable
      title="Users"
      columns={columns}
      data={tableData}
      actions={actions}
      options={{
        actionsColumnIndex: -1,
        pageSize: tableData.length > 5 ? 10 : 5,
        initialPage: Number(page || 0),
        searchText: search || '',
      }}
      onSearchChange={onSearchChange}
      onChangePage={(newPage) => onPageChange(String(newPage))}
    />
  );
};

export default UsersTable;
