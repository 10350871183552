import {
  Chip, makeStyles, Paper, Table, TableBody, TableCell, TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import DownloadIcon from '@material-ui/icons/GetApp';

import Spinner from '../../../components/spinner/spinner';

import getQueryString from '../helpers/getQueryString';
import request from '../../../helpers/request';
import getLabelTextFromId from '../../../helpers/getLabelTextFromId';
import capitalize from '../../../helpers/capitalize';
import getReconcilicationPayoutDetailAmount from '../helpers/getReconcilicationPayoutDetailAmount';

import Options from '../types/Options';
import Nullable from '../../../types/Nullable';
import ReconciliationReportData from '../types/ReconciliationReportData';
import Label from '../../../types/Label';
import downloadReconciliationReport from '../helpers/downloadReconciliationReport';
import SiteData from '../../../types/SiteData';
import getSearchPeriod from '../helpers/getSearchPeriod';

interface Props {
  options: Options;
  site: Nullable<SiteData['site']>
  siteName: Nullable<SiteData['name']>
  siteLabels: Label[]
}
const useStyles = makeStyles((theme) => ({
  tableHeadCell: { fontWeight: 'bold' },
  downloadButton: {
    margin: theme.spacing(3, 0, 0, 3),
  },
  totalsRow: {
    paddingTop: theme.spacing(8),
    fontWeight: 'bold',
  },
}));

const ReconciliationReport = ({
  options,
  site,
  siteName,
  siteLabels,
}: Props) => {
  const classes = useStyles();
  const [tableData, setTableData] = useState<Array<ReconciliationReportData>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (site == null) return;

    const queryString = getQueryString(
      'reconciliationReport',
      site,
      options,
      undefined,
      undefined,
      options.reportingLabels,
    );
    request(queryString, 'GET')
      .then((response) => setTableData(response))
      .catch(() => setError('Reconciliation Report could not be created. Please try again.'))
      .finally(() => setIsLoading(false));
  }, []);

  const payoutDetailTitles: string[] = useMemo(() => (
    tableData.reduce((titles: string[], currentVendor) => {
      const currentVendorTitles = currentVendor.payoutDetails.reduce(
        (payoutTitles: string[], currentDetails) => {
          if (!titles.includes(currentDetails.payoutTitle)) {
            return [...payoutTitles, currentDetails.payoutTitle];
          }
          return payoutTitles;
        },
        [],
      );
      return [...titles, ...currentVendorTitles];
    }, ['stripe'])
  ), [tableData]);

  const searchPeriod = useMemo(() => getSearchPeriod(
    options.type, options.start, options.end,
  ), [options]);

  const handleDownload = useCallback(() => {
    downloadReconciliationReport(
      tableData, payoutDetailTitles, siteLabels, siteName, site, searchPeriod,
    );
  }, [tableData]);

  const ReconciliationRow = (props: { row: ReconciliationReportData }) => {
    const { row } = props;
    const isTotalsRow = row.vendorId === 'TOTALS';

    return (
      <>
        <TableRow className={isTotalsRow ? classes.totalsRow : undefined}>
          <TableCell className={isTotalsRow ? classes.totalsRow : undefined}>
            {getLabelTextFromId(row.vendorId, siteLabels) || row.vendorId}
          </TableCell>
          <TableCell className={isTotalsRow ? classes.totalsRow : undefined}>
            {row.transactions}
          </TableCell>
          <TableCell className={isTotalsRow ? classes.totalsRow : undefined}>
            {row.totalSales.toFixed(2)}
          </TableCell>
          <TableCell className={isTotalsRow ? classes.totalsRow : undefined}>
            {row.tips.toFixed(2)}
          </TableCell>
          <TableCell className={isTotalsRow ? classes.totalsRow : undefined}>
            {row.serviceCharge.toFixed(2)}
          </TableCell>
          <TableCell className={isTotalsRow ? classes.totalsRow : undefined}>
            {row.charityDonations.toFixed(2)}
          </TableCell>
          <TableCell className={isTotalsRow ? classes.totalsRow : undefined}>
            {row.giftCardsSold.toFixed(2)}
          </TableCell>
          <TableCell className={isTotalsRow ? classes.totalsRow : undefined}>
            {row.netPeaziFees.toFixed(2)}
          </TableCell>
          <TableCell className={isTotalsRow ? classes.totalsRow : undefined}>
            {row.peaziFeesVat.toFixed(2)}
          </TableCell>
          <TableCell className={isTotalsRow ? classes.totalsRow : undefined}>
            {row.commissionReceived.toFixed(2)}
          </TableCell>
          <TableCell className={isTotalsRow ? classes.totalsRow : undefined}>
            {row.commissionPaid.toFixed(2)}
          </TableCell>
          <TableCell className={isTotalsRow ? classes.totalsRow : undefined}>
            {row.payout.toFixed(2)}
          </TableCell>
          {payoutDetailTitles.map((title) => (
            <TableCell className={isTotalsRow ? classes.totalsRow : undefined}>
              {getReconcilicationPayoutDetailAmount(tableData, row.vendorId, title).toFixed(2)}
            </TableCell>
          ))}
        </TableRow>
      </>
    );
  };

  if (isLoading) return <Spinner />;

  if (error.length > 0) return <Typography align="center" color="error">{error}</Typography>;

  if (tableData.length === 0) {
    return (
      <Typography align="center">
        No sales were recorded for items with the selected Reporting Label(s) during
        the chosen date range.
      </Typography>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Chip
        className={classes.downloadButton}
        icon={<DownloadIcon />}
        label="Download Reconciliation Report"
        onClick={handleDownload}
        color="primary"
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeadCell}>Vendor</TableCell>
            <TableCell className={classes.tableHeadCell}>Transactions</TableCell>
            <TableCell className={classes.tableHeadCell}>Total Sales&nbsp;(£)</TableCell>
            <TableCell className={classes.tableHeadCell}>Tips&nbsp;(£)</TableCell>
            <TableCell className={classes.tableHeadCell}>Other Charges&nbsp;(£)</TableCell>
            <TableCell className={classes.tableHeadCell}>Charity Donations&nbsp;(£)</TableCell>
            <TableCell className={classes.tableHeadCell}>Gift Cards Sold&nbsp;(£)</TableCell>
            <TableCell className={classes.tableHeadCell}>Fees net of VAT&nbsp;(£)</TableCell>
            <TableCell className={classes.tableHeadCell}>Fees VAT&nbsp;(£)</TableCell>
            <TableCell className={classes.tableHeadCell}>Commission Received&nbsp;(£)</TableCell>
            <TableCell className={classes.tableHeadCell}>Commission Paid&nbsp;(£)</TableCell>
            <TableCell className={classes.tableHeadCell}>Total Payout&nbsp;(£)</TableCell>
            {payoutDetailTitles.map((title) => (
              <TableCell className={classes.tableHeadCell}>{`${capitalize(title)} Payout (£)`}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row) => (
            <ReconciliationRow key={row.vendorId} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReconciliationReport;
