import React, {
  ReactNode, useCallback, useState,
} from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import Nullable from '../types/Nullable';
import AlertMessage from '../types/AlertMessage';

import AlertContext from './AlertContext';

interface Props {
  children : ReactNode
}

const AlertContextProvider = ({ children }: Props) => {
  const [alertMessage, setAlertMessage] = useState<Nullable<AlertMessage>>(null);

  const handleAlertClose = useCallback(() => {
    setAlertMessage(null);
  }, [setAlertMessage]);

  return (
    <AlertContext.Provider value={{ setMessage: setAlertMessage }}>
      {children}
      {alertMessage && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={alertMessage !== null}
          autoHideDuration={6000}
          onClose={handleAlertClose}
        >
          <Alert elevation={6} variant="filled" onClose={handleAlertClose} severity={alertMessage.type}>
            {alertMessage.message}
          </Alert>
        </Snackbar>
      )}
    </AlertContext.Provider>
  );
};

export default AlertContextProvider;
