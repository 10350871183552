import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './containers/app/app';

import store from './constants/store';

import initialiseFirebase from './helpers/initialise-firebase';

initialiseFirebase();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('app'),
);
