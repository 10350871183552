import * as Joi from 'joi';

import OrderData from '../types/OrderData';

const isOrderData = (value: unknown): value is OrderData => {
  const schema = Joi.object({
    userId: Joi.string(),
    orderNumber: Joi.string().required(),
    site: Joi.string().required(),
    total: Joi.number().required(),
    tableNumber: Joi.string().allow(''),
    notes: Joi.string().allow(''),
    testMode: Joi.boolean(),
    userDetails: Joi.object({
      name: Joi.string().allow(''),
      email: Joi.string().allow(''),
      phoneNumber: Joi.string().allow(''),
      selectedAddress: Joi.string().allow(''),
    }),
    onlineOrder: Joi.boolean().required(),
    serviceChargeAmount: Joi.number(),
    tipAmount: Joi.number(),
    status: Joi.string().required(),
    orderTime: Joi.number().required(),
    order: Joi.array().items(Joi.object({
      plu: Joi.number().required(),
      pluName: Joi.string().allow(''),
      name: Joi.string().required(),
      price: Joi.number().required(),
      description: Joi.string().allow(''),
      quantity: Joi.number().required(),
      serviceCharge: Joi.boolean(),
      tip: Joi.boolean(),
      discount: Joi.boolean(),
      refunded: Joi.boolean(),
      refundAmount: Joi.number(),
      addOns: Joi.array().items(Joi.object({
        plu: Joi.number().required(),
        name: Joi.string().required(),
        pluName: Joi.string().allow(''),
        description: Joi.string().allow(''),
        price: Joi.number().required(),
      })),
      charityDonation: Joi.boolean(),
    })).required(),
    discount: Joi.alternatives().try(
      Joi.object({
        code: Joi.string().required(),
        maxUsages: Joi.number().allow(null),
        usages: Joi.number().allow(null),
        name: Joi.string().required(),
        value: Joi.alternatives()
          .try(Joi.number().min(0.01), Joi.number().min(1).max(99)).required(),
        type: Joi.string().required(),
        from: Joi.date().allow(''),
        to: Joi.date().allow(''),
        day: Joi.string().allow(null).allow(''),
        reportingLabels: Joi.array().items(Joi.string()),
        plu: Joi.number().allow(null),
        minOrderValue: Joi.number().min(0),
        status: Joi.string().required(),
      }),
      Joi.array().items(Joi.object({
        active: Joi.boolean().required(),
        code: Joi.string().required(),
        description: Joi.string().allow(''),
        discountAmount: Joi.number().required(),
        plu: Joi.number().required(),
        removeable: Joi.boolean(),
        site: Joi.string().required(),
        type: Joi.string().required(),
        value: Joi.number().required(),
      })),
    ).allow(null),
    charityDonationAmount: Joi.number(),
  }).required();
  const result = schema.validate(value, { allowUnknown: true });

  return result.error == null;
};

export default isOrderData;
