import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import {
  Paper,
  makeStyles,
} from '@material-ui/core';
import { DataGrid, GridCellParams } from '@material-ui/data-grid';
import ErrorDialog from '../../../components/ErrorDialog/ErrorDialog';
import formatMoney from '../../../helpers/formatMoney';
import ServerReportData from '../../../types/ServerReportData';
import Options from '../types/Options';
import SiteData from '../../../types/SiteData';
import apiRequest from '../../../helpers/api-request';
import Nullable from '../../../types/Nullable';
import DateType from '../types/DateType';

interface Props {
  options: Options
  siteData: Nullable<SiteData>
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  filtersContainer: {
    margin: theme.spacing(0, 0, 4, 0),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dateInput: {
    margin: theme.spacing(0, 2, 0, 0),
  },
  serverSelect: {
    width: 400,
  },
}));

const ServerReport = ({
  options,
  siteData,
}: Props) => {
  const classes = useStyles();
  const [isServersTableLoading, setIsServersTableLoading] = useState<boolean>(false);
  const [serverError, setServerError] = useState<boolean>(false);
  const [serverRows, setServerRows] = useState<ServerReportData[]>([]);

  const handleServerErrorOkClick = () => {
    setServerError(false);
  };
  useEffect(() => {
    if (siteData != null) {
      setIsServersTableLoading(true);
      let startDate;
      let endDate;
      switch (options.type) {
        case DateType.TradingDate:
          startDate = DateTime.fromFormat(options.start, 'yyyy-MM-dd').toSeconds();
          endDate = DateTime.fromFormat(options.end, 'yyyy-MM-dd').toSeconds();
          break;
        default:
          startDate = DateTime.fromFormat(options.start, 'yyyy-MM-dd\'T\'HH:mm').toSeconds();
          endDate = DateTime.fromFormat(options.end, 'yyyy-MM-dd\'T\'HH:mm').toSeconds();
      }
      apiRequest(
        `server-report?site=${encodeURIComponent(siteData.site)}
        &fromDate=${startDate}
        &untilDate=${endDate}${options.posServer != null ? `
        &server=${options.posServer}` : ''}`,
        'GET',
      ).then((response) => {
        const { data } = response;
        setServerRows(data);
        setIsServersTableLoading(false);
      });
    }
  }, [siteData, options]);

  const posServercolumns = [
    { field: 'server', headerName: 'Server', flex: 1 },
    { field: 'orderNumber', headerName: 'Order Number', flex: 1 },
    {
      field: 'orderTime',
      headerName: 'Order Time',
      renderCell: ({ row: { orderTime } }: GridCellParams) => DateTime.fromSeconds(orderTime).toFormat('yyyy/MM/dd HH:mm'),
      flex: 1,
    },
    {
      field: 'value',
      headerName: 'Value',
      renderCell: ({ row: { value } }: GridCellParams) => formatMoney(value),
      flex: 1,
    },
    { field: 'paymentType', headerName: 'Payment Type', flex: 1 },
    { field: 'status', headerName: 'Order Status', flex: 1 },
  ];

  return (
    <>
      <Paper className={classes.container}>
        <DataGrid
          rows={serverRows.map((row) => ({ ...row, id: row.orderNumber }))}
          columns={posServercolumns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          autoHeight
          loading={isServersTableLoading}
        />
      </Paper>
      <ErrorDialog
        open={serverError}
        onOkButtonClick={handleServerErrorOkClick}
      />
    </>
  );
};

export default ServerReport;
