import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  makeStyles,
} from '@material-ui/core';

import AdornmentTextField from '../../adornment-text-field/adornment-text-field';

import getCurrencyAdornment from '../../../helpers/getCurrencyAdornment';

const useStyles = makeStyles(() => ({
  fields: {
    marginBottom: 20,
  },
}));

const AdvancedSearchDialog = ({
  open, options, onChange, onClose, onClear, onSearch, siteData,
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Advanced Search</DialogTitle>
      <DialogContent>
        <TextField
          className={classes.fields}
          label="Order Number"
          variant="outlined"
          value={options.orderNumber}
          onChange={(e) => onChange('orderNumber', e.target.value)}
          fullWidth
        />
        <TextField
          className={classes.fields}
          label={`${siteData?.locationDescriptor || 'Table Number'}`}
          variant="outlined"
          value={options.tableNumber}
          onChange={(e) => onChange('tableNumber', e.target.value)}
          fullWidth
        />
        <TextField
          className={classes.fields}
          label="Order Time (from)"
          variant="outlined"
          type="datetime-local"
          value={options.timeAfter}
          onChange={(e) => onChange('timeAfter', e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
        <TextField
          className={classes.fields}
          label="Order Time (to)"
          variant="outlined"
          type="datetime-local"
          value={options.timeBefore}
          onChange={(e) => onChange('timeBefore', e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
        <AdornmentTextField
          id="outlined-adornment-amount"
          label="Amount"
          labelWidth={60}
          value={options.amount}
          onChange={(e) => onChange('amount', e.target.value)}
          adornment={getCurrencyAdornment(siteData.currency)}
          required
        />
        <TextField
          className={classes.fields}
          label="Last Four"
          variant="outlined"
          value={options.last4}
          onChange={(e) => onChange('last4', e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onClear} color="secondary">
          Clear
        </Button>
        <Button onClick={onSearch} color="primary">
          Search
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AdvancedSearchDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  options: PropTypes.shape({
    orderNumber: PropTypes.string,
    tableNumber: PropTypes.string,
    timeAfter: PropTypes.string,
    timeBefore: PropTypes.string,
    amount: PropTypes.string,
    last4: PropTypes.string,
    includeFailedPayments: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  siteData: PropTypes.shape().isRequired,
};

export default AdvancedSearchDialog;
