import * as React from 'react';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridRowData,
} from '@material-ui/data-grid';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import getUsers from '../../../actions/get-users';

import Nullable from '../../../types/Nullable';
import apiRequest from '../../../helpers/api-request';
import POSOtherPaymentType from '../../../types/POSOtherPaymentType';
import Toolbar from './Toolbar';
import ConfirmationDialog from '../../../components/confirmation-dialog/confirmation-dialog';
import CustomChip from '../../../components/CustomChip/CustomChip';
import SiteData from '../../../types/SiteData';
import User from '../../../types/User';
import AppUrl from '../../../types/AppUrl';

interface Props {
  settings: SiteData
  users: User[]
}

const useStyles = makeStyles((theme) => ({
  chipBox: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  remainingChips: {
    marginLeft: theme.spacing(1),
  },
}));

const POSOtherPaymentsTable = ({ settings, users }: Props) => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [tableError, setTableError] = useState<Nullable<Error>>(null);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [openDeletePaymentTypeDialog, setOpenDeletePaymentTypeDialog] = useState<boolean>(false);
  const [
    selectedOtherPaymentType,
    setSelectedOtherPaymentType,
  ] = useState<GridRowData | null>(null);
  const [posPaymentTypes, setPosPaymentTypes] = useState<POSOtherPaymentType[]>([]);

  const { id: siteId } = settings;

  const fetchList = useCallback(async () => {
    const { data } = await apiRequest(`sites/${siteId}/pos-other-payment-types/`, 'GET');
    setPosPaymentTypes(data);
    setTableError(null);
  }, []);

  useEffect(() => {
    if (users != null && users.length > 0) {
      fetchList().then(() => setTableLoading(false));
    }
  }, [siteId, users]);

  useEffect(() => {
    if (users.length === 0) {
      dispatch(getUsers());
    }
  }, []);

  const handleChangeStatus = async (posPaymentType: GridRowData) => {
    setTableLoading(true);
    const body = { ...posPaymentType, active: !posPaymentType.active };
    try {
      await apiRequest(
        `sites/${siteId}/pos-other-payment-types/${posPaymentType.id}`,
        'PUT',
        body,
      );
      await fetchList();
    } catch (error) {
      setTableError(new Error('Something went wrong, please try again or contact support'));
    } finally {
      setTableLoading(false);
    }
  };

  const handleDeleteOtherPaymentType = async () => {
    if (selectedOtherPaymentType != null) {
      try {
        setTableLoading(true);
        setOpenDeletePaymentTypeDialog(false);
        await apiRequest(`sites/${settings.id}/pos-other-payment-types/${selectedOtherPaymentType.id}`, 'DELETE');
        await fetchList();
      } catch (err) {
        setTableError(new Error('Something went wrong, please try again or contact support'));
      } finally {
        setTableLoading(false);
      }
    }
  };

  const columns: GridColumns = useMemo(() => [
    { headerName: 'Name', field: 'name', flex: 2 },
    {
      headerName: 'Reporting Labels',
      field: 'reportingLabels',
      flex: 3,
      renderCell: (params: GridCellParams) => {
        const paymentLabels = [...params.row.reportingLabels];
        if (paymentLabels.length > 0) {
          const matchedLabel = settings.reportingLabels?.find(({ id }) => paymentLabels[0] === id);
          return (
            <Box className={classes.chipBox}>
              <CustomChip
                label={matchedLabel != null ? matchedLabel.text : ''}
                color={theme.palette.primary.main}
              />
              {paymentLabels.length > 1 && (
                <Typography className={classes.remainingChips} color="primary">{`+${paymentLabels.length - 1}... `}</Typography>
              )}
            </Box>
          );
        }
        return <></>;
      },
    },
    {
      headerName: 'Users',
      field: 'posUsers',
      flex: 3,
      renderCell: (params: GridCellParams) => {
        const paymentUsers = [...params.row.posUsers];
        if (paymentUsers.length > 0) {
          const matchedUser = users.find(({ email }) => paymentUsers[0] === email);
          return (
            <>
              <CustomChip
                label={matchedUser != null ? matchedUser.name : ''}
                color={theme.palette.primary.main}
              />
              {paymentUsers.length > 1 && (
                <Typography className={classes.remainingChips} color="primary">{`+${paymentUsers.length - 1}... `}</Typography>
              )}
            </>
          );
        }
        return <></>;
      },
    },
    { headerName: 'Type', field: 'type', flex: 1.5 },
    {
      headerName: 'Status',
      field: 'active',
      flex: 1.5,
      renderCell: (params: GridCellParams) => (
        <CustomChip
          label={params.row.active ? 'Enabled' : 'Disabled'}
          color={params.row.active ? 'Green' : 'Red'}
          onClick={() => handleChangeStatus(params.row)}
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 2,
      renderCell: (params: GridCellParams) => (
        <Grid>
          <Tooltip title="Edit additional payment type">
            <IconButton
              onClick={() => {
                history.push(`/pos/edit-additional-payment-type/${params.row.id}`);
              }}
              color="inherit"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Remove Additional Payment Type">
            <IconButton
              onClick={() => {
                setSelectedOtherPaymentType(params.row);
                setOpenDeletePaymentTypeDialog(true);
              }}
              color="inherit"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ),
    },
  ], [users]);

  return (
    <>
      <DataGrid
        components={{ Toolbar }}
        columns={columns}
        rows={posPaymentTypes}
        autoHeight
        pageSize={10}
        rowsPerPageOptions={[10]}
        loading={tableLoading}
        error={tableError}
        componentsProps={{
          toolbar: {
            onButtonClick: () => {
              history.push(AppUrl.AddPosAdditionalPaymentType);
            },
            buttonTitle: 'Add payment type',
            hideSearch: true,
          },
        }}
      />
      <ConfirmationDialog
        title="Remove user from POS"
        message={`Are you sure you want to remove ${selectedOtherPaymentType?.name}?`}
        open={openDeletePaymentTypeDialog}
        onSave={handleDeleteOtherPaymentType}
        onClose={() => setOpenDeletePaymentTypeDialog(false)}
      />
    </>
  );
};

export default POSOtherPaymentsTable;
