enum PeaziColour {
  LightModeGreen = '#1B8183',
  DarkModeGreen = '#A1E4E1',
  LightModeBlue = '#2196F3',
  DarkModeBlue = '#90CAF9',
  LightModeIndigo = '#3F51B5',
  DarkModeIndigo = '#B6C1FA',
  LightModeDeepPurple = '#673AB7',
  DarkModeDeepPurple = '#C2AAED',
  LightModePurple = '#9C27b0',
  DarkModePurple = '#D7A2E0',
}

export default PeaziColour;
