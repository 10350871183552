import React, { ChangeEvent, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  DialogContentText,
  makeStyles,
} from '@material-ui/core';
import Nullable from '../../../types/Nullable';
import posImage from '../../../images/wisepos.svg';

const useStyles = makeStyles({
  infoText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

interface Props {
  open: boolean;
  onCancelClick: () => void;
  onSaveClick: (
    label: string,
    pairingCode: string,
  ) => Promise<void>;
  error: Nullable<Error>;
  loading: boolean;
}

const AddReaderDialog = (
  {
    open,
    onCancelClick,
    onSaveClick,
    error,
    loading,
  }: Props,
) => {
  const classes = useStyles();

  const [label, setLabel] = useState<string>('');
  const [pairingCode, setPairingCode] = useState<string>('');

  const handleLabelChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;
    setLabel(value);
  };

  const handlePairingCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;
    setPairingCode(value);
  };

  return (
    <Dialog
      open={open}
    >
      <DialogTitle>
        Add Device
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.infoText}>
          <img src={posImage} alt="card reader device" />
          If you have a BBPOS WisePOS E, go to settings and tap ‘Generate pairing code’.
          Add the generated code below.
        </DialogContentText>
        <TextField
          label="Label"
          value={label}
          onChange={handleLabelChange}
          variant="outlined"
          margin="normal"
          fullWidth
        />
        <TextField
          label="Pairing Code"
          value={pairingCode}
          onChange={handlePairingCodeChange}
          variant="outlined"
          margin="normal"
          fullWidth
        />
        {error && (
          <DialogContentText>
            Something went wrong. Please contact support if this problem persists.
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onCancelClick}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSaveClick(label, pairingCode)}
          disabled={loading}
        >
          Add Device
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddReaderDialog;
