import React, {
  ChangeEvent, useCallback, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';

import SnackBar from '../../components/snack-bar/snack-bar';

import isEmailValid from '../../helpers/isEmailValid';
import resetPassword from './helpers/resetPassword';

import peaziLogo from '../../images/peazi-logo.svg';
import peaziLogoDark from '../../images/peazi-logo-dark.svg';

import AuthUrl from '../../types/AuthUrl';
import Nullable from '../../types/Nullable';

const useStyles = makeStyles((theme) => ({
  forgotPasswordContainer: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    width: theme.spacing(50),
  },
  loginForm: {
    margin: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 100,
    height: 'auto',
  },
  loginText: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const ForgotPasswordPage = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [email, setEmail] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [resetError, setResetError] = useState<Nullable<string>>(null);
  const classes = useStyles();
  const history = useHistory();

  const isFormValid = useMemo(() => isEmailValid(email), [email]);

  const onEmailChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, [setEmail]);

  const onClick = useCallback(() => {
    resetPassword(email)
      .then(() => {
        setOpen(true);
        setTimeout(() => history.push(AuthUrl.Login), 5000);
      })
      .catch((error) => {
        setResetError(error.message || 'Failed to send password reset email');
      });
  }, [email]);

  return (
    <Box className={classes.forgotPasswordContainer}>
      <Paper className={classes.paper} elevation={3}>
        <Grid className={classes.loginForm}>
          <img className={classes.image} src={prefersDarkMode ? peaziLogoDark : peaziLogo} alt="Peazi Logo" />
          <Typography className={classes.loginText} variant="h6">Forgot Password</Typography>
          <TextField
            label="Email"
            variant="outlined"
            type="email"
            margin="normal"
            autoComplete="email"
            value={email}
            onChange={onEmailChange}
            fullWidth
            required
            autoFocus
          />
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={onClick}
            disabled={!isFormValid}
            fullWidth
          >
            Reset Password
          </Button>
          {resetError && <Typography variant="body1" color="error">{resetError}</Typography>}
          <SnackBar
            open={open}
            message="Password reset email successfully sent, please check your emails."
            onClose={() => setOpen(false)}
          />
        </Grid>
      </Paper>
    </Box>
  );
};

export default ForgotPasswordPage;
