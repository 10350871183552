import React, { useEffect, useState, ChangeEvent } from 'react';
import firebase from 'firebase/app';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  makeStyles,
  Paper,
  Tab,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { DataGrid } from '@material-ui/data-grid';
import { ArrowForward } from '@material-ui/icons';
import { DateTime } from 'luxon';

import ErrorDialog from '../../components/ErrorDialog/ErrorDialog';
import Spinner from '../../components/spinner/spinner';
import apiRequest from '../../helpers/api-request';
import isSiteData from '../../helpers/isSiteData';

import ReduxState from '../../types/ReduxState';
import User from '../../types/User';
import SiteData from '../../types/SiteData';
import Nullable from '../../types/Nullable';
import CustomerAuthProvider from '../../types/CustomerAuthProvider';

const useStyles = makeStyles((theme) => ({
  paper: { margin: theme.spacing(2, 0), padding: theme.spacing(1, 0) },
  errorMessageBox: { padding: theme.spacing(4) },
  tabList: { borderBottom: '1px solid #e8e8e8' },
  formControl: { marginRight: theme.spacing(4) },
}));

const CustomersPage = () => {
  const history = useHistory();

  const classes = useStyles();

  const user = useSelector<ReduxState, Nullable<User>>(
    (state) => state.authentication.get('USER'),
  );

  const [tab, setTab] = useState<'options' | 'table'>('options');
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [customers, setCustomers] = useState<{
    id: string;
    displayName: string;
    ordersCount: number
  }[]>([]);
  const [timeRange] = useState<{ startingAfter: number; upUntil: number }>({
    upUntil: DateTime.now().toMillis(),
    startingAfter: DateTime.now().minus({ days: 30 }).toMillis(),
  });
  const [settings, setSettings] = useState<Nullable<SiteData>>(null);

  useEffect(() => {
    if (user == null) return () => {};
    try {
      const unsub = firebase
        .firestore()
        .collection('sites')
        .where('site', '==', user.site)
        .onSnapshot((snapshot) => {
          const doc = snapshot.docs[0];
          const data = { id: doc.id, ...doc.data() };
          if (!isSiteData(data)) {
            throw new Error(`Invalid data in Firestore for site "${user.site}".`);
          }
          setSettings(data);
        });
      return unsub;
    } catch {
      setError(true);
      return () => {};
    }
  }, [user]);

  useEffect(() => {
    if (settings == null) return;
    setLoading(true);
    apiRequest(
      `sites/${settings.id}/customers?upUntil=${timeRange.upUntil}&startingAfter=${timeRange.startingAfter}`,
      'GET',
    )
      .then((response) => {
        setCustomers(response.data.customers);
      }).catch(() => {
        setError(true);
      }).finally(() => {
        setLoading(false);
      });
  }, [settings?.id]);

  const handleTabChange = (_event: ChangeEvent<{}>, newValue: 'options' | 'table') => {
    setTab(newValue);
  };

  const authProviderCheckboxHandlerFactory = (provider: CustomerAuthProvider) => async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    if (settings == null) return;

    const { target: { checked } } = event;
    let newAuthProviders: CustomerAuthProvider[];
    if (checked) {
      newAuthProviders = [...settings.customersOptions.authProviders, provider];
    } else {
      newAuthProviders = settings.customersOptions.authProviders
        .filter((element) => element !== provider);
    }

    try {
      await firebase.firestore().collection('sites').doc(settings.id).update({
        customersOptions: {
          ...settings.customersOptions,
          authProviders: newAuthProviders,
        },
      });
    } catch {
      setError(true);
    }
  };

  const appFeatureCheckboxHandlerFactory = (propertyToUpdate: string) => async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    if (settings == null) return;

    const { target: { checked } } = event;

    try {
      await firebase.firestore().collection('sites').doc(settings.id).update({
        customersOptions: {
          ...settings.customersOptions,
          appFeatures: {
            ...settings.customersOptions.appFeatures,
            [propertyToUpdate]: checked,
          },
        },
      });
    } catch {
      setError(true);
    }
  };

  const handleErrorDialogOkButtonClick = () => history.go(0);

  const viewButtonClickHandlerFactory = (id: string) => () => {
    history.push(`/customers/${id}`);
  };

  if (settings == null || loading) return <Spinner />;

  const {
    customersOptions: {
      authProviders: authProvidersEnabled,
      appFeatures: appFeaturesEnabled,
    },
  } = settings;

  return (
    <Paper className={classes.paper}>
      <TabContext value={tab}>
        <TabList className={classes.tabList} onChange={handleTabChange}>
          <Tab value="options" label="Options" />
          <Tab value="table" label="Customers" />
        </TabList>
        <TabPanel value="options">
          <FormControl className={classes.formControl} component="fieldset">
            <FormLabel component="legend">Authentication Providers</FormLabel>
            <FormGroup>
              <FormControlLabel
                label="Mobile Phone"
                control={(
                  <Checkbox
                    checked={authProvidersEnabled.includes(CustomerAuthProvider.MobilePhone)}
                    onChange={authProviderCheckboxHandlerFactory(CustomerAuthProvider.MobilePhone)}
                  />
                )}
              />
              <FormControlLabel
                label="Google"
                control={(
                  <Checkbox
                    checked={authProvidersEnabled.includes(CustomerAuthProvider.Google)}
                    onChange={authProviderCheckboxHandlerFactory(CustomerAuthProvider.Google)}
                  />
                )}
              />
              <FormControlLabel
                label="Apple Id"
                control={(
                  <Checkbox
                    checked={authProvidersEnabled.includes(CustomerAuthProvider.Apple)}
                    onChange={authProviderCheckboxHandlerFactory(CustomerAuthProvider.Apple)}
                  />
                )}
              />
              <FormControlLabel
                label="Email"
                control={(
                  <Checkbox
                    checked={authProvidersEnabled.includes(CustomerAuthProvider.Email)}
                    onChange={authProviderCheckboxHandlerFactory(CustomerAuthProvider.Email)}
                  />
                )}
              />
            </FormGroup>
            <FormHelperText>
              Sign up methods available for new Customers
            </FormHelperText>
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">Order & Pay App Features</FormLabel>
            <FormGroup>
              <FormControlLabel
                label="Show Sign Up Options"
                control={(
                  <Checkbox
                    checked={appFeaturesEnabled.showSignUpOption}
                    onChange={appFeatureCheckboxHandlerFactory('showSignUpOption')}
                  />
                )}
              />
              <FormControlLabel
                label="Pre-populate Customer Name"
                control={(
                  <Checkbox
                    checked={appFeaturesEnabled.populateCustomerName}
                    onChange={appFeatureCheckboxHandlerFactory('populateCustomerName')}
                  />
                )}
              />
            </FormGroup>
            <FormHelperText>
              How Customer info is used in the App
            </FormHelperText>
          </FormControl>
        </TabPanel>
        <TabPanel value="table">
          <DataGrid
            autoHeight
            pageSize={20}
            rows={customers}
            columns={[
              { field: 'displayName', headerName: 'Customer', flex: 5 },
              { field: 'ordersCount', headerName: 'Orders (30 Days)', flex: 4 },
              {
                field: 'actions',
                headerName: 'Actions',
                renderCell: (params) => (
                  <Tooltip title="View Customer">
                    <IconButton
                      onClick={viewButtonClickHandlerFactory(params.row.id)}
                    >
                      <ArrowForward />
                    </IconButton>
                  </Tooltip>
                ),
                flex: 1,
              },
            ]}
          />
          <ErrorDialog
            open={error}
            onOkButtonClick={handleErrorDialogOkButtonClick}
          />
        </TabPanel>
      </TabContext>
    </Paper>
  );
};

export default CustomersPage;
