import firebase from 'firebase/app';
import { DateTime } from 'luxon';

const uploadImage = (site, image) => firebase
  .storage()
  .ref()
  .child(`${site}/images/${image.name}-${DateTime.now().valueOf()}.png`)
  .put(image);

export default uploadImage;
