import firebase from 'firebase/app';

import OrderData from '../types/OrderData';
import Dispatch from '../types/Dispatch';

import {
  GET_UNPROCESSED_ORDERS_START,
  GET_UNPROCESSED_ORDERS_SUCCESS,
} from '../constants/actions';

export default (site?: string) => (dispatch: Dispatch) => {
  dispatch({
    type: GET_UNPROCESSED_ORDERS_START,
  });
  const firebaseCall = firebase
    .firestore()
    .collection('orders')
    .where('orderProcessed', '==', false);
  const call = site ? firebaseCall.where('site', '==', site) : firebaseCall;
  call
    .onSnapshot((snapshots) => {
      const unprocessedOrders: OrderData[] = [];
      snapshots.forEach((snapshot) => unprocessedOrders
        .push(<OrderData>{ ...snapshot.data(), id: snapshot.id }));
      dispatch({
        type: GET_UNPROCESSED_ORDERS_SUCCESS,
        numberOfUnprocessedOrders: unprocessedOrders.length,
        unprocessedOrders: unprocessedOrders.sort((a, b) => a.orderTime - b.orderTime),
      });
    });
};
