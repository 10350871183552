import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridRowData,
} from '@material-ui/data-grid';
import {
  Dialog,
  Grid,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Toolbar from './Toolbar';
import AddLocationModal from './AddLocationModal';
import EditLocationModal from './EditLocationModal';

import apiRequest from '../../../helpers/api-request';

import Nullable from '../../../types/Nullable';
import TerminalLocation from '../../../types/TerminalLocation';
import SiteAddress from '../../../types/SiteAddress';

interface Props {
  siteId: Nullable<string>;
  siteAddress: Nullable<SiteAddress>;
}

const POSLocationsTable = ({
  siteId,
  siteAddress,
}: Props) => {
  const history = useHistory();

  const [locations, setLocations] = useState<Array<TerminalLocation>>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [tableError, setTableError] = useState<Nullable<Error>>(null);

  const [searchText, setSearchText] = useState<string>('');

  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [locationToEdit, setLocationToEdit] = useState<Nullable<TerminalLocation>>(null);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [modalError, setModalError] = useState<Nullable<Error>>(null);

  useEffect(() => {
    if (siteId == null) return;
    setTableError(null);
    setTableLoading(true);
    apiRequest(`sites/${siteId}/terminal/locations`, 'GET')
      .then(({ data }) => {
        setLocations(data);
      })
      .catch((err) => {
        setTableError(err);
      })
      .finally(() => {
        setTableLoading(false);
      });
  }, [siteId]);

  const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleClearSearchClick = () => {
    setSearchText('');
  };

  const handleAddClick = () => {
    setAddModalOpen(true);
  };

  const handleEditClick = (row: GridRowData) => {
    const location = row as TerminalLocation;
    setLocationToEdit(location);
  };

  const handleDeleteClick = async (locationId: string) => {
    setTableLoading(true);
    try {
      if (siteId == null) throw new Error('Missing siteId.');
      await apiRequest(
        `sites/${siteId}/terminal/locations/${locationId}`,
        'DELETE',
      );
      history.go(0);
    } catch (err) {
      setTableError(err as Error);
      setTableLoading(false);
    }
  };

  const handleAddModalSubmitClick = async (
    name: string,
  ) => {
    setModalError(null);
    setModalLoading(true);
    try {
      if (siteId == null) throw new Error('Missing siteId.');
      if (siteAddress == null) throw new Error('Missing Site Address.');
      await apiRequest(
        `sites/${siteId}/terminal/locations`,
        'POST',
        {
          displayName: name,
          address: {
            line1: siteAddress.line1,
            line2: siteAddress.line2,
            city: siteAddress.city,
            postCode: siteAddress.postCode,
            country: siteAddress.country,
          },
        },
      );
      history.go(0);
    } catch (err) {
      setModalError(err as Error);
      setModalLoading(false);
    }
  };

  const handleAddModalCancelClick = () => {
    setModalError(null);
    setAddModalOpen(false);
  };

  const handleEditModalSubmitClick = async (
    locationId: string,
    name: string,
  ) => {
    setModalError(null);
    setModalLoading(true);
    try {
      if (siteId == null) throw new Error('Missing siteId.');
      if (siteAddress == null) throw new Error('Mssing Site Address.');
      await apiRequest(
        `sites/${siteId}/terminal/locations/${locationId}`,
        'PUT',
        {
          displayName: name,
          address: {
            line1: siteAddress.line1,
            line2: siteAddress.line2,
            city: siteAddress.city,
            postCode: siteAddress.postCode,
            country: siteAddress.country,
          },
        },
      );
      history.go(0);
    } catch (err) {
      setModalError(err as Error);
      setModalLoading(false);
    }
  };

  const handleEditModalCancelClick = () => {
    setModalError(null);
    setLocationToEdit(null);
  };

  const handleViewClick = (row: GridRowData) => {
    history.push(`/pos/${row.id}`);
  };

  const columns: GridColumns = useMemo(() => [
    { field: 'displayName', headerName: 'Location name', flex: 2 },
    { field: 'readerCount', headerName: 'Payment devices', flex: 2 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Grid>
          <Tooltip title="View location">
            <IconButton
              color="inherit"
              onClick={() => handleViewClick(params.row)}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit location">
            <IconButton
              onClick={() => handleEditClick(params.row)}
              color="inherit"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Remove location">
            <IconButton
              color="inherit"
              onClick={() => handleDeleteClick(params.row.id)}
              disabled={params.row.readerCount > 0}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ),
    },
  ],
  []);

  return (
    <>
      <DataGrid
        components={{ Toolbar }}
        columns={columns}
        rows={locations}
        loading={tableLoading}
        error={tableError}
        autoHeight
        pageSize={10}
        rowsPerPageOptions={[10]}
        componentsProps={{
          toolbar: {
            value: searchText,
            onSearchTextChange: handleSearchTextChange,
            onSearchTextClear: handleClearSearchClick,
            onButtonClick: handleAddClick,
            buttonTitle: 'Add location',
          },
        }}
      />
      <Dialog
        open={addModalOpen}
      >
        <AddLocationModal
          onCancelClick={handleAddModalCancelClick}
          onSubmitClick={handleAddModalSubmitClick}
          error={modalError}
          setError={setModalError}
          showAddressWarning={siteAddress == null}
          loading={modalLoading}
        />
      </Dialog>
      {locationToEdit && (
        <Dialog
          open={locationToEdit != null}
        >
          <EditLocationModal
            location={locationToEdit}
            onSubmitClick={handleEditModalSubmitClick}
            onCancelCick={handleEditModalCancelClick}
            error={modalError}
            setError={setModalError}
            showAddressWarning={siteAddress == null}
            loading={modalLoading}
          />
        </Dialog>
      )}
    </>
  );
};

export default POSLocationsTable;
