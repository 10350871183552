import * as Joi from 'joi';

import VatRate from '../types/VatRate';

const isVATRate = (value: unknown): value is VatRate => {
  const schema = Joi.object({
    label: Joi.string().required(),
    value: Joi.number().required(),
  });

  const joiResult = schema.validate(value);
  return joiResult.error == null;
};

export default isVATRate;
