/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useEffect, useMemo, useState, useCallback,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { DateTime } from 'luxon';

import MaterialTable, { MTableToolbar } from 'material-table';
import {
  Grid,
  makeStyles,
  Typography,
  Chip,
} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';

import Labels from '../../../components/Labels/Labels';
import Spinner from '../../../components/spinner/spinner';

import useUrlParams from '../../../hooks/useUrlParams';
import formatMoney from '../../../helpers/formatMoney';
import downloadPayoutsReport from '../helpers/downloadPayoutsReport';
import getPayoutsReport from '../helpers/getPayoutsReport';

import Options from '../types/Options';
import Query from '../../../types/Query';
import SiteData from '../../../types/SiteData';
import TableElement from '../types/TableElement';
import User from '../../../types/User';
import Nullable from '../../../types/Nullable';
import ToolbarProps from '../types/ToolbarProps';

type Props = {
  user: Nullable<User>
  siteData: Nullable<SiteData>
  options: Options
};

const useStyles = makeStyles(() => ({
  treeTable: {
    margin: 10,
  },
}));

const PayoutsReportSection = ({
  user, siteData, options,
}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const [fetchedData, setFetchedData] = useState<boolean>(false);
  const classes = useStyles();
  const tableRef = React.createRef<TableElement>();

  const {
    values: [page, search],
    setters: [onPageChange, onSearchChange],
  } = useUrlParams(['payoutsPage', 'payoutsSearch']);

  useEffect(() => {
    if (page == null || search == null) {
      history.replace(
        `${location.pathname}${location.search.length > 0 ? `${location.search}&` : '?'}payoutsPage=0&payoutsSearch=`,
      );
    }
  }, []);

  const site = useMemo(() => {
    if (!user) return '';
    return user.site;
  }, [user]);

  const siteLabels = useMemo(() => {
    if (!siteData) return [];
    if (!siteData.reportingLabels) return [];
    return siteData.reportingLabels;
  }, [siteData]);

  const activeReportingLabels = useMemo(() => options.reportingLabels, [siteLabels, options]);

  const handleDownload = useCallback(async (searchText: string) => {
    const { data } = await getPayoutsReport(
      site, options, { search: searchText }, user?.id, activeReportingLabels,
    );

    return downloadPayoutsReport(data);
  }, [site, options, user]);

  const refreshData = useCallback(() => {
    if (tableRef.current != null) {
      tableRef.current.onQueryChange({ options });
    }
  }, [tableRef, options]);

  const getData = useCallback(async (query: Query) => {
    setErrorMessage(null);
    try {
      const queryOptions = { ...query };
      if (!fetchedData && page != null && search != null) {
        queryOptions.page = Number(page);
        queryOptions.search = search;
      }
      const data = await getPayoutsReport(
        site, options, queryOptions, user?.id, activeReportingLabels,
      );
      if (fetchedData) {
        if ((query.search || '') !== (search || '')) {
          onSearchChange(query.search || '');
        }
        if ((query.page || 0) !== Number(page || 0)) {
          onPageChange(String(query.page || 0));
        }
      }
      setFetchedData(true);
      return data;
    } catch (error) {
      const { message } = error as Error;
      setErrorMessage(message || 'Something went wrong getting Payouts Report');
      return {
        data: [],
        page: 0,
        totalCount: 0,
      };
    }
  }, [site, options, user, page, search, fetchedData]);

  if (page == null || search == null) return <Spinner />;

  return (
    <Grid>
      {options && errorMessage === null && (
        <MaterialTable
          title="Payouts Report"
          tableRef={tableRef}
          columns={[
            {
              title: 'Payout Date',
              field: 'payoutDate',
              render: (rowData) => DateTime
                .fromISO(rowData.payoutDate)
                .setLocale('en-gb')
                .toLocaleString(),
            },
            { title: 'Your Payout', field: 'totalPayout', render: (rowData) => formatMoney(rowData.totalPayout, siteData?.currency) },
          ]}
          data={getData}
          actions={[
            {
              icon: 'refresh',
              tooltip: 'Refresh Data',
              isFreeAction: true,
              onClick: refreshData,
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            pageSize: 10,
            searchText: search,
            debounceInterval: 1000,
            sorting: false,
          }}
          components={{
            Toolbar: (props: ToolbarProps) => (
              <div>
                <MTableToolbar {...props} />
                <div style={{ padding: 10 }}>
                  <Chip
                    style={{ marginRight: 10 }}
                    icon={<DownloadIcon />}
                    label="Download Payouts Report"
                    onClick={() => handleDownload(props.searchText)}
                    color="primary"
                  />
                </div>
              </div>
            ),
          }}
          detailPanel={(rowData) => (
            <Grid className={classes.treeTable}>
              <MaterialTable
                title="Items"
                columns={[
                  { title: 'Order Number', field: 'orderNumber' },
                  {
                    title: 'Time',
                    field: 'orderTime',
                    render: (data) => DateTime
                      .fromISO(data.orderTime)
                      .setLocale('en-gb')
                      .toLocaleString(DateTime.DATETIME_SHORT),
                  },
                  {
                    title: 'Trading Date',
                    field: 'tradingDate',
                    render: (data) => DateTime
                      .fromISO(data.tradingDate)
                      .setLocale('en-gb')
                      .toLocaleString(),
                  },
                  { title: 'Payout', field: 'payout', render: (data) => formatMoney(data.totalPayout) },
                  {
                    title: 'Label',
                    field: 'reportingLabels',
                    render: (data) => (data.reportingLabels == null ? '' : <Labels labels={data.reportingLabels} />),
                  },
                ]}
                data={rowData.items}
                options={{
                  search: false,
                }}
              />
            </Grid>
          )}
        />
      )}
      {errorMessage && (
        <Typography align="center" color="error">{errorMessage}</Typography>
      )}
    </Grid>
  );
};

export default PayoutsReportSection;
