import { Map } from 'immutable';
import {
  GET_SITE_ORDERS_START,
  GET_SITE_ORDERS_SUCCESS,
  GET_SITE_ORDERS_ERROR,
} from '../constants/actions';
import STORE_KEYS from '../constants/store-keys';

const {
  IS_SITE_ORDERS_LOADING,
  NUMBER_OF_ORDERS,
  NUMBER_OF_ORDERS_TODAY,
  UNPROCESSED_ORDERS_TODAY,
  UNPROCESSED_ORDERS,
  AMOUNT_OF_SALES,
  AMOUNT_OF_SALES_TODAY,
  TRACK_TRACE_INFO,
  SITE_ORDERS,
  SITE_ORDERS_LOADING_ERROR,
} = STORE_KEYS.SITE_ORDERS;

const initialState = {};
initialState[IS_SITE_ORDERS_LOADING] = false;
initialState[NUMBER_OF_ORDERS] = 0;
initialState[NUMBER_OF_ORDERS_TODAY] = 0;
initialState[UNPROCESSED_ORDERS] = 0;
initialState[UNPROCESSED_ORDERS_TODAY] = 0;
initialState[AMOUNT_OF_SALES] = 0;
initialState[AMOUNT_OF_SALES_TODAY] = 0;
initialState[TRACK_TRACE_INFO] = [];
initialState[SITE_ORDERS] = [];
initialState[SITE_ORDERS_LOADING_ERROR] = '';
const initialStateMap = Map(initialState);

export default (state = initialStateMap, action) => {
  switch (action.type) {
    case GET_SITE_ORDERS_START:
      return state
        .set(IS_SITE_ORDERS_LOADING, true)
        .set(NUMBER_OF_ORDERS, 0)
        .set(NUMBER_OF_ORDERS_TODAY, 0)
        .set(UNPROCESSED_ORDERS, 0)
        .set(UNPROCESSED_ORDERS_TODAY, 0)
        .set(AMOUNT_OF_SALES, 0)
        .set(AMOUNT_OF_SALES_TODAY, 0)
        .set(TRACK_TRACE_INFO, [])
        .set(SITE_ORDERS, [])
        .set(SITE_ORDERS_LOADING_ERROR, '');
    case GET_SITE_ORDERS_SUCCESS:
      return state
        .set(IS_SITE_ORDERS_LOADING, false)
        .set(NUMBER_OF_ORDERS, action.numberOfOrders)
        .set(NUMBER_OF_ORDERS_TODAY, action.numberOfOrdersToday)
        .set(UNPROCESSED_ORDERS, action.unprocessedOrders)
        .set(UNPROCESSED_ORDERS_TODAY, action.unprocessedOrdersToday)
        .set(AMOUNT_OF_SALES, action.amountOfSales)
        .set(AMOUNT_OF_SALES_TODAY, action.amountOfSalesToday)
        .set(TRACK_TRACE_INFO, action.trackAndTraceInfo)
        .set(SITE_ORDERS, action.siteOrders);
    case GET_SITE_ORDERS_ERROR:
      return state
        .set(IS_SITE_ORDERS_LOADING, false)
        .set(SITE_ORDERS_LOADING_ERROR, action.message);
    default:
      return state;
  }
};
