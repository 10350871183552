import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import MaterialTable from 'material-table';
import { Grid } from '@material-ui/core';

import RemoveItemDialog from '../../../components/RemoveItemDialog/RemoveItemDialog';

import hasPermission from '../../../helpers/has-permission';

import AppUrl from '../../../types/AppUrl';
import SiteSlotTime from '../../../types/SiteSlotTime';
import User from '../../../types/User';
import RoleRestrictedAction from '../../../types/RoleRestrictedAction';
import Nullable from '../../../types/Nullable';

type Props = {
  slotDays: SiteSlotTime[],
  onCreate: () => void,
  onRemove: (day: string) => void,
  user: User,
};

const SlotsTable = ({
  slotDays, onCreate, onRemove, user,
}: Props) => {
  const [itemToRemove, setItemToRemove] = useState<Nullable<SiteSlotTime>>(null);
  const history = useHistory();

  const handleRemove = useCallback(() => {
    if (itemToRemove && itemToRemove.day) {
      onRemove(itemToRemove.day);
    }
  }, [onRemove, itemToRemove]);

  const openRemoveDialog = useCallback(setItemToRemove, [setItemToRemove]);

  const closeRemoveDialog = useCallback(() => setItemToRemove(null), [setItemToRemove]);

  const description = useMemo(() => (itemToRemove
    ? `Are you sure you want to remove ${itemToRemove.day}`
    : ''), [itemToRemove]);

  return (
    <Grid>
      <MaterialTable
        title="Slot Days"
        columns={[
          { title: 'Day', field: 'day' },
          { title: 'All Day Slot', field: 'allDaySlot', render: (rowData) => (rowData.allDaySlot ? 'Yes' : 'No') },
        ]}
        actions={[
          {
            icon: 'add',
            tooltip: 'Create Slot Day',
            isFreeAction: true,
            disabled: !hasPermission(RoleRestrictedAction.CreateSlotDay, user),
            onClick: onCreate,
          },
          (rowData) => ({
            icon: 'visibility',
            tooltip: 'View Slot',
            onClick: () => history.push(AppUrl.SlotTimes.replace(':day', rowData.day)),
          }),
          (rowData) => ({
            icon: 'delete',
            tooltip: 'Remove Day',
            disabled: !hasPermission(RoleRestrictedAction.RemoveSlotDay, user),
            onClick: () => openRemoveDialog(rowData),
          }),
        ]}
        data={slotDays}
        options={{
          actionsColumnIndex: -1,
          pageSize: slotDays.length > 5 ? 10 : 5,
        }}
      />
      <RemoveItemDialog
        title="Remove Slot Time"
        description={description}
        open={itemToRemove != null}
        onRemove={handleRemove}
        handleClose={closeRemoveDialog}
      />
    </Grid>
  );
};

export default SlotsTable;
