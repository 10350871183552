import request from '../../../helpers/request';
import getQueryString from './getQueryString';

import Options from '../types/Options';
import SalesReportsResult from '../types/SalesGraphsResult';

const getSalesGraphs = async (
  site: string, options: Options, userId?: string, reportingLabels?: string[],
): Promise<SalesReportsResult> => {
  const endpoint = getQueryString('salesGraphs', site, options, userId, undefined, reportingLabels);

  const result = await request(endpoint, 'GET');
  return result;
};

export default getSalesGraphs;
