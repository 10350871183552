import React, { useState } from 'react';

import {
  Avatar,
  Button, Grid, makeStyles, Typography,
} from '@material-ui/core';

import Nullable from '../../types/Nullable';

const useStyles = makeStyles({
  imageForm: {
    marginBottom: 20,
  },
  imageUpload: {
    display: 'none',
  },
  buttonGroup: {
    marginTop: 20,
    marginBottom: 20,
  },
  removeButton: {
    marginLeft: 10,
  },
});

type Props = {
  buttonLabel: string
  onChange: (file: File, preview: string) => void
  onRemove: () => void
  currentImage: Nullable<string> | undefined
  fallbackImage?: string
  description?: string
  description2?: string
  id?: string
  previewGridStyles: string
  previewStyles: string
};

const ImageUpload = ({
  buttonLabel,
  onChange,
  onRemove,
  currentImage,
  fallbackImage = '',
  description,
  description2,
  id,
  previewGridStyles,
  previewStyles,
}: Props) => {
  const classes = useStyles();
  const [imagePreviewUrl, setImagePreviewUrl] = useState<Nullable<string>>(
    currentImage || fallbackImage,
  );

  const handleChange = (event: React.ChangeEvent) => {
    const reader = new FileReader();
    const target = event.target as HTMLInputElement;
    if (target.files != null) {
      const file: File = target.files[0];
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          setImagePreviewUrl(reader.result);
          onChange(file, reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemove = () => {
    setImagePreviewUrl(fallbackImage);
    onRemove();
  };

  return (
    <Grid className={classes.imageForm}>
      {imagePreviewUrl && imagePreviewUrl.length !== 0 && (
        <Grid className={previewGridStyles}>
          <Avatar variant="rounded" src={imagePreviewUrl} className={previewStyles} />
        </Grid>
      )}
      <Grid className={classes.buttonGroup}>
        <label htmlFor={`${id != null ? `outlined-button-${id}` : 'outlined-button-file'}`}>
          <Button color="primary" variant="outlined" component="span">
            {buttonLabel}
          </Button>
          <input
            accept="image/*"
            id={`${id != null ? `outlined-button-${id}` : 'outlined-button-file'}`}
            className={classes.imageUpload}
            type="file"
            onChange={handleChange}
          />
        </label>
        {fallbackImage !== imagePreviewUrl ? (
          <Button
            className={classes.removeButton}
            variant="outlined"
            color="secondary"
            onClick={handleRemove}
          >
            Remove Image
          </Button>
        ) : null}
      </Grid>
      <Typography>{description}</Typography>
      <Typography>{description2}</Typography>
    </Grid>
  );
};

export default ImageUpload;
