import firebase from 'firebase/app';

import NotificationData from '../types/NotificationData';

const clearAllNotifications = (
  notifications: NotificationData[],
) => Promise.all(notifications.map((notification) => firebase
  .firestore()
  .collection('notifications')
  .doc(notification.id)
  .update({ active: false })));

export default clearAllNotifications;
