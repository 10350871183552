import React from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

import Choice from '../../../types/Choice';
import ProductData from '../../../types/ProductData';

type Props = {
  options: ProductData[]
  changeOption: (options: Choice[]) => void
  defaultValues?: ProductData[]
  className?: string;
  helperText?: string
};

const MultipleSelect = ({
  defaultValues,
  options,
  changeOption,
  className,
  helperText,
}: Props) => {
  const handleChange = (selectedOptions: ProductData[]) => {
    const organisedOptions = selectedOptions.map((option) => ({
      plu: option.plu,
      displayName: option.addonDescription || option.name,
    }));
    changeOption(organisedOptions);
  };

  return (
    <Autocomplete
      className={className}
      multiple
      id="multiple-select"
      options={options}
      defaultValue={defaultValues || []}
      getOptionLabel={(option) => (option ? `${option.name || option.addonDescription} (${option.plu})` : '')}
      onChange={(e, newValue) => handleChange(newValue || [])}
      filterSelectedOptions
      style={className == null ? { width: 350, marginBottom: 20 } : {}}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          variant="outlined"
          label="Choices"
          helperText={helperText}
        />
      )}
    />
  );
};

export default MultipleSelect;
