import React, { useState, ChangeEvent } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  TextField,
  DialogActions,
  Button,
  makeStyles,
  FormHelperText,
  Divider,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import isCountry from '../../helpers/isCountry';
import isEmailValid from '../../helpers/isEmailValid';
import AccountsInfo from '../../types/AccountsInfo';
import Country from '../../types/Country';
import never from '../../helpers/never';

interface Props {
  open: boolean;
  loading?: boolean;
  error?: boolean;
  onFormSubmit: (formValues: AccountsInfo & { country: Country }) => void;
  onCloseClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  dialogContent: { display: 'flex', flexDirection: 'column' },
  textField: { margin: theme.spacing(1, 0) },
  radioGroupFormControl: { margin: theme.spacing(1, 0, 0, 0) },
  divider: { margin: theme.spacing(2, 0) },
  addressForm: { display: 'flex', flexDirection: 'column' },
}));

const AccountsInfoDialog = ({
  open,
  loading,
  error,
  onFormSubmit,
  onCloseClick,
}: Props) => {
  const classes = useStyles();

  const [country, setCountry] = useState<Country>(Country.GB);
  const [companyName, setCompanyName] = useState<string>('');
  const [accountsEmail, setAccountsEmail] = useState<string>('');
  const [
    companyAddress,
    setCompanyAddress,
  ] = useState<AccountsInfo['companyAddress'] & { addressLine2: string }>({
    addressLine1: '',
    addressLine2: '',
    city: '',
    postalCode: '',
    country: 'Great Britain',
  });
  const [addressEdited, setAddressEdited] = useState<boolean>(false);
  const [isAddressFormFocused, setIsAddressFormFocused] = useState<boolean>(false);

  const handleCountryChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    if (isCountry(value)) {
      setCountry(value);

      if (addressEdited) return;
      let addressCountry: string;
      switch (value) {
        case Country.GB:
          addressCountry = 'Great Britain';
          break;
        case Country.ES:
          addressCountry = 'Spain';
          break;
        default:
          never(value);
      }
      setCompanyAddress((currentValue) => ({ ...currentValue, country: addressCountry }));
    }
  };

  const handleCompanyNameChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setCompanyName(value);
  };

  const addressChangeHandlerFactory = (
    property: keyof AccountsInfo['companyAddress'],
  ) => (
    { target: { value } }: ChangeEvent<HTMLInputElement>,
  ) => {
    setCompanyAddress((currentValue) => ({ ...currentValue, [property]: value }));
  };

  const handleAccountsEmailChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setAccountsEmail(value);
  };

  const handleAddressFormFocus = () => {
    setIsAddressFormFocused(true);
    setAddressEdited(true);
  };

  const handleAddressFormBlur = () => setIsAddressFormFocused(false);

  const handleGoToStripeClick = () => {
    const address: AccountsInfo['companyAddress'] = {
      addressLine1: companyAddress.addressLine1,
      city: companyAddress.city,
      country: companyAddress.country,
      postalCode: companyAddress.postalCode,
    };
    if (companyAddress.addressLine2.length > 0) {
      address.addressLine2 = companyAddress.addressLine2;
    }

    onFormSubmit({
      country,
      companyName,
      companyAddress: address,
      accountsEmail,
    });
  };

  const isFormValid = (
    isEmailValid(accountsEmail)
    && isCountry(country)
    && companyName.length > 0
    && companyAddress.addressLine1.length > 0
    && companyAddress.city.length > 0
    && companyAddress.country.length > 0
    && companyAddress.postalCode.length > 0
  );

  return (
    <Dialog open={open} maxWidth="sm" fullWidth PaperProps={{ id: 'accounts-form-dialog' }}>
      {loading && <LinearProgress />}
      <DialogTitle>Accounts Information</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          className={classes.textField}
          value={companyName}
          onChange={handleCompanyNameChange}
          label="Company Name"
          variant="outlined"
        />
        <TextField
          className={classes.textField}
          value={accountsEmail}
          onChange={handleAccountsEmailChange}
          label="Accounts Email Address"
          variant="outlined"
        />
        <FormControl className={classes.radioGroupFormControl}>
          <FormLabel>Country</FormLabel>
          <RadioGroup row value={country} onChange={handleCountryChange}>
            <FormControlLabel value={Country.GB} control={<Radio />} label="Great Britain" />
            <FormControlLabel value={Country.ES} control={<Radio />} label="España" />
          </RadioGroup>
          <FormHelperText>This is the country you will be accepting payments in.</FormHelperText>
        </FormControl>
        <Divider className={classes.divider} />
        <form
          className={classes.addressForm}
          onFocus={handleAddressFormFocus}
          onBlur={handleAddressFormBlur}
        >
          <FormLabel focused={isAddressFormFocused}>
            Business Address
          </FormLabel>
          <TextField
            className={classes.textField}
            value={companyAddress.addressLine1}
            onChange={addressChangeHandlerFactory('addressLine1')}
            label="Line 1"
            required
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            value={companyAddress.addressLine2}
            onChange={addressChangeHandlerFactory('addressLine2')}
            label="Line 2"
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            value={companyAddress.city}
            onChange={addressChangeHandlerFactory('city')}
            label="City"
            required
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            value={companyAddress.country}
            onChange={addressChangeHandlerFactory('country')}
            label="Country"
            required
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            value={companyAddress.postalCode}
            onChange={addressChangeHandlerFactory('postalCode')}
            label="Postal Code"
            required
            variant="outlined"
          />
        </form>
        {error && (
          <Typography color="error">
            Error. Please try again, and contact support if this problem persists.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseClick} variant="outlined">Close</Button>
        <Button
          onClick={handleGoToStripeClick}
          variant="outlined"
          disabled={!isFormValid}
        >
          Go to Stripe
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AccountsInfoDialog;
