import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box, IconButton, Typography, withStyles,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const styles = () => ({
  field: {
    marginBottom: 5,
  },
});

const KeyField = ({
  classes,
  title,
  info,
  normalWeightTitleFont,
}) => {
  const [showKey, setShowKey] = useState(false);
  return (
    <div className={classes.field}>
      <Typography component="div" display="inline">
        <Box
          display="inline"
          fontWeight={normalWeightTitleFont ? 'fontWeightNormal' : 'fontWeightBold'}
        >
          {`${title}:`}
        </Box>
      </Typography>
      {' '}
      <Typography component="div" display="inline">
        {showKey ? info : '*'.repeat(info.length)}
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => setShowKey(!showKey)}
        >
          {showKey ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton>
      </Typography>
    </div>
  );
};

KeyField.propTypes = {
  classes: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
  info: PropTypes.string,
  normalWeightTitleFont: PropTypes.bool,
};

KeyField.defaultProps = {
  info: '',
  normalWeightTitleFont: false,
};

export default withStyles(styles)(KeyField);
