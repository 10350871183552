import firebase from 'firebase';
import Label from '../../../types/Label';
import SiteData from '../../../types/SiteData';

const saveUserLabel = (site: SiteData, label: Label) => {
  let userLabels = [label];
  if (site.userLabels) {
    userLabels = userLabels.concat(site.userLabels);
  }
  return firebase.firestore()
    .collection('sites')
    .doc(site.id)
    .update({
      userLabels,
    });
};

export default saveUserLabel;
