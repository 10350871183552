import Papa from 'papaparse';

import downloadFile from '../../../helpers/downloadFile';

import ProductCsv from '../../../types/ProductCsv';

const downloadProducts = (productsData: ProductCsv[], fileName: string) => {
  const data = Papa.unparse(productsData);

  return downloadFile({
    data,
    fileName,
    fileType: 'text/csv',
  });
};

export default downloadProducts;
