import convertTimestampToUtc from '../../../helpers/convertTimestampToUtc';
import DateType from '../types/DateType';
import Options from '../types/Options';
import Query from '../../../types/Query';

const getQueryString = (
  endpoint: string,
  site: string,
  options?: Options,
  userId?: string,
  query?: Query,
  reportingLabels?: string[],
) => {
  let queryString = `${endpoint}?site=${encodeURIComponent(site)}`;

  if (options && options.type === DateType.TradingDate) {
    queryString += `&tradingDateFrom=${options.start}&tradingDateTo=${options.end}`;
  }

  if (options && options.type === DateType.TransactionDate) {
    queryString += `&transactionDateFrom=${convertTimestampToUtc(options.start)}&transactionDateTo=${convertTimestampToUtc(options.end)}`;
  }

  if (userId) {
    queryString += `&userId=${userId}`;
  }

  if (query && query.pageSize != null && query.page != null) {
    queryString += `&pageSize=${query.pageSize}&page=${query.page}`;
  }

  if (query && query.search && query.search !== '') {
    queryString += `&search=${encodeURIComponent(query.search)}`;
  }

  if (reportingLabels && reportingLabels.length !== 0) {
    reportingLabels.forEach((label) => {
      queryString += `&reportingLabels[]=${label}`;
    });
  }

  if (query
    && query.orderBy != null
    && query.orderBy.field != null
    && query.orderDirection != null) {
    queryString += `&orderBy=${query.orderBy.field}&orderDirection=${query.orderDirection}`;
  }

  return queryString;
};

export default getQueryString;
