import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Dialog, DialogTitle, DialogContent, DialogActions, Typography,
} from '@material-ui/core';

import OutlinedTextField from '../../../components/outlined-text-field/outlined-text-field';
import OutlinedButton from '../../../components/outlined-button/outlined-button';

const SlotTimesDialog = ({
  open, onSave, handleClose, errorMessage,
}) => {
  const [slotDay, setSlotDay] = useState('');

  const onClose = () => {
    setSlotDay('');
    handleClose();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Create a Slot Day</DialogTitle>
      <DialogContent>
        <OutlinedTextField
          id="outlined-slot-day"
          classOption="flex"
          label="Slot Day"
          value={slotDay}
          onChange={(event) => setSlotDay(event.target.value)}
          helperText="For example 2021-03-24"
          required
        />
        {errorMessage ? <Typography>{errorMessage}</Typography> : null}
      </DialogContent>
      <DialogActions>
        <OutlinedButton
          label="Cancel"
          color="secondary"
          onClick={handleClose}
        />
        <OutlinedButton
          label="Save"
          color="primary"
          onClick={() => onSave(slotDay)}
          disabled={slotDay.length === 0}
        />
      </DialogActions>
    </Dialog>
  );
};

SlotTimesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

SlotTimesDialog.defaultProps = {
  errorMessage: null,
};

export default SlotTimesDialog;
