export default (urlEndpoint) => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return `https://dev.peazi.app/${urlEndpoint}`;
    case 'staging':
      return `https://stage.peazi.app/${urlEndpoint}`;
    default:
      return `https://peazi.app/${urlEndpoint}`;
  }
};
