import { DateTime } from 'luxon';
import Options from '../types/Options';
import DateType from '../types/DateType';

const getSearchPeriod = (
  type: Options['type'],
  start: Options['start'],
  end: Options['end'],
) => {
  if (type === DateType.TradingDate) {
    const tradingStart = DateTime
      .fromISO(start)
      .toLocaleString(DateTime.DATE_SHORT);
    const tradingEnd = DateTime
      .fromISO(end)
      .toLocaleString(DateTime.DATE_SHORT);
    return `${tradingStart},to,${tradingEnd}`;
  }
  const transactionStart = DateTime
    .fromISO(start)
    .toLocaleString(DateTime.DATETIME_SHORT)
    .replace(',', '');
  const transactionEnd = DateTime
    .fromISO(end)
    .toLocaleString(DateTime.DATETIME_SHORT)
    .replace(',', '');
  return `${transactionStart},to,${transactionEnd}`;
};

export default getSearchPeriod;
