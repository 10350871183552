import Label from '../../../types/Label';

const getLabelText = (productLabels: string[], siteLabels: Label[]) => {
  if (!productLabels || !siteLabels) return '';
  return productLabels
    .map((label) => {
      const foundLabel = siteLabels.find((lab) => lab.id === label);
      return foundLabel ? foundLabel.text : '';
    }).join(';');
};

export default getLabelText;
