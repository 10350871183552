import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Typography, Box } from '@material-ui/core';

import getSiteMap from '../../actions/get-site-map';
import getProducts from '../../actions/get-products';

import Spinner from '../../components/spinner/spinner';
import ProductForm from '../../components/product-form/product-form';

import changeSiteMap from '../../helpers/change-site-map';
import addProduct from '../../helpers/add-product';

import Nullable from '../../types/Nullable';
import ReduxState from '../../types/ReduxState';
import User from '../../types/User';
import ProductData from '../../types/ProductData';
import SiteMapCategory from '../../types/SiteMapCategory';

const AddProductPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const authenticatedUser = useSelector<ReduxState, Nullable<User>>(
    (state) => state.authentication.get('USER'),
  );
  const siteMap = useSelector<ReduxState, SiteMapCategory[]>(
    (state) => state.siteMap.get('SITE_MAP'),
  );
  const siteMapId = useSelector<ReduxState, string>(
    (state) => state.siteMap.get('SITE_MAP_ID'),
  );
  const isSiteMapLoading = useSelector<ReduxState, boolean>(
    (state) => state.siteMap.get('IS_SITE_MAP_LOADING'),
  );
  const products = useSelector<ReduxState, ProductData[]>(
    (state) => state.products.get('PRODUCTS'),
  );
  const isProductsLoading = useSelector<ReduxState, boolean>(
    (state) => state.products.get('IS_PRODUCTS_LOADING'),
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Nullable<string>>(null);

  useEffect(() => {
    if (authenticatedUser != null) {
      const { site: currentSite } = authenticatedUser;
      dispatch(getSiteMap(currentSite));
      dispatch(getProducts(currentSite));
    }
  }, [authenticatedUser]);

  const onSave = useCallback(async (
    product: ProductData,
    newSiteMap: SiteMapCategory[],
    productId: string,
    image: Nullable<string>,
  ) => {
    setLoading(true);
    setError(null);

    try {
      if (authenticatedUser == null) throw new Error('Missing user data.');
      await addProduct({ ...product, site: authenticatedUser.site }, image);
      if (newSiteMap.length !== 0) {
        await changeSiteMap(siteMapId, { site: authenticatedUser.site, products: newSiteMap });
      }
      history.push('/products');
    } catch (saveError) {
      const { message: errorMessage } = saveError as Error;
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  }, [authenticatedUser, history, siteMapId]);

  const onCancel = useCallback(() => history.push('/products'), [history]);

  if (loading || isSiteMapLoading || isProductsLoading) return <Spinner />;

  return (
    <Box>
      <ProductForm siteMap={siteMap} products={products} onSave={onSave} onCancel={onCancel} />
      {error && <Typography variant="body1" color="error">{error}</Typography>}
    </Box>
  );
};

export default AddProductPage;
