import React,
{
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  Typography,
  Box,
  Button,
  makeStyles,
} from '@material-ui/core';

import getProducts from '../../actions/get-products';
import getSiteMap from '../../actions/get-site-map';

import ProductForm from '../../components/product-form/product-form';
import Spinner from '../../components/spinner/spinner';

import updateProduct from '../../helpers/update-product';
import changeSiteMap from '../../helpers/change-site-map';
import deleteImage from '../../helpers/deleteImage';

import { VIEW_PRODUCT } from '../../constants/urls';

import Nullable from '../../types/Nullable';
import ReduxState from '../../types/ReduxState';
import User from '../../types/User';
import ProductData from '../../types/ProductData';
import SiteMapCategory from '../../types/SiteMapCategory';

const useStyles = makeStyles({
  missingProductContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  missingProductText: {
    margin: '0.5em 0',
  },
});

const EditProductPage = () => {
  const history = useHistory();
  const params = useParams<{ productId: string }>();
  const classes = useStyles();

  const dispatch = useDispatch();

  const authenticatedUser = useSelector<ReduxState, Nullable<User>>(
    (state) => state.authentication.get('USER'),
  );
  const isProductsLoading = useSelector<ReduxState, boolean>(
    (state) => state.products.get('IS_PRODUCTS_LOADING'),
  );
  const products = useSelector<ReduxState, ProductData[]>(
    (state) => state.products.get('PRODS'),
  );
  const isSiteMapLoading = useSelector<ReduxState, boolean>(
    (state) => state.siteMap.get('IS_SITE_MAP_LOADING'),
  );
  const siteMap = useSelector<ReduxState, SiteMapCategory[]>(
    (state) => state.siteMap.get('SITE_MAP'),
  );
  const siteMapId = useSelector<ReduxState, string>(
    (state) => state.siteMap.get('SITE_MAP_ID'),
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (authenticatedUser != null) {
      dispatch(getProducts(authenticatedUser.site));
      dispatch(getSiteMap(authenticatedUser.site));
    }
  }, [authenticatedUser]);

  const product = useMemo(
    () => products.find(({ plu }) => plu === Number(params.productId)) || null,
    [params, products],
  );

  const onSave = useCallback(
    async (
      newProduct: ProductData,
      newSiteMap: SiteMapCategory[],
      productId: string,
      image: Nullable<string>,
    ) => {
      setLoading(true);
      setErrors([]);

      try {
        if (product?.image != null
          && newProduct?.image == null
          && (product?.image || '').includes('firebasestorage')) {
          await deleteImage(product.image);
        }
        await updateProduct(productId, newProduct, image);
      } catch {
        setErrors((value) => [...value, 'Product data could not be updated']);
      }
      try {
        if (newSiteMap.length > 0) {
          if (authenticatedUser == null) throw new Error('Missing user data');
          await changeSiteMap(siteMapId, { site: authenticatedUser.site, products: newSiteMap });
        }
      } catch {
        setErrors((value) => [...value, 'Site Map data could not be updated']);
      }
      if (errors.length === 0) history.push(VIEW_PRODUCT.replace(':productId', String(newProduct.plu)));
    },
    [history, siteMapId, product],
  );

  const onCancel = useCallback(
    () => history.push(VIEW_PRODUCT.replace(':productId', String(product?.plu))),
    [history],
  );

  if (isProductsLoading || isSiteMapLoading || loading) return <Spinner />;

  if (product == null) {
    return (
      <Box className={classes.missingProductContainer}>
        <Typography
          className={classes.missingProductText}
          variant="h6"
        >
          {`Product ${params.productId} could not be found.`}
        </Typography>
        <Button
          href="/products"
          variant="contained"
          color="primary"
        >
          Return to Products
        </Button>
      </Box>
    );
  }

  return (
    <Box>
      <ProductForm
        selectedProduct={product}
        products={products}
        siteMap={siteMap}
        onSave={onSave}
        onCancel={onCancel}
      />
      {errors.length > 0
        ? <Typography variant="body1" color="error">{`Errors: ${errors.join(', ')}.`}</Typography>
        : null}
    </Box>
  );
};

export default EditProductPage;
