import downloadFile from '../../../helpers/downloadFile';
import CommissionReportData from '../../../types/CommissionReportData';
import DateType from '../types/DateType';
import SiteData from '../../../types/SiteData';
import Nullable from '../../../types/Nullable';

const downloadCommissionReport = (
  commissionData: CommissionReportData[],
  companyName: Nullable<string>,
  siteVatNo: SiteData['vatNumber'],
  periodType: DateType,
  period: string,
) => {
  const invoiceDetails = [
    `Business name:,${companyName}`,
    `VAT Number:,${siteVatNo || 'n/a'}`,
    `${periodType === DateType.TradingDate ? 'Trading' : 'Transaction'} Period:,${period}`,
  ];
  const commissionReport: string[] = [];
  commissionData.forEach(({
    sourceName,
    destinationName,
    grossSales,
    netSales,
    netCommission,
    vat,
    grossCommission,
    amountTransferred,
    amountDue,
    commissionDetails,
  }) => {
    commissionDetails.forEach(({
      paymentMethod,
      netCommission: detailedNetCommission,
      vat: detailedVat,
      grossCommission: detailedGrossCommission,
    }) => {
      const paymentMethodDetails = [
        sourceName,
        '',
        paymentMethod,
        '',
        '',
        detailedNetCommission.toFixed(2),
        detailedVat.toFixed(2),
        detailedGrossCommission.toFixed(2),
      ].join(',');
      commissionReport.push(paymentMethodDetails);
    });
    const labelReport = [
      sourceName,
      destinationName,
      `${sourceName} TOTAL`,
      grossSales.toFixed(2),
      netSales.toFixed(2),
      netCommission.toFixed(2),
      vat.toFixed(2),
      grossCommission.toFixed(2),
      amountTransferred.toFixed(2),
      amountDue.toFixed(2),
    ].join(',');

    commissionReport.push(labelReport);
  });
  return downloadFile({
    data: [
      ...invoiceDetails,
      '',
      'Source,Destination,Name,Gross Sales (£),Net Sales (£),Net Commission (£),VAT (£),Gross Commission (£),Commission Paid (£),Commission Due (£)',
      ...commissionReport,
    ].join('\n'),
    fileName: `Commission Report ${commissionData.length === 1 ? `${commissionData[0].sourceName}_` : ''}${period.replace(',to,', ' to ')}.csv`,
    fileType: 'text/csv',
  });
};

export default downloadCommissionReport;
