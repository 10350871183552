import ProductData from '../types/ProductData';
import Choice from '../types/Choice';
import ProductOption from '../types/ProductOption';

const filterProductsByAddon = (plu: Number, products: ProductData[]) => (
  products.filter((product) => {
    if (product.options) {
      return product.options.some((option: ProductOption) => (
        option.choices.some((choice: Choice) => choice.plu === plu)
      ));
    }
    return false;
  })
);

export default filterProductsByAddon;
