import * as Joi from 'joi';

const isEmailValid = (email: string) => {
  const schema = Joi.string().email({ tlds: false }).required();

  const joiResult = schema.validate(email);
  return joiResult.error == null;
};

export default isEmailValid;
