import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Grid, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';

import GiftCardsTable from './components/GiftCardsTable';
import GiftCardProducts from './components/GiftCardProducts';
import GiftCardsOptions from './components/GiftCardsOptions';
import GiftCardsImportForm from './components/GiftCardsImportForm';
import Spinner from '../../components/spinner/spinner';
import GiftCardAuditTrailReport from '../../components/GiftCardAuditTrailReport/GiftCardAuditTrailReport';

import initialiseImportJobsListener from '../../helpers/initialiseImportJobsListener';

import getSettings from '../../actions/get-settings';

import ReduxState from '../../types/ReduxState';
import Nullable from '../../types/Nullable';
import SiteData from '../../types/SiteData';
import User from '../../types/User';
import ImportJob from '../../types/ImportJob';
import ImportJobStatus from '../../types/ImportJobStatus';

import useUrlParams from '../../hooks/useUrlParams';

const GiftCardsPage = () => {
  const [importJobs, setImportJobs] = useState<ImportJob[]>([]);
  const [getJobsErrorMessage, setGetJobsErrorMessage] = useState<string>('');
  const authenticatedUser = useSelector<ReduxState, Nullable<User>>(
    (state) => state.authentication.get('USER'),
  );
  const siteData = useSelector<ReduxState, Nullable<SiteData>>(
    (state) => state.settings.get('SETTINGS'),
  );
  const dispatch = useDispatch();

  const {
    values: [tab],
    setters: [onTabChange],
  } = useUrlParams(['tab']);

  useEffect(() => {
    if (authenticatedUser != null && siteData == null) {
      dispatch(getSettings(authenticatedUser.site));
    }
  }, [authenticatedUser]);

  useEffect(() => {
    if (authenticatedUser?.site == null) return () => {};
    const unsubscribe = initialiseImportJobsListener(
      authenticatedUser.site, setImportJobs, setGetJobsErrorMessage,
    );
    return () => unsubscribe();
  }, []);

  const activeTab = useMemo(() => {
    if (['1', '2', '3', '4', '5'].includes(tab || '')) return tab;
    return '1';
  }, [tab]);

  const handleTabChange = useCallback((event: React.ChangeEvent<{}>, newActiveTab: string) => (
    onTabChange(newActiveTab)
  ), []);

  const tabs = useMemo(() => {
    const tabsAvailable = [
      <Tab label="Gift Cards" value="1" key="1" />,
      <Tab label="Gift Card Products" value="2" key="2" />,
      <Tab label="Gift Card Options" value="3" key="3" />,
      <Tab label="Import" value="4" key="4" />,
      <Tab label="Audit Trail Report" value="5" key="5" />,
    ];
    return tabsAvailable;
  }, []);

  const isImportJobActive = useMemo(() => {
    const activeJobs = importJobs.filter((job) => job.status !== ImportJobStatus.Completed);
    return activeJobs.length > 0;
  }, [importJobs]);

  if (!siteData || !authenticatedUser) return <Spinner />;

  return (
    <Grid>
      <TabContext value={activeTab || '1'}>
        <Box>
          <TabList onChange={handleTabChange}>{tabs}</TabList>
        </Box>
        <TabPanel value="1">
          <GiftCardsTable
            user={authenticatedUser}
            siteData={siteData}
            isImportJobActive={isImportJobActive}
          />
        </TabPanel>
        <TabPanel value="2">
          <GiftCardProducts siteData={siteData} />
        </TabPanel>
        <TabPanel value="3">
          <GiftCardsOptions
            siteData={siteData}
            resetSiteData={() => dispatch(getSettings(authenticatedUser.site))}
          />
        </TabPanel>
        <TabPanel value="4">
          <GiftCardsImportForm
            importJobs={importJobs}
            getJobsErrorMessage={getJobsErrorMessage}
            site={siteData.site}
            userEmail={authenticatedUser.email}
          />
        </TabPanel>
        <TabPanel value="5">
          <GiftCardAuditTrailReport site={siteData.site} />
        </TabPanel>
      </TabContext>
    </Grid>
  );
};

export default GiftCardsPage;
