import moment from 'moment';

export default (slotString: string | undefined): number => {
  if (slotString) {
    const slot = slotString.split(':');
    const hours = Number(slot[0]);
    const minutes = Number(slot[1]);
    return moment().startOf('day').add(hours, 'hours').add(minutes, 'minutes')
      .valueOf();
  }
  return moment().startOf('day').valueOf();
};
