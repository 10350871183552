import Category from '../../../types/Category';

const copySiteMap = (siteMap: Category[]): Category[] => (
  siteMap.map((category) => (
    {
      ...category,
      subCategories: category.subCategories.map(
        (subCategory) => (
          {
            ...subCategory,
            products: subCategory.products.map((product) => ({ ...product })),
          }
        ),
      ),
    }
  ))
);

export default copySiteMap;
