import React, { useEffect, useMemo, useState } from 'react';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
  LinearProgress,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import apiRequest from '../../../helpers/api-request';
import isEmailValid from '../../../helpers/isEmailValid';

import Nullable from '../../../types/Nullable';
import GiftCard from '../../../types/GiftCard';
import User from '../../../types/User';

type Props = {
  open: boolean
  user: User
  activeGiftCard: Nullable<GiftCard>
  onClose: () => void
};

const ReIssueDialog = ({
  open, user, activeGiftCard, onClose,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const [newRecipientName, setNewRecipientName] = useState<string>('');
  const [newRecipientEmail, setNewRecipientEmail] = useState<string>('');
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState<boolean>(false);

  useEffect(() => {
    if (activeGiftCard != null) {
      setNewRecipientName(activeGiftCard.recipientName);
      setNewRecipientEmail(activeGiftCard.recipientEmail);
    }
  }, [activeGiftCard]);

  const handleClose = () => {
    setErrorMessage(null);
    setNewRecipientName('');
    setNewRecipientEmail('');
    onClose();
  };

  const handleReIssue = async () => {
    if (activeGiftCard?.id != null) {
      try {
        setIsLoading(true);
        await apiRequest(
          `gift-card/${activeGiftCard.id}/re-issue`,
          'POST',
          {
            recipientName: newRecipientName,
            recipientEmail: newRecipientEmail,
            user: user.email || '',
          },
        );
        setSuccessSnackbarOpen(true);
        onClose();
      } catch (err) {
        setErrorMessage('Could not re-issue Gift Card');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSuccessSnackbarClose = () => {
    setSuccessSnackbarOpen(false);
  };

  const isDisabled = useMemo(
    () => newRecipientName === '' || !isEmailValid(newRecipientEmail),
    [newRecipientName, newRecipientEmail],
  );

  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="re-issue-gift-card-title">
        {isLoading && <LinearProgress />}
        <DialogTitle id="re-issue-gift-card-title">{`Re-Issue Gift Card ${activeGiftCard?.code || ''}`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The Gift Card code will be changed during the re-issue process.
            An email will be sent to the recipient with details of the new code.
          </DialogContentText>
          <TextField
            label="Recipient Name"
            value={newRecipientName}
            onChange={(event) => setNewRecipientName(event.target.value)}
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            label="Recipient Email"
            value={newRecipientEmail}
            onChange={(event) => setNewRecipientEmail(event.target.value)}
            variant="outlined"
            margin="normal"
            fullWidth
          />
          {errorMessage && (
            <DialogContentText color="error">
              {errorMessage}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" variant="text" onClick={handleClose}>Close</Button>
          <Button color="primary" variant="text" onClick={handleReIssue} disabled={isDisabled}>Re Issue</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleSuccessSnackbarClose} severity="success">Gift Card Re Issued</Alert>
      </Snackbar>
    </>
  );
};

export default ReIssueDialog;
