import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Grid, TextField } from '@material-ui/core';

const styles = () => ({
  block: {
    display: 'block',
    marginBottom: 10,
  },
  flex: {
    width: 350,
    display: 'inlineBlock',
    marginRight: 20,
    marginBottom: 20,
  },
  normal: {
    marginBottom: 20,
  },
  time: {
    marginRight: 10,
    marginBottom: 10,
  },
  number: {
    maxWidth: 114,
    marginRight: 10,
    marginBottom: 10,
  },
});

const OutlinedTextField = ({
  classes,
  classOption,
  id,
  type,
  label,
  value,
  onChange,
  multiline,
  required,
  error,
  helperText,
  disabled,
  placeholder,
  onClick,
  inputLabelProps,
}) => (
  <Grid className={classes[classOption]}>
    <TextField
      id={id}
      type={type}
      className={classes[classOption]}
      placeholder={placeholder}
      label={label}
      value={value}
      onChange={onChange}
      variant="outlined"
      multiline={multiline}
      required={required}
      error={error}
      helperText={helperText}
      disabled={disabled}
      onClick={onClick}
      InputLabelProps={inputLabelProps}
      fullWidth
    />
  </Grid>
);

OutlinedTextField.propTypes = {
  classes: PropTypes.shape().isRequired,
  classOption: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  multiline: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  inputLabelProps: PropTypes.shape(),
};

OutlinedTextField.defaultProps = {
  value: '',
  type: 'string',
  multiline: false,
  required: false,
  error: false,
  helperText: null,
  disabled: false,
  placeholder: null,
  onChange: null,
  onClick: null,
  inputLabelProps: null,
};

export default withStyles(styles)(OutlinedTextField);
