import React,
{
  useCallback,
  useEffect,
  useMemo,
  useState,
  ChangeEvent,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TextField,
} from '@material-ui/core';

import ReduxState from '../../types/ReduxState';
import Nullable from '../../types/Nullable';
import SiteData from '../../types/SiteData';
import User from '../../types/User';
import UserSiteConfig from '../../types/UserSiteConfig';
import UserRole from '../../types/UserRole';
import Label from '../../types/Label';

import getSettings from '../../actions/get-settings';
import getUsers from '../../actions/get-users';

import labelsFromIds from '../../components/label-input/helpers/labels-from-ids';
import isUserRole from '../edit-user-page/helpers/isUserRole';
import request from '../../helpers/request';

import Spinner from '../../components/spinner/spinner';

interface Props {
  match: {
    params: {
      userId: string,
    },
  },
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  pageTitle: {
    marginBottom: 20,
  },
  textInput: {
    marginBottom: 20,
    width: 350,
  },
  radioGroup: {
    display: 'block',
    marginBottom: 10,
  },
  saveButton: {
    marginTop: 10,
    marginBottom: 10,
    width: 350,
  },
  subtitle: {
    margin: '37px 0px 8px',
  },
  instruction: {
    marginBottom: 16,
  },
}));

const EditSiteUserPage = ({ match }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const authenticatedUser = useSelector<ReduxState, Nullable<User>>(
    (state) => state.authentication.get('USER'),
  );
  const settings = useSelector<ReduxState, Nullable<SiteData>>(
    (state) => state.settings.get('SETTINGS'),
  );
  const allUsers = useSelector<ReduxState, User[]>(
    (state) => state.users.get('USERS'),
  );

  const user: Nullable<User> = allUsers.find(({ id }) => id === match.params.userId) || null;
  const userSiteConfig: Nullable<UserSiteConfig> = user?.sites
    .find(({ site }) => site === settings?.site) || null;

  const [reportingLabel, setReportingLabel] = useState<Nullable<Label>>(
    labelsFromIds(
      userSiteConfig?.reportingLabels || [],
      settings?.reportingLabels || [],
    )[0] || null,
  );
  const [role, setRole] = useState<UserRole>(userSiteConfig?.role || UserRole.User);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Nullable<string>>(null);
  const [POSPin, setPOSPin] = useState<string>(userSiteConfig?.POSPin || '');

  const labelSelectOptions = useMemo(() => {
    if (settings != null && settings.reportingLabels) {
      return settings.reportingLabels
        .filter((label) => (label.active != null ? label.active : true));
    }
    return [];
  }, [settings]);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    if (authenticatedUser != null) dispatch(getSettings(authenticatedUser.site));
  }, [authenticatedUser]);

  const reportingLabelChangeHandler = useCallback((event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    if (value === 'clear-select') {
      setReportingLabel(null);
    } else if (settings != null && settings.reportingLabels) {
      const newLabel = settings.reportingLabels
        .filter((label) => (label.active != null ? label.active : true))
        .find(({ text }) => text === value);
      if (newLabel) setReportingLabel(newLabel);
    }
  }, [setReportingLabel, settings]);

  const roleChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (isUserRole(value)) {
      setRole(value);
    }
  }, [setRole]);

  const handleChangePin = (event: React.ChangeEvent<{ value: string }>) => {
    const { target: { value } } = event;
    if (/^\d{0,4}$/.test(value)) setPOSPin(value);
  };

  const isValidPIN = useMemo(() => (
    POSPin.length === 4 || POSPin.length === 0
  ), [POSPin]);

  const handleSaveClick = useCallback(async () => {
    setLoading(true);
    try {
      if (!user) throw new Error('Missing user data');
      if (!userSiteConfig) throw new Error('Missing user data');
      if (!settings) throw new Error('Missing site data');
      const updatedConfig: UserSiteConfig = {
        ...userSiteConfig,
        role,
        reportingLabels: reportingLabel ? [reportingLabel.id] : [],
      };
      updatedConfig.POSPin = POSPin;
      updatedConfig.POSUser = POSPin.length === 4;
      const updatedSites = user.sites.map(
        (userSite) => (userSite.site === settings.site ? updatedConfig : userSite),
      );
      await request(
        'updateUser',
        'POST',
        {
          uid: user.id,
          name: user.name,
          email: user.email,
          admin: user.admin,
          sites: updatedSites,
        },
      );
      history.push('/manage-site-users');
    } catch {
      setError('User could not be updated, please try again.');
      setLoading(false);
    }
  }, [setLoading, setError, user, userSiteConfig, settings, role, reportingLabel, history, POSPin]);

  if (loading || !authenticatedUser || !settings || !user) return <Spinner />;

  return (
    <Box className={classes.container}>
      <Typography variant="h6">Edit User</Typography>
      <Typography className={classes.pageTitle}>{`The settings below will affect ${user.name} (${user.email}) at ${settings.name}.`}</Typography>
      <FormControl style={{ width: 350, marginBottom: 20 }} variant="outlined">
        <InputLabel id="label-select-label">Reporting Label</InputLabel>
        <Select
          labelId="label-select-label"
          labelWidth={120}
          value={reportingLabel ? reportingLabel.text : ''}
          onChange={reportingLabelChangeHandler}
          MenuProps={{ variant: 'menu' }}
        >
          {labelSelectOptions.map(({ id, text }) => (
            <MenuItem key={id} value={text}>{text}</MenuItem>
          ))}
          <MenuItem key="clear-select" value="clear-select">
            <Typography variant="button" color="primary">No Label</Typography>
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl className={classes.radioGroup}>
        <FormLabel id="role-radio-group-label">Role</FormLabel>
        <RadioGroup
          row
          aria-labelledby="role-radio-group-label"
          defaultValue={UserRole.User}
          value={role}
          onChange={roleChangeHandler}
        >
          <FormControlLabel value={UserRole.User} control={<Radio />} label="User" />
          <FormControlLabel value={UserRole.Manager} control={<Radio />} label="Manager" />
          <FormControlLabel value={UserRole.SuperUser} control={<Radio />} label="Super User" />
        </RadioGroup>
      </FormControl>
      <Typography variant="subtitle1" className={classes.subtitle}>Peazi POS PIN (optional)</Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        className={classes.instruction}
      >
        Assign a 4-number PIN for Peazi POS login
      </Typography>
      <TextField
        label="PIN"
        className={classes.textInput}
        variant="outlined"
        type="number"
        value={POSPin}
        onChange={handleChangePin}
      />
      <Button
        className={classes.saveButton}
        variant="contained"
        color="primary"
        onClick={handleSaveClick}
        disabled={!isValidPIN}
      >
        Save
      </Button>
      {error != null && <Typography variant="body1" color="error">{error}</Typography>}
    </Box>
  );
};

export default EditSiteUserPage;
