import { ComponentType } from 'react';

import AddProductPage from '../containers/AddProductPage/AddProductPage';
import CreateSitePage from '../containers/create-site-page/create-site-page';
import AddSubCategoryPage from '../containers/AddSubCategoryPage/AddSubCategoryPage';
import AddUserPage from '../containers/add-user-page/add-user-page';
import AdminPage from '../containers/AdminPage/AdminPage';
import AdminDashbaordPage from '../containers/AdminDashbaordPage/AdminDashbaordPage';
import CategoryPage from '../containers/category-page/category-page';
import DispenseScreen from '../containers/dispense-screen-page/dispense-screen-page';
import EditProductPage from '../containers/EditProductPage/EditProductPage';
import EditUserPage from '../containers/edit-user-page/edit-user-page';
import HomePage from '../containers/home-page/home-page';
import NotificationPage from '../containers/notification-page/notification-page';
import NotificationsPage from '../containers/notifications-page/notifications-page';
import OrderPage from '../containers/OrderPage/OrderPage';
import OrdersPage from '../containers/orders-page/orders-page';
import ProductsPage from '../containers/products-page/products-page';
import ProfilePage from '../containers/profile-page/profile-page';
import QuickSettingsPage from '../containers/quick-settings-page/quick-settings-page';
import RefundedOrdersPage from '../containers/RefundedOrdersPage/RefundedOrdersPage';
import SettingsPage from '../containers/settings-page/settings-page';
import SiteDashboardPage from '../containers/site-dashboard-page/site-dashboard-page';
import SiteMapPage from '../containers/site-map-page/site-map-page';
import SiteOrdersPage from '../containers/site-orders-page/site-orders-page';
import SiteUnpaidOrdersPage from '../containers/site-unpaid-orders-page/site-upaid-orders-page';
import SitesPage from '../containers/sites-page/sites-page';
import SlotTimesPage from '../containers/slot-times-page/slot-times-page';
import SlotsPage from '../containers/slots-page/slots-page';
import StripeCompletePage from '../containers/stripe-complete-page/stripe-complete-page';
import StripeSetupPage from '../containers/stripe-setup-page/stripe-setup-page';
import SubCategoryPage from '../containers/SubCategoryPage/SubCategoryPage';
import EditSubCategoryPage from '../containers/EditSubCategoryPage/EditSubCategoryPage';
import UnpaidOrders from '../containers/unpaid-orders-page/unpaid-orders-page';
import UnprocessedOrdersPage from '../containers/unprocessed-orders-page/unprocessed-orders-page';
import UploadPage from '../containers/upload-page/upload-page';
import UsersPage from '../containers/users-page/users-page';
import ViewProductPage from '../containers/view-product-page/view-product-page';
import DiscountsPage from '../containers/DiscountsPage/DiscountsPage';
import AddDiscountPage from '../containers/AddDiscountPage/AddDiscountPage';
import EditDiscountPage from '../containers/EditDiscountPage/EditDiscountPage';
import ReportingLabelManagementPage from '../containers/reporting-label-management-page/reporting-label-management-page';
import ReportsPage from '../containers/ReportsPage/ReportsPage';
import ManageSiteUsersPage from '../containers/ManageSiteUsersPage/ManageSiteUsersPage';
import AddSiteUserPage from '../containers/AddSiteUserPage/AddSiteUserPage';
import CloudPrintersPage from '../containers/CloudPrintersPage/CloudPrintersPage';
import POSUserPage from '../containers/pos-user-page/pos-user-page';
import EditPosUserPage from '../containers/pos-edit-user-page/pos-edit-user-page';
import POSPage from '../containers/pos-page/pos-page';
import EditSiteUserPage from '../containers/EditSiteUserPage/EditSiteUserPage';
import PosLocationPage from '../containers/pos-location-page/PosLocationPage';
import POSAdditionalPaymentPage from '../containers/pos-additional-payment-page/pos-additional-payment-page';
import EditPosAdditionalPaymentPage from '../containers/pos-edit-additional-payment-page/pos-edit-additional-payment-page';
import KioskPage from '../containers/KioskPage/KioskPage';
import CollectionScreenPage from '../containers/CollectionScreenPage/CollectionScreenPage';
import CustomersPage from '../containers/CustomersPage/CustomersPage';
import TextMessagesPage from '../containers/TextMessagesPage/TextMessagesPage';
import GiftCardsPage from '../containers/GiftCardsPage/GiftCardsPage';
import GiftCardPage from '../containers/GiftCardPage/GiftCardPage';
import AddGiftCardProductPage from '../containers/AddGiftCardProductPage/AddGiftCardProductPage';
import EditGiftCardProductPage from '../containers/EditGiftCardProductPage/EditGiftCardProductPage';
import CustomerPage from '../containers/CustomerPage/CustomerPage';

import AppUrl from '../types/AppUrl';

const APP_URL_SCREEN_MAP: Record<AppUrl, ComponentType<any>> = {
  [AppUrl.SiteOrders]: SiteOrdersPage,
  [AppUrl.SiteUnpaidOrders]: SiteUnpaidOrdersPage,
  [AppUrl.RefundedOrders]: RefundedOrdersPage,
  [AppUrl.SiteUnprocessedOrders]: UnprocessedOrdersPage,
  [AppUrl.Reports]: ReportsPage,
  [AppUrl.Order]: OrderPage,
  [AppUrl.Home]: HomePage,
  [AppUrl.CloudPrinters]: CloudPrintersPage,
  [AppUrl.Profile]: ProfilePage,
  [AppUrl.SiteMap]: SiteMapPage,
  [AppUrl.Category]: CategoryPage,
  [AppUrl.SubCategory]: SubCategoryPage,
  [AppUrl.Products]: ProductsPage,
  [AppUrl.ViewProduct]: ViewProductPage,
  [AppUrl.Slots]: SlotsPage,
  [AppUrl.SlotTimes]: SlotTimesPage,
  [AppUrl.SiteDashboard]: SiteDashboardPage,
  [AppUrl.DispenseScreen]: DispenseScreen,
  [AppUrl.CollectionScreen]: CollectionScreenPage,
  [AppUrl.Notifications]: NotificationsPage,
  [AppUrl.Notification]: NotificationPage,
  [AppUrl.QuickSettings]: QuickSettingsPage,
  [AppUrl.AddSubCategory]: AddSubCategoryPage,
  [AppUrl.EditSubCategory]: EditSubCategoryPage,
  [AppUrl.AddProduct]: AddProductPage,
  [AppUrl.EditProduct]: EditProductPage,
  [AppUrl.StripeSetup]: StripeSetupPage,
  [AppUrl.StripeComplete]: StripeCompletePage,
  [AppUrl.Settings]: SettingsPage,
  [AppUrl.Discounts]: DiscountsPage,
  [AppUrl.AddDiscountPage]: AddDiscountPage,
  [AppUrl.EditDiscountPage]: EditDiscountPage,
  [AppUrl.Admin]: AdminPage,
  [AppUrl.AdminDashbaord]: AdminDashbaordPage,
  [AppUrl.Orders]: OrdersPage,
  [AppUrl.UnprocessedOrders]: UnprocessedOrdersPage,
  [AppUrl.UnpaidOrders]: UnpaidOrders,
  [AppUrl.Sites]: SitesPage,
  [AppUrl.CreateSite]: CreateSitePage,
  [AppUrl.Users]: UsersPage,
  [AppUrl.AddUser]: AddUserPage,
  [AppUrl.EditUser]: EditUserPage,
  [AppUrl.UploadProducts]: UploadPage,
  [AppUrl.ReportingLabelManagement]: ReportingLabelManagementPage,
  [AppUrl.ManageSiteUsers]: ManageSiteUsersPage,
  [AppUrl.AddSiteUser]: AddSiteUserPage,
  [AppUrl.EditSiteUser]: EditSiteUserPage,
  [AppUrl.POSHome]: POSPage,
  [AppUrl.AddPOSUser]: POSUserPage,
  [AppUrl.EditPosUser]: EditPosUserPage,
  [AppUrl.AddPosAdditionalPaymentType]: POSAdditionalPaymentPage,
  [AppUrl.POSLocation]: PosLocationPage,
  [AppUrl.EditPosAdditionalPaymentType]: EditPosAdditionalPaymentPage,
  [AppUrl.Kiosk]: KioskPage,
  [AppUrl.Customers]: CustomersPage,
  [AppUrl.Customer]: CustomerPage,
  [AppUrl.TextMessages]: TextMessagesPage,
  [AppUrl.GiftCards]: GiftCardsPage,
  [AppUrl.GiftCard]: GiftCardPage,
  [AppUrl.AddGiftCardProduct]: AddGiftCardProductPage,
  [AppUrl.EditGiftCardProduct]: EditGiftCardProductPage,
};

export default APP_URL_SCREEN_MAP;
