import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Grid, IconButton, makeStyles, Snackbar,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import Alert from '@material-ui/lab/Alert';

import getSettings from '../../actions/get-settings';

import FabIcon from '../../components/fab-icon/fab-icon';
import Spinner from '../../components/spinner/spinner';
import OrdersTable from '../../components/orders-table/orders-table';

import processOrder from '../../helpers/process-order';

import ReduxState from '../../types/ReduxState';
import User from '../../types/User';
import Nullable from '../../types/Nullable';
import SiteData from '../../types/SiteData';

const useStyles = makeStyles(() => ({
  downloadButton: {
    marginBottom: 10,
  },
}));

const SiteOrdersPage = () => {
  const dispatch = useDispatch();
  const user = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));
  const settings = useSelector<ReduxState, Nullable<SiteData>>((state) => state.settings.get('SETTINGS'));
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    if (user != null) {
      dispatch(getSettings(user.site));
    }
  }, [user]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event: React.SyntheticEvent, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const onProcessOrder = (orderNumber: string) => {
    if (user != null) {
      setLoading(true);
      processOrder(orderNumber, user.name)
        .then(() => {
          setLoading(false);
        });
    }
  };

  const userSiteConfig = useMemo(() => user?.sites.find((site) => site.site === user.site), [user]);

  if (loading || !settings) return <Spinner />;

  return (
    <Grid>
      <Grid className={classes.downloadButton}>
        <FabIcon size="medium" color="primary">
          <IconButton onClick={handleClick}>
            <GetAppIcon />
          </IconButton>
        </FabIcon>
      </Grid>
      <OrdersTable
        site={user?.site || ''}
        processOrder={onProcessOrder}
        siteData={settings}
        reportingLabels={userSiteConfig?.reportingLabels || []}
      />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={() => setOpen(false)}
          severity="error"
          elevation={6}
          variant="filled"
        >
          Sorry but downloading of orders is not currently available.
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default SiteOrdersPage;
