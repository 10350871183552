import firebase from 'firebase/app';

import uploadImage from './upload-image';
import getImageUrl from './get-image-url';

const addProduct = async (product, productImage) => {
  const firebaseCollection = firebase
    .firestore()
    .collection('masterproducts');
  let image = product.image || '';

  if (productImage) {
    image = await uploadImage(product.site, productImage)
      .then(({ ref }) => getImageUrl(ref.fullPath));
  }

  return firebaseCollection
    .where('plu', '==', product.plu)
    .where('site', '==', product.site)
    .get()
    .then((snapshot) => {
      if (snapshot.empty) {
        return firebaseCollection
          .add({ ...product, image });
      }
      return Promise.reject(new Error('PLU already in use'));
    });
};

export default addProduct;
