import React, { useCallback, useMemo } from 'react';
import {
  Grid,
  GridSize,
  Box,
  ListSubheader,
  Paper,
  makeStyles,
} from '@material-ui/core';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import capitalize from '../../../helpers/capitalize';
import Status from '../../../types/Status';
import OrderItem from '../../../types/OrderItem';
import OrderData from '../../../types/OrderData';
import OrderFlowCard from './OrderFlowCard';
import InvolvesKitchen from '../../../types/InvolvesKitchen';
import canUserViewProduct from '../../../helpers/can-user-view-product';
import Label from '../../../types/Label';
import UserSiteConfig from '../../../types/UserSiteConfig';
import Nullable from '../../../types/Nullable';
import User from '../../../types/User';
import CollectTableNumber from '../../../types/CollectTableNumber';
import never from '../../../helpers/never';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
  },
  swimlaneHeading: {
    textAlign: 'center',
    fontSize: theme.spacing(2.5),
    fontWeight: 'bold',
  },
  columnsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

const getDraggableStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  padding: 8,
  margin: 0,
  background: isDragging ? 'lightgreen' : '',
  ...draggableStyle,
});

const getDroppableStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'lightblue' : '',
  padding: 0,
  height: '100%',
  minHeight: '200px',
});

const getColumnStyles = (swimlaneColumns: number) => {
  let width = '33%';
  if (swimlaneColumns === 1) width = '100%';
  if (swimlaneColumns === 2) width = '50%';

  return { width };
};

interface Props {
  columnStatus: Status
  filterOrdersByColumn: (column: Status) => OrderData[]
  involvesKitchen: InvolvesKitchen
  labelSelectValue: Label[]
  userSiteConfig?: UserSiteConfig
  user: Nullable<User>
  showCompleted: boolean
  siteReportingLabels?: Label[]
  inHouseCollectionLabels?: string[]
  collectTableNumberStatus?: CollectTableNumber
  statusSelectOptions: Status[]
  statusUpdateHandlerFactory: (
    (orderNumber: string, cartIds: string[], newStatus: Status) => () => void
  )
  swimlanesCount: number
}

const OrderFlowLane = ({
  columnStatus,
  filterOrdersByColumn,
  involvesKitchen,
  labelSelectValue,
  userSiteConfig,
  user,
  showCompleted,
  siteReportingLabels,
  inHouseCollectionLabels,
  collectTableNumberStatus = CollectTableNumber.Always,
  statusSelectOptions,
  statusUpdateHandlerFactory,
  swimlanesCount,
}: Props) => {
  const classes = useStyles();

  const filterOrderItems = useCallback((items: OrderItem[]) => (
    items.filter((item) => {
      if (
        item.serviceCharge
        || item.tip
        || item.refunded
        || item.discount
        || item.charityDonation
      ) return false;

      if (involvesKitchen === InvolvesKitchen.Food && !item.involvesKitchen) return false;
      if (involvesKitchen === InvolvesKitchen.Drinks && item.involvesKitchen) return false;
      if (item.status !== columnStatus) return false;

      if (
        (labelSelectValue.length > 0)
        && (
          !item.reportingLabels?.some(
            (labelId) => labelSelectValue.map(({ id }) => id).includes(labelId),
          )
        )
      ) {
        return false;
      }

      return (!userSiteConfig || canUserViewProduct(item, userSiteConfig));
    }).map((item) => {
      if (item.quantityRefunded != null) {
        return { ...item, quantity: item.quantity - item.quantityRefunded };
      }
      return item;
    })
  ), [involvesKitchen, user, labelSelectValue]);

  const swimlaneWidth: GridSize = useMemo(() => {
    switch (swimlanesCount) {
      case 1:
        return 12;
      case 2:
        return 6;
      default:
        return 4;
    }
  }, [swimlanesCount]);

  const swimlaneColumns = useMemo(() => {
    switch (swimlanesCount) {
      case 1:
        return 3;
      case 2:
        return 2;
      default:
        return 1;
    }
  }, [swimlanesCount]);

  const orders = filterOrdersByColumn(columnStatus);

  let columns: OrderData[][] = [];
  switch (swimlaneColumns) {
    case 1:
      columns = [orders];
      break;
    case 2:
      columns = orders.reduce<OrderData[][]>((result, order, index) => {
        if (index % 2 === 0) {
          return [[...result[0], order], result[1]];
        }
        return [result[0], [...result[1], order]];
      }, [[], []]);
      break;
    case 3:
      columns = orders.reduce<OrderData[][]>((result, order, index) => {
        if (index % 3 === 0) {
          return [[...result[0], order], result[1], result[2]];
        }
        if (index % 3 === 1) {
          return [result[0], [...result[1], order], result[2]];
        }
        return [result[0], result[1], [...result[2], order]];
      }, [[], [], []]);
      break;
    default:
      never(swimlaneColumns);
  }

  return (
    <Grid item xs={swimlaneWidth}>
      <Paper className={classes.paper}>
        <Droppable droppableId={columnStatus}>
          {(droppableProvided, droppableSnapshot) => (
            <div
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
              style={getDroppableStyle(droppableSnapshot.isDraggingOver)}
            >
              <ListSubheader className={classes.swimlaneHeading}>
                {capitalize(columnStatus)}
              </ListSubheader>
              <Box className={classes.columnsContainer}>
                {columns.map((column, index) => (
                  <Box style={getColumnStyles(swimlaneColumns)}>
                    {column.map((fullOrder) => (
                      <Draggable
                        key={`${fullOrder.id}/${columnStatus}`}
                        draggableId={`${fullOrder.id}/${columnStatus}`}
                        index={index}
                      >
                        {(draggableProvided, draggableSnapshot) => (
                          <div
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...draggableProvided.draggableProps}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...draggableProvided.dragHandleProps}
                            ref={draggableProvided.innerRef}
                            style={getDraggableStyle(
                              draggableSnapshot.isDragging,
                              draggableProvided.draggableProps.style,
                            )}
                          >
                            <OrderFlowCard
                              key={`${fullOrder.id}/${columnStatus}`}
                              showCompleted={showCompleted}
                              filteredOrder={{
                                ...fullOrder,
                                order: filterOrderItems(fullOrder.order),
                              }}
                              showLabels={labelSelectValue.length !== 1}
                              siteReportingLabels={siteReportingLabels}
                              inHouseCollectionLabels={inHouseCollectionLabels}
                              collectTableNumberStatus={collectTableNumberStatus}
                              statusSelectOptions={statusSelectOptions}
                              statusUpdateHandlerFactory={statusUpdateHandlerFactory}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </Box>
                ))}
              </Box>
            </div>
          )}

        </Droppable>
      </Paper>
    </Grid>
  );
};

export default OrderFlowLane;
