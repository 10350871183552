import Category from '../../../types/Category';
import copySiteMap from './copySiteMap';

const removeProductFromSiteMap = (
  plu: number,
  location: { category: string, subcategory: string },
  siteMap: Category[],
): Category[] => {
  const { category: productCategory, subcategory: productSubcategory } = location;

  const newSiteMap = copySiteMap(siteMap);

  const category = newSiteMap.find(
    ({ category: categoryName }) => categoryName === productCategory,
  );
  if (category == null) throw new Error('Category does not exist');
  const subCategory = category.subCategories.find(
    ({ name: subCategoryName }) => subCategoryName === productSubcategory,
  );
  if (subCategory == null) throw new Error('Subcategory does not exist');
  const productIndex = subCategory.products.findIndex((product) => product.plu === plu);
  if (productIndex === -1) throw new Error('Product does not appear on Site Map');
  subCategory.products.splice(productIndex, 1);
  return newSiteMap;
};

export default removeProductFromSiteMap;
