import firebase from 'firebase/app';
import moment from 'moment';
import { DateTime } from 'luxon';

import slotTimeToMilliseconds from '../helpers/slotTimeToMilliseconds';

import OrderData from '../types/OrderData';
import Dispatch from '../types/Dispatch';

import {
  GET_INHOUSE_ORDERS_SUCCESS,
  GET_ONLINE_ORDERS_SUCCESS,
  GET_ORDERS_START,
} from '../constants/actions';

export default (
  site: string, tradeDate?: string,
) => (dispatch: Dispatch) => {
  dispatch({
    type: GET_ORDERS_START,
  });
  const inHouseCall = firebase
    .firestore()
    .collection('orders')
    .where('tradingDate', '==', tradeDate || DateTime.now().toISODate())
    .where('site', '==', site);

  const onlineCall = firebase
    .firestore()
    .collection('orders')
    .where('slotDay', '==', moment().format('YYYY-MM-DD'))
    .where('site', '==', site);

  const sortOrdersByDate = (a: OrderData, b: OrderData): number => {
    if (a.orderProcessed && !b.orderProcessed) return 1;
    if (b.orderProcessed && !a.orderProcessed) return -1;
    const aTime = a.orderTime || 0;
    const bTime = b.orderTime || 0;
    return aTime - bTime;
  };

  const sortOrdersBySlotTime = (a: OrderData, b: OrderData): number => {
    if (a.orderProcessed && !b.orderProcessed) return 1;
    if (b.orderProcessed && !a.orderProcessed) return -1;
    const aTime = slotTimeToMilliseconds(a.slotTime) || 0;
    const bTime = slotTimeToMilliseconds(b.slotTime) || 0;
    return aTime - bTime;
  };

  inHouseCall
    .onSnapshot((snapshots) => {
      const orders: OrderData[] = [];
      snapshots.forEach((snapshot) => orders
        .push(<OrderData>{ ...snapshot.data(), id: snapshot.id }));
      const filteredOrders = orders
        .filter((order) => !order.onlineOrder && !order.duplicate && !order.testOrder && order.status !== 'unpaid');
      const unprocessedOrders = filteredOrders
        .filter(({ orderProcessed }) => !orderProcessed)
        .sort((a, b) => a.orderTime - b.orderTime);
      dispatch({
        type: GET_INHOUSE_ORDERS_SUCCESS,
        orders: filteredOrders.sort(sortOrdersByDate),
        numberOfOrders: filteredOrders.length,
        numberOfUnprocessedOrders: unprocessedOrders.length,
        amountOfSales: filteredOrders.reduce((output, order) => output + order.total, 0) || 0,
        unprocessedOrders,
      });
    });

  onlineCall
    .onSnapshot((snapshots) => {
      const orders: OrderData[] = [];
      snapshots.forEach((snapshot) => orders
        .push(<OrderData>{ ...snapshot.data(), id: snapshot.id }));

      const filteredOrders = orders
        .filter((order) => order.onlineOrder && !order.duplicate && !order.testOrder && order.status !== 'unpaid');
      const unprocessedOrders = filteredOrders
        .filter(({ orderProcessed }) => !orderProcessed)
        .sort((a, b) => slotTimeToMilliseconds(a.slotTime) - slotTimeToMilliseconds(b.slotTime));
      dispatch({
        type: GET_ONLINE_ORDERS_SUCCESS,
        orders: filteredOrders.sort(sortOrdersBySlotTime),
        numberOfOrders: filteredOrders.length,
        numberOfUnprocessedOrders: unprocessedOrders.length,
        amountOfSales: filteredOrders.reduce((output, order) => output + order.total, 0) || 0,
        unprocessedOrders,
      });
    });
};
