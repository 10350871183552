import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import TerminalLocation from '../../../types/TerminalLocation';
import Nullable from '../../../types/Nullable';

interface Props {
  location: TerminalLocation;
  onCancelCick: () => void;
  onSubmitClick: (
    locationId: string,
    name: string,
  ) => Promise<void>;
  error: Nullable<Error>;
  setError: Dispatch<SetStateAction<Nullable<Error>>>
  showAddressWarning: boolean;
  loading: boolean;
}

const useStyles = makeStyles((theme) => ({
  addLocationForm: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    minWidth: theme.spacing(58),
  },
  description: {
    margin: theme.spacing(2, 0, 3),
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(5),
  },
  buttonSpacer: {
    marginRight: theme.spacing(1),
  },
  input: {
    margin: theme.spacing(1, 0),
  },
}));

const EditLocationModal = ({
  location: {
    id,
    displayName: currentName,
  },
  onCancelCick,
  onSubmitClick,
  error,
  setError,
  showAddressWarning,
  loading,
}: Props) => {
  const classes = useStyles();

  const [name, setName] = useState<string>(currentName);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(null);
    const { target: { value } } = event;
    setName(value);
  };

  const isFormValid = (
    name
    && showAddressWarning === false
  );

  return (
    <Box className={classes.addLocationForm}>
      <Typography variant="h6">Edit Location</Typography>
      <Typography variant="body1" className={classes.description}>Locations can have payment devices assigned to them</Typography>
      <TextField
        className={classes.input}
        label="Location name"
        variant="outlined"
        value={name}
        onChange={handleNameChange}
      />
      {error && (
        <Typography color="error">
          {error.message.includes('409')
            ? `${name} is in use, please choose another name and try again.`
            : 'Something went wrong. Please contact support if this problem persists.'}
        </Typography>
      )}
      {showAddressWarning && (
        <Typography color="error">
          Please input a site address on the settings page.
        </Typography>
      )}
      <Box
        className={classes.buttonContainer}
      >
        <Button
          className={classes.buttonSpacer}
          variant="outlined"
          color="secondary"
          onClick={onCancelCick}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSubmitClick(id, name)}
          disabled={!isFormValid || loading}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default EditLocationModal;
