import request from '../helpers/request';

import {
  GET_ORDER_STATS_START,
  GET_ORDER_STATS_SUCCESS,
  GET_ORDER_STATS_ERROR,
} from '../constants/actions';

export default (site) => (dispatch) => {
  dispatch({
    type: GET_ORDER_STATS_START,
  });
  request(
    `orderStats?site=${encodeURIComponent(site || '')}`,
    'GET',
  )
    .then((orderStats) => {
      dispatch({
        type: GET_ORDER_STATS_SUCCESS,
        orderStats,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_ORDER_STATS_ERROR,
        message: 'Could not get order stats',
      });
    });
};
