import Label from '../../../types/Label';

const createPaymentRequirementsText = (label: Label): string => {
  const {
    vatRegistered,
    commissionPercent,
    stripeAccountId,
    stripeAccountSetupComplete,
  } = label;
  const requirements: string[] = [];
  if (vatRegistered == null) {
    requirements.push('VAT Status');
  }
  if (commissionPercent == null) {
    requirements.push('Site Commission');
  }
  if (
    stripeAccountId == null
    || stripeAccountSetupComplete == null
    || !stripeAccountSetupComplete
  ) {
    requirements.push('Stripe Account');
  }
  return requirements.join(', ');
};

export default createPaymentRequirementsText;
