/* eslint-disable react/destructuring-assignment */
import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';

import {
  Avatar,
  Box,
  Button,
  Drawer,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import Spinner from '../spinner/spinner';
import ImageUpload from '../image-upload/image-upload';
import ProductDetails from './components/product-details';
import ProductOptions from './components/product-options';
import ProductAddOns from './components/product-add-ons';
import LabelInput from '../label-input/label-input';
import AddToMenuForm from './components/AddToMenuForm';
import TabPanel from '../TabPanel/TabPanel';
import MultipleSelect from '../multiple-select/multiple-select';

import defaultTimes from './helpers/default-times';
import saveUserLabel from './helpers/save-user-label';
import saveReportingLabel from '../../helpers/save-reporting-label';
import labelsFromIds from '../label-input/helpers/labels-from-ids';
import canUserViewProduct from '../../helpers/can-user-view-product';
import filterProductsByAddon from '../../helpers/filter-products-by-addon';
import updateProduct from '../../helpers/update-product';
import hasPermission from '../../helpers/has-permission';
import isProduct from '../../helpers/isProduct';

import getVatRates from '../../actions/getVatRates';
import getSites from '../../actions/get-sites';

import Allergens from './constants/allergens';
import defaultProduct from './constants/defaultProduct';

import ProductFormTabValue from '../../types/ProductFormTabValue';
import RoleRestrictedAction from '../../types/RoleRestrictedAction';

const useStyles = makeStyles((theme) => ({
  imageGrid: {
    padding: 10,
    width: 100,
    height: 100,
    marginBottom: 20,
  },
  image: {
    backgroundColor: 'black',
    width: 100,
    height: 100,
    objectFit: 'contain',
    objectPosition: '50% 50%',
  },
  tabSection: {
    marginBottom: theme.spacing(10),
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2),
  },
  alert: { marginBottom: theme.spacing(2) },
  title: { paddingBottom: theme.spacing(1) },
  titleSection: { paddingBottom: theme.spacing(3) },
  buttons: { padding: theme.spacing(1), paddingLeft: theme.spacing(5) },
  cancelButton: { marginLeft: theme.spacing(1) },
  allergenSelect: {
    width: 350,
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const ProductForm = ({
  onSave, siteMap, products, selectedProduct, onCancel,
}) => {
  const [product, setProduct] = useState(defaultProduct);
  const [newSiteMap, setNewSiteMap] = useState(siteMap);
  const [selectedFile, setSelectedFile] = useState('');
  const [imagePreview, setImagePreview] = useState('');
  const [tabValue, setTabValue] = useState(ProductFormTabValue.Details);
  const [userLabels, setUserLabels] = useState([]);
  const [reportingLabels, setReportingLabels] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const user = useSelector((state) => state.authentication.get('USER'));
  const sites = useSelector((state) => state.sites.get('SITES'));
  const sitesLoading = useSelector((state) => state.sites.get('IS_SITES_LOADING'));
  const isVatRatesLoading = useSelector((state) => state.vatRates
    .get('IS_VAT_RATES_LOADING'));
  const vatRates = useSelector((state) => state.vatRates.get('VAT_RATES'));

  const classes = useStyles();
  const dispatch = useDispatch();

  const site = useMemo(() => sites.find((siteData) => siteData.site === user?.site), [user, sites]);

  const userSiteConfig = useMemo(
    () => user.sites.find((config) => config.site === user.site),
    [user],
  );

  const unremoveableLabels = useMemo(() => {
    if (!product.reportingLabels) {
      return [];
    }
    return product.reportingLabels
      .filter((labelId) => !reportingLabels.map(({ id }) => id).includes(labelId));
  }, [product, reportingLabels]);

  const addOnProductOptions = useMemo(() => {
    if (products == null) return [];
    if (userSiteConfig == null) return products;
    return products.filter((potentialAddOn) => canUserViewProduct(potentialAddOn, userSiteConfig));
  }, [products, userSiteConfig]);

  useEffect(() => {
    dispatch(getVatRates());
  }, []);

  useEffect(() => {
    if (!site && !sitesLoading) {
      dispatch(getSites());
    }
    if (site?.userLabels) setUserLabels(site.userLabels);
    if (site?.reportingLabels) {
      if (
        userSiteConfig != null
        && userSiteConfig.reportingLabels.length > 0
      ) {
        setReportingLabels(
          labelsFromIds(userSiteConfig.reportingLabels, site.reportingLabels),
        );
        if (!product.reportingLabels || product.reportingLabels.length === 0) {
          setProduct((currentValue) => ({
            ...currentValue,
            reportingLabels: userSiteConfig.reportingLabels,
          }));
        }
      } else {
        setReportingLabels(site.reportingLabels);
      }
    }
  }, [site, user]);

  useEffect(() => {
    if (selectedProduct) {
      setProduct({
        ...selectedProduct,
        options: selectedProduct.options.map((option) => (!option.id
          ? { ...option, id: uuidv4() }
          : option)),
      });
    }
  }, [selectedProduct]);

  const {
    plu,
    show,
    image,
    allergens,
  } = product;

  const showSelects = show && siteMap && siteMap.length !== 0;

  const updateAssociatedProducts = useCallback(async () => {
    const filteredProductsByPlu = filterProductsByAddon(product.plu, products);
    return Promise.all(filteredProductsByPlu.map((productToUpdate) => (
      updateProduct(
        productToUpdate.id,
        {
          ...productToUpdate,
          options: productToUpdate.options.map((option) => (
            {
              ...option,
              choices: option.choices.map((choice) => {
                if (choice.plu === product.plu) {
                  return {
                    ...choice,
                    displayName: product.addonDescription,
                  };
                }
                return choice;
              }),
            }
          )),
        },
        null,
      )
    )));
  });

  const onClick = async () => {
    setErrorMessage(null);
    if (product.options.length > 0 && !product.options.every((o) => o.choices.length > 0)) {
      setTabValue(ProductFormTabValue.AddOns);
      setErrorMessage('One or more options on the product has no choices, please add products to addOn or remove addOn.');
    } else {
      if (selectedProduct != null) {
        await updateAssociatedProducts();
      }
      const newProduct = {
        ...product,
        plu: Number(product.plu),
        price: Number(product.price),
      };
      await onSave(newProduct, newSiteMap, product.id, selectedFile || null);
    }
  };

  const handleChange = (label, value) => {
    const values = { ...product, [label]: value };

    setProduct(values);
  };

  const handleCheckBoxChange = (label, event) => {
    const values = { ...product, [label]: event.target.checked };

    setProduct(values);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleImageChange = (file, preview) => {
    setSelectedFile(file);
    setImagePreview(preview);
  };

  const handleImageRemove = () => {
    setSelectedFile('');
    setImagePreview('');
  };

  const handleUserLabelChange = useCallback((value) => {
    handleChange('userLabels', value);
  }, [handleChange]);

  const handleReportingLabelChange = useCallback((value) => {
    if (value.length + unremoveableLabels.length < 2) {
      handleChange('reportingLabels', value.concat(unremoveableLabels));
    }
  }, [handleChange, unremoveableLabels]);

  const onUserLabelSave = useCallback((label) => saveUserLabel(site, label), [site]);

  const onReportingLabelSave = useCallback((label) => saveReportingLabel(site, label), [site]);

  const changeTimes = (event) => {
    const { checked } = event.target;
    const values = { ...product, times: checked ? defaultTimes() : [] };

    setProduct(values);
  };

  const changeOption = (newOption, newOptionIndex) => {
    const newProduct = {
      ...product,
      options: product.options.map((option, i) => {
        if (i === newOptionIndex) {
          return newOption;
        }
        return option;
      }),
    };

    setProduct(newProduct);
  };

  const addNewOption = () => {
    const newProduct = {
      ...product,
      options: product.options.concat({
        id: uuidv4(),
        index: product.options.length,
        type: 'addOn',
        minChoices: 1,
        maxChoices: 1,
        choices: [],
      }),
    };

    setProduct(newProduct);
  };

  const removeOption = (index) => {
    product.options.splice(index, index + 1);

    const newProduct = {
      ...product,
      options: product.options,
    };

    setProduct(newProduct);
  };

  const handleTimesChange = useCallback(
    (newTimes) => {
      setProduct((value) => ({
        ...value,
        times: newTimes,
      }));
    },
    [],
  );

  if (isVatRatesLoading || !site) return <Spinner />;

  return (
    <Grid>
      <Grid className={classes.tabSection}>
        <Tabs className={classes.tabs} indicatorColor="primary" variant="scrollable" value={tabValue} onChange={handleTabChange}>
          <Tab label="Details" value={ProductFormTabValue.Details} />
          <Tab label="Image" value={ProductFormTabValue.Image} />
          <Tab label="Settings" value={ProductFormTabValue.Options} />
          <Tab label="Add Ons" value={ProductFormTabValue.AddOns} />
          <Tab label="Labels" value={ProductFormTabValue.Labels} />
          <Tab label="Add to Menu" value={ProductFormTabValue.AddToMenu} disabled={!showSelects} />
        </Tabs>
        {errorMessage ? <Alert className={classes.alert} severity="error">{errorMessage}</Alert> : null}
        <TabPanel tabValue={tabValue} value={ProductFormTabValue.Details}>
          <ProductDetails
            product={product}
            onChange={handleChange}
            vatRates={vatRates}
            siteData={site}
            plusInUse={products.map((p) => p.plu)}
          />
        </TabPanel>
        <TabPanel tabValue={tabValue} value={ProductFormTabValue.Image}>
          <Paper>
            {image ? (
              <Box style={{ paddingLeft: 20, paddingTop: 20 }}>
                <Avatar variant="rounded" src={image || ''} className={classes.image} />
                <Button
                  style={{ marginTop: 5 }}
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleChange('image', null)}
                >
                  Remove Image
                </Button>
              </Box>
            ) : null}
            <Box style={{ padding: 20 }}>
              <ImageUpload
                buttonLabel="Upload Image"
                onChange={handleImageChange}
                onRemove={handleImageRemove}
                defaultImage={imagePreview}
                previewGridStyles={classes.imageGrid}
                previewStyles={classes.image}
              />
              <Typography variant="body1">The image should have an aspect ratio of 3:2 and best resolution is 600x400.</Typography>
              <Typography variant="body1">For best results, we recommend having the product in the centre of the image.</Typography>
            </Box>
          </Paper>
        </TabPanel>
        <TabPanel tabValue={tabValue} value={ProductFormTabValue.Options}>
          <ProductOptions
            product={product}
            onChange={handleChange}
            onResetTimesChange={changeTimes}
            onTimesChange={handleTimesChange}
            onCheckBoxChange={handleCheckBoxChange}
          />
        </TabPanel>
        <TabPanel tabValue={tabValue} value={ProductFormTabValue.AddOns}>
          <ProductAddOns
            product={product}
            products={addOnProductOptions}
            onClick={addNewOption}
            onChange={changeOption}
            onRemove={removeOption}
          />
        </TabPanel>
        <TabPanel tabValue={tabValue} value={ProductFormTabValue.Labels}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Paper>
                <Box style={{ padding: 20 }}>
                  <Grid className={classes.titleSection}>
                    <Typography className={classes.title} variant="h6">Search Labels</Typography>
                    <Typography color="textSecondary" variant="body1">
                      Guests can use these to filter products, e.g.
                      &quot;Vegan&quot; or &quot;Gluten-free&quot;
                    </Typography>
                  </Grid>
                  <LabelInput
                    multiple
                    value={product.userLabels || []}
                    onChange={handleUserLabelChange}
                    labels={userLabels}
                    onLabelSave={onUserLabelSave}
                    inputLabel="Search Labels"
                    inputLabelWidth={88}
                    width={350}
                    canCreateLabels={
                      hasPermission(RoleRestrictedAction.CreateNewSearchLabels, user)
                    }
                    disabled={false}
                  />
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <Box style={{ padding: 20 }}>
                  <Grid className={classes.titleSection}>
                    <Typography className={classes.title} variant="h6">Allergens</Typography>
                    <Typography color="textSecondary" variant="body1">
                      Notify guests about products allergens
                    </Typography>
                  </Grid>
                  <MultipleSelect
                    className={classes.allergenSelect}
                    label="Allergens"
                    defaultValues={allergens || []}
                    options={Allergens.map((value) => ({ label: value, value }))}
                    onChange={(allergen) => handleChange('allergens', allergen)}
                  />
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <Box style={{ padding: 20 }}>
                  <Grid className={classes.titleSection}>
                    <Typography className={classes.title} variant="h6">Reporting Labels</Typography>
                    <Typography color="textSecondary" variant="body1">
                      Group products together for dispense and reporting
                    </Typography>
                  </Grid>
                  <LabelInput
                    multiple={false}
                    value={product.reportingLabels || []}
                    onChange={handleReportingLabelChange}
                    labels={reportingLabels}
                    onLabelSave={onReportingLabelSave}
                    inputLabel="Reporting Labels"
                    inputLabelWidth={125}
                    width={350}
                    canCreateLabels={(
                      userSiteConfig != null && userSiteConfig.reportingLabels.length === 0
                    )}
                    canClearLabels={(
                      userSiteConfig != null && userSiteConfig.reportingLabels.length === 0
                    )}
                    disabled={userSiteConfig == null || userSiteConfig.reportingLabels.length === 1}
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </TabPanel>
        {showSelects && (
          <TabPanel tabValue={tabValue} value={ProductFormTabValue.AddToMenu}>
            <Paper>
              <Box style={{ padding: 20 }}>
                <Typography className={classes.title} variant="h6">Add to Menu</Typography>
                <AddToMenuForm plu={Number(plu)} siteMap={newSiteMap} setSiteMap={setNewSiteMap} />
              </Box>
            </Paper>
          </TabPanel>
        )}
      </Grid>
      <Drawer anchor="bottom" variant="permanent">
        <Grid
          className={classes.buttons}
          container
          direction="row"
          justify="flex-start"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={onClick}
            disabled={!isProduct(product)}
          >
            Save
          </Button>
          <Button
            className={classes.cancelButton}
            variant="outlined"
            color="primary"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Grid>
      </Drawer>
    </Grid>
  );
};

ProductForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  siteMap: PropTypes.arrayOf(PropTypes.shape()),
  products: PropTypes.arrayOf(PropTypes.shape()),
  selectedProduct: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    plu: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    price: PropTypes.number.isRequired,
    over18: PropTypes.bool.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape()),
    image: PropTypes.string,
  }),
};

ProductForm.defaultProps = {
  siteMap: null,
  selectedProduct: null,
  products: null,
};

export default ProductForm;
