import React, {
  useState, useCallback, ChangeEvent, useMemo,
} from 'react';

import {
  Box, Typography, TextField, Paper, makeStyles,
} from '@material-ui/core';

import FadeInSpinner from './FadeInSpinner';
import LoadingSwitch from './LoadingSwitch';

import SiteData from '../../../types/SiteData';

interface Props {
  settings: SiteData;
  displaySiteWaitingTimesOptions: boolean;
  waitingTimesUpdateLoading: boolean;
  onDrinksWaitingTimesChange: (newDrinksWaitingTime: number) => void;
  onFoodWaitingTimesChange: (newFoodWaitingTime: number) => void;
  displaySiteStatusSwitch: boolean;
  siteStatusUpdateLoading: boolean;
  onSiteStatusChange: (value: boolean) => Promise<void>;
}

const useStyles = makeStyles({
  container: {
    marginBottom: 10,
    padding: 20,
  },
  formHeading: {
    marginBottom: 20,
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  waitingTimesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  textInput: {
    marginBottom: 20,
    marginRight: 20,
    width: 150,
  },
});

const SiteDefaultsForm = ({
  settings,
  displaySiteWaitingTimesOptions,
  waitingTimesUpdateLoading,
  onDrinksWaitingTimesChange,
  onFoodWaitingTimesChange,
  displaySiteStatusSwitch,
  siteStatusUpdateLoading,
  onSiteStatusChange,

}: Props) => {
  const classes = useStyles();

  const [drinksWaitingTime, setDrinksWaitingTime] = useState<number>(
    settings.waitingTimes?.drinksWaitingTime || 0,
  );
  const [foodWaitingTime, setFoodWaitingTime] = useState<number>(
    settings.waitingTimes?.foodWaitingTime || 0,
  );

  const handleDrinksWaitingTimeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newDrinksWaitingTime = Number(event.target.value);
      if (!Number.isNaN(newDrinksWaitingTime)) {
        setDrinksWaitingTime(newDrinksWaitingTime);
        onDrinksWaitingTimesChange(newDrinksWaitingTime);
      }
    },
    [setDrinksWaitingTime, onDrinksWaitingTimesChange],
  );

  const handleFoodWaitingTimeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newFoodWaitingTime = Number(event.target.value);
      if (!Number.isNaN(newFoodWaitingTime)) {
        setFoodWaitingTime(newFoodWaitingTime);
        onFoodWaitingTimesChange(newFoodWaitingTime);
      }
    },
    [setFoodWaitingTime, onFoodWaitingTimesChange],
  );

  const title = useMemo(() => `Main Site (${settings.name})`, [settings]);

  if (!displaySiteWaitingTimesOptions) return null;

  return (
    <Paper className={classes.container}>
      <Typography variant="h6" className={classes.formHeading}>{title}</Typography>
      <Box className={classes.optionsContainer}>
        {displaySiteStatusSwitch && (
          <LoadingSwitch
            label={settings.active ? 'Online' : 'Offline'}
            active={settings.active}
            loading={siteStatusUpdateLoading}
            disabled={siteStatusUpdateLoading}
            onChange={onSiteStatusChange}
          />
        )}
        <Box className={classes.waitingTimesContainer}>
          <TextField
            label="Drink Waiting Time"
            className={classes.textInput}
            variant="outlined"
            value={drinksWaitingTime}
            disabled={waitingTimesUpdateLoading}
            onChange={handleDrinksWaitingTimeChange}
          />
          <TextField
            label="Food Waiting Time"
            className={classes.textInput}
            variant="outlined"
            value={foodWaitingTime}
            disabled={waitingTimesUpdateLoading}
            onChange={handleFoodWaitingTimeChange}
          />
          {waitingTimesUpdateLoading && (
            <FadeInSpinner loading={waitingTimesUpdateLoading} />
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default SiteDefaultsForm;
