import React, { Dispatch, SetStateAction } from 'react';

import {
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ImageUpload from '../image-upload/image-upload';
import Nullable from '../../types/Nullable';
import READER_IMAGE from '../../constants/readerImage';

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    paddingLeft: theme.spacing(3),
  },
  readerSplashGrid: {
    padding: 10,
    width: theme.spacing(12.5),
    height: theme.spacing(22.25),
    marginBottom: 20,
  },
  readerSplash: {
    width: theme.spacing(12.5),
    height: theme.spacing(22.25),
    objectFit: 'contain',
    objectPosition: '50% 50%',
  },
}));

type Props = {
  readerSplashImage: string | undefined
  handleChange: (preview: string) => void
  handleRemove: () => void
  setError: Dispatch<SetStateAction<Nullable<string>>>
  setSplashImage: Dispatch<SetStateAction<Nullable<File>>>
  descriptionText: string
  fallbackImage?: string
};

const TerminalSplashImage = ({
  readerSplashImage,
  handleChange,
  handleRemove,
  setError,
  setSplashImage,
  descriptionText,
  fallbackImage = READER_IMAGE.Default,
}: Props) => {
  const classes = useStyles();

  const handleSplashImageChange = (file: File, preview: string) => {
    if (
      file.type !== 'image/png'
      && file.type !== 'image/jpeg'
      && file.type !== 'image/gif'
    ) {
      setError('Invalid file format for reader splash screen (only PNG, JPG or GIF are acceptable)');
    } else if (
      file.size > 2000000
      && file.type !== 'image/gif'
    ) {
      setError('Reader splash image file size too large for PNG or JPG (must be below 2MB)');
    } else if (
      file.size > 4000000
      && file.type === 'image/gif'
    ) {
      setError('Reader splash image file size too large for GIF (must be below 4MB)');
    } else {
      const image = new Image();
      image.src = preview;
      image.onload = () => {
        if (image.width > 720) {
          setError('Maximum reader splash image width is 720px');
        } else if (image.height > 1280) {
          setError('Maximum reader splash image height 1280px');
        } else {
          setError(null);
          if (
            handleChange != null
            && setSplashImage != null
          ) {
            setSplashImage(file);
            handleChange(preview);
          }
        }
      };
    }
  };

  const handleSplashImageRemove = () => {
    if (
      setSplashImage != null
    ) {
      setSplashImage(null);
      handleRemove();
    }
  };

  return (
    <>
      <Typography variant="h6">POS Payment Terminal Splash Screen</Typography>
      <Box className={classes.sectionContainer}>
        <ImageUpload
          id="readerSplashImage"
          buttonLabel="Upload splash screen"
          onChange={handleSplashImageChange}
          onRemove={handleSplashImageRemove}
          currentImage={readerSplashImage}
          description={descriptionText}
          description2="The image should have portrait orientation and a resolution of 720x1280."
          previewGridStyles={classes.readerSplashGrid}
          previewStyles={classes.readerSplash}
          fallbackImage={fallbackImage}
        />
      </Box>
    </>
  );
};

export default TerminalSplashImage;
