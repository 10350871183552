/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import Dropzone from 'react-dropzone';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  uploadArea: {
    width: 500,
    height: 200,
    border: '3px dotted green',
    padding: 50,
    margin: 20,
  },
}));

const UploadZone = ({ onDrop }) => {
  const classes = useStyles();

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        onDrop({
          fileName: acceptedFiles[0],
          fileData: reader.result,
        });
      };
      reader.readAsText(acceptedFiles[0]);
    }
  };

  return (
    <Dropzone onDrop={handleDrop}>
      {({ getRootProps, getInputProps }) => (
        <div className={classes.uploadArea} {...getRootProps()}>
          <input {...getInputProps()} />
          <Typography variant="body1">Drag & drop or click here to select product file</Typography>
        </div>
      )}
    </Dropzone>
  );
};

UploadZone.propTypes = {
  onDrop: PropTypes.func.isRequired,
};

export default UploadZone;
