import React, { useCallback, useMemo, useState } from 'react';

import {
  Box,
  Button, Divider, Drawer, Grid, Paper, Typography, makeStyles,
} from '@material-ui/core';

import OutlinedTextField from '../outlined-text-field/outlined-text-field';
import DragAndDropList from '../drag-and-drop-list/drag-and-drop-list';
import ImageUpload from '../image-upload/image-upload';
import canUserViewProduct from '../../helpers/can-user-view-product';

import User from '../../types/User';
import SubCategory from '../../types/SubCategory';
import Nullable from '../../types/Nullable';
import ProductData from '../../types/ProductData';

interface Props {
  user: User,
  subCategories: SubCategory[],
  subCategoryToEdit: Nullable<SubCategory>,
  productsToUse: ProductData[],
  onSave: (newSubCat: SubCategory, newImage: Nullable<File>) => Promise<void>,
  onCancel: () => void,
}

const useStyles = makeStyles((theme) => ({
  paper: { marginBottom: theme.spacing(10) },
  sectionTitle: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  mainSection: {
    margin: theme.spacing(2),
  },
  buttons: { padding: 10, paddingRight: 20 },
  cancelButton: { marginRight: 10 },
  largeImageGrid: {
    padding: 10,
    width: '100%',
    marginBottom: 20,
  },
  largeImage: {
    aspectRatio: '30 / 7',
    backgroundColor: 'black',
    width: '100%',
    height: 'auto',
    objectFit: 'contain',
    objectPosition: '50% 50%',
  },
}));

const SubCategoryForm = ({
  user, subCategories, subCategoryToEdit, productsToUse, onSave, onCancel,
}: Props) => {
  const [subCategory, setSubCategory] = useState<SubCategory>(subCategoryToEdit || {
    name: '',
    image: null,
    sequence: 0,
    products: [],
  });
  const [selectedFile, setSelectedFile] = useState<Nullable<File>>(null);
  const classes = useStyles();

  const userSiteConfig = useMemo(
    () => user.sites.find(({ site }) => site === user.site),
    [user],
  );

  const isSubCategoryNameDuplicate = useMemo(() => {
    const orginalCatName = subCategoryToEdit ? subCategoryToEdit.name : '';
    return subCategories
      .map((subCat) => subCat.name)
      .includes(subCategory.name) && orginalCatName !== subCategory.name;
  }, [subCategory, subCategoryToEdit, subCategories]);

  const handleChange = useCallback(<T extends keyof SubCategory>(
    name: T, value: SubCategory[T],
  ) => {
    const values = {
      ...subCategory, [name]: name === 'sequence' ? Number(value) : value,
    };
    setSubCategory(values);
  }, [subCategory]);

  const handleSequenceChange = useCallback((newValue: string) => {
    const newSubCategory = {
      ...subCategory,
      sequence: Number(newValue),
    };
    setSubCategory(newSubCategory);
  }, [subCategory]);

  const handleImageChange = useCallback((file: File, preview: string | null) => {
    setSelectedFile(file);
    handleChange('image', preview);
  }, []);

  const handleImageRemove = useCallback(() => {
    setSelectedFile(null);
    handleChange('image', '');
  }, []);

  const {
    name, image, sequence, products,
  } = subCategory;

  const productsWithInfo = useMemo(() => (
    products.map((product) => {
      const productWithInfo = productsToUse.find(({ plu }) => product.plu === plu);
      const productName = productWithInfo != null ? productWithInfo.name : ' ';
      return { ...product, name: productName };
    })), [products, productsToUse]);

  const filteredProducts = useMemo(() => (
    productsToUse.filter((prod) => (
      !products.map((p) => p.plu).includes(prod.plu)
      && prod.show
      && userSiteConfig != null
      && canUserViewProduct(prod, userSiteConfig)
    ))), [productsToUse, products, userSiteConfig]);

  const unremovableProducts = useMemo(() => {
    if (userSiteConfig == null) return [];
    return productsWithInfo.filter((product) => {
      const productWithLabels = productsToUse.find(({ plu }) => product.plu === plu);
      if (productWithLabels == null) return false;
      return !canUserViewProduct(productWithLabels, userSiteConfig);
    }).map(({ plu }) => plu);
  }, [productsWithInfo, userSiteConfig]);

  return (
    <Grid>
      <Paper className={classes.paper} variant="outlined">
        <Typography className={classes.sectionTitle} variant="h6">
          {`${subCategoryToEdit ? 'Edit' : 'Add'} ${subCategoryToEdit ? subCategoryToEdit.name : 'Sub Category'}`}
        </Typography>
        <Divider />
        <Box className={classes.mainSection}>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
          >
            <OutlinedTextField
              id="name"
              classOption="flex"
              label="Name"
              value={name}
              onChange={(event) => handleChange('name', event.target.value)}
              helperText={isSubCategoryNameDuplicate ? 'Sub Category name has already been used, please try another' : null}
              error={isSubCategoryNameDuplicate}
              required
            />
            <OutlinedTextField
              id="outlined-sequence"
              classOption="flex"
              type="number"
              label="Sequence"
              value={sequence}
              onChange={(event) => handleSequenceChange(event.target.value)}
            />
            <ImageUpload
              buttonLabel="Upload Sub Category Image"
              onChange={handleImageChange}
              onRemove={handleImageRemove}
              currentImage={image}
              description="The best resolution is 600 x 140."
              previewGridStyles={classes.largeImageGrid}
              previewStyles={classes.largeImage}
            />
            <DragAndDropList
              productsToUse={filteredProducts}
              productsUsed={productsWithInfo}
              unremovableProducts={unremovableProducts}
              onChange={handleChange}
            />
          </Grid>
        </Box>
      </Paper>
      <Drawer anchor="bottom" variant="permanent">
        <Grid
          className={classes.buttons}
          container
          direction="row"
          justify="flex-end"
        >
          <Button
            className={classes.cancelButton}
            variant="outlined"
            color="primary"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onSave(subCategory, selectedFile)}
            disabled={name === ''}
          >
            Save
          </Button>
        </Grid>
      </Drawer>
    </Grid>
  );
};

export default SubCategoryForm;
