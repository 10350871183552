import React from 'react';
import { useHistory } from 'react-router-dom';

import MaterialTable from 'material-table';

import nameToUrl from '../../../helpers/name-to-url';
import useUrlParams from '../../../hooks/useUrlParams';

import AppUrl from '../../../types/AppUrl';
import SubCategory from '../../../types/SubCategory';

type Props = {
  subCategories: SubCategory[]
  category: string
  isLoading: boolean
  onRemove: (name: string) => void
  editableSubCategories: SubCategory['name'][]
  userCanAddSubCategory: boolean
};

const CategoryTable = ({
  subCategories,
  category,
  isLoading,
  onRemove,
  editableSubCategories,
  userCanAddSubCategory,
}: Props) => {
  const history = useHistory();

  const {
    values: [page, search],
    setters: [onPageChange, onSearchChange],
  } = useUrlParams(['page', 'search']);

  return (
    <MaterialTable
      title={`${nameToUrl(category)} Sub Categories`}
      columns={[
        { title: 'Sub Category', field: 'name' },
        { title: 'Sequence', field: 'sequence' },
        { title: 'Products', field: 'products', render: (rowData) => rowData.products.length },
      ]}
      data={subCategories}
      actions={[
        (rowData) => ({
          icon: 'visibility',
          tooltip: 'View Sub Category',
          onClick: () => history
            .push(AppUrl.SubCategory
              .replace(':category', nameToUrl(category))
              .replace(':subCategory', nameToUrl(rowData.name))),
        }),
        {
          icon: 'add',
          tooltip: 'Add Sub Category',
          isFreeAction: true,
          disabled: !userCanAddSubCategory,
          onClick: () => history.push(AppUrl.AddSubCategory.replace(':category', nameToUrl(category))),
        },
        (rowData) => ({
          icon: 'delete',
          tooltip: 'Remove Sub Category',
          onClick: () => onRemove(rowData.name),
          disabled: !editableSubCategories.includes(rowData.name),
        }),
      ]}
      options={{
        actionsColumnIndex: -1,
        pageSize: 10,
        initialPage: Number(page || 0),
        searchText: search || '',
      }}
      onSearchChange={onSearchChange}
      onChangePage={(newPage) => onPageChange(String(newPage))}
      isLoading={isLoading}
    />
  );
};

export default CategoryTable;
