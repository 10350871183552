/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState, useEffect, useMemo, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DateTime } from 'luxon';
import MaterialTable, { MTableToolbar } from 'material-table';

import {
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import AdvancedSearchDialog from './components/advanced-search-dialog';
import PaymentMethod from './components/payment-method';
import Labels from '../Labels/Labels';
import OrderStatus from '../OrderStatus/OrderStatus';

import hasPermission from '../../helpers/has-permission';
import formatMoney from '../../helpers/formatMoney';
import getOrders from './helpers/get-orders';
import useUrlParams from '../../hooks/useUrlParams';

import STORE_KEYS from '../../constants/store-keys';
import RoleRestrictedAction from '../../types/RoleRestrictedAction';
import labelsFromIds from '../label-input/helpers/labels-from-ids';
import SposOptionsDialog from './components/SposOptionsDialog';

const {
  AUTHENTICATION: { USER },
} = STORE_KEYS;

const OrdersTable = ({
  site, processOrder, refundedOrders, siteData, reportingLabels,
}) => {
  const defaultTimeBefore = DateTime.now().setLocale('en-GB').toFormat('yyyy-LL-dd HH:mm');
  const defaultTimeAfter = DateTime.now().minus({ months: 1 }).setLocale('en-GB').toFormat('yyyy-LL-dd HH:mm');
  const defaultOptions = {
    orderNumber: '',
    tableNumber: '',
    timeAfter: defaultTimeAfter,
    timeBefore: defaultTimeBefore,
    amount: '',
    last4: '',
  };

  const user = useSelector((state) => state.authentication.get(USER));

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(defaultOptions);
  const [optionsChanged, setOptionsChanged] = useState(false);
  const [status, setStatus] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const [sposOptionsSelectedOrder, setSposOptionsSelectedOrder] = useState(null);

  const history = useHistory();
  const tableRef = useRef();

  const {
    values: [page, search],
  } = useUrlParams(['page', 'search']);

  const labelsToSearch = useMemo(() => labelsFromIds(reportingLabels, siteData.reportingLabels)
    .map(({ text }) => text),
  [siteData, reportingLabels]);

  const handleSearch = (searchOptions) => {
    setOpen(false);
    const advancedSearch = {
      ...searchOptions,
      timeAfter: searchOptions.timeAfter.split('T').join(' '),
      timeBefore: searchOptions.timeBefore.split('T').join(' '),
    };
    return tableRef.current && tableRef.current.onQueryChange({ advancedSearch });
  };

  const handleRefresh = () => {
    const newTimeAfter = DateTime.now().minus({ months: 1 }).setLocale('en-GB').toFormat('yyyy-LL-dd HH:mm');
    const newTimeBefore = DateTime.now().setLocale('en-GB').toFormat('yyyy-LL-dd HH:mm');
    const newOptions = {
      ...options,
      timeAfter: newTimeAfter,
      timeBefore: newTimeBefore,
    };

    setOptions(newOptions);
    handleSearch(newOptions);
  };

  useEffect(() => {
    if (optionsChanged) return () => {};
    const interval = setInterval(() => {
      handleRefresh();
    }, 300000); // 5 minutes
    return () => clearInterval(interval);
  }, [optionsChanged]);

  useEffect(() => {
    if (tableRef.current != null) {
      tableRef.current.onQueryChange(
        {
          advancedSearch: options,
          page: (search != null && search.length > 0) ? 0 : Number(page || 0),
          search: search || '',
        },
      );
    }
  }, []);

  const getData = async (query) => {
    const data = await getOrders(
      query,
      site,
      refundedOrders,
      status,
      labelsToSearch,
    );
    if (refundedOrders) {
      window.history.replaceState(null, '', `refunded-orders?page=${query.page}&search=${query.search}`);
    } else {
      window.history.replaceState(null, '', `orders?page=${query.page}&search=${query.search}`);
    }
    setIsLoading(false);
    return data;
  };

  const handleChange = (label, value) => {
    setOptions({ ...options, [label]: value });
    setOptionsChanged(true);
  };

  const handleClear = () => {
    setOptions(defaultOptions);
    setOptionsChanged(false);
    handleSearch(defaultOptions);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    handleSearch(options);
  };

  const sposOptionsClickHandlerFactory = (orderNumber) => () => {
    setSposOptionsSelectedOrder(orderNumber);
  };

  const handleSposOptionsCloseClick = () => {
    setSposOptionsSelectedOrder(null);
  };

  const columns = useMemo(() => ([
    { title: 'Order Number', field: 'order_number' },
    {
      title: 'Reporting Labels',
      field: 'reporting_labels',
      render: (rowData) => (rowData.reporting_labels == null ? '' : <Labels labels={JSON.parse(rowData.reporting_labels)} />),
    },
    { title: 'Date', field: 'order_time', render: (rowData) => DateTime.fromISO(rowData.order_time).toFormat('cccc, DDD, t') },
    { title: `${siteData?.locationDescriptor || 'Table Number'}`, field: 'table_number' },
    { title: 'Notes', field: 'notes' },
    { title: 'Items', field: 'total_items' },
    { title: 'Payment Method', field: 'wallet_type', render: (rowData) => <PaymentMethod method={rowData.wallet_type} /> },
    { title: 'Card Last Four', field: 'card_last4' },
    { title: 'Total', field: 'total_value', render: (rowData) => formatMoney(rowData.total_value || rowData.amount, siteData.currency) },
    { title: 'Status', field: 'status', render: (rowData) => <OrderStatus status={rowData.status} /> },
  ]), []);

  if (siteData.delivery != null || siteData.collection != null) {
    columns.splice(4, 0, { title: 'Slot', field: 'slot', render: (rowData) => (rowData.slot ? DateTime.fromISO(rowData.slot).toFormat('DDD, t') : 'N/A') });
  }

  const actions = [
    {
      icon: 'visibility',
      tooltip: 'View Order',
      disabled: !hasPermission(RoleRestrictedAction.ViewOrder, user),
      onClick: (event, rowData) => history.push(`/orders/${rowData.order_number}`),
    },
    (rowData) => ({
      icon: SettingsBackupRestoreIcon,
      tooltip: 'Process Order',
      disabled: !processOrder || rowData.status === 'processed' || rowData.status === 'complete' || !hasPermission(RoleRestrictedAction.ProcessOrder, user),
      onClick: () => processOrder(rowData.order_number),
    }),
    {
      icon: 'refresh',
      tooltip: 'Refresh Data',
      isFreeAction: true,
      onClick: handleRefresh,
    },
  ];

  if (siteData.spos?.consoleFeaturesEnabled) {
    actions.splice(
      1,
      0,
      (rowData) => ({
        icon: 'receipt',
        tooltip: 'Open SPOS Options',
        onClick: sposOptionsClickHandlerFactory(rowData.order_number),
      }),
    );
  }

  return (
    <Grid>
      <MaterialTable
        title={`${site || ''} ${refundedOrders ? 'Refunded ' : ''} Orders`}
        tableRef={tableRef}
        columns={columns}
        data={getData}
        isLoading={isLoading}
        actions={actions}
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
          searchText: search || '',
        }}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <div style={{ padding: 10 }}>
                <FormControl>
                  <RadioGroup onChange={handleStatusChange} value={status} row>
                    <FormControlLabel value="" control={<Radio />} label="All" />
                    <FormControlLabel value="succeeded" control={<Radio />} label="Paid" />
                    <FormControlLabel value="failed" control={<Radio />} label="Unpaid" />
                  </RadioGroup>
                </FormControl>
                <Chip
                  icon={<AddIcon />}
                  label="Advanced Search"
                  onClick={() => setOpen(!open)}
                  variant="outlined"
                />
                {optionsChanged
                  ? (
                    <Chip
                      style={{ marginLeft: 5 }}
                      icon={<CloseIcon />}
                      label="Clear"
                      onClick={handleClear}
                      color="secondary"
                      variant="outlined"
                    />
                  )
                  : null}
              </div>
            </div>
          ),
        }}
      />
      <AdvancedSearchDialog
        open={open}
        options={options}
        onChange={handleChange}
        onClose={() => setOpen(false)}
        onClear={handleClear}
        onSearch={() => handleSearch(options)}
        siteData={siteData}
      />
      <SposOptionsDialog
        selectedOrderNumber={sposOptionsSelectedOrder}
        onCloseClick={handleSposOptionsCloseClick}
      />
    </Grid>
  );
};

OrdersTable.propTypes = {
  site: PropTypes.string,
  processOrder: PropTypes.func,
  refundedOrders: PropTypes.bool,
  siteData: PropTypes.shape(),
  reportingLabels: PropTypes.arrayOf(PropTypes.string),
};

OrdersTable.defaultProps = {
  site: null,
  processOrder: null,
  refundedOrders: null,
  siteData: null,
  reportingLabels: [],
};

export default OrdersTable;
