/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';

import MaterialTable, { MTableToolbar } from 'material-table';
import {
  Chip,
  Grid,
  Typography,
} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';

import Labels from '../../../components/Labels/Labels';
import Spinner from '../../../components/spinner/spinner';

import getTransactionsReport from '../helpers/getTransactionsReport';
import formatMoney from '../../../helpers/formatMoney';
import useUrlParams from '../../../hooks/useUrlParams';

import DateType from '../types/DateType';
import Options from '../types/Options';
import Query from '../../../types/Query';
import SiteData from '../../../types/SiteData';
import TableElement from '../types/TableElement';
import ToolbarProps from '../types/ToolbarProps';
import User from '../../../types/User';
import Nullable from '../../../types/Nullable';
import apiRequest from '../../../helpers/api-request';
import ReportDownloadDialog from './ReportDownloadDialog';
import ProcessingFeeSummary from './ProcessingFeeSummary';
import getTransactionsReportHeader from '../helpers/getTransactionsReportHeader';
import TransactionsReportHeaderData from '../../../types/TransactionsReportHeaderData';
import CharityDonationsTable from './CharityDonationsTable';
import hasPermission from '../../../helpers/has-permission';
import RoleRestrictedAction from '../../../types/RoleRestrictedAction';

type Props = {
  user: Nullable<User>
  siteData: Nullable<SiteData>
  options: Options
};

const TransactionsReportSection = ({
  user, siteData, options,
}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [downloadDialogOpen, setDownloadDialogOpen] = useState<boolean>(false);
  const [downloadError, setDownloadError] = useState<boolean>(false);
  const [fetchedData, setFetchedData] = useState<boolean>(false);
  const [
    transactionsReportHeaderData,
    setTransactionsReportHeaderData,
  ] = useState<Nullable<TransactionsReportHeaderData>>(null);
  const [isLoadingHeader, setIsLoadingHeader] = useState<boolean>(true);
  const tableRef = React.createRef<TableElement>();

  const {
    values: [page, search],
    setters: [onPageChange, onSearchChange],
  } = useUrlParams(['transactionsPage', 'transactionsSearch']);

  useEffect(() => {
    if (page == null || search == null) {
      history.replace(
        `${location.pathname}${location.search.length > 0 ? `${location.search}&` : '?'}transactionsPage=0&transactionsSearch=`,
      );
    }
  }, []);

  useEffect(() => {
    if (siteData != null) {
      getTransactionsReportHeader(siteData.site, options, user?.id, options.reportingLabels)
        .then((data) => setTransactionsReportHeaderData(data))
        .finally(() => setIsLoadingHeader(false));
    }
  }, [siteData, options]);

  const site = useMemo(() => {
    if (!user) return '';
    return user.site;
  }, [user]);

  const siteLabels = useMemo(() => {
    if (!siteData) return [];
    if (!siteData.reportingLabels) return [];
    return siteData.reportingLabels;
  }, [siteData]);

  const activeReportingLabels = useMemo(() => options.reportingLabels, [siteLabels, options]);

  const refreshData = useCallback(() => {
    if (tableRef.current != null) {
      tableRef.current.onQueryChange({ options });
    }
  }, [tableRef, options]);

  const handleDownloadReportClick = useCallback(() => {
    setDownloadDialogOpen(true);
  }, []);

  const handleDownloadCancelClick = useCallback(() => {
    setDownloadDialogOpen(false);
  }, []);

  const handleDownload = useCallback(async (userEmail: string) => {
    const {
      start,
      end,
      type,
      reportingLabels,
    } = options;
    const tableRefWithState = tableRef as unknown as { current: { state: { query: Query } } };
    const {
      current: { state: { query: { search: searchText, orderBy, orderDirection } } },
    } = tableRefWithState;
    const dateColumn = type === DateType.TradingDate ? 'trading_date' : 'order_time';
    try {
      setDownloadLoading(true);
      await apiRequest(
        'reports-transactions',
        'POST',
        {
          site,
          emailAddress: userEmail,
          startDate: start,
          endDate: end,
          dateField: dateColumn,
          reportingLabels,
          orderByField: orderBy?.field || dateColumn,
          orderDirection: orderDirection || 'asc',
          search: searchText || '',
        },
      );
      setDownloadLoading(false);
      setDownloadDialogOpen(false);
    } catch {
      setDownloadLoading(false);
      setDownloadError(true);
    }
  }, [site, options, tableRef]);

  const getData = useCallback(async (query: Query) => {
    setErrorMessage(null);
    try {
      const queryOptions = { ...query };
      if (!fetchedData && page != null && search != null) {
        queryOptions.page = Number(page);
        queryOptions.search = search;
      }
      const data = await getTransactionsReport(
        site, options, queryOptions, user?.id, activeReportingLabels,
      );
      if (fetchedData) {
        if ((query.search || '') !== (search || '')) {
          onSearchChange(query.search || '');
        }
        if ((query.page || 0) !== Number(page || 0)) {
          onPageChange(String(query.page || 0));
        }
      }
      setFetchedData(true);
      return data;
    } catch (error) {
      const { message } = error as Error;
      setErrorMessage(message || 'Something went wrong getting Transaction Report');
      return {
        data: [],
        page: 0,
        totalCount: 0,
      };
    }
  }, [site, options, user, page, search, fetchedData]);

  const labelString = useMemo(() => {
    if (options.start === '' || options.end === '') return null;
    const type = options.type === DateType.TradingDate
      ? 'Trading'
      : 'Transaction';
    return `${type} Date: ${moment(options.start).format('DD/MM/YYYY')} to ${moment(options.end).format('DD/MM/YYYY')}`;
  }, [options]);

  const renderLabelChip = useMemo(() => {
    if (options.reportingLabels == null) return null;
    if (options.reportingLabels.length === 0) return null;

    const names = siteLabels
      .filter((label) => options.reportingLabels.includes(label.id))
      .map(({ text }) => text);

    return (
      <Chip
        label={`Reporting Label: ${names.join(', ')}`}
        color="secondary"
        variant="outlined"
      />
    );
  }, [options]);

  const splitTip = useMemo(() => siteData && siteData.splitTip, [siteData]);
  const splitPayout = useMemo(() => siteData && siteData.splitPayouts, [siteData]);

  if (errorMessage) return <Typography align="center" color="error">{errorMessage}</Typography>;

  if (page == null || search == null) return <Spinner />;

  return (
    <Grid>
      {options && (
        <>
          {hasPermission(RoleRestrictedAction.ViewTransactionReportSummaries, user) && (
            <>
              <ProcessingFeeSummary
                isLoading={isLoadingHeader}
                totalValues={transactionsReportHeaderData?.totalValues}
                chargeValues={transactionsReportHeaderData?.chargeValues}
                payoutValues={transactionsReportHeaderData?.payoutValues}
                siteData={siteData}
              />
              {siteData?.charityDonation != null && (
                <CharityDonationsTable
                  charityDonationsSummaryData={transactionsReportHeaderData?.charityDonations}
                  siteData={siteData}
                />
              )}
            </>
          )}
          <MaterialTable
            title="Transactions Report"
            tableRef={tableRef}
            columns={[
              { title: 'Order Number', field: 'order_number' },
              {
                title: 'Reporting Label',
                field: 'reportingLabels',
                render: (rowData) => (rowData.reportingLabels != null ? <Labels labels={rowData.reportingLabels} /> : ''),
                sorting: false,
              },
              { title: 'Date', field: 'order_time', render: (rowData) => moment(rowData.order_time).format('Do MMMM YYYY, HH:mm:ss') },
              { title: 'PLU', field: 'plu' },
              { title: 'Name', field: 'name' },
              { title: 'Quantity', field: 'quantity' },
              { title: 'Item Price', field: 'total', render: (rowData) => formatMoney(rowData.price, siteData?.currency) },
              { title: 'Item Total', field: 'total', render: (rowData) => formatMoney(rowData.total, siteData?.currency) },
              {
                title: 'Discount',
                field: 'discount_amount',
                render: (rowData) => (rowData.discount_amount
                  ? formatMoney(rowData.discount_amount, siteData?.currency)
                  : '-'),
              },
              {
                title: 'Tip',
                field: 'tip',
                render: (rowData) => formatMoney(rowData.tip, siteData?.currency),
                hidden: !splitTip,
              },
              {
                title: 'Charge',
                field: 'charge',
                render: (rowData) => formatMoney(rowData.charge, siteData?.currency),
                hidden: !splitPayout,
              },
              { title: 'Commission', field: 'commission', render: (rowData) => formatMoney(rowData.commission, siteData?.currency) },
              {
                title: 'Payout',
                field: 'payout',
                render: (rowData) => formatMoney(rowData.payout, siteData?.currency),
                hidden: !splitPayout,
              },
              {
                title: 'Payment Method',
                field: 'payment_method',
              },
            ]}
            data={getData}
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: refreshData,
              },
            ]}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
              searchText: search,
              debounceInterval: 1000,
            }}
            components={{
              Toolbar: (props: ToolbarProps) => (
                <div>
                  <MTableToolbar {...props} />
                  <div style={{ padding: 10 }}>
                    <Chip
                      style={{ marginRight: 10 }}
                      icon={<DownloadIcon />}
                      label="Download Transaction Report"
                      onClick={handleDownloadReportClick}
                      color="primary"
                    />
                    {labelString && (
                      <Chip
                        style={{ marginRight: 10 }}
                        label={labelString}
                        color="secondary"
                        variant="outlined"
                      />
                    )}
                    {renderLabelChip}
                  </div>
                </div>
              ),
            }}
          />
        </>
      )}
      <ReportDownloadDialog
        open={downloadDialogOpen}
        onCancelClick={handleDownloadCancelClick}
        onDownload={handleDownload}
        loading={downloadLoading}
        error={downloadError}
      />
    </Grid>
  );
};

export default TransactionsReportSection;
