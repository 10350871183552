import React, { useMemo } from 'react';

import { Grid } from '@material-ui/core';

import SiteInfoSection from './site-info-section';
import SettingsSection from './settings-section';
import OrderFlowSection from './OrderFlowSection';
import OnlineSection from './online-section';
import OpeningTimesSection from './opening-times-section';

import SiteData from '../../../types/SiteData';
import User from '../../../types/User';
import Nullable from '../../../types/Nullable';

type Props = {
  siteSettings: SiteData
  user: Nullable<User>
  onLoading: (value: boolean) => void
};

const SiteProfile = ({
  siteSettings, user, onLoading,
}: Props) => {
  const {
    collection,
    delivery,
    openingTimes,
  } = siteSettings;

  const isCollection = useMemo(() => collection && collection.length !== 0, [collection]);
  const isDelivery = useMemo(() => delivery && delivery.length !== 0, [delivery]);

  return (
    <Grid container spacing={3}>
      <SiteInfoSection settings={siteSettings} />
      <SettingsSection settings={siteSettings} />
      <OrderFlowSection siteData={siteSettings} />
      {isCollection || isDelivery ? (
        <OnlineSection
          settings={siteSettings}
          isCollection={isCollection || false}
          isDelivery={isDelivery || false}
          setLoading={(value) => onLoading(value)}
          user={user}
        />
      ) : null}
      {openingTimes ? <OpeningTimesSection settings={siteSettings} /> : null}
    </Grid>
  );
};

export default SiteProfile;
