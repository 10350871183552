import request from '../../../helpers/request';
import getQueryString from './getQueryString';

import SalesReportHeaderData from '../types/SalesReportHeaderData';
import Options from '../types/Options';

const getSalesReportHeader = async (
  site: string, options: Options, userId?: string, reportingLabels?: string[],
): Promise<SalesReportHeaderData> => {
  const endpoint = getQueryString('salesReportHeader', site, options, userId, undefined, reportingLabels);

  const result = await request(endpoint, 'GET');
  return result;
};

export default getSalesReportHeader;
