import SiteOpeningTime from '../types/SiteOpeningTime';

const createDefaultOpeningTimes = (): SiteOpeningTime[] => (
  [...Array(7).keys()].map((el, index) => ({
    day: index,
    closed: false,
    open: '16:30',
    kitchenOpen: '16:30',
    close: '22:30',
    kitchenClose: '22:30',
  }))
);

export default createDefaultOpeningTimes;
