import AuthUrl from '../types/AuthUrl';
import AUTH_URL_SCREEN_MAP from '../constants/AuthUrlScreenMap';
import NoMatchPage from '../containers/no-match-page/no-match-page';
import Route from '../types/Route';

const getAuthRouteComponent = (route: Route<AuthUrl>) => {
  const { url } = route;
  if (url == null) return NoMatchPage;

  return AUTH_URL_SCREEN_MAP[url];
};

export default getAuthRouteComponent;
