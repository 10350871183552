import ReconciliationReportData from '../types/ReconciliationReportData';

const getReconcilicationPayoutDetailAmount = (
  tableData: ReconciliationReportData[], vendorId: string, payoutTitle: string,
) => {
  const foundVendor = tableData.find((vendor) => vendor.vendorId === vendorId);
  const foundPayout = foundVendor?.payoutDetails.find((payouts) => (
    payouts.payoutTitle === payoutTitle
  ));
  return foundPayout?.amount || 0;
};

export default getReconcilicationPayoutDetailAmount;
