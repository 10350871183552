import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import firebase from 'firebase';

import { DataGrid, GridCellParams } from '@material-ui/data-grid';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import ErrorDialog from '../../../components/ErrorDialog/ErrorDialog';
import TableHeader from './TableHeader';

import isGiftCardProduct from '../../../helpers/isGiftCardProduct';
import formatMoney from '../../../helpers/formatMoney';
import removeProduct from '../../../helpers/remove-product';

import Nullable from '../../../types/Nullable';
import SiteData from '../../../types/SiteData';
import ProductData from '../../../types/ProductData';
import ProductType from '../../../types/ProductType';
import AppUrl from '../../../types/AppUrl';

type Props = {
  siteData: SiteData
};

const GiftCardProducts = ({ siteData }: Props) => {
  const [products, setProducts] = useState<ProductData[]>([]);
  const [error, setError] = useState<Nullable<Error>>(null);
  const history = useHistory();

  useEffect(() => {
    const unsub = firebase
      .firestore()
      .collection('masterproducts')
      .where('site', '==', siteData.site)
      .where('type', '==', ProductType.GiftCard)
      .onSnapshot((snapshot) => {
        const results: ProductData[] = [];
        snapshot.docs.forEach((doc) => {
          const data = { id: doc.id, ...doc.data() };
          if (isGiftCardProduct(data)) results.push(data);
        });
        setProducts(results);
      }, (err) => {
        setError(err);
      });
    return () => unsub();
  }, [siteData]);

  const handleErrorOkClick = () => {
    history.go(0);
  };

  const handleAddClick = () => {
    history.push(AppUrl.AddGiftCardProduct);
  };

  const handleRemove = async (productId: string) => {
    try {
      await removeProduct(productId);
    } catch {
      setError(new Error('Could not delete product, please try again.'));
    }
  };

  return (
    <>
      <DataGrid
        components={{ Header: TableHeader }}
        componentsProps={{ header: { onAddClick: handleAddClick } }}
        rows={products}
        columns={[
          { field: 'plu', headerName: 'PLU', flex: 0.5 },
          { field: 'name', headerName: 'Name', flex: 1 },
          {
            field: 'price',
            headerName: 'Price',
            flex: 0.5,
            renderCell: (params: GridCellParams) => (
              formatMoney(params.row.price, siteData?.currency)
            ),
          },
          {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params: GridCellParams) => (
              <Grid>
                <Tooltip title="Edit Gift Card Product">
                  <IconButton
                    onClick={() => {
                      history.push(AppUrl.EditGiftCardProduct.replace(':productId', params.row.id));
                    }}
                    color="inherit"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Remove Gift Card Product">
                  <IconButton
                    onClick={() => handleRemove(params.row.id)}
                    color="inherit"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            ),
          },
        ]}
        autoHeight
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
      <ErrorDialog
        open={error != null}
        onOkButtonClick={handleErrorOkClick}
        message={error?.message}
      />
    </>
  );
};

export default GiftCardProducts;
