import { DateTime } from 'luxon';

import isOnTradingDay from './isOnTradingDay';
import modulus from './modulus';

import SiteOpeningTime from '../../../types/SiteOpeningTime';

const getTradeDate = (openingTimes: SiteOpeningTime[] | null) => {
  const now = DateTime.now();
  if (!openingTimes) return now.toISODate();
  const todayOpeningTimes = openingTimes
    .find((time) => time.day === modulus(now.weekday - 1, 7));

  if (todayOpeningTimes
    && isOnTradingDay(todayOpeningTimes.open, todayOpeningTimes.close, now)) {
    return now.toISODate();
  }

  const yesterdayOpeningTimes = openingTimes
    .find((time) => time.day === modulus(now.weekday - 2, 7));

  if (yesterdayOpeningTimes
    && isOnTradingDay(
      yesterdayOpeningTimes.open, yesterdayOpeningTimes.close, now.minus({ days: 1 }),
    )) {
    return now.minus({ days: 1 }).toISODate();
  }

  return now.toISODate();
};

export default getTradeDate;
