import firebase from 'firebase/app';

import {
  VERIFICATION_START,
  AUTHORISATION_SUCCESS,
  AUTHORISATION_ERROR,
} from '../constants/actions';

export default (uid) => (dispatch) => {
  dispatch({
    type: VERIFICATION_START,
  });

  firebase
    .firestore()
    .collection('userdata')
    .doc(uid)
    .onSnapshot((snapshot) => {
      const user = snapshot.data();
      let userSiteConfig = user.sites.find((siteConfig) => siteConfig.site === user.site);
      if (!userSiteConfig) {
        const [fallbackConfig] = user.sites;
        userSiteConfig = fallbackConfig;
      }
      user.site = userSiteConfig.site;
      if (user != null && userSiteConfig != null) {
        return dispatch({
          type: AUTHORISATION_SUCCESS,
          user: { ...user, id: snapshot.id },
        });
      }
      throw Error('User is missing site data.');
    },
    () => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          dispatch({
            type: AUTHORISATION_ERROR,
            message: 'Permission denied.',
          });
        });
    });
};
