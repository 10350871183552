import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box, makeStyles, Typography } from '@material-ui/core';

import Spinner from '../../components/spinner/spinner';
import GiftCardProductForm from '../../components/GiftCardProductForm/GiftCardProductForm';

import getSiteMap from '../../actions/get-site-map';
import getVatRates from '../../actions/getVatRates';

import addProduct from '../../helpers/add-product';
import changeSiteMap from '../../helpers/change-site-map';

import AppUrl from '../../types/AppUrl';
import ReduxState from '../../types/ReduxState';
import Nullable from '../../types/Nullable';
import User from '../../types/User';
import SiteMapCategory from '../../types/SiteMapCategory';
import VatRate from '../../types/VatRate';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: theme.spacing(52),
  },
  title: {
    margin: theme.spacing(2, 0, 2),
  },
  textInput: {
    marginBottom: theme.spacing(2),
  },
  inputErrorText: {
    marginBottom: theme.spacing(5),
  },
}));

const AddGiftCardProductPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Nullable<string>>(null);
  const authenticatedUser = useSelector<ReduxState, Nullable<User>>(
    (state) => state.authentication.get('USER'),
  );
  const isSiteMapLoading = useSelector<ReduxState, boolean>(
    (state) => state.siteMap.get('IS_SITE_MAP_LOADING'),
  );
  const siteMapId = useSelector<ReduxState, string>(
    (state) => state.siteMap.get('SITE_MAP_ID'),
  );
  const siteMap = useSelector<ReduxState, SiteMapCategory[]>(
    (state) => state.siteMap.get('SITE_MAP'),
  );
  const isVatRatesLoading = useSelector<ReduxState, boolean>((state) => state.vatRates
    .get('IS_VAT_RATES_LOADING'));
  const vatRates = useSelector<ReduxState, VatRate[]>((state) => state.vatRates.get('VAT_RATES'));
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getVatRates());
  }, []);

  useEffect(() => {
    if (authenticatedUser != null) {
      const { site: currentSite } = authenticatedUser;
      dispatch(getSiteMap(currentSite));
    }
  }, [authenticatedUser]);

  const handleSave = useCallback(async (giftCardProduct, newMenu, image) => {
    setIsLoading(true);
    setError(null);

    try {
      if (authenticatedUser == null) throw new Error('Missing user data.');
      await addProduct({ ...giftCardProduct, site: authenticatedUser.site }, image);

      if (newMenu.length !== 0) {
        await changeSiteMap(siteMapId, { site: authenticatedUser.site, products: newMenu });
      }

      setIsLoading(false);
      history.push(`${AppUrl.GiftCards}?tab=2`);
    } catch (saveError) {
      const { message: errorMessage } = saveError as Error;
      setError(errorMessage);
      setIsLoading(false);
    }
  }, []);

  if (isLoading || isSiteMapLoading || isVatRatesLoading) return <Spinner />;

  return (
    <Box className={classes.container}>
      <Typography variant="h6" className={classes.title}>Create Gift Card</Typography>
      <GiftCardProductForm
        siteMap={siteMap.map((m) => ({ ...m, id: m.categoryId || '' }))}
        vatRates={vatRates}
        onSave={handleSave}
      />
      {error && <Typography variant="body1" color="error">{error}</Typography>}
    </Box>
  );
};

export default AddGiftCardProductPage;
