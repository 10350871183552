import * as Joi from 'joi';

import DiscountCode from '../types/DiscountCode';

const isDiscountCodes = (value: unknown): value is DiscountCode[] => {
  const schema = Joi.array().items(Joi.object({
    code: Joi.string().required(),
    maxUsages: Joi.number()
      .greater(0)
      .allow(null)
      .required(),
    usages: Joi.number().required(),
  })).required();

  const result = schema.validate(value);

  return result.error == null;
};

export default isDiscountCodes;
