import React, { useCallback, useMemo, useState } from 'react';
import firebase from 'firebase/app';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core';

import OutlinedTextField from '../../../components/outlined-text-field/outlined-text-field';
import SimpleSelect from '../../../components/SimpleSelect/SimpleSelect';
import BackdropSpinner from '../../../components/backdrop-spinner/backdrop-spinner';
import SnackBar from '../../../components/snack-bar/snack-bar';
import RefundItem from './RefundItem';

import hasPermission from '../../../helpers/has-permission';
import request from '../../../helpers/request';
import formatMoney from '../../../helpers/formatMoney';
import canUserViewProduct from '../../../helpers/can-user-view-product';

import refundReasons from '../constants/refundReasons';

import RoleRestrictedAction from '../../../types/RoleRestrictedAction';
import OrderData from '../../../types/OrderData';
import User from '../../../types/User';
import SiteData from '../../../types/SiteData';
import Nullable from '../../../types/Nullable';
import OrderStatusType from '../../../types/OrderStatusType';
import ItemToRefund from '../types/ItemToRefund';

type Props = {
  siteData: SiteData
  orderData: OrderData
  user: User
};

const RefundConfirmation = ({ siteData, orderData, user }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [snackOpen, setSnackOpen] = useState<boolean>(false);
  const [itemsToRefund, setItemsToRefund] = useState<ItemToRefund[]>([]);
  const [refundReason, setRefundReason] = useState<string>('');
  const [additionalInfo, setAdditionalInfo] = useState<string>('');
  const [refundError, setRefundError] = useState<Nullable<string>>(null);
  const [refundRequested, setRefundRequested] = useState<boolean>(false);
  const [spinner, setSpinner] = useState<boolean>(false);

  const userSiteConfig = useMemo(
    () => user?.sites.find(({ site }) => site === user.site),
    [user],
  );

  const orderItems = useMemo(() => (
    orderData.order.filter((item) => {
      if (userSiteConfig == null || !canUserViewProduct(item, userSiteConfig)) return false;
      if (item.discount) return false;
      return item.quantity !== 0;
    })
  ), [orderData, userSiteConfig]);

  const amount = useMemo(() => (
    itemsToRefund.reduce<number>((total, item) => {
      const { refundAmount, refundedAddOns } = item;
      const addOnsTotal = refundedAddOns
        .reduce<number>((subTotal, addOn) => subTotal + addOn.refundAmount, 0);
      return total + refundAmount + addOnsTotal;
    }, 0)
  ), [itemsToRefund]);

  const amountAlreadyRefunded = useMemo(() => {
    const { paymentIntent } = orderData;
    if (paymentIntent == null || paymentIntent.amount_refunded == null) return 0;
    return paymentIntent.amount_refunded / 100;
  }, [orderData]);

  const isTipARefundableItem = useMemo(() => {
    const tipItem = orderData.order.find((item) => item.tip);
    return tipItem != null;
  }, [orderData]);

  const amountLeftToBeRefunded = useMemo(() => (
    /* multiplying by 100 ensures these values are not decimal, preventing issues in cases such as
    1.1 - 1 = 0.10000000000000009 */
    ((orderData.total * 100) - (amountAlreadyRefunded * 100)) / 100
  ), [isTipARefundableItem, orderData]);

  const handleClose = useCallback(() => {
    setSnackOpen(false);
  }, []);

  const handleConfirm = async () => {
    if (orderData.paymentIntent && orderData.paymentIntent.id) {
      setSpinner(true);
      try {
        const idToken = await firebase.auth().currentUser?.getIdToken();
        await request(
          'stripeRefundItems',
          'POST',
          {
            orderNumber: orderData.orderNumber,
            refundReason,
            additionalInfo,
            itemsToRefund,
          },
          { Authorization: `Bearer ${idToken}` },
        );
        setRefundRequested(true);
        setSpinner(false);
        setOpen(false);
        setSnackOpen(true);
        setItemsToRefund([]);
        setRefundReason('');
        setAdditionalInfo('');
      } catch {
        setSpinner(false);
        setRefundError('Something went wrong');
        handleClose();
      }
    } else {
      setRefundError('Order is missing charge ID, please contact support.');
    }
  };

  const addItemToRefund = useCallback((newItemToRefund: ItemToRefund) => {
    setItemsToRefund((currentValue) => [...currentValue, newItemToRefund]);
  }, []);

  const removeItemToRefund = useCallback((cartId: string) => {
    setItemsToRefund((currentValue) => currentValue.filter((item) => item.cartId !== cartId));
  }, []);

  const updateItemToRefund = useCallback((updatedItemToRefund: ItemToRefund) => {
    setItemsToRefund((currentValue) => currentValue.map((item) => {
      if (item.cartId !== updatedItemToRefund.cartId) return item;
      return updatedItemToRefund;
    }));
  }, []);

  const refundButtonTooltip = useMemo(() => {
    if (orderData.status === OrderStatusType.FullRefund) return 'Order already fully refunded';
    if (!hasPermission(RoleRestrictedAction.RefundOrder, user)) return 'User has no permissions to refund';
    if (refundRequested) return 'Refund in progress';
    if (!siteData.stripeConnectActive) return 'Site has not setup stripe';
    return 'Refund Order';
  }, [orderData, hasPermission, user, refundRequested, siteData]);

  const isRefundButtonDisabled = useMemo(() => orderData.paymentIntent == null
    || orderData.status === OrderStatusType.FullRefund
    || !hasPermission(RoleRestrictedAction.RefundOrder, user)
    || refundRequested
    || !siteData.stripeConnectActive, [orderData, hasPermission, user, refundRequested, siteData]);

  const isDisabled = useMemo(() => itemsToRefund.length === 0
    || refundReason.length === 0, [itemsToRefund, refundReason]);

  return (
    <div>
      <Tooltip disableFocusListener disableTouchListener title={refundButtonTooltip}>
        <span>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => setOpen(true)}
            disabled={isRefundButtonDisabled}
          >
            {refundRequested ? 'Refund Requested' : 'Refund Order'}
          </Button>
        </span>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Refund order: ${orderData.orderNumber}`}</DialogTitle>
        <DialogContent dividers>
          <BackdropSpinner open={spinner} />
          <DialogContentText id="alert-dialog-description">
            Refunds take 5-10 days to appear on a customers statement
          </DialogContentText>
          {orderItems.map((orderItem) => (
            <RefundItem
              key={orderItem.cartId}
              orderItem={orderItem}
              itemsToRefund={itemsToRefund}
              addItemtoRefund={addItemToRefund}
              removeItemToRefund={removeItemToRefund}
              updateItemToRefund={updateItemToRefund}
            />
          ))}
          <SimpleSelect label="Refund Reason" value={refundReason} items={refundReasons} onChange={setRefundReason} labelWidth={110} />
          <OutlinedTextField
            id="outlined-name"
            classOption="flex"
            label="Additional Information"
            value={additionalInfo}
            onChange={(event) => setAdditionalInfo(event.target.value)}
          />
          <Typography variant="body1">
            {`Refund amount: ${formatMoney(amount, siteData.currency)}${!isTipARefundableItem ? '*' : ''} out of ${formatMoney(amountLeftToBeRefunded, siteData.currency)}`}
          </Typography>
          {!isTipARefundableItem && (
            <Typography variant="body2">
              *Customer tips related to this order will be refunded up to their full value based on
              the items selected above.
            </Typography>
          )}
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            {refundError ? <Typography variant="body1" color="error" gutterBottom>{refundError}</Typography> : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus disabled={isDisabled}>
            Refund
          </Button>
        </DialogActions>
      </Dialog>
      <SnackBar
        open={snackOpen}
        onClose={handleClose}
        message="Refund successful - order will be updated soon."
      />
    </div>
  );
};

export default RefundConfirmation;
