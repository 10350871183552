import React,
{
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@material-ui/core';

import Spinner from '../../components/spinner/spinner';
import UsersTable from '../../components/UsersTable/UsersTable';

import getUsers from '../../actions/get-users';

import ReduxState from '../../types/ReduxState';
import Nullable from '../../types/Nullable';
import User from '../../types/User';

const UsersPage = () => {
  const isUsersLoading = useSelector<ReduxState, boolean>((state) => state.users.get('IS_USERS_LOADING'));
  const users = useSelector<ReduxState, User[]>((state) => state.users.get('USERS'));
  const [error, setError] = useState<Nullable<string>>(null);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const onEdit = useCallback((userId: string) => {
    history.push(`/admin/users/${userId}`);
  }, [history]);

  const onAdd = useCallback(() => history.push('/admin/users/add'), [history]);

  const handleErrorOkClick = useCallback(() => setError(null), [setError]);

  if (isUsersLoading) return <Spinner />;

  return (
    <Grid>
      <UsersTable
        tableData={users}
        onEdit={onEdit}
        onAdd={onAdd}
      />
      <Dialog
        open={error != null}
      >
        <DialogTitle>Something went wrong</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {error}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorOkClick} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default UsersPage;
