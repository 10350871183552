import getSiteOpeningTimes from './get-site-opening-times';
import getTradeDate from './getTradeDate';

import SiteOpeningTime from '../../../types/SiteOpeningTime';

const getSiteTradeDate = async (site: string): Promise<string> => {
  const siteOpeningTimes: SiteOpeningTime[] = await getSiteOpeningTimes(site);
  const tradeDate: string = getTradeDate(siteOpeningTimes);
  return tradeDate;
};

export default getSiteTradeDate;
