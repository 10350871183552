import Category from '../../../types/Category';

const findProductOnSiteMap = (
  plu: number,
  siteMap: Category[],
): Array<{ category: string, subcategory: string }> => {
  const locations: Array<{ category: string, subcategory: string }> = [];

  siteMap.forEach((category) => {
    const { subCategories } = category;
    subCategories.forEach((subCategory) => {
      const { products } = subCategory;
      products.forEach((product) => {
        if (product.plu === plu) {
          locations.push({ category: category.category, subcategory: subCategory.name });
        }
      });
    });
  });

  return locations;
};

export default findProductOnSiteMap;
