import SiteAddress from '../types/SiteAddress';

const formatSiteAddress = (address: SiteAddress) => {
  const {
    line1,
    line2,
    city,
    country,
    postCode,
  } = address;
  return `${line1}, ${line2 != null ? `${line2}, ` : ''}${city}, ${postCode}, ${country}`;
};

export default formatSiteAddress;
