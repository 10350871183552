import OrderItem from '../../../types/OrderItem';

const sortOrderItems = (mixedOrderItems: OrderItem[]): OrderItem[] => {
  const newOrderItems = [...mixedOrderItems];
  const sortedOrderItems = newOrderItems.sort((a, b) => {
    if (
      a.reportingLabels == null
      || b.reportingLabels == null
    ) {
      return 0;
    }
    if (
      a.reportingLabels[0] < b.reportingLabels[0]
      || a.reportingLabels.length === 0
    ) {
      return -1;
    }
    return 1;
  });
  return sortedOrderItems;
};

export default sortOrderItems;
