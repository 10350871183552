import React from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

type Props<T extends string> = {
  className?: string
  label: string
  defaultValues: T[]
  options: { label: string, value: T }[]
  onChange: (value: T[]) => void
  helperText?: string
};

const MultipleSelect = <T extends string>({
  className,
  label,
  defaultValues,
  options,
  onChange,
  helperText,
}: Props<T>) => (
  <Autocomplete
    className={className}
    multiple
    options={options}
    value={options.filter((option) => defaultValues.includes(option.value))}
    onChange={(_, newValue) => onChange(newValue.map(({ value }) => value))}
    getOptionLabel={(option) => option.label}
    filterSelectedOptions
    renderInput={(params) => (
      <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
        variant="outlined"
        label={label}
        helperText={helperText}
      />
    )}
  />
  );

export default MultipleSelect;
