import Currency from '../types/Currency';

const getCurrencyAdornment = (currency?: Currency) => {
  switch (currency) {
    case Currency.EUR:
      return '€';
    case Currency.GBP:
      return '£';
    default:
      return '£';
  }
};

export default getCurrencyAdornment;
