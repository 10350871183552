import React, { useCallback, useState } from 'react';

import {
  Button, Grid, IconButton, Typography, makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import InfoIcon from '@material-ui/icons/Info';

import SearchToolbar from './SearchToolbar';
import DiscountCodesInfo from './DiscountCodesInfo';

import hasPermission from '../../../helpers/has-permission';
import downloadDiscountCodes from '../helpers/downloadDiscountCodes';

import RoleRestrictedAction from '../../../types/RoleRestrictedAction';
import User from '../../../types/User';
import ProductData from '../../../types/ProductData';
import SiteData from '../../../types/SiteData';
import Nullable from '../../../types/Nullable';
import DiscountCode from '../../../types/DiscountCode';

type Props = {
  discountCodes: DiscountCode[]
  value: string
  onChange: (value: string) => void
  clearSearch: () => void
  user: Nullable<User>
  onRestock: () => void
  products: ProductData[]
  siteData: SiteData
  filter: string
  onFilterChange: (productFilter: string) => void
  onCreate: () => void
  onUpload: () => void
};

const useStyles = makeStyles(() => ({
  toolbar: {
    margin: 20,
  },
}));

const Toolbar = ({
  discountCodes, value, onChange, clearSearch, user, onCreate, onUpload,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const classes = useStyles();

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  }, [onChange]);

  const handleDownload = useCallback(() => downloadDiscountCodes('discount_codes_template.csv'), [discountCodes]);

  return (
    <Grid className={classes.toolbar}>
      <Grid
        container
        direction="row"
        justify="space-between"
      >
        <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography style={{ marginRight: 5 }} variant="h6">2. Discount Codes</Typography>
          <IconButton
            onClick={() => setIsOpen(true)}
            color="inherit"
          >
            <InfoIcon />
          </IconButton>
        </Grid>
        <Grid>
          <SearchToolbar
            value={value}
            onChange={handleChange}
            clearSearch={clearSearch}
          />
          <Button
            style={{ marginRight: 10, marginBottom: 10 }}
            variant="outlined"
            color="primary"
            onClick={handleDownload}
            startIcon={<GetAppIcon />}
          >
            Download Template
          </Button>
          <Button
            style={{ marginRight: 10, marginBottom: 10 }}
            variant="outlined"
            color="primary"
            onClick={onUpload}
            startIcon={<PublishIcon />}
          >
            Upload Codes
          </Button>
          <Button
            style={{ marginBottom: 10 }}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={onCreate}
            disabled={!hasPermission(RoleRestrictedAction.CreateDiscountCode, user)}
          >
            Add Code
          </Button>
        </Grid>
      </Grid>
      <DiscountCodesInfo open={isOpen} onClose={() => setIsOpen(false)} />
    </Grid>
  );
};

export default Toolbar;
