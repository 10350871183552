import React from 'react';

import { Button, Grid } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';

import downloadReport from '../helpers/downloadReport';

import GiftCardAuditTrail from '../../../types/GiftCardAuditTrail';

type Props = {
  auditTrail: GiftCardAuditTrail[]
  site: string
};

const Toolbar = ({ auditTrail, site }: Props) => (
  <Grid>
    <Button
      style={{ margin: 10 }}
      startIcon={<DownloadIcon />}
      onClick={() => downloadReport(auditTrail, site)}
      variant="outlined"
      color="primary"
    >
      Download Report
    </Button>
  </Grid>
);

export default Toolbar;
