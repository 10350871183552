import { createContext } from 'react';
import AlertMessage from '../types/AlertMessage';
import Nullable from '../types/Nullable';

interface ContextValue {
  setMessage: (message: Nullable<AlertMessage>) => void
}

const AlertContext = createContext<ContextValue>({ setMessage: () => null });

export default AlertContext;
