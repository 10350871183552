import React, { useCallback, useMemo, useState } from 'react';

import MaterialTable from 'material-table';
import { Grid } from '@material-ui/core';

import RemoveItemDialog from '../../../components/RemoveItemDialog/RemoveItemDialog';

import hasPermission from '../../../helpers/has-permission';

import RoleRestrictedAction from '../../../types/RoleRestrictedAction';

import SlotTime from '../../../types/SlotTime';
import User from '../../../types/User';
import Nullable from '../../../types/Nullable';
import SiteData from '../../../types/SiteData';

type Props = {
  slotTimes: SlotTime[]
  onCreate: () => void
  onEdit: (slotTime: SlotTime) => void
  onRemove: (id: string) => void
  user: User
  siteData: SiteData
};

const SlotTimesTable = ({
  slotTimes, onCreate, onEdit, onRemove, user, siteData,
}: Props) => {
  const [itemToRemove, setItemToRemove] = useState<Nullable<SlotTime>>(null);

  const handleRemove = useCallback(() => {
    if (itemToRemove && itemToRemove.id) {
      onRemove(itemToRemove.id);
    }
  }, [onRemove, itemToRemove]);

  const openRemoveDialog = useCallback(setItemToRemove, [setItemToRemove]);

  const closeRemoveDialog = useCallback(() => setItemToRemove(null), [setItemToRemove]);

  const description = useMemo(() => (itemToRemove
    ? `Are you sure you want to remove ${itemToRemove.time}`
    : ''), [itemToRemove]);

  return (
    <Grid>
      <MaterialTable
        title="Slot Times"
        columns={[
          { title: 'Day', field: 'day' },
          { title: 'Time', field: 'time', render: (rowData) => (rowData.allDaySlot ? 'All Day' : rowData.time) },
          { title: 'Slot Capacity', field: 'slotCapacity' },
          { title: 'Type', field: 'type' },
        ]}
        actions={[
          {
            icon: 'add',
            tooltip: 'Create Slot Time',
            isFreeAction: true,
            disabled: !hasPermission(RoleRestrictedAction.CreateSlotTime, user)
              || siteData?.allDaySlots,
            onClick: onCreate,
          },
          (rowData) => ({
            icon: 'edit',
            tooltip: 'Edit Slot Time',
            disabled: !hasPermission(RoleRestrictedAction.EditSlotTime, user),
            onClick: () => onEdit(rowData),
          }),
          (rowData) => ({
            icon: 'delete',
            tooltip: 'Remove Slot Time',
            disabled: !hasPermission(RoleRestrictedAction.RemoveSlotTime, user),
            onClick: () => openRemoveDialog(rowData),
          }),
        ]}
        data={slotTimes}
        options={{
          actionsColumnIndex: -1,
          pageSize: slotTimes.length > 5 ? 10 : 5,
        }}
      />
      <RemoveItemDialog
        title="Remove Slot Time"
        description={description}
        open={itemToRemove != null}
        onRemove={handleRemove}
        handleClose={closeRemoveDialog}
      />
    </Grid>
  );
};

export default SlotTimesTable;
