import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
} from '@material-ui/core';

import Spinner from '../../../components/spinner/spinner';
import request from '../../../helpers/request';
import getQueryString from '../helpers/getQueryString';
import Options from '../types/Options';
import Nullable from '../../../types/Nullable';
import DiscountsReportData from '../../../types/DiscountsReportData';

interface Params {
  options: Options;
  site: Nullable<string>;
}

const useStyles = makeStyles(() => ({
  tableHeadCell: { fontWeight: 'bold' },
}));

const DiscountsReport = ({ options, site }: Params) => {
  const classes = useStyles();

  const [tableData, setTableData] = useState<DiscountsReportData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (site == null) return;

    const queryString = getQueryString(
      'discountsReport',
      site,
      options,
      undefined,
      undefined,
      options.reportingLabels,
    );
    request(queryString, 'GET')
      .then((response) => setTableData(response))
      .catch(() => setError('Commission Report could not be created. Please try again.'))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) return <Spinner />;

  if (error.length > 0) return <Typography align="center" color="error">{error}</Typography>;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeadCell}>Name</TableCell>
            <TableCell className={classes.tableHeadCell}>Code</TableCell>
            <TableCell className={classes.tableHeadCell}>Total Discounted (£)</TableCell>
            <TableCell className={classes.tableHeadCell}>Frequency</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row) => (
            <TableRow key={`${row.name}#${row.code}`}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.code}</TableCell>
              <TableCell>{row.amount.toFixed(2)}</TableCell>
              <TableCell>{row.count}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DiscountsReport;
