import React, { MouseEvent, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Badge, Button, Divider, Grid, IconButton, Typography, Menu, makeStyles,
} from '@material-ui/core';
import { Notifications } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';

import clearNotification from '../../../helpers/clearNotification';
import clearAllNotifications from '../../../helpers/clearAllNotifications';

import AppUrl from '../../../types/AppUrl';
import NotificationData from '../../../types/NotificationData';
import Nullable from '../../../types/Nullable';

const useStyles = makeStyles(() => ({
  notificationsTitle: {
    margin: 10,
  },
  notificationsSection: {
    margin: 10,
    width: 350,
  },
  link: {
    marginBottom: 10,
  },
  notification: {
    marginBottom: 5,
  },
  noNotifications: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: 50,
    marginBottom: 50,
  },
}));

type Props = {
  notifications: NotificationData[]
};

const NotificationMenu = ({ notifications }: Props) => {
  const [anchorEl, setAnchorEl] = useState<Nullable<EventTarget & Element>>(null);
  const classes = useStyles();

  const handleOpen = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpen = useMemo(() => anchorEl != null, [anchorEl]);

  return (
    <>
      <IconButton
        aria-label="alert-menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpen}
        color="inherit"
      >
        <Badge badgeContent={notifications.length} color="secondary">
          <Notifications />
        </Badge>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isOpen}
        onClose={handleClose}
      >
        <Grid>
          <Grid container direction="row" justify="space-between">
            <Typography className={classes.notificationsTitle} variant="h5" gutterBottom>
              Notifications
            </Typography>
            <IconButton onClick={handleClose} color="inherit">
              <CloseIcon />
            </IconButton>
          </Grid>
          <Divider />
          <Grid className={classes.notificationsSection}>
            <Grid container direction="row" justify="space-between" className={classes.link}>
              <Button variant="text" component={RouterLink} to={AppUrl.Notifications}>
                View All
              </Button>
              <Button variant="text" onClick={() => clearAllNotifications(notifications)} disabled={notifications.length === 0}>
                Clear All
              </Button>
            </Grid>
            {
              notifications.length !== 0 ? (
                notifications.map((notification) => (
                  <Alert
                    key={notification.id}
                    className={classes.notification}
                    severity={notification.type}
                    onClose={() => clearNotification(notification.id)}
                  >
                    {notification.message}
                  </Alert>
                ))
              ) : (
                <Grid container justify="center">
                  <Typography className={classes.noNotifications} variant="h5" gutterBottom>
                    No active notifications
                  </Typography>
                </Grid>
              )
            }
          </Grid>
        </Grid>
      </Menu>
    </>
  );
};

export default NotificationMenu;
