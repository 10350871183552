import React from 'react';

import {
  Box,
  Divider,
  Grid,
  Paper,
  Typography,
  makeStyles,
  Avatar,
} from '@material-ui/core';

import InfoField from '../../../components/info-field/info-field';

import formatMoney from '../../../helpers/formatMoney';

import ProductData from '../../../types/ProductData';
import SiteData from '../../../types/SiteData';
import Nullable from '../../../types/Nullable';
import ProductType from '../../../types/ProductType';

const useStyles = makeStyles(() => ({
  paper: { height: '100%' },
  sectionTitle: {
    marginTop: 20,
    marginLeft: 20,
    marginBottom: 5,
  },
  mainSection: {
    margin: 20,
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
  imageGrid: {
    padding: 10,
    width: 100,
    height: 100,
    marginBottom: 20,
  },
  image: {
    backgroundColor: 'black',
    width: 100,
    height: 100,
    objectFit: 'contain',
    objectPosition: '50% 50%',
  },
}));

type Props = {
  product: ProductData
  siteData?: Nullable<SiteData>
};

const ProductDetailsSection = ({ product, siteData }: Props) => {
  const classes = useStyles();
  const {
    plu, name, pluName, type, price, description, vatRate, addonDescription,
  } = product;

  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.paper} variant="outlined">
        <Typography className={classes.sectionTitle} variant="h6">Details</Typography>
        <Divider />
        <Box className={classes.mainSection}>
          <InfoField
            title="Name"
            info={name}
          />
          <InfoField
            title="PLU"
            info={plu}
          />
          <InfoField
            title="PLU Name"
            info={pluName}
          />
          <InfoField
            title="Type"
            info={type || ProductType.Product}
          />
          <InfoField
            title="Description"
            info={description}
          />
          <InfoField
            title="Add On Description"
            info={addonDescription}
          />
          <Divider className={classes.divider} />
          <InfoField
            title="Price"
            info={formatMoney(price, siteData?.currency)}
          />
          <InfoField
            title="VAT Rate"
            info={vatRate != null ? `${vatRate * 100}%` : 'Not Set'}
          />
          <Divider className={classes.divider} />
          <Typography style={{ fontWeight: 'bold' }}>Product Image:</Typography>
          {product.image ? <Avatar variant="rounded" src={product.image || ''} className={classes.image} /> : 'Not Set'}
        </Box>
      </Paper>
    </Grid>
  );
};

export default ProductDetailsSection;
