import request from '../../../helpers/request';

import OrderData from '../../../types/OrderData';
import Status from '../../../types/Status';
import Nullable from '../../../types/Nullable';
import SiteData from '../../../types/SiteData';

const notifyUser = (
  siteData: Nullable<SiteData>,
  order: OrderData,
  cartIds: string[],
  newStatus: Status,
) => {
  const orderFlowNotifyText = siteData?.orderFlow?.notifyUserText || [];
  const orderFlowNotifyEmail = siteData?.orderFlow?.notifyUserEmail || [];

  if (!orderFlowNotifyText.includes(newStatus)
    && !orderFlowNotifyEmail.includes(newStatus)) return Promise.resolve();

  return request('notifyUser', 'POST', { order, cartIds, newStatus });
};

export default notifyUser;
