import Joi from 'joi';

import StripeRequestSuccessResponse from '../../../types/StripeRequestSuccessResponse';

const isStripeRequestSuccessResponse = (value: unknown): value is StripeRequestSuccessResponse => {
  const schema = Joi.object({
    message: Joi.string().required(),
    url: Joi.string().required(),
  }).required();

  const result = schema.validate(value);
  return result.error == null;
};

export default isStripeRequestSuccessResponse;
