import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@material-ui/core';

import CloudPrintersTable from './components/CloudPrintersTable';
import CloudPrinterDialog from './components/CloudPrinterDialog';
import Spinner from '../../components/spinner/spinner';

import getSettings from '../../actions/get-settings';
import updateSiteSettings from '../../actions/update-site-settings';

import CloudPrinterData from '../../types/CloudPrinterData';
import ReduxState from '../../types/ReduxState';
import User from '../../types/User';
import SiteData from '../../types/SiteData';
import Nullable from '../../types/Nullable';
import hasPermission from '../../helpers/has-permission';
import RoleRestrictedAction from '../../types/RoleRestrictedAction';

const CloudPrintersPage = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [
    selectedCloudPrinter, setSelectedCloudPrinter,
  ] = useState<Nullable<CloudPrinterData>>(null);
  const user = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));
  const isSettingsLoading = useSelector<ReduxState, boolean>((state) => state.settings
    .get('IS_SETTINGS_LOADING'));
  const settings = useSelector<ReduxState, Nullable<SiteData>>((state) => state.settings.get('SETTINGS'));
  const dispatch = useDispatch();

  const userSiteConfig = useMemo(() => {
    if (user != null) return user.sites.find(({ site }) => site === user.site);
    return null;
  }, [user]);

  useEffect(() => {
    if (user != null) {
      dispatch(getSettings(user.site));
    }
  }, [user]);

  const handleOpen = useCallback(() => setOpen(true), []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setSelectedCloudPrinter(null);
  }, []);

  const getPrinters = useCallback((cloudPrinter: CloudPrinterData) => {
    if (settings?.cloudPrinters == null) return [cloudPrinter];
    if (selectedCloudPrinter != null) {
      return settings.cloudPrinters.map((printer) => {
        if (selectedCloudPrinter.id === printer.id) return cloudPrinter;
        return printer;
      });
    }
    return settings.cloudPrinters.concat(cloudPrinter);
  }, [settings, selectedCloudPrinter]);

  const handleSave = useCallback(async (cloudPrinter: CloudPrinterData) => {
    if (!settings) throw new Error('Settings do not exist');
    dispatch(updateSiteSettings(settings.id || '', {
      cloudPrinters: getPrinters(cloudPrinter),
    }));
    handleClose();
  }, [selectedCloudPrinter, settings, handleClose]);

  const handleEdit = useCallback((cloudPrinter: CloudPrinterData) => {
    setSelectedCloudPrinter(cloudPrinter);
  }, []);

  const handleRemove = async (id: string) => {
    if (!settings || settings.cloudPrinters == null) return null;
    const newSettings = {
      ...settings,
      cloudPrinters: settings.cloudPrinters.filter((printer) => printer.id !== id),
    };
    return dispatch(updateSiteSettings(settings.id || '', newSettings));
  };

  const cloudPrinters = useMemo(() => {
    if (!settings || settings.cloudPrinters == null) return [];
    const printersToShow = settings.cloudPrinters.filter((printer) => (
      hasPermission(RoleRestrictedAction.ViewCloudPrinter, user)
      || printer.reportingLabels.some((printerLabel) => (
        userSiteConfig?.reportingLabels.some((userLabel) => userLabel === printerLabel)
      ))
    ));
    return printersToShow;
  }, [settings]);

  const labels = useMemo(() => {
    if (!settings || settings.reportingLabels == null) return [];
    return settings.reportingLabels
      .filter((label) => {
        if (
          userSiteConfig != null
          && userSiteConfig.reportingLabels.length > 0
        ) {
          return userSiteConfig.reportingLabels.some((userLabel) => userLabel === label.id);
        }
        return label.active != null ? label.active : true;
      });
  }, [settings]);

  if (isSettingsLoading) return <Spinner />;

  return (
    <Grid>
      <CloudPrintersTable
        tableData={cloudPrinters}
        onAdd={handleOpen}
        onEdit={handleEdit}
        onRemove={handleRemove}
        labels={labels}
      />
      <CloudPrinterDialog
        open={open || selectedCloudPrinter != null}
        selectedCloudPrinter={selectedCloudPrinter}
        onSave={handleSave}
        onClose={handleClose}
        cloudPrinters={cloudPrinters}
        labels={labels}
        labelRequired={
          userSiteConfig != null
          && userSiteConfig.reportingLabels.length > 0
        }
        settings={settings}
      />
    </Grid>
  );
};

export default CloudPrintersPage;
