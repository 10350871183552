import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import firebase from 'firebase/app';
import Papa from 'papaparse';
import { DateTime } from 'luxon';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  Fade,
  Tooltip,
} from '@material-ui/core';
import {
  CheckCircle, Info, Publish, Search,
} from '@material-ui/icons';

import ImportJobStatusChip from '../../../components/ImportJobStatusChip/ImportJobStatusChip';

import apiRequest from '../../../helpers/api-request';
import isImportedGiftCard from '../helpers/isImportedGiftCard';

import User from '../../../types/User';
import Nullable from '../../../types/Nullable';
import SiteData from '../../../types/SiteData';
import ImportJob from '../../../types/ImportJob';

interface Props {
  importJobs: ImportJob[];
  getJobsErrorMessage: string;
  site: SiteData['site'];
  userEmail: User['email'];
}

const useStyles = makeStyles((theme) => ({
  importJobsCard: {
    marginBottom: theme.spacing(2),
  },
  headerBox: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerColumnBox: {
    display: 'flex',
    alignItems: 'center',
  },
  uploadButton: {
    marginRight: theme.spacing(1),
  },
  fileInput: {
    display: 'none',
  },
  loadingSpinner: {
    marginLeft: theme.spacing(1),
  },
}));

const GiftCardsImportForm = ({
  importJobs, getJobsErrorMessage, site, userEmail,
}: Props) => {
  const classes = useStyles();
  const fileInputRef = useRef<Nullable<HTMLInputElement>>(null);
  const [file, setFile] = useState<Nullable<File>>(null);
  const [previewTableRows, setPreviewTableRows] = useState<string[][]>([]);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [uploadRequested, setUploadRequested] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (getJobsErrorMessage.length > 0) {
      setErrorMessage(getJobsErrorMessage);
    }
  }, [getJobsErrorMessage]);

  useEffect(() => {
    const { current: fileInput } = fileInputRef;
    if (fileInput == null) return;

    fileInput.addEventListener('change', (event) => {
      setUploadRequested(false);
      setFile(null);
      setPreviewTableRows([]);

      const inputElement = event.target as HTMLInputElement;

      if (inputElement.files == null) {
        setErrorMessage('The file could not be read.');
        return;
      }
      const newFile = inputElement.files[0];
      if (newFile.type !== 'text/csv') {
        setErrorMessage('Unsupported file type.');
        return;
      }
      setFile(newFile);

      const reader = new FileReader();
      reader.onloadend = () => {
        const csvString = reader.result as string;
        setPreviewTableRows(
          (Papa.parse(csvString, { skipEmptyLines: true }).data as string[][]).slice(1),
        );
      };
      reader.readAsText(newFile);
    });
  }, []);

  const handleDownloadSampleClick = async () => {
    const url = await firebase
      .storage()
      .ref('test/Peazi Gift Cards Sample.csv')
      .getDownloadURL();
    window.location.href = url;
  };

  const handleUploadClick = async () => {
    if (file == null) {
      setErrorMessage('File data missing. Please re-select the file to upload.');
      return;
    }

    const timestamp = DateTime
      .now()
      .startOf('second')
      .toISO({
        includeOffset: false,
        suppressMilliseconds: true,
      });
    const filePath = `${site}/imports/gift-cards/${timestamp}.csv`;

    try {
      setUploadLoading(true);
      setUploadRequested(true);

      await firebase
        .storage()
        .ref(filePath)
        .put(file);

      await apiRequest(
        'import/gift-card',
        'POST',
        { site, filePath, userEmail },
      );
    } catch {
      setErrorMessage('Error starting import. Please try again.');
    } finally {
      setUploadLoading(false);
    }
  };

  const isAllGiftCardsValid = useMemo(() => previewTableRows.every((row) => {
    const { isValid } = isImportedGiftCard(row);
    return isValid;
  }), [previewTableRows]);

  const renderGiftCardValidRow = (giftCardRow: string[]) => {
    const { isValid, errorString } = isImportedGiftCard(giftCardRow);

    return isValid
      ? <CheckCircle style={{ color: 'green' }} />
      : (
        <Tooltip title={errorString}>
          <Info style={{ color: 'red' }} />
        </Tooltip>
      );
  };

  return (
    <>
      {importJobs.length > 0 && (
        <Card className={classes.importJobsCard}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
            >
              Active Import Jobs
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Start Time
                  </TableCell>
                  <TableCell>
                    Created By
                  </TableCell>
                  <TableCell>
                    Current Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {importJobs.map((importJob) => (
                  <TableRow key={importJob.id}>
                    <TableCell>
                      {DateTime
                        .fromMillis(importJob.created)
                        .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
                    </TableCell>
                    <TableCell>
                      {importJob.createdBy}
                    </TableCell>
                    <TableCell>
                      <ImportJobStatusChip status={importJob.status} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      )}
      <Card>
        <CardContent>
          <Typography
            variant="h6"
            gutterBottom
          >
            Import Gift Cards
          </Typography>
          <Box className={classes.headerBox}>
            <Box className={classes.headerColumnBox}>
              <label htmlFor="file-input">
                <Button
                  className={classes.uploadButton}
                  variant="contained"
                  color="primary"
                  component="span"
                  startIcon={<Search />}
                  disabled={uploadLoading}
                >
                  Choose .csv file
                </Button>
                <input
                  ref={fileInputRef}
                  accept="csv/*"
                  className={classes.fileInput}
                  id="file-input"
                  type="file"
                />
              </label>
              <Button
                variant="outlined"
                onClick={handleDownloadSampleClick}
              >
                Download sample file
              </Button>
            </Box>
            <Box className={classes.headerColumnBox}>
              {file != null && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Publish />}
                  onClick={handleUploadClick}
                  disabled={uploadRequested || !isAllGiftCardsValid}
                >
                  {`Confirm Import (${previewTableRows.length} Item${previewTableRows.length > 1 ? 's' : ''})`}
                </Button>
              )}
              <Fade in={uploadLoading}>
                <CircularProgress className={classes.loadingSpinner} size="2em" />
              </Fade>
            </Box>
          </Box>
          {errorMessage.length > 0 && (
            <Typography
              color="error"
            >
              {errorMessage}
            </Typography>
          )}
          {previewTableRows.length > 0 && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Balance (£)</TableCell>
                  <TableCell>Recipient Email</TableCell>
                  <TableCell>Recipient Name</TableCell>
                  <TableCell>Recipient Message</TableCell>
                  <TableCell>Valid From Date</TableCell>
                  <TableCell>Expiry Date</TableCell>
                  <TableCell>Valid</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {previewTableRows.slice(0, 5).map((row, i) => (
                  // there's no unique property to use as a key, and the array isn't modified
                  // eslint-disable-next-line react/no-array-index-key
                  <TableRow key={i}>
                    <TableCell>{row[0]}</TableCell>
                    <TableCell>{row[1]}</TableCell>
                    <TableCell>{row[2]}</TableCell>
                    <TableCell>{row[3]}</TableCell>
                    <TableCell>{row[4]}</TableCell>
                    <TableCell>{row[5]}</TableCell>
                    <TableCell>{row[6]}</TableCell>
                    <TableCell>
                      {renderGiftCardValidRow(row)}
                    </TableCell>
                  </TableRow>
                ))}
                {previewTableRows.length > 5 && (
                  <TableRow>
                    <TableCell align="right" colSpan={7}>
                      {`... ${previewTableRows.length - 5} more rows`}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default GiftCardsImportForm;
