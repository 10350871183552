import Axios from 'axios';

const cloudFunctionsHost = process.env.FIREBASE_FUNCTIONS;

export default (endpoint, method = 'GET', body, headers = {}) => {
  const options = {
    method,
    url: `${cloudFunctionsHost}/${endpoint}`,
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };

  if (body) {
    options.data = JSON.stringify(body);
  }

  return Axios(options)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response.data));
};
