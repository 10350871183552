import PeaziColour from '../types/PeaziColour';
import InteractionColourValue from '../types/InteractionColourValue';

const getColourCode = (colour: string, prefersDarkMode: boolean) => {
  switch (colour) {
    case InteractionColourValue.Green:
      return prefersDarkMode ? PeaziColour.DarkModeGreen : PeaziColour.LightModeGreen;
    case InteractionColourValue.Blue:
      return prefersDarkMode ? PeaziColour.DarkModeBlue : PeaziColour.LightModeBlue;
    case InteractionColourValue.Indigo:
      return prefersDarkMode ? PeaziColour.DarkModeIndigo : PeaziColour.LightModeIndigo;
    case InteractionColourValue.DeepPurple:
      return prefersDarkMode ? PeaziColour.DarkModeDeepPurple : PeaziColour.LightModeDeepPurple;
    case InteractionColourValue.Purple:
      return prefersDarkMode ? PeaziColour.DarkModePurple : PeaziColour.LightModePurple;
    default:
      return '#000000';
  }
};

export default getColourCode;
