import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
  Grid, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, FormHelperText,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const styles = () => ({
  field: {
    marginBottom: 20,
  },
  password: {
    width: 350,
  },
});

const OutlinedTextField = ({
  id,
  classes,
  label,
  value,
  onChange,
  error,
  helperText,
  labelWidth,
  disabled,
}) => {
  const [showPassword, handleShowPassword] = React.useState(false);
  return (
    <Grid>
      <FormControl className={classes.field} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
        <OutlinedInput
          id={id}
          className={classes.password}
          type={showPassword ? 'text' : 'password'}
          value={value}
          onChange={onChange}
          error={error}
          disabled={disabled}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => handleShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )}
          labelWidth={labelWidth}
          required
        />
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </Grid>
  );
};

OutlinedTextField.propTypes = {
  id: PropTypes.string.isRequired,
  classes: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  labelWidth: PropTypes.number,
  disabled: PropTypes.bool,
};

OutlinedTextField.defaultProps = {
  value: '',
  error: false,
  helperText: null,
  labelWidth: 70,
  disabled: false,
};

export default withStyles(styles)(OutlinedTextField);
