import React, {
  useMemo, useEffect, useState, useCallback,
} from 'react';
import { useSelector } from 'react-redux';

import {
  Box, Divider, Paper, Typography,
} from '@material-ui/core';

import Spinner from '../../components/spinner/spinner';
import LabelBox from './components/LabelBox';
import SiteDefaultsForm from './components/SiteDefaultsForm';
import ErrorDialog from '../../components/ErrorDialog/ErrorDialog';

import hasPermission from '../../helpers/has-permission';
import initialiseSiteListener from '../../helpers/initialiseSiteListener';
import setSiteOffline from './helpers/setSiteOffline';
import setSiteWaitingTimes from './helpers/setSiteWaitingTimes';
import setLabelOffline from './helpers/setLabelOffline';
import setLabelOnline from './helpers/setLabelOnline';
import setLabels from './helpers/setLabels';
import debounce from '../../helpers/debounce';

import ReduxState from '../../types/ReduxState';
import User from '../../types/User';
import Nullable from '../../types/Nullable';
import SiteData from '../../types/SiteData';
import RoleRestrictedAction from '../../types/RoleRestrictedAction';
import Label from '../../types/Label';

const QuickSettingsPage = () => {
  const user = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));
  const [settings, setSettings] = useState<Nullable<SiteData>>(null);
  const [error, setError] = useState<Nullable<Error>>(null);
  const [siteOfflineLoading, setSiteOfflineLoading] = useState<boolean>(false);
  const [waitingTimesLoading, setWaitingTimesLoading] = useState<boolean>(false);

  useEffect(() => {
    if (user != null) {
      initialiseSiteListener(user.site, setSettings, setError);
    }
  }, [user]);

  const userSiteConfig = useMemo(
    () => user?.sites.find((config) => config.site === user.site),
    [user],
  );

  const filteredLabels: Label[] = useMemo(() => {
    if (user != null && settings != null && settings.reportingLabels != null) {
      if (hasPermission(RoleRestrictedAction.ControlAllQuickSettings, user)) {
        return settings.reportingLabels
          .filter((label) => (label.active != null ? label.active : true));
      }
      const userReportingLabelIds = user.sites
        .find(({ site }) => site === settings.site)?.reportingLabels;
      if (
        userReportingLabelIds == null || userReportingLabelIds.length === 0
      ) {
        return settings.reportingLabels
          .filter((label) => (label.active != null ? label.active : true));
      }
      return settings.reportingLabels
        .filter(({ id }) => userReportingLabelIds.includes(id))
        .filter((label) => (label.active != null ? label.active : true));
    }
    return [];
  }, [user, settings]);

  const handleSiteOfflineSwitch = useCallback(
    async (value: boolean) => {
      setSiteOfflineLoading(true);
      try {
        if (settings?.id == null) throw new Error('site settings could not be found.');
        await setSiteOffline(settings.id, value);
      } catch (err) {
        setError(err as Error);
      } finally {
        setSiteOfflineLoading(false);
      }
    },
    [setSiteOfflineLoading, settings, setError],
  );

  const updateDrinksWaitingTime = useCallback(
    debounce(
      (async (newDrinksWaitingTime: number) => {
        setWaitingTimesLoading(true);
        try {
          if (settings?.id == null) throw new Error('site settings could not be found');
          await setSiteWaitingTimes(
            settings.id,
            {
              drinksWaitingTime: newDrinksWaitingTime,
              foodWaitingTime: settings.waitingTimes?.foodWaitingTime || 0,
            },
          );
        } catch (err) {
          setError(err as Error);
        } finally {
          setWaitingTimesLoading(false);
        }
      }),
      1000,
    ),
    [setWaitingTimesLoading, settings],
  );

  const updateFoodWaitingTimes = useCallback(
    debounce(
      (async (newFoodWaitingTime: number) => {
        setWaitingTimesLoading(true);
        try {
          if (settings?.id == null) throw new Error('site settings could not be found');
          await setSiteWaitingTimes(
            settings.id,
            {
              drinksWaitingTime: settings.waitingTimes?.drinksWaitingTime || 0,
              foodWaitingTime: newFoodWaitingTime,
            },
          );
        } catch (err) {
          setError(err as Error);
        } finally {
          setWaitingTimesLoading(false);
        }
      }),
      1000,
    ),
    [setWaitingTimesLoading, settings],
  );

  const labelWaitingTimesChangeHandlerFactory = useCallback(
    (
      label: Label,
    ) => async (drinksWaitingTime: number, foodWaitingTime: number) => {
      const updatedLabel: Label = { ...label, foodWaitingTime, drinksWaitingTime };
      try {
        if (settings?.id == null || settings?.reportingLabels == null) {
          throw new Error('site settings could not be found');
        }
        await setLabels(settings.id, label.id, settings.reportingLabels, updatedLabel);
      } catch (err) {
        setError(err as Error);
      }
    },
    [settings, setError],
  );

  const labelOfflineStatusHandlerFactory = useCallback(
    (
      label: Label,
    ) => async (value: boolean) => {
      try {
        if (settings?.id == null) throw new Error('site settings could not be found.');
        if (value === false) {
          await setLabelOffline(settings.id, label.id);
        } else {
          await setLabelOnline(settings.id, label.id);
        }
      } catch (err) {
        setError(err as Error);
      }
    },
    [settings, setError],
  );

  const handleErrorOkClick = useCallback(
    () => setError(null),
    [setError],
  );

  const displaySiteStatusSwitch: boolean = useMemo(
    () => (
      hasPermission(RoleRestrictedAction.ControlAllQuickSettings, user)
      || (hasPermission(RoleRestrictedAction.SetSiteOffline, user)
      && (userSiteConfig?.reportingLabels == null || userSiteConfig?.reportingLabels.length === 0))
    ),
    [userSiteConfig],
  );

  const displaySiteWaitingTimesOptions: boolean = useMemo(
    () => {
      if (hasPermission(RoleRestrictedAction.ControlAllQuickSettings, user)) return true;
      if (userSiteConfig?.reportingLabels != null && userSiteConfig.reportingLabels.length > 0) {
        return false;
      }
      return true;
    },
    [user],
  );

  const displayLabelStatusSwitch: boolean = useMemo(
    () => {
      if (hasPermission(RoleRestrictedAction.SetLabelOffline, user)) return true;
      return false;
    },
    [user, displaySiteStatusSwitch],
  );

  if (!settings) return <Spinner />;

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <SiteDefaultsForm
        settings={settings}
        displaySiteWaitingTimesOptions={displaySiteWaitingTimesOptions}
        waitingTimesUpdateLoading={waitingTimesLoading}
        onDrinksWaitingTimesChange={updateDrinksWaitingTime}
        onFoodWaitingTimesChange={updateFoodWaitingTimes}
        displaySiteStatusSwitch={displaySiteStatusSwitch}
        siteStatusUpdateLoading={siteOfflineLoading}
        onSiteStatusChange={handleSiteOfflineSwitch}
      />
      {filteredLabels.length > 0 ? (
        <Paper>
          <Box style={{ margin: 20 }}>
            <Typography variant="h6" style={{ marginBottom: 10 }}>Vendors</Typography>
            <Divider style={{ marginBottom: 10 }} />
            {filteredLabels.map((label) => (
              <>
                <LabelBox
                  key={label.id}
                  label={label}
                  onLabelWaitingTimeChange={labelWaitingTimesChangeHandlerFactory(label)}
                  isLabelOffline={settings.offlineLabels?.includes(label.id) || false}
                  onLabelOfflineStatusChange={labelOfflineStatusHandlerFactory(label)}
                  displayLabelStatusSwitch={displayLabelStatusSwitch}
                />
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
              </>
            ))}
          </Box>
        </Paper>
      ) : null}
      <ErrorDialog open={error != null} onOkButtonClick={handleErrorOkClick} />
    </Box>
  );
};

export default QuickSettingsPage;
