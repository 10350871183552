import { firestore } from 'firebase/app';

import isUser from './isUser';

import User from '../types/User';
import Nullable from '../types/Nullable';

const initialiseUsersListener = (
  handleSetUsers: (user: User[]) => void,
  handleSetError: (error: Nullable<Error>) => void,
) => (
  firestore()
    .collection('userdata')
    .onSnapshot((querySnapshot) => {
      const queryResults: User[] = [];
      querySnapshot.forEach((doc) => {
        const data = {
          ...doc.data(), id: doc.id,
        };
        if (isUser(data)) queryResults.push(data);
      });

      handleSetUsers(queryResults);
    },
    () => {
      handleSetError(new Error('Users could not be loaded successfully.'));
    })
);

export default initialiseUsersListener;
