import React from 'react';
import { Typography, Chip, makeStyles } from '@material-ui/core';
import RouteChip from '../../types/RouteChip';

interface Props {
  labelText: string;
  chip: RouteChip;
}

const useStyles = makeStyles({
  chip: {
    borderRadius: '4px',
    fontSize: '11px',
    height: '18px',
    width: '48px',
    letterSpacing: '0.4px',
  },
});

const RouteLabelWithChip = ({
  labelText,
  chip,
}: Props) => {
  const classes = useStyles();

  return (
    <Typography>
      {labelText}
      &nbsp;&nbsp;
      <Chip
        className={classes.chip}
        size="small"
        variant="outlined"
        label={chip}
        color="primary"
      />
    </Typography>
  );
};

export default RouteLabelWithChip;
