import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography,
} from '@material-ui/core';

import OutlinedButton from '../outlined-button/outlined-button';

const ConfirmationDialog = ({
  title, message, open, onSave, onClose, errorMessage,
}) => (
  <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <OutlinedButton
        label="Cancel"
        color="secondary"
        onClick={onClose}
      />
      <OutlinedButton label="Confirm" color="primary" onClick={onSave} />
      {errorMessage ? <Typography variant="body1" color="error">{errorMessage}</Typography> : null}
    </DialogActions>
  </Dialog>
);

ConfirmationDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

ConfirmationDialog.defaultProps = {
  errorMessage: null,
};

export default ConfirmationDialog;
