import React from 'react';

import {
  Chip, Typography, Grid, makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  field: {
    marginBottom: 5,
  },
  title: {
    fontWeight: 'bold',
    marginRight: 5,
  },
  item: {
    marginRight: 5,
    marginBottom: 5,
  },
}));

type Props = {
  title: string
  list: string[]
  variant?: 'default' | 'outlined'
  color?: 'primary' | 'secondary' | 'default'
};

const ListField = ({
  title, list, variant, color,
}: Props) => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.field}
      container
      direction="row"
      alignItems="center"
    >
      <Typography className={classes.title}>{`${title}:`}</Typography>
      {list.length !== 0
        ? list.map((item) => <Chip key={item} className={classes.item} variant={variant || 'default'} color={color || 'default'} size="small" label={item} />)
        : <Typography>None</Typography>}
    </Grid>
  );
};

export default ListField;
