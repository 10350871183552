import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import MaterialTable from 'material-table';

import { Chip } from '@material-ui/core';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';

import formatMoney from '../../../helpers/formatMoney';
import hasPermission from '../../../helpers/has-permission';

import RoleRestrictedAction from '../../../types/RoleRestrictedAction';

const UnprocessedOrdersTable = ({
  siteData, user, processOrder, unprocessedOrders,
}) => {
  const history = useHistory();
  return (
    <MaterialTable
      title={`${siteData.name || ''} Unprocessed Orders`}
      columns={[
        { title: 'Order Number', field: 'orderNumber' },
        { title: 'Site', field: 'site' },
        { title: 'Date', field: 'orderTime', render: (rowData) => moment(rowData.orderTime).format('Do MMMM YYYY, HH:mm:ss') },
        { title: `${siteData?.locationDescriptor || 'Table Number'}`, field: 'tableNumber' },
        { title: 'Slot', field: 'slotDay', render: (rowData) => (rowData.slotDay ? `${rowData.slotDay}, ${rowData.slotTime}` : '') },
        { title: 'Notes', field: 'notes' },
        { title: 'Type', field: 'onlineType' },
        { title: 'Items', field: 'order', render: (rowData) => rowData.order.length },
        { title: 'Total', field: 'total', render: (rowData) => formatMoney(rowData.total, siteData.currency) },
        {
          title: 'Status',
          field: 'orderProcessed',
          render: (rowData) => (rowData.orderProcessed
            ? <Chip label="Processed" style={{ backgroundColor: 'green' }} />
            : <Chip label="Unprocessed" style={{ backgroundColor: 'red' }} />),
        },
        { title: '', field: 'refunded', render: (rowData) => (rowData.refunded ? <Chip label="Refunded" color="primary" /> : null) },
      ]}
      data={unprocessedOrders}
      actions={[
        {
          icon: 'visibility',
          tooltip: 'View Order',
          disabled: !hasPermission(RoleRestrictedAction.ViewOrder, user),
          onClick: (event, rowData) => history.push(`/orders/${rowData.orderNumber}`),
        },
        (rowData) => ({
          icon: SettingsBackupRestoreIcon,
          tooltip: 'Process Order',
          disabled: (
            rowData.orderProcessed
            || !hasPermission(RoleRestrictedAction.ProcessOrder, user)
          ),
          onClick: () => processOrder(rowData.orderNumber),
        }),
      ]}
      options={{
        actionsColumnIndex: -1,
        pageSize: 10,
      }}
    />
  );
};

UnprocessedOrdersTable.propTypes = {
  siteData: PropTypes.shape(),
  user: PropTypes.shape(),
  processOrder: PropTypes.func.isRequired,
  unprocessedOrders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

UnprocessedOrdersTable.defaultProps = {
  siteData: null,
  user: null,
};

export default UnprocessedOrdersTable;
