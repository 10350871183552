import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { DateTime } from 'luxon';

import {
  Box, Button, Chip, makeStyles, Paper,
  TextField,
} from '@material-ui/core';
import { DataGrid, GridCellParams } from '@material-ui/data-grid';

import GiftCardHistoryActionChip from '../GiftCardHistoryActionChip/GiftCardHistoryActionChip';
import ErrorDialog from '../ErrorDialog/ErrorDialog';
import DateField from '../date-field/date-field';
import Toolbar from './components/Toolbar';

import apiRequest from '../../helpers/api-request';
import formatMoney from '../../helpers/formatMoney';

import GiftCardAuditTrail from '../../types/GiftCardAuditTrail';
import BackdropSpinner from '../backdrop-spinner/backdrop-spinner';

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  table: {
    marginTop: 10,
    height: '70vh',
    paddingBottom: theme.spacing(5),
  },
  fields: { marginRight: 20 },
  searchFields: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  timestampInput: {
    width: 180,
  },
  searchButton: {
    marginLeft: theme.spacing(2),
  },
}));

type Props = {
  site: string
};

type Options = {
  code: string
  start: string
  end: string
};

const GiftCardAuditTrailReport = ({ site }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<Options>({
    code: '',
    start: DateTime.now().minus({ weeks: 1 }).toFormat('yyyy-MM-dd'),
    end: DateTime.now().toFormat('yyyy-MM-dd'),
  });
  const [auditTrail, setAuditTrail] = useState<GiftCardAuditTrail[]>([]);
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
  const [isSearched, setIsSearched] = useState<boolean>(false);
  const classes = useStyles();
  const history = useHistory();

  const { code, start, end } = options;

  const handleSearch = async () => {
    setIsLoading(true);
    setErrorDialogOpen(false);
    try {
      const { data } = await apiRequest(
        `gift-card/audit/${site}`,
        'POST',
        { code, start, end },
      );
      setAuditTrail(data);
      setIsSearched(true);
    } catch {
      setErrorDialogOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClear = () => {
    setOptions({
      code: '',
      start: DateTime.now().minus({ weeks: 1 }).toFormat('yyyy-MM-dd'),
      end: DateTime.now().toFormat('yyyy-MM-dd'),
    });
    setIsSearched(false);
  };

  const handleErrorOkClick = () => {
    history.go(0);
  };

  const handleOptionsChange = <T extends keyof Options>(
    label: T, value: Options[T],
  ) => {
    setOptions((oldOptions) => ({ ...oldOptions, [label]: value }));
    setIsSearched(false);
    setAuditTrail([]);
  };

  return (
    <Box>
      <BackdropSpinner open={isLoading} />
      <Paper className={classes.paperContainer}>
        <Box className={classes.searchFields}>
          <TextField
            className={classes.fields}
            label="Gift Card Code"
            value={code}
            onChange={(e) => handleOptionsChange('code', e.target.value)}
            variant="outlined"
            margin="normal"
          />
          <DateField
            textFieldClassName={classes.fields}
            label="Start Date"
            value={start || ''}
            onChange={(e) => handleOptionsChange('start', e.target.value)}
          />
          <DateField
            textFieldClassName={classes.fields}
            label="End Date"
            value={end || ''}
            onChange={(e) => handleOptionsChange('end', e.target.value)}
          />
        </Box>
        <Box>
          <Button
            onClick={handleClear}
            size="large"
            variant="outlined"
            color="secondary"
          >
            Clear
          </Button>
          <Button
            className={classes.searchButton}
            onClick={handleSearch}
            size="large"
            variant="outlined"
            color="primary"
          >
            Search
          </Button>
        </Box>
      </Paper>
      {isSearched && (
        <Box className={classes.table}>
          <DataGrid
            rows={auditTrail}
            columns={[
              {
                field: 'timestamp',
                headerName: 'Date',
                flex: 1,
                renderCell: (params: GridCellParams) => DateTime
                  .fromISO(params.row.timestamp)
                  .toLocaleString(DateTime.DATETIME_MED),
              },
              { field: 'giftCardId', headerName: 'Gift Card ID', flex: 1 },
              { field: 'code', headerName: 'Code', flex: 1 },
              {
                field: 'source',
                headerName: 'Source',
                flex: 1,
                renderCell: (params: GridCellParams) => (
                  <Chip
                    label={params.row.source}
                    color="primary"
                  />
                ),
              },
              {
                field: 'action',
                headerName: 'Action',
                flex: 1,
                renderCell: (params: GridCellParams) => (
                  <GiftCardHistoryActionChip action={params.row.action} />
                ),
              },
              {
                field: 'user',
                headerName: 'User',
                flex: 1,
                renderCell: (params: GridCellParams) => params.row.user || 'N/A',
              },
              {
                field: 'amount',
                headerName: 'Amount Change',
                flex: 1,
                renderCell: (params: GridCellParams) => formatMoney(params.row.amount),
              },
              {
                field: 'balance',
                headerName: 'Balance',
                flex: 1,
                renderCell: (params: GridCellParams) => formatMoney(params.row.balance),
              },
            ]}
            components={{ Toolbar }}
            componentsProps={{
              toolbar: {
                auditTrail,
                site,
              },
            }}
            pageSize={10}
            rowsPerPageOptions={[10]}
            loading={isLoading}
          />
          <ErrorDialog
            open={errorDialogOpen}
            onOkButtonClick={handleErrorOkClick}
            message="Could not get Audit Trail Report"
          />
        </Box>
      )}
    </Box>
  );
};

export default GiftCardAuditTrailReport;
