import React from 'react';
import moment from 'moment';
import { Box } from '@material-ui/core';

import OutlinedTextField from '../../outlined-text-field/outlined-text-field';

interface Props {
  times: string[];
  hiddenTimes: boolean[];
  onChange: (value: string, day: number) => void;
}

const ProductTimesInput = ({
  times, hiddenTimes, onChange,
}: Props) => (
  <Box
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    }}
  >
    {times.map((time, index) => {
      const day = moment().isoWeekday(index + 1).format('dddd');
      return (
        <Box key={day}>
          <OutlinedTextField
            id="time"
            label={day}
            type="time"
            classOption="time"
            value={time || '00:00'}
            onChange={(event) => onChange(
              event.target.value, index,
            )}
            disabled={hiddenTimes[index]}
          />
        </Box>
      );
    })}
  </Box>
);

export default ProductTimesInput;
