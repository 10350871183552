import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import apiRequest from '../../helpers/api-request';

import ReduxState from '../../types/ReduxState';
import Nullable from '../../types/Nullable';
import SiteData from '../../types/SiteData';
import User from '../../types/User';
import getSettings from '../../actions/get-settings';
import getUsers from '../../actions/get-users';
import Spinner from '../../components/spinner/spinner';
import POSOtherPaymentType from '../../types/POSOtherPaymentType';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: theme.spacing(52),
  },
  title: {
    margin: theme.spacing(2, 0, 1),
  },
  inputLabel: {
    marginLeft: theme.spacing(1),
  },
  inputs: {
    marginTop: theme.spacing(2),
    minWidth: theme.spacing(52),
  },
  subtitle: {
    margin: theme.spacing(4, 0, 1),
  },
  button: {
    marginTop: theme.spacing(5),
  },
}));

const EditPosAdditionalPaymentPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<{ POSPaymentTypeId: string }>();
  const { POSPaymentTypeId } = params;

  const [paymentTypeName, setPaymentTypeName] = useState<Nullable<string>>(null);
  const [selectedUserEmails, setSelectedUserEmails] = useState<string[]>([]);
  const [
    selectedReportingLabelIds,
    setSelectedReportingLabelIds,
  ] = useState<string[]>([]);
  const [additionalPaymentType, setAdditionalPaymentType] = useState<Nullable<string>>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Nullable<Error>>(null);
  const [nameErrorWarning, setNameErrorWarning] = useState<boolean>(false);

  const authenticatedUser = useSelector<ReduxState, Nullable<User>>((state) => state.authentication.get('USER'));
  const isUsersLoading = useSelector<ReduxState, boolean>((state) => state.users.get('IS_USERS_LOADING'));
  const users = useSelector<ReduxState, User[]>((state) => state.users.get('USERS'));
  const settings = useSelector<ReduxState, Nullable<SiteData>>((state) => state.settings.get('SETTINGS'));

  const userSiteConfig = useMemo(
    () => authenticatedUser?.sites.find((siteConfig) => siteConfig.site === authenticatedUser.site),
    [authenticatedUser],
  );

  useEffect(() => {
    if (authenticatedUser != null) {
      dispatch(getUsers());
      dispatch(getSettings(authenticatedUser.site));
    }
  }, [authenticatedUser]);

  useEffect(() => {
    if (settings != null && POSPaymentTypeId != null) {
      const fetchPaymentType = async () => {
        try {
          const { data } = await apiRequest(`sites/${settings.id}/pos-other-payment-types/${POSPaymentTypeId}`, 'GET');
          const {
            name,
            reportingLabels,
            posUsers,
            type,
          } = data;
          setPaymentTypeName(name);
          setSelectedReportingLabelIds(reportingLabels);
          setSelectedUserEmails(posUsers);
          setAdditionalPaymentType(type);
          setIsLoading(false);
        } catch (err) {
          setError(new Error('Something went wrong, please try again or contact support'));
        }
      };
      fetchPaymentType();
    }
  }, [settings]);

  const labelSelectOptions = useMemo(() => {
    if (settings != null && settings.reportingLabels) {
      return settings.reportingLabels
        .filter((label) => (label.active != null ? label.active : true));
    }
    return [];
  }, [settings]);

  const userSelectOptions = useMemo(() => {
    if (userSiteConfig != null) {
      return users.filter((user) => (
        user.sites.some((site) => userSiteConfig.site === site.site && site.POSUser)
      ));
    }
    return [];
  }, [userSiteConfig]);

  const handleNameChange = (event: React.ChangeEvent<{ value: string }>) => {
    const { target: { value } } = event;
    if (
      value.toLowerCase() === 'card'
      || value.toLowerCase() === 'terminal'
    ) {
      setNameErrorWarning(true);
    } else {
      setNameErrorWarning(false);
    }
    setPaymentTypeName(value);
  };

  const handleReportingLabelChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { target: { value } } = event;
    if (Array.isArray(value) && value.every((element: unknown) => typeof element === 'string')) {
      if (value.some((element: string) => element === 'clear-select')) {
        setSelectedReportingLabelIds([]);
      } else {
        setSelectedReportingLabelIds(value);
      }
    }
  };

  const handleUserChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { target: { value } } = event;
    if (Array.isArray(value) && value.every((element: unknown) => typeof element === 'string')) {
      if (value.some((element: string) => element === 'clear-select')) {
        setSelectedUserEmails([]);
      } else {
        setSelectedUserEmails(value);
      }
    }
  };

  const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { target: { value } } = event;
    if (typeof value === 'string') setAdditionalPaymentType(value);
  };

  const handleSubmit = async () => {
    if (
      settings != null
      && paymentTypeName != null
      && selectedReportingLabelIds != null
      && selectedUserEmails != null
      && additionalPaymentType != null
    ) {
      try {
        setIsLoading(true);
        const additionalPaymentTypeBody: POSOtherPaymentType = {
          name: paymentTypeName,
          reportingLabels: selectedReportingLabelIds,
          posUsers: selectedUserEmails,
          type: additionalPaymentType,
          active: true,
        };
        await apiRequest(
          `sites/${settings.id}/pos-other-payment-types/${POSPaymentTypeId}`,
          'PUT',
          additionalPaymentTypeBody,
        );
        history.push('/pos?tab=5');
      } catch (err) {
        setIsLoading(false);
        setError(new Error('Something went wrong, please try again or contact support'));
      }
    }
  };

  if (isUsersLoading || !settings || isLoading) return <Spinner />;

  return (
    <Box className={classes.container}>
      <Typography
        variant="h6"
        className={classes.title}
      >
        Edit POS payment type
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">Update name</Typography>
      <Typography color="textSecondary">Update the name to identify the payment type</Typography>
      <TextField
        variant="outlined"
        value={paymentTypeName}
        onChange={handleNameChange}
        className={classes.inputs}
        placeholder="Name"
      />
      {nameErrorWarning && <Typography variant="body1" color="error">{`Name cannot be set to ${paymentTypeName}`}</Typography>}
      <Typography variant="subtitle1" className={classes.subtitle}>Add/Remove reporting labels</Typography>
      <Typography color="textSecondary">Payment type is restricted to selected reporting label(s)</Typography>
      <FormControl className={classes.inputs} variant="outlined">
        <InputLabel className={classes.inputLabel} id="multiple-label-select-label">Reporting Label</InputLabel>
        <Select
          multiple
          labelId="multiple-label-select-label"
          labelWidth={125}
          value={selectedReportingLabelIds}
          onChange={handleReportingLabelChange}
          MenuProps={{ variant: 'menu' }}
        >
          {labelSelectOptions.map(({ id, text }) => (
            <MenuItem key={id} value={id}>{text}</MenuItem>
          ))}
          <MenuItem key="clear-select" value="clear-select">
            <Typography variant="button" color="primary">No Label</Typography>
          </MenuItem>
        </Select>
      </FormControl>
      <Typography variant="subtitle1" className={classes.subtitle}>Add/Remove users</Typography>
      <Typography color="textSecondary">Payment type is restricted to selected user(s)</Typography>
      <FormControl className={classes.inputs} variant="outlined">
        <InputLabel className={classes.inputLabel} id="multiple-user-select-label">POS users</InputLabel>
        <Select
          multiple
          labelId="multiple-user-select-label"
          labelWidth={90}
          value={selectedUserEmails}
          onChange={handleUserChange}
          MenuProps={{ variant: 'menu' }}
        >
          {userSelectOptions.map(({ id, email, name }) => (
            <MenuItem key={id} value={email}>{name}</MenuItem>
          ))}
          <MenuItem key="clear-select" value="clear-select">
            <Typography variant="button" color="primary">No users</Typography>
          </MenuItem>
        </Select>
      </FormControl>
      <Typography variant="subtitle1" className={classes.subtitle}>Type</Typography>
      <Typography color="textSecondary">Update payment type</Typography>
      <FormControl className={classes.inputs} variant="outlined">
        <InputLabel className={classes.inputLabel} id="type-select-label">Additional payment type</InputLabel>
        <Select
          labelId="type-select-label"
          labelWidth={180}
          value={additionalPaymentType}
          onChange={handleTypeChange}
          MenuProps={{ variant: 'menu' }}
        >
          <MenuItem key="1" value="Cash">
            <Typography variant="button" color="primary">Cash</Typography>
          </MenuItem>
          <MenuItem key="2" value="Voucher">
            <Typography variant="button" color="primary">Voucher</Typography>
          </MenuItem>
        </Select>
      </FormControl>
      <Button
        variant="contained"
        disabled={paymentTypeName == null || additionalPaymentType === ''}
        onClick={handleSubmit}
        color="primary"
        className={classes.button}
      >
        Update payment type
      </Button>
      {error && <Typography variant="body1" color="error">{error.message}</Typography>}
    </Box>
  );
};

export default EditPosAdditionalPaymentPage;
