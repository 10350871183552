import firebase from 'firebase/app';

const setLabelOffline = (siteId: string, newOfflineLabel: string) => (
  firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .update({
      offlineLabels: firebase.firestore.FieldValue.arrayUnion(newOfflineLabel),
    })
);

export default setLabelOffline;
