import firebase from 'firebase/app';

import ProductData from '../types/ProductData';

const changeProducts = (id: string, newProduct: ProductData) => (
  firebase
    .firestore()
    .collection('masterproducts')
    .doc(id)
    .update(newProduct)
);

export default changeProducts;
