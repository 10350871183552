import React,
{
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Grid } from '@material-ui/core';

import getSiteMap from '../../actions/get-site-map';
import getProducts from '../../actions/get-products';

import CategoryTable from './components/CategoryTable';
import RemoveSubCategoryDialog from './components/remove-sub-category-dialog';
import Spinner from '../../components/spinner/spinner';

import filterCategory from './helpers/filter-category';
import canUserViewProduct from '../../helpers/can-user-view-product';
import hasPermission from '../../helpers/has-permission';

import STORE_KEYS from '../../constants/store-keys';
import RoleRestrictedAction from '../../types/RoleRestrictedAction';

const {
  AUTHENTICATION: { USER },
  SITE_MAP: { IS_SITE_MAP_LOADING, SITE_MAP },
  PRODUCTS: { IS_PRODUCTS_LOADING, PRODUCTS },
} = STORE_KEYS;

const CategoryPage = () => {
  const user = useSelector((state) => state.authentication.get(USER));
  const isSiteMapLoading = useSelector((state) => state.siteMap.get(IS_SITE_MAP_LOADING));
  const siteMap = useSelector((state) => state.siteMap.get(SITE_MAP));
  const allProducts = useSelector((state) => state.products.get(PRODUCTS));
  const isAllProductsLoading = useSelector((state) => state.products.get(IS_PRODUCTS_LOADING));
  const [subCategory, setSubCategory] = useState(null);
  const dispatch = useDispatch();
  const params = useParams();

  const { category } = params;

  useEffect(() => {
    if (user.site) {
      dispatch(getSiteMap(user.site));
      dispatch(getProducts(user.site));
    }
  }, []);

  const userSiteConfig = useMemo(
    () => user.sites.find(({ site }) => site === user.site),
    [user],
  );

  const handleRemove = () => {
    setSubCategory(null);
    dispatch(getSiteMap(user.site));
  };

  const subCategories = useMemo(
    () => filterCategory(siteMap, category).sort((a, b) => a.sequence - b.sequence),
    [siteMap, category],
  );

  const viewableSubCategories = useMemo(() => {
    if (
      subCategories.length === 0
      || allProducts.length === 0
      || userSiteConfig == null
    ) return [];
    return subCategories.filter(({ products }) => {
      if (products.length === 0) return true;
      return products.some((product) => {
        const productWithInfo = allProducts.find(({ plu }) => product.plu === plu);
        if (productWithInfo == null) return true;
        return canUserViewProduct(productWithInfo, userSiteConfig);
      });
    });
  }, [subCategories, allProducts, userSiteConfig]);

  const editableSubCategories = useMemo(() => (
    viewableSubCategories.filter(({ products }) => {
      if (products.length === 0) return true;
      return products.every((product) => {
        const productWithInfo = allProducts.find(({ plu }) => product.plu === plu);
        if (productWithInfo == null) return true;
        return canUserViewProduct(productWithInfo, userSiteConfig);
      });
    }).map(({ name }) => name)
  ), [allProducts, viewableSubCategories, userSiteConfig]);

  const userCanAddSubCategory = useMemo(() => (
    hasPermission(RoleRestrictedAction.CreateSubCategory, user)
    && subCategories.every(({ name }) => editableSubCategories.includes(name))
  ), [user, editableSubCategories, subCategories]);

  if (isSiteMapLoading) return <Spinner />;

  return (
    <Grid>
      <CategoryTable
        subCategories={subCategories}
        category={category}
        isLoading={isAllProductsLoading || isSiteMapLoading}
        onRemove={(g) => setSubCategory(g)}
        editableSubCategories={editableSubCategories}
        userCanAddSubCategory={userCanAddSubCategory}
      />
      <RemoveSubCategoryDialog
        open={subCategory !== null}
        subCategoryToRemove={subCategory}
        onSave={handleRemove}
        handleClose={() => setSubCategory(null)}
      />
    </Grid>
  );
};

export default CategoryPage;
